angular.module('portailDepotDemandeAide.depot').controller('depotSimpleDemandeurAdresseController', [
  '$scope',
  'StoreService',
  function($scope, StoreService) {
    'use strict';

    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.adresseDemandeurConfiguration.ns;
    $scope.demandeur = StoreService.demandeur.get();

    $scope.navigate.next = function() {
      StoreService.demandeur.set($scope.demandeur);

      const personnaliteJuridiqueMorale = _.get($scope.demandeur, 'famille.expand.personnaliteJuridique') === 'MORALE';
      const nextStep = personnaliteJuridiqueMorale ? 'demandeur-representant-legal' : 'demandeur-complementaire';
      $scope.saveDemandeur().then(() => $scope.goToStep(nextStep));
    };

    $scope.adresseDemandeurConfiguration.showErrors = $scope.showErrorsOnNavigate();
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'tiers';
  },
]);
