angular.module('common.directives').directive('globalErrors', [
  '$rootScope',
  'configuration',
  'alertsService',
  '$transitions',
  '$translate',
  function($rootScope, configuration, alertsService, $transitions, $translate) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'common/common-directives/global-errors/global-errors.html',
      scope: {},
      link: function($scope) {
        // Hide generals error on state change start
        $transitions.onStart({}, function() {
          $scope.alerts = [];
        });

        var errorProcess = function(error) {
          return (
            (_.startsWith(error.config.url, configuration.user.accountManagement) &&
              _.includes([404, 406, 409], error.status)) ||
            (_.startsWith(error.config.url, _.get(configuration, 'tiers.url')) &&
              _.includes([403, 404, 406, 409], error.status)) ||
            (_.startsWith(error.config.url, _.get(configuration, 'aides.url')) &&
              _.includes([403, 404, 406], error.status)) ||
            (_.startsWith(error.config.url, '/gestion-depot-demandes') &&
              _.includes([403, 404, 406, 409], error.status)) ||
            (_.startsWith(error.config.url, configuration.persistence) && _.includes([406, 502], error.status)) ||
            (_.startsWith(error.config.url, '/document-collect') && _.includes([409, 404], error.status))
          );
        };

        // Check errors http
        $rootScope.$on('$httpError', function(event, error) {
          if (error && error.config && _.isString(error.config.url)) {
            if (errorProcess(error)) {
              return;
            }
          }

          if (error && error.status === 401) {
            return;
          }

          if (error && error.status === -1) {
            return;
          }

          var messageParameters = {
            detail: error.config.method + ' ' + error.config.url + ' - ' + error.status + ' ' + error.statusText,
          };

          var messages = [];
          messages.push($translate.instant('error.http', messageParameters));

          // Add X-Request-ID
          if (error.config.headers && error.config.headers['X-Request-ID']) {
            messages.push($translate.instant('error.xRequestId', { xRequestId: error.config.headers['X-Request-ID'] }));
          }

          $scope.alerts = [{ type: 'error', messages: messages, title: 'error.title' }];
        });
      },
    };
  },
]);
