angular.module('form.services').factory('formFormat', [

  function() {
    'use strict';

    // Function who format a phone number
    var formatPhone = function (phone) {

      var phoneFormated = phone;
      if (phone) {
        phone = phone.replace(/ |\.|\-/g, '');
        if (phone.indexOf('+33') === 0 && phone.length === 12) {
          phoneFormated = phone.substring(0, 3) + ' ' + phone.substring(3, 4) + ' ' + phone.substring(4, 6) + ' ' + phone.substring(6, 8) + ' ' + phone.substring(8, 10) + ' ' + phone.substring(10, 12);
        }
        if (phone.length === 10) {
          phoneFormated = phone.substring(0, 2) + ' ' + phone.substring(2, 4) + ' ' + phone.substring(4, 6) + ' ' + phone.substring(6, 8) + ' ' + phone.substring(8, 10);
        }
      }

      return phoneFormated;
    };

    /**
     * Capitalizes the first letter(s) of the given string
     * If the parameter contains spaces or '-', the word just after is also capitalized
     */
    var capitalizeFirst = function(word) {
      var capitalizedWord = null;
      if (word) {
        var pieces = word.split(' ');
        var i, j;
        for (i = 0; i < pieces.length; i++) {
          pieces[i] = pieces[i].toLowerCase();
          j = pieces[i].charAt(0).toUpperCase();
          pieces[i] = j + pieces[i].substr(1);
        }
        capitalizedWord = pieces.join(' ');

        pieces = capitalizedWord.split('-');
        for (i = 0; i < pieces.length; i++) {
          j = pieces[i].charAt(0).toUpperCase();
          pieces[i] = j + pieces[i].substr(1);
        }
        capitalizedWord = pieces.join('-');
      }
      return capitalizedWord;
    };

    return {
      capitalizeFirst: capitalizeFirst,
      formatPhone: formatPhone
    };
  }
]);
