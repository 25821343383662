// Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationMoyensHumainsComponent', {
  templateUrl: 'depot/justification/moyens-humains/justification-moyens-humains.component.html',
  controller: /* @ngInject */ JustificationMoyensHumainsController,
  bindings: {
    workflow: '<',
    justification: '=',
    print: '<',
    validStep: '<',
    disableNavigation: '<',
    saveJustification: '<',
    stepIndex: '<',
  },
});

function JustificationMoyensHumainsController($scope) {
  var ctrl = this;

  ctrl.$onInit = function() {
    ctrl.initTableContent();
  };

  // Initiate Table
  ctrl.initTableContent = function() {
    if (!_.has(ctrl.justification, 'moyensHumains')) {
      ctrl.justification.moyensHumains = {};
    }
    if (!_.has(ctrl.justification, 'moyensHumains.content')) {
      ctrl.justification.moyensHumains.content = [];
    }
    ctrl.moyensHumainsValues = [];
    _.forEach(ctrl.workflow.moyensHumains.hierarchie, function(hierarchie) {
      var moyenHumain = {
        premierNiveau: {
          title: hierarchie.premierNiveau.title,
          href: hierarchie.premierNiveau.href,
          nbPersonnes: null,
          nbEtpt: null,
        },
        deuxiemesNiveaux: initDeuxiemeNiveau(hierarchie.deuxiemesNiveaux),
      };

      if (_.isEmpty(moyenHumain.deuxiemesNiveaux)) {
        var content = _.find(ctrl.justification.moyensHumains.content, { href: hierarchie.premierNiveau.href });
        moyenHumain.premierNiveau.nbPersonnes = content ? content.nbPersonnes : null;
        moyenHumain.premierNiveau.nbEtpt = content ? content.nbEtpt : null;
      } else {
        ctrl.updateTotal(moyenHumain);
      }

      ctrl.moyensHumainsValues.push(moyenHumain);
    });
  };

  /**
   * Format moyen-humain
   * @param {Object} deuxiemesNiveaux
   * @returns {Object}
   */
  function initDeuxiemeNiveau(deuxiemesNiveaux) {
    var _deuxiemesNiveaux = [];
    _.forEach(deuxiemesNiveaux, function(deuxiemeNiveau) {
      var content = _.find(ctrl.justification.moyensHumains.content, { href: deuxiemeNiveau.href });
      _deuxiemesNiveaux.push({
        title: deuxiemeNiveau.title,
        href: deuxiemeNiveau.href,
        nbPersonnes: content ? content.nbPersonnes : null,
        nbEtpt: content ? content.nbEtpt : null,
      });
    });
    return _deuxiemesNiveaux;
  }

  /**
   * Update total number of people
   * @param {Object} hierarchie
   * @returns {Number}
   */
  ctrl.updateTotal = function(hierarchie) {
    hierarchie.premierNiveau.nbPersonnes = _.sumBy(hierarchie.deuxiemesNiveaux, 'nbPersonnes');
    hierarchie.premierNiveau.nbEtpt = _.sumBy(hierarchie.deuxiemesNiveaux, 'nbEtpt');
  };

  ctrl.updateJustification = function() {
    var newJustification = [];
    _.forEach(ctrl.moyensHumainsValues, function(moyenHumainValue) {
      if (!_.isEmpty(moyenHumainValue.deuxiemesNiveaux)) {
        _.forEach(moyenHumainValue.deuxiemesNiveaux, function(deuxiemesNiveaux) {
          newJustification.push(deuxiemesNiveaux);
        });
      } else {
        newJustification.push(moyenHumainValue.premierNiveau);
      }
    });
    // Method to remove $$hashKey
    ctrl.justification.moyensHumains.content = JSON.parse(angular.toJson(newJustification));
  };

  ctrl.isFormTableValid = function() {
    return (
      _.find(ctrl.justification.moyensHumains.content, function(content) {
        return content.nbEtpt > content.nbPersonnes;
      }) === undefined
    );
  };

  // Prevent form validation when keyboard "enter" is press
  ctrl.preventValidationOnEnter = function(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  /**
   * Method to check if the form is valid
   */
  ctrl.isFormValid = function() {
    return $scope.moyensHumainsForm && $scope.moyensHumainsForm.$valid && ctrl.isFormTableValid();
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the previous step
   */
  ctrl.previousStep = function() {
    ctrl.validStep(-1);
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the next step
   */
  ctrl.nextStep = function() {
    if (ctrl.isFormValid()) {
      ctrl.validStep();
    }
  };

  /**
   * Method for the justification-step-wizard-navigation to save this step
   **/
  ctrl.saveStep = function() {
    ctrl.saveJustification();
  };
}
