angular.module('common.services').factory('dataSchemasService', ['$http', '$q', '$log', dataSchemasService]);

function dataSchemasService($http, $q, $log) {
  'use strict';

  return {
    /**
     * Get a list of data schemas promises
     * @param {array} urls array of data-schemas hrefs
     * @returns {array} array of Promises
     */
    getDataSchemasList: (urls) => {
      const dataSchemasList = [];
      _.forEach(urls, (url) => {
        const dataSchema = $http
          .get(url)
          .then(function(response) {
            return response.data;
          })
          .catch(function(error) {
            $log.error(error);
            throw error;
          });
        dataSchemasList.push(dataSchema);
      });
      return $q.all(dataSchemasList);
    },
  };
}
