angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleDemandeurRecapitulatifController', depotSimpleDemandeurRecapitulatifController);

depotSimpleDemandeurRecapitulatifController.$inject = ['$scope', '$q', '$timeout', 'configuration', 'tiersService'];

function depotSimpleDemandeurRecapitulatifController($scope, $q, $timeout, configuration, tiersService) {
  const ficheRecapitulativeReady = $q.defer();
  const ressourceshumainesReady = $q.defer();
  const agrementstiersassociationReady = $q.defer();
  const unionsfederationsreseauxtiersassociationReady = $q.defer();

  // If the user hasn't any tiers, we have to go to the tiers creation workflow
  if (!$scope.tiers) {
    $scope.goToStep('demandeur-famille', true);
  }

  $scope.cleanNavigate();
  $scope.navigate.ns = 'teleservice.demandeur-recapitulatif';

  $scope.isAssociation = _.get($scope.tiers, 'famille.expand.typeFamille', '') === 'TIERS_ASSOCIATION';

  // rules:
  // https://jira.mgdis.fr/browse/PLAID-12175 (this screen)
  // https://jira.mgdis.fr/browse/PLAID-13443 (contract defaults)
  const canEditTiersInDepot =
    _.get(configuration, 'tiers.modificationIdentificationTiersParComptes', true) &&
    _.get(configuration, 'tiers.modificationDeTiersParDepot', false);

  // Iframe to referentiel-tiers
  function getIframeReferentielTiersUrl(reference, page) {
    var url = tiersService.getIframeUrl(reference, page, { readOnly: !canEditTiersInDepot, richtext: false });
    return url;
  }

  function loadFicheRecapitulative() {
    $scope.ficheRecapitulativeUrl = getIframeReferentielTiersUrl($scope.tiers.reference, 'ficherecapitulative');
    return ficheRecapitulativeReady.promise;
  }

  function loadInfosCompTiers() {
    const href = _.get($scope, 'tiers.famille.href');
    const displaySaveButton = canEditTiersInDepot;

    return tiersService
      .getViewsSrcFromFamille($scope.tiers, href, !canEditTiersInDepot, displaySaveButton)
      .then((data) => {
        $scope.areViewsActive = data.areViewsActive;
        $scope.viewsIframeSrc = data.viewsIframeSrc;

        $timeout(() => {
          setIframeResizer('#viewsInfosComps');
        });
      });
  }

  function loadResourcesHumaines() {
    $scope.ressourcesHumainesUrl = getIframeReferentielTiersUrl($scope.tiers.reference, 'ressourceshumaines');
    return ressourceshumainesReady.promise;
  }

  function loadAgrementsTiersAssociation() {
    $scope.agrementsUrl = getIframeReferentielTiersUrl($scope.tiers.reference, 'agrementstiersassociation');
    return agrementstiersassociationReady.promise;
  }

  function loadUnionsFederationsReseauxTiersAssociation() {
    $scope.unionsFederationsReseauxUrl = getIframeReferentielTiersUrl(
      $scope.tiers.reference,
      'unionsfederationsreseauxtiersassociation'
    );
    return unionsfederationsreseauxtiersassociationReady.promise;
  }

  function loadAllIframes() {
    return loadFicheRecapitulative()
      .then(() => loadInfosCompTiers())
      .then(() => {
        if ($scope.isAssociation) {
          // Additional pages for tiers association
          return loadAgrementsTiersAssociation()
            .then(loadUnionsFederationsReseauxTiersAssociation)
            .then(loadResourcesHumaines);
        }
      });
  }

  function setIframeResizer(id) {
    iFrameResize(
      {
        heightCalculationMethod: 'lowestElement',
        checkOrigin: false,
        inPageLinks: true,
      },
      id || '.referentiel-tiers-iframe'
    );
  }

  /**
   * update the tiers context, the purpose if to compute the hidding condition with the new values
   */
  function updateInfosTiersContext() {
    const viewsIframe = angular.element('#viewsInfosComps');
    if (viewsIframe && viewsIframe[0]) {
      viewsIframe[0].contentWindow.postMessage(
        {
          action: 'updateContextExpressions',
          contextExpressions: JSON.parse(JSON.stringify($scope.tiers)),
        },
        '*'
      );
    }
  }

  $scope.navigate.next = () => {
    const viewsIframe = angular.element('#viewsInfosComps');
    if (viewsIframe && viewsIframe[0]) {
      const controleCompletude = _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false);
      viewsIframe[0].contentWindow.postMessage(
        {
          action: 'validViews',
          options: { skipRequiredErrors: controleCompletude, showAllErrors: !controleCompletude },
        },
        '*'
      );
    } else {
      $scope.goToStep('demandeur-complementaire');
    }
  };
  $scope.stepsWizard.steps = $scope.getSimpleSteps();
  $scope.stepsWizard.active = 'tiers';

  // Display page content only when the first iframe is loaded
  function iframeListener(msg) {
    const action = _.get(msg, 'data.action');

    if (action === 'ready') {
      const route = _.get(msg, 'data.route');

      if (route === 'ficheRecapitulative') {
        $scope.$apply(function() {
          $scope.ficheRecapitulativeLoaded = true;
          ficheRecapitulativeReady.resolve();
          setIframeResizer();
        });
      } else if (route === 'ressourceshumaines') {
        ressourceshumainesReady.resolve();
      } else if (route === 'agrementstiersassociation') {
        agrementstiersassociationReady.resolve();
      } else if (route === 'unionsfederationsreseauxtiersassociation') {
        unionsfederationsreseauxtiersassociationReady.resolve();
      } else {
        // update the tiers context when ready, else hidding condition may not work properly
        updateInfosTiersContext();
      }
    } else if (action === 'updateStateViews') {
      const state = _.get(msg, 'data.state');

      if (state === 'ok') {
        // we're outside angular's scope and goToStep modify the "aide" object and a watcher on the aide needs to be triggered to go to the next page
        // so trigger an apply to launch a digest cycle for the watcher to get called
        $scope.$apply(() => $scope.goToStep('demandeur-complementaire'));
      }
    } else if (action === 'savedTiers') {
      $scope.tiers = _.get(msg, 'data.tiers');
      updateInfosTiersContext();
    }
  }

  loadAllIframes();

  window.addEventListener('message', iframeListener, false);

  $scope.$on('$destroy', () => {
    window.removeEventListener('message', iframeListener, false);
  });
}
