angular.module('aides.services').factory('complementsService', function($http, $q, $log, configuration, Complement) {
  'use strict';

  let _tenantId;
  let _url = _.get(configuration, 'demandesComplementPieces.url') || '/gestion-depot-demandes';

  // Configure either at provider level or directly service
  this.tenantId = function(tenantId) {
    _tenantId = tenantId;
  };
  this.url = function(url) {
    _url = url;
  };

  function _getUrl() {
    return _tenantId ? `${_url}/${_tenantId}` : _url;
  }

  return {
    putAvancement: function(complement, avancement) {
      // Getting complement's ID and updating its status
      let complementId = complement.id;
      complement.avancement = avancement;
      const complementJson = angular.toJson(complement);

      return $http
        .put(`${_getUrl()}/demandesComplementPieces/${complementId}/avancement`, complementJson)
        .then((result) => new Complement(result.data));
    },
  };
});
