angular.module('portailDepotDemandeAide.depot').controller('demandePaiementPiecesController', [
  '$scope',
  '$http',
  '$q',
  '$log',
  'dataDocService',
  'demandesPaiementService',
  'Piece',
  'piecesService',
  function($scope, $http, $q, $log, dataDocService, demandesPaiementService, Piece, piecesService) {
    'use strict';

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.piecesConfiguration.ns;
    $scope.navigate.next = $scope.goToStepFn('recapitulatif');

    // Pieces's configuration
    $scope.viewConfiguration = $scope.piecesConfiguration;

    // Steps Wizard
    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'pieces';

    // Page Options
    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pagePieces;
    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    } else {
      $scope.activePage();
    }

    // Persistence
    $scope.demandePaiement.pieces = _.concat(
      piecesService.initializePiecesPersistence(
        $scope.pageOptions.modelesPieces,
        _.filter($scope.demandePaiement.pieces, { fonction: 'depot' })
      ),
      _.get($scope.demandePaiement, 'teleservicePaiement.expand.modelesPieces', [])
    );

    // Filter the pieces by payment type
    $scope.demandePaiement.pieces = _.filter($scope.demandePaiement.pieces, function(piece) {
      var pieceFound =
        piece.typesPaiement === undefined || (piece.typesPaiement && piece.typesPaiement.length === 0)
          ? true
          : _.find(piece.typesPaiement, function(type) {
              var href = _.get(type, 'expand._links.self.href', type.href);
              return href === $scope.demandePaiement.typePaiement.href;
            });
      return pieceFound;
    });

    // Filter the pieces by famille
    var familleTiers = _.get($scope.demandePaiement, 'demandeFinancement.expand.demandeur.expand.famille.href');

    $scope.demandePaiement.pieces = _.filter($scope.demandePaiement.pieces, function(piece) {
      //If the piece doesnt have a familly, we ignore this filter..
      //If not, we filter for the pieces in demandeur.famille
      var pieceFound =
        piece.familles === undefined || (piece.familles && piece.familles.length === 0)
          ? true
          : _.find(piece.familles, function(famille) {
              return _.includes(familleTiers, famille.href);
            });

      return pieceFound;
    });

    // Shorcut for access to pieces
    $scope.pieces = $scope.demandePaiement.pieces;
    $scope.persistenceConfiguration = _.get($scope.teleserviceConfiguration, 'persistenceConfiguration.expand', {});

    // Demande de paiement - Save the demande when we add a new document on piece or update a document
    $scope.saveDemandePaiement = function() {
      demandesPaiementService.saveDemandePaiement($scope.demandePaiement);
    };

    // Url where to upload files
    $scope.urlDocuments = $scope.demandePaiement._links['mgs:documents'].href;

    // Directory of file's icons
    $scope.urlFileIcons = './resources/images/file-icons/';

    /**
     * Evaluate conditionAffichage (visible/hidden) setting on a piece
     * @method isPieceVisible
     * @param {Object} piece the piece
     * @returns {Boolean} the evaluate condition
     */
    $scope.isPieceVisible = function(piece) {
      return piecesService.evalConditionPiece($scope, piece, 'conditionAffichage', true);
    };

    /**
     * Evaluate conditionObligatoire (required) setting on a piece
     * @method isPieceRequired
     * @param {Object} piece the piece
     * @returns {Boolean} the evaluate condition
     */
    $scope.isPieceRequired = function(piece) {
      return piecesService.evalConditionPiece($scope, piece, 'conditionObligatoire', true);
    };
  },
]);
