angular.module('portailDepotDemandeAide.depot').controller('depotSimpleConfirmationController', [
  '$scope',
  '$state',
  '$translate',
  '$rootScope',
  '$interval',
  'recapitulatifService',
  function($scope, $state, $translate, $rootScope, $interval, recapitulatifService) {
    'use strict';

    $scope.cleanNavigate();
    $scope.navigate.noform = true;

    $scope.navigate.finish = function() {
      $state.go('app.connected.dashboard.accueil', {}, { reload: true });
    };
    $scope.navigate.ns = $scope.confirmationAideConfiguration.ns;

    $scope.stepsWizard.steps = [];
    $scope.stepsWizard.active = null;

    // Url to access to recapitulatif document
    var pieceRecapitulatif = _.find($scope.aide.pieces, function(piece) {
      return piece.reference === 'recapitulatif';
    });

    if (pieceRecapitulatif && pieceRecapitulatif.documents && pieceRecapitulatif.documents[0]) {
      $scope.documentRecapitulatif = pieceRecapitulatif.documents[0];
      $scope.documentRecapitulatif.expand.properties['cmis:name'] = {};

      var setCmisName = function() {
        var recapitulatifLabel = $translate.instant(
          $scope.confirmationAideConfiguration.ns + '.documents.recapitulatif'
        );
        $scope.documentRecapitulatif.expand.properties['cmis:name'].value = recapitulatifLabel;
      };
      setCmisName();
      $rootScope.$on('$translateChangeSuccess', setCmisName);
    }

    // Load recapitulatif

    // Booléen indiquant qu'une requête http a déjà été lancée dans $interval
    var lockInterval = false;
    var lockIntervalContribution = false;
    var intervalRecapitulatif;
    var intervalRecapitulatifContribution;

    // Look if recaptitulatif already exists
    var pieceRecap = _.find($scope.aide.pieces, ['reference', 'recapitulatif']);
    if (pieceRecap && pieceRecap.documents && pieceRecap.documents.length > 0) {
      $scope.aide.documentRecapitulatif = _.head(pieceRecap.documents);
    } else if ($scope.aide.status !== 'WAITING_FOR_CERTIFICATE') {
      // If the recap doesnt exists, means that worker-pdf is still working
      // we query the server every 3 seconds to verify
      // Note: If in the future we have perf problems with this method, we can migrate to web sockets
      intervalRecapitulatif = $interval(function() {
        if (lockInterval) {
          return;
        }
        // on initialise le "lock"
        lockInterval = true;
        recapitulatifService
          .isReady($scope.aide.id)
          .then(function(recap) {
            if (recap.result === true) {
              $scope.aide.documentRecapitulatif = recap.value;
              // $scope.contribution.documentRecapitulatif = recap.value;
              $interval.cancel(intervalRecapitulatif);
            }
            // on libère le "lock"
            lockInterval = false;
          })
          .catch(function() {
            lockInterval = false;
          });
      }, 3000);
    }
    if ($scope.aide.status === 'WAITING_FOR_CERTIFICATE') {
      $scope.$emit('changeTitleTransmissionAttestation', {
        title: _.get($scope, 'teleserviceConfiguration.workflow.pageConfirmation.titleTransmissionAttestation'),
      });
    }

    if ($scope.contribution) {
      var pieceContribRecap = _.find($scope.contribution.pieces, ['reference', 'recapitulatif_modifie']);
      if (pieceContribRecap && pieceContribRecap.documents && pieceContribRecap.documents.length > 0) {
        $scope.contribution.documentRecapitulatif = _.head(pieceContribRecap.documents);
      } else {
        intervalRecapitulatifContribution = $interval(function() {
          if (lockIntervalContribution) {
            return;
          }
          // on initialise le "lock"
          lockIntervalContribution = true;
          recapitulatifService
            .isReady($scope.aide.id, _.get($scope, 'contribution.id'))
            .then(function(recap) {
              if (recap.result === true) {
                $scope.contribution.documentRecapitulatif = recap.value;
                $interval.cancel(intervalRecapitulatifContribution);
              }
              // on libère le "lock"
              lockIntervalContribution = false;
            })
            .catch(function() {
              lockIntervalContribution = false;
            });
        }, 3000);
      }
    }

    $scope.$on('$destroy', function() {
      $interval.cancel(intervalRecapitulatif);
      $interval.cancel(intervalRecapitulatifContribution);
    });
  },
]);
