angular.module('form.services').factory('formUtils', [

  function() {
    'use strict';

    /**
		 * // a function to set a primitive in an object in order for it to be compatible
		 * with double data binding when passed to directives with isolated scopes
		 * Hackish but necessary when working on dynamic-field and siblings that bind primitive vars
		 * @param  {object} scope            Angular scope
		 * @param  {[type]} primitiveKey     [description]
		 * @param  {[type]} wrapperObjectKey [description]
		 * @return {[type]}                  [description]
		 */
    var wrapPrimitiveInObject = function(scope, primitiveKey, wrapperObjectKey) {
      scope[wrapperObjectKey] = scope[wrapperObjectKey] || {};
      scope[wrapperObjectKey][primitiveKey] = scope[primitiveKey];
      scope.$watch(primitiveKey, function() {
        scope[wrapperObjectKey][primitiveKey] = scope[primitiveKey];
      });
      scope.$watch(wrapperObjectKey + '.' + primitiveKey, function() {
        scope[primitiveKey] = scope[wrapperObjectKey][primitiveKey];
      });
    };

    /**
		 * Function who check is a value is an angular expression.
		 * Use for configuration properties who have expression angular
		 * @return {boolean} This string is a angular expression or not
		 */
    var checkIsAngularExpression = function(value) {
      var expression = false;
      if (typeof value === 'string' && (
        value.indexOf('==') !== -1 ||
					value.indexOf('!=') !== -1 ||
					value.indexOf('===') !== -1 ||
					value.indexOf('!==') !== -1 ||
					value.indexOf('>') !== -1 ||
					value.indexOf('<') !== -1 ||
					value.indexOf('>=') !== -1 ||
					value.indexOf('<=') !== -1)) {
        expression = true;
      }
      return expression;
    };

    return {
      wrapPrimitiveInObject: wrapPrimitiveInObject,
      checkIsAngularExpression: checkIsAngularExpression
    };
  }
]);