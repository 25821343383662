angular.module('common.model').factory('LinkUser', function(configuration) {
  'use strict';

  function LinkUser(data) {
    data = data || {};
    this.href = data.href;
    this.title = data.title;
    this.email = data.email;
  }

  LinkUser.prototype.getCleanEntity = function() {
    var linkUserClone = _.cloneDeep(this);
    delete linkUserClone.expand;
    return linkUserClone;
  };

  return LinkUser;
});
