angular.module('portailDepotDemandeAide.dashboard').controller('dashboardMesAttestationsController', [
  '$scope',
  'mesAttestations',
  '$translate',
  'aidesService',
  '$q',
  '$state',
  'contributionsService',
  'Aide',
  function($scope, mesAttestations, $translate, aidesService, $q, $state, contributionsService, Aide) {
    $scope.mesAttestations = mesAttestations;
    $scope.attestationSelected = false;
    $scope.attestationHonneur = false;
    $scope.attestationsPerPage = 20;

    $scope.initPager = function() {
      $scope.pagerAttestations = {
        currentPage: 1,
        attestationsPerPage: $scope.attestationsPerPage,
        from: 1,
        to:
          _.get($scope, 'mesAttestations.total') > $scope.attestationsPerPage
            ? $scope.attestationsPerPage
            : _.get($scope, 'mesAttestations.total'),
      };
    };

    $scope.initPager();

    $scope.isAttestationChecked = function() {
      $scope.attestationSelected = false;
      _.each(_.get($scope, 'mesAttestations.hits'), function(attestation) {
        if (attestation.transmit === true) {
          $scope.attestationSelected = true;
          return false;
        }
      });
    };

    $scope.changePage = function() {
      var pager = $scope.pagerAttestations;
      var from = (pager.currentPage - 1) * pager.attestationsPerPage;
      var size = pager.attestationsPerPage;
      aidesService.getAttestations(from, size).then(function(result) {
        $scope.mesAttestations = result;
        $scope.updatePager();
        $scope.attestationSelected = false;
      });
    };

    $scope.updatePager = function() {
      var from =
        _.get($scope, 'mesAttestations.total') === 0
          ? 0
          : (_.get($scope, 'pagerAttestations.currentPage') - 1) *
              _.get($scope, 'pagerAttestations.attestationsPerPage') +
            1;
      _.set($scope, 'pagerAttestations.from', from);
      var to = from + $scope.attestationsPerPage - 1;
      to = to > _.get($scope, 'mesAttestations.total') ? _.get($scope, 'mesAttestations.total') : to;
      _.set($scope, 'pagerAttestations.to', to);
    };

    $scope.transmit = function() {
      // take only transmitted attestations
      const transmittedAttestation = _.filter(_.get($scope, 'mesAttestations.hits'), 'transmit');

      const references = _.map(transmittedAttestation, '_source.reference');

      // construct aide href from reference
      const hrefAides = _.map(references, (ref) => aidesService.getAideHref(ref));

      // get contributions (promises)
      const getContributions = _.map(hrefAides, (hrefAide) =>
        contributionsService.getContributionFromDemande(hrefAide, 'WAITING_FOR_CERTIFICATE')
      );

      $q.all(getContributions)
        .then((contributionsResp) => {
          const contributions = _.map(contributionsResp, (resp) => resp._embedded.items[0]);

          const updateAides = _.map(transmittedAttestation, (attestation) => {
            _.unset(attestation, 'transmit');
            attestation.status = 'TRANSMITTED';

            // look if there's a contribution on the aide
            const referenceAide = attestation._source.reference;
            const contribution = _.find(
              contributions,
              (contrib) => contrib && contrib.demande.href === aidesService.getAideHref(referenceAide)
            );
            if (!contribution) {
              // directly update aide
              return transmitAide(referenceAide).then((aide) => aidesService.updateDemandeurGroupesGestion(aide));
            }
            // retreive aide with contribution applyied and update it
            return aidesService
              .getAideWithContribution(referenceAide, contribution.reference)
              .then((aide) => {
                aide.status = 'SUPPORTED';
                return aidesService.update(aide);
              })
              .then((aide) => answerContribution(contribution.reference).then(() => aide))
              .then((aide) => aidesService.updateDemandeurGroupesGestion(aide));
          });

          return $q.all(updateAides);
        })
        .then(function() {
          $state.go('app.connected.dashboard.attestations.confirmation', { attestations: references });
        });
    };

    function transmitAide(referenceAide) {
      return aidesService.patchAideByReference(referenceAide, [
        {
          op: 'replace',
          path: '/status',
          value: 'TRANSMITTED',
        },
      ]);
    }

    function answerContribution(referenceContribution) {
      return contributionsService.patch(referenceContribution, [
        {
          op: 'replace',
          path: '/statut',
          value: 'ANSWERED',
        },
        {
          op: 'add',
          path: '/dateReponse',
          value: new Date(),
        },
      ]);
    }
  },
]);
