angular.module('user.directives').directive('accountPersonalInformations', [
  '$rootScope',
  '$translate',
  '$location',
  'accountManagementService',
  'alertsService',
  'userSessionService',
  'jwtSessionService',
  'USER_EVENTS',

  function (
    $rootScope,
    $translate,
    $location,
    accountManagementService,
    alertsService,
    userSessionService,
    jwtSessionService,
    USER_EVENTS
  ) {
    function handleOidcAccount(scope) {
      accountManagementService
        .getPublicSettings()
        .then(function (response) {
          const publicSettings = response.data;
          scope.isUnpairingAllowed =
            publicSettings.autoConnect === false &&
            publicSettings.providerMandatory !== true &&
            publicSettings.allowUnpairing === true;
          return _.find(publicSettings.oidcProviders, function (oidcProvider) {
            return oidcProvider.providerId === scope.user.providerId;
          });
        })
        .then(function (oidcProviderInfos) {
          scope.unpairAccountHref = accountManagementService.getUnpairingUrl(
            encodeURIComponent($location.absUrl()),
            jwtSessionService.getJwtKey()
          );
          // Special case for an account paired with an OIDC one.
          // When these accounts are logged in from internal authentication, there is no providerId in the jwt
          const message = _.get(jwtSessionService.user(), 'providerId', false)
            ? 'user.user-management.providerSourceLabel'
            : 'user.user-management.providerPairedLabel';
          scope.providerInfos = {
            title: oidcProviderInfos.title,
            logo: oidcProviderInfos.logo,
            providerSourceLabel: $translate.instant(message, {
              providerTitle: getLinkTitle(oidcProviderInfos),
            }),
            unpairAccountBtn: $translate.instant('user.account-update-personal-settings.unpair-account-button', {
              providerTitle: oidcProviderInfos.title,
            }),
          };
        });
    }

    function getLinkTitle(oidcProviderInfos) {
      if (oidcProviderInfos.homePage) {
        return '<a href="' + oidcProviderInfos.homePage + '" target="_blank">' + oidcProviderInfos.title + '</a>';
      }
      return oidcProviderInfos.title;
    }

    function isOidcUser(user) {
      return Boolean(user.providerId && !user.franceConnect);
    }

    function isFromProvider(user) {
      return Boolean(user.providerId || user.franceConnect);
    }

    function isFranceConnect(user) {
      return Boolean(user.franceConnect);
    }

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'user/user-directives/account-personal-informations/account-personal-informations.html',
      scope: {
        viewConfiguration: '=',
        mdm: '=',
        masterdata: '=',
        user: '=?',
      },
      link: function (scope) {
        // Init kendo config for birthdate property
        scope.birthdatePickerConfig = {
          min: '01/01/1900',
          max: new Date(),
          culture: 'fr-FR',
        };

        let observe;
        if (userSessionService.getUser()) {
          scope.userPersonalInformations = angular.copy(scope.user || userSessionService.getUser()).getCleanEntity();
          observe = jsonpatch.observe(scope.userPersonalInformations);
          scope.isOidcUser = isOidcUser(scope.userPersonalInformations);
          scope.isFromProvider = isFromProvider(scope.userPersonalInformations);
          scope.isFranceConnect = isFranceConnect(scope.userPersonalInformations);
        }

        // Keep compatibility with mdm and masterdata sources, the priority is masterdata
        if (scope.masterdata && scope.masterdata.titresCivilites) {
          scope.civilitesOptions = 'civilite.title as civilite.title for civilite in civilites';
          scope.civilites = scope.masterdata.titresCivilites.items;
        } else if (scope.mdm && scope.mdm.civilites) {
          scope.civilitesOptions =
            'civilite.expand.libelle.value as civilite.expand.libelle.value for civilite in civilites';
          scope.civilites = scope.mdm.civilites.array;
        } else {
          scope.civilites = [];
        }

        if (scope.user.providerId) {
          handleOidcAccount(scope);
        } else {
          // Case when the account is OIDC but the user connected with internal authentication (ie: paired account)
          accountManagementService.getAccount(scope.user.userName, scope.mdm).then(function (user) {
            if (user.paired === true && user.meta && user.meta.providerId) {
              handleOidcAccount(scope);
            }
          });
        }

        /**
         * Save user settings
         * @param user object to be saved
         */
        scope.modifyUser = function (user) {
          if (
            _.get(scope.userPersonalInformations, 'name.givenName') &&
            _.get(scope.userPersonalInformations, 'name.familyName')
          ) {
            scope.userPersonalInformations.displayName =
              scope.userPersonalInformations.name.givenName + ' ' + scope.userPersonalInformations.name.familyName;
          }

          const patches = jsonpatch.generate(observe);
          accountManagementService
            .patchAccount(user.userName, patches)
            .then(function (result) {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertSuccess('user.account-update-personal-settings.success'),
                'notification-change-settings'
              );
              scope.changeUserForm.$setPristine();
              scope.updateUser(result.data.id);
            })
            .catch(function () {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertError('user.account-update-personal-settings.error'),
                'notification-change-settings'
              );
            });
        };

        /**
         * Update user settings
         * @param userId Id of the user object to be saved
         */
        scope.updateUser = function (userId) {
          accountManagementService.getAccount(userId, scope.mdm).then(function (user) {
            scope.user = user;
            $rootScope.$broadcast(USER_EVENTS.userUpdated);
          });
        };
      },
    };
  },
]);
