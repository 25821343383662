'use strict';
angular.module('user.directives').directive('accountLoginRecover', [
  'accountManagementService',
  'alertsService',
  'validationService',

  function (accountManagementService, alertsService, validationService) {
    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'user/user-directives/account-login-recover/account-login-recover.html',
      scope: {
        viewConfiguration: '=',
      },
      link: function (scope) {
        scope.validationService = validationService;

        scope.recover = {
          email: null,
        };

        /**
         * Recover the email of an user
         */
        scope.recoverEmail = function () {
          accountManagementService.recoverEmail(scope.recover.email).then(
            function success() {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertSuccess('user.account-recover-informations.login-forgotten-confirmation', {
                  email: scope.recover.email,
                }),
                'notification-recover-email'
              );
              scope.accountLoginRecoverForm.$setPristine();
              scope.recover.email = null;
            },
            function error() {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertError('user.account-recover-informations.login-forgotten-unknown'),
                'notification-recover-email'
              );
            }
          );
        };
      },
    };
  },
]);
