angular
  .module('portailDepotDemandeAide.dashboard')
  .controller('dashboardOffresStageRecapitulatifController', function($scope, offreStage, piecesConfiguration) {
    $scope.offreStage = offreStage;
    $scope.pieces = $scope.offreStage.pieces;
    $scope.viewConfiguration = piecesConfiguration;
    $scope.urlDocuments = $scope.offreStage._links['mgs:documents'].href;
    // Directory of file's icons
    $scope.urlFileIcons = './resources/images/file-icons/';
    $scope.recapitulatif = 'connected.dashboard.offresStage.recapitulatif.';

    $scope.adresse =
      (_.get($scope.offreStage, 'adresse.postale.LigneUne')
        ? $scope.offreStage.adresse.postale.LigneUne + '<br/>'
        : '') +
      (_.get($scope.offreStage, 'adresse.postale.LigneDeux')
        ? $scope.offreStage.adresse.postale.LigneDeux + '<br/>'
        : '') +
      (_.get($scope.offreStage, 'adresse.postale.LigneTrois')
        ? $scope.offreStage.adresse.postale.LigneTrois + '<br/>'
        : '') +
      (_.get($scope.offreStage, 'adresse.postale.LigneQuatre')
        ? $scope.offreStage.adresse.postale.LigneQuatre + '<br/>'
        : '') +
      (_.get($scope.offreStage, 'adresse.codePostal') ? $scope.offreStage.adresse.codePostal : '') +
      (_.get($scope.offreStage, 'adresse.ville') ? ' ' + $scope.offreStage.adresse.ville : '');
  });
