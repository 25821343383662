/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name file-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A basic file field, usable for dynamic forms. The binded data is an array of files.
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 *
 * @example
 *
 *   `<file-field id="file1" name="pieces" model="message.pieces" view-configuration='contactConfiguration'></file-field>`
 *
 */
angular.module('form.directives').directive('fileField', function() {
  'use strict';

  return {
    restrict: 'EA',
    replace: false,
    templateUrl: 'form/form-directives/file-field/file-field.html',
    scope: {
      model: '=',
      config:'=configuration',
      ns: '=namespace',
      viewConfiguration: '=',
      name: '@',
      bare: '=',
      remoteValidation: '=?'
    },
    link: function(scope) {
      scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
      scope.namespace = scope.ns || _.get(scope, 'viewConfiguration.ns');
      scope.required = scope.configuration.required;  // Needed for validation-field

      // Read remote validation from directive, configuration or viewConfiguration
      scope.remoteValidation = scope.remoteValidation !== undefined ? scope.remoteValidation :
        scope.configuration.remoteValidation !== undefined ? scope.configuration.remoteValidation :
          _.get(scope, 'viewConfiguration.remoteValidation') !== undefined ? _.get(scope, 'viewConfiguration.remoteValidation') : false;

      scope.model = scope.model || [];
    }
  };
});