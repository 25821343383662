angular.module('common.model').factory('LocalizedText', function() {
  'use strict';

  function LocalizedText(data) {
    data = data || {};
    this.value = data.value || '';
  }

  return LocalizedText;
});
