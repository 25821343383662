class AidesFilterController {
  constructor(aidesService, AideSearch, AidesFilterConfiguration, AidesConstant, JustificationsConstant, $translate) {
    this.aidesService = aidesService;
    this.AideSearch = AideSearch;
    this.configuration = AidesFilterConfiguration;
    this.aidesConstant = AidesConstant;
    this.justificationsConstant = JustificationsConstant;
    this._translate = $translate;
  }

  $onInit() {
    // Map one item with multiple status and with avis
    // This is for on going instruction "En cours d'instruction"
    this.mapOnGoingInstruction = {
      search: [
        this.aidesConstant.demandeStatuts.ACCEPTABLE,
        this.aidesConstant.dossierStatuts.INSTRUCTION,
        this.aidesConstant.dossierStatuts.FININSTRUCTION,
      ],
      mapAvis: { [this.aidesConstant.dossierStatuts.FININSTRUCTION]: this.configuration.avis.FAVORABLE },
    };

    // This is for on ended instruction "Instruction terminée"
    this.mapEndedInstruction = {
      search: [
        this.aidesConstant.demandeStatuts.UNACCEPTABLE,
        this.aidesConstant.dossierStatuts.FININSTRUCTION_DEFAVORABLE,
        this.aidesConstant.dossierStatuts.FININSTRUCTION,
      ],
      mapAvis: { [this.aidesConstant.dossierStatuts.FININSTRUCTION]: this.configuration.avis.DEFAVORABLE },
    };

    // Filters
    this.searchText = '';
    this.searchStatus = [];
    this.searchTeleservice = [];
    this.searchOperatorExercice = this.configuration.operators[0];

    // Data list for kendo multi select
    this.statusList = [];
    this.teleservicesList = [];

    const ctrl = this;

    // Only some status here
    const allJustifStatus = this.justificationsConstant.onGoingJustificationStatuts;
    this.aidesService.getStatusAndTeleservices().then((res) => {
      // Status list
      let dataSourceStatus;
      try {
        const widgetStatus = $('#kendo-status-filter').getKendoMultiSelect();
        dataSourceStatus = widgetStatus.dataSource;
      } catch (e) {
        // For test purpose
        dataSourceStatus = null;
      }

      const statuses = (res.status || []).sort();
      statuses.forEach((statut) => {
        const title = ctrl._translate.instant('connected.dashboard.aides.list.state-' + statut);
        let statutObj = {
          title: title,
          value: statut,
        };
        // Check if status name already in list because we don't want duplicate
        const notAlreadyIn = _.findIndex(ctrl.statusList, (st) => st.title === title) === -1;
        // check justificationOnly
        const addItem =
          notAlreadyIn && ((ctrl.justificationOnly && allJustifStatus.includes(statut)) || !ctrl.justificationOnly);
        if (addItem) {
          ctrl.statusList.push(statutObj);
          if (dataSourceStatus) {
            // add in kendo list
            dataSourceStatus.add(statutObj);
          }
        }
      });

      // teleservices list
      let dataSourceTeleservice;
      try {
        const widgetTeleservice = $('#kendo-teleservice-filter').getKendoMultiSelect();
        dataSourceTeleservice = widgetTeleservice.dataSource;
      } catch (e) {
        // For test purpose
        dataSourceTeleservice = null;
      }

      const teleservices = (res.teleservices || []).sort();
      teleservices.forEach((teleservice) => {
        const teleserviceObj = {
          title: teleservice,
          value: teleservice,
        };
        ctrl.teleservicesList.push(teleserviceObj);
        if (dataSourceTeleservice) {
          dataSourceTeleservice.add(teleserviceObj);
        }
      });
    });

    this.aideSearch = new this.AideSearch({
      text: this.searchText,
      status: this.searchStatus,
      teleservice: this.searchTeleservice,
      operatorExercice: this.searchOperatorExercice.value,
    });
  }

  /**
   * Update exercice operator filter and retrieve demandes
   * @param {object} op
   */
  changeExerciceOperatorFilter(op) {
    this.searchOperatorExercice = op;
    if (!this.searchOperatorExercice.value) {
      delete this.dateExercice;
    }
    this.searchOnChange();
  }

  /**
   * Retrieve demandes after entering a text in the searchbar
   * @param {object} event
   */
  reloadSearch(event) {
    if (event && event.key === 'Enter') {
      this.searchOnChange();
    }
  }
  /**
   * Retrieve demandes if operators and date of a filter are both defined or undefined
   */
  searchOnChange() {
    const isExerciceFilterValid = this._isFilterValid(this.searchOperatorExercice, this.dateExercice);

    let statusList = this.searchStatus;
    // If justifOnly and empty status list, we set default status
    if (this.justificationOnly && statusList.length === 0) {
      statusList = this.justificationsConstant.onGoingJustificationStatuts;
    }

    // Check if we have a special status, with a map
    let onGoingInstruction = false;
    let endedInstruction = false;
    let mapAvis = null;
    _.forEach(statusList, (status) => {
      // Check the map for instructions: if one selected status is in a map array, we add all others status in same array.
      if (_.includes(this.mapOnGoingInstruction.search, status)) {
        onGoingInstruction = true;
      }
      if (_.includes(this.mapEndedInstruction.search, status)) {
        endedInstruction = true;
      }
    });
    // Set all status (out of loop)
    if (onGoingInstruction) {
      statusList = statusList.concat(this.mapOnGoingInstruction.search);
      mapAvis = this.mapOnGoingInstruction.mapAvis;
    }
    if (endedInstruction) {
      statusList = statusList.concat(this.mapEndedInstruction.search);
      mapAvis = this.mapEndedInstruction.mapAvis;
    }
    // If both are selected, we cancel the mapAvis
    if (onGoingInstruction && endedInstruction) {
      mapAvis = null;
    }

    // Remove duplicates
    statusList = _.uniq(statusList);

    const newSearch = new this.AideSearch({
      text: this.searchText,
      status: statusList,
      teleservice: this.searchTeleservice,
      operatorExercice: this.searchOperatorExercice.value,
      dateExercice: this.dateExercice,
    });

    if (isExerciceFilterValid && !_.isEqual(this.aideSearch, newSearch)) {
      this.aideSearch = newSearch;
      this.aideSearch.mapAvis = mapAvis;
      this.search({ aideSearch: this.aideSearch });
    }
  }

  _isFilterValid(operator, value) {
    return (!operator.value && !value) || (operator.value && value);
  }
}

angular.module('aides').component('aidesFilter', {
  templateUrl: 'aides/aides-components/aides-filter/aides-filter.component.html',
  controller: /* @ngInject */ AidesFilterController,
  bindings: {
    search: '&',
    justificationOnly: '<',
  },
});
