/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name date-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A basic date field for dynamic forms.
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 *   Uses kendo-ui datePicker directive
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 * @param {object} datePickerConfig - Customised config of kendo datePicker
 *
 * @example
 *
 *   `<date-field name="dateNaissance" view-configuration="identificationDemandeurConfiguration" data="demandeur"></date-field>`
 *
 */

angular.module('form.directives').directive('dateField', ['dateUtils', '$timeout',

  function(dateUtils, $timeout) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/date-field/date-field.html',
      require: '^form',
      scope: {
        name: '@',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=',
        bare: '=',
        datePickerConfig: '=',
        isoString: '=',
        readOnly: '=',
        dateOnly: '=',
        remoteValidation: '=?',
        labelClass: '=?'
      },
      link: function(scope) {

        scope.forceRefresh = true;
        var watches = [];
        var timer;

        // Convert date string from json to Date (except if dateOnly mode, we need to keep date string format)
        if (!scope.dateOnly && typeof(scope.model) === 'string' && !scope.isoString) {
          scope.model = moment(scope.model).toDate();
        }

        watches.push(scope.$watch('[config, viewConfiguration]', function() {
          scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
          scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');

          // Read remote validation from directive, configuration or viewConfiguration
          scope.remoteValidation = scope.remoteValidation !== undefined ? scope.remoteValidation :
            scope.configuration.remoteValidation !== undefined ? scope.configuration.remoteValidation :
              _.get(scope, 'viewConfiguration.remoteValidation') !== undefined ? _.get(scope, 'viewConfiguration.remoteValidation') : false;
        }, true));

        function getDisplayModel(dateModel) {
          var displayModel = null;
          if(_.isDate(dateModel)){
            if(scope.isoString){
              displayModel = kendo.toString(dateModel, 'yyyy-MM-dd');
            }else{
              displayModel = kendo.toString(dateModel, 'u');
            }
          }

          return displayModel;
        }

        // Indicates if the model was deleted from this directive
        var modelDeleted = false;

        watches.push(scope.$watch('model', function() {
          var dateModel = scope.model ? moment(scope.model).toDate() : null;
          scope.dateField = {
            displayModel: getDisplayModel(dateModel),
            kModel: dateModel,
            datePickerConfig: scope.datePickerConfig ?
              scope.datePickerConfig : {
                format: 'd',
                culture: 'fr-FR'
              }
          };

          // Min & max based on configuration.restrictions
          dateUtils.setMinRestriction(scope.configuration.restrictions, scope.dateField);
          dateUtils.setMaxRestriction(scope.configuration.restrictions, scope.dateField);

          // Min & max based on configuration.restrictions.minDuration && configuration.restrictions.maxDuration
          if(scope.configuration && scope.configuration.restrictions) {
            scope.configuration.restrictions.filter(function(restriction){
              return (restriction.hasOwnProperty('maxDurationInclusive') ||
                restriction.hasOwnProperty('minDurationInclusive') ||
                restriction.hasOwnProperty('maxDurationExclusive') ||
                restriction.hasOwnProperty('minDurationExclusive'));
            }).forEach(function(restriction){
              dateUtils.setDurationRestriction(restriction, scope.dateField);
            });
          }

          // dirty dirty ! force refreshing of the kendo-ui directive so that configuration modifications are applied.
          if (!modelDeleted) {
            scope.forceRefresh = false;
            timer = $timeout(function() {
              scope.forceRefresh = true;
              scope.$digest();
            }, 1);
          }
          else {
            modelDeleted = false;
          }

        }, true));

        watches.push(scope.$watch('dateField.kModel', function() {
          if (scope.dateField && scope.dateField.kModel !== null) {
            if (scope.dateOnly) {
              scope.model = moment(scope.dateField && scope.dateField.kModel).format('YYYY-MM-DD');
            } else {
              scope.model = scope.isoString ? scope.dateField.kModel.toISOString() : scope.dateField.kModel;
            }
          }
        }));

        watches.push(scope.$watch('dateField.displayModel', function() {
          // Delete model when field has been emptied
          if (scope.dateField && !scope.dateField.displayModel && scope.model) {
            delete scope.model;
            modelDeleted = true;
          }
        }));

        scope.validateDateField = function() {
          // Case where the field was modified but not its model, it means that kendo rejected its format
          if (scope.dateField && scope.dateField.displayModel && !scope.dateField.kModel) {
            return false;
          }
          // Case where the value doesn't respect min and max constraints. Possible if the user doesn't use the datePicker.
          if (scope.dateField && scope.dateField.datePickerConfig.max && scope.model > scope.dateField.datePickerConfig.max) {
            return false;
          }
          if (scope.dateField && scope.dateField.datePickerConfig.min && scope.model < scope.dateField.datePickerConfig.min) {
            return false;
          }
          return true;

        };

        scope.$on('$destroy', function() {
          $timeout.cancel(timer);
          _.each(watches, function(watch) {
            watch();
          });
        });
      }
    };
  }
]);
