angular.module('portailDepotDemandeAide.depot').controller('depotSimpleBeneficiaireAdresseController', [
  '$scope',
  'StoreService',
  function($scope, StoreService) {
    'use strict';

    $scope.demandeur = StoreService.demandeur.get();
    $scope.beneficiaire = StoreService.beneficiaire.get();
    $scope.situationPrincipale = _.find($scope.beneficiaire.situations, { principale: true });

    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.adresseBeneficiaireConfiguration.ns;
    $scope.navigate.next = function() {
      StoreService.beneficiaire.set($scope.beneficiaire);

      const personnaliteJuridiqueMorale =
        _.get($scope.beneficiaire, 'famille.expand.personnaliteJuridique') === 'MORALE';
      const nextStep = personnaliteJuridiqueMorale ? 'beneficiaire-representant-legal' : 'beneficiaire-complementaire';
      $scope.saveBeneficiaire().then(() => $scope.goToStep(nextStep));
    };

    $scope.adresseBeneficiaireConfiguration.showErrors = $scope.showErrorsOnNavigate();
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'beneficiaire';
  },
]);
