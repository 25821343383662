/*eslint angular/document-service: 0 */
//jscs:disable requireCamelCaseOrUpperCaseIdentifiers
/*jshint camelcase:false */

angular.module('aides.services').factory('aidesService', aidesService);

aidesService.$inject = [
  '$http',
  '$q',
  '$rootScope',
  '$location',
  'jsonpatch',
  'Aide',
  'Tiers',
  'configuration',
  'urlService',
  'cmisService',
  'tiersService',
  'teleservicesService',
  'groupesGestionService',
  'bourseService',
  '$sce',
  'jwtSessionService',
  '$log',
  'alertsService',
  '$translate',
];

function aidesService(
  $http,
  $q,
  $rootScope,
  $location,
  jsonpatch,
  Aide,
  Tiers,
  configuration,
  urlService,
  cmisService,
  tiersService,
  teleservicesService,
  groupesGestionService,
  bourseService,
  $sce,
  jwtSessionService,
  $log,
  alertsService,
  $translate
) {
  'use strict';

  let _tenantId;
  let _url = _.get(configuration, 'aides.url') || '/gestion-depot-demandes';
  let _ressourcePath = _.get(configuration, 'aides.ressourcePath') || 'aides';
  let savingAide = false;

  // Configure either at provider level or directly service
  this.tenantId = function(tenantId) {
    _tenantId = tenantId;
  };
  this.url = function(url) {
    _url = url;
  };

  function _getUrl() {
    return _tenantId ? _url + '/' + _tenantId : _url;
  }

  /** TODO this function is temporary
   * It allows to request tiers separately in order to obtain the correct version from referentiel-tiers
   * @param aide: aide containing tiers (demandeur or beneficiaire)
   * @param path: path to tiers in aide
   * @param expands: array of expands to add to the tiers
   * @param requests: array of promises to feed
   */
  function expandTiers(aide, path, expands, requests, mdm) {
    // If tiers is registered in referentiel-tiers, simply request it with expands
    if (_.get(aide, path + '.href')) {
      requests.push(
        $http
          .get(_.get(aide, path + '.href'), {
            params: {
              expand: expands.join(','),
            },
          })
          .then(function(response) {
            // Write it in aide
            const tiers = new Tiers(response.data, mdm);
            _.set(aide, path + '.expand', tiers);
            return tiers;
          })
          .catch(function() {
            // return null si erreur http pour ne pas planter le traitement
            return;
          })
      );
    }
    // Otherwise (if it's directly in demande), we have to call expands one by one
    else {
      _.each(expands, function(tiersProperty) {
        const dataHref = _.get(aide, path + '.expand.' + tiersProperty + '.href');
        if (dataHref) {
          requests.push(
            $http.get(dataHref).then(function(response) {
              // Write in aide
              _.set(aide, path + '.expand.' + tiersProperty + '.expand', response.data);
              return response.data;
            })
          );
        }
      });
    }
  }

  /**
   * Fonction de récupération des expands liés aux documents de l'aide
   * @param newAide
   * @param oldAide
   */
  function gererExpandDocuments(newAide, oldAide) {
    if (!_.isEmpty(newAide.pieces) && !_.isEmpty(oldAide.pieces)) {
      _.map(oldAide.pieces, function(oldPiece) {
        const piecePersistance = _.find(newAide.pieces, function(newPiece) {
          return newPiece.reference === oldPiece.reference;
        });
        if (piecePersistance && !_.isEmpty(piecePersistance.documents) && !_.isEmpty(oldPiece.documents)) {
          _.map(oldPiece.documents, function(oldDocument) {
            const documentPersistence = _.find(piecePersistance.documents, function(newDocument) {
              return newDocument.id === oldDocument.id;
            });
            if (documentPersistence) {
              // Dans le cas ou on a perdu les properties (expand) du document lors de la sauvegarde, on les rajoute dans le nouveau document
              const newPropertiesDocument = _.get(documentPersistence, 'expand.properties');
              const oldPropertiesDocument = _.get(oldDocument, 'expand.properties');
              if (
                oldPropertiesDocument &&
                !_.isEmpty(oldPropertiesDocument) &&
                (!newPropertiesDocument || _.isEmpty(newPropertiesDocument))
              ) {
                _.set(documentPersistence, 'expand.properties', oldPropertiesDocument);
              }
            }
          });
        }
      });
    }
  }

  /**
   * Get expands from pieces documents to put them on planFinancement documents
   * @param newAide
   * @param oldAide
   */
  function gererExpandPlanFinancementDocuments(newAide, oldAide) {
    // retrieve old documents and make it unique
    const oldDocuments = new Set(JSONPath('$.pieces[*].documents[?(@.expand)]', oldAide));

    // planFinancement documents
    const newPlanFinancementDocuments = JSONPath('$..depense..documents[*]', newAide.planFinancement || []);

    // use .size because it's a Set
    if (oldDocuments.size && newPlanFinancementDocuments.length) {
      oldDocuments.forEach((oldDocument) => {
        // get all documents with the same id
        const newDocuments = newPlanFinancementDocuments.filter((doc) => doc.id === oldDocument.id);

        newDocuments.forEach((document) => {
          // In the case we lost expand on document when saving aide, we add them in the new document
          const newPropertiesDocument = _.get(document, 'expand.properties');
          const oldPropertiesDocument = _.get(oldDocument, 'expand.properties');
          if (
            oldPropertiesDocument &&
            !_.isEmpty(oldPropertiesDocument) &&
            (!newPropertiesDocument || _.isEmpty(newPropertiesDocument))
          ) {
            _.set(document, 'expand.properties', oldPropertiesDocument);
          }
        });
      });
    }
  }

  /**
   * Evalue la condition complémentaire paramétrée sur un téléservice pour l'avis préalable d'une aide
   *
   * @param {Object} conditionComplementaire Condition complémentaire paramétrée sur le téléservice
   * @param {Object} aide Aide déposée
   * @returns {Object} TRUE si la condition est absente ou remplie, FALSE sinon
   */

  function evaluerConditionComplementaireAvisPrealable(conditionComplementaire, aide) {
    // Pas de condition complémentaire : fin
    if (_.isEmpty(conditionComplementaire)) {
      return true;
    }

    const scope = $rootScope.$new();
    // Allow moment.js to be available in scope, so isPieceVisible or isPieceRequired function can evaluate conditions based on date
    scope.moment = moment;
    scope._ = _;

    // Evaluation de la condition complémentaire
    return scope.$eval(conditionComplementaire, aide);
  }

  function manageExpandTiers(params, tiersRequests) {
    const expand = params.expand;

    // Regex used to determine if an expand is on a tiers
    const tiersRegex = tiersRequests.reduce(function(regex, config, index) {
      regex += '(' + config.key + ')' + (index < tiersRequests.length - 1 ? '|' : '');
      return regex;
    }, '');
    if (new RegExp(tiersRegex).test(expand)) {
      // split expand for each requests
      const expandAide = [];
      _.each(expand.split(','), function(expandPart) {
        _.each(tiersRequests, function(reqConfig) {
          // If the expand is on a tiers, it belongs to its request
          if (new RegExp('^' + reqConfig.key + '(\\.expand)?\\.').test(expandPart)) {
            reqConfig.expand.push(expandPart.replace(new RegExp('^' + reqConfig.key + '(\\.expand)?\\.'), ''));
          }
        });
        // Otherwise, it's on the aide request
        if (!new RegExp(tiersRegex).test(expandPart)) {
          expandAide.push(expandPart);
        }
      });

      params.expand = expandAide.join(',');
    }
  }

  function get(uri, mdm, params) {
    const config = {
      params: params,
    };

    // TODO This is a temporary solution
    // Since referentiel-financement use tiers 2.19, we extract the expands to call directly referentiel-tiers
    const tiersRequests = _.map(['demandeur', 'beneficiaires', 'mandataires'], function(tiersKey) {
      // Requests for demandeur, beneficiaires, mandataires
      return {
        key: tiersKey,
        active: new RegExp(tiersKey).test(params.expand),
        expand: [],
      };
    });

    manageExpandTiers(config.params, tiersRequests);

    // Request aide
    return $http.get(uri, config).then((response) => {
      const aide = new Aide(response.data, mdm);

      // TODO temporary
      // Request tiers from referentiel-tiers
      const requests = [];
      _.each(tiersRequests, function(requestConfig) {
        if (requestConfig.active) {
          // Demandeur need only one request, others are multiple
          if (!_.isArray(aide[requestConfig.key])) {
            expandTiers(aide, requestConfig.key, requestConfig.expand, requests, mdm);
          } else {
            _.each(aide[requestConfig.key], function(beneficiaire, index) {
              expandTiers(aide, requestConfig.key + '.' + index, requestConfig.expand, requests, mdm);
            });
          }
        }
      });

      return $q.all(requests).then(function() {
        return aide;
      });
    });
  }

  /** generate aide href */
  function getAideHref(reference) {
    return `${_getUrl()}/${_ressourcePath}/${reference}`;
  }

  /**
   * retreive the aide by reference
   * @param {*} reference
   */
  function getAide(reference) {
    return $http.get(getAideHref(reference));
  }

  /**
   * Manage action after aide saved, used by aide and contribution service
   * * this method mutates the aide parameter
   * @param {Object} aide the current aide
   * @param {Object} aideUpdated the updated aide
   * @param {boolean} aideContext true if it's used by aideService
   * @return {void}
   */
  function postUpdateAide(aide, aideUpdated, aideContext) {
    if (aideContext) {
      // Update aide with only added properties (like reference and links)
      const patchs = json_diff(aide, aideUpdated);
      _.each(patchs, function(patch) {
        if (
          patch.op !== 'remove' &&
          patch.value !== undefined &&
          _.get(aide, patch.path.replace(/\//g, '.')) === undefined &&
          patch.value !== null &&
          !_.startsWith(patch.path, '/planFinancement')
        ) {
          _.set(aide, patch.path.replace(/\//g, '.'), patch.value);
        }
      });

      // Display the menu echange on footer after the demande is stored
      $rootScope.displayEchangeMenu = { value: $rootScope.echangesActif, demandeId: aideUpdated.reference };
    }

    // Multifinanceur data are calculated in the API side, so we update the information
    if (_.has(aideUpdated, 'multiFinanceur')) {
      aide.multiFinanceur = _.get(aideUpdated, 'multiFinanceur');
    }
    if (_.has(aideUpdated, 'optionsMultiFinanceur')) {
      aide.optionsMultiFinanceur = _.get(aideUpdated, 'optionsMultiFinanceur');
    }

    if (!_.isEmpty(_.get(aideUpdated, 'planFinancement'))) {
      aide.planFinancement = _.get(aideUpdated, 'planFinancement');
    }

    if (aideUpdated._metadata) {
      aide._metadata = aideUpdated._metadata;
    }
  }

  /**
   * export expand documents from oldAide to newAide
   * @param {object} newAide
   * @param {object} oldAide
   */
  function gererExpandDocumentsAide(newAide, oldAide) {
    // Get expand properties of aide's documents
    gererExpandDocuments(newAide, oldAide);

    // retrieve expands for documents in planFinancement
    gererExpandPlanFinancementDocuments(newAide, oldAide);
  }

  /**
   * Get active decisions for ligne in Plan Financement
   * @param {Object} ligne
   * @returns {Array<Object>}
   */
  function getActiveDecisions(ligne) {
    const decisionOrClotureEvents = _.get(ligne, 'financement.history.events', []).filter(({ type }) =>
      ['DECISION', 'CLOTURE'].includes(type)
    );

    return decisionOrClotureEvents.reduce((result, event, index, events) => {
      if (event.type === 'DECISION' && (!events[index + 1] || events[index + 1].type !== 'CLOTURE')) {
        result.push(event);
      }
      return result;
    }, []);
  }

  /**
   * test if ligne have at least ONE non CLOTURE DECISION in its pf events
   * @param {object} ligneFictive
   * @returns {boolean}
   */
  function isLigneFictiveHaveActiveDecisions(ligneFictive) {
    if (!ligneFictive) throw new Error('[isLigneFictiveHaveActiveDecisions] - Missing aide param');

    return getActiveDecisions(ligneFictive).length > 0;
  }

  return {
    /**
     * Get the aides associated with this user
     */

    // TODO : suppression du filtre du tiers car un utilisateur créateur de la demande doit le voir
    getAides: function(expand, skip, top) {
      expand = expand || [];

      const config = {
        params: {},
      };

      // Si definition de la pagination, on l'ajoute à la query
      if (!_.isNil(skip) && !_.isNil(top)) {
        config.params.$top = top;
        config.params.$skip = skip;
      }

      if (!_.isEmpty(expand)) {
        config.params.$expand = expand.join(',');
      }

      return $http
        .get('/aides/api/tenants/' + _.get(configuration, 'tenant.id') + '/demandes-financement', config)
        .then(function(response) {
          return response.data;
        });
    },

    /**
     * Used to retrieve aides with filters
     * @param {AideSearch} aideSearch the search object
     * @param {Number} skip offset where the search begins
     * @param {Number} top
     * @param {Array} expand
     */
    searchAides(aideSearch, skip, top, expand) {
      let config = {};

      // Set pagination if exists
      if (!_.isNil(skip) && !_.isNil(top)) {
        config.$skip = skip;
        config.$top = top;
      }

      if (!_.isEmpty(expand)) {
        config.$expand = expand.join(',');
      }

      config.aideSearch = aideSearch;
      return $http
        .post('/aides/api/tenants/' + _.get(configuration, 'tenant.id') + '/demandes-financement-search', config)
        .then((response) => response.data)
        .catch((rejection) => {
          $log.error(
            `An error occurred trying to recover the demandes with filters from request with message : ${rejection.data}`
          );
          $rootScope.$broadcast('alerts', alertsService.getAlertError($translate.instant('common.error.happened')));
          throw new Error(`[aides-services] - searchAides - caused by: ${rejection.data}`);
        });
    },

    /**
     * Used to retrieve all status and teleservices I have
     */
    getStatusAndTeleservices() {
      return $http
        .get('/aides/api/tenants/' + _.get(configuration, 'tenant.id') + '/demandes-financement-status-teleservices')
        .then(function(response) {
          return response.data;
        })
        .catch((rejection) => {
          $log.error(
            `An error occurred trying to recover status and teleservices from request with message : ${rejection.data}`
          );
          $rootScope.$broadcast('alerts', alertsService.getAlertError($translate.instant('common.error.happened')));
          throw new Error(`[aides-services] - getStatusAndTeleservices - caused by: ${rejection.data}`);
        });
    },

    /**
     * Retrieve decisions history for a specific demande
     * If dossierReference is specified, then filter decisions for this dossier
     * @param {string} reference Aide reference
     * @param {string} [dossierReference] Dossier reference
     * @returns {Promise<Array>}
     */
    getDecisionsHistory: function(reference, dossierReference) {
      const url =
        '/aides/api/tenants/' +
        _.get(configuration, 'tenant.id') +
        '/' +
        _ressourcePath +
        '/' +
        reference +
        '/decision-history';

      return $http({ method: 'GET', url: url }).then(function(resp) {
        const decisions = resp.data || [];
        return !dossierReference
          ? decisions
          : decisions.filter(
              ({ dossierFinancement }) =>
                _.get(dossierFinancement, 'href', '')
                  .split('/')
                  .pop() === dossierReference
            );
      });
    },

    /**
     * Make the automatic prise-en-charge if conditions are fullfilled
     * this means,
     * tiers.status = SUPPORTED
     * agent administrator asigned to linkedUsers as OWNER
     * @param {*} demandeur tiers demandeur
     * @param {*} teleservice teleservice of the aide (used to check if the tiers should be created with SUPPORTED status)
     * @param {*} tenantId id of the current tenant
     */
    priseEnChargeAuto: function(demandeur, teleservice, tenantId) {
      // If the teleservice is well configured and the tiers matches all conditions,
      // Then the new tiers is sent in 'SUPPORTED' status

      // we need the last version of teleservice to fullfill some conditions
      return teleservicesService.getTeleService(teleservice.reference).then((lastTeleservice) => {
        return tiersService
          .canCreateSupportedTiers(demandeur, teleservice, lastTeleservice)
          .then((canCreateSupportedTiers) => {
            if (canCreateSupportedTiers) {
              // we remove previous owner (the actual user)
              const linkedUsersArray = demandeur.linkedUsers
                .filter((linkedUser) => linkedUser.form !== 'OWNER')
                .map((item) => {
                  // we clean invalid properties (not in contract)
                  delete item.expand;
                  return item;
                });
              const adminLinkedUsers = {
                href: `/account-management/${tenantId}-agents/users/administrator`,
                title: 'Traitement AUTOMATIQUE',
                form: 'OWNER',
              };
              // and we add the new OWNER (agent 'administrator')
              // this user is used as agent for prise-en-charge-auto feature
              linkedUsersArray.push(adminLinkedUsers);

              const patches = [
                { op: 'replace', path: '/status', value: 'SUPPORTED' },
                { op: 'replace', path: '/linkedUsers', value: linkedUsersArray },
              ];

              return tiersService.patchTiers(demandeur.reference, patches);
            } else {
              return demandeur;
            }
          });
      });
    },

    /**
     * Check if an avis préalable is necessary for the aide
     * * this method mutates the aide parameter
     * @param {Object} aide
     * @param {Object} contribution
     * @returns {Object}
     */
    determinerAvisPrealable: (aide, contribution) => {
      return $q((resolve) => {
        // we don't have to check for avisPrealable if contribution isn't a redirection
        if (contribution && contribution.typeContribution !== 'REDIRECTION') return resolve(aide);

        // Default status : TRANSMITTED (no avisPrealable)
        if (!contribution && aide.status !== 'WAITING_FOR_CERTIFICATE') {
          aide.status = 'TRANSMITTED';
        }

        // Get avisPrealables configuration of the teleservice
        const typeWorkflow = contribution
          ? _.get(contribution, 'teleservice.workflow.type')
          : _.get(aide, 'teleservice.expand.workflow.type');
        const avisPrealableTeleservice = contribution
          ? _.get(contribution, `teleservice.workflow.${typeWorkflow}.avisPrealable`)
          : //! teleservice isn't always using typeWorkflow in his path
            _.get(aide, `teleservice.expand.workflow.${typeWorkflow}.avisPrealable`) ||
            _.get(aide, `teleservice.expand.workflow.avisPrealable`);

        // "Avis préalable" isn't enabled on the teleservice
        if (!avisPrealableTeleservice || !avisPrealableTeleservice.actif) return resolve(aide);

        // Get the "Tiers connu" on the teleservice if there is one
        const tiersConnu = avisPrealableTeleservice.tiers;
        const tiersConnuHref = tiersConnu && tiersConnu.href;

        // The "Tiers pour avis préalable" on the teleservice is a "Tiers connu"
        if (tiersConnuHref) {
          // The "Tiers connu" isn't the demandeur
          if (tiersConnuHref !== aide.demandeur.href) {
            // The additional condition of the teleservice isn't filled
            if (
              evaluerConditionComplementaireAvisPrealable(
                _.get(avisPrealableTeleservice, 'conditionComplementaire'),
                aide
              )
            ) {
              // The "Tiers connu" is designated as a "Contributeur pour avis préalable" on the aide
              aide.avisPrealables = [
                {
                  tiers: {
                    title: tiersConnu.title,
                    href: tiersConnuHref,
                    mail: tiersConnu.mail,
                  },
                },
              ];
              aide.status = 'REGISTERED';
            }
          }

          return resolve(aide);
        }

        // The "Tiers pour avis préalable" is the "chef de file" on the aide contrat
        const contratHref = _.get(aide, 'contrat.href');

        // No contrat on the aide
        if (!contratHref) return resolve(aide);

        $http.get(contratHref).then((response) => {
          const contrat = response.data;

          // The aide has a contrat
          if (contrat) {
            const chefDeFile = contrat.chefDeFile;
            const chefDeFileHref = chefDeFile && chefDeFile.href;

            // The contrat has a "chef de file"
            if (chefDeFileHref) {
              // The "chef de file" isn't the demandeur
              if (chefDeFileHref !== aide.demandeur.href) {
                // The additional condition of the teleservice isn't filled
                if (
                  evaluerConditionComplementaireAvisPrealable(
                    _.get(avisPrealableTeleservice, 'conditionComplementaire'),
                    aide
                  )
                ) {
                  // The "chef de file" is designated as a "Contributeur pour avis préalable" on the aide
                  aide.avisPrealables = [
                    {
                      tiers: {
                        title: chefDeFile.title,
                        href: chefDeFileHref,
                        mail: chefDeFile.mail,
                      },
                    },
                  ];
                  aide.status = 'REGISTERED';
                }
              }
            }
          }

          return resolve(aide);
        });
      });
    },

    /**
     * Valid an aide
     * @param demandeur demandeur of the aide
     * @param newAide aide to create
     * @param modificationAttestation boolean set to true when validated with a signataire account
     * @param teleservice teleservice of the demande
     * @param tenantId tenant id
     */
    validAide: function(demandeur, newAide, modificationAttestation, teleservice, tenantId) {
      newAide = urlService.deleteBaseUrlInEntity(configuration.baseUrl, newAide);

      const aide = new Aide(newAide);

      if (aide.status !== 'WAITING_FOR_CERTIFICATE') {
        aide.status = 'TRANSMITTED';
      }

      if (modificationAttestation) {
        const userAdministrateurDemande = _.find(aide.linkedUsers, { form: 'ADMINISTRATOR' });
        userAdministrateurDemande.href = _.get(aide, 'user.href');
        userAdministrateurDemande.title = _.get(aide, 'user.title');
      }

      // remove metadatas used only for the depot process
      _.unset(aide, 'history.begin.metadata.searchTypeDemandeur');
      _.unset(aide, 'history.begin.metadata.searchTypeBeneficiaire');
      _.unset(aide, 'history.begin.metadata.representantLegal');
      _.unset(aide, 'history.begin.metadata.representantLegalBeneficiaire');

      return (
        tiersService
          .getCurrentTiers({}, 'famille')
          // Returns the tiers of the user if exists and create demandeur tiers as the tiers of the user otherwise
          .then((linkedTiers) => {
            return linkedTiers || this.createDemandeur(demandeur, aide);
          })
          .then((demandeur) => {
            // if needed, we make the prise-en-charge-auto
            return this.priseEnChargeAuto(demandeur, teleservice, tenantId);
          })
          .then((demandeur) => {
            aide.demandeur = {
              rel: 'tiers',
              title: tiersService.getTitleTiers(demandeur),
              href: demandeur.id,
            };

            return this.createBeneficiaires(modificationAttestation, aide, demandeur);
          })
          .then(([beneficiaire]) => {
            // to be sure of our patch generation, we get the last version of the tiers
            return $http.get(beneficiaire.href).then(({ data }) => data);
          })
          .then((beneficiaire) => {
            const observer = jsonpatch.observe(beneficiaire);

            this.createMissingPiecesOnTiers(aide, beneficiaire);

            return this.copyAideDocumentsOnTiers(beneficiaire, aide).then(() => {
              const patches = jsonpatch.generate(observer);
              if (!_.isEmpty(patches)) {
                return tiersService.patchTiers(beneficiaire.reference, patches);
              }
            });
          })
          .then(() => this.determinerAvisPrealable(aide))
          .then(() => {
            if (aide.status === 'TRANSMITTED') {
              return this.updateDemandeurGroupesGestion(aide);
            } else {
              return aide;
            }
          })
          .then((aide) => this.saveAide(aide))
          .then((savedAide) => {
            aide.reference = savedAide.reference;
            aide._links = savedAide._links;
            aide.title = savedAide.title;
            return aide;
          })
      );
    },

    /**
     * Add or update an aide
     * @param {object} aide Aide
     * @return {object} Aide
     */
    saveAide: function(aide) {
      // we lock concurrent calls
      if (savingAide) return $q.reject('[saveAide] - there is another call in progress');

      savingAide = true;
      try {
        if (!_.get(aide, 'contrat.href')) {
          _.unset(aide, 'contrat');
        }
        if (aide.reference) {
          const oldAide = angular.copy(aide);
          return this.update(aide).then((updateAide) => {
            postUpdateAide(aide, updateAide, true);

            // Manually update _metadata
            if (_.has(updateAide, '_metadata')) {
              _.set(aide, '_metadata', updateAide._metadata);
            }

            gererExpandDocumentsAide(updateAide, oldAide);

            return updateAide;
          });
        } else {
          const aideCleaned = aide.getCleanEntity(true);
          return this.create(aideCleaned).then((newAide) => {
            // get the new teleservice href since we moved on a revision
            aide.teleservice.href = newAide.teleservice.href;

            postUpdateAide(aide, newAide, true);

            return newAide;
          });
        }
      } catch (error) {
        return $q.reject('[saveAide] - an error has occurred the registration has failed');
      } finally {
        savingAide = false;
      }
    },

    /**
     * Remove an aide
     * @param aide
     * @returns {HttpPromise}
     */
    remove: function(aide) {
      return $http.delete(_getUrl() + '/' + _ressourcePath + '/' + aide.reference);
    },

    list: function(mdm, expand) {
      expand = _.isEmpty(expand) ? '' : '?expand=' + expand;
      return $http.get(_getUrl() + '/' + _ressourcePath + expand).then(function(response) {
        return _.map(response.data, function(aideData) {
          return new Aide(aideData, mdm);
        });
      });
    },

    /**
     * Get an aide
     * @param reference
     * @param mdm
     * @param expand
     * @returns {*}
     */
    get: function(reference, mdm, expand) {
      const uri = getAideHref(reference);
      return get(uri, mdm, { expand: expand || '' });
    },

    /**
     * Get an aide merged with contribution
     * @param {*} reference
     * @param {*} referenceContribution
     * @param {*} mdm
     * @param {*} expand
     */
    getAideWithContribution: function(reference, referenceContribution, mdm, expand) {
      const uri = getAideHref(reference);

      const params = {
        contribution: referenceContribution,
      };

      if (expand) {
        params.expand = expand;
      }

      return get(uri, mdm, params);
    },

    /**
     * Get the href of an aide by reference
     * @param {*} reference
     */
    getAideHref: getAideHref,

    getRecap: function(reference, mdm, params) {
      params.expands = params.expands || 'pieces.documents,domiciliationBancaire.pieces.documents';

      // this method is called to display the "recapitulatif" on the aide and contribution screen
      const url = `/aides/api/tenants/${_.get(
        configuration,
        'tenant.id'
      )}/${_ressourcePath}/${reference}/recapitulatif`;

      return $http
        .get(url, {
          params: params,
        })
        .then(function(response) {
          return new Aide(response.data, mdm);
        });
    },

    /**
     * Get an aide by is reference
     */
    getAide: getAide,

    /**
     * Get aides by references
     * @param {*} references
     */
    getAidesByReference: function(references) {
      if (_.isEmpty(references)) {
        return [];
      }

      if (!Array.isArray(references)) {
        references = [references];
      }

      // reference to promise
      const getAides = _.map(references, (reference) => getAide(reference));

      return $q.all(getAides).then((res) => _.map(res, 'data'));
    },

    /**
     * Get aide with expands eventually merged with a contribution
     * @param {*} href
     * @param {*} expand
     * @param {*} contribution
     */
    getAideExpanded: (href, expand = '', contribution) => {
      if (!href || typeof href !== 'string') return $q.reject('getAideExpanded - the href must be a non empty string');
      const query = {
        url: href,
        method: 'GET',
        params: {},
      };

      if (contribution) query.params.contribution = contribution.reference;
      if (expand) query.params.$expand = expand;

      return $http(query).then(function(response) {
        return response.data;
      });
    },

    create: function(aide, mdm) {
      return $http.post(_getUrl() + '/' + _ressourcePath, aide).then(function(response) {
        return new Aide(response.data, mdm);
      });
    },
    update: function(aide, mdm) {
      // Récupération d'un objet aide nettoyé
      const aideEntity = aide.getCleanEntity(true);
      const url = _getUrl() + '/' + _ressourcePath + '/' + aide.reference;

      const config = { params: {} };
      if (aide.status === 'WAITING_FOR_CERTIFICATE') {
        const linkMail = $location.absUrl().split('connecte')[0] + 'connecte/dashboard/attestations/mesAttestations';
        config.params.attestationsURL = linkMail;
      }

      return $http.put(url, aideEntity, config).then(function(response) {
        return new Aide(response.data, mdm);
      });
    },

    copyDocumentDomiciliationBancaireTiers: function(baseUrlFolder, documentPiece, kind, entity) {
      return cmisService.copyDocument(baseUrlFolder, documentPiece, kind, entity);
    },

    /**
     * Patch de la liste des pièces sur une aide
     */
    patchPieces: function(reference, pieces) {
      const patches = [
        {
          op: 'add',
          path: '/pieces',
          value: pieces,
        },
      ];

      return $http.patch(_getUrl() + '/' + _ressourcePath + '/' + reference, patches).then(function(response) {
        return response.data;
      });
    },
    /**
     * Copie les documents de l'aide comme au tiers sur le tiers si le document n'existe pas
     *
     * @param {Object} aide
     * @param {Object} tiers
     * @param {Object} piecesModifie
     * @return Retourne le tiers avec les documents modifiés
     */
    copyTiersDocuments: function(aide, tiers, piecesModifie) {
      const copyDocumentsOnTiers = [];
      _.each(tiers.pieces, function(tiersPiece) {
        tiersPiece.documents = tiersPiece.documents || [];
        // Pièces typé structure sur l'aide d'origine
        const piecesAideTiersOrigin = _.find(aide.pieces, {
          reference: tiersPiece.reference,
        });
        // Pièces typé structure sur les pièces
        let piecesAideTiers = _.find(piecesModifie, {
          reference: tiersPiece.reference,
        });

        // On filtre la liste des documents pour ne garder que les nouveaux documents
        piecesAideTiers = _.filter(_.get(piecesAideTiers, 'documents'), function(docAide) {
          return !_.find(_.get(piecesAideTiersOrigin, 'documents'), function(docAideOrigin) {
            return docAideOrigin.id === docAide.id;
          });
        });

        // Copy all documents from the aide for this piece on the tiers
        // If we reference a document from 'porte-document' is href contains 'tiers'
        _.each(piecesAideTiers, function(newDocumentAide) {
          const docTiersExist = _.find(tiersPiece.documents, function(document) {
            const doc = _.get(document, "expand.properties['cmis:objectId'].value");
            const docAide = _.get(newDocumentAide, "expand.properties['cmis:objectId'].value");
            return doc === docAide;
          });
          if (
            _.includes(newDocumentAide.href, '/aides/') &&
            !_.includes(newDocumentAide.origin, 'tiers') &&
            !_.includes(newDocumentAide.origin, 'root?objectId') &&
            !docTiersExist
          ) {
            // Copy du document ajouté
            const documentTiers = angular.copy(newDocumentAide);
            copyDocumentsOnTiers.push(
              cmisService
                .copyDocument(cmisService.getBaseUrl() + '/tiers/' + tiers.reference, documentTiers, 'tiers', tiers)
                .then(function(newDocumentsTiers) {
                  newDocumentAide.origin = newDocumentsTiers.href;
                  tiersPiece.documents.push(newDocumentsTiers);
                })
            );
          }
        });
      });

      return $q.all(copyDocumentsOnTiers).then(function() {
        return tiers;
      });
    },

    /**
     * Get caracteristiques-sociales
     */
    getCaracteristiquesSociales: function(type) {
      const config = {
        params: {
          $top: 100,
        },
      };

      // Restriction sur le type
      config.params.$filter = "actif eq true and typeCaracteristiqueSociale/href eq '" + type.href + "'";

      return $http.get(_getUrl() + '/valeurs-caracteristique-sociale', config).then(function(response) {
        const result = _.get(response, 'data._embedded.items');
        const listFiltree = [];
        _.each(result, function(val) {
          const newVal = {
            href: val.id,
            title: val.libelle,
          };
          listFiltree.push(newVal);
        });
        return listFiltree;
      });
    },

    /**
     * Get contracts
     */
    getContracts: function(typeContracts, searchText) {
      const config = {
        params: {
          $top: 10000,
        },
        headers: { 'Cache-Control': 'no-cache' },
      };

      // Restriction sur typeContrat
      let filter =
        typeContracts.reduce(
          function(prev, curr, index) {
            return prev.concat(index !== 0 ? " or type/href eq '" + curr.href + "'" : '');
          },
          "statut eq 'PUBLIE' and actif eq true and (type/href eq '" + typeContracts[0].href + "'",
          1
        ) + ')';

      if (!_.isNil(searchText) && searchText !== ' ') {
        filter += " and substringof(title,'" + searchText + "')";
      }

      config.params.$filter = filter;
      config.params.$orderby = 'title';
      config.params.$top = 20;

      return $http
        .get(_getUrl() + '/contrats', config)
        .then(function(response) {
          return _.get(response, 'data._embedded.items');
        })
        .catch(function(err) {
          throw err;
        });
    },

    /**
     * Get sous-thematiques
     */
    getSousThemathiques: function(thematique) {
      const config = {
        params: {
          $top: 100,
        },
      };

      // Restriction sur la thématique
      config.params.$filter = "actif eq true and thematique/href eq '" + thematique.href + "'";

      return $http
        .get(_getUrl() + '/sousThematiques-financement', config)
        .then(function(response) {
          return _.get(response, 'data._embedded.items');
        })
        .catch(function(err) {
          throw err;
        });
    },
    /**
     * @function getPublicSettingsFinancement
     */
    getPublicSettingsFinancement: function() {
      return $http
        .get(_.get(configuration, 'publicSettingsFinancement.service'))
        .then(function(response) {
          return _.get(response, 'data');
        })
        .catch(function(err) {
          throw err;
        });
    },
    /**
     * Patch sur une aide
     *
     * @param {Object} aide
     * @param {Array} patches
     * @return Retourne l'aide modifiée
     */
    patchAide: function(aide, patches) {
      return $http.patch(_getUrl() + '/' + _ressourcePath + '/' + aide.reference, patches).then(function(response) {
        return response.data;
      });
    },

    /**
     * Patch an aide only with is reference
     *
     * @param {Object} aide
     * @param {Array} patches
     * @return the aide modified
     */
    patchAideByReference: function(referenceAide, patches) {
      return $http.patch(getAideHref(referenceAide), patches).then(function(response) {
        return response.data;
      });
    },

    getAttestations: function(from, size) {
      // EU backend used as middleware to Request
      return $http
        .get('/aides/api/tenants/' + _.get(configuration, 'tenant.id') + '/attestations?from=' + from + '&size=' + size)
        .then(function(results) {
          _.each(_.get(results, 'data.hits'), function(aide) {
            aide._source.createdOn = _.get(aide._source, 'history.begin.date');
            _.each(aide._source.history.events, function(event) {
              if (event.reference === 'WAITING_FOR_CERTIFICATE') {
                aide._source.demandeAttestationDate = event.date;
                return false;
              }
            });
          });
          return _.get(results, 'data');
        });
    },
    getPublicSettingsGestionDepotDemandes: function() {
      return $http
        .get(_.get(configuration, 'publicSettingsGestionDepotDemandes.service'))
        .then(function(response) {
          return _.get(response, 'data');
        })
        .catch(function(err) {
          throw err;
        });
    },
    /**
     * Retourne si une aide est pluriannuelle multi-financeur avec mode de préinstruction partagé
     * @param {*} aide aide
     * @param {*} teleservice teleservice
     */
    isCPO: function(aide) {
      return _.get(aide, '_metadata.isCpo');
    },

    /**
     * Returns if an aide is multi-year and from an automatic rollover
     * @param {Object} aide aide
     * @return {boolean}
     */
    isRenewed: (aide) => _.get(aide, '_metadata.isDemandeRenewed', false),

    getPlanFinancementByAnnee: function(planFinancements, annee) {
      const targetPlanFinancement = _.find(planFinancements, ['periode.exercice', annee]);

      return targetPlanFinancement;
    },

    /**
     * Get current planFinancement of the demande-financement :
     *   - if pluriannuelle multi financeur partagé => plan financement of the current exercice
     *   - otherwise the first planFinancement
     * @param {Object} aide aide
     * @return {Object}
     */
    getCurrentPlanFinancement: function(aide = {}) {
      const plansFinancement = aide.planFinancement || _.get(aide, 'expand.planFinancement');
      if (!Array.isArray(plansFinancement) || plansFinancement.length === 0) {
        throw new Error(`getCurrentPlanFinancement - the aide has no 'planFinancement'`);
      }

      const isDemandeRenewed = aide._metadata && aide._metadata.isDemandeRenewed;
      // If the demande-financement has been renewed, we select the planFinancement
      // matching with the current exercice
      const currentYear = isDemandeRenewed
        ? aide.exerciceBudgetaire
        : _.get(aide, 'history.begin.metadata.stepMetadata.exercice');

      let currentPlanFinancement;
      if (currentYear && this.isCPO(aide)) {
        currentPlanFinancement = this.getPlanFinancementByAnnee(aide.planFinancement, currentYear);
      }
      if (!currentPlanFinancement) {
        currentPlanFinancement = aide.planFinancement[0];
      }

      return currentPlanFinancement;
    },

    /**
     * Retrieve the first year of the plan de financement
     * @param {Object} planFinancement the plan de financement
     */
    getFirstYearOfPf: function(planFinancement) {
      if (!Array.isArray(planFinancement) || planFinancement.length === 0)
        throw new Error('planFinancement must be an array!');
      // TODO: refactor this because the following default value for periode.exercice does not make sense
      const exercicesSorted = planFinancement.map((pf) => _.get(pf, 'periode.exercice', 0)).sort((a, b) => a - b);
      const exerciceMin = exercicesSorted[0];
      return exerciceMin;
    },

    /**
     * Retrieve the first plan de financement
     * @param {Object} aide the current demande
     */
    getFirstPlanFinancement: function(aide) {
      const planFinancement = _.get(aide, 'planFinancement');
      if (!planFinancement || !Array.isArray(planFinancement))
        throw new Error(`'planFinancement' must be a valid array`);
      const firstYear = this.getFirstYearOfPf(planFinancement);
      let firstPlanFinancement = _.find(planFinancement, ['periode.exercice', firstYear]);
      // Return planFinancement[0] for retrocompatibilty
      if (!firstPlanFinancement && planFinancement.length > 0) firstPlanFinancement = planFinancement[0];
      return firstPlanFinancement;
    },

    checkExternalCritere: function(critere, teleservice) {
      const tenant = _.get(configuration, 'tenant.id');
      const uri = `/aides/api/tenants/${tenant}/teleservices/${teleservice}/criteres/${critere.reference}/check`;
      const config = {
        params: {
          reponseSaisie: critere.reponseSaisie,
        },
        headers: {
          'X-No-Interceptor': true,
        },
      };
      return $http.get(uri, config).then((response) => {
        return { critere: critere, result: response.data };
      });
    },

    /**
     * Copy document from the aide on each piece of the tiers
     *
     * @param {*} tiers tiers containing the piece where the aide document should be copied
     * @param {*} aide aide containing the documents that should be copied on the tiers
     *
     * @return {Promise} a promise containing the result of the documents copy requests
     */
    copyAideDocumentsOnTiers: function(tiers, aide) {
      const cmisBaseUrl = cmisService.getBaseUrl();
      const documentsCopyPromises = [];

      _.each(tiers.pieces, (tiersPiece) => {
        tiersPiece.documents = tiersPiece.documents || [];

        const aidePiece = _.find(aide.pieces, {
          reference: tiersPiece.reference,
        });

        // Copy all documents from the aide for this piece on the tiers
        // If we reference a document from 'porte-document' is href contains 'tiers'
        const aidePieceDocuments = _.get(aidePiece, 'documents');
        _.each(aidePieceDocuments, (documentAide) => {
          const documentIsFromAideEntity = _.includes(
            _.get(documentAide, 'expand.properties.entity:uri.value'),
            'demandes-financement'
          );
          const documentIsFromTiersOrigin = _.includes(documentAide.origin, 'tiers'); //(e.g. selected in the porte document of the tiers)

          if (documentIsFromAideEntity && !documentIsFromTiersOrigin) {
            const newTiersDocument = angular.copy(documentAide);
            const documentCopyPromise = cmisService
              .copyDocument(`${cmisBaseUrl}/tiers/${tiers.reference}`, newTiersDocument, 'tiers', tiers)
              .then((document) => {
                const documentWithoutExpand = _.omit(document, ['expand']);
                tiersPiece.documents.push(documentWithoutExpand);
              });
            documentsCopyPromises.push(documentCopyPromise);
          }
        });
      });

      return $q.all(documentsCopyPromises);
    },

    /**
     * Create missing missing pieces on tiers
     *
     * Copy aide pieces that are in teleservice models and in the tiers famille
     * but are missing on the tiers
     * (i.e. pieces in the porte document of the tiers which are set on the teleservice)
     *
     * @param {*} aide aide containing the pieces that should be copied on the tiers
     * @param {*} tiers tiers where the pieces are copied
     */
    createMissingPiecesOnTiers: function(aide, tiers) {
      tiers.pieces = (tiers && tiers.pieces) || [];

      const teleservicePiecesModels = _.get(aide, 'teleservice.expand.workflow.pagePieces.modelesPieces');

      _.each(aide.pieces, (piece) => {
        const pieceOnTeleservice = _.find(teleservicePiecesModels, {
          reference: piece.reference,
        });

        if (pieceOnTeleservice) {
          const pieceOnFamille = _.find(_.get(tiers.famille, 'expand.pieces'), {
            reference: piece.reference,
          });
          const pieceOnTiers = _.find(tiers.pieces, {
            reference: pieceOnTeleservice.reference,
          });

          if (pieceOnFamille && !pieceOnTiers) {
            const clonePiece = angular.copy(piece);
            clonePiece.documents = [];
            _.unset(clonePiece, 'conditionAffichage');
            _.unset(clonePiece, 'conditionObligatoire');
            _.unset(clonePiece, 'obligatoireSurRecevabilite');
            tiers.pieces.push(clonePiece);
          }
        }
      });
    },

    /**
     * Create the aide beneficiaires
     *
     * Create the tiers in the beneficiaires property and update that property
     * with the newly created tiers
     *
     * If there is no beneficiaire in that property, fill it with the demandeur
     * since this means that the demandeur should be the beneficiaire
     *
     * @param {*} modificationAttestation boolean set to true when validated with a signataire account
     * @param {*} aide aide containing the beneficiaires that should be created
     * @param {*} demandeur demandeur tiers in case the demandeur is the beneficiaire
     *
     * @return {Promise} the tiers beneficiaires updated property of the aide
     */
    createBeneficiaires: function(modificationAttestation, aide, demandeur) {
      let beneficiairesCreationPromises = [];

      if (!modificationAttestation) {
        beneficiairesCreationPromises = _.map(aide.beneficiaires, (beneficiaire) => {
          const tiersBeneficiaire = beneficiaire.expand;
          tiersBeneficiaire.status = 'TRANSMITTED';
          return tiersService.saveTiersAndThematiques(tiersBeneficiaire);
        });
      }

      return $q.all(beneficiairesCreationPromises).then((beneficiairesCreated) => {
        // /!\ this may be a problem since beneficiairesCreated is empty when
        // modificationAttestation is falsy but was kept as-is on refactor
        const demandeurIsBeneficiaire = beneficiairesCreated.length === 0;

        if (demandeurIsBeneficiaire) {
          aide.beneficiaires = [
            {
              rel: 'tiers',
              title: tiersService.getTitleTiers(demandeur),
              href: demandeur.id,
            },
          ];
        } else {
          aide.beneficiaires = _.map(beneficiairesCreated, function(beneficiaireCreated) {
            return {
              rel: 'tiers',
              title: tiersService.getTitleTiers(beneficiaireCreated),
              href: beneficiaireCreated.id,
            };
          });
        }

        return aide.beneficiaires;
      });
    },

    /**
     * Create the aide demandeur
     *
     * Creates the demandeur and update the demandeur property on the aide with
     * the newly created tiers
     *
     * @param {*} demandeur demandeur tiers
     * @param {*} aide aide where demandeur is the demandeur
     * @return {Promise} promise of the created demandeur tiers
     */
    createDemandeur: function(demandeur, aide) {
      demandeur.status = 'TRANSMITTED';
      demandeur.user = aide.user;
      return tiersService.saveTiersAndThematiques(demandeur);
    },

    /**
     * Update the groupes de gestion of the demandeur
     *
     * Add the groupe de gestion of the bourse as a groupe de gestion secondaire on the tiers if it
     * exists and is not already present on the tiers (the added groupe de gestion is the the groupe
     * de gestion principal of the composante or the etablissement)
     *
     * This provide more flexibility to set the permissions on the tiers by setting permissions
     * for multiple groupes de gestion
     * If an acl grants a role on a tiers for one of its groupe de gestion, the user will have
     * this role granted on the tiers even if other acls deny this role on another groupe de gestion
     *
     * This function only adds new groupes de gestion on the tiers. By doing this it may grant roles
     * to users that were not granted to some users without denying roles to users that previously
     * had them (no need for duplicate groupes de gestion)
     *
     * @param {*} aide aide
     */
    updateDemandeurGroupesGestion: function(aide) {
      if (groupesGestionService.areGroupesGestionActive() && aide.bourse) {
        const getBourse = bourseService.getBourseById(aide.bourse.href);
        const getDemandeur = tiersService.getTiersByReference(aide.demandeur.href.split('/').pop());

        return $q.all([getBourse, getDemandeur]).then(([bourse, demandeur]) => {
          if (!bourse.groupeGestion) {
            return aide;
          }

          const patches = [];

          if (!demandeur.groupesGestion) {
            patches.push({
              op: 'add',
              path: '/groupesGestion',
              value: [bourse.groupeGestion],
            });
          } else if (!_.some(demandeur.groupesGestion, { href: bourse.groupeGestion.href })) {
            patches.push({
              op: 'add',
              path: '/groupesGestion/-',
              value: bourse.groupeGestion,
            });
          }

          if (patches.length > 0) {
            return tiersService.patchTiers(demandeur.reference, patches).then(() => aide);
          } else {
            return aide;
          }
        });
      } else {
        return $q.resolve(aide);
      }
    },
    /**
     * Generate demandes-report table iframe src
     * @param {String} referenceDemande
     */
    generateDemandeReportTableIframeSrc: (referenceDemande) => {
      const templateDemandesReportTableIframeSrc = `${configuration.aides.v2.url}/<%= demandeId %>/demandesReport/table?jwtKey=<%= jwtKey %>`;
      const compiledDemandesReportTableIframeSrc = _.template(templateDemandesReportTableIframeSrc);
      const key = jwtSessionService.getJwtKey();

      const demandesReportTableIframeSrc = compiledDemandesReportTableIframeSrc({
        demandeId: referenceDemande,
        jwtKey: key,
      });

      return $sce.trustAsResourceUrl(demandesReportTableIframeSrc);
    },

    /**
     * Generate demandes-report saisie iframe src
     * @param {String} referenceDemande
     */
    generateDemandeReportSaisieIframeSrc: (referenceDemande) => {
      const templateDemandesReportSaisieIframeSrc = `${configuration.aides.v2.url}/<%= demandeId %>/demandesReport/saisie?jwtKey=<%= jwtKey %>`;
      const compiledDemandesReportSaisieIframeSrc = _.template(templateDemandesReportSaisieIframeSrc);
      const key = jwtSessionService.getJwtKey();

      const demandeReportSaisieIframeSrc = compiledDemandesReportSaisieIframeSrc({
        demandeId: referenceDemande,
        jwtKey: key,
      });

      return $sce.trustAsResourceUrl(demandeReportSaisieIframeSrc);
    },

    postUpdateAide: postUpdateAide,

    gererExpandDocumentsAide,

    /**
     * Find aide.statut for financeur principal
     * @param {*} aide
     */
    statutForFinanceurPrincipal(aide) {
      const currentPlanFinancement = this.getCurrentPlanFinancement(aide);
      const lignes = JSONPath('$.recette...lignes.*', currentPlanFinancement);

      const groupesGestionFinanceurPrincipal = _.get(aide, 'financeurPrincipal.groupesGestion', []);

      const ligneFinanceurPrincipal = _.find(lignes, (ligne) => {
        const groupesGestionLigne = _.get(ligne, 'financement.financeur.groupesGestion', []);

        // Intersection of both groupGestions array, performed on object's hrefs
        var commonGroups = _.intersectionWith(
          groupesGestionFinanceurPrincipal,
          groupesGestionLigne,
          (a, b) => a.href === b.href
        );

        return commonGroups.length > 0;
      });

      const status = _.get(ligneFinanceurPrincipal, 'financement.statut');

      return status || aide.status;
    },
    /**
     * test if aide ligne fictive have at least one non CLOTURE DECISION favorable
     * @param {object} aideLigneFictive
     * @returns {boolean}
     */
    isAideHaveAtLeastOneActiveDecisionFavorable(aideLigneFictive) {
      if (!aideLigneFictive) throw new Error('[isAideHaveAtLeastOneActiveDecisionFavorable] - Missing aide param');

      const validDecisions = getActiveDecisions(aideLigneFictive);
      return validDecisions.some(({ avis }) => avis === 'FAVORABLE');
    },

    isLigneFictiveHaveActiveDecisions,
    /**
     * Check if the user has the right to access the current demande for depot
     * with the tiers he has selected
     *
     * @param {object} demande demande (needs to have demandeur expanded)
     * @param {object} userSelectedTiers tiers selected by the user
     * @returns {boolean} true if the user can acces depot for the demande
     */
    canAccessDepotForDemande(demande, userSelectedTiers) {
      const isInCreationProcess = demande.status === 'REQUESTED';
      // Get the status from the expanded demandeur
      // because the injected "demandeur" might not be the same and be the selected tiers
      const expandedDemandeur = _.get(demande, 'demandeur.expand') || {};
      const demandeurExists = expandedDemandeur.id;
      const demandeurKnown = _.includes(tiersService.TIERS_KNOWN_STATUSES, expandedDemandeur.status);
      const demandeurIsSelectedTiers = userSelectedTiers && expandedDemandeur.id === userSelectedTiers.id;

      const canAccessDepotForThisDemande =
        !demandeurExists || (demandeurKnown && demandeurIsSelectedTiers) || !userSelectedTiers;

      return !isInCreationProcess || canAccessDepotForThisDemande;
    },
  };
}
