/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name validation-form
 * @restrict A
 * @description
 *
 *   A form with some added behaviour.
 *
 *   Will add a 'attempted' boolean on the form's controller.
 *
 *   Will set focus to the first field in error when form submission fails because of some invalidity.
 *
 * @param {function} validationForm - The function to run when the form is submitted and valid.
 *
 * @example
 *
 *   `<form name="userCreateForm" novalidate validation-form="createUser()">...</form>`
 *
 */

angular.module('form.directives').directive('validationForm', ['$parse', '$log', '$window',
  function($parse, $log, $window) {
    'use strict';

    return {
      restrict: 'A',
      replace: true,
      transclude: false,
      link: function(scope, formElement, attributes) {

        var watches = [];
        // Initialisation de la variable à l'instanciation de la directive
        var formName = attributes.name;
        if (formName) {
          scope[formName].attempted = false;
        } else {
          $log.error('validationForm : The attribute \'name\' must be define.');
        }

        // A parent controller can "spy" on the scope state by giving an object to fill
        if (attributes.formWatch) {
          var watcherName = attributes.formWatch;
          watches.push(scope.$watch(formName + '.$valid', function() {
            scope[watcherName].attempted = scope[formName].attempted;
            scope[watcherName].$valid = scope[formName].$valid;
            scope[watcherName].$invalid = scope[formName].$invalid;
          }, true));
        }

        // Fonction à exécuter lorsque le formulaire est valide
        var fn = $parse(attributes.validationForm);
        // Fonction à exécuter lorsque le formulaire est invalide
        var fnI = $parse(attributes.invalid);

        formElement.submit(function(event) {

          function formSubmit() {

            if (scope[formName].$valid) {
              scope[formName].attempted = false;
              // Reset dirty, pristine and untouched state on form
              scope[formName].$setPristine();
              scope[formName].$setUntouched();
              if (fn) {
                fn(scope, {
                  $event: event
                });
              }
            } else {
              scope[formName].attempted = true;

              // Sélection du premier champ en erreur (ignore les inputs façades de kendo)
              var firstElementOnError = $('#' + formName + ' .ng-invalid:not(.k-formatted-value):first');

              if (firstElementOnError) {

                // Recupération des coordonnées du premier champ en erreur afin
                // de repositionner l'écran de l'utilisateur dessus.
                // Gestion d'une intégration page simple ou bien iframe
                var positionElement = firstElementOnError.offset();

                if (positionElement) {
                  var giveFocus = function() {
                    // Particular case for kendo widgets that use
                    // 2 inputs element : one hidden and one displayed
                    if (firstElementOnError.hasClass('k-widget')) {
                      firstElementOnError = firstElementOnError.find('input')[0];
                    }
                    // Donner le focus au premier champ invalide
                    firstElementOnError.focus();
                  };

                  var parent;
                  try {
                    parent = $window.parent;
                  } catch (e) {
                    // Happens in case of cross domain iframe integration
                  }

                  if (!parent || parent === $window.self) {
                    return $('html, body').animate({
	                    scrollTop: positionElement.top - 10
	                  }, 'slow', function() {
	                    giveFocus();
	                  });
                  }

                  if (!$window.frameElement) {
                    return $log.warning('forms - validationForm - Iframe element is not named, its offset can not be used to manage scroll');
                  }

                  var globalOffset = $($window.frameElement).offset().top + positionElement.top;

                  // 160 est arbitraire et correspond surtout à l'intégration dans portail-acteurs-eco
                  // TODO: meilleure manière de faire ?
                  parent.scrollTo(0, globalOffset - 160 > 0 ? globalOffset - 160 : 0);
                  giveFocus();
                }
              }

              // Exécute la fonction de formulaire invalide
              if (fnI) {
                fnI(scope, {
                  $event: event
                });
              }
            }
          }
          scope.$apply(formSubmit);
        });

        scope.$on('$destroy', function() {
          _.each(watches, function(watch) {
            watch();
          });
        });
      }
    };
  }
]);
