angular.module('portailDepotDemandeAide.dashboard').controller('dashboardDemandesAidesController', [
  '$scope',
  'configuration',
  'aides',
  'aidesJustifications',
  'contributionsService',
  'aidesService',
  'demandesAidesService',
  'alertsService',
  'tiers',
  '$state',
  '$log',
  '$sce',
  '$translate',
  'publicSettingsFinancement',
  'teleservices',
  'aidesPagination',
  'jwtSessionService',
  '$stateParams',
  'JustificationsConstant',
  function(
    $scope,
    configuration,
    aides,
    aidesJustifications,
    contributionsService,
    aidesService,
    demandesAidesService,
    alertsService,
    tiers,
    $state,
    $log,
    $sce,
    $translate,
    publicSettingsFinancement,
    teleservices,
    aidesPagination,
    jwtSessionService,
    $stateParams,
    JustificationsConstant
  ) {
    'use strict';
    $scope.tiers = tiers;

    // Get the list of teleservices
    const teleservicesCache = _.map(teleservices, (teleservice = {}) => ({
      id: teleservice.id,
      reference: teleservice.reference,
      duplicationDemandeFinancement: teleservice.duplicationDemandeFinancement,
      justifications:
        _.get(teleservice, 'justification.active', false) &&
        Array.isArray(_.get(teleservice, 'justification.teleservices'))
          ? teleservice.justification.teleservices.map(({ href, title, justificationType }) => ({
              href,
              title,
              type: justificationType,
            }))
          : [],
    }));

    const ctrl = this;
    // Search filters
    ctrl.aideSearchMem = null;
    $scope.justificationOnly = $stateParams.justificationOnly;

    $scope.pagination = {
      visible: true,
      totalCount: aidesPagination.itemsTotal,
      itemsPerPage: aidesPagination.itemsPerPage,
      skip: 0,
      top: aidesPagination.itemsPerPage,
      currentPage: $stateParams.page,
    };

    // Accès à l'Extranet V8 : récupération du code du tiers V8 éventuellement corrélé au tiers

    let codeTiersV8;

    if (!_.isEmpty(_.get(tiers, 'correlations'))) {
      const tenantId = _.get(configuration, 'tenant.id');
      const refAppliExterneTiersV8 = '/referentiel-tiers/'.concat(tenantId, '/applicationsExternes/TIERSV8');
      const correlationTiersV8 = _.find(tiers.correlations, { reference: refAppliExterneTiersV8 });

      if (!_.isEmpty(correlationTiersV8)) {
        codeTiersV8 = _.get(correlationTiersV8, 'detail.inputReference');
      }
    }

    // Accès à l'Extranet V8 : construction de l'affichage du lien vers l'Extranet V8 dans le "HTML haut"
    let helpHtmlContent = '';

    if (!_.isEmpty(codeTiersV8) && !_.isEmpty(configuration.liensExternes)) {
      const lienExterneExtranetV8 = _.find(configuration.liensExternes, { type: 'EXTRANETV8' });

      if (!_.isEmpty(lienExterneExtranetV8) && !_.isEmpty(lienExterneExtranetV8.url)) {
        const donneesLienExterneExtranetV8 = {
          urlExtranetV8: lienExterneExtranetV8.url,
          codeTiers: codeTiersV8,
          originAuthentification: 'PDAFRONT',
          jwt: jwtSessionService.jwt(),
        };
        helpHtmlContent = $translate.instant(
          'connected.dashboard.aides.demandesAides.lienExterneExtranetV8',
          donneesLienExterneExtranetV8
        );
      }
    }

    // Handle the justificationOnly mode
    const state = $state.$current;
    state.data = $state.$current.data || {};
    // Update the title and the help text
    if ($scope.justificationOnly) {
      state.data.title = $translate.instant('connected.dashboard.aides.demandesAidesJustificationPage.title');
      helpHtmlContent =
        helpHtmlContent + $translate.instant('connected.dashboard.aides.demandesAidesJustificationPage.help');
      // Default searchMem for pagination if justifOnly
      this.aideSearchMem = this.aideSearchMem || { status: JustificationsConstant.onGoingJustificationStatuts };
    } else {
      state.data.title = $translate.instant('connected.dashboard.aides.demandesAides.title');
      helpHtmlContent = helpHtmlContent + $translate.instant('connected.dashboard.aides.demandesAides.help');
    }

    $scope.helpHtmlContent = $sce.trustAsHtml(helpHtmlContent);
    $scope.hideDemandeDepotPieces = _.get(configuration, 'aides.hideDemandeDepotPieces', false);
    $scope.piecePostRecevabilite = _.get(publicSettingsFinancement, 'piecePostRecevabilite', false);

    // List of demandes, check if justificationOnly
    const defaultAides = aidesJustifications || aides;
    demandesAidesService.getListeDemandes(defaultAides).then(function(displayedDemandes) {
      $scope.setDisplayedDemandes(displayedDemandes);
    });

    /**
     * Function to set displayed demandes
     * @param {Array} displayedDemandes Array of demandes to be displayed
     * @return {void}
     */
    $scope.setDisplayedDemandes = function(displayedDemandes) {
      const demandesAvecSuivi = [];

      const demandesReferences = _.map(displayedDemandes, (demande) => {
        return demande.reference;
      });

      contributionsService.getContributionsRedirectionFromDemandes(demandesReferences).then(({ contributions }) => {
        _.each(displayedDemandes, function(demande) {
          if (!demandesAidesService.isMultiFinanceursWithoutFinanceurPrincipal(demande.aide)) {
            demande.lastDecision = demandesAidesService.getlastDecision(demande.aide);
          }

          demande.dateDepotAvisPrealable = demandesAidesService.getDateDepotAvisPrealable(demande.aide);
          demande.avisPrealableDonne = demandesAidesService.getAvisPrealable(demande.aide);
          demande.aEteTransmise = demandesAidesService.getEtatTransmis(demande.aide);

          // Affichage des informations d'instruction si le mode multi-financeur n'est pas activé en mode partagé
          demande.afficherInformationsInstruction = !(
            _.get(demande, 'aide.multiFinanceur', false) &&
            _.get(demande, 'aide.optionsMultiFinanceur.modePreInstruction') === 'PARTAGE'
          );

          // Affichage du motif de rejet si la demande est au statut rejeté et que le mode multi-financeur n'est pas activé en mode partagé
          demande.afficherMotifRejet =
            demande.state === 'RETURNED' &&
            !(
              _.get(demande, 'aide.multiFinanceur', false) &&
              _.get(demande, 'aide.optionsMultiFinanceur.modePreInstruction') === 'PARTAGE'
            );

          // Covers the case when we have paiements on the demande but then we add avenants and the state changes from VOTE
          // to INSTRUCTION and then FININSTRUCTION; the usager needs to see the status VOTE and have the paiements button visible
          if (demande.state === 'FININSTRUCTION' && demande.teleservicePaiement) {
            // but we don't have to do that for v8 dossier
            if (!demande.hasCorrelationsV8) demande.state = 'VOTE';
          }

          // Getting virtual status of demande with justification
          if (_.has(demande, 'aide.justification.statut')) {
            demande.state = 'JUSTIFICATION_' + demande.aide.justification.statut;
          }

          //if a contribution has the same reference as a demande
          //equivalent to .find(), but IE compatible
          const hasContribution = contributions.filter(function(contrib) {
            return contrib.demande.reference === demande.reference;
          });

          demande.contributionRedirectionActive = hasContribution.length;
          demandesAvecSuivi.push(demande);
        });
        $scope.displayedDemandes = demandesAvecSuivi;
      });
    };

    /**
     * Delete a demande-aide from the displayed list
     * @param  {object} aide Aide
     */
    $scope.removeDemande = function(aide) {
      // Remove from the list, not reloading required
      $scope.displayedDemandes = _.filter($scope.displayedDemandes, function(displayedDemande) {
        return aide.reference !== displayedDemande.reference;
      });
    };

    $scope.changePagination = function(skip, top, currentPage) {
      $scope.changePaginationComponentCfg(skip, top, currentPage);
      $scope.changePage();
    };

    $scope.changePage = function() {
      searchAides();
    };

    $scope.changePaginationComponentCfg = function(skip, top, currentPage) {
      $scope.pagination.top = top;
      $scope.pagination.skip = skip;
      $state.go('.', { page: currentPage });
      $scope.pagination.currentPage = currentPage;
    };

    // Define if justification link can be displayed
    $scope.displayJustificationButton = function(demande) {
      return (
        _.has(demande, 'aide.justification.href') &&
        [JustificationsConstant.JUSTIFICATION_JUSTIFIED, JustificationsConstant.JUSTIFICATION_VALIDATED].indexOf(
          demande.state
        ) !== -1
      );
    };

    /**
     * Get current teleservice for a demande-aides
     * We can't use demande.teleservice.expand because not always up to date
     * @param {Object} demande the demande-aide
     */
    $scope.getTeleserviceWithDemande = function(demande) {
      // Get the teleservice used
      const demandeTeleserviceId = _.get(demande, 'aide.teleservice.expand.id', '');
      // return the full teleservice object
      return _.find(teleservicesCache, (ts) => ts.id === demandeTeleserviceId);
    };

    /**
     * Search aides with filters
     * @param {AideSearch} aideSearch object with filters
     */
    $scope.searchAides = function(aideSearch) {
      //  check if search is necessary
      if (!_.isEqual(ctrl.aideSearchMem, aideSearch)) {
        ctrl.aideSearchMem = aideSearch;
        return searchAides(aideSearch);
      }
    };

    function searchAides(aideSearch = null) {
      if (aideSearch === null) {
        // search reload when change pages
        aideSearch = ctrl.aideSearchMem;
      }
      const currentPage = _.parseInt($scope.pagination.currentPage);
      const itemsPerPage = _.parseInt($scope.pagination.itemsPerPage);
      const skip = (currentPage - 1) * itemsPerPage;
      const expand = [
        'demandesComplementPieces',
        'demandeur',
        'dispositif',
        'teleservice',
        'justification',
        'demandesReport',
      ];

      return aidesService
        .searchAides(aideSearch, skip, itemsPerPage, expand)
        .then((response) => {
          const aides = _.get(response, 'hits');
          _.set($scope, 'pagination.totalCount', _.get(response, 'total'));
          if (itemsPerPage > 0) {
            _.set($scope, 'pagination.currentPage', _.parseInt(skip / itemsPerPage) + 1);
          }

          demandesAidesService.getListeDemandes(aides).then(function(displayedDemandes) {
            $scope.setDisplayedDemandes(displayedDemandes);
          });
        })
        .catch((err) => {
          $scope.alerts = alertsService.getAlertError($scope.viewConfiguration.ns + '.error.generic');
          $log.error(err);
        });
    }
  },
]);
