angular.module('common.model').factory('Piece', function(Document, Link, LocalizedText) {
  'use strict';

  function Piece(data) {
    data = data || {};
    this.reference = data.reference;
    if (data.libelle && _.isNil(data.libelle.value)) {
      data.libelle = {
        value: data.libelle,
      };
    }
    if (data.libelle) {
      this.libelle = new LocalizedText(data.libelle);
    }
    this.actif = data.actif || true;
    if (data.commentaire) {
      this.commentaire = new LocalizedText(data.commentaire);
    }
    if (data.description) {
      this.description = new LocalizedText(data.description);
    }
    this.expiration = data.expiration;
    this.modeTransmission = data.modeTransmission || 'DEPOSEE';
    this.nature = data.nature;
    if (_.has(data, 'conforme')) {
      this.conforme = data.conforme;
    }
    this.fonction = data.fonction;
    this.obligatoire = data.obligatoire;
    this.displayListDocuments = data.displayListDocuments;
    this.envoiPostal = data.envoiPostal;
    this.familles = data.familles;
    this.typesPaiement = data.typesPaiement;
    this.conditionAffichage = data.conditionAffichage;
    this.conditionObligatoire = data.conditionObligatoire;
    this.obligatoireSurRecevabilite = data.obligatoireSurRecevabilite;

    // Documents
    if (Array.isArray(data.documents)) {
      this.documents =
        _.map(data.documents, function(document) {
          return new Link({
            title: document.title,
            expand: new Document(document.expand),
            href: document.href,
            id: document.id,
            rel: document.rel,
            origin: document.origin,
            type: document.type,
          });
        }) || [];
    }

    // Uniquement utilisé dans le cadre d'une demande en multi-financeurs synchrone
    if (_.has(data, 'conformitesParFinanceur')) {
      this.conformitesParFinanceur = data.conformitesParFinanceur;
    }
  }

  Piece.prototype.getCleanEntity = function() {
    var cleanPiece = _.cloneDeep(this);
    delete cleanPiece.actif;
    delete cleanPiece.displayListDocuments;
    delete cleanPiece.envoiPostal;
    delete cleanPiece.familles;
    delete cleanPiece.typesPaiement;

    // Documents
    if (cleanPiece.documents) {
      _.each(cleanPiece.documents, function(document) {
        delete document.expand;
      });
      // Remove empty documents
      _.remove(cleanPiece.documents, function(doc) {
        return doc && doc.href === undefined;
      });
    }

    return cleanPiece;
  };

  return Piece;
});
