// Module declaration
angular.module('portailDepotDemandeAide.depot').component('aideComponent', {
  templateUrl: 'dashboard/aides/aide/aide.component.html',
  controller: /* @ngInject */ AideController,
  transclude: { title: '?aideTitle', children: '?aideChildren' },
  bindings: {
    tiers: '<',
    originDemandeReference: '<',
    demande: '<',
    justificationOnly: '<',
    currentTeleservice: '<',
    hideIcon: '<',
  },
});

function AideController(
  $rootScope,
  $scope,
  $state,
  $log,
  $modal,
  $q,
  $translate,
  demandesAidesService,
  aidesService,
  alertsService,
  JustificationsConstant,
  aidePaiementAuthorizationService,
  suiviFinancementService,
  configuration,
  AidesConstant
) {
  const ctrl = this;
  const AIDE_STATUS = AidesConstant.demandeStatuts;
  const DOSSIER_STATUS = AidesConstant.dossierStatuts;
  const PAIEMENT_STATUS = AidesConstant.paiementStatuts;

  /**
   * Initialize component
   * @returns {void}
   */
  ctrl.$onInit = () => {
    const justificationStatut = `JUSTIFICATION_${_.get(ctrl.demande, 'aide.justification.statut', '')}`;
    const allJustifStatus = Object.values(JustificationsConstant.justificationStatuts);
    ctrl.isJustificationStep = allJustifStatus.includes(justificationStatut);
    ctrl.fromParameterRecapitulatif = ctrl.justificationOnly ? 'suiviJustifications' : null;
    ctrl.isMultiDossiers = _.get(ctrl.demande, 'aide.dossiersFinancement.length', 0) > 1;
    ctrl.displayPaiement = false;
    ctrl.teleservicePaiement = ctrl.demande.teleservicePaiement;
    if (!ctrl.justificationOnly && !ctrl.isMultiDossiers) {
      ctrl.actionsLoading = true;
      ctrl.initPaiementInformations().finally((ctrl.actionsLoading = false));
    }
  };

  /**
   * Init paiement informations
   * 1. display paiement icon authorization
   * 2. up to date teleservicePaiement
   * @returns {Promise<void>}
   */
  ctrl.initPaiementInformations = function() {
    const aide = _.get(ctrl.demande, 'aide');
    // if mono-financeur or multi partage with financeur principal
    if (!demandesAidesService.isMultiFinanceursWithoutFinanceurPrincipal(aide)) {
      const lignePF = suiviFinancementService.getFinancialLine(aide);
      return aidePaiementAuthorizationService
        .canCreateDemandePaiementMonoFinanceur(aide, lignePF)
        .then(({ canCreatePaiement, teleservicePaiement }) => {
          ctrl.canCreatePaiementMonoOrMultiFinanceursPartage = canCreatePaiement;
          ctrl.teleservicePaiement = teleservicePaiement;
        });
    }

    // if not multi-financeur synchronise early return
    if (!_.get(ctrl.demande, 'afficherInformationsInstruction')) return $q.resolve();

    // if multi-financeur synchronise
    return aidePaiementAuthorizationService
      .canCreateDemandePaiementMultiFinanceur(aide, _.get(ctrl.demande, 'teleservicePaiement'))
      .then(({ canCreatePaiement }) => {
        ctrl.canCreatePaiementMultiFinanceursSynchronise = canCreatePaiement;
      });
  };

  /**
   * Show a modal asking for demande-aide deletion confirmation
   * @returns {void}
   */
  ctrl.deleteDemande = function() {
    const scopeModal = $scope.$new();
    scopeModal.persistence = _.get(ctrl.demande, 'aide');
    scopeModal.confirmDeleteDemande = ctrl.confirmDeleteDemande;

    $modal({
      scope: scopeModal,
      template: 'dashboard/aides/modal/modal-delete-demande.html',
    });
  };

  /**
   * Show an error alert
   * @param {Object} error
   * @param {string} error.data
   * @returns {void}
   */
  this.showErrorAlert = ({ data = '' }) => {
    $rootScope.$broadcast(
      'alerts',
      alertsService.getAlertError(`${$translate.instant('common.error.happened')} ${data}`)
    );
  };

  /**
   * Delete a demande-aide
   * @param {Object} modal Modal to hide after the persistence has been deleted
   * @param {Object} aide Aide
   * @returns {Promise<void>}
   */
  this.confirmDeleteDemande = (modal, aide) => {
    modal.$hide();
    return aidesService
      .remove(aide)
      .then(() => {
        $scope.$parent.removeDemande(aide);
        $rootScope.$broadcast(
          'alerts',
          alertsService.getAlertSuccess($translate.instant('connected.dashboard.aides.demandesAides.deleted'))
        );
      })
      .catch((error) => {
        this.showErrorAlert(error);
      });
  };

  /**
   * Duplication of demande-aide
   * @return {Promise<void>}
   */
  this.duplicateDemande = () => {
    const aide = _.get(ctrl.demande, 'aide');
    return demandesAidesService
      .duplicateDemand(aide)
      .then((aideDuplicated) => {
        $state.go('app.connected.config.depot.simple', {
          configurationId: _.get(aide, 'teleservice.expand.reference'),
          p: aideDuplicated.reference,
        });
      })
      .catch((err) => {
        const data = _.get(err, 'data', {});
        if (err.status === 403 && data.error.code === 403.1 && data.error.maxPersistenceNumber) {
          modalMaxNumberPersistenceReach();
        } else {
          if (data.message) {
            $log.error(data.message);
            this.showErrorAlert();
          }
        }
      });
  };

  /**
   * Check if Ajout Piece button should be displayed according to JIRA
   * https://jira.mgdis.fr/browse/PLAID-11055
   * @returns {boolean}
   */
  ctrl.showAjoutPiecesButton = () => {
    // If option is unset on aide or contribution de redirection open and active or demande is clotured, then we hide the button
    const hideDemandeDepotPieces = _.get(configuration, 'aides.hideDemandeDepotPieces', false);
    if (hideDemandeDepotPieces || ctrl.demande.contributionRedirectionActive || ctrl.demande.state === 'CLOTURE') {
      return false;
    }

    const demandeStatus = _.get(ctrl.demande, 'aide.status', ctrl.demande.state);
    const acceptedStatuses = [AIDE_STATUS.REGISTERED, AIDE_STATUS.TRANSMITTED, AIDE_STATUS.SUPPORTED];

    // We check teleservice configuration for depotPiecesApresRecevabilite
    const depotPiecesApresRecevabiliteActive = _.get(
      ctrl.demande,
      'aide.teleservice.expand.workflow.simple.depotPiecesApresRecevabilite'
    );
    if (depotPiecesApresRecevabiliteActive) {
      acceptedStatuses.push(AIDE_STATUS.ACCEPTABLE);
    }

    return acceptedStatuses.includes(demandeStatus);
  };

  /**
   * Check if demande has proper status to display
   * an icon for suivi des demandes de paiement for each financeur
   * @returns {boolean}
   */
  ctrl.verifyStatus = function() {
    const status = ctrl.demande.state;
    return [
      AIDE_STATUS.ACCEPTABLE,
      AIDE_STATUS.UNACCEPTABLE,
      DOSSIER_STATUS.FININSTRUCTION_DEFAVORABLE,
      DOSSIER_STATUS.INSTRUCTION,
      PAIEMENT_STATUS.REFUSE,
      PAIEMENT_STATUS.PROPOSE,
      PAIEMENT_STATUS.LIQUIDE,
      PAIEMENT_STATUS.MANDATE,
      PAIEMENT_STATUS.PAYE,
    ].includes(status);
  };

  /**
   * Check if demande is Cpo
   * @return {boolean}
   */
  ctrl.isCPO = () => aidesService.isCPO(this.demande.aide);

  /**
   * Determines if the duplicate button should be visible or not
   * @return {boolean}
   */
  ctrl.showDuplicateButton = () => {
    const aide = this.demande.aide;
    const contributionRedirectionActive = this.demande.contributionRedirectionActive;
    const isDemandeRenewed = aidesService.isRenewed(aide);

    if (contributionRedirectionActive || isDemandeRenewed) {
      return false;
    }
    // rules https://jira.mgdis.fr/browse/PLAID-13521
    const duplicable = this.currentTeleservice.duplicationDemandeFinancement;

    // The user's account must also be part of the third party applicant's accounts.
    const isUserOwnerOfDemandeur = _.get(this.tiers, 'id') === _.get(aide, 'demandeur.expand.id');

    // The request must have had a SUPPORTED status
    const hasBeenPriseEnCharge = _.some(
      _.get(aide, 'history.events', []),
      ({ type, reference }) => type === 'STATUS' && reference === AIDE_STATUS.SUPPORTED
    );

    // The status of the request must be valid (any status after takeover)
    const isValidStatus = !_.includes(
      [
        'active',
        AIDE_STATUS.REQUESTED,
        AIDE_STATUS.REGISTERED,
        AIDE_STATUS.TRANSMITTED,
        AIDE_STATUS.RETURNED,
        AIDE_STATUS.WIP_SUPPORTED,
        AIDE_STATUS.WAITING_FOR_CERTIFICATE,
      ],
      aide.status
    );

    /*
      why to compare the history and ALSO the status of the request?
      A bug was found in the generation of the .history (PLAID-16955), and in the change of
      status in multifunder there was no history of change of status to SUPPORTED. There may
      still be existing requests with a hole in the .history to avoid regressions, also on
      the current status of the request is compared.
      */
    return duplicable && isUserOwnerOfDemandeur && (isValidStatus || hasBeenPriseEnCharge);
  };

  /**
   * Display modal for max persistence number reach
   * @returns {void}
   */
  function modalMaxNumberPersistenceReach() {
    const scopeModal = $scope.$new();
    $modal({
      scope: scopeModal,
      template: 'dashboard/aides/modal/modal-maxnumber-demandes.html',
    });
  }
}
