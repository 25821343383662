angular.module('form.services').factory('mapService', ['$q',

  function($q) {

    'use strict';

    var geoCoder = new google.maps.Geocoder();

    function _getAddress(coordonnees, simple) {

      // Construct search address

      var elements = [
        coordonnees.geographique.PointRemise,
        coordonnees.geographique.CodePostal,
        coordonnees.geographique.Localite.value,
        _.get(coordonnees, 'regionInsee.value', '')
      ];

      // Workaround for DOM-TOM
      // Google maps geocoder don't retreive any result if country 'FRANCE' is set for DOM-TOM
      if (coordonnees.geographique.CodePostal &&
        !_.startsWith(coordonnees.geographique.CodePostal, '97') &&
        !_.startsWith(coordonnees.geographique.CodePostal, '98')) {
        elements.push(coordonnees.geographique.Pays.value);
      }

      // If simple, we don't search the street
      if (simple) {
        elements.splice(0, 1);
      }

      return elements.join(', ');
    }

    function _getLocation(coordonnees, simple) {

      var defer = $q.defer();

      var address = _getAddress(coordonnees, simple);

      geoCoder.geocode({
        'address': address
      }, function(results, status) {

        switch (status) {
          case google.maps.GeocoderStatus.OK:
            var localisation = {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng()
            };
            defer.resolve(localisation);
            break;
          case google.maps.GeocoderStatus.ZERO_RESULTS:
            //If no results, we try a most general address (without street)
            defer.resolve(_getLocation(coordonnees, true));
            break;
          default:
            defer.reject(status);
        }
      });

      return defer.promise;
    }

    return {
      getLocation: _getLocation
    };
  }
]);
