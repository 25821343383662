// Decorate $log to send logs over HTTP
// Inspired by http://statelessprime.blogspot.fr/2013/10/send-logging-and-exceptions-to-server.html

var logHTTP = angular.module('logHTTP', []);

logHTTP.provider('logHTTP', ['$provide', function($provide) {
	'use strict';

	var that = this;

	// Configuration parameters of the provider
	var _url;
	this.setURL = function(url) {
		_url = url;
	};

	var _metadata = {};
	this.setMetadata = function(key, value) {
		_metadata[key] = value;
	};
	this.delMetadata = function(key) {
		delete _metadata[key];
	};

	var _levels = ['debug', 'info', 'warn', 'error'];
	this.setLevels = function(levels) {
		_levels = levels;
	};

	var _level = 'error';
	this.setLevel = function(level) {
		_level = level;
	};

	// reproduce console.log string interpolation in a simplicist manner
	// and merge different levels of log metadata
	function buildLog(level, args) {
		args = [].slice.call(args);

		args = args.map(function(arg) {
			return formatError(arg);
		});

		var i = 1;
		var msg = '';
		var metadata = null;
		if (args[0] && typeof args[0] == 'string') {
			msg = args[0].replace(/%./g, function() {
				return args[i++];
			});
			metadata = args[i];
		} else {
			metadata = args[0];
		}

		var logData = {};

		// Merge the optional global metadata
		angular.extend(logData, _metadata);

		// Merge the optional additional object parameter		
		if (metadata && typeof metadata === 'object') {
			angular.extend(logData, metadata);
		} else if (metadata) {
			logData.metadata = metadata;
		}

		logData.message = msg;
		logData.level = level;
		logData.url = window.location.href;

		return logData;
	}

	// Send a log to a distant server.
	// Use pure XMLHttpRequest for minimal dependency to any library
	function sendLog(data) {
		var req = new XMLHttpRequest();
		req.open('POST', _url, true);
		req.setRequestHeader('Content-Type', 'application/json');
		req.send(JSON.stringify(data));
	}

	// Shared log function.
	function logAll(level, args) {
		var ind = _levels.indexOf(level);
		var minInd = _levels.indexOf(_level);
		if (ind >= minInd) {
			var logData = buildLog(level, args);
			sendLog(logData);
		}
	}

	// duplicated from original angular sources
	// https://github.com/angular/angular.js/blob/master/src/ng/log.js#L3
	// Format an error as some text
	function formatError(arg) {
		if (arg instanceof Error) {
			if (arg.stack) {
				arg = (arg.message && arg.stack.indexOf(arg.message) === -1) ? 'Error: ' + arg.message + '\n' + arg.stack : arg.stack;
			} else if (arg.sourceURL) {
				arg = arg.message + '\n' + arg.sourceURL + ':' + arg.line;
			}
		}
		return arg;
	}

	// Actually decorate $log, using utility functions defined above
	$provide.decorator('$log', ['$delegate', function($delegate) {

		var _debug = $delegate.debug;
		var _info = $delegate.info;
		var _warn = $delegate.warn;
		var _error = $delegate.error;

		$delegate.debug = function() {
			logAll('debug', arguments);
			_debug.apply($delegate, arguments);
		};

		$delegate.info = function() {
			logAll('info', arguments);
			_info.apply($delegate, arguments);
		};

		$delegate.warn = function() {
			logAll('warn', arguments);
			_warn.apply($delegate, arguments);
		};

		$delegate.error = function() {
			logAll('error', arguments);
			_error.apply($delegate, arguments);
		};

		return $delegate;
	}]);

	// The actual injected service only serves to configure metadata after initial config step
	this.$get = [function() {
		return {
			setMetadata: that.setMetadata,
			delMetadata: that.delMetadata
		};
	}];

}]);