angular.module('common.services').factory('themeManagementService', themeManagementService);

/* @ngInject */
function themeManagementService(configuration, $http, $window, $rootScope, $log, $location) {
  'use strict';
  return {
    /**
     * A callback of a $transitions hook. Used to check and register a new theme
     * or favicon to the localStorage.
     * @param {Object} transition transition passed to $transitions hook callbacks
     * @returns {Promise|Void} return a promise if a request is made to referentiel-themes
     */
    registerTheme: (transition) => {
      const params = transition.params();
      if (params.theme) {
        return $http
          .get(`${configuration.referentielThemes.api}/${params.theme}`)
          .then((res) => {
            const themeHref = _.get(res, 'data.theme');
            const faviconHref = _.get(res, 'data.favicon');
            // store theme url if it is defined
            if (themeHref) {
              $window.localStorage.setItem('theme', themeHref);
            } else {
              $window.localStorage.removeItem('theme');
            }
            // store favicon url if it is defined
            if (faviconHref) {
              $window.localStorage.setItem('favicon', faviconHref);
            } else {
              $window.localStorage.removeItem('favicon');
            }
          })
          .catch((err) => {
            // default theme
            $log.error('Could not fetch themes files', err);
            $window.localStorage.removeItem('theme');
            $window.localStorage.removeItem('favicon');
          });
      } else {
        // default theme
        $window.localStorage.removeItem('theme');
        $window.localStorage.removeItem('favicon');
      }
    },
    /**
     * Apply the theme and favicon stored in localStorage
     */
    applyTheme: () => {
      $rootScope.specificFavicon = $window.localStorage.getItem('favicon');

      const specificTheme = $window.localStorage.getItem('theme');
      const refThemeMatch = /^\/referentiel-themes\/(.*)/.exec(specificTheme);
      if (specificTheme && refThemeMatch) {
        $rootScope.specificTheme = specificTheme;
      }
    },
    /**
     * Set the theme query param to the theme stored locally
     */
    themeInParams: () => {
      const theme = $window.localStorage.getItem('theme') ? $window.localStorage.getItem('theme').split('/')[4] : null;
      $location.search('theme', theme);
    },
  };
}
