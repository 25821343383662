angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleBeneficiaireFamilleController', depotSimpleBeneficiaireFamilleController);
depotSimpleBeneficiaireFamilleController.$inject = [
  '$scope',
  '$httpParamSerializer',
  'Tiers',
  'tiersService',
  'configuration',
  'jwtSessionService',
  '$sce',
  'StoreService',
];
function depotSimpleBeneficiaireFamilleController(
  $scope,
  $httpParamSerializer,
  Tiers,
  tiersService,
  configuration,
  jwtSessionService,
  $sce,
  StoreService
) {
  'use strict';

  $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsBeneficiaire || {};

  $scope.cleanNavigate();
  // Manually set $error to form to disable save button at init
  // If we already have a valid tiers we should receive a message from iframe to clear $error
  $scope.depotForm.$error = { invalidTiers: {} };

  // Get the list of familles references in a string
  const restrictionFamillesTiers = _.map($scope.pageOptions.familles, (famille) =>
    famille.famille.href.split('/').pop()
  ).join(',');

  let publicSettingsTiers;

  tiersService.getPublicSettingsTiers().then((publicSettings) => {
    publicSettingsTiers = publicSettings;
  });

  let globalMetadata = _.get($scope, 'aide.history.begin.metadata');

  let beneficiaire = StoreService.beneficiaire.get();

  $scope.referentielTiersIframeUrl = getVerificationSiretIframeUrl(restrictionFamillesTiers);

  // create the beneficiaire if it does not already exist
  if (!beneficiaire) {
    const newBeneficiaire = new Tiers({
      status: 'TEMPORARY',
      user: $scope.aide.user,
    }).getCleanEntity();

    tiersService.saveTiers(newBeneficiaire, $scope.mdm).then((tiers) => {
      beneficiaire = StoreService.beneficiaire.set(tiers);
      $scope.aide.beneficiaires = [
        {
          href: beneficiaire.id,
          title: beneficiaire.title,
          expand: beneficiaire,
        },
      ];
      return $scope.saveAide();
    });
  }

  // Resize iframe
  iFrameResize(
    {
      heightCalculationMethod: 'lowestElement',
      checkOrigin: false,
      inPageLinks: true,
    },
    '#verification-siret-iframe'
  );

  $scope.iframeElement = angular.element('#verification-siret-iframe')[0].contentWindow;

  $scope.navigate.ns = $scope.familleBeneficiaireConfiguration.ns;

  $scope.viewConfiguration = $scope.familleBeneficiaireConfiguration;
  $scope.stepsWizard.steps = $scope.getSimpleSteps();
  $scope.stepsWizard.active = 'beneficiaire';
  window.addEventListener('message', receiveMessageEvent);

  $scope.$on('$destroy', function() {
    window.removeEventListener('message', receiveMessageEvent);
    $scope.depotForm.$error = {};
  });
  $scope.navigate.next = function() {
    // Next step
    const isValid = _.get($scope, 'tiersValidation.valid');
    const adressePrincipale = _.find(beneficiaire.situations, { principale: true });
    const adresseEtrangere = adressePrincipale && adressePrincipale.etrangere;

    const rnaFilled = globalMetadata.searchTypeBeneficiaire === 'RNA';

    const metadatas = {
      sirenReadOnly: !adresseEtrangere && !!_.get($scope, 'tiersFound.SIRET.SIREN'),
      nicEditable: adresseEtrangere || !_.get($scope, 'tiersFound.SIRET.SIREN') || rnaFilled,
      codeRnaReadOnly: rnaFilled,
    };

    if (isValid) {
      $scope
        .saveBeneficiaire()
        .then(() => $scope.goToStep('beneficiaire-identification', undefined, undefined, undefined, metadatas));
    } else {
      $scope.iframeElement.postMessage({ action: 'click-next' }, '*');
    }
  };

  /**
   * Returns the referentiel-tiers iframe url for siret verification
   */
  function getVerificationSiretIframeUrl(familles) {
    const demandeur = StoreService.demandeur.get();
    const teleservice = _.get($scope, 'aide.teleservice.expand.reference');
    const jwtKey = jwtSessionService.getJwtKey();
    const queryParams = {
      theme: 'demandeur',
      usagers: true,
      ref: beneficiaire && beneficiaire.reference,
      boutonSuivant: false,
      jwtKey,
      familles,
      gestionRattachementCompte: false,
      teleservice,
      adresseEtrangere: true,
      nsResources: 'beneficiaire-famille',
      searchType: globalMetadata.searchTypeBeneficiaire,
    };
    if (demandeur.SIRET && demandeur.SIRET.SIREN && demandeur.SIRET.NIC) {
      queryParams.forbiddenSIRET = `${demandeur.SIRET.SIREN}${demandeur.SIRET.NIC}`;
    }
    const url = `${configuration.tiers.ux}#/verificationsiret?${$httpParamSerializer(queryParams)}`;
    return $sce.trustAsResourceUrl(url);
  }

  /**
   * Manage the messages we receive from child frames
   * @param msgEvent
   */
  function receiveMessageEvent(msgEvent) {
    const data = msgEvent.data;
    if (data.action !== 'tiersValidation') {
      return;
    }

    $scope.tiersValidation = data;

    // add/reset error in the form to disable/enable the save button
    $scope.depotForm.$error = data.valid ? {} : { invalidTiers: {} };

    // add search type
    globalMetadata.searchTypeBeneficiaire = data.searchType;

    // override beneficiaire data if the iframe returns a valid tiers
    // (may return valid message without a tiers to avoid overriding beneficiaire on load)
    if (data.valid && data.tiers) {
      $scope.tiersFound = data.tiers;

      const apiEntrepriseActive = _.get(publicSettingsTiers, 'apiEntreprise.active');

      const updatedBeneficiaireFields = { SIRET: data.tiers.SIRET };

      if (apiEntrepriseActive) {
        const thematiquesLiees = _.get(beneficiaire, 'thematiquesLiees', {});
        const newThematiqueAsso = _.get(data, 'tiers.thematiquesLiees.association', {});

        if (!thematiquesLiees.association || !thematiquesLiees.association.expand) {
          _.set(thematiquesLiees, 'association.expand', {});
        }

        const newAssoInfos = newThematiqueAsso.expand || {};
        const oldAssoInfos = thematiquesLiees.association.expand;

        oldAssoInfos.codeRNA = newAssoInfos.codeRNA;
        oldAssoInfos.dateModificationRNA = newAssoInfos.dateModificationRNA;
        oldAssoInfos.datePublicationJO = newAssoInfos.datePublicationJO;
        oldAssoInfos.descriptionActivites = newAssoInfos.descriptionActivites;

        _.assign(updatedBeneficiaireFields, {
          raisonSociale: data.tiers.raisonSociale,
          referenceAdministrative: data.tiers.referenceAdministrative,
          situations: data.tiers.situations,
          NAF: data.tiers.NAF,
          NAFNiv732: data.tiers.NAFNiv732,
          externalData: data.tiers.externalData,
          enseigne: data.tiers.enseigne,
          thematiquesLiees,
        });
      } else {
        const situations = _.get(beneficiaire, 'situations');
        const situationPrincipale = _.find(situations, { principale: true });
        const currentSituationPaysCode = _.get(situationPrincipale, 'geographique.Pays.code');
        const updatedSituationPaysCode = _.get(data.tiers, 'situations[0].geographique.Pays.code');

        // reset situations when domiciliation changes if api entreprise is not active
        if (currentSituationPaysCode !== updatedSituationPaysCode) {
          updatedBeneficiaireFields.situations = data.tiers.situations;
        }
      }

      updatedBeneficiaireFields.sansSIRET = _.get(data.tiers, 'sansSIRET');

      // get the expanded famille from mdm
      // (the expanded version from iframe is missing some informations needed for the depot)
      updatedBeneficiaireFields.famille = _.find(
        $scope.mdm.famillestiers.array,
        (famille) => famille.href === _.get(data.tiers, 'famille.href')
      );

      // add famille pieces to the tiers
      // (not done by the referentiel-tiers middleware because the tiers is created without any famille)
      tiersService.addFamillePiecesToTiers(updatedBeneficiaireFields);

      const newBeneficiaire = new Tiers(_.assign({}, beneficiaire, updatedBeneficiaireFields));

      // call the function of famille change to update the tiers
      tiersService.changeFamilleTiers(newBeneficiaire, null, $scope.mdm, $scope.masterdata);
      beneficiaire = StoreService.beneficiaire.set(newBeneficiaire);
      $scope.aide.beneficiaires[0].expand = beneficiaire;
    }

    // Manually set $error to form to disable/enable save button and then use $scope.$apply()
    // to force angular to trigger watcher because this function isn't called from angularjs
    $scope.$apply();
  }
}
