angular.module('portailDepotDemandeAide.tiersSelection', ['ui.router']);

angular.module('portailDepotDemandeAide.tiersSelection').config([
  '$stateProvider',
  function($stateProvider) {
    'use strict';

    $stateProvider.state('app.connected.tiers-selection', {
      url: '/tiers-selection?redirectTo&redirectParams&redirectFrom',
      params: {
        redirectTo: {
          dynamic: true,
        },
        redirectParams: {
          dynamic: true,
        },
        redirectFrom: {
          dynamic: true,
        },
      },
      component: 'tiersSelection',
      resolve: {
        tiersMine: ($http, configuration) => {
          // we need user tiers list.
          const options = {
            from: 0,
            size: 1,
            select: ['reference'],
          };
          const url = _.get(configuration, 'tiers.url') || '/referentiel-tiers';
          return $http.post(url + '/tiers/mine', options);
        },
      },
    });
  },
]);
