'use strict';
// this directive is used to integrate a small user management widget, usually in the header of a portal

angular.module('user.directives').directive('userAuthentication', [
  'alertsService',

  function (alertsService) {
    return {
      restrict: 'E,A',
      replace: true,
      transclude: false,
      templateUrl: 'user/user-directives/user-authentication/user-authentication.html',
      scope: {
        accountCreationHref: '@',
        accountRecoverHref: '@',
        alerts: '=',
        jwtSessionOnly: '=?',
        autofocus: '=?',
      },
      controller: [
        '$rootScope',
        '$scope',
        'AUTHENTICATION_EVENTS',
        'authenticationService',
        'jwtSessionService',
        function ($rootScope, $scope, AUTHENTICATION_EVENTS, authenticationService, jwtSessionService) {
          $scope.loading = false;

          // We must use a new variable named 'status' for display or not errors on the fields authentication
          // because IE11 has a bug : https://github.com/angular/angular.js/issues/1206
          $scope.fields = {
            login: {
              status: null,
            },
            password: {
              status: null,
            },
          };

          $scope.blurFieldsAuthentication = function (fieldName) {
            if ($scope.formulaireConnexion[fieldName].$invalid) {
              $scope.fields[fieldName].status = 'error';
            } else {
              $scope.fields[fieldName].status = 'success';
            }
          };

          /**
           * Connect the user
           * @param login The user's login
           * @param password The user's password
           */
          $scope.connection = function (login, password) {
            $scope.loading = true;

            if ($scope.jwtSessionOnly) {
              authenticationService = jwtSessionService;
            }

            authenticationService.login(login, password).then(
              function () {
                $rootScope.$broadcast(AUTHENTICATION_EVENTS.loginSuccess, login);
                $scope.loading = false;
              },
              function (error) {
                $rootScope.$broadcast(AUTHENTICATION_EVENTS.loginFailed, error);
                $scope.$broadcast(
                  'alerts',
                  alertsService.getAlertError('user.authentication.error'),
                  'user-authentication-alerts'
                );
                $scope.loading = false;
              }
            );
          };
        },
      ],
    };
  },
]);
