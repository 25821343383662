angular.module('tiers').directive('moyensHumainsTiers', ['$http', '$q', moyensHumains]);

function moyensHumains($http, $q) {
  /** Fieldset for tiers association */
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: 'tiers/tiers-directives/moyens-humains/moyens-humains.html',
    scope: {
      tiers: '=',
      configuration: '=',
      namespace: '=',
    },
    link: function(scope) {
      scope.viewConfiguration = _.merge(
        {
          ns: scope.namespace + '.moyens-humains',
        },
        scope.configuration
      );

      // Create thematiqueRessourcesHumaines if not set
      if (!_.get(scope.tiers, 'thematiquesLiees.ressourcesHumaines.tableauEffectifs.items')) {
        _.set(scope.tiers, 'thematiquesLiees.ressourcesHumaines.tableauEffectifs.items', []);
        _.set(scope.tiers, 'thematiquesLiees.ressourcesHumaines.tableauEffectifs.date', new Date());
      }
      scope.thematiqueRessourcesHumaines = _.get(scope.tiers, 'thematiquesLiees.ressourcesHumaines');

      // EXERCICES
      // Set exercices in thematique (current or previous year)
      scope.exercices = {
        list: [new Date().getFullYear() - 1, new Date().getFullYear()],
      };
      // Initialize exercice with first effectif in thematique, or most recent year
      scope.exercices.selected =
        _.get(scope.thematiqueRessourcesHumaines.tableauEffectifs, 'items.0.exercice') || scope.exercices.list[0];
      // When current exercice is changed, update effectifs
      scope.$watch(
        'exercices.selected',
        function() {
          _.each(scope.thematiqueRessourcesHumaines.tableauEffectifs.items, function(effectif) {
            if (_.isNaN(scope.exercices.selected)) {
              delete effectif.exercice;
            } else {
              effectif.exercice = scope.exercices.selected;
            }
          });
        },
        true
      );
      // Configuration for exercice field
      scope.exerciceConfiguration = _.merge(
        {
          restrictions: [
            {
              minInclusive: scope.exercices.list[0],
              maxInclusive: scope.exercices.list[1],
            },
          ],
        },
        _.get(scope.configuration, 'fields.exercice')
      );

      // EFFECTIFS
      // Get list of typesEffectifs from famille
      var typesEffectifs = _.get(scope.tiers, 'famille.expand.typesEffectifs', []);
      var typesEffectifsReq = _.map(typesEffectifs, function(typeEffLink) {
        return $http.get(typeEffLink.href).then(function(res) {
          return res.data;
        });
      });
      $q.all(typesEffectifsReq).then(function(typesEffectifs) {
        // An array list fields, while an object references values in thematique
        scope.typesEffectifsFields = [];
        scope.effectifsValues = {};

        // Create fields configurations
        _.each(typesEffectifs, function(typeEffectif) {
          if (typeEffectif.active) {
            // Merge configuration with eventual one in teleservice
            var fieldConfig = _.merge(
              {
                reference: typeEffectif.reference,
                label: typeEffectif.title,
                restrictions: [
                  {
                    minInclusive: 0,
                  },
                ],
              },
              _.find(scope.configuration.fields, { reference: typeEffectif.reference })
            );

            scope.typesEffectifsFields.push(fieldConfig);
          }

          // Find or initialize effectif in tiers
          var effectif = _.find(scope.thematiqueRessourcesHumaines.tableauEffectifs.items, {
            cle: typeEffectif.reference,
            exercice: scope.exercices.selected,
          });
          if (!effectif) {
            effectif = {
              cle: typeEffectif.reference,
              libelle: typeEffectif.title,
              exercice: scope.exercices.selected,
            };
            scope.thematiqueRessourcesHumaines.tableauEffectifs.items.push(effectif);
          }
          // Keep a direct reference to the object
          scope.effectifsValues[typeEffectif.reference] = effectif;
        });
      });
    },
  };
}
