/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name file-input
 * @scope
 * @restrict EA
 * @description
 *
 *   A basic file input. For dynamic forms, please use file-field. The binded data is an array of files.
 *
 *   Uses bootstrap to display an input in adequation with the style, and allows to configure the button's label.
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {String} model - The model to bind to
 * @param {string} label - Button's label
 * @param {string} placeholder - Text displayed when no file is selected
 * @param {boolean} required
 * @param {boolean} multiple - Wether the file-input can upload several files or not
 * @param {string} extensionsAllowed - list of extensions allowed
 * @param {string} fileSizeLimit - size limit for each input file
 * @param {string} maxFiles - maximum number of files allowed
 *
 * @example
 *
 *   `<file-input
 *      name="{{name}}"
 *      model="model"
 *      label="{{namespace + '.' + name + '.button' | translate}}"
 *      placeholder="{{field.placeholder}}"
 *      required="field.required"
 *      multiple="field.multiple"
 *      extensionsAllowed=['pdf', 'png']
 *      fileSizeLimit="10485760"
 *      maxFiles="2">
 *    </file-input>`
 *
 */
angular.module('form.directives').directive('fileInput', ['$translate', function($translate) {
  'use strict';

  return {
    restrict: 'EA',
    replace: false,
    templateUrl: 'form/form-directives/file-field/file-input.html',
    scope: {
      model: '=',
      required: '=',
      multiple: '=',
      label: '@',
      name: '@',
      placeholder: '@',
      extensionsAllowed: '=',
      fileSizeLimit: '=',
      maxFiles: '=',
    },
    link: function(scope, element) {
      var watches = [];
      scope.model = scope.model || [];

      // File input are one of the few elements angular doesn't manage well yet, so there's going to be some DOM manipulations
      var input = element.find('input[type="file"]');

      // We have to add the multiple attribute manually, since the input checks only its presence (even with a value of "false")
      watches.push(scope.$watch('multiple', function(active) {
        if (active === true) {
          input.attr('multiple', '');
        }
      }));

      watches.push(scope.$watch('extensionsAllowed', function(extensionsAllowed) {
        if (extensionsAllowed) {
          input.attr('accept', extensionsAllowed.map(function(extension) {
            return '.' + extension;
          }).join(','));
        }
      }));

      // When files are added, we update the model
      input.on('change', function() {
        scope.fileErrors = undefined;
        scope.$apply(function() {
          // Creating a new instance of array would break the binding, so we change the current one
          scope.model.splice(0);
          // Cette ligne ne fonctionne pas sous ie en version 3 de lodash mais c'est corrigé en 4.
          // _.merge(scope.model,element.find('input')[0].files);
          var files = element.find('input')[0].files;

          if(scope.maxFiles && files.length > scope.maxFiles) {
            scope.fileErrors = $translate.instant('form.field.error.file.maxFilesExceeded', {maxFiles: scope.maxFiles});
          }

          files.forEach(function(file) {
            if(scope.extensionsAllowed && !scope.extensionsAllowed.includes(file.name.split('.').pop())) {
              scope.fileErrors = $translate.instant('form.field.error.file.extensionUnauthorized', {
                extensions: scope.extensionsAllowed
              });
            }

            if(scope.fileSizeLimit) {
              if(file.size > scope.fileSizeLimit) {
                scope.fileErrors = $translate.instant('form.field.error.file.fileSizeLimitExceeded', {
                  fileSizeLimit: scope.fileSizeLimit / 1024 / 1024
                });
              }
            }
          })

          if(!scope.fileErrors) {
            Array.prototype.push.apply(scope.model, files);
          }

          // Displaying a list of the registered files ; This list is also used to check the presence of data when the field is required
          scope.names = _.map(scope.model, 'name').join(', ');
        });
      });

      // Note: since the objects inside model are Files taken directly from the DOM, we can't watch them directly
      watches.push(scope.$watch('model.length', function() {
        // Empty names if the model is initialized from the outside
        if (scope.model.length === 0) {
          scope.names = '';
        }
      }));

      scope.$on('$destroy', function() {

        input.off();
        _.each(watches,function (watch) {
          watch();
        });
      });
    }
  };
}]);