angular.module('portailDepotDemandeAide.user').controller('accountRecoverInformationsController', [
  '$scope',
  'userAccountRecoverInformationsConfiguration',
  function($scope, userAccountRecoverInformationsConfiguration) {
    'use strict';

    // Give access the configuration of the view
    $scope.userAccountRecoverInformationsConfiguration = userAccountRecoverInformationsConfiguration;
  },
]);
