/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name textarea-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A basic textarea field for dynamic forms.
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to
 * viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 *
 * @example
 *
 *   `<textarea-field name="dateNaissance" view-configuration="identificationDemandeurConfiguration" data="demandeur"></textare-field>`
 *
 */
angular.module('form.directives').directive('textareaField', ['$log',  '$timeout', 'formUtils',
  function($log, $timeout, formUtils) {
    'use strict';

    return {
      restrict : 'EA',
      replace : true,
      templateUrl : 'form/form-directives/textarea-field/textarea-field.html',
      require: '^form',
      scope : {
        name : '@',
        viewConfiguration : '=',
        config : '=configuration',
        ns : '=namespace',
        model : '=',
        bare : '=',
        displayCharactersLeft: '=?',
        remoteValidation: '=?',
        format : '@',
        readOnly : '=',
        rows : '=',
        labelClass: '=?'
      },
      link : function(scope, element, attrs, formCtrl) {

        scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
        scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');

        scope.format = scope.format || scope.configuration.format;

        scope.charLeft = {
          display: false
        };

        // Read option from directive, configuration or viewConfiguration
        function readGlobalOption(optionName) {
          scope[optionName] = scope[optionName] !== undefined ? scope[optionName] :
            scope.configuration[optionName] !== undefined ? scope.configuration[optionName] :
              _.get(scope, 'viewConfiguration.' + optionName) !== undefined ? _.get(scope, 'viewConfiguration.' + optionName) : false;
        }
        readGlobalOption('displayCharactersLeft');
        readGlobalOption('remoteValidation');

        scope.maxLength = _.get(scope.configuration, 'restrictions[0].maxLength', scope.configuration.maxlength || 4000);
        scope.charactersLeftData = {};
        scope.charactersLeftData.value = scope.maxLength;

        formUtils.wrapPrimitiveInObject(scope, 'model', 'data');

        scope.tools = [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'insertUnorderedList',
          'insertOrderedList',
          'indent',
          'outdent',
          'createLink',
          'unlink',
          'formatting',
          'fontName',
          'fontSize',
          'foreColor',
          'backColor',
          'cleanFormatting'
        ];

        // In this mode, the text is automatically process to remove the unnecessary style
        // And the toobars have less options
        if(scope.configuration.simpleMode === true) {
          scope.tools = [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'insertUnorderedList',
            'insertOrderedList',
            'indent',
            'outdent',
            'createLink',
            'unlink',
            'formatting'
          ];

          // Style management when the data changed
          scope.$watch('model', function(value) {
            if(scope.configuration.richtext !== true) { return; }

            // In this case, the input is a simple text, we must not try to handle it as HTML
            var htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
            if(!htmlRegex.test(value)) {
              return;
            }

            // If the input is a valid HTML, we will handle it and remove unnecessary style
            var $result;
            try {
              $result = $('<div></div>').append($(value));
            } catch(e) {
              $log.warn('textarea-field - failed to parse some content detected as HTML', e.message);
            }
            var editable = element.find('[contenteditable]');
            $(editable).html($result.html());
            // replace all styles except the ones that can be edited in the editor
            $.each($(editable).find('*'), function(idx, val) {
              var $item = $(val);
              if ($item.length > 0) {
                var saveStyle = {
                  'font-weight' : $item.css('font-weight'),
                  'font-style' : $item.css('font-style'),
                  'text-align' : $item.css('text-align'),
                  'text-decoration' : $item.css('text-decoration')
                };
                $item.removeAttr('style').removeClass().css(saveStyle);
              }
            });
            // remove unnecesary tags (if paste from word)
            $(editable).children('style').remove();
            $(editable).children('meta').remove();
            $(editable).children('link').remove();
          });
        }

        // Handle form $dirty and $pristine
        scope.kendoEditorChange = function kendoEditorChange() {
      	formCtrl[attrs.name].$dirty = true;
          formCtrl[attrs.name].$pristine = false;
          formCtrl.$dirty = true;
          formCtrl.$pristine = false;
        };

        //Remove know characters \u2018\u2019 by '
        //Remove special characters (only accepts ISO 8859-15 characters)
        scope.$watch('model', function(newValue) {
          if (newValue) {
            var valueISO885915 = newValue.replace(/[\u2018\u2019] /g, '\'');
            valueISO885915 = valueISO885915.replace(/[^\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178] /g, ' ');
            scope.model = valueISO885915;
          }
          if(scope.displayCharactersLeft) {
            scope.charactersLeftData.value = scope.maxLength - _.get(scope, 'model.length', 0);
          }
        });

        scope.$on('$destroy', function() {
        });

        // Added a timeout to undisplay character left message.
        // This timeout must give to the user time to click on a form button at the bottom of the page.
        // Without this timeout, when the message disappears, the button pull up in the form and the click on the button is not always effective.
        scope.waitUndisplayCharacterLeft = function(){
          $timeout(function () {
            scope.charLeft.display = false;
          }, 100);
        };
      }
    };
  }]);
