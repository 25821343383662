angular.module('form.services').factory('dateUtils', [

  function() {
    'use strict';

    /*
     * Set the minimum and maximum limits of a date field
     * @param border {string} - either 'min' or 'max'
     * @param restrictions {array} - List of restrictions objects
     * @param dateField {object} - date field configuration in the scope
     * @param unit {string} - time unit on which operating calculations : days (default), weeks, months or years
     */
    var setBorderRestriction = function(border, restrictions, dateField, unit) {
      unit = unit || 'days';

      // Finding a restriction containing a parameter [min|max]Inclusive or [min|max]Exclusive
      var borderConstraint = _.find(restrictions, function(constraint) {
        return _.has(constraint, border + 'Inclusive') || _.has(constraint, border + 'Exclusive');
      });

      if (borderConstraint) {
        var limit;
        // Inclusive limit mean that we just have to set the date to the kendo directive
        if (_.has(borderConstraint, border + 'Inclusive')) {
          if (unit === 'years' &&
            borderConstraint[border + 'Inclusive'] &&
            borderConstraint[border + 'Inclusive'].toString().length === 4) {
            limit = new Date(borderConstraint[border + 'Inclusive'], 0, 1);
          } else {
            limit = new Date(borderConstraint[border + 'Inclusive']);
          }
        }
        // On exclusive, though, we have to take the date next to the limit
        else if (_.has(borderConstraint, border + 'Exclusive')) {
          var borderMoment;
          if (unit === 'years' && borderConstraint[border + 'Exclusive'] && borderConstraint[border + 'Exclusive'].toString().length === 4) {
            borderMoment = moment(new Date(borderConstraint[border + 'Exclusive'], 0, 1));
          } else {
            borderMoment = moment(new Date(borderConstraint[border + 'Exclusive']));
          }
          // Next [day] for minimum, previous [day] for maximum
          switch (border) {
            case 'min':
              limit = borderMoment.add(1, unit).toDate();
              break;
            case 'max':
              limit = borderMoment.subtract(1, unit).toDate();
              break;
          }
        }

        // Setting the kendo directive's configuration
        dateField.datePickerConfig[border] = limit;
      }
    };

    /**
     * Gets the duration of the given string duration
     * @param  {string} durationString The duration given in a string format
     * example: P5y2M10d15h means a period of five years, two months, 10 days and 15 hours
     * Prefix the period with "-" to substract
     * @return {Date} a date object corresponding to the durationString parameter
     */
    var getTimeDuration = function(durationString) {
      var duration = moment(new Date());
      var substract = false;

      if (durationString) {
        // Check if there is a + or - before 'P' character, indicating if we have to add the duration
        // to now date, or remove it. No sign will indicate a +
        if (durationString.charAt(0) === '-') {
          substract = true;
          durationString = durationString.substring(1,durationString.length);
        } else if (durationString.charAt(0) === '+') {
          durationString = durationString.substring(1,durationString.length);
        }

        // Now parse, starting with the 'P' character indicating we parse a period
        if (durationString.charAt(0) === 'P') {
          durationString = durationString.substring(1,durationString.length);

          while (durationString.length > 1) {
            // Get first character which is not a number in the durationString
            var cpt = 0;
            while (!isNaN(parseInt(durationString[cpt]))) {cpt++;}
            var value = parseInt(durationString.substring(0, cpt));

            // Add or substract from now date
            duration = substract ?
              duration.subtract(value, durationString.charAt(cpt)) :
              duration.add(value, durationString.charAt(cpt));

            // Switch to next item to parse
            durationString = durationString.substring(cpt + 1,durationString.lenth);
          }
        }
      }

      return duration.toDate();
    };

    return {
      setMinRestriction: function(restrictions, dateField, unit) {
        setBorderRestriction('min', restrictions, dateField, unit);
      },
      setMaxRestriction: function(restrictions, dateField, unit) {
        setBorderRestriction('max', restrictions, dateField, unit);
      },
      setDurationRestriction: function(restrictions, dateField) {
        var max = -1;
        if (_.get(restrictions, 'maxDurationInclusive')) {
          max = getTimeDuration(_.get(restrictions, 'maxDurationInclusive'));
        }
        if (_.get(restrictions, 'maxDurationExclusive')) {
          max = getTimeDuration(_.get(restrictions, 'maxDurationExclusive'));
        }
        if (max !== -1) {
          dateField.datePickerConfig.max = max;
        }

        var min = -1;
        if (_.get(restrictions, 'minDurationInclusive')) {
          min = getTimeDuration(_.get(restrictions, 'minDurationInclusive'));
        }
        if (_.get(restrictions, 'minDurationExclusive')) {
          min = getTimeDuration(_.get(restrictions, 'minDurationExclusive'));
        }
        if (min !== -1) {
          dateField.datePickerConfig.min = min;
        }
      }
    };
  }
]);