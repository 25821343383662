angular
  .module('common.directives')
  /**
   * the directive for the actions of the liste values
   */
  .component('mgPagination', {
    templateUrl: 'common/common-directives/pagination/pagination.html',
    bindings: {
      currentPage: '<',
      totalItems: '<',
      changePagination: '&',
      itemsPerPage: '<',
    },
    controller: PaginationController,
  });

function PaginationController($translate) {
  var ctrl = this;
  //Variables
  ctrl.maxSize = 5;
  ctrl.nextText = '»';
  ctrl.previousText = '«';
  //Functions
  ctrl.onClick = onClick;
  ctrl.$onInit = onInit;
  ctrl.$onChanges = onChanges;

  function onInit() {
    ctrl.visible = ctrl.totalItems > ctrl.itemsPerPage;
  }

  function onChanges(changes) {
    if (changes.totalItems || changes.itemsPerPage) {
      ctrl.visible = ctrl.totalItems > ctrl.itemsPerPage;
    }
  }

  function onClick() {
    var paginationSettings = {
      skip: (ctrl.currentPage - 1) * ctrl.itemsPerPage,
      top: ctrl.itemsPerPage,
      currentPage: ctrl.currentPage,
    };
    ctrl.changePagination(paginationSettings);
  }
}
