angular.module('portailDepotDemandeAide.dashboard').controller('dashboardDemandeDepotPiecesController', [
  '$scope',
  '$log',
  'aide',
  'teleservice',
  'piecesConfiguration',
  'aidesService',
  'tiersService',
  '$state',
  'Piece',
  'alertsService',
  'publicSettingsFinancement',
  '$stateParams',
  'piecesService',
  function(
    $scope,
    $log,
    aide,
    teleservice,
    piecesConfiguration,
    aidesService,
    tiersService,
    $state,
    Piece,
    alertsService,
    publicSettingsFinancement,
    $stateParams,
    piecesService
  ) {
    'use strict';

    // Utiliser le tiers bénéficaire pour le porte document
    $scope.aide = aide;
    $scope.tiers = aide.beneficiaires[0].expand;
    $scope.viewConfiguration = piecesConfiguration;
    $scope.navigate = {
      lock: 0,
    };

    // set setting parameter for 1 document by piece
    $scope.unDocumentParPiece = _.get(publicSettingsFinancement, 'unDocumentParPiece', false);

    $scope.documentsModified = false;

    // Url where to upload files
    $scope.urlDocuments = $scope.aide._links['mgs:documents'].href;

    // Directory of file's icons
    $scope.urlFileIcons = './resources/images/file-icons/';
    $scope.persistenceConfiguration = _.get(teleservice, 'persistenceConfiguration.expand', {});

    // Copie dans le scope du tableau de pièces pour l'ajout des nouveaux documents
    $scope.pieces = angular.copy($scope.aide.pieces);
    $scope.pieces = _.filter($scope.pieces, function(piece) {
      return (
        piece.fonction === 'depot' &&
        piece.modeTransmission !== 'ENVOIEPOSTAL' &&
        !piece.conforme &&
        !piece.obligatoire &&
        _.isEmpty(piece.documents)
      );
    });

    var copyAide = [];
    aidesService.getAide($scope.aide.reference).then(function(result) {
      copyAide = result.data.pieces;
    });
    //
    var changePiecesDocuments = function() {
      _.each($scope.aide.pieces, function(pieceAide) {
        var pieceModifie = _.find($scope.pieces, function(piece) {
          return pieceAide.reference === piece.reference;
        });
        if (pieceModifie) {
          if (!_.isEqual(_.map(pieceModifie.documents, 'id'), _.map(pieceAide.documents, 'id'))) {
            $scope.documentsModified = true;
          }
        }
      });
    };

    var setNewDocumentsOnPieces = function() {
      _.each($scope.aide.pieces, function(pieceAide) {
        var pieceModifie = _.find($scope.pieces, function(piece) {
          return pieceAide.reference === piece.reference;
        });
        if (pieceModifie) {
          if (!_.isEqual(_.map(pieceModifie.documents, 'id'), _.map(pieceAide.documents, 'id'))) {
            pieceAide.documents = pieceModifie.documents;
          }
          delete pieceAide.conforme;
        }
      });
      $scope.aide.pieces = _.map($scope.aide.pieces, function(p) {
        return new Piece(p);
      });
    };

    $scope.changeDocument = function() {
      $scope.documentsModified = false;
      changePiecesDocuments();
    };

    // Save new and delete documents on 'aide'
    $scope.depotPieces = function(piecesModifie) {
      // Copie des documents de l'aide sur le tiers si pièce typé 'structure'
      aidesService
        .copyTiersDocuments($scope.aide, $scope.tiers, $scope.pieces)
        .then(function(tiers) {
          return tiersService.patchPieces(tiers.reference, tiers);
        })
        .then(function() {
          // On affecte les nouveaux documents sur les pièces de l'aide
          setNewDocumentsOnPieces();
          //remet à toutes les pièces leur 'conformitesParFinanceur' et leur 'conforme'
          _.each($scope.aide.pieces, function(piece) {
            var find = _.find(copyAide, function(piAide) {
              if (
                piAide.reference === piece.reference &&
                piece.documents &&
                piAide.documents &&
                piece.documents.length === piAide.documents.length
              ) {
                return piAide;
              }
            });
            if (find && _.has(find, 'conformitesParFinanceur')) {
              piece.conformitesParFinanceur = find.conformitesParFinanceur;
              piece.conforme = find.conforme;
            }
          });
          return aidesService.patchPieces($scope.aide.reference, $scope.aide.pieces);
        })
        .then(function() {
          $state.go('app.connected.dashboard.aides.demandesAides');
        })
        .catch(function(error) {
          $log.error('demandedepotpieces', error);
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertError('connected.dashboard.aides.demandedepotpieces.modal.error.unknow'),
            'demandes-depot-pieces-alerts'
          );
        });
    };

    // Allow pages to manually block next and previous buttons
    $scope.navigate.lockNavigation = function() {
      $scope.navigate.lock++;
    };
    $scope.navigate.unlockNavigation = function() {
      $scope.navigate.lock = Math.max(0, $scope.navigate.lock - 1);
    };

    /**
     * Evaluate conditionAffichage (visible/hidden) setting on a piece
     * @method isPieceVisible
     * @param {Object} piece the piece
     * @returns {Boolean} the evaluate condition
     */
    $scope.isPieceVisible = function(piece) {
      return piecesService.evalConditionPiece($scope, piece, 'conditionAffichage');
    };

    $scope.preventValidationOnEnter = function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };

    $scope.goToListAides = function() {
      $state.go('app.connected.dashboard.aides.demandesAides', { page: $stateParams.page });
    };
  },
]);
