angular.module('portailDepotDemandeAide.depot').controller('depotSimpleCriteresController', [
  '$rootScope',
  '$scope',
  '$translate',
  '$q',
  '$modal',
  'Tiers',
  'aidesService',
  function($rootScope, $scope, $translate, $q, $modal, Tiers, aidesService) {
    'use strict';

    // fetch the demande teleservice version if in edition
    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageCritereEligibilite', {});
    $scope.criteresStatus = $scope.criteresStatus || {
      criteres: false,
      attestation: false,
      attempted: false,
    };

    if ($scope.pageOptions.actif) {
      // override existing criteres configuration from TS
      $scope.aide.criteresEligibilite = _.defaultsDeep(
        _.cloneDeep($scope.pageOptions.CritereEligibilite),
        $scope.aide.criteresEligibilite
      );
    }

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.noform = true;
    $scope.navigate.ns = $scope.criteresConfiguration.ns;
    $scope.criteresConfiguration.remoteValidation = false;

    $scope.navigate.next = function() {
      // Set the attempted marker to true
      $scope.criteresStatus.attempted = true;
      if (
        !$scope.criteresStatus.formValid ||
        ($scope.pageOptions.attestationHonneur && !$scope.criteresStatus.attestation)
      ) {
        // Not all criteres were captured by user. The directive will show the errors, jut don't navigate yet.
        return;
      }

      // we assume external API criteres are of type STRING
      const externalApiCriteres = _.filter($scope.aide.criteresEligibilite, ['type', 'STRING']);
      const promises = _.map(externalApiCriteres, (critere) => {
        return aidesService.checkExternalCritere(critere, $scope.teleserviceConfiguration.reference);
      });
      // re-init the custom messages list
      $scope.criteresStatus.messages = [];

      $q.all(promises)
        .then((results) => {
          // filter invalid results : eligibility of a service differs from the awaited response
          const invalids = _.filter(results, (result) => result.result.eligible !== result.critere.reponseAttendue);
          $scope.criteresStatus.messages = _.map(invalids, 'critere.libelleKO');
          return _.isEmpty(invalids);
        })
        .then(
          (valid) => {
            // Set the entity criteres result with the UX computed value
            $scope.aide.eligible = $scope.criteresStatus.criteres && valid;
            if ($scope.aide.eligible) {
              $scope.confirmNext(false);
            } else {
              $modal({
                scope: $scope,
                template: 'depot/simple/criteres/depot-simple-criteres-nok.html',
              });
            }
          },
          (rejection) => {
            if (rejection.status === 403) {
              // too much API calls
              const message = $translate.instant('teleservice.criteres.api.overflow');
              $scope.alerts = [{ type: 'error', messages: [message], title: 'error.title' }];
            } else {
              $rootScope.$broadcast('$httpError', rejection);
            }
          }
        );
    };

    $scope.confirmNext = function(forget) {
      // If the user is associated to a tiers go to the recap of this tiers
      // else start creating a new tiers
      if ($scope.tiers) {
        $scope.goToStep('demandeur-recapitulatif', forget);
      } else {
        $scope.goToStep('demandeur-famille', forget);
      }
    };

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'criteresEligibilite';

    if (!$scope.pageOptions.actif || $scope.pageOptions.actif === 'false') {
      $scope.confirmNext(true);
    }

    $scope.isCriteresLocked =
      _.get($scope.teleserviceConfiguration, 'urlApiExterneInitialisation') &&
      _.includes(_.get($scope.aide, 'history.begin.metadata.stepsVisited', []), 'criteres') &&
      _.some($scope.pageOptions.CritereEligibilite, (critere) => critere.type === 'STRING');
  },
]);
