/**
 * @ngdoc module
 * @module form
 * @name form
 * @description
 *
 *   Utilities for form management and dynamic forms.
 *
 *   Composed of {@link ../form.directives/index.html form.directives}, {@link ../form.services/index.html form.services} and {@link ../form.filters/index.html form.filters}.
 *
 */

angular.module('form', [
  'form.services',
  'form.directives',
  'form.filters'
]);