/**
 * @ngdoc service
 * @name tiers.services.tiersService
 * @requires $http,  configuration, $log, Tiers, TiersDoublon, TiersSchema
 **/
angular.module('tiers.services').factory('tiersService', tiersService);

tiersService.$inject = [
  '$rootScope',
  '$http',
  '$q',
  '$log',
  'Tiers',
  'Coordonnees',
  'configuration',
  'Piece',
  'jwtSessionService',
  'viewsService',
  'jsonpatch',
  'tiersThematiquesService',
  'StoreService',
];

function tiersService(
  $rootScope,
  $http,
  $q,
  $log,
  Tiers,
  Coordonnees,
  configuration,
  Piece,
  jwtSessionService,
  viewsService,
  jsonpatch,
  tiersThematiquesService,
  StoreService
) {
  let i18n;
  let getAllCurrentUserTiersCache;

  function _getUrl() {
    return _.get(configuration, 'tiers.url') || '/referentiel-tiers';
  }

  function _transformMasterData(data) {
    const parsedData = {
      raw: data,
      items: _.map(data, function(item) {
        // TODO
        // This slice is temporary! We sould get rid of these useless libelle.value as soon as we can.
        if (item.libelle && !item.libelle.value) {
          item.libelle = {
            value: item.libelle,
          };
        }

        return {
          href: item.id || item.href,
          title: item.title || _.get(item, 'libelle.value'),
          expand: item,
        };
      }),
    };

    return parsedData;
  }

  function _getMasterData(source) {
    return $http.get(_getUrl() + '/' + source + '?$top=1000&$orderby=title').then(function(response) {
      return _transformMasterData(response.data);
    });
  }

  function _getFamille(href) {
    return $http
      .get(href, {
        headers: {
          Accept: 'application/vnd.mgdis.tiers-3.19.0+json',
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  return {
    TIERS_KNOWN_STATUSES: Object.freeze(['SUPPORTED', 'BLOCKED', 'OBSOLETE', 'TRANSMITTED']),

    /**
     * Get the tiers id based on its reference
     *
     * @param {string} reference tiers reference
     * @returns {string} tiers id
     */
    buildIdFromReference(reference) {
      return _getUrl() + '/tiers/' + reference;
    },

    /**
     * Define a new tiers
     */
    changeFamilleTiers: function(tiers, user, mdm, masterdata) {
      // Keeping the already selected situation
      const situationEtrangere = _.get(tiers, 'situations[0].etrangere');
      _.extend(tiers, {
        famille: tiers.famille,
      });

      // Automatic creation of an address if the tiers doesn't have
      if (!tiers.situations || tiers.situations.length === 0) {
        tiers.situations.push(new Coordonnees());
      }

      if (user) {
        tiers.fillEmail(user);
        if (_.get(tiers, 'famille.expand.personnaliteJuridique') === 'PHYSIQUE') {
          tiers.fillInformationsTiersWithUser(user, mdm, masterdata);
        }
      }

      _.set(tiers, 'situations[0].etrangere', situationEtrangere);

      if (tiers.formeJuridique && tiers.formeJuridique.href && tiers.famille) {
        if (!tiers.famille.expand) {
          throw new Error(
            'changeFamilleTiers - tiers famille should have been expanded - ' +
              tiers.reference +
              ' - ' +
              JSON.stringify(tiers.famille)
          );
        } else if (!_.find(tiers.famille.expand.formesJuridiques, { href: tiers.formeJuridique.href })) {
          // Forme juridique doesn't exist in new famille
          delete tiers.formeJuridique;
        }
      }
    },

    getTitleTiers: function(tiers) {
      let titleTiers;
      if (tiers.raisonSociale) {
        titleTiers = tiers.raisonSociale;
      } else {
        titleTiers = _.trim(
          _.get(tiers, 'individu.Prenoms.PrenomUsuel', '') + ' ' + _.get(tiers, 'individu.Noms.NomUsage', '')
        );
      }

      return titleTiers;
    },

    /**
     * Récupération d'un tiers en fonction de sa référence
     * @param {Object} mdm Master data management
     * @param expand
     * @param user
     * @returns {*}
     */
    getTiersByReference: function(reference, mdm, expand) {
      return this.getTiersById(_getUrl() + '/tiers/' + reference, mdm, expand);
    },

    /**
     * Récupération du tiers selectionné en localStorage
     * @param {Object} mdm Master data management
     * @param expand string
     * @returns {Promise} get tiers promise
     */
    getCurrentTiers: function(mdm = {}, expand = '') {
      // Always use current recorded tiers reference in localstorage
      // when preparing our request.
      const currentTiersRef = StoreService.currentTiersRef.get(true);
      if (!currentTiersRef) {
        return $q.resolve();
      }

      const config = {
        params: {
          expand: expand || 'famille,formeJuridique',
        },
      };

      return $http
        .get(_getUrl() + '/tiers/' + currentTiersRef, config)
        .then(function(response) {
          // set cached reference
          StoreService.currentTiersRef.set(currentTiersRef);
          return new Tiers(response.data, mdm);
        })
        .catch((err) => {
          // unset invalid tiers
          StoreService.currentTiersRef.unset(true);
          // catch the error if the tiers is not found (to keep same behavior as before)
          // rethrow it otherwise
          if (err.status !== 404) {
            throw err;
          }
        });
    },

    /**
     * Get Tier by userName
     * @param {string} userName
     */
    getTiersByUserName(userName) {
      if (!userName) throw new Error('userName is not set');
      const config = {
        params: {
          expand: 'famille',
          $filter:
            "linkedUsers/href eq '" +
            urljoin(configuration.user.accountManagement, 'users', userName) +
            "' and (linkedUsers/form eq 'ADMINISTRATOR' or linkedUsers/form eq 'CONTRIBUTOR') and status ne 'RETURNED' and status ne 'MERGED'",
        },
      };
      return $http
        .get(_getUrl() + '/tiers', config)
        .then(function(response) {
          if (response.status !== 200) {
            throw new Error(response);
          }
          return response.data;
        })
        .catch(function(err) {
          $log.error(err);
          throw new Error('Cannot find this tier - ' + err);
        });
    },

    /**
     * Search tiers in referentiel (can use odata query filters)
     *
     * @param {*} params query params (can be odata query filters)
     */
    findTiers(params) {
      const config = params && { params };
      return $http.get(`${_getUrl()}/tiers`, config).then(function(response) {
        return response.data;
      });
    },

    /**
     *
     * @param {String} tiersId
     * @returns {Promise<Array>} The signataires with their email
     */
    getSignataires(tiersId) {
      if (!tiersId) throw new Error('tiersId is required');
      const options = {
        params: {
          tiersId,
        },
      };
      return $http.get(configuration.tiers.signataires, options).then((response) => response.data);
    },

    cleanPatches: function(patches) {
      const invalidPaths = ['/specificData', '/dateImmatriculationDisplay', '/thematiquesLiees'];

      const validPatches = _.filter(patches, function(patch) {
        const isInvalid = _.some(invalidPaths, function(invalidPath) {
          return _.startsWith(patch.path, invalidPath);
        });
        return !isInvalid;
      });

      return validPatches;
    },

    patchTiers: function(reference, patches, mdm) {
      const cleanedPatches = this.cleanPatches(patches);

      if (reference) {
        const tiersUrl = _getUrl() + '/tiers/' + reference;
        return $http.patch(tiersUrl, cleanedPatches).then(function(response) {
          return new Tiers(response.data, mdm);
        });
      }
      throw new Error('patchTiers - tiers reference is required');
    },
    /**
     * Enregistre le tiers au niveau du serveur
     * @param  {Tiers} tiers Tiers
     * @param  {mdm} mdm Le mdm
     */
    saveTiers: function(tiers, mdm) {
      // Récupération d'un objet tiers nettoyé
      const tiersEntity = tiers.getCleanEntity();

      if (tiersEntity.reference) {
        // Tiers modification => PATCH
        const tiersUrl = _getUrl() + '/tiers/' + tiers.reference;
        return $http.put(tiersUrl, tiersEntity).then(function(response) {
          return new Tiers(response.data, mdm);
        });
      } else {
        // Tiers creation => POST
        return $http.post(_getUrl() + '/tiers', tiersEntity).then(function(response) {
          return new Tiers(response.data, mdm);
        });
      }
    },

    /**
     * Verify the uniqueness of tiers SIRET in existing tiers
     * Return an array of objects corresponding to the requested SIRET
     *
     * @param  {String} SIREN Siren
     * @param  {String} NIC  Nic
     * @param  {String} famille Famille href
     */
    controlerUniciteSIRET: function(SIREN, NIC, famille) {
      const query = {
        method: 'POST',
        url: urljoin(_getUrl(), '/tiers/siret'),
        data: {
          SIRET: {
            SIREN,
            NIC,
          },
          famille,
          status: 'SUPPORTED',
        },
        json: true,
      };
      return $http(query).then(function(response) {
        return response.data;
      });
    },

    getMasterData: function(sources) {
      if (typeof sources === 'string') {
        sources = [sources];
      }

      const props = {};

      _.each(sources || [], function(source) {
        props[source] = _getMasterData(source);
      });

      return Promise.props(props);
    },

    getCivilites: function() {
      return _getMasterData('titresCivilites');
    },

    getTribunauxInstances: function() {
      return _getMasterData('tribunauxInstance');
    },

    getFamilles: function() {
      return _getMasterData('familles');
    },

    /**
     * Fetch a famille, extract views from it
     * @param {object} tiers
     * @param {url} href
     * @param {boolean} readOnly
     * @param {boolean} displaySaveButton
     */
    getViewsSrcFromFamille: (tiers, href, readOnly, displaySaveButton) => {
      return _getFamille(href).then((famille) => {
        const areViewsActive = (famille.views || []).some((view) => view.actif);

        // We don't want data-schema to manage entity if patch is sent by portail-depot
        // If displaySaveButton is truthy, we want data-schema to patch the entity
        const patchViewsEntity = displaySaveButton;

        const viewsIframeSrc = viewsService.getAllViewsIframeUrl(
          tiers,
          'demandeur',
          readOnly,
          false,
          5,
          displaySaveButton,
          famille.views,
          ['Accept:application/vnd.mgdis.tiers-3.19.0+json', 'Content-Type:application/vnd.mgdis.tiers-3.19.0+json'],
          famille.id,
          {
            'referentiel-tiers': [
              'Accept:application/vnd.mgdis.tiers-3.19.0+json',
              'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
            ],
          },
          patchViewsEntity
        );

        return {
          areViewsActive,
          viewsIframeSrc,
        };
      });
    },

    getPersonnaliteJuridique: function(tiers) {
      const famille = _.get(tiers, 'famille');
      if (_.isEmpty(tiers) || _.isEmpty(famille)) throw new Error('Pas de tiers de défini');

      let personnaliteJuridique =
        _.get(tiers, 'famille.expand.personnaliteJuridique') || _.get(tiers, 'famille.expand.d.personnaliteJuridique');
      if (personnaliteJuridique) return $q.resolve(personnaliteJuridique);

      if (_.get(tiers, 'famille.href')) {
        return $http
          .get(tiers.famille.href)
          .then(function(result) {
            return _.get(result, 'data.personnaliteJuridique');
          })
          .catch(function(error) {
            throw new Error('Erreur à la récupération du tiers', error);
          });
      }
    },

    /**
     * Patch de la liste des pièces
     */
    patchPieces: function(reference, tiers) {
      tiers.pieces = _.map(tiers.pieces || [], function(piece) {
        return new Piece(piece).getCleanEntity();
      });

      const patches = [
        {
          op: 'add',
          path: '/pieces',
          value: tiers.pieces,
        },
      ];

      return $http.patch(_getUrl() + '/tiers/' + tiers.reference, patches).then(function(response) {
        return response.data;
      });
    },
    /**
     * Allow to search a financeur privilegie
     * @param {*} searchLibelle
     */
    findFinanceursPrivilegie: function(searchLibelle) {
      // Escape quote for the odata $filter param
      searchLibelle = searchLibelle.replace(/'/g, "''");
      const config = {
        params: {
          $filter: `substringof(thematiquesLiees/financeur/financeurPrivilegie/libelleCourt, '${searchLibelle}')`,
        },
      };

      return $http.get(_getUrl() + '/financeurs', config).then(function(response) {
        return response.data;
      });
    },
    /**
     * Allow to get a tiers financeur by groupe gestion
     * @param {*} href href of the financeur
     */
    getTiersFinanceurPrivilegieByGroupeGestionHref: function(href) {
      return $http
        .get(
          _getUrl() +
            `/financeurs?filter=substringof(thematiquesLiees/financeur/financeurPrivilegie/groupeGestion/href, '${href}')`
        )
        .then(function(response) {
          return response.data[0];
        });
    },

    getPublicSettingsTiers: function() {
      return $http.get(_.get(configuration, 'tiers.publicSettings')).then(function(response) {
        return response.data;
      });
    },

    /**
     * Generates a href for an iframe page
     * @param {string} reference tiers reference
     * @param {string} page page name (route)
     * @param {object} [options] other options to overwrite defaults
     */
    getIframeUrl: function(reference, page, options) {
      const defaultOptions = {
        ref: reference,
        theme: !_.isNil($rootScope.specificTheme) ? 'specific' : 'demandeur',
        namespace: 'usagers.tiers',
        usagers: true,
        titleLevel: 4,
        jwtKey: jwtSessionService.getJwtKey(),
        readOnly: 'false',
      };

      const allOptions = _.merge({}, defaultOptions, options);
      const queryparams = $.param(allOptions);

      const url = configuration.tiers.ux + '#/' + page + '?' + queryparams;
      return url;
    },

    getFamilleAssociationIdentification(tiers) {
      if (_.has(tiers, 'famille.expand.associationIdentification')) {
        return $q.resolve(_.get(tiers, 'famille.expand.associationIdentification'));
      } else {
        return $http.get(`${tiers.famille.href}?merge=true`).then((response) => {
          return _.get(response, 'data.associationIdentification');
        });
      }
    },

    /**
     * Call has-similar route to verify if a tiers has doublons
     * @param {*} tiers tiers object
     */
    hasSimilar(tiers) {
      const url = `${_getUrl()}/tiers/${tiers.reference}/has-similar`;
      return $http.get(url).then(({ data }) => data);
    },

    /**
     * Check if it is possible to create directly a tiers 'SUPPORTED'
     * @param {object} tiers tiers of the demande
     * @param {object} teleservice teleservice of the demande
     * @param {object} lastTeleservice last teleservice revision (some conditions need it)
     * @returns true if it is possible
     */
    canCreateSupportedTiers(tiers, teleservice, lastTeleservice) {
      let result;

      const priseEnChargeAuto = _.get(lastTeleservice || teleservice, 'priseEnChargeAuto');
      const isDepotDelegue = _.get(teleservice, 'workflow.depotDelegue.actif');
      const isMultiFinanceurs = _.get(teleservice, 'multiFinanceur.active');
      const isTiersStatusValid = tiers.status === 'TEMPORARY' || tiers.status === 'TRANSMITTED';

      return this.getPersonnaliteJuridique(tiers).then((personnaliteJuridique) => {
        const isTiersPhysique = personnaliteJuridique === 'PHYSIQUE';
        result = priseEnChargeAuto && !isDepotDelegue && !isMultiFinanceurs && isTiersPhysique && isTiersStatusValid;

        const priseEnChargeAutoSimilitudeDemandeur = _.get(
          lastTeleservice || teleservice,
          'priseEnChargeAutoSimilitudeDemandeur'
        );
        if (result && priseEnChargeAutoSimilitudeDemandeur) {
          return this.hasSimilar(tiers).then(({ hasSimilar }) => {
            // ! we can create a tiers in supported, ONLY if there is no doublons (similar)
            result = result && !hasSimilar;
            return result;
          });
        }
        return result;
      });
    },

    /**
     * The documents of a tiers may be different from the configuration
     * of his famille. Thus if there are differences we correct them
     * @param {object} tiers
     * @return {object} updated pieces and value to update
     */
    mergePiecesFamilleInTiers: (tiers) => {
      // Keeping a copy of the tiers to compare it with the patch
      const clonedTiers = _.cloneDeep(tiers);

      if (_.isEmpty(clonedTiers.pieces)) {
        clonedTiers.pieces = [];
      }

      // remove expands on piece because we don't want to patch them
      clonedTiers.pieces = clonedTiers.pieces.map((piece) => {
        if (Array.isArray(piece.documents)) {
          piece.documents = piece.documents.map(({ href, id, rel }) => ({ href, id, rel }));
        }
        return piece;
      });
      const observer = jsonpatch.observe(clonedTiers);

      return _getFamille(tiers.famille.href).then((famille) => {
        const piecesFamilles = famille.pieces;
        const piecesToAdd = [];

        //? UPDATE
        // Updates the value of the libelle and the modele
        piecesFamilles.forEach((pieceFamille) => {
          _.unset(pieceFamille, 'modele.expand');

          const pieceTiers = _.find(clonedTiers.pieces, (piece) => piece.reference === pieceFamille.reference);
          if (pieceTiers) {
            const libelle = _.get(pieceFamille, 'libelle.value');
            _.set(pieceTiers, `libelle.value`, libelle);
            pieceTiers.modele = pieceFamille.modele;

            if (_.get(pieceFamille, 'description.value')) {
              pieceTiers.description = pieceFamille.description;
            } else if (pieceTiers.description) {
              delete pieceTiers.description;
            }
            pieceTiers.nature = pieceFamille.nature;
          } else {
            // pieceTiers === undefined means that pieceFamille is a new
            // piece that was added to the familly
            piecesToAdd.push(pieceFamille);
          }
        });

        //? DELETE
        // Pieces may have been removed from the familly. We have to remove them
        // from the tiers if no document has been uploaded
        clonedTiers.pieces = clonedTiers.pieces.filter((piece) => {
          const isPieceInFamilly = _.find(piecesFamilles, (pieceFamille) => piece.reference === pieceFamille.reference);
          const hasDocuments = !_.isEmpty(piece.documents);

          if (!isPieceInFamilly) {
            delete piece.modele;
          }

          return isPieceInFamilly || hasDocuments;
        });

        //? ADD
        // Pieces added to the familly, we have to add them to the tiers and to clear them
        piecesToAdd.forEach((pieceFamille) => {
          // Unset piece description if empty
          // (on the tiers, the description is not required but it must not be empty if set)
          if (!_.get(pieceFamille, 'description.value')) {
            delete pieceFamille.description;
          }
          delete pieceFamille.pieceDeReference;
          delete pieceFamille.envoiPostal;

          clonedTiers.pieces.push(pieceFamille);
        });

        let patches = jsonpatch.generate(observer);

        // add the pieces array if undefined
        if (_.isEmpty(tiers.pieces)) {
          patches = _.concat(
            [
              {
                op: 'add',
                path: '/pieces',
                value: [],
              },
            ],
            patches
          );
        }

        if (!_.isEmpty(patches)) {
          return $http.patch(tiers.id, patches).then((response) => {
            const patchedTiers = new Tiers(response.data);

            // set documents to avoid expand issues
            patchedTiers.pieces.forEach((piece) => {
              if (!piece.documents) {
                return;
              }
              const originalPiece = _.find(tiers.pieces, { reference: piece.reference });
              if (originalPiece) {
                // Copy original piece documents to get expand
                piece.documents = originalPiece.documents;
              }
            });

            return patchedTiers.pieces;
          });
        }

        return tiers.pieces;
      });
    },
    /**
     * Copy pieces from famille to tiers
     * @param {object} tiers
     */
    addFamillePiecesToTiers: (tiers) => {
      const famillePieces = _.get(tiers.famille, 'expand.pieces');
      tiers.pieces = _.map(famillePieces, (piece) => {
        return _.pick(piece, ['reference', 'libelle', 'description', 'fonction', 'obligatoire', 'modele']);
      });
    },

    /**
     * Save a tiers and its thematiques
     *
     * The thematiquesLiees property does not exist in the tiers contract and is filled on fetch
     * For ease of use, it is edited as part of the tiers but it needs to be saved apart
     *
     * This method saves the tiers and its thematiques and update the thematiquesLiees property
     * on the tiers with the saved thematiques
     *
     * @param {*} tiers tiers to save
     *
     * @return {Promise} a promise with the saved tiers and its updated thematiques
     */
    saveTiersAndThematiques: function(tiers) {
      const thematiques = tiers.thematiquesLiees;
      delete tiers.thematiquesLiees;

      return this.saveTiers(tiers).then((savedTiers) => {
        // We loop into thematiques, generating an http call by thematique type
        const thematiquesSavePromises = _.map(thematiques, (thematiqueValue, thematiqueKey) => {
          // The api only accepts thematiques in kebabCase (currently they are in camelCase)
          const collectionName = _.kebabCase(thematiqueKey);
          return tiersThematiquesService
            .saveThematique(collectionName, thematiqueValue, savedTiers)
            .then((savedThematique) => {
              thematiques[thematiqueKey] = savedThematique;
            });
        });

        return $q.all(thematiquesSavePromises).then(() => {
          savedTiers.thematiquesLiees = thematiques;
          return savedTiers;
        });
      });
    },

    /**
     * Checking if informations-complementaires are active
     * We display them if there is at least one field to display, it can be infos-comp spec (association/entreprise)
     * or infos-comp per famille (views)
     * @param {*} pageOptions
     * @param {*} teleserviceConfiguration
     * @param {*} tiers
     * @param {*} secondDemande
     * @param {string} identity
     */
    areInfosCompTiersActive: (pageOptions, teleserviceConfiguration, tiers, secondDemande, identity = 'demandeur') => {
      if (!tiers.famille.expand) {
        throw new Error('[areInfosCompTiersActive] tiers.famille.expand must exists');
      }
      const infosCompActive = _.get(pageOptions, 'complementaires.actif', true);
      const infosComplementairesTypes = { TIERS_ENTREPRISE: 'entreprise', TIERS_ASSOCIATION: 'association' };
      const tiersFamilleType = _.get(tiers, 'famille.expand.typeFamille');
      const informationsComplementairesValue = infosComplementairesTypes[tiersFamilleType];
      const familleHasViews = _.get(tiers, 'famille.expand.views', []).some((view) => view.actif);

      // if entreprise or association display infos-comp by default
      // don't display by default for other kinds of tiers
      let isEntrepriseOrAssociationAndActive = _.get(
        pageOptions,
        `complementaires.${informationsComplementairesValue}.actif`,
        !!informationsComplementairesValue
      );

      // if there are infos-comp specs, we need to check that there is at least one field to display
      if (isEntrepriseOrAssociationAndActive) {
        const infosComplementairesFields = {
          TIERS_ENTREPRISE: ['companyCreationDate', 'capitalAmount', 'mainActivity', 'groupMembership'],
          TIERS_ASSOCIATION: ['associationCreationDate'],
        };

        const isThereAFieldToDisplay = (fields) => {
          const atLeastOneVisibleField = fields.some((field) => {
            const fieldConfiguration = _.get(
              teleserviceConfiguration,
              `${identity}.${identity}-complementaire.fields.${field}`,
              {}
            );
            return !fieldConfiguration.hidden;
          });

          return atLeastOneVisibleField;
        };

        isEntrepriseOrAssociationAndActive =
          isEntrepriseOrAssociationAndActive && isThereAFieldToDisplay(infosComplementairesFields[tiersFamilleType]);
      }

      const areInfosCompActive =
        infosCompActive && (isEntrepriseOrAssociationAndActive || (familleHasViews && !secondDemande));

      return {
        isEntrepriseOrAssociationAndActive,
        areInfosCompActive,
      };
    },

    getReferentielTiersI18n: () => {
      if (i18n) {
        return $q.resolve(i18n);
      }

      return $http.get(configuration.i18n.referentielTiers).then(({ data }) => {
        i18n = data;
        return data;
      });
    },

    /**
     * Fetch the tiers referent if the option is enabled
     *
     * @param {*} tiersId id of the tiers
     */
    getTiersReferent(tiersId) {
      return this.getPublicSettingsTiers().then((publicSettings) => {
        if (!publicSettings.displayTiersReferentUsager.active) {
          return;
        }

        return $http.get(tiersId + '/referent').then(({ data }) => data);
      });
    },

    /**
     * Get creation steps for demandeur
     * @return {Array<String>}
     */
    getDemandeurCreationSteps() {
      return [
        'demandeur-famille',
        'demandeur-identification',
        'demandeur-adresse',
        'demandeur-representant-legal',
        'demandeur-representants',
        'demandeur-thematiques',
        'demandeur-complementaire',
      ];
    },

    /**
     * Get creation steps for beneficiaire
     * @return {Array<String>}
     */
    getBeneficiaireCreationSteps() {
      return [
        'beneficiaire-famille',
        'beneficiaire-identification',
        'beneficiaire-adresse',
        'beneficiaire-representant-legal',
        'beneficiaire-representants',
        'beneficiaire-thematiques',
        'beneficiaire-complementaire',
      ];
    },

    /**
     * Get all the tiers the user is linked to
     * @param {boolean} refresh force refreshing cahed return value
     *
     * @returns {Promise<object[]>} list of tiers the user is linked to
     */
    getAllCurrentUserTiers(refresh = false) {
      if (!getAllCurrentUserTiersCache || refresh) {
        const options = {
          from: 0,
          size: 1000,
          select: ['id', 'famille', 'reference', 'SIRET'],
          expand: ['famille'],
        };
        getAllCurrentUserTiersCache = $http.post(_getUrl() + '/tiers/mine', options).then((results) => {
          return results.data.tiers;
        });
      }
      return getAllCurrentUserTiersCache;
    },
    /**
     * Récupération d'un tiers en fonction de soin Id
     * @param {string} id tiers'id
     * @param {Object} mdm Master data management
     * @param {object} expand tiers'properties to expand
     * @returns {Promise<object>}
     */
    getTiersById: function(id, mdm, expand) {
      const config = {
        params: {
          expand: expand || 'famille,formeJuridique',
        },
      };

      return $http.get(id, config).then(function(response) {
        return new Tiers(response.data, mdm);
      });
    },
  };
}
