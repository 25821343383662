'use strict';
angular.module('aides.recapitulatif').directive('recapitulatifDocuments', function() {
  return {
    templateUrl: 'aides/aides-directives/recapitulatif/recapitulatif-documents/recapitulatif-documents.component.html',
    controller: recapitulatifDocuments,
    controllerAs: '$ctrl',
  };
});

function recapitulatifDocuments(jwtSessionService, configuration, $scope, $httpParamSerializer, StoreService) {
  this.$onInit = function $onInit() {
    // generating publication URL iframe
    const publicationIframeQueryParam = $httpParamSerializer({
      jwtKey: jwtSessionService.getJwtKey(),
      currentTiers: StoreService.currentTiersRef.get(),
    });
    const templatePublicationIframeSrc =
      configuration.referentielPublication.ux +
      '<%= tenantId %>/documents-publies/demande/<%= demandeId %>?<%= queryParams %>';
    const compiledPublicationIframeSrc = _.template(templatePublicationIframeSrc);
    const publicationIframeSrc = compiledPublicationIframeSrc({
      tenantId: configuration.tenant.id,
      demandeId: $scope.demande.reference,
      queryParams: publicationIframeQueryParam,
    });
    this.publicationIframeSrc = publicationIframeSrc;
  };

  function resizeIframe() {
    iFrameResize(
      {
        heightCalculationMethod: 'taggedElement',
        checkOrigin: false,
        inPageLinks: true,
      },
      '#publicationIframe'
    );
  }

  window.addEventListener('message', function(e) {
    if (e.data.route === 'documents-publies.demande.ux') {
      resizeIframe();
    }
  });
  $scope.$on('$destroy', function() {
    window.removeEventListener('message', resizeIframe);
  });
}
