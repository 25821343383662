angular.module('portailDepotDemandeAide.depot', ['ui.router', 'ngMessages', 'aides']);

angular.module('portailDepotDemandeAide.depot').config([
  '$stateProvider',
  function($stateProvider) {
    'use strict';

    var resolveViewConfiguration = function(module, view, namespace) {
      return [
        'viewManagerService',
        '$rootScope',
        function(viewManagerService, $rootScope) {
          return $rootScope.configurationPromise.then(() => {
            return viewManagerService.getViewConfiguration(module, view, 'teleservice.' + namespace);
          });
        },
      ];
    };

    const contributionModificationState = {
      // Procedure model 'simple', routing between pages inside the procedure is done using includes
      // in order to prevent bugs in the workflow caused by browser navigation actions
      url: '/contributionModification?p&c',
      controller: 'depotSimpleController',
      templateUrl: 'depot/simple/depot-simple.html',
      reloadOnSearch: false, // ?p will be set after some time, do not reload the state for it
      resolve: {
        // Contribution
        contribution: [
          '$stateParams',
          '$state',
          'contributionsService',
          'contributionsFactory',
          'contributionsConstants',
          ($stateParams, $state, contributionsService, contributionsFactory, contributionsConstants) => {
            return contributionsService.get($stateParams.c).then((contribution) => {
              if (contribution && contribution.statut === contributionsConstants.status.ANSWERED) {
                contributionsService.notifications.alreadyDone();
                return $state.go('app.connected.dashboard.accueil');
              }

              return contributionsFactory.setContribution(contribution);
            });
          },
        ],
        aide: [
          '$stateParams',
          'mdm',
          'teleservicesService',
          'teleserviceConfiguration',
          'aidesService',
          ($stateParams, mdm, teleservicesService, teleserviceConfiguration, aidesService) => {
            const aideReference = $stateParams.p;
            const contributionReference = $stateParams.c;
            const expands = [
              'teleservice',
              'teleservice.expand.thematiques',
              'demandeur.expand.famille',
              'beneficiaires.expand.famille',
              'pieces.documents',
              'specifiques.value',
              'planFinancement.depense.postes.lignes.pieces.documents',
            ].join(',');
            return aidesService
              .getAideWithContribution(aideReference, contributionReference, mdm, expands)
              .then((aide) => {
                if (aide.teleserviceExtension) {
                  return teleservicesService
                    .getTeleService(teleserviceConfiguration.reference, null, {
                      teleserviceExtension: aide.teleserviceExtension.href,
                    })
                    .then((teleserviceWithExtension) => {
                      // remove the "workflow.simple" key to match the teleserviceConfiguration
                      teleserviceWithExtension.workflow =
                        teleserviceWithExtension.workflow[teleserviceConfiguration.workflow.type];
                      teleserviceWithExtension.workflow.type = teleserviceConfiguration.workflow.type;
                      _.assign(teleserviceConfiguration, teleserviceWithExtension);
                      return aide;
                    });
                } else {
                  return aide;
                }
              });
          },
        ],
        demandeur: [
          'aide',
          (aide) => {
            return _.get(aide, 'demandeur.expand');
          },
        ],
        // Simple
        simpleConfiguration: resolveViewConfiguration('simple'),

        // Public Settings Financement
        publicSettingsFinancement: [
          'aidesService',
          (aidesService) => {
            return aidesService.getPublicSettingsFinancement();
          },
        ],
        declarationCompteSignataire: [
          '$stateParams',
          'teleservicesService',
          ($stateParams, teleservicesService) => {
            return teleservicesService
              .getTeleService($stateParams.configurationId, false)
              .then(({ declarationCompteSignataire }) => declarationCompteSignataire);
          },
        ],
        attestationDeclarationHonneur: [
          'configuration',
          '$http',
          (configuration, $http) => {
            return $http
              .get(configuration.referentielPiece.url + '/attestationDeclarationHonneur')
              .then(({ data }) => data);
          },
        ],
        tiersPhysiqueAlreadyLinked: [
          'tiersService',
          'mdm',
          (tiersService, mdm) => {
            return tiersService
              .getAllCurrentUserTiers()
              .then((allTiers) => {
                return allTiers.find((tiers) => tiers.famille.expand.personnaliteJuridique === 'PHYSIQUE');
              })
              .then((tiers) => {
                return tiers && tiersService.getTiersById(tiers.id, mdm);
              });
          },
        ],
      },
    };

    const contributionRedirectionState = _.assign({}, contributionModificationState);
    contributionRedirectionState.url = '/contributionRedirection?p&c';

    $stateProvider
      .state('app.connected.config', {
        // This is a abstract state to share some logic between all states that depend on a configuration (=téléprocédure)
        url: '/:configurationId',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          configurationId: [
            '$stateParams',
            function($stateParams) {
              return $stateParams.configurationId;
            },
          ],
          tiers: [
            'tiersService',
            'mdm',
            function(tiersService, mdm) {
              return tiersService.getCurrentTiers(
                mdm,
                [
                  'pieces.documents',
                  'famille',
                  'formeJuridique',
                  'linkedUsers',
                  'civilite',
                  'domiciliationsBancaires.pieces.documents',
                ].join(',')
              );
            },
          ],
        },
      })
      .state('app.connected.config.depot', {
        // Shared logic accross all pages of any workflow, mostly a rich template
        url: '/depot',
        controller: 'depotController',
        template: '<div ui-view></div>',
        abstract: true,
        resolve: {
          teleserviceConfiguration: [
            '$stateParams',
            '$translate',
            '$location',
            'teleservicesService',
            'configuration',
            function($stateParams, $translate, $location, teleservicesService, configuration) {
              // If we have an id persistence, we retrieve the teleservice same if it's disable
              var persistence = $location.search().p ? true : false;

              return teleservicesService
                .getTeleService($stateParams.configurationId, persistence, { expand: 'thematiques' })
                .then(function(teleservice) {
                  // Modify by reference i18n's configuration and force the refresh.
                  // Necessary to fetch files with dynamic names based on current teleservice
                  configuration.i18n.teleservice.id = $stateParams.configurationId.split('?')[0];

                  if (!teleservice) {
                    return $translate.refresh().then(function() {
                      return {};
                    });
                  }

                  if (teleservice.workflow.type) {
                    configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                    return $translate.refresh().then(function() {
                      teleservice.workflow = teleservice.workflow[configuration.i18n.teleservice.workflow];
                      // copy type to control access in controller
                      teleservice.workflow.type = configuration.i18n.teleservice.workflow;
                      return teleservice;
                    });
                  } else {
                    configuration.i18n.teleservice.workflow = teleservice.typeWorkflow;
                    return teleservice;
                  }
                });
            },
          ],
          publicSettingsTiers: [
            'tiersService',
            'adresseService',
            function(tiersService, adresseService) {
              return tiersService.getPublicSettingsTiers().then(function(publicSettingsTiers) {
                // Configure api ban
                adresseService.setApiBan(
                  _.get(publicSettingsTiers, 'remoteServices.apiban.active', false),
                  _.get(publicSettingsTiers, 'remoteServices.apiban.url')
                );
                return publicSettingsTiers;
              });
            },
          ],
        },
      })
      .state('app.connected.config.depot.simple', {
        // Procedure model 'simple', routing between pages inside the procedure is done using includes
        // in order to prevent bugs in the workflow caused by browser navigation actions
        url: '/simple?p&fromEchange&user&theme',
        controller: 'depotSimpleController',
        templateUrl: 'depot/simple/depot-simple.html',
        reloadOnSearch: false, // ?p will be set after some time, do not reload the state for it
        resolve: {
          aide: [
            '$state',
            '$stateParams',
            'configuration',
            'aidesService',
            'userSessionService',
            'teleserviceConfiguration',
            'teleservicesService',
            'Aide',
            'mdm',
            (
              $state,
              $stateParams,
              configuration,
              aidesService,
              userSessionService,
              teleserviceConfiguration,
              teleservicesService,
              Aide,
              mdm
            ) => {
              var teleserviceUrl = teleserviceConfiguration.id;
              var user = userSessionService.getUser();
              if ($stateParams.p) {
                var aideReference = $stateParams.p;
                var expands = [
                  'teleservice',
                  'teleservice.expand.thematiques',
                  'demandeur.expand.famille',
                  'beneficiaires.expand.famille',
                  'pieces.documents',
                  'specifiques.value',
                  'planFinancement.depense.postes.lignes.pieces.documents',
                ].join(',');
                return aidesService.get(aideReference, mdm, expands).then(function(aide) {
                  if (!_.includes(['REQUESTED', 'WAITING_FOR_CERTIFICAT', 'REGISTERED'], aide.status)) {
                    $state.go('app.connected.dashboard.accueil');
                    return;
                  }

                  if (aide.teleserviceExtension) {
                    return teleservicesService
                      .getTeleService(teleserviceConfiguration.reference, null, {
                        teleserviceExtension: aide.teleserviceExtension.href,
                      })
                      .then(function(teleserviceWithExtension) {
                        // remove the "workflow.simple" key to match the teleserviceConfiguration
                        teleserviceWithExtension.workflow =
                          teleserviceWithExtension.workflow[teleserviceConfiguration.workflow.type];
                        teleserviceWithExtension.workflow.type = teleserviceConfiguration.workflow.type;
                        _.assign(teleserviceConfiguration, teleserviceWithExtension);
                        return aide;
                      });
                  } else {
                    return aide;
                  }
                });
              } else {
                return new Aide({
                  status: 'REQUESTED',
                  dispositif: teleserviceConfiguration.dispositif,
                  user: {
                    title: user.displayName,
                    rel: 'user',
                    href: configuration.user.accountManagement + /users/ + user.userName,
                  },
                  teleservice: {
                    id: teleserviceUrl,
                    href: teleserviceUrl,
                    title: teleserviceConfiguration.title,
                    rel: 'teleservice',
                    expand: teleserviceConfiguration,
                  },
                  linkedUsers: [
                    {
                      title: user.displayName,
                      rel: 'user',
                      form: 'ADMINISTRATOR',
                      href: configuration.user.accountManagement + /users/ + user.userName,
                    },
                  ],
                });
              }
            },
          ],
          demandeur: [
            'aide',
            'tiers',
            'Tiers',
            (aide, tiers, Tiers) => {
              let demandeur;
              if (tiers) {
                // if we are attached to a tiers, then it become the demandeur of the demande
                // it is the case when we create a new demande OR edit a REQUESTED demande (that may have had a different demandeur)
                demandeur = new Tiers(tiers);
              } else if (aide.demandeur.href && aide.demandeur.expand) {
                demandeur = aide.demandeur.expand;
              }
              return demandeur;
            },
          ],
          // Simple
          simpleConfiguration: resolveViewConfiguration('simple'),

          // Public Settings Financement
          publicSettingsFinancement: [
            'aidesService',
            (aidesService) => {
              return aidesService.getPublicSettingsFinancement();
            },
          ],
          contribution: [
            'contributionsFactory',
            (contributionsFactory) => {
              return contributionsFactory.setContribution(null);
            },
          ],
          declarationCompteSignataire: [
            '$stateParams',
            'teleservicesService',
            ($stateParams, teleservicesService) => {
              return teleservicesService
                .getTeleService($stateParams.configurationId, false)
                .then(function(teleservice) {
                  return teleservice.declarationCompteSignataire;
                });
            },
          ],
          attestationDeclarationHonneur: [
            'configuration',
            '$http',
            (configuration, $http) => {
              return $http
                .get(configuration.referentielPiece.url + '/attestationDeclarationHonneur')
                .then(function(res) {
                  return res.data;
                });
            },
          ],
          echangesActif: [
            'aide',
            'teleservicesService',
            (aide, teleservicesService) => {
              return teleservicesService.getTeleServiceFromObject(aide, true).then(function(teleservice) {
                return teleservice.echangesActif;
              });
            },
          ],
          tiersPhysiqueAlreadyLinked: [
            'tiersService',
            'mdm',
            (tiersService, mdm) => {
              return tiersService
                .getAllCurrentUserTiers()
                .then((allTiers) => {
                  return allTiers.find((tiers) => tiers.famille.expand.personnaliteJuridique === 'PHYSIQUE');
                })
                .then((tiers) => {
                  return tiers && tiersService.getTiersById(tiers.id, mdm);
                });
            },
          ],
        },
        onEnter: function($rootScope, aide, echangesActif) {
          $rootScope.echangesActif = echangesActif;
          if (aide.reference) {
            $rootScope.displayEchangeMenu = {
              value: echangesActif,
              demandeId: aide.reference,
            };
          }
        },
        onExit: function($rootScope) {
          $rootScope.displayEchangeMenu = {
            value: false,
          };
        },
      })
      .state('app.connected.config.depot.demandePaiement', {
        // Procedure model 'demandePaiement', routing between pages inside the procedure is done using includes
        // in order to prevent bugs in the workflow caused by browser navigation actions
        url: '/:referenceAide/demandePaiement?reference&financeurHref&dossier',
        controller: 'demandePaiementController',
        templateUrl: 'depot/demande-paiement/demande-paiement.html',
        dynamic: true, // ?reference will be set after some time, do not reload the state for it
        params: {
          aide: null,
        },
        resolve: {
          aide: [
            '$stateParams',
            'aidesService',
            'Aide',
            function($stateParams, aidesService, Aide) {
              if ($stateParams.aide) {
                return new Aide($stateParams.aide);
              } else {
                return aidesService.get(
                  $stateParams.referenceAide,
                  undefined,
                  'demandeur,beneficiaires,beneficiaires.famille'
                );
              }
            },
          ],
          demandePaiement: [
            '$stateParams',
            'demandesPaiementService',
            'aide',
            'teleserviceConfiguration',
            function($stateParams, demandesPaiementService, aide, teleserviceConfiguration) {
              var teleserviceUrl = teleserviceConfiguration.id;

              if ($stateParams.reference) {
                return demandesPaiementService
                  .getDemandePaiement($stateParams.reference, {
                    params: {
                      $expand: 'pieces.documents,domiciliationBancaire.pieces.documents',
                    },
                  })
                  .then(function(demandePaiement) {
                    demandePaiement.teleservicePaiement = {
                      id: teleserviceUrl,
                      href: teleserviceUrl,
                      title: teleserviceConfiguration.title,
                      expand: teleserviceConfiguration,
                    };

                    demandePaiement.demandeFinancement = {
                      href: aide.id,
                      expand: aide,
                    };

                    return demandePaiement;
                  });
              } else {
                return {
                  step: 'informations-generales',
                  stepsStack: [],
                  statut: 'EN_COURS',
                  teleservicePaiement: {
                    id: teleserviceUrl,
                    href: teleserviceUrl,
                    title: teleserviceConfiguration.title,
                    expand: teleserviceConfiguration,
                  },
                  demandeFinancement: {
                    href: aide.id,
                    expand: aide,
                  },
                };
              }
            },
          ],
          typesPaiement: [
            'paiementService',
            'demandePaiement',
            function(paiementService, demandePaiement) {
              if (_.get(demandePaiement, 'teleservicePaiement.expand')) {
                return demandePaiement.teleservicePaiement.expand.workflow.pageInformationsGenerales.typesPaiement;
              }
              return paiementService.getTypesPaiement(demandePaiement.teleservicePaiement.id);
            },
          ],
          informationsComplementaires: [
            'teleservicesService',
            'teleserviceConfiguration',
            'dataSchemasService',
            function(teleservicesService, teleserviceConfiguration, dataSchemasService) {
              // Get list of informations complementaires data-schemas from teleservice paiement
              const infosCompHrefList = JSONPath(
                "$.[?(@.page == 'pageInformationsComplementaires')].schema.href",
                _.get(teleserviceConfiguration, 'views')
              );
              const infosComplementaires = dataSchemasService
                .getDataSchemasList(infosCompHrefList)
                .then((infosComp) => {
                  return infosComp;
                });
              return infosComplementaires;
            },
          ],
          decisions: [
            '$stateParams',
            'aidesService',
            function($stateParams, aidesService) {
              return aidesService.getDecisionsHistory($stateParams.referenceAide, $stateParams.dossier);
            },
          ],
        },
      })
      .state('app.connected.config.depot.offresStage', {
        // Procedure model 'offresStage', routing between pages inside the procedure is done using includes
        // in order to prevent bugs in the workflow caused by browser navigation actions
        url: '/offresStage?ref',
        controller: 'depotOffresStageController',
        templateUrl: 'depot/offres-stage/depot-offres-stage.html',
        reloadOnSearch: false, // ?ref will be set after some time, do not reload the state for it
        resolve: {
          offreStage: [
            '$stateParams',
            'configuration',
            'offresStageService',
            'userSessionService',
            'teleserviceConfiguration',
            'OffreStage',
            function(
              $stateParams,
              configuration,
              offresStageService,
              userSessionService,
              teleserviceConfiguration,
              OffreStage
            ) {
              var teleserviceUrl = teleserviceConfiguration.id;
              var user = userSessionService.getUser();

              if ($stateParams.ref) {
                var referenceOffreStage = $stateParams.ref;
                var expands = [
                  'teleservice',
                  'teleservice.expand.thematiques',
                  'demandeur.expand.famille',
                  'beneficiaires.expand.famille',
                  'pieces.documents',
                ].join(',');
                return offresStageService.get(referenceOffreStage, expands);
              } else {
                //initialisation des pieces
                var pieces = _.clone(_.get(teleserviceConfiguration, 'workflow.pagePieces.modelesPieces'));
                _.forEach(pieces, function(piece) {
                  delete piece.pieceDeReference;
                });
                return new OffreStage({
                  statut: 'REQUESTED',
                  dispositif: teleserviceConfiguration.dispositif,
                  user: {
                    title: user.displayName,
                    rel: 'user',
                    href: configuration.user.accountManagement + /users/ + user.userName,
                  },
                  adresse: {},
                  teleservice: {
                    id: teleserviceUrl,
                    href: teleserviceUrl,
                    title: teleserviceConfiguration.title,
                    rel: 'teleservice',
                    expand: teleserviceConfiguration,
                  },
                  pieces: pieces,
                  history: {
                    begin: {},
                    events: [],
                    end: {},
                  },
                });
              }
            },
          ],
          cadresDepot: [
            'offresStageService',
            'settings',
            function(offresStageService) {
              return offresStageService.initCadresDepot();
            },
          ],
          secteursActivite: [
            'offresStageService',
            'settings',
            function(offresStageService) {
              return offresStageService.initSecteursActiviteStructure();
            },
          ],
          effectifs: [
            'offresStageService',
            'settings',
            function(offresStageService) {
              return offresStageService.initEffectifStructure();
            },
          ],
          dureesStage: [
            'offresStageService',
            'settings',
            function(offresStageService) {
              return offresStageService.initDureesStage();
            },
          ],
          typesStage: [
            'offresStageService',
            'settings',
            function(offresStageService) {
              return offresStageService.initTypesStage();
            },
          ],
          qualifs: [
            'offresStageService',
            'settings',
            function(offresStageService) {
              return offresStageService.initNiveauxQualificationRequis();
            },
          ],
          siret: [
            'tiersService',
            'userSessionService',
            'mdm',
            function(tiersService, mdm) {
              return tiersService.getCurrentTiers(mdm).then(function(tiers) {
                if (!_.isEmpty(tiers)) {
                  return tiers.SIRET;
                }
              });
            },
          ],
        },
      })
      .state('app.connected.config.depot.contributions', {
        url: '/contributions/:contributionRef',
        component: 'contributionAvisComponent',
        resolve: {
          // Contribution
          contribution: [
            '$stateParams',
            'contributionsService',
            function($stateParams, contributionsService) {
              return contributionsService.get($stateParams.contributionRef);
            },
          ],
          // Public Settings Financement
          publicSettingsFinancement: [
            'aidesService',
            function(aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],
          demande: [
            'contribution',
            function(contribution) {
              return _.get(contribution, 'demande', null);
            },
          ],
        },
      })
      .state('app.connected.config.depot.contributionRedirection', contributionRedirectionState)
      .state('app.connected.config.depot.contributionModification', contributionModificationState);
  },
]);
