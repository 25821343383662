angular.module('aides').constant('AidesFilterConfiguration', {
  operators: [
    {
      title: 'connected.dashboard.aides.search.filters.noFilter',
      value: null,
    },
    {
      title: 'connected.dashboard.aides.search.filters.operators.eq',
      value: 'EQ',
    },
    {
      title: 'connected.dashboard.aides.search.filters.operators.sup',
      value: 'SUP',
    },
    {
      title: 'connected.dashboard.aides.search.filters.operators.inf',
      value: 'INF',
    },
  ],
  datepicker: {
    options: {
      start: 'decade',
      depth: 'decade',
      value: '',
      format: 'yyyy',
    },
    pattern: /^(19|20)\d{2}$/,
  },
  avis: {
    FAVORABLE: 'FAVORABLE',
    DEFAVORABLE: 'DEFAVORABLE',
  },
});
