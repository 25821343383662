angular.module('common.directives').directive(
  'breadcrumb',
  /* @ngInject */
  function($state, jwtSessionService) {
    'use strict';

    const getHierarchyBreadcrumb = function(rootState, currentState) {
      // Manages the breadcrumb (=fil d'ariane) according to the current state.
      let breadcrumbRootState = [];
      if (rootState) {
        breadcrumbRootState.push({
          name: rootState,
          abstract: false,
          breadcrumb: rootState.slice(4) + '.breadcrumb', // Remove 'app.' state
        });
      }

      //Ajout d'un parent
      if (currentState.show_parent) {
        breadcrumbRootState.push({
          name: currentState.show_parent.name,
          abstract: false,
          breadcrumb: currentState.show_parent.breadcrumb,
        });
      }

      const breadcrumbOtherStates = _.map(currentState.path, function(path) {
        const state = {
          name: path.self.name,
          abstract: path.self.abstract,
          breadcrumb: _.get(path, 'data.breadcrumb') || path.self.name.slice(4) + '.breadcrumb', // Remove 'app.' state
        };

        if (currentState.tooltip) {
          state.tooltip = currentState.tooltip;
        }

        return state;
      });

      const breadcrumbStates = _.filter(breadcrumbOtherStates, function(state) {
        return state.name !== rootState;
      });

      return _.union(breadcrumbRootState, breadcrumbStates);
    };

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'common/common-directives/breadcrumb/breadcrumb.html',
      scope: {
        rootState: '=',
        currentState: '=',
        label: '=',
      },
      link: function($scope) {
        $scope.$watchGroup(['rootState', 'currentState'], function(newValues) {
          if (newValues) {
            $scope.states = getHierarchyBreadcrumb($scope.rootState, $scope.currentState);
          }
        });

        /**
         * Manage redirection to home/login based on jwt session
         */
        $scope.goHome = function() {
          const user = jwtSessionService.user();
          if (user) {
            $state.go('app.connected.dashboard.accueil');
          } else {
            $state.go('app.home');
          }
        };
      },
    };
  }
);
