/**
 * @ngdoc service
 * @name tiers.services.adresseService
 * @requires $q, $http, configuration
 * @description Allows to search postal code and town
 **/
angular.module('tiers.services').factory('adresseService', [
  '$q',
  '$http',
  'configuration',

  function($q, $http, configuration) {
    'use strict';

    var adresseService = {};

    function _url() {
      return configuration.mdm.mgdis;
    }
    function _source() {
      return configuration.codePostalVille.source || 'mdmfrance';
    }
    function _nbItems() {
      return configuration.codePostalVille.nbItems || 10;
    }

    var _codeInseeRegExp = /^(2[AB]|[0-9]{2})[0-9]{3}$/;

    adresseService.searchCodeInseeVille = function(searchCodeInsee) {
      // Double the special characters so that the search can be done on ' character, and do not make errors
      var searchString = searchCodeInsee.replace(/([.*+?^=!:${}()|\'\"\[\]\/\\])/g, '$1$1');

      if (_codeInseeRegExp.test(searchString)) {
        var baseRequest = {
          $top: 1,
          $skip: 0,
        };

        // Filter by code insee
        var requestEqualCodeInsee = _.merge(
          {
            $filter: "codeinsee eq '" + searchString + "'",
          },
          baseRequest
        );

        // Envoi
        return $http
          .get(_url() + _source(), {
            params: requestEqualCodeInsee,
          })
          .then(function(result) {
            return _.get(result, 'data.d.results[0].communemaj');
          });
      } else {
        var deferred = $q.defer();
        deferred.resolve('');
        return deferred.promise;
      }
    };

    /**
     * Request hexaposte data matching a filter
     *
     * Used by #searchCodePostalVille
     * Returns the results sorted by code postal and commune name
     *
     * @param {string} filter filter
     */
    function queryMdmCodePostalVilleSearch(filter) {
      const query = {
        $top: _nbItems(),
        $skip: 0,
        $orderby: 'codepostal asc, communemaj asc',
        $filter: filter,
      };

      return $http
        .get(_url() + _source(), {
          params: query,
        })
        .then(({ data }) => _.get(data, 'd.results', []));
    }

    /**
     * Search the code postal and commune from the hexaposte data
     *
     * If the searched term contains numbers it looks for a combination of code postal, commune
     * and ligne5 (commune déléguée)
     * otherwise it looks for commune for which the name starts with and/or contains the searched
     * term
     *
     * @param {string} searchedTerm searched term
     */
    adresseService.searchCodePostalVille = (searchedTerm) => {
      // Double the special characters
      const searchString = searchedTerm.replace(/([.*+?^=!:${}()|"'[\]/\\])/g, '$1$1');
      const hasNumbers = /[0-9]/.test(searchString);

      if (hasNumbers) {
        // if there is numbers in the searched string it should be the code postal
        // we break it in 3 groups (lbligne5, code postal and commune) and build the filter on the result
        const match = /^([^0-9]+)?([0-9]+)([^0-9]+)?$/.exec(searchString);
        const [lbLigne5, codePostal, commune] = _.slice(match, 1);

        const filters = [`startswith(codepostal, '${codePostal}')`];

        if (lbLigne5) {
          const trimmedLigne5 = lbLigne5.trim();
          filters.push(`startswith(tolower(lbligne5), tolower('${trimmedLigne5}'))`);
        }

        if (commune) {
          const trimmedCommune = commune.trim();
          filters.push(
            `(startswith(tolower(communemaj), tolower('${trimmedCommune}')) or startswith(tolower(lbacheminement), tolower('${trimmedCommune}')))`
          );
        }

        return queryMdmCodePostalVilleSearch(filters.join(' and '));
      } else {
        // if there is no code postal in the search string we search only on the commune
        // if there is only letters with no accent we search commune that starts with the searched string or that
        // contains it if there is no result. otherwise we search commune that contains all words of the searched string
        const hasOnlyLetters = /^[a-zA-Z]+$/.test(searchString);
        const startsWithQuery = `startswith(tolower(communemaj), tolower('${searchString}')) or startswith(tolower(lbacheminement), tolower('${searchString}'))`;

        const searchStartsWithIfHasOnlyLetters = hasOnlyLetters
          ? queryMdmCodePostalVilleSearch(startsWithQuery)
          : $q.resolve([]);

        return searchStartsWithIfHasOnlyLetters.then((results) => {
          if (results.length > 0) {
            return results;
          }

          const containWordsQuery = _.map(searchString.split(' '), (word) => {
            return `(substringof(tolower('${word}'), tolower(communemaj)) or substringof(tolower('${word}'), tolower(lbacheminement)))`;
          }).join(' and ');

          return queryMdmCodePostalVilleSearch(containWordsQuery);
        });
      }
    };

    /**
     * Retrieve a codePostal resource from given codePostal and codeInsee
     */
    adresseService.getCodePostalVilleFromCodes = function(codePostal, codeInsee) {
      return $http
        .get(_url() + _source(), {
          params: {
            $filter: "codepostal eq '" + codePostal + "' and codeinsee eq '" + codeInsee + "'",
          },
        })
        .then(function(response) {
          return _.get(response, 'data.d.results[0]');
        });
    };

    // ------ API BAN ------

    // Keep a configuration for the service
    var _apiBan = {
      active: false,
      url: '',
    };

    // Set configuration
    adresseService.setApiBan = function(active, url) {
      _apiBan.active = active;
      _apiBan.url = url;
    };

    adresseService.isApiBanActive = function() {
      return _apiBan.active;
    };

    // Call apiBan to search an address
    adresseService.searchApiBan = function(searchString) {
      return $http
        .get(_apiBan.url + '/search', {
          params: {
            q: searchString,
            limit: 10,
          },
        })
        .then(function(response) {
          return _.get(response, 'data.features', []);
        });
    };

    // Tell if configured url is the official apiban (adresse.data.gouv.fr)
    adresseService.isDefaultApiUsed = function() {
      return _apiBan.url === _.get(configuration, 'apiban.defaultApi');
    };

    return adresseService;
  },
]);
