angular.module('portailDepotDemandeAide.user', ['user.constants', 'ui.router']);

angular.module('portailDepotDemandeAide.user').config([
  '$stateProvider',
  'USER_ROLES',
  function($stateProvider, USER_ROLES) {
    'use strict';

    var resolveConfiguration = function(module, view) {
      return [
        'viewManagerService',
        '$rootScope',
        function(viewManagerService, $rootScope) {
          // Wait for configuration from publicSettings
          return $rootScope.configurationPromise.then(function(configuration) {
            return viewManagerService.getViewConfiguration(module, view);
          });
        },
      ];
    };

    $stateProvider
      .state('app.connected.user', {
        url: '/user',
        abstract: true,
        controller: 'userController',
        template: '<div class="view-full" ui-view></div>',
        resolve: {
          mdm: [
            'mdmService',
            'tiersService',
            function(mdmService, tiersService) {
              return mdmService.fill(['pays']).then(function(mdm) {
                return tiersService.getMasterData(['familles', 'titresCivilites']).then(function(masterdata) {
                  _.set(
                    mdm,
                    'famillestiers.array',
                    _.filter(masterdata.familles.items, function(famille) {
                      return famille.expand.actif;
                    })
                  );
                  _.set(mdm, 'civilites.array', masterdata.titresCivilites.items);
                  return mdm;
                });
              });
            },
          ],
        },
      })
      .state('app.connected.user.account-user', {
        url: '/account-user',
        controller: 'accountUserController',
        templateUrl: 'user/account/account-user/account-user.html',
        resolve: {
          userAccountUserConfiguration: resolveConfiguration('user', 'account-user'),
          rattachementUserConfiguration: resolveConfiguration('tiers', [
            'identification',
            'adresse',
            'piece-form',
            'rattachement-user-tiers',
          ]),
          tiers: [
            'tiersService',
            'mdm',
            (tiersService, mdm) => {
              return tiersService.getCurrentTiers(mdm);
            },
          ],
          currentUser: [
            '$rootScope',
            'accountManagementService',
            ($rootScope, accountManagementService) => {
              return accountManagementService.getAccount($rootScope.currentUser.id).then(function(user) {
                // màj du currentUser qui a pu évoluer suite à la validation des "agreements" (lors de la connection)
                $rootScope.currentUser = _.merge($rootScope.currentUser, user);
              });
            },
          ],
          // Rmq : pour info, $scope.listeAutorisation et $scope.listeAbonnement sont initialisé par les composants
          // components.abonnements et components.autorisations (via agreement.html)
        },
      })
      .state('app.user', {
        url: '/user',
        template: '<div class="view-full" ui-view></div>',
        abstract: true,
      })
      .state('app.user.account-creation', {
        url: '/account-creation?redirectTo&redirectParams',
        controller: 'accountCreationController',
        templateUrl: 'user/account/account-creation/account-creation.html',
        resolve: {
          userAccountCreationConfiguration: resolveConfiguration('user', 'account-creation'),
          mdm: [
            'mdmService',
            function(mdmService) {
              return mdmService.fill(['civilites']);
            },
          ],
        },
        data: {
          roles: [USER_ROLES.guest],
        },
      })
      .state('app.user.account-creation-confirmation', {
        url: '/account-creation-confirmation?username&token',
        controller: 'accountCreationConfirmationController',
        templateUrl: 'user/account/account-creation-confirmation/account-creation-confirmation.html',
        resolve: {
          userAccountCreationConfirmationConfiguration: resolveConfiguration('user', 'account-creation-confirmation'),
        },
        data: {
          roles: [USER_ROLES.guest],
        },
      })
      .state('app.user.account-recover-informations', {
        url: '/account-recover-informations',
        controller: 'accountRecoverInformationsController',
        templateUrl: 'user/account/account-recover-informations/account-recover-informations.html',
        resolve: {
          userAccountRecoverInformationsConfiguration: resolveConfiguration('user', 'account-recover-informations'),
        },
        data: {
          roles: [USER_ROLES.guest],
        },
      })
      .state('app.user.account-reset-password', {
        url: '/account-reset-password?username&token',
        controller: 'accountResetPasswordController',
        templateUrl: 'user/account/account-reset-password/account-reset-password.html',
        resolve: {
          userAccountResetPasswordConfiguration: resolveConfiguration('user', 'account-reset-password'),
          username: [
            '$stateParams',
            function($stateParams) {
              return $stateParams.username;
            },
          ],
          token: [
            '$stateParams',
            function($stateParams) {
              return $stateParams.token;
            },
          ],
        },
        data: {
          roles: [USER_ROLES.guest],
        },
      });
  },
]);
