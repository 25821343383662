'use strict';
angular.module('user.directives').directive('accountCreation', [
  '$location',
  'configuration',
  'mdmService',
  'accountManagementService',
  'alertsService',
  'validationService',
  'User',

  function ($location, configuration, mdmService, accountManagementService, alertsService, validationService, User) {
    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'user/user-directives/account-creation/account-creation.html',
      scope: {
        viewConfiguration: '=',
        mdm: '=',
        masterdata: '',
      },
      link: function (scope) {
        // Initialization of the user
        scope.user = new User();

        // Keep compatibility with mdm and masterdata sources, the priority is masterdata
        if (scope.masterdata && scope.masterdata.titresCivilites) {
          scope.civilitesOptions = 'civilite.title as civilite.title for civilite in civilites';
          scope.civilites = scope.masterdata.titresCivilites.items;
        } else if (scope.mdm && scope.mdm.civilites) {
          scope.civilitesOptions =
            'civilite.expand.libelle.value as civilite.expand.libelle.value for civilite in civilites';
          scope.civilites = scope.mdm.civilites.array;
        } else {
          scope.civilites = [];
        }

        // Object to bind confirmation variables
        scope.confirm = {};

        scope.accountSuccessfullyCreated = false;

        /**
         * Create account for the given user
         * @param user The user object, for which an account has to be created
         */
        scope.createAccount = function (user) {
          if (_.isEmpty(user.displayName)) {
            user.displayName = _.trim(_.get(user, 'name.givenName', '') + ' ' + _.get(user, 'name.familyName', ''));
          }

          // The user will be able to validate its new account thanks to this link
          var linkMail = $location
            .absUrl()
            .replace($location.path(), configuration.user.accountCreationConfirmationPath);
          accountManagementService.createAccount(user, linkMail).then(
            function success() {
              scope.user = new User();
              scope.confirmPassword = '';
              scope.confirmEmail = '';
              scope.accountForm.$setPristine();
              // When this boolean value is set to true, all fields of the "create account" page are hidden thanks to the ng-hide
              scope.accountSuccessfullyCreated = true;
              //Get the public settings to know the
              accountManagementService
                .getPublicSettings()
                .then(function (response) {
                  var data = response.data;
                  var delay = {};
                  var defaultTokenTime = 43200; //12 hours (in seconds)

                  //We parse the value into a moment() object, to easy convert
                  var intervalObject =
                    data && data.actionTokenTTL
                      ? moment.duration(data.actionTokenTTL, 'seconds')
                      : moment.duration(defaultTokenTime, 'seconds');

                  //Create the display object
                  delay.seconds = intervalObject.asSeconds();
                  delay.hours = intervalObject.asHours();
                  delay.days = intervalObject.asDays();
                  delay.human = intervalObject.humanize();

                  scope.$broadcast(
                    'alerts',
                    alertsService.getAlertSuccess('user.account-creation.confirmation', {
                      user: user,
                      delay: delay,
                    }),
                    'account-creation-alerts'
                  );
                })
                .catch(function () {
                  //If the query fails, 'doesnt matter', we show the standard message
                  scope.$broadcast(
                    'alerts',
                    alertsService.getAlertSuccess('user.account-creation.confirmation', {
                      user: user,
                    }),
                    'account-creation-alerts'
                  );
                });
            },
            function error(result) {
              scope.accountSuccessfullyCreated = false;

              // Conflict, can be a username (code 1)  or email (code 2) conflict
              if (result.status === 409) {
                if (result.data.code === 1) {
                  scope.$broadcast(
                    'alerts',
                    alertsService.getAlertError('user.account-creation.errors.username-conflict', {
                      user: user,
                    }),
                    'account-creation-alerts'
                  );
                  // Gives the focus to the field with jQuery
                  $('#userId').focus();
                } else if (result.data.code === 2) {
                  scope.$broadcast(
                    'alerts',
                    alertsService.getAlertError('user.account-creation.errors.email-conflict', {
                      user: user,
                    }),
                    'account-creation-alerts'
                  );
                  $('#email').focus();
                }
              } else {
                scope.$broadcast(
                  'alerts',
                  alertsService.getAlertError('user.account-creation.errors.other-error', {
                    user: user,
                  }),
                  'account-creation-alerts'
                );
              }
            }
          );
        };

        /**
         * Check if email respect RFCs
         */
        scope.validationService = validationService;
      },
    };
  },
]);
