angular.module('common.filters').filter('replace', function() {
  'use strict';
  return function(string, params) {
    if (string && params && angular.isObject(params)) {
      $.map(params, function(val, key) {
        string = string.replace(key, val);
      });
    }

    return string;
  };
});
