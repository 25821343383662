angular.module('portailDepotDemandeAide.user').controller('accountCreationController', [
  '$scope',
  'userAccountCreationConfiguration',
  'mdm',

  function($scope, userAccountCreationConfiguration, mdm) {
    'use strict';
    $scope.userAccountCreationConfiguration = userAccountCreationConfiguration;
    $scope.mdm = mdm;
  },
]);
