angular.module('portailDepotDemandeAide.depot').factory('planFinancementUtils', PlanFinancementUtils);

function PlanFinancementUtils() {
  return {
    FINANCEUR_PRIVILEGIE: 'FINANCEURPRIVILEGIE',
    /**
     * This method is used to retrieve lines with a financeur associated
     * with each line, since this information is only available after the demande
     * has been saved we need to retrieve it before saving to be able to make some
     * verifications
     * The caller handles the logic when lignes is empty
     * @param {Array} lignes an array of ligne
     * @returns the current lignes with financeur
     */
    getFinanceursFromLignes: function(lignes) {
      if (!lignes) throw new Error('getFinanceursFromLignes - lignes cannot be empty');
      // filter lignes with a defined financeur
      return lignes.filter((ligne) => _.get(ligne, 'financement.financeur'));
    },

    /**
     * This method is used to retrieve every financeur and their type attached to
     * a ligne in the plan de financement
     * @param {Array} postes the postes of the plan de financement
     * @returns an array with every lignes that have a financeur attached
     */
    extractFinanceursFromPostes: function(postes) {
      if (!postes) throw new Error('extractFinanceursFromPostes - postes cannot be empty');
      if (postes.length === 0) return [];

      postes = postes.filter(function(poste) {
        //? filter out the ligne fictive so we don't have to check for it in the loop
        return poste.reference !== 'MGS_POSTE_FICTIF';
      });
      var lignesToCheck = [];
      postes.forEach(function(poste) {
        const lignes = poste.lignes || [];
        const sousPostes = poste.sousPostes || [];

        // get lignes with financeurs - case where a ligne has mode UNIQUE_FINANCEUR
        const financeurs = this.getFinanceursFromLignes(lignes);
        if (financeurs.length > 0) lignesToCheck = lignesToCheck.concat(financeurs);

        // get sousPostes.lignes with financeurs
        // case where sousPoste has mode PRINCIPAL_FINANCEUR or MULTIPLE_FINANCEUR
        if (sousPostes.length !== 0) {
          sousPostes.forEach(function(sousPoste) {
            const lignes = sousPoste.lignes || [];
            if (lignes.length === 0) return;
            const financeurs = this.getFinanceursFromLignes(lignes);
            if (financeurs.length > 0) lignesToCheck = lignesToCheck.concat(financeurs);
          }, this); //! bad things will happen if this is forgotten
        }
      }, this);

      return lignesToCheck;
    },

    /**
     * This method is used to know the state of the montant in the current ligne
     * A ligne that is not filled will return undefined
     * A ligne that is filled, but that's not greater than 0 will return 0
     * A ligne that is filled, and that's greater than 0 will return 1
     * @param {Object} ligne the current ligne we want to check for the montant
     * @returns a number between -1 and 1 that will be used by the caller
     */
    checkMontant: function(ligne, allowedNegativeAmounts) {
      if (!ligne) throw new Error('checkMontant - ligne cannot be undefined');
      // return -1 if undefined; 0 if montant === 0; 1 if montant > 0
      var montantHT = ligne.montant && ligne.montant.ht;
      var montantTTC = ligne.montant && ligne.montant.ttc;

      if (_.isNil(montantHT) && _.isNil(montantTTC)) return -1;
      if (montantHT === 0 || montantTTC === 0) return 0;
      if (montantHT > 0 || montantTTC > 0 || allowedNegativeAmounts) return 1;
      throw new Error('checkMontant - montant is not undefined, nor equal to 0 nor superior to 0');
    },
    /**
     * check if sousPoste with mode MULTIPLE_FINANCEUR has a line without amount
     * @param {array} postes postes with financeur
     * @returns {Boolean} true if there's a line not filled in a MULTIPLE_FINANCEUR poste
     */
    checkPostesMultipleFinanceur: function(postes, allowedNegativeAmounts) {
      // get only sousPostes MULTIPLE_FINANCEUR
      const sousPostesMultipleFinanceur = _.flatMap(postes, (poste) => {
        return _.filter(poste.sousPostes || [], (sousPoste) => sousPoste.mode === 'MULTIPLE_FINANCEUR');
      });

      // check if there's a line without montant
      return sousPostesMultipleFinanceur.some((sousPoste) => {
        // fast return if there's no line
        if (_.isEmpty(sousPoste.lignes)) return false;
        return sousPoste.lignes.some((ligne) => this.checkMontant(ligne, allowedNegativeAmounts) <= 0, this);
      }, this);
    },
    /**
     * Check if there's a ligne with a montant
     * @param {array} lignes
     * @param {Boolean} allowedNegativeAmounts
     * @param {String} typeToCheck typeFinanceur to check
     * @returns {Boolean} false if a ligne has an amount > 0
     */
    checkFinanceur: function(lignes, allowedNegativeAmounts, typeToCheck) {
      return !lignes.some((ligne) => {
        const financeur = _.get(ligne, 'financement.financeur', {});

        let isTypeToCheck = true; // if we do not pass a typeToCheck in parameter, we consider that we'll check all financeur no matter what type they are

        // multi-fin partagé case: we check if there's a filled financeur privilégié
        if (typeToCheck === this.FINANCEUR_PRIVILEGIE) {
          isTypeToCheck = financeur.typeFinanceur === this.FINANCEUR_PRIVILEGIE || financeur.isFinanceurPrivilegier;
        }
        // multi-synchro case: we check if there's a filled financeur with organisation
        else if (typeToCheck === 'ORGANISATION') {
          isTypeToCheck = !_.isEmpty(financeur.organisation);
        }

        // check if montant is > 0 and if ligne has the wanted type of financeur
        return this.checkMontant(ligne, allowedNegativeAmounts) > 0 && isTypeToCheck;
      }, this);
    },

    /**
     * Returns the number with 2 digits after the decimal point
     * @param {number} number
     * @returns {number}
     */
    parseFloat2Dec: function(number) {
      if (isNaN(number)) throw new Error(`[parseFloat2Dec] the "number" param is not a valid number: ${number}`);

      const result = parseFloat(number.toFixed(2));

      // Need this to avoid "-0" => we want an unsigned 0
      return result === 0 ? 0 : result;
    },
  };
}
