angular.module('common.services').factory('groupesGestionService', groupeGestionService);

groupeGestionService.$inject = ['jwtSessionService'];

function groupeGestionService(jwtSessionService) {
  return {
    areGroupesGestionActive: () => {
      return jwtSessionService.user().groupeGestionActif;
    },
  };
}
