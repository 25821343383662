angular.module('tiers').directive('identificationMoralForm', [
  'tiersValidationService',
  'validationService',
  'tiersService',
  'StoreService',
  '$timeout',
  function(tiersValidationService, validationService, tiersService, StoreService, $timeout) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl:
        'tiers/tiers-directives/identification-form/includes/identification-moral-form/identification-moral-form.html',
      require: '^form',
      scope: {
        tiers: '=',
        mdm: '=',
        edit: '=',
        viewConfiguration: '=',
        forbiddenSiret: '=',
        filteredFormesJuridiques: '=',
        displayLegend: '<',
      },
      link: function(scope, element, attributes, formController) {
        //Get custom label if tiers sans SIRET
        tiersService.getReferentielTiersI18n().then((result) => {
          scope.viewConfiguration.fields.noSiretReason.label = result.tiers.sansSiret.motifAbsence;
        });

        // If next/previous, display existing tiers in vignette
        scope.existingTiers = StoreService.existingTiers.get();
        // If SIRET does not correspond to existing tiers in store, clean existing tiers
        if (
          scope.existingTiers &&
          scope.existingTiers.SIRET &&
          scope.existingTiers.SIRET.SIREN !== scope.tiers.SIRET.SIREN &&
          scope.existingTiers.SIRET.NIC !== scope.tiers.SIRET.NIC
        ) {
          StoreService.existingTiers.clean();
          delete scope.existingTiers;
        }

        /**
         * Fonction appelée lorsque le champ SIRET perd le focus
         * Permet de modifier le statut des champs
         */
        scope.blurFieldsSiret = function(fieldName) {
          if (scope.form[fieldName].$invalid) {
            scope.fields[fieldName].status = 'error';
          } else {
            scope.fields[fieldName].status = 'success';
          }
        };

        /**
         * Allow to control formeJuridique value and delete if it's not valued
         */
        scope.onFormeJuridiqueChange = function() {
          if (_.isEmpty(scope.tiers.formeJuridique)) {
            delete scope.tiers.formeJuridique;
          }
        };

        // Méthode de vérification du SIRET
        scope.checkSiretValidity = tiersValidationService.checkSiretValidity;

        // If SIRET or NIC can be edited, check for tiers with same SIRET and display vignette
        scope.searchExistingTiers = function(siren, nic) {
          if (scope.checkSiretValidity(siren, nic)) {
            tiersService.controlerUniciteSIRET(siren, nic).then((existingTiers) => {
              if (existingTiers.length > 0) {
                // We save the existingTiers in case of next/previous
                StoreService.existingTiers.set(existingTiers[0]);
                scope.existingTiers = existingTiers[0];
              } else {
                StoreService.existingTiers.clean();
                delete scope.existingTiers;
              }
            });
          } else {
            delete scope.existingTiers;
          }
        };

        /**
         * manage copy paste in siret (siren) field
         */
        scope.pasteSiret = function(event) {
          const pastedData = event.originalEvent.clipboardData.getData('text');
          const cleanedData = pastedData.replace(/\s/g, '');
          const toSIREN = cleanedData.substr(0, 9);
          const toNIC = cleanedData.substr(9, 5) || '';
          _.set(scope.tiers, 'SIRET.SIREN', toSIREN);
          _.set(scope.tiers, 'SIRET.NIC', toNIC);
          scope.form['SIREN'].$setDirty(true);
          scope.form['NIC'].$setDirty(true);
          $timeout(() => {
            scope.blurFieldsSiret('SIREN');
            scope.blurFieldsSiret('NIC');
            scope.searchExistingTiers(toSIREN, toNIC);
          });
        };

        // Check if email respect RFCs
        scope.validationService = validationService;

        // Form instance
        scope.form = formController;

        // Management of the SIREN and NIC fields
        scope.fields = {
          SIREN: {
            status: undefined,
          },
          NIC: {
            status: undefined,
          },
        };
        // We use timeout for update 'scope.fields' data
        // If user press 'next' and the siret is not valid
        // The error will be displayed
        $timeout(function() {
          // the siret is composed of siren and nic
          const fields = ['SIREN', 'NIC'];
          fields.forEach((field) => {
            if (scope.form[field].$invalid) {
              scope.fields[field].status = 'error';
            } else {
              scope.fields[field].status = 'success';
            }
          });
        });

        scope.onChangeFormeJuridique = function() {
          if (scope.tiers.formeJuridique === null) {
            scope.tiers.formeJuridique = undefined;
          }
        };

        function updateNafRequired(publicSettingsTiers, etrangere) {
          scope.nafRequired = _.get(publicSettingsTiers, 'naf.required', false) && !etrangere;

          // Gestion du NAF
          if (_.has(scope.viewConfiguration, 'fields.NAFNiv732')) {
            if (scope.nafRequired) {
              scope.viewConfiguration.fields.NAFNiv732.required = true;
              scope.viewConfiguration.fields.NAFNiv732.hidden = false;
            } else {
              scope.viewConfiguration.fields.NAFNiv732.required = false;
              if (_.isNil(scope.viewConfiguration.fields.NAFNiv732.hidden)) {
                scope.viewConfiguration.fields.NAFNiv732.hidden = false;
              }
            }
          }
        }

        if (_.has(scope.viewConfiguration, 'fields.NAFNiv732')) {
          tiersService.getPublicSettingsTiers().then(function(publicSettingsTiers) {
            scope.situationPrincipale = _.find(scope.tiers && scope.tiers.situations, { principale: true });
            const etrangere = scope.situationPrincipale && scope.situationPrincipale.etrangere;
            updateNafRequired(publicSettingsTiers, etrangere);
            scope.$watch('situationPrincipale.etrangere', (etrangere) => {
              updateNafRequired(publicSettingsTiers, etrangere);
            });
          });
        }
      },
    };
  },
]);
