'use strict';
// This service interacts with a CAS Rest exposition server and with the account-management service
// see https://wiki.jasig.org/display/casum/restful+api
// see http://gitserv/gitweb/?p=mgdis/backend/account-management.git;a=summary

angular
  .module('user.services.authentication-service', [
    'configuration',
    'jwt.session',
    'user.services.user-session-service',
  ])
  .factory('authenticationService', [
    '$http',
    '$q',
    'configuration',
    'accountManagementService',
    'userSessionService',
    'jwtSessionService',
    function ($http, $q, configuration, accountManagementService, userSessionService, jwtSessionService) {
      return {
        /**
         * Connect a user to the portal
         * @param username The username
         * @param password The password
         */
        login: function (username, password) {
          return jwtSessionService.login(username, password).then(function () {
            return userSessionService.getUser();
          });
        },

        /**
         * Disconnect a user from the portal
         */
        logout: function () {
          jwtSessionService.logout();
        },

        /**
         * Indicates if the current user is authenticated on the portal
         */
        isAuthenticated: function () {
          return !!userSessionService.getUserId();
        },

        /**
         * Indicates if the current user is authorized for parameters roles
         * @param authorizedRoles Roles for which the authorization has to be checked
         */
        isAuthorized: function (authorizedRoles) {
          if (!angular.isArray(authorizedRoles)) {
            authorizedRoles = [authorizedRoles];
          }
          return this.isAuthenticated() && authorizedRoles.indexOf(userSessionService.userRole) !== -1;
        },
      };
    },
  ]);
