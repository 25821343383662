/**
 * @ngdoc module
 * @module form
 * @name form.services
 * @description
 *
 *   Services related to form management.
 *
 *   This module is a part of {@link ../form/index.html form}.
 *
 *   TODO document the services.
 *
 */

angular.module('form.services', ['configuration']);