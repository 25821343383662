'use strict';
angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleInformationsGeneralesTroisController', infosComplTroisCtrl);

infosComplTroisCtrl.$inject = [
  '$scope',
  'configuration',
  'viewsService',
  '$timeout',
  'IFrameCommunicationManager',
  'StoreService',
];

function infosComplTroisCtrl($scope, configuration, viewsService, $timeout, IFrameCommunicationManager, StoreService) {
  const iFrameCommunicationManagers = [];
  const viewsCommunicationManager = new IFrameCommunicationManager('#viewsIframe3');
  iFrameCommunicationManagers.push(viewsCommunicationManager);

  const controleCompletude = _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false);

  $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageInformationsGenerales3', {});
  $scope.config = configuration;

  $scope.cleanNavigate();
  $scope.navigate.ns = $scope.informationsComplementaires3Configuration.ns;
  $scope.showErrors = $scope.showErrorsOnNavigate();
  $scope.stepsWizard.active = 'dossier';

  // Generation of the url to load the views.
  // Allows to know if we have to display the iframe or not
  const viewsPage = _.filter(_.get($scope, 'aide.views'), { page: 'pageInformationsGenerales3' });
  const goToNextPage = _.get($scope.pageOptions, 'actif') === false || _.isEmpty(viewsPage);

  // List of forms or master details of this page.
  // Generation of the url to load the views.
  StoreService.depot.pendingPromises.promise().then(() => {
    if (_.some(viewsPage)) {
      $scope.viewsIframeSrc = viewsService.getViewsIframeUrl(
        $scope.aide,
        'pageInformationsGenerales3',
        'demandeur',
        false,
        5,
        null,
        {
          'referentiel-tiers': [
            'Accept:application/vnd.mgdis.tiers-3.19.0+json',
            'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
          ],
        }
      );

      viewsCommunicationManager.manageEvent(updateViewsAide);
    }

    // Wait for iframes to be loaded
    $timeout(function() {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },
        '#viewsIframe3'
      );
    }, 0);
  });

  function updateViewsAide(msg) {
    viewsService.updateViewsEntity($scope, msg, $scope.aide, '#viewsIframe3', {
      showAllErrors: $scope.showErrors,
      skipRequiredErrors: controleCompletude,
    });
  }

  $scope.navigate.beforePrevious = () => {
    return validateViewsAndWaitForUpdate(true);
  };

  $scope.navigate.next = function(forget) {
    if (goToNextPage) {
      $scope.goToStep('indicateurs', forget);
    } else {
      // Envoi du message permettant de valider la saisie des Liste/Fiche / Formulaires
      validateViewsAndWaitForUpdate(controleCompletude)
        .then(() => {
          $scope.goToStep('indicateurs', forget);
        })
        .catch(() => {
          // iframe validation / user rejected the goToStep
        });
    }
  };

  /**
   * Check if the data set on the demande views are valid and wait for them to be updated on the demande in memory
   *
   * @param {boolean} ignoreAllFields option to ignore errors on fields
   * @returns {Promise} promise that resolves when the views are updated
   */
  function validateViewsAndWaitForUpdate(ignoreAllFields) {
    viewsCommunicationManager.sendEvent({
      action: 'validViews',
      options: { skipRequiredErrors: ignoreAllFields, showAllErrors: !ignoreAllFields },
    });

    return viewsCommunicationManager.manageEventWithPromise((msg, resolve, reject) => {
      viewsService.updateStateViews($scope, msg, resolve, reject, $scope.teleserviceConfiguration);
    });
  }

  if (goToNextPage) {
    $scope.navigate.next(true);
  }

  // Remove listeners
  $scope.$on('$destroy', function() {
    _.each(iFrameCommunicationManagers, (manager) => manager.close());
  });
}
