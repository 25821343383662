angular.module('portailDepotDemandeAide.dashboard').component('contributionsRecapitulatif', {
  templateUrl: 'dashboard/contributions/contributions-recapitulatif/contributions-recapitulatif.html',
  controller: /* @ngInject */ contributionsRecapitulatifController,
  bindings: {
    contribution: '=',
    contributionConfiguration: '=',
    avisConfiguration: '=',
    piecesConfiguration: '=',
    teleservice: '=',
  },
});

function contributionsRecapitulatifController() {
  var ctrl = this;

  ctrl.$onInit = function() {};

  ctrl.isPiecesJustificativesActive = function() {
    return _.get(ctrl.teleservice, 'workflow.contributionAvis.pagePieces.actif');
  };
}
