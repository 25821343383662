angular.module('tiers').component('logoInput', {
  templateUrl: 'tiers/tiers-components/logo-input/logo-input.component.html',
  controller: LogoInputController,
  bindings: {
    ngModel: '<',
    options: '<',
  },
  require: { ngModelCtrl: '?ngModel' },
});

LogoInputController.$inject = [
  '$q',
  '$translate',
  '$sce',
  '$element',
  'tiersService',
  'configuration',
  'numberService',
];

function LogoInputController($q, $translate, $sce, $element, tiersService, configuration, numberService) {
  const uploadFileField = $element.find('#uploadFileField');

  this.$onInit = () => {
    this.selection = null;
    this.errors = [];
    this.numberService = numberService;
    this.deleteConfirmation = false;

    this.logoPreviewSrc = this.ngModel;
    tiersService.getPublicSettingsTiers().then((settings) => {
      this.defaultPreviewSrc = _.get(settings, 'logo.defaultPreviewImageSrc');
      if (this.defaultPreviewSrc) {
        this.defaultPreviewSrc = this.defaultPreviewSrc.replace('{{tenantId}}', configuration.tenant.id);
      }
      this.logoPreviewSrc = this.ngModel || this.defaultPreviewSrc;
    });

    uploadFileField.filestyle({
      input: false,
      badge: false,
      buttonText: $translate.instant('tiers.identification.logoInput.buttons.upload'),
      buttonName: 'btn btn-default',
      iconName: 'fa fa-download',
      disabled: !!this.ngModel,
    });
  };

  /**
   * Delete the current logo
   */
  this.removeLogo = () => {
    this.deleteConfirmation = false;
    updateLogo(undefined);
  };

  /**
   * Get the text displayed in the help block
   */
  this.helpText = () => {
    const params = {
      allowedExtensions: allowedExtensionsString(),
      maxDocumentSize: humanReadableSize(this.options.maxDocumentSize),
      maxWidth: this.options.maxWidth,
      maxHeight: this.options.maxHeight,
    };
    return $sce.trustAsHtml($translate.instant('tiers.identification.logoInput.helpText', params));
  };

  /**
   * Triggered when a file is selected to validate it and update the logo or display errors if invalid
   */
  this.onFileSelection = () => {
    if (!this.selection) {
      return;
    }

    return this.checkFileValidity(this.selection.file, this.selection.content).then((errors) => {
      this.errors = errors;

      if (this.errors.length > 0) {
        return;
      }

      updateLogo(this.selection.content);
    });
  };

  /**
   * Returns the allowed extensions joined by comma
   */
  const allowedExtensionsString = () => {
    return this.options && this.options.allowedExtensions && this.options.allowedExtensions.join(', ');
  };

  /**
   * Returns a size in bytes in a human readable format
   */
  const humanReadableSize = (size) => {
    const units = ['ko', 'Mo', 'Go', 'To'];
    const unitIndex = size > 0 ? Math.floor(Math.log(size) / Math.log(1000)) : 0;
    const unit = units[unitIndex];
    // Size displayed in the unit at index unitIndex in the units array with 2 significant digits precision (may be none if ends with ".00")
    const sizeInUnit = this.numberService.round(size / Math.pow(1000, unitIndex), 2);
    return `${sizeInUnit}${unit}`;
  };

  /**
   * Check if a file is valid and returns errors if it is not
   * @param {*} file
   */
  this.checkFileValidity = (file, base64Content) => {
    const deferred = $q.defer();
    const errors = [];

    if (!this.options) {
      return errors;
    }

    if (!isExtensionAllowed(file)) {
      const errorMessage = $translate.instant('tiers.identification.logoInput.errors.allowedExtensions', {
        allowedExtensions: allowedExtensionsString(),
      });
      errors.push(errorMessage);
      deferred.resolve(errors);
    } else if (file.size > this.options.maxDocumentSize * 1000) {
      const errorMessage = $translate.instant('tiers.identification.logoInput.errors.maxDocumentSize', {
        maxDocumentSize: humanReadableSize(this.options.maxDocumentSize),
      });
      errors.push(errorMessage);
      deferred.resolve(errors);
    } else {
      this.areImageDimensionsValid(base64Content)
        .then((areDimensionsValid) => {
          if (!areDimensionsValid) {
            const errorMessage = $translate.instant('tiers.identification.logoInput.errors.imageDimensions', {
              minWidth: this.options.minWidth,
              maxWidth: this.options.maxWidth,
              minHeight: this.options.minHeight,
              maxHeight: this.options.maxHeight,
            });
            errors.push(errorMessage);
          }
          deferred.resolve(errors);
        })
        .catch(() => {
          errors.push($translate.instant('tiers.identification.logoInput.errors.invalidImage'));
          deferred.resolve(errors);
        });
    }

    return deferred.promise;
  };

  /**
   * Check if the file extension is allowed
   * @param {*} file
   */
  const isExtensionAllowed = (file) => {
    const extension = file.name.split('.').pop();
    return !this.options.allowedExtensions || this.options.allowedExtensions.indexOf(extension) !== -1;
  };

  /**
   * Check if the dimensions of the image are valid according to the given constrainst
   * @param {*} base64Image
   */
  this.areImageDimensionsValid = (base64Image) => {
    const deferred = $q.defer();
    const img = new Image();

    img.src = base64Image;

    img.onload = () => {
      const validWidth =
        (!this.options.minWidth || img.width >= this.options.minWidth) &&
        (!this.options.maxWidth || img.width <= this.options.maxWidth);
      const validHeight =
        (!this.options.minHeight || img.height >= this.options.minHeight) &&
        (!this.options.maxHeight || img.height <= this.options.maxHeight);
      deferred.resolve(validWidth && validHeight);
    };

    img.onerror = () => deferred.reject();

    return deferred.promise;
  };

  /**
   * Update the logo (logoSrc is undefined when deleted or contains a data url / base64)
   * @param {*} logoSrc
   */
  const updateLogo = (logoSrc) => {
    this.ngModelCtrl && this.ngModelCtrl.$setViewValue(logoSrc);
    this.logoPreviewSrc = logoSrc || this.defaultPreviewSrc;
    uploadFileField.filestyle('disabled', !!logoSrc);
  };
}
