class RecapitulatifDecisionsController {
  /**
   * Initialize component
   * @returns {void}
   */
  $onInit() {
    this.isMultiDossiers = this.dossiersFinancement.length > 1;
  }
}

RecapitulatifDecisionsController.$inject = [];

angular.module('aides.recapitulatif').component('recapitulatifDecisions', {
  templateUrl: 'aides/aides-components/recapitulatif/decisions/recapitulatif-decisions.html',
  controller: RecapitulatifDecisionsController,
  bindings: {
    namespace: '<',
    decisions: '<',
    dossiersFinancement: '<',
  },
});
