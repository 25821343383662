'use strict';
angular.module('user.directives').directive('accountResetPassword', [
  'accountManagementService',
  'alertsService',

  function (accountManagementService, alertsService) {
    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'user/user-directives/account-reset-password/account-reset-password.html',
      scope: {
        username: '=',
        token: '=',
        viewConfiguration: '=',
      },
      link: function (scope) {
        scope.pwdManagement = {};

        /**
         * Validate the password of the user (called after a reset)
         * @param newPassword The new user's password which has to be validated
         */
        scope.validatePassword = function (newPassword) {
          accountManagementService.validatePassword(scope.username, scope.token, newPassword).then(
            function success() {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertSuccess('user.account-reset-password.confirmation'),
                'notification-reset-password'
              );
              scope.resetPasswordForm.$setPristine();
              scope.pwdManagement = {};
            },
            function error() {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertError('user.account-reset-password.error'),
                'notification-reset-password'
              );
            }
          );
        };
      },
    };
  },
]);
