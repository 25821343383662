'use strict';
angular.module('user.directives').directive('accountChangePassword', [
  'accountManagementService',
  'alertsService',
  'userSessionService',
  function (accountManagementService, alertsService, userSessionService) {
    /**
     * @param {*} scope - passe le scope souhaité
     * @param {*} validity  - validite du mot de passe, retourné par account-management
     * @param {*} value -  champ d'un form
     */
    function setPasswordInvalid(scope, validity, value) {
      // Taille minimum du mot
      if (_.indexOf(validity.brokenRules, 'minLength') >= 0) {
        _.set(scope, 'wrongPassword.minLength', validity.appliedRules.minLength.value);
        value.$setValidity('minLength', false);
      } else {
        value.$setValidity('minLength', true);
      }
      // Règles à respecter
      if (_.indexOf(validity.brokenRules, 'charsMix') >= 0) {
        _.set(scope, 'wrongPassword.minMatches', validity.appliedRules.charsMix.minMatches);
        value.$setValidity('charsMix', false);
      } else {
        value.$setValidity('charsMix', true);
      }
      // Caratères autorisés
      if (_.indexOf(validity.brokenRules, 'acceptedChars') >= 0) {
        value.$setValidity('acceptedChars', false);
      } else {
        value.$setValidity('acceptedChars', true);
      }
    }

    /**
     * @param {*} value - champ d'un form
     */
    function setPasswordValid(value) {
      var tabRules = ['minLength', 'acceptedChars', 'charsMix'];
      tabRules.forEach(function (rule) {
        value.$setValidity(rule, true);
      });
    }

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'user/user-directives/account-change-password/account-change-password.html',
      scope: {
        viewConfiguration: '=',
        user: '=',
      },
      link: function (scope) {
        // Initialize changePassword data
        scope.changePassword = {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        };

        if (userSessionService.getUser()) {
          scope.userId = userSessionService.getUser().id;
        }

        /**
         * Modify user password
         * @param {string} userId Id of the user whose password has to be changed
         * @param {object} changePassword - object containing old and new password
         * @returns {Promise<void>}
         */
        scope.modifyPassword = function (userId, changePassword) {
          return accountManagementService
            .modifyPassword(userId, changePassword.oldPassword, changePassword.newPassword)
            .then(
              function success() {
                scope.$broadcast(
                  'alerts',
                  alertsService.getAlertSuccess('user.account-change-password.success'),
                  'notification-modify-password'
                );
                scope.changePasswordForm.$setPristine();
                scope.changePassword = {
                  oldPassword: '',
                  newPassword: '',
                  confirmPassword: '',
                };
              },
              function error(result) {
                if (result.status === 401) {
                  scope.$broadcast(
                    'alerts',
                    alertsService.getAlertError('user.account-change-password.error.oldPassword'),
                    'notification-modify-password'
                  );
                } else {
                  switch (result.data && result.data.code) {
                    case 'ERR_PASSWORD_UNCHANGED':
                      scope.$broadcast(
                        'alerts',
                        alertsService.getAlertError('user.account-change-password.error.passwordUnchanged'),
                        'notification-modify-password'
                      );
                      break;
                    default:
                      scope.$broadcast(
                        'alerts',
                        alertsService.getAlertError('user.account-change-password.error.default'),
                        'notification-modify-password'
                      );
                      break;
                  }
                }
              }
            );
        };

        scope.$watch(
          'changePasswordForm.newPassword.$modelValue',
          _.debounce(function (val) {
            scope.$apply(function () {
              if (val) {
                accountManagementService.checkPassword(val).then(function (validity) {
                  if (validity.valid) {
                    setPasswordValid(scope.changePasswordForm.newPassword);
                  } else {
                    setPasswordInvalid(scope, validity, scope.changePasswordForm.newPassword);
                  }
                });
              }
            });
          }, 200)
        );
      },
    };
  },
]);
