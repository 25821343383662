/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name check-validation-field
 * @restrict A
 * @description
 *
 *   Applies a simple validation rule to a form field.
 *
 *   Takes an angular expression and evaluates it in the current scope, if it returns false the field will be set to invalid.
 *
 * @param {string} checkValidationField - The expression to evaluate
 *
 * @example
 *
 *   `<input type="text" ng-model="myModel" check-validation-field="myModel.length < 10">`
 *
 */

angular.module('form.directives').directive('checkValidationField', [

  function() {
    'use strict';
    return {
      require: 'ngModel',
      link: function(scope, elem, attrs, ctrl) {

        // if ngModel is not defined, we do nothing
        if (!ctrl) {
          return;
        }
        if (!attrs.checkValidationField) {
          return;
        }

        var watch = scope.$watch(attrs.checkValidationField, function(isValid) {
          ctrl.$setValidity('checkValidity', isValid);
        });

        scope.$on('$destroy',function () {
          watch();
        });
      }
    };
  }
]);
