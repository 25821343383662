angular.module('tiers').directive('relationsAutresAssociations', relationsAutresAssociations);

relationsAutresAssociations.$inject = ['tiersService', '$http', '$q'];

/** Fieldset for tiers association */
function relationsAutresAssociations(tiersService, $http, $q) {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: 'tiers/tiers-directives/relations-autres-associations/relations-autres-associations.html',
    scope: {
      tiers: '=',
      configuration: '=',
      namespace: '=',
    },
    link: function(scope) {
      scope.viewConfiguration = _.merge(
        {
          ns: scope.namespace + '.relations-autres-associations',
        },
        scope.configuration
      );

      // All data are written in thematique
      scope.thematiqueAssociation = _.get(scope.tiers, 'thematiquesLiees.association');

      // Get typesGroupement
      tiersService.getMasterData('typesGroupement').then(function(data) {
        scope.typesGroupement = {
          array: _.get(data, 'typesGroupement.items'),
        };
      });

      // Get federationsAffiliees from famille
      var federationsAffiliees = _.get(scope.tiers, 'famille.expand.federationsAffiliees', []);
      var federationsAffilieesReq = _.map(federationsAffiliees, function(fedLink) {
        return $http.get(fedLink.href).then(function(res) {
          return res.data;
        });
      });
      $q.all(federationsAffilieesReq).then(function(federations) {
        if (federations.length > 0) {
          // Split federations onto categories and items
          scope.federationsNiv1 = [];
          scope.federationsNiv2 = [];
          _.each(federations, function(federation) {
            var list = federation.niveau === 2 ? scope.federationsNiv2 : scope.federationsNiv1;
            list.push(federation);
          });
        }
      });

      // Transform the association's federationAffiliee into a list, and reciprocally
      scope.federationAffilieeList = [];
      if (_.get(scope.thematiqueAssociation, 'federationAffiliee')) {
        scope.federationAffilieeList.push(scope.thematiqueAssociation.federationAffiliee);
      }

      scope.$watch(
        'federationAffilieeList',
        function() {
          if (scope.federationAffilieeList.length > 0) {
            _.set(scope.thematiqueAssociation, 'federationAffiliee', scope.federationAffilieeList[0]);
          } else {
            _.unset(scope, 'thematiqueAssociation.federationAffiliee');
          }
        },
        true
      );
    },
  };
}
