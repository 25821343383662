/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name text-field
 * @scope
 * @restrict EA
 * @description
 *
 * Follow this recommendation from iframe-resizer: https://github.com/davidjbradshaw/iframe-resizer#iframe-not-detecting-textarea-resizes
 * But in the angular way, ie with a directive
 *
 * This will detect resizing of textareas or content editable fields and trigger resizing of the parent iframe if it is available and iframe-resizer was correctly loaded.
 *
 * @example
 *
 *   `<textarea text-resize-iframe></textarea>`
 *
 */
angular.module('form.directives').directive('textResizeIframe', [
  function() {
    'use strict';

    return {
      restrict: 'A',
      link: function(scope, element) {
        var x;
        var y;

        function store() {
          x = element[0].offsetWidth;
          y = element[0].offsetHeight;
        }

        element.on('mousemove', function() {
          if (element[0].offsetWidth !== x || element[0].offsetHeight !== y) {
            store();
            if ('parentIFrame' in window) {
              window.parentIFrame.size();
            }
          }
        });
      }
    };
  }
]);
