angular.module('tiers.model').factory('SiteWeb', [
  function() {
    'use strict';

    function SiteWeb(data) {
      data = data || {};
      this.OTHERTYPE = data.OTHERTYPE;
      this.TYPE = data.TYPE;
      this.lang = data.lang;
      this.value = data.value;
    }

    return SiteWeb;
  },
]);
