angular.module('common.services').factory('cmisService', function($http, $q, configuration, userSessionService) {
  'use strict';

  var _tenantId;
  var _url = _.get(configuration, 'documentCollect.url') || '/document-collect';

  // Configure either at provider level or directly service
  this.tenantId = function(tenantId) {
    _tenantId = tenantId;
  };
  this.url = function(url) {
    _url = url;
  };

  function _getUrl() {
    var baseUrl = _tenantId ? _url + '/' + _tenantId : _url;
    return baseUrl + '/root';
  }

  /**
   * Retourne les informations cmis d'un document
   *
   * @param {String} href Url du document
   * @return Properties cmis du document
   */
  function getDocumentProperties(href) {
    return $http.get(href).then(function(response) {
      return response;
    });
  }

  function getUrlDocuments(urlDocuments, objectId) {
    if (_.includes(urlDocuments, 'document-collect')) {
      urlDocuments = _.first(urlDocuments.split('/root'));
      urlDocuments += '/root?objectId=' + objectId;
    } else {
      urlDocuments += '/' + objectId;
    }

    return urlDocuments;
  }

  /**
   *  Set properties cmis d'un document
   *
   * @param {String} folder Répertoire du document
   * @param {Object} documentPiece document
   * @param {Object} response properties du document
   * @return Retourne le document avec ses propriétés cmis
   */
  function setPropertiesDocument(folder, documentPiece, response) {
    var objectId = _.get(response, 'data.properties.cmis:objectId.value') || response._id;
    documentPiece.expand.properties['cmis:objectId'] = {};
    documentPiece.expand.properties['cmis:objectId'].value = objectId;

    if (
      response.data.properties &&
      response.data.properties['cmis:name'] &&
      response.data.properties['entity:originalfilename']
    ) {
      _.set(documentPiece.expand.properties, 'cmis:name.value', _.get(response.data.properties, '.cmis:name.value'));
      _.set(
        documentPiece.expand.properties,
        'entity:originalfilename.value',
        _.get(response.data.properties, 'entity:originalfilename.value')
      );
    }
    documentPiece.id = getUrlDocuments(folder, objectId);
    documentPiece.title = response.data.properties['entity:originalfilename'].value;
    documentPiece.href = documentPiece.id + '&cmisselector=object';
    documentPiece.expand.properties['cmis:creationDate'] = {};
    documentPiece.expand.properties['cmis:creationDate'].value = new Date().toISOString();
    documentPiece.expand.isUploaded = true;
    documentPiece.expand.isUploading = false;
    documentPiece.expand.progress = 100;
    return documentPiece;
  }

  /**
   * Build the params arrays to update a document properties
   *
   * Returns an object with propertyId and propertyValue arrays for use in
   * requests to document-collect
   *
   * @param {string} kind kind of entity
   * @param {object} entity entity
   * @returns {object} object with propertyId and propertyValues arrays
   */
  function buildDocumentMetadatasUpdatesQueryParams(kind, entity) {
    if (_.isEmpty(kind)) {
      throw new Error('"kind" is required to update entity metadata');
    }

    if (_.isEmpty(entity)) {
      throw new Error('"entity" is required to update entity metadata');
    }

    const uri = entity.id || _.get(entity, '_links.self.href', '');
    if (_.isEmpty(uri)) {
      throw new Error('"entity" must have either an "id" or a link to itself');
    }

    const user = userSessionService.getUser();
    const updatedProperties = {
      'cmis:secondaryObjectTypeIds': 'entity:metadata',
      'entity:uri': uri,
      'entity:reference': entity.reference || '',
      'entity:referenceAdministrative': entity.referenceAdministrative || '',
      'entity:kind': kind,
      'entity:author': _.get(user, 'displayName', ''),
      'entity:document:date': new Date().toISOString(),
    };

    return propertiesObjectToArrays(updatedProperties);
  }

  /**
   * Transform an object containing properties to two arrays:
   * - propertyId that contains the keys of the properties
   * - propertyValue that contains the values of the properties
   *
   * @param {object} properties properties
   * @returns {object} object with propertyId and propertyValue arrays
   */
  function propertiesObjectToArrays(properties) {
    const groupedInArrays = {
      propertyId: [],
      propertyValue: [],
    };

    for (const property of Object.keys(properties)) {
      groupedInArrays.propertyId.push(property);
      groupedInArrays.propertyValue.push(properties[property]);
    }

    return groupedInArrays;
  }

  return {
    getBaseUrl: function() {
      return _getUrl();
    },

    getUrlDocuments: getUrlDocuments,

    createFolder: function(basePath, folderName) {
      var config = {
        params: {
          cmisaction: 'createFolder',
          'propertyId[0]': 'cmis:name',
          'propertyValue[0]': folderName,
          'propertyId[1]': 'cmis:objectTypeId',
          'propertyValue[1]': 'cmis:folder',
          succinct: true,
        },
      };

      return $http.post(_getUrl() + '/' + basePath, {}, config).then(function(response) {
        return response.data;
      });
    },

    /**
     * Méthode permettant de copier un document dans la ged
     *
     * @param {String} folder Répertoire du document dans la ged
     * @param {Object} documentPiece document à copier
     * @param {String} kind Type de l'entité
     * @param {Object} entity Entité
     * @return {Object} Retourne la copie de document ou le document si conflit
     */
    copyDocument(folder, documentPiece, kind, entity) {
      const objectId = _.get(documentPiece, 'expand.properties.cmis:objectId.value');
      const metadataUpdateParams = buildDocumentMetadatasUpdatesQueryParams(kind, entity);
      const queryParams = {
        cmisaction: 'createDocumentFromSource',
        sourceId: objectId,
        propertyId: metadataUpdateParams.propertyId,
        propertyValue: metadataUpdateParams.propertyValue,
      };

      // Copy document and update its properties
      return $http
        .post(folder, {}, { params: queryParams })
        .then(function(response) {
          setPropertiesDocument(folder, documentPiece, response);
          documentPiece.expand = _.merge(documentPiece.expand, response.data);
          return documentPiece;
        })
        .catch(function(err) {
          if (err.status === 409) {
            // If document already exists in folder get the properties of the given
            // document instead

            // /!\ Warning: the given document might not be the document that
            // already exists in the aide folder. So Deleting the returned
            // document will delete the given document instead of the document
            // that exists in the folder.
            const documentHref = _.get(documentPiece, 'href');

            return getDocumentProperties(documentHref)
              .then(function(documentCmis) {
                return setPropertiesDocument(folder, documentPiece, documentCmis);
              })
              .catch(function(err) {
                return documentPiece;
              });
          }
          throw err;
        });
    },

    /**
     * Expand document
     *
     * @param {Object} document
     * @returns {Promise<Object>} expanded document
     */
    expandDocument(document) {
      // If the document name is missing, fetch metadatas, else returns it unaltered
      if (_.get(document, 'expand.properties.name') || _.get(document, 'expand.properties["cmis:name"].value')) {
        return $q.resolve(document);
      }

      // Get the data from CMIS
      return getDocumentProperties(document.href).then(function(docProperties) {
        const properties = docProperties.data && docProperties.data.properties;
        if (properties) {
          document.expand = {
            properties,
          };
        }

        return document;
      });
    },
  };
});
