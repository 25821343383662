angular.module('portailDepotDemandeAide.depot').controller('depotSimpleBeneficiaireComplementaireController', [
  '$scope',
  'StoreService',
  'jsonpatch',
  'tiersService',
  function($scope, StoreService, jsonpatch, tiersService) {
    'use strict';

    // typefamilles that do not require any "informations complementaires"
    const infosComplementairesTypes = ['TIERS_ASSOCIATION', 'TIERS_ENTREPRISE'];
    $scope.beneficiaire = StoreService.beneficiaire.get();
    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsBeneficiaire;

    // Navigate but don't remember the page we are leaving
    if (
      !_.get($scope.pageOptions, 'complementaires.actif') ||
      ($scope.beneficiaire.famille.expand &&
        !_.includes(infosComplementairesTypes, $scope.beneficiaire.famille.expand.typeFamille))
    ) {
      $scope.goToStep('informations-generales', true);
    }

    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.informationsComplementairesBeneficiaireConfiguration.ns;
    const observer = jsonpatch.observe($scope.beneficiaire);

    $scope.navigate.next = function() {
      const patches = jsonpatch.generate(observer);
      if (!_.isEmpty(patches)) {
        tiersService.patchTiers($scope.beneficiaire.reference, patches, $scope.mdm).then(function(savedTiers) {
          const cleanedTiers = savedTiers.getCleanEntity();
          cleanedTiers.famille.expand = $scope.beneficiaire.famille.expand;
          StoreService.beneficiaire.set(cleanedTiers);
          $scope.goToStep('informations-generales');
        });
      } else {
        $scope.goToStep('informations-generales');
      }
    };
    $scope.informationsComplementairesDemandeurConfiguration.showErrors = $scope.showErrorsOnNavigate();

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'beneficiaire';
  },
]);
