angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleChoixFinanceurController', depotSimpleChoixFinanceurController);

depotSimpleChoixFinanceurController.$inject = [
  '$scope',
  '$translate',
  'teleservicesService',
  'alertsService',
  'depotSimpleService',
];

/**
 * Controller to manage "pageFinanceur" workflow step
 *
 * Some variables are herited from parent controller
 *
 * $scope.teleserviceConfiguration : configuration of the current teleservice
 * $scope.aide : the current aide
 *
 * @param {*} $scope
 */
function depotSimpleChoixFinanceurController(
  $scope,
  $translate,
  teleservicesService,
  alertsService,
  depotSimpleService
) {
  'use strict';

  var ctrl = this;

  // help text of the page
  ctrl.helpTopHtmlContent;

  ctrl.financeurPrincipal;

  function $init() {
    //Reinitialize navigation
    $scope.cleanNavigate();

    // Prepare new navigation
    $scope.navigate.ns = $scope.pageFinanceurConfiguration.ns;

    // Recalcul steps
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'financeur';

    $scope.helpTopHtmlContent = $translate.instant('teleservice.financeur.financeur.help');

    /**
     * @Override  call when trying to go to the next step
     */
    $scope.navigate.next = function() {
      var tsExtension;
      return teleservicesService
        .getTeleserviceExtension({
          hrefTeleservice: $scope.teleserviceConfiguration.id,
          hrefGroupeGestion: ctrl.financeurPrincipal.financeurPrivilegie.groupeGestion.href,
        })
        .then(function(teleserviceExtension) {
          if (teleserviceExtension) {
            tsExtension = teleserviceExtension;
            return teleservicesService.getTeleService($scope.teleserviceConfiguration.reference, null, {
              expand: 'thematiques',
              teleserviceExtension: teleserviceExtension.id,
            });
          } else {
            return teleservicesService.getTeleService($scope.teleserviceConfiguration.reference, null, {
              expand: 'thematiques',
            });
          }
        })
        .then(function(teleserviceWithExtension) {
          // remove the "workflow.simple" key to match the teleserviceConfiguration
          teleserviceWithExtension.workflow =
            teleserviceWithExtension.workflow[$scope.teleserviceConfiguration.workflow.type];
          teleserviceWithExtension.workflow.type = $scope.teleserviceConfiguration.workflow.type;

          var dateDebut = new Date(teleserviceWithExtension.dateDebut);
          var dateFin = new Date(teleserviceWithExtension.dateFin);
          var now = moment();
          // valide that teleservice with extension is open (it include financeur date)
          if (now.isAfter(dateFin) || now.isBefore(dateDebut)) {
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError(
                $translate.instant('teleservice.financeur.errors.depotImpossible', {
                  libelleTeleservice: teleserviceWithExtension.libelle.value,
                  libelleFournisseur: ctrl.financeurPrincipal.libelleCourt,
                  dateDebut: moment(dateDebut).format('DD/MM/YYYY'),
                  dateFin: moment(dateFin).format('DD/MM/YYYY'),
                })
              ),
              'financeur-alerts'
            );
          } else {
            var posteRecettePrincipal = _.get($scope.teleserviceConfiguration, 'workflow.pageFinanceur.posteRecette');

            // validate that there's a "poste recette principale"
            if (!posteRecettePrincipal) {
              $scope.$broadcast(
                'alerts',
                alertsService.getAlertError(
                  $translate.instant('teleservice.financeur.errors.noPostePrincipal', {
                    libelleTeleservice: teleserviceWithExtension.libelle.value,
                    libelleFournisseur: ctrl.financeurPrincipal.libelleCourt,
                  })
                ),
                'financeur-alerts'
              );
            } else if (tsExtension) {
              _.assign($scope.teleserviceConfiguration, teleserviceWithExtension);
              $scope.aide.teleserviceExtension = {
                href: tsExtension.id,
              };
              // Initialize plan financement at the beginning
              depotSimpleService.initPlanFinancementFields($scope.aide, $scope.teleserviceConfiguration);
              $scope.goToStep('preambule');
            } else {
              // reinit teleservice informations
              _.assign($scope.teleserviceConfiguration, teleserviceWithExtension);
              delete $scope.aide.teleserviceExtension;

              // Initialize plan financement at the beginning
              depotSimpleService.initPlanFinancementFields($scope.aide, $scope.teleserviceConfiguration);
              $scope.goToStep('preambule');
            }
          }
        });
    };
  }

  $scope.onFinanceurSelected = function(financeur) {
    ctrl.financeurPrincipal = financeur;
    if (financeur) {
      $scope.aide.financeurPrincipal = {
        href: financeur.tiers.href,
        title: financeur.financeurPrivilegie.libelleCourt,
        typeFinanceur: 'FINANCEURPRIVILEGIE',
        groupesGestion: [financeur.financeurPrivilegie.groupeGestion],
      };
    } else {
      delete $scope.aide.financeurPrincipal;
    }
  };

  $init();
}
