angular.module('portailDepotDemandeAide.dashboard', ['ui.router']).config([
  '$stateProvider',
  '$urlRouterProvider',
  function($stateProvider) {
    'use strict';

    const resolveViewConfiguration = function(module, view, namespace) {
      return [
        'viewManagerService',
        '$log',
        '$rootScope',
        function(viewManagerService, $log, $rootScope) {
          return $rootScope.configurationPromise.then(function() {
            return viewManagerService.getViewConfiguration(module, view, 'teleservice.' + namespace);
          });
        },
      ];
    };

    const resolveConfiguration = function(viewManagerService, $rootScope, module, view) {
      return $rootScope.configurationPromise.then(function() {
        return viewManagerService.getViewConfiguration(module, view);
      });
    };

    $stateProvider
      .state('app.connected.dashboard', {
        url: '/dashboard',
        abstract: true,
        template: '<div ui-view></div>',
        resolve: {
          tiers: function(tiersService, mdm) {
            return tiersService.getCurrentTiers(mdm);
          },
        },
      })
      .state('app.connected.dashboard.accueil', {
        url:
          '/accueil?email&token&unauthorized&teleServiceNotFound&maximumPersistenceReach&maximumOffresStageReached&teleserviceNotInvited',
        component: 'dashboard',
        resolve: {
          offresStageActif: function(jwtSessionService) {
            return _.get(jwtSessionService.user(), 'offresStageActif');
          },
          referent: (tiersService, tiers) => {
            if (!tiers || !_.includes(['SUPPORTED', 'BLOCKED', 'OBSOLETE'], tiers.status)) {
              return;
            }

            return tiersService.getTiersReferent(tiers.id);
          },
        },
      })
      .state('app.connected.dashboard.deposerDemandeAide', {
        url: '/deposerDemandeAide',
        controller: 'dashboardDeposerDemandeAideController',
        templateUrl: 'dashboard/deposer-demande-aide/deposer-demande-aide.html',
        resolve: {
          teleservices: function(teleservicesService, userSessionService, tiers) {
            if (userSessionService.getUser()) {
              const sources = ['title', 'workflow.familles'];
              return teleservicesService.getListTeleServices(tiers, 'simple', sources, { noInvitation: true });
            } else {
              return [];
            }
          },
          familles: function(tiersService) {
            return tiersService.getFamilles();
          },
        },
      })
      .state('app.connected.dashboard.aides', {
        url: '/aides?justificationOnly',
        abstract: true,
        // Redirect to child state automatically https://github.com/angular-ui/ui-router/issues/948
        redirectTo: 'app.connected.dashboard.aides.demandesAides',
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.aides.demandesAides', {
        url: '/suivreDemandesAides?page',
        controller: 'dashboardDemandesAidesController',
        templateUrl: 'dashboard/aides/demandes-aides/demandes-aides.html',
        params: {
          page: {
            value: '1',
            dynamic: true,
          },
        },
        resolve: {
          // Public Settings Financement
          publicSettingsFinancement: [
            'aidesService',
            function(aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],
          aidesPagination: (configuration) => {
            return {
              itemsPerPage: _.get(configuration, 'aides.pagination.itemsPerPage', 10),
              itemsTotal: 0,
            };
          },
          aidesResponse: (aidesService, aidesPagination, $stateParams) => {
            if ($stateParams.justificationOnly) return {};

            let page = Number($stateParams.page) - 1 || 0;
            page = page < 0 ? 0 : page;

            return aidesService.getAides(
              [
                'demandesComplementPieces',
                'demandeur',
                'dispositif',
                'teleservice',
                'justification',
                'demandesReport',
                'dossiersFinancement',
              ],
              aidesPagination.itemsPerPage * page,
              aidesPagination.itemsPerPage
            );
          },
          aides: (aidesResponse, aidesPagination) => {
            aidesPagination.itemsTotal = aidesResponse.total || 0;
            return aidesResponse.hits;
          },
          aidesJustifications: function(aidesService, aidesPagination, $stateParams, JustificationsConstant) {
            // Get aides with justificationOnly
            if ($stateParams.justificationOnly) {
              const aideSearch = { status: JustificationsConstant.onGoingJustificationStatuts };
              let page = Number($stateParams.page) - 1 || 0;
              page = page < 0 ? 0 : page;
              const skip = aidesPagination.itemsPerPage * page;
              const top = aidesPagination.itemsPerPage;
              const expand = [
                'demandesComplementPieces',
                'demandeur',
                'dispositif',
                'teleservice',
                'justification',
                'demandesReport',
              ];
              // Request & handle result
              return aidesService.searchAides(aideSearch, skip, top, expand).then((res) => {
                aidesPagination.itemsTotal = res.total || 0;
                return res.hits || [];
              });
            } else {
              return null;
            }
          },
          teleservices: (teleservicesService, userSessionService, tiers) => {
            const sources = ['id', 'justification', 'duplicationDemandeFinancement'];
            return userSessionService.getUser()
              ? teleservicesService.getListTeleServices(tiers, 'simple', sources)
              : [];
          },
        },
      })
      .state('app.connected.dashboard.aides.demandesPaiement', {
        url: '/:reference/demandesPaiement?financeur&dossier&teleservicePaiement',
        abstract: true,
        template: '<div ui-view></div>',
        params: {
          aide: null,
          teleservicePaiement: null,
        },
        resolve: {
          aide: function($stateParams, aidesService) {
            if ($stateParams.aide) {
              return $stateParams.aide;
            } else {
              return aidesService.get($stateParams.reference);
            }
          },
          dispositifEligible: function(aide, demandesPaiementService, $stateParams) {
            const financeur = _.find(_.get(aide, 'multiFinancement.financeurs', []), ['href', $stateParams.financeur]);
            return demandesPaiementService.getDispositifEligible(aide, _.get(financeur, 'href'));
          },
          teleservicePaiement: function($stateParams, aide, teleservicesService, demandesAidesService) {
            const teleservicePaiement = _.get($stateParams, 'teleservicePaiement');
            let reference;
            if (teleservicePaiement) {
              if (!_.get(teleservicePaiement, 'reference')) {
                // Cas du multifinanceur (dans $stateParams.teleservicePaiement, on a le Href du teleservice)
                reference = teleservicePaiement.split('/').pop();
                return teleservicesService.getTeleService(reference);
              }
              return teleservicesService.getTeleService(teleservicePaiement.reference);
            } else {
              const ligneRegion = demandesAidesService.findLigneFinancementDispositifEligible(aide);
              const teleservicePaiementHref = _.get(ligneRegion, 'financement.teleservicePaiement.href', '');
              reference = teleservicePaiementHref.split('/').pop();
              return teleservicesService.getTeleService(reference);
            }
          },
          demandesPaiement: function($stateParams, aide, demandesPaiementService) {
            return $stateParams.dossier
              ? demandesPaiementService.getDemandesPaiementFromDossier(aide, $stateParams.dossier)
              : demandesPaiementService.getDemandesPaiementLignePlanFinancement(aide, $stateParams.financeur);
          },
          decisions: function($stateParams, aidesService) {
            return aidesService.getDecisionsHistory($stateParams.reference, $stateParams.dossier);
          },
        },
      })
      .state('app.connected.dashboard.aides.demandesPaiement.list', {
        url: '',
        controller: 'dashboardDemandesPaiementController',
        templateUrl: 'dashboard/aides/demandes-paiement/demandes-paiement.html',
      })
      .state('app.connected.dashboard.aides.demandesPaiement.recapitulatif', {
        url: '/:referencePaiement/recapitulatif',
        controller: 'demandePaiementRecapitulatifOnlyController',
        templateUrl: 'dashboard/aides/demandes-paiement/recapitulatif/demande-paiement-recapitulatif-only.html',
        resolve: {
          // Informations Generales
          informationsGeneralesConfiguration: resolveViewConfiguration(
            'demandePaiement',
            'informations-generales',
            'informations-generales'
          ),

          informationsComplementairesConfiguration: resolveViewConfiguration(
            'demandePaiement',
            'informations-complementaires',
            'informations-complementaires'
          ),

          planFinancementConfiguration: resolveViewConfiguration(
            'demandePaiement',
            'document-comptable',
            'document-comptable'
          ),

          // Domiciliation Bancaire
          domiciliationBancaireConfiguration: resolveViewConfiguration(
            'demandePaiement',
            'domiciliation-bancaire',
            'domiciliation-bancaire'
          ),

          // Pieces
          piecesConfiguration: resolveViewConfiguration('demandePaiement', 'pieces', 'pieces'),

          // Recapitulatif
          recapitulatifConfiguration: resolveViewConfiguration('demandePaiement', 'recapitulatif', 'recapitulatif'),

          // Confirmation
          confirmationConfiguration: resolveViewConfiguration('demandePaiement', 'confirmation', 'confirmation'),

          demandePaiement: function($stateParams, demandesPaiementService) {
            return demandesPaiementService.getDemandePaiement($stateParams.referencePaiement, {
              params: {
                $expand: 'pieces.documents,domiciliationBancaire.pieces.documents,demandeFinancement.teleservice',
              },
            });
          },
          // Configuration du teleservice
          teleserviceConfiguration: function(
            $state,
            $translate,
            $location,
            teleservicesService,
            configuration,
            teleservicePaiement
          ) {
            return teleservicesService
              .getTeleService(_.get(teleservicePaiement, 'reference'), false)
              .then(function(teleservice) {
                // Modify by reference i18n's configuration and force the refresh.
                // Necessary to fetch files with dynamic names based on current teleservice
                configuration.i18n.teleservice.id = _.get(teleservicePaiement, 'reference');

                if (teleservice) {
                  configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                  return $translate.refresh().then(function() {
                    teleservice.workflow = teleservice.workflow[teleservice.workflow.type];
                    // copy type to control access in controller
                    teleservice.workflow.type = configuration.i18n.teleservice.workflow;
                    return teleservice;
                  });
                } else {
                  return $translate.refresh().then(function() {
                    return {};
                  });
                }
              });
          },
        },
      })
      .state('app.connected.dashboard.aides.demandesPaiement.complements', {
        url: '/:referencePaiement/complementsPaiement',
        controller: 'dashboardPaiementsDemandesComplementPiecesController',
        templateUrl:
          'dashboard/aides/demandes-paiement/demandes-complement-pieces/demandes-paiement-complement-pieces.html',
        resolve: {
          demandePaiement: function(demandesPaiementService, $stateParams) {
            return demandesPaiementService.getDemandePaiement($stateParams.referencePaiement, {
              params: { $expand: 'pieces.documents' },
            });
          },
          aideComplementsConfiguration: function(viewManagerService, $rootScope) {
            return resolveConfiguration(viewManagerService, $rootScope, 'aides', 'complements');
          },
        },
      })
      .state('app.connected.dashboard.aides.complements', {
        url: '/:reference/complements',
        controller: 'dashboardDemandesComplementPiecesController',
        templateUrl: 'dashboard/aides/demandes-complement-pieces/demandes-complement-pieces.html',
        params: {
          page: null,
        },
        resolve: {
          aide: function($stateParams, $http, $q, aidesService, mdm) {
            const properties = 'demandesComplementPieces,beneficiaires.pieces.documents,pieces.documents,teleservice';
            return aidesService.get($stateParams.reference, mdm, properties);
          },
          teleservice: function(aide, teleservicesService) {
            return teleservicesService.getTeleServiceFromObject(aide);
          },
          aideComplementsConfiguration: function(viewManagerService, dataCollectConfigurationService, $q, $rootScope) {
            const promises = [
              resolveConfiguration(viewManagerService, $rootScope, 'aides', 'complements'),
              dataCollectConfigurationService.get('complement'),
            ];
            return $q.all(promises).then(function(data) {
              return _.merge(data[0], data[1]);
            });
          },
          persistences: function(dataCollectPersistenceService, userSessionService) {
            if (userSessionService.getUser()) {
              return dataCollectPersistenceService.list();
            } else {
              return [];
            }
          },
          // Public Settings Financement
          publicSettingsFinancement: [
            'aidesService',
            function(aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],
          previousState: [
            '$state',
            function($state) {
              const params = angular.copy($state.params);
              delete params['#'];
              return {
                name: $state.current.name,
                params: params,
              };
            },
          ],
        },
      })
      .state('app.connected.dashboard.aides.demandedepotpieces', {
        url: '/:reference/depotpieces',
        controller: 'dashboardDemandeDepotPiecesController',
        templateUrl: 'dashboard/aides/demandes-depot-pieces/demandes-depot-pieces.html',
        params: {
          page: null,
        },
        resolve: {
          aide: function($stateParams, aidesService, mdm) {
            return aidesService.get(
              $stateParams.reference,
              mdm,
              'demandeur.pieces.documents,beneficiaires.pieces.documents,pieces.documents'
            );
          },
          teleservice: function(aide, teleservicesService) {
            return teleservicesService.getTeleServiceFromObject(aide);
          },
          piecesConfiguration: function(viewManagerService, $rootScope) {
            return resolveConfiguration(viewManagerService, $rootScope, 'aides', 'depotpieces');
          },
          publicSettingsFinancement: [
            'aidesService',
            function(aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],
        },
      })
      .state('app.connected.dashboard.recapitulatif', {
        url: '/:aide/recapitulatif?fromContribution&fromAttestations&fromEchange&statutContribution&from',
        controller: 'aideRecapitulatifController',
        templateUrl: 'dashboard/recapitulatif/recapitulatif.html',
        params: {
          page: null,
        },
        resolve: {
          contribution: function($stateParams, aidesService, contributionsService) {
            // don't resolve contribution when accessing echanges
            if ($stateParams.fromEchange) return;

            const hrefAide = aidesService.getAideHref($stateParams.aide);
            return contributionsService.getContributionModificationOrRedirectionNotClosedForDemande(hrefAide);
          },
          aide: function($stateParams, mdm, recapitulatifService, contribution) {
            let params = {};
            if (contribution) {
              params = { contribution: contribution.reference };
            }

            return recapitulatifService.getRecap($stateParams.aide, mdm, params);
          },
          teleservice: function(aide, teleservicesService, configuration, $translate) {
            return teleservicesService.getTeleServiceFromObject(aide).then(function(teleservice) {
              configuration.i18n.teleservice.id = teleservice.reference;
              configuration.i18n.teleservice.workflow = teleservice.workflow.type;
              return $translate.refresh().then(function() {
                return teleservice;
              });
            });
          },
          echangesActif: function(aide, teleservicesService) {
            return teleservicesService.getTeleServiceFromObject(aide, true).then(function(teleservice) {
              return teleservice.echangesActif;
            });
          },
          teleservicePaiement: function($stateParams, aide, teleservicesService, demandesAidesService) {
            const teleservicePaiement = _.get($stateParams, 'teleservicePaiement');
            let reference;
            if (!teleservicePaiement) {
              const ligneRegion = demandesAidesService.findLigneFinancementDispositifEligible(aide);
              const teleservicePaiementHref = _.get(ligneRegion, 'financement.teleservicePaiement.href', '');
              reference = teleservicePaiementHref.split('/').pop();
              return teleservicesService.getTeleService(reference);
            }

            if (!_.get(teleservicePaiement, 'reference')) {
              // Cas du multifinanceur (dans $stateParams.teleservicePaiement, on a le Href du teleservice)
              reference = teleservicePaiement.split('/').pop();
              return teleservicesService.getTeleService(reference);
            }
            return teleservicesService.getTeleService(teleservicePaiement.reference);
          },

          demandesPaiement: function($stateParams, aide, demandesPaiementService) {
            return demandesPaiementService.getDemandesPaiementLignePlanFinancement(aide, $stateParams.financeur);
          },
          decisions: function($stateParams, aidesService) {
            return aidesService.getDecisionsHistory($stateParams.aide);
          },
        },
        onEnter: function($rootScope, $stateParams, echangesActif) {
          $rootScope.echangesActif = echangesActif;
          $rootScope.displayEchangeMenu = { value: echangesActif, demandeId: $stateParams.aide };
        },
        onExit: function($rootScope) {
          $rootScope.displayEchangeMenu = { value: false };
        },
      })
      .state('app.connected.dashboard.contributionsAvisPrealable', {
        url: '/contributionsAvisPrealable',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.contributionsAvisPrealable.list', {
        url: '/:typeAvis',
        controller: 'contributionsAvisPrealableController',
        templateUrl: 'dashboard/contributions-avis-prealable/contributions-avis-prealable.html',
        params: {
          aides: null,
        },
        resolve: {
          aides: function($stateParams, tiers, avisPrealablesService, demandesAidesService) {
            if (_.isEmpty($stateParams.aides)) {
              return avisPrealablesService.getContributionsPourAvisPrealables([]).then(function(aidesWithAvis) {
                const aidesWithAvisRendu = _.filter(aidesWithAvis, function(aide) {
                  const avisTiers = avisPrealablesService.getAvisPrealableTiers(aide.avisPrealables, tiers);
                  if ($stateParams.typeAvis === 'avisADonner') {
                    return avisTiers && _.isEmpty(avisTiers.avis);
                  } else {
                    return avisTiers && !_.isEmpty(avisTiers.avis);
                  }
                });

                return demandesAidesService.getListeDemandes(aidesWithAvisRendu, tiers);
              });
            } else {
              return _.cloneDeep($stateParams.aides);
            }
          },
        },
      })
      .state('app.connected.dashboard.contributionsAvisPrealable.avis', {
        url: '/:typeAvis',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.contributionsAvisPrealable.avis.recapitulatif', {
        url: '/:reference/recapitulatif',
        controller: 'aideRecapitulatifController',
        templateUrl: 'dashboard/recapitulatif/recapitulatif.html',
        resolve: {
          // contribution need to be resolved to show the page
          contribution: () => null,
          aide: function($stateParams, mdm, avisPrealablesService) {
            return avisPrealablesService.getContributionPourAvisPrealables(
              $stateParams.reference,
              mdm,
              'demandeur.famille,' +
                'demandeur.formeJuridique,' +
                'beneficiaires.famille,' +
                'pieces.documents,' +
                'domiciliationBancaire.pieces.documents'
            );
          },
          teleservice: function(aide, teleservicesService, configuration, $translate) {
            return teleservicesService.getTeleServiceFromObject(aide).then(function(teleservice) {
              configuration.i18n.teleservice.id = teleservice.reference;
              configuration.i18n.teleservice.workflow = teleservice.workflow.type;
              return $translate.refresh().then(function() {
                return teleservice;
              });
            });
          },
          teleservicePaiement: function($stateParams, aide, teleservicesService, demandesAidesService) {
            const teleservicePaiement = _.get($stateParams, 'teleservicePaiement');
            let reference;
            if (!teleservicePaiement) {
              const ligneRegion = demandesAidesService.findLigneFinancementDispositifEligible(aide);
              const teleservicePaiementHref = _.get(ligneRegion, 'financement.teleservicePaiement.href', '');
              reference = teleservicePaiementHref.split('/').pop();
              return teleservicesService.getTeleService(reference);
            }

            if (!_.get(teleservicePaiement, 'reference')) {
              // Cas du multifinanceur (dans $stateParams.teleservicePaiement, on a le Href du teleservice)
              reference = teleservicePaiement.split('/').pop();
              return teleservicesService.getTeleService(reference);
            }
            return teleservicesService.getTeleService(teleservicePaiement.reference);
          },

          demandesPaiement: function($stateParams, aide, demandesPaiementService) {
            return demandesPaiementService.getDemandesPaiementLignePlanFinancement(aide, $stateParams.financeur);
          },
          decisions: function(aide, aidesService) {
            return aidesService.getDecisionsHistory(aide.reference);
          },
        },
      })
      .state('app.connected.dashboard.offresStage', {
        url: '/offresStage',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.offresStage.mesOffres', {
        url: '/mesOffres',
        controller: 'dashboardMesOffresController',
        templateUrl: 'dashboard/offres-stage/mes-offres/mes-offres.html',
        resolve: {
          offres: function(offresStageService) {
            return offresStageService.getOffresStage(0, 20);
          },
        },
      })
      .state('app.connected.dashboard.offresStage.recapitulatif', {
        url: '/:offreStage/recapitulatif',
        controller: 'dashboardOffresStageRecapitulatifController',
        templateUrl: 'dashboard/offres-stage/recapitulatif/recapitulatif-offre-stage.html',
        resolve: {
          offreStage: function($stateParams, offresStageService) {
            return offresStageService.getOffreStage($stateParams.offreStage);
          },
          piecesConfiguration: function(viewManagerService, $rootScope) {
            return resolveConfiguration(viewManagerService, $rootScope, 'offreStage', 'depotpieces');
          },
        },
      })
      .state('app.connected.dashboard.offresStage.notification', {
        url: '/:offreStage/notification',
        controller: 'dashboardNotificationOffreStageController',
        templateUrl: 'dashboard/offres-stage/notification/notification-offre-stage.html',
        resolve: {
          offre: function($stateParams, offresStageService) {
            return offresStageService.getOffreStage($stateParams.offreStage);
          },
          niveauxQualificationRequis: function(offresStageService) {
            return offresStageService.initNiveauxQualificationRequis();
          },
        },
      })
      .state('app.connected.dashboard.offresStage.pieces', {
        url: '/:offreStage/pieces',
        controller: 'dashboardOffresStagePiecesController',
        templateUrl: 'dashboard/offres-stage/pieces/pieces-offre-stage.html',
        resolve: {
          offreStage: function($stateParams, offresStageService) {
            return offresStageService.getOffreStage($stateParams.offreStage);
          },
          teleservice: function(offreStage, teleservicesService) {
            return teleservicesService.getTeleServiceFromObject(offreStage);
          },
          piecesConfiguration: function(viewManagerService, $rootScope) {
            return resolveConfiguration(viewManagerService, $rootScope, 'offreStage', 'depotpieces');
          },
          publicSettingsFinancement: [
            'aidesService',
            function(aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],
        },
      })
      .state('app.connected.dashboard.contributionsSearch', {
        url: '/contributionsSearch',
        component: 'contributionsSearch',
      })
      .state('app.connected.dashboard.contributions', {
        url: '/contributions',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.contributions.recapitulatif', {
        url: '/:referenceContribution/recapitulatif',
        component: 'contributionsRecapitulatif',
        resolve: {
          // Contribution
          contribution: [
            '$stateParams',
            'contributionsService',
            function($stateParams, contributionsService) {
              return contributionsService.get($stateParams.referenceContribution).then(function(contribution) {
                return contribution;
              });
            },
          ],
          teleservice: function(contribution, teleservicesService, configuration, $translate) {
            return teleservicesService.getTeleServiceFromObject(contribution).then(function(teleservice) {
              configuration.i18n.teleservice.id = teleservice.reference;
              configuration.i18n.teleservice.workflow = teleservice.workflow.type;
              return $translate.refresh().then(function() {
                return teleservice;
              });
            });
          },
          contributionConfiguration: function(viewManagerService, $rootScope, contribution, teleservice) {
            if (contribution.typeContribution === 'AVIS') {
              return resolveConfiguration(viewManagerService, $rootScope, 'contributionAvis', 'avis');
            } else {
              return _.get(teleservice, 'workflow.contributionAvisFinancement.pageContributionAvisFinancement');
            }
          },
          // contributionAvis
          avisConfiguration: resolveViewConfiguration('contributionAvis', 'avis', 'avis'),
          // Pieces
          piecesConfiguration: resolveViewConfiguration('contributionAvis', 'pieces', 'pieces'),
        },
      })
      .state('app.connected.dashboard.contributionsModificationList', {
        url: '/contributions/modifications/:demande',
        component: 'contributionsModifications',
        params: {
          page: null,
        },
        resolve: {
          contributionsDepart: function($stateParams, contributionsService) {
            const contribResults = {
              contributions: [],
              totalContributions: 0,
            };
            return contributionsService
              .searchModificationsForDemande($stateParams.demande, 0)
              .then(({ contributions, total }) => {
                const contribT = [];
                const contribNT = [];

                contributions.forEach((contribution = {}) => {
                  if (
                    (contribution.cloture && contribution.cloture.value) ||
                    !contribution.statut ||
                    !['asked', 'inprogress'].includes(contribution.statut.toLowerCase())
                  ) {
                    contribT.push(contribution);
                  } else {
                    contribNT.push(contribution);
                  }
                });

                contribResults.contributions = contribNT
                  .sort(contributionsService.sortByDateFunction)
                  .concat(contribT.sort(contributionsService.sortByDateFunction));
                contribResults.totalContributions = total;
                return contribResults;
              });
          },
          /** Resolve pour le breadcrumb */
          demande: function($stateParams, aidesService) {
            return aidesService.get($stateParams.demande);
          },
        },
      })
      .state('app.connected.dashboard.echanges', {
        url: '/echanges',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.echanges.centreEchanges', {
        url: '/centreEchanges',
        controller: 'dashboardCentreEchangesController',
        templateUrl: 'dashboard/echanges/centre-echanges/centre-echanges.html',
      })
      .state('app.connected.dashboard.attestations', {
        url: '/attestations',
        abstract: true,
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.attestations.mesAttestations', {
        url: '/mesAttestations',
        controller: 'dashboardMesAttestationsController',
        templateUrl: 'dashboard/attestations/mes-attestations/mes-attestations.html',
        resolve: {
          mesAttestations: function(aidesService) {
            return aidesService.getAttestations(0, 20);
          },
        },
      })
      .state('app.connected.dashboard.documentsReçus', {
        url: '/my',
        controller: 'dashboardMesPublicationsController',
        controllerAs: '$ctrl',
        templateUrl: 'dashboard/publication/mes-publications/mes-publications.html',
      })
      .state('app.connected.dashboard.attestations.confirmation', {
        url: '/confirmation?attestations',
        controller: 'dashboardConfirmationAttestationsController',
        templateUrl: 'dashboard/attestations/confirmation-attestations/confirmation-attestations.html',
        resolve: {
          attestationsTransmises: function(aidesService, $stateParams) {
            return aidesService.getAidesByReference($stateParams.attestations);
          },
        },
      })
      .state('app.connected.dashboard.justifications', {
        abstract: true,
        url: '/justifications',
        redirectTo: 'app.connected.dashboard.justifications.search',
        template: '<div ui-view></div>',
      })
      .state('app.connected.dashboard.justification', {
        url: '/justification/:justificationReference?recap',
        component: 'justificationComponent',
        resolve: {
          justificationAndExpandedPieces: [
            '$stateParams',
            'justificationService',
            function($stateParams, justificationService) {
              const select = [
                // Common
                'date',
                'active',
                'demandeFinancement',
                'kind',
                'reference',
                'statut',
                'teleservice',
                'tenant',
                'title',
                'user',
                'history',
                // Informations de réalisation - Informations Générales
                'miseEnOeuvre',
                'questionBeneficiaire',
                'beneficiaires',
                'nbBeneficiaires',
                'nbQuartiersPrioritaires',
                'dateDebut',
                'dateFin',
                'lieux',
                'objectifs',
                // Informations de réalisation - Informations Complémentaires
                'views',
                // Informations de réalisation - Moyens humains
                'moyensHumains',
                // Tableau de synthèse
                'tableau',
                // Données chiffrées
                'reglesRepartition',
                'explicationEcarts',
                'contributionsVolontaires',
                'observations',
                // Pieces
                // Recapitulatif
                'pieces',
                // Raison non réalisation (Téléservice NRA)
                'raisonNonRealisation',
              ];
              return justificationService.getJustificationAndExpandedPieces(
                $stateParams.justificationReference,
                select
              );
            },
          ],
          justification: [
            'justificationAndExpandedPieces',
            (justificationAndExpandedPieces) => justificationAndExpandedPieces.justification,
          ],
          piecesExpanded: [
            'justificationAndExpandedPieces',
            (justificationAndExpandedPieces) => justificationAndExpandedPieces.piecesExpanded,
          ],
          teleserviceJustification: [
            'justification',
            'justificationService',
            function(justification, justificationService) {
              return justificationService.getTeleservice(justification, [
                'workflow',
                'typeWorkflow',
                'allowedExtensions',
                'maxDocumentSize',
                'maxNumberDocuments',
                'documentComptable',
              ]);
            },
          ],
          /* @ngInject */
          demandeFinancement: (justification, $http, $log) =>
            $http
              .get(`${_.get(justification, 'demandeFinancement.href')}?expand=teleservice`)
              .then((aide) => _.get(aide, 'data'))
              .catch((error) => {
                $log.error(`Can't get demandeFinancement from justification ${error}`);
              }),
        },
      })
      .state('app.connected.dashboard.saisieDemandeReport', {
        url: '/demandes-report/:demandeReference',
        component: 'saisieDemandeReportComponent',
        resolve: {
          demandeReference: ['$stateParams', ($stateParams) => $stateParams.demandeReference],
        },
      });
  },
]);
