// Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationInformationsGeneralesComponent', {
  templateUrl: 'depot/justification/informations-generales/justification-informations-generales.component.html',
  controller: /* @ngInject */ JustificationInformationsGeneralesController,
  bindings: {
    workflow: '<',
    justification: '=',
    print: '<',
    validStep: '<',
    disableNavigation: '<',
    saveJustification: '<',
    stepIndex: '<',
  },
});

function JustificationInformationsGeneralesController($scope, $translate) {
  var ctrl = this;

  ctrl.$onInit = function() {
    ctrl.initLabels();
    ctrl.initRestrictions();

    ctrl.workflow.ns = 'justification';
  };

  ctrl.totalFemmes = function() {
    return _.sumBy(ctrl.justification.beneficiaires, 'nbFemmes');
  };

  ctrl.totalHommes = function() {
    return _.sumBy(ctrl.justification.beneficiaires, 'nbHommes');
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the previous step
   */
  ctrl.previousStep = function() {
    ctrl.validStep(-1);
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the next step
   */
  ctrl.nextStep = function() {
    if (ctrl.isFormValid()) {
      ctrl.validStep();
    }
  };

  /**
   * Method to check if the form is valid
   */
  ctrl.isFormValid = function() {
    return $scope.justificationInformationsGeneralesForm && $scope.justificationInformationsGeneralesForm.$valid;
  };

  /**
   * Method for the justification-step-wizard-navigation to save this step
   **/
  ctrl.saveStep = function() {
    ctrl.saveJustification();
  };

  /**
   * Initialize custom labels and use default if not found
   * @returns {void}
   */
  ctrl.initLabels = function() {
    // Groups name
    const groups = ctrl.workflow.groups || {};
    // Find value or use default value
    ctrl.labelIdentificationDemande = _.get(
      groups,
      'identificationDemande.label',
      $translate.instant('justification.informationsGenerales.demande.title')
    );
    ctrl.labelBilanQualitatifAction = _.get(
      groups,
      'bilanQualitatifAction.label',
      $translate.instant('justification.informationsGenerales.bilan.title')
    );
    ctrl.nonRealisationAction = _.get(
      groups,
      'nonRealisationAction.label',
      $translate.instant('justification.informationsGenerales.nonRealisation.title')
    );
  };

  /**
   * Init input fields restrictions
   * @returns {void}
   */
  ctrl.initRestrictions = () => {
    // Allow positive number only for input number fields
    const restrictions = [{ minInclusive: 0 }];
    // Apply to these fields
    const strictlyPositiveField = ['nbBeneficiaires', 'nbQuartiersPrioritaires'];
    strictlyPositiveField.forEach((field) => {
      _.set(ctrl.workflow, `fields.${field}.restrictions`, restrictions);
    });
    // Apply to independant fields
    ctrl.configStrictlyPositiveNumber = { restrictions };
  };

  /**
   * Calculate if the nb beneficiaire field shoud be disabled
   * true if the yes/no field is visible with "no" answer, and this field is visible
   * @returns {Boolean}
   */
  ctrl.displayNbBeneficiairesField = () =>
    ctrl.justification.questionBeneficiaire === false &&
    ctrl.workflow.fields.questionBeneficiaire.visible &&
    ctrl.workflow.fields.nbBeneficiaires.visible;

  /**
   * Calculate if the nb quartiers prioritaires field shoud be disabled
   * true if the yes/no field is visible, with "yes" or ("no" + previous field visible), and this field is visible
   * @returns {Boolean}
   */
  ctrl.displayNbQuartiersPrioritairesField = () =>
    ctrl.workflow.fields.questionBeneficiaire.visible &&
    (ctrl.justification.questionBeneficiaire === true ||
      (ctrl.justification.questionBeneficiaire === false && ctrl.workflow.fields.nbBeneficiaires.visible)) &&
    ctrl.workflow.fields.nbQuartiersPrioritaires.visible;
}
