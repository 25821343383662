/**
 * @ngdoc directive
 * @name tiers.directive:informationsComplementairesForm
 * @restrict EAC
 * @description Directive that displays more information of a tiers
 * @example
 * <informations-complementaires-form view-configuration="informationsComplementairesDemandeurConfiguration"
 *   tiers="demande.demandeur.expand">
 * </informations-complementaires-form>
 **/
angular.module('tiers').directive('informationsComplementairesForm', informationsComplementairesForm);

informationsComplementairesForm.$inject = ['jsonpatch', 'StoreService'];

function informationsComplementairesForm(jsonpatch, StoreService) {
  return {
    restrict: 'EA',
    replace: true,
    transclude: true,
    templateUrl: 'tiers/tiers-directives/informations-complementaires-form/informations-complementaires-form.html',
    scope: {
      edit: '=',
      tiers: '=',
      viewConfiguration: '=',
    },
    link: function(scope, element, attrs, formController) {
      // We made an object only with properties affected by this screen
      const selectedProperties = _.pick(scope.tiers, [
        'dateImmatriculation',
        'capitalSocial',
        'activitePrincipale',
        'appartenanceGroupe',
        'raisonSocialeGroupe',
        'comptesConsolides',
      ]);

      scope.entityToUpdate = _.cloneDeep(selectedProperties);

      if (selectedProperties.dateImmatriculation) {
        scope.dateImmatriculationDisplay = new Date(selectedProperties.dateImmatriculation);
        scope.dateImmatriculationTemp = _.clone(selectedProperties.dateImmatriculation);
      }

      //saving the changes in string type
      scope.onDateChange = function(kendoEvent) {
        const date = kendoEvent.sender.value();
        if (date instanceof Date) {
          const stringDate = date.toISOString();
          scope.entityToUpdate.dateImmatriculation = stringDate;
          scope.dateImmatriculationDisplay = date;
        } else {
          scope.entityToUpdate.dateImmatriculation = '';
        }
      };

      scope.dateImmatriculationPickerConfig = {
        min: '01/01/1900',
        max: new Date(),
        format: 'd',
        start: 'month',
        depth: 'month',
      };

      scope.montantOptions = {
        format: 'c2',
      };

      // Form instance
      scope.form = formController;

      scope.$watch(
        'entityToUpdate',
        (newValue, oldValue) => {
          if (newValue) {
            if (!newValue.appartenanceGroupe) {
              // Appartenance au groupe
              scope.entityToUpdate.raisonSocialeGroupe = undefined;
              scope.entityToUpdate.comptesConsolides = undefined;
            }

            const patches = jsonpatch.compare(selectedProperties, scope.entityToUpdate);

            // on init dateImmatriculation may have a different type but the same value
            // we don't send a patch in this case
            if (
              newValue.dateImmatriculation instanceof Date &&
              oldValue.dateImmatriculation === newValue.dateImmatriculation.toISOString() &&
              patches.length === 1
            ) {
              return;
            }

            _.forEach(patches, (patch) => {
              StoreService.tiers.patches.add(patch.op, patch.path, patch.value);
            });
            _.merge(scope.tiers, scope.entityToUpdate);
          }
        },
        true
      );
    },
  };
}
