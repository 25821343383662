// load configuration files from the resources of a portal to configure the views of our modules
// TODO: should not stay into the user module.

angular.module('form.services').factory('viewManagerService', ['$http', '$q', '$log', 'configuration',
  function($http, $q, $log, configuration) {
    'use strict';

    var basePath = configuration['resource-manager'].path;

    if (!basePath || !(angular.isString(basePath) || angular.isArray(basePath))) {
      $log.error('Missing key "resource-manager.path" in configuration, it should contain a string or an array of strings');
    }

    /**
     * Get a list of promises for download configurations on a module. We can hava multiple paths
     * @param  {[type]} moduleName Name of the module
     * @return {[type]}            List of promises
     */
    var getPromisesFilesConfiguration = function(moduleName) {
      var paths = angular.isArray(basePath) ? basePath : [basePath];

      return _.map(paths, function(path) {
        return $http({
          method: 'GET',
          url: path + '/' + moduleName + '.json',
          cache: true
        });
      });
    };

    // fetch a configuration for a view inside a complete configuration object
    var extractViewConfiguration = function(configuration, moduleName, viewId, namespace) {
      if (!configuration[moduleName] || !configuration[moduleName][viewId]) {
        $log.error('Missing configuration ' + moduleName + '.' + viewId);
        return null;
      }
      var viewConfiguration = configuration[moduleName][viewId];

      // namespaces will be used to fetch labels in i18n files for each fields
      viewConfiguration.ns = namespace || viewConfiguration.ns || moduleName + '.' + viewId;
      viewConfiguration.viewId = viewConfiguration.viewId || viewId;
      viewConfiguration.moduleName = viewConfiguration.moduleName || moduleName;

      if (viewConfiguration.fields) {
        _.each(viewConfiguration.fields, function(field) {
          field.ns = field.ns || viewConfiguration.ns;
        });
      }

      return viewConfiguration;
    };

    return {

      // Used for testing purpose otherwise see getViewConfiguration
      extractViewConfiguration: extractViewConfiguration,
      /**
       * Return a view configuration that can be the merge result of multiple subviews configuration
       * @param  {[type]} moduleName Name of the module
       * @param  {[type]} viewsId     Ids of the views configuration (a string or an array)
       * @return {[type]}            Configuration of the views
       */
      getViewConfiguration: function(moduleName, viewsId, namespace) {

        var deferred = $q.defer();

        $q.all(getPromisesFilesConfiguration(moduleName)).then(function(results) {

          var configuration = {};
          results.forEach(function(result) {
            $.extend(true, configuration, result.data);
          });

          var result = {};
          if (viewsId && angular.isArray(viewsId)) {
            // Extract multiple view configurations from a module
            for (var i in viewsId) {
              var viewId = viewsId[i];
              _.merge(result, extractViewConfiguration(configuration, moduleName, viewId, namespace));
            }
          } else if (viewsId) {
            // extract a single view configuration from a module
            result = extractViewConfiguration(configuration, moduleName, viewsId, namespace);
          } else {
            // get raw configuration object of a module
            result = configuration[moduleName];
          }

          deferred.resolve(result);
        },
        function(error) {
          deferred.reject(error);
        });

        return deferred.promise;
      }
    };
  }
]);