(function() {
  'use strict';

  angular.module('components.autorisations').factory('autorisationService', autorisationService);

  function autorisationService($http, configuration) {
    var _urlAgreementList = urljoin(configuration.user.accountManagement, 'agreements');

    return {
      getList: function(tenantId) {
        return $http
          .get(_urlAgreementList.replace('{{tenantId}}', tenantId))
          .then(function(response) {
            return _.filter(response.data.results, ['type', 'AUTORISATION']);
          })
          .catch(function(err) {
            throw new Error('Fail list agreement ', err);
          });
      },
    };
  }
})();
