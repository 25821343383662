'use strict';
angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotOffresStageRecapitulatifController', [
    '$scope',
    '$sce',
    '$state',
    '$log',
    'offresStageService',
    depotOffresStageRecapitulatifController,
  ]);

function depotOffresStageRecapitulatifController($scope, $sce, $state, $log, offresStageService) {
  var ctrl = this;
  ctrl.ns = 'offres-stage';

  ctrl.recapitulatifHTMLContent = $sce.trustAsHtml(_.get($scope, 'workflow.pageRecapitulatif.content'));

  ctrl.getFooter = function() {
    return $sce.trustAsHtml(_.get($scope, 'workflow.pageRecapitulatif.footer'));
  };

  ctrl.getHeader = function() {
    return $sce.trustAsHtml(_.get($scope, 'workflow.pageRecapitulatif.header'));
  };

  function init() {
    $scope.cleanNavigate();
    $scope.navigate.ns = 'teleservice.recapitulatif';
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'recapitulatif';

    $scope.navigate.validate = function() {
      $scope.attempted = true;
      if ($scope.cguAcceptation) {
        offresStageService.validOffreStage($scope.offreStage).then(function(offreStage) {
          $log.debug('offreStage - after creation :' + JSON.stringify(offreStage));
          $scope.goToStep('confirmation');
        });
      }
    };
  }
  init();
}
