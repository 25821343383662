angular.module('portailDepotDemandeAide.dashboard').directive('recapitulatifAvisPieces', [
  'piecesService',
  function(piecesService) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'dashboard/contributions/contributions-recapitulatif/pieces/recapitulatif-pieces.html',
      scope: {
        contribution: '=',
        piecesConfiguration: '=',
      },
      link: function(scope, element, attributes, controllers) {
        scope.viewConfiguration = scope.piecesConfiguration;

        scope.urlFileIcons = './resources/images/file-icons/';

        /**
         * Evaluate conditionAffichage (visible/hidden) setting on a piece
         * @method isPieceVisible
         * @param {Object} piece the piece
         * @returns {Boolean} the evaluate condition
         */
        scope.isPieceVisible = function(piece) {
          return piecesService.evalConditionPiece(scope, piece, 'conditionAffichage');
        };
      },
    };
  },
]);
