'use strict';
// store in the brower's memory some data about the connected user

angular.module('user.services.user-session-service', ['user.model']).factory('userSessionService', [
  'jwtSessionService',
  'User',
  function (jwtSessionService, User) {
    return {
      getUser: function () {
        var user = jwtSessionService.user();
        user = user ? new User(user) : null;
        return user;
      },

      getUserId: function () {
        var user = this.getUser();
        return user && user.id;
      },

      getSessionId: function () {
        return jwtSessionService.jwt();
      },
    };
  },
]);
