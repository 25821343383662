/**
 * @ngdoc service
 * @name common.services.paiementService
 * @requires
 * @description Manage a list of "paiement"
 **/
angular.module('common.services').factory('paiementService', function($http, $log, demandesPaiementService) {
  'use strict';

  var _tabPaiements = [];

  var getTabPaiement = function() {
    return _tabPaiements;
  };

  var setTabPaiement = function(lb, dateReal, montant, statut, montantPrevisionnel) {
    _tabPaiements.push({
      libelle: lb,
      date: dateReal,
      montant: montant,
      statut: statut,
      montantPrevisionnel,
    });
  };

  var getLibelle = function(dp) {
    return {
      typePaiement: _.get(dp, 'typePaiement.title') || _.get(dp, 'typePaiement.expand.libelle'),
      date: dp.dateReception,
    };
  };

  /**
   * Function to add demande paiement with transmitted status on tab
   * @param {object} dp demande paiement
   * @returns {void}
   */
  var addDPTransmis = function(dp) {
    const mt = _.get(dp, 'montantPropose.ttc', _.get(dp, 'montant.ttc', 0));
    const dtReal = dp.dateReception;
    setTabPaiement(dp.title, dtReal, mt, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
  };

  /**
   * Function to add demande paiement with proposed status on tab
   * @param {object} dp demande paiement
   * @returns {void}
   */
  var addDPPropose = function(dp) {
    var mt = _.get(dp, 'paiement.montantPropose.ttc');
    var dtReal = _.get(dp, 'paiement.dateProposition');
    setTabPaiement(dp.title, dtReal, mt, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
  };

  /**
   * Function to add demande paiement with liquidated status on tab
   * @param {object} dp demande paiement
   * @returns {void}
   */
  var addDPLiquide = function(dp) {
    var mt = demandesPaiementService.getMontantPaiementByTypeMontant(dp.paiement, 'montantLiquide');
    var dtReal = _.get(dp, 'paiement.liquidations.0.dateLiquidation', _.get(dp, 'paiement.dateLiquidation'));
    setTabPaiement(dp.title, dtReal, mt, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
  };

  /**
   * Function to add demande paiement with mandated status on tab
   * @param {object} dp demande paiement
   * @returns {void}
   */
  var addDPMandate = function(dp) {
    var mt = demandesPaiementService.getMontantPaiementByTypeMontant(dp.paiement, 'montantMandate');
    var dtReal = _.get(dp, 'paiement.liquidations.0.dateMandat', _.get(dp, 'paiement.dateMandat'));
    setTabPaiement(dp.title, dtReal, mt, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
  };

  /**
   * Function to add demande paiement with paid status on tab
   * @param {object} dp demande paiement
   * @returns {void}
   */
  var addDPPaye = function(dp) {
    var mt = demandesPaiementService.getFirstMontantPaiementFromTypesMontant(dp.paiement, [
      'montantPaye',
      'montantMandate',
    ]);
    var dtReal =
      _.get(dp, 'paiement.liquidations.0.datePaiement', _.get(dp, 'paiement.datePaiement')) ||
      _.get(dp, 'paiement.liquidations.0.dateMandat', _.get(dp, 'paiement.dateMandat'));
    setTabPaiement(dp.title, dtReal, mt, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
  };

  /**
   * Function to add demande paiement with financial rejected status on tab
   * @param {object} dp demande paiement
   * @returns {void}
   */
  var addDPRejetFinancier = function(dp) {
    var mt = demandesPaiementService.getMontantLePlusAvanceDemandePaiement(dp);
    var dtRejet = _.get(dp, 'paiement.liquidations.0.dateRejetFinancier');
    setTabPaiement(dp.title, dtRejet, mt, dp.statut, _.get(dp, 'montantPrevisionnel.ttc', 0));
  };

  // Suivant le statut, construction d'une ligne de paiement pour affichage
  var buildTabPaiement = function(demandesPaiement) {
    // Reinit _tabPaiements
    _tabPaiements = [];
    _.forEach(demandesPaiement, function(demandePaiement) {
      var statut = demandePaiement.statut;
      switch (statut) {
        case 'TRANSMIS':
        case 'REJETE':
        case 'INSTRUCTION':
        case 'REFUSE':
        case 'ACCEPTABLE':
        case 'SUPPORTED':
          addDPTransmis(demandePaiement);
          break;
        case 'PROPOSE':
          addDPPropose(demandePaiement);
          break;
        case 'LIQUIDE':
          addDPLiquide(demandePaiement);
          break;
        case 'MANDATE':
          addDPMandate(demandePaiement);
          break;
        case 'PAYE':
          addDPPaye(demandePaiement);
          break;
        case 'REJETFINANCIER':
          addDPRejetFinancier(demandePaiement);
          break;
        case 'EN_COURS':
          break;
        default:
          $log.error('Unrecognized statut [' + statut + '] ', demandePaiement);
          break;
      }
    });
    return getTabPaiement();
  };

  return {
    /**
     * Build paiements table
     * @param {Object[]} demandesPaiement
     * @returns {Object[]} paiements table
     */
    buildPaiementsTable: function(demandesPaiement) {
      // Sort by dateReception
      demandesPaiement = _.sortBy(demandesPaiement || [], function(demandePaiement) {
        return new Date(demandePaiement.dateReception);
      });

      // build paiements tab
      return buildTabPaiement(demandesPaiement);
    },

    /**
     * get the types of paiement
     * @param {string} teleservicePaiementId
     * @returns {Promise<object>}
     */
    getTypesPaiement: function(teleservicePaiementId) {
      return $http.get(teleservicePaiementId).then(function(response) {
        var typesPaiement = _.get(
          response,
          'data.workflow.demandePaiement.pageInformationsGenerales.typesPaiement',
          []
        );
        return typesPaiement;
      });
    },
  };
});
