/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name steps-wizard
 * @scope
 * @transclude
 * @restrict EA
 * @description
 *
 *   Display a steps wizard helper as seen on top of pages from a data collect workflow.
 *
 * @param {array<string>} steps - The list of step ids to display.
 * @param {string} labelsPrefix - The prefix to prepend to a step's id to get the key of the label to be fetched using angular-translate.
 * @param {string} active - The currently active step.
 *
 * @example
 *
 *   `<steps-wizard steps="['demandeur', 'beneficiaire', 'aide']" labels-prefix="'myConfig.steps'" active="demandeur"></steps--wizard>`
 *
 */

angular.module('form.directives').directive('stepsWizard', [
  function () {

    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'form/form-directives/stepswizard/stepswizard.html',
      scope: {
        steps: '=',
        labelsPrefix: '=',
        active : '=',
        stepLabel: '@',
        activeStepLabel: '@',
        customStepsNames: '='
      },
      link: function (scope) {
        // use custom steps names if provided and if array
        scope.useCustomNames = Array.isArray(scope.customStepsNames) && !!scope.customStepsNames.length;

        var watch = scope.$watch('steps', function () {
          if (scope.steps) {
            scope.width = 100 / scope.steps.length + '%';
          }
        }, true);

        scope.$on('$destroy', function () {
          watch();
        });

        /**
         * Check if the step is active or not
         * @param {number} index
         * @param {string} step
         * @return {boolean}
         */
        scope.isStepActive = function (index, step) {
          return index === scope.active || scope.active === step || step.indexOf(scope.active) !== -1;
        };
      },
    };
  },
]);
