// This service can be used to configure some global behavior for the form components
// for a whole application

angular.module('form.services').provider('formPreferences', [function() {
  'use strict';
  var _this = this;
  var _config = {};

  this.config = function(config) {
    if (config === undefined) {
      return _config;
    } else {
      _config = config;
    }
  };

  this.$get = [function() {
    return {
      config: _this.config
    };
  }];

}]);
