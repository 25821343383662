/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name bool-radio-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A basic boolean field for dynamic forms.
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 *
 * @example
 *
 *   `<bool-field name="demandeurIsBeneficiaire" view-configuration="identificationDemandeurConfiguration" data="persistence"></bool-field>`
 *
 */
angular.module('form.directives').directive('boolField', ['$timeout', 'formUtils',

  function($timeout, formUtils) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/bool-field/bool-field.html',
      scope: {
        name: '@',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=',
        valueTrue: '=',
        valueFalse: '=',
        bare: '=',
        readOnly: '=',
        remoteValidation: '=?',
        onChange: '&?',
        labelClass: '=?'
      }, link: function(scope) {
        scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
        scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');
				
        // Read remote validation from directive, configuration or viewConfiguration
        scope.remoteValidation = scope.remoteValidation !== undefined ? scope.remoteValidation :
          scope.configuration.remoteValidation !== undefined ? scope.configuration.remoteValidation :
            _.get(scope, 'viewConfiguration.remoteValidation') !== undefined ? _.get(scope, 'viewConfiguration.remoteValidation') : false;

        formUtils.wrapPrimitiveInObject(scope, 'model', 'data');

        // Callback on changes
        scope.change = function() {
          if (scope.onChange) {
            // Must wait scope to update
            $timeout(scope.onChange, 0);
          }
        };
      }
    };
  }
]);
