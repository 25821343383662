/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name tags-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A tags field: select several value from a source in a text-field using tags.
 *   By default, items are registered in Link objects ({href: '{{item.id}}'})
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {object} source - List of available data
 * @param {string} displayProperty - What to display and search from the browsed values (can't be deep)
 * @param {string} keyProperty - Key identifying each items from source (can't be deep) (default: 'id')
 * @param {bool} autocomplete - Enable autocomplete from a source (default: true)
 * @param {string} binding - How the field should register data: 'link' (default), 'clone' or 'value'
 * @param {bool} notRestricted - Allow the user to enter any value (not compatible with 'link' binding) (default: false)
 *
 * @example
 *
 *   `<tags-field
 *     name="intervenants"
 *     view-configuration="viewConfiguration"
 *     model="visite.intervenants"
 *     source="mdm.employes"
 *     display-key="completeName">
 *   </tags-field>`
 *
 */

angular.module('form.directives').directive('tagsField', ['$q', '$timeout',
  function($q, $timeout) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/tags-field/tags-field.html',
      require: '^form',
      scope: {
        name: '@',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=',
        source: '=',
        displayProperty: '@',
        keyProperty: '@?',
        autocomplete: '=?',
        placeholder: '@?',
        binding: '@?',
        nbResults: '@?',
        notRestricted: '=?',
        readOnly: '=?',
        displayInvalidTagMessage: '=',
        onChange: '&?',
        remoteValidation: '=?',
        labelClass: '=?'
      },
      link: function(scope, element, attrs, ctrl) {
        // Field configuration
        scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
        scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');
        scope.namespace = scope.namespace;
        scope.placeholder = scope.placeholder || scope.namespace + '.' + scope.name + '.placeholder';
        scope.readOnly = scope.readOnly || false;

        // Read remote validation from directive, configuration or viewConfiguration
        scope.remoteValidation = scope.remoteValidation !== undefined ? scope.remoteValidation :
          scope.configuration.remoteValidation !== undefined ? scope.configuration.remoteValidation :
            _.get(scope, 'viewConfiguration.remoteValidation') !== undefined ? _.get(scope, 'viewConfiguration.remoteValidation') : false;

        // Mode and default values
        scope.autocomplete = scope.autocomplete === undefined ? true : scope.autocomplete;
        scope.binding = scope.binding || 'link';
        scope.nbResults = scope.nbResults || 500;
        scope.notRestricted = (scope.notRestricted && scope.binding !== 'link') || false;
        scope.keyProperty = scope.binding === 'value' ? scope.displayProperty : scope.keyProperty || 'id';
        // scope.status.filled is used to aknowledge if the model is empty or not
        scope.status = {filled: ''};

        var prepareTagsData = function() {
          // Copying the model items into tagsData
          if (scope.binding === 'clone') {
            scope.tagsData = angular.copy(scope.model);
          }
          else {
            scope.tagsData = _.map(scope.model, function(item) {
              // Transforming labels into objects
              var tagItem = {};
              if (scope.binding === 'value') {
                tagItem[scope.displayProperty] = item;
              }
              // Using identifiers (by default, 'id')
              else if (scope.binding === 'link' || true) {
                tagItem[scope.keyProperty] = item.href;
                var itemSource = _.find(scope.source, function(masterItem) {
                  return masterItem[scope.keyProperty] === item.href;
                });
                tagItem[scope.displayProperty] = itemSource ? itemSource[scope.displayProperty] : item.title;

              }
              return tagItem;
            });
          }
        };

        scope.model = scope.model || [];
        if (!scope.autocomplete) {
          // without autocompletion, the model is just an array of strings, and there is no need for loading data
          scope.binding = 'value';
          scope.displayProperty = 'text';
          scope.notRestricted = true;
          scope.tagsData = _.map(scope.model, function(tag) {
            return {text: tag};
          });
          scope.loadingData = false;
        }
        else {
          scope.tagsData = [];
          scope.loadingData = true;
        }
        scope.$watch('source', function() {
          if (scope.source && scope.autocomplete) {
            prepareTagsData();
            scope.loadingData = false;
          }
        });

        var fieldUpdate = false;
        // Update tagsData when external change occures to the model
        scope.$watch('model', function() {
          // WARNING : changes coming from the field must not be taken into account!
          if (!fieldUpdate) {
            // Update tagsData
            if (scope.autocomplete && scope.source) {
              fieldUpdate = true;
              prepareTagsData();
            }
            else if (!scope.autocomplete) {
              fieldUpdate = true;
              _.each(scope.model, function(tag) {
                scope.tagsData.push({text: tag});
              });
            }
          }
          else {
            // Get ready for potential next edit
            fieldUpdate = false;
          }
        }, true);

        scope.$watch('tagsData', function(newVal) {
          // Each change, we copy what's into tagsData into model, transforming it accordingly
          if (newVal && !scope.loadingData) {
            scope.status.filled = _.isEmpty(newVal) ? '' : 'true';
            // WARNING : changes coming from outside must not be taken into account!
            if (!fieldUpdate) {
              fieldUpdate = true;
              scope.model = scope.tagsData.map(function(item) {
                if (scope.binding === 'value') {
                  // No autocomplete: only the tag is copied
                  return item[scope.displayProperty];
                }
                else if (scope.binding === 'clone') {
                  // Cloning the entire item into the model
                  return angular.copy(item);
                }
                else if (scope.binding === 'link' || true) {
                  // Default behavior: a Link is created using the identifier ('id')
                  return {
                    href: item[scope.keyProperty],
                    title: item[scope.displayProperty]
                  };
                }
              });
              if (scope.onChange) {
                $timeout(scope.onChange, 0);
              }
            }
            else {
              // Get ready for potential next edit
              fieldUpdate = false;
            }
          }
        }, true);

        // Watch form and field $valid fields
        if (scope.displayInvalidTagMessage) {
          scope.form = ctrl;
          scope.$watch('form.$valid', function(newValue) {
            if (newValue !== undefined) {
              scope.invalidForm = !newValue;

              // Force field $valid to true when form is $valid
              if (!scope.invalidForm) {
                scope.form[scope.name].$valid = newValue;
                scope.form[scope.name].$invalid = !newValue;
                scope.invalidField = !newValue;
              }
            }
          });
          scope.$watch('form[scope.name]', function(newValue) {
            if (newValue !== undefined) {
              scope.invalidField = !newValue;
            }
          });
        }

        // A tag is valid only if it has an identifier in the source of data
        // The function also update the invalid variable used to track errors on the field
        scope.validTag = function(value) {
          var returnValue = scope.notRestricted || !!(value[scope.keyProperty] && _.find(scope.source, function(item) {
            return item[scope.keyProperty] === value[scope.keyProperty];
          }));
          if (scope.displayInvalidTagMessage) {
            // $valid and $invalid are not updated automatically, so set them from here
            scope.form[scope.name].$valid = returnValue;
            scope.form[scope.name].$invalid = !returnValue;
            scope.invalidField = !returnValue;
          }
          return returnValue;
        };

        // searching from the source
        scope.filterSource = function(source, query, property) {
          return $q(function(resolve) {
            resolve(source.filter(function(item) {
              return !query || _.get(item, property).toUpperCase().indexOf(query.toUpperCase()) !== -1;
            }));
          });
        };
      }
    };
  }

]);
