/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name options-attributes
 * @require select
 * @restrict A
 * @description
 *
 *   Allow to add attributes to options in a select using ng-options
 *   Attributes are taken from the array on which ng-options is based. They are distributed using the array's indexes.
 *   However, if a "orderBy" or "groupBy" is used, it is advised to use "track by" to put the corresponding attributes to the right option.
 *
 * @param {object} optionsAttributes - object associating attributes to add with path to property in data
 *
 * @example
 *
 *   `<select name="city"
 *     ng-options="city as city.expand.label for city in listCities track by city.reference"
 *     options-attributes="{title: 'expand.label'}"></select>`
 */
angular.module('form.directives').directive('optionsAttributes', ['$timeout', function($timeout) {
  'use strict';

  return {
    restrict: 'A',
    replace: false,
    require: 'select',
    link: function(scope, element, attributes) {
      // Read parameters
      var attributesMap = scope.$eval(attributes.optionsAttributes);

      // Throw an error if the ngOptions attribute isn't defined
      if (!attributes.ngOptions) {
        throw Error('options-attributes used without ng-options');
      }
      // Read ng-options attribute to retrieve data to use
      var dataName = attributes.ngOptions.match(/for .+ in ([^ ^|]+)/)[1];
      var trackByMatches = attributes.ngOptions.match(/track by ([^ ^|]+)/);
      var trackBy = trackByMatches && trackByMatches[1] ? trackByMatches[1].split('.').slice(1).join('.') : '';

      scope.$watch(dataName, function(data) {
        if (data && attributesMap) {
          // Attributes must be added only once option elements are generated
          $timeout(function() {
            // Iterate only over non-empty option elements
            var optionsElements = _.filter($('option', element), function(el) {
              return !!$(el).attr('value');
            });

            _.each(optionsElements, function(el, i) {
              // The data used is associated with index by default
              var dataItem = data[i];
              if (trackBy) {
                // Otherwise, we find the corresponding data by comparing the property given in trackBy with the option's value
                dataItem = _.find(data, function(item) {
                  return _.get(item, trackBy) === $(el).attr('value');
                });
              }

              // Write attributes on the element, with selected data
              _.forOwn(attributesMap, function(key, attribute) {
                $(el).attr(attribute, _.get(dataItem, key));
              });
            });
          }, 0);
        }
      });
    }
  };
}]);
