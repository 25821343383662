/**
 * @ngdoc directive
 * @name tiers.directive: identificationFiche
 * @restrict EA
 * @description
 *
 *   Directive that displays tiers information like siret, NAF or personal information for physical tiers
 *   **Note:** This directive is used on several component's views (recapitulatif-tiers.html, demande-paiement-informations-generales.html...)
 *
 * @example
 * <identification-fiche tiers="demande.demandeur.expand" user="currentUser"
 *   view-configuration="identificationDemandeurConfiguration" read-only="true">
 * </identification-fiche>
 **/
angular.module('tiers').directive('identificationFiche', [
  '$modal',
  '$translate',
  'configuration',
  'tiersService',

  function($modal, $translate, configuration, tiersService) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'tiers/tiers-directives/identification-fiche/identification-fiche.html',
      scope: {
        tiers: '=',
        user: '=',
        edit: '=',
        mdm: '=',
        masterdata: '=',
        viewConfiguration: '=',
        readOnly: '=',
        correlationButton: '=',
        correlationAction: '&',
        validate: '&',
        editTiersHref: '=',
        editLink: '@',
        modified: '=',
        diff: '=',
      },
      link: function(scope) {
        // Shortcuts for access to fields
        scope.$watch('viewConfiguration', function() {
          scope.fields = _.get(scope, 'viewConfiguration.fields');
        });

        // Help tooltips
        scope.tooltips = {
          edit: {
            title: 'tiers.identification-fiche.actions.edit',
          },
        };

        // for PDF generation
        if (_.has(scope.tiers, 'famille.expand.d')) {
          _.set(scope.tiers, 'famille.expand', _.get(scope.tiers, 'famille.expand.d'));
        }

        if (_.has(scope.tiers, 'formeJuridique.expand.d')) {
          _.set(scope.tiers, 'formeJuridique.expand', _.get(scope.tiers, 'formeJuridique.expand.d'));
        }

        // Check if tiers has thematique association and if it's in an expand
        scope.thematiqueAssociation = _.get(scope.tiers, 'thematiquesLiees.association');

        const hasInscriptionRegistre =
          _.get(scope, 'thematiqueAssociation.inscriptionRegistre.date') ||
          _.get(scope, 'thematiqueAssociation.inscriptionRegistre.volume') ||
          _.get(scope, 'thematiqueAssociation.inscriptionRegistre.folio') ||
          _.get(scope, 'thematiqueAssociation.inscriptionRegistre.tribunal');

        if (hasInscriptionRegistre) {
          tiersService.getFamilleAssociationIdentification(scope.tiers).then((associationIdentification) => {
            scope.displayRegistreInscription =
              _.get(associationIdentification, 'inscriptionRegistreTribunalInstance.visible') && hasInscriptionRegistre;
          });
        }

        //Get custom label if tiers sans SIRET
        tiersService.getReferentielTiersI18n().then((result) => {
          scope.noSiretMotifAbsenceLabel = result.tiers.sansSiret.motifAbsence;
        });

        /**
         * Validation des modifications sur les caractéristiques du tiers
         * @param  {Modal} modal [description]
         */
        scope.updateCaracteristiques = function(modal, tiers) {
          scope.validate({
            tiers: tiers,
          });
          modal.$hide();
        };

        //If the url doesn't have a protocol, we add it manually
        //Like this, ng-href can manage the link
        scope.$watch('tiers.sitesWeb[0]', function(newValue) {
          if (newValue && !_.startsWith(newValue, 'http')) {
            scope.tiers.sitesWeb[0] = 'http://' + newValue;
          }
        });

        /**
         * Affichage de la la fenêtre modale de modification des caractéristiques du tiers
         */
        scope.editCaracteristiques = function() {
          if (configuration.tiers.modificationIdentificationTiersParComptes === true) {
            // Définition d'un scope isolé pour la fenêtre modale.
            // Création d'une copie de l'objet tiers(pivot) pour modification.
            var scopeModal = scope.$new();
            scopeModal.mdm = scope.mdm;
            scopeModal.tiers = angular.copy(scope.tiers);
            scopeModal.updateCaracteristiques = scope.updateCaracteristiques;

            $modal({
              scope: scopeModal,
              template: 'tiers/tiers-directives/identification-fiche/modal/modal-identification.html',
              backdrop: 'static',
            });
          } else {
            $modal({
              template: 'tiers/tiers-directives/warning-tiers/modal/modal-warning-modifications.html',
              backdrop: 'static',
            });
          }
        };
      },
    };
  },
]);
