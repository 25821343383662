angular.module('form.filters').filter('tryTranslate', ['$translate', function($translate) {
  'use strict';

  /**
  * Translate a label, and if no translation exists, return an empty string.
  */
  return function(lbKey) {
    return $translate.instant(lbKey) !== lbKey ? $translate.instant(lbKey) : '';
  };
}]);
