angular
  .module('portailDepotDemandeAide.depot')
  .factory('justificationTableauSyntheseService', justificationTableauSyntheseService);

function justificationTableauSyntheseService() {
  const sumReducer = (acc, currentValue) => acc + currentValue;

  /**
   * Get montantRealise from ligne. We use the fact that either ht or ttc exist, but not both
   *
   * @param {object} ligne
   * @returns {number}
   */
  const getLigneMontantRealise = (ligne) => {
    const montantHT = _.get(ligne, 'montantRealise.ht');
    const montantTTC = _.get(ligne, 'montantRealise.ttc');

    const finalMontant = montantHT >= 0 ? montantHT : montantTTC;

    if (finalMontant) return finalMontant;
    else return 0;
  };

  /**
   * Make the sum of montantRealise on all poste fitered by balance control poste liste
   * @param {string} resource depense or recette
   * @param {object} justificationTableau justification financial table
   * @param {Array<object>} postesControl balance control poste liste
   * @returns {number}
   */
  function sumMontantRealise(resource, justificationTableau, postesControl) {
    const postes = _.get(justificationTableau, `${resource}.postes`, []);
    const postesControlReferences = _.get(postesControl, resource, []).map((poste) => poste.reference);
    return postes
      .map((poste) => {
        // Get only lignes referenced in balance control list
        const lignes = _.filter(poste.lignes, (ligne) => _.includes(postesControlReferences, ligne.reference));
        if (!lignes.length) return 0;
        // Sum montantRealise of each fitered lines
        return lignes.map(getLigneMontantRealise).reduce(sumReducer);
      })
      .reduce(sumReducer);
  }

  return {
    /**
     * Check if balance control can be activated
     * @param {object} controleEquilibre
     */
    canBalanceControl: (controleEquilibre) => {
      // check if controleEquilibre type is BLOQUANT_PERSONNALISE and typeMontant is defined
      if (!controleEquilibre || controleEquilibre.type !== 'BLOQUANT_PERSONNALISE') return false;
      // check if postes are not empty
      return Boolean(
        _.get(controleEquilibre, 'postes.depense.length') && _.get(controleEquilibre, 'postes.recette.length')
      );
    },
    /**
     * Do the custom balance control check
     * @param {array} postes
     * @param {array} justificationTableau
     */
    balanceControl: (postes, justificationTableau) => {
      const sumMontantRealiseDepense = sumMontantRealise('depense', justificationTableau, postes);
      const sumMontantRealiseRecette = sumMontantRealise('recette', justificationTableau, postes);
      return _.round(sumMontantRealiseDepense, 2) === _.round(sumMontantRealiseRecette, 2);
    },
  };
}
