// Module declaration
angular.module('portailDepotDemandeAide.depot').component('justifComponent', {
  templateUrl: 'dashboard/justifications/justification/justification.component.html',
  controller: /* @ngInject */ JustifController,
  bindings: {
    demande: '<',
    currentTeleservice: '<',
  },
});

function JustifController(
  $translate,
  $rootScope,
  $log,
  $uibModal,
  $state,
  $filter,
  alertsService,
  justificationsService,
  justificationService,
  JustificationsConstant,
  teleservicesService
) {
  let ctrl = this;
  ctrl._translate = $translate;
  ctrl._rootScope = $rootScope;
  ctrl._modal = $uibModal;
  ctrl._alertsService = alertsService;
  ctrl._justificationsService = justificationsService;
  ctrl._justificationService = justificationService;
  ctrl._justificationsConstant = JustificationsConstant;
  ctrl._state = $state;

  /**
   * Checks if CRF and NRA teleservices are available in ctrl.currentTeleservice
   * @return {void}
   */
  const checkJustifTeleservices = () => {
    // Check available justif teleservices.
    ctrl.hasCRFTeleservice = isCurrentTeleserviceAvailable('CRF');
    ctrl.hasNRATeleservice = isCurrentTeleserviceAvailable('NRA');
  };

  ctrl.$onInit = function() {
    if (ctrl.currentTeleservice) {
      checkJustifTeleservices();
    } else {
      teleservicesService.getTeleServiceFromObject(ctrl.demande.aide, true).then((teleservice) => {
        if (Array.isArray(teleservice.justification.teleservices)) {
          ctrl.currentTeleservice = {
            id: teleservice.id,
            justifications: teleservice.justification.teleservices.map(({ href, title, justificationType }) => ({
              href,
              title,
              type: justificationType,
            })),
          };
        }
        checkJustifTeleservices();
      });
    }

    ctrl.aide = _.get(ctrl.demande, 'aide', {});
    // Get the expand (or the justif if no expand)
    ctrl.justification = _.get(ctrl.aide, 'justification.expand', _.get(ctrl.aide, 'justification', {}));
    ctrl.teleservice = _.get(ctrl.aide, 'teleservice.expand', {});

    ctrl.askDate = getAskDate();
    ctrl.creationDate = getCreationDate();
    ctrl.depositDate = getDepositDate();

    ctrl.justificationReadyToJustify =
      _.get(ctrl.justification, 'statut') === ctrl._justificationsConstant.justificationSubStatuts.READY_TO_JUSTIFY;
    ctrl.justificationEnded =
      _.has(ctrl.justification, 'reference') &&
      _.includes(
        [
          ctrl._justificationsConstant.justificationSubStatuts.JUSTIFIED,
          ctrl._justificationsConstant.justificationSubStatuts.VALIDATED,
        ],
        _.get(ctrl.justification, 'statut', '')
      );

    ctrl.justificationInProgress = !ctrl.justificationReadyToJustify && !ctrl.justificationEnded;
    ctrl.justificationReference = _.get(ctrl.justification, 'reference', '');
    ctrl.justificationName = _.get(ctrl.justification, 'teleservice.title', '');

    ctrl.isOwner = isOwner();
    ctrl.isJustificationOwner = isJustificationOwner();
    ctrl.createDemandeJustificationInProgress = false;
  };

  function getAskDate() {
    let askDate = '';
    const eventDate = ctrl._justificationService.getAskDate(ctrl.aide);
    if (eventDate) {
      askDate = ctrl._translate.instant('connected.dashboard.aides.search.askDate', {
        date: $filter('date')(eventDate, 'dd MMMM yyyy'),
        time: $filter('date')(eventDate, 'HH:mm:ss'),
      });
    }
    return askDate;
  }

  function getCreationDate() {
    let creationDate = '';
    const eventData = ctrl._justificationService.getCreationDate(ctrl.justification);
    if (eventData) {
      creationDate = ctrl._translate.instant('connected.dashboard.aides.search.creationDate', {
        user: eventData.userTitle,
        date: $filter('date')(eventData.date, 'dd MMMM yyyy'),
        time: $filter('date')(eventData.date, 'HH:mm:ss'),
      });
    }
    return creationDate;
  }

  function getDepositDate() {
    // Just in case no Transmitted event found
    let depotDate = ctrl._translate.instant(
      `connected.dashboard.aides.list.state-${ctrl._justificationsConstant.JUSTIFICATION_JUSTIFIED}`
    );
    const eventData = ctrl._justificationService.getDepositDate(ctrl.justification);
    if (eventData) {
      depotDate = ctrl._translate.instant('connected.dashboard.aides.search.depotDate', {
        user: eventData.userTitle,
        date: $filter('date')(eventData.date, 'dd MMMM yyyy'),
        time: $filter('date')(eventData.date, 'HH:mm:ss'),
      });
    }
    return depotDate;
  }

  /**
   * Checks if a teleservice justification is available
   * @param {String} type Teleservice type (CRF, NRA)
   */
  function isCurrentTeleserviceAvailable(type) {
    return !_.isNil(getTeleserviceByType(type));
  }

  /**
   * A "demande de report" is avaible only if :
   * - the justification is ready to justify
   * - there's a begin or end Date
   * - all demandes de report are completed
   * @return {boolean|*}
   */
  ctrl.isDemandeReportAvailable = function() {
    const date = _.get(ctrl.aide, 'periodeRealisation.dateFin') || _.get(ctrl.aide, 'dateRealisation');
    const allWithAvis = _.get(ctrl.aide, 'demandesReport', []).every(
      (demandeReport) => demandeReport.expand && demandeReport.expand.avisReport && demandeReport.expand.avisReport.avis
    );
    return ctrl.justificationReadyToJustify && allWithAvis && date;
  };

  ctrl.createDemandeReport = function() {
    ctrl._state.go('app.connected.dashboard.saisieDemandeReport', { demandeReference: ctrl.aide.reference });
  };

  /**
   * Get available justif teleservices with type
   * @param {String} type Teleservice type (CRF, NRA)
   */
  function getTeleserviceByType(type) {
    const justifList = _.get(ctrl.currentTeleservice, 'justifications', []);
    return _.find(justifList, (justif) => justif.type === type);
  }

  /**
   * Is the user that began the demande
   * @return {Boolean}
   */
  function isOwner() {
    return _.get(ctrl.demande, 'aide.history.begin.user.href') === ctrl._rootScope.currentUser.self;
  }

  /**
   * Is the user that began the justification
   * @return {Boolean}
   */
  function isJustificationOwner() {
    return _.get(ctrl.justification, 'history.begin.user.href') === ctrl._rootScope.currentUser.self;
  }

  ctrl.createJustification = function(type) {
    if (ctrl.createDemandeJustificationInProgress) {
      return;
    }

    ctrl.createDemandeJustificationInProgress = true;

    // Get the justif teleservice with the choosed type
    const teleservice = getTeleserviceByType(type);
    // Check that teleservice is found
    if (_.isNil(teleservice)) {
      // Should not come here because this function can only be called if justif teleservice exists
      $log.error(`Teleservice is null with type ${type}`);
      return;
    }
    const date = new Date().toISOString();
    const hrefTs = teleservice.href + '?date=' + date;
    const justification = {
      demandeFinancement: {
        href: _.get(ctrl.aide, 'id'),
        title: _.get(ctrl.aide, 'libelle.value'),
        referenceAdministrative: _.get(ctrl.aide, 'referenceAdministrative'),
      },
      kind: type,
      statut: 'REQUESTED',
      teleservice: {
        href: hrefTs,
        title: teleservice.title,
      },
    };
    ctrl._justificationsService.createJustification(justification).then((response) => {
      // redirect to justification
      ctrl._state.go('app.connected.dashboard.justification', { justificationReference: response.reference });
    });
  };

  ctrl.deleteJustification = function() {
    const instance = ctrl._modal.open({
      component: 'justificationsListDeleteModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        message: () => {
          //the message differs if the current user is the creator of the demande
          const key = isOwner() ? 'owner' : 'other';
          return ctrl._translate.instant(`connected.dashboard.aides.search.modal.message.${key}`);
        },
      },
    });

    //catching the result to handle modal.dismiss() as it throw a promise rejection
    const justifId = _.get(ctrl.justification, 'id');
    instance.result
      .then(() => ctrl._justificationsService.deleteJustification({ id: justifId }))
      .then((justificationRemovedId) => {
        if (justificationRemovedId && ctrl.justification.id === justificationRemovedId) {
          _.set(ctrl.demande, 'justification', { statut: 'READY_TO_JUSTIFY' });
          _.set(ctrl.demande, 'state', 'JUSTIFICATION_READY_TO_JUSTIFY');
          ctrl.justificationReadyToJustify = true;
          ctrl.justificationInProgress = false;
          ctrl.justificationReference = null;
        } else {
          ctrl.alerts = ctrl._alertsService.getAlertError(
            ctrl._translate.instant('connected.dashboard.justifications.search.delete', {
              libelleDemande: _.get(ctrl.demande, 'aide.libelle.value'),
            })
          );
        }
      });
  };
}
