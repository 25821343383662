/*
 * Module for the persistence of a user.
 */
angular.module('persistence', []);
/*
 * Service for the persistence of a user. This service list, get, create, remove and progress persistences.
 */
angular.module('persistence').factory('dataCollectPersistenceService', ['$http', 'configuration',
  function($http, configuration) {
    'use strict';

    var baseUrl = function(configurationId) {
      configurationId = configurationId || configuration.configurationId;
      if (configurationId) {
        return configuration.persistence + '/configuration/' + configurationId + '/persistence/';
      } else {
        return configuration.persistence + '/persistence/';
      }
    };

    return {
      list: function(configurationId) {
        return $http.get(baseUrl(configurationId)).then(function(response) {
          return response.data.results;
        });
      },
      get: function(id, configurationId) {
        return $http.get(baseUrl(configurationId) + id).then(function(response) {
          return response.data;
        });
      },
      create: function(content, configurationId) {
        return $http({
          method: 'POST',
          url: baseUrl(configurationId),
          data: content
        }).then(function(response) {
          return response.data;
        });
      },
      progress: function(id, content, configurationId) {
        return $http({
          method: 'PUT',
          url: baseUrl(configurationId) + id + '/content',
          data: content
        }).then(function(response) {
          return response.data;
        });
      },
      progressOrCreate: function(persistence, configurationId) {
        if (persistence._id) {
          return this.progress(persistence._id, persistence.content, configurationId);
        } else {
          return this.create(persistence, configurationId).then(function(newPersistence) {
            persistence._id = newPersistence._id;
          });
        }
      },
      submit: function(id, configurationId, definitive) {
        return $http({
          method: 'POST',
          url: baseUrl(configurationId) + id + '/definitive',
          data: definitive
        }).then(function(response){
          return response.data;
        });
      },
      remove: function(id, configurationId) {
        return $http({
          method: 'DELETE',
          url: baseUrl(configurationId) + id
        }).then(function(response){
          return response.data;
        });
      }
    };
  }
]);
/*
 * Service for the persistence configuration.
 */
angular.module('persistence').factory('dataCollectConfigurationService', ['$http', 'configuration',
  function($http, configuration) {
    'use strict';

    var _baseUrl = configuration.persistence + '/configuration/';

    var _transform = function(configuration){

      // Apply a transformation to a teleservice definition that will it much easier to use in the portals

      var optionsObject = {};
      _.each(configuration.options, function(option){
        // Each option is set with reference as the key
        optionsObject[option.reference] = (!angular.isUndefined(option.opinion) ? option.opinion : option.values || option.value);

        // If the option has a dot in its ref, then put it in a regrouping section in the configuration
        if (option.reference.indexOf('.') !== -1) {
          _.deepSetValue(configuration, option.reference, optionsObject[option.reference]);
        }
      });
      configuration.options = optionsObject;


      // Each field is set with reference as the key
      configuration.specificField = _.indexBy(configuration.specificField, 'reference');
      configuration.fields = _.indexBy(configuration.fields, 'reference');

      // If a field has a dot in its ref, the put it in a regrouping section in the configuration
      _.each(configuration.fields, function(field){
        if (field.reference.indexOf('.') !== -1) {
          _.deepSetValue(configuration, field.reference.replace('.', '.fields.'), field);
        }
      });


      return configuration;
    };

    return {
      list: function() {
        return $http.get(_baseUrl + '?active=true').then(function(response){
          return _.map(response.data.results, _transform);
        });
      },
      get: function(id) {
        return $http.get(_baseUrl + id).then(function(response){
          return _transform(response.data);
        });
      }
    };
  }
]);
/*
 * Service for the documents persistence of a user. This service list, get, create documents persistences.
 */
angular.module('persistence').factory('dataDocService', ['$http', '$q', '$log', 'configuration',
  function($http, $q, $log, configuration) {
    'use strict';


    var baseUrl = function(configurationId) {
      configurationId = configurationId || configuration.configurationId;
      if (configurationId) {
        return configuration.persistence + '/configuration/' + configurationId + '/persistence/';
      } else {
        return configuration.persistence + '/persistence/';
      }
    };

    return {

      /**
       * Return the url of service documents persistence
       * @param  {String} persistenceId Id of the persistence
       */
      getUrlServiceDocumentsPersistence: function(persistenceId, configurationId) {
        return baseUrl(configurationId) + persistenceId + '/documents';
      },

      /**
       * Service who return the list of documents in the persistence
       * @param  {String} persistenceId Id of the persistence
       */
      listDocuments: function(persistenceId, configurationId) {
        var deferred = $q.defer();
        $http.get(baseUrl(configurationId) + persistenceId + '/documents').success(function(data) {
          var documents = [];
          if (data && data.results) {
            documents = data.results;
          }
          deferred.resolve(documents);
        }).error(function(error) {
          $log.error(error);
          deferred.reject(error);
        });

        return deferred.promise;
      }
    };
  }
]);
angular.module('persistence').factory('portalPersistenceService', ['$http', 'configuration',
  function($http, configuration) {
    'use strict';

    var _baseUrl = configuration.portalPersistence + '/portal/' + configuration.portalId + '/persistence/';

    return {
      /**
       * Return a promise for obtain the persistence
       */
      getPersistence: function() {
        return $http.get(_baseUrl);
      },
      /**
       * Define a persistence
       * @param {Object} content Content to store in the persistence
       */
      setPersistence: function(content) {
        return $http({
          method: 'PUT',
          url: _baseUrl,
          data: content
        });
      }
    };
  }
]);