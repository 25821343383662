// Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationTableauSyntheseDepensesRecettesComponent', {
  templateUrl:
    'depot/justification/tableau-synthese-depenses-recettes/justification-tableau-synthese-depenses-recettes.component.html',
  controller: /* @ngInject */ JustificationTableauSyntheseDepensesRecettesController,
  bindings: {
    workflow: '<',
    justification: '=',
    resource: '<',
    print: '<',
    planFinancementModel: '<',
    typeMontant: '<',
    teleservice: '<',
  },
});

function JustificationTableauSyntheseDepensesRecettesController(
  $scope,
  $modal,
  $translate,
  justificationTableauSyntheseDepensesRecettesService
) {
  const ctrl = this;

  ctrl.$onInit = () => {
    ctrl.namespace = `justification.tableauSynthese.${ctrl.resource}`;
    ctrl.initLabels();
    ctrl.lignesFormConfiguration = {};
    // viewConfiguration for <recherche-multifinanceurs> component
    ctrl.viewConfiguration = { ns: ctrl.namespace + '.table' };
    ctrl.financeurs = justificationTableauSyntheseDepensesRecettesService.getFinanceurs(
      ctrl.justification,
      ctrl.resource
    );
    ctrl.initTable();
  };

  /**
   * Update the subtotal of each poste
   * @returns {void}
   */
  ctrl.updateMontantRealise = () => {
    (ctrl.postes || []).forEach((poste) => {
      // Add property if not exist and reset to 0
      poste.montantRealise = {
        ttc: 0,
        ht: 0,
      };
      (poste.lignes || []).forEach((ligne) => {
        const isCumulable = justificationTableauSyntheseDepensesRecettesService.isLineCumulable(
          ligne.reference,
          poste.reference,
          ctrl.planFinancementModel,
          ctrl.resource
        );
        if (isCumulable) {
          poste.montantRealise.ttc += ligne.montantRealise.ttc || 0;
          poste.montantRealise.ht += ligne.montantRealise.ht || 0;
        }
      });
      (poste.sousPostes || []).forEach((sousPoste) => {
        (sousPoste.lignes || []).forEach((ligne) => {
          poste.montantRealise.ttc += ligne.montantRealise.ttc || 0;
          poste.montantRealise.ht += ligne.montantRealise.ht || 0;
        });
      });

      // remove 0
      if (poste.montantRealise.ttc === 0) delete poste.montantRealise.ttc;
      if (poste.montantRealise.ht === 0) delete poste.montantRealise.ht;
    });
  };

  /**
   * Add a new line for the financeur tiers to the sous poste
   * @param {Object} tiers the financeur
   * @param {Object} sousPoste the sousPoste to which a new line is added
   * @returns {void}
   */
  ctrl.callbackAddFinanceur = function(tiers, sousPoste) {
    const montantNouvelleLigne = {};
    // For recette, typeMontant is TTC if mode MIXTE or TTC, else HT
    const typeMontant = ctrl.typeMontant === 'HT' ? 'ht' : 'ttc';
    montantNouvelleLigne[typeMontant] = 0;

    const nouvelleLigne = {
      poste: sousPoste.reference,
      reference: shortid.gen(),
      montant: montantNouvelleLigne,
      libelle: {
        value: tiers.libelleCourt,
      },
      financeur: {
        href: tiers.id,
        title: tiers.libelleCourt,
      },
      montantRealise: _.cloneDeep(montantNouvelleLigne),
    };

    sousPoste.lignes.push(nouvelleLigne);
    ctrl.lignesFormConfiguration[nouvelleLigne.reference] = {
      typeMontant,
    };
  };

  /**
   * Open a modal for the removal of a financeur (and its line) and on confirm proceeds
   * @param {Array<Object>} lignes
   * @param {Object} ligne
   * @returns {void}
   */
  ctrl.removeLigneFinanceur = (lignes, ligne) => {
    const scopeModal = $scope.$new();

    scopeModal.lignes = lignes;
    scopeModal.ligne = ligne;
    scopeModal.viewConfiguration = ctrl.viewConfiguration;
    scopeModal.confirmRemoveLigne = (lignes, ligne) => {
      _.remove(lignes, ligne);
      ctrl.updateMontantRealise();
    };
    $modal({
      scope: scopeModal,
      template: 'common/common-modal/confirm-delete-modal.html',
    });
  };

  /**
   * Returns true if the line has a montant equal to 0 and if the financeur of the line is not one the financeurs
   * @param {Object} line
   * @returns {boolean}
   */
  ctrl.isLigneFinanceurDeletable = (line) => {
    const typeMontant = ctrl.getTypeMontant(line.reference);
    return !ctrl.financeurs.includes(line.financeur.href) || !_.get(line, `montant.${typeMontant}`);
  };

  ctrl.addLine = (line) => {
    if (!line) return;
    const planFinancementLine = justificationTableauSyntheseDepensesRecettesService.getPlanFinancementLine(
      line.reference,
      _.get(line, 'poste.reference'),
      ctrl.planFinancementModel,
      ctrl.resource
    );
    if (!planFinancementLine) return;
    planFinancementLine.isUsed = true;
    ctrl.initTable();
  };

  /**
   * Check if ligne has amount in montantRealise or montant
   * @param {object} ligne
   * @returns {boolean}
   */
  ctrl.hasAmount = (ligne) => {
    const amount =
      _.get(ligne, `montantRealise.${ctrl.getTypeMontant(ligne.reference)}`) ||
      _.get(ligne, `montant.${ctrl.getTypeMontant(ligne.reference)}`) ||
      0;
    return amount > 0;
  };

  /**
   * In mixte mode, we get type montant from line's typeMontant
   * else we get it from ctrl.typeMontant
   * @param {string} reference line reference
   * @returns {string}
   */
  ctrl.getTypeMontant = (reference) => {
    return ctrl.typeMontant && ctrl.typeMontant !== 'MIXTE'
      ? ctrl.typeMontant.toLowerCase()
      : _.get(ctrl, `lignesFormConfiguration.${reference}.typeMontant`, 'ht');
  };

  /**
   * Update the type montant of a line
   * @param {object} ligne
   * @returns {void}
   */
  ctrl.updateTypeMontant = (ligne) => {
    const montant = _.get(ligne, 'montant.ht', _.get(ligne, 'montant.ttc'));
    const montantRealise = _.get(ligne, 'montantRealise.ht', _.get(ligne, 'montantRealise.ttc'));
    const typeMontant = ctrl.lignesFormConfiguration[ligne.reference].typeMontant;
    ligne.montant = {
      [typeMontant]: montant,
    };
    ligne.montantRealise = {
      [typeMontant]: montantRealise,
    };
    ctrl.updateMontantRealise();
  };

  ctrl.deleteLine = (line, lines) => {
    _.remove(lines, line);
    const planFinancementLine = justificationTableauSyntheseDepensesRecettesService.getPlanFinancementLine(
      line.reference,
      line.poste,
      ctrl.planFinancementModel,
      ctrl.resource
    );
    if (!planFinancementLine) return;

    planFinancementLine.isUsed = false;
    ctrl.initTable();
    ctrl.updateMontantRealise();
  };

  ctrl.isDeletable = (line) => {
    if (!line) return false;
    const typeMontant = ctrl.getTypeMontant(line.reference);
    // Checks if line has a default value
    const value = _.get(line, `montant.${typeMontant}`, 0);
    if (value > 0) {
      return false;
    }
    // else, we check if line is optional
    const planFinancementLine = justificationTableauSyntheseDepensesRecettesService.getPlanFinancementLine(
      line.reference,
      line.poste,
      ctrl.planFinancementModel,
      ctrl.resource
    );
    return _.has(planFinancementLine, 'optionnel');
  };

  ctrl.initTable = () => {
    let displayLines = justificationTableauSyntheseDepensesRecettesService.getLinesToDisplay(
      ctrl.justification,
      ctrl.resource,
      ctrl.teleservice,
      ctrl.planFinancementModel,
      ctrl.typeMontant
    );
    ctrl.postes = displayLines.activeLinePostes;
    ctrl.optionalLines = displayLines.optionalLines;
    ctrl.lignesFormConfiguration = displayLines.lignesFormConfiguration;
  };

  ctrl.computeRatioMontant = (ligne) => {
    const typeMontant = ctrl.getTypeMontant(ligne.reference);
    return (ligne.montantRealise[typeMontant] * 100) / ligne.montant[typeMontant];
  };

  /**
   * Initialize custom labels and use default if not found
   * @returns {void}
   */
  ctrl.initLabels = function() {
    const fields = _.get(ctrl.workflow, `fields.${ctrl.resource}`, {});

    /**
     * Translate a label for tableau synthese
     * @param {string} key
     * @returns {string}
     */
    const translate = (key) => $translate.instant(`${ctrl.namespace}.${key}`);

    ctrl.labels = {
      label: fields.label || translate('title'),
      expected: fields.expected || translate('table.columnPrevu'),
      real: fields.real || translate('table.columnReal'),
      percentage: fields.percentage || translate('table.columnPourcentage'),
      comment: fields.comment || translate('table.precisions'),
    };
  };
}
