/**
 * @desc Composant permettant de saisir des caractéristiques sociales
 */
angular.module('aides').component('recapitulatifCaracteristiquesSociales', {
  bindings: {
    caracteristiquesSociales: '<',
    teleservice: '<',
  },
  templateUrl:
    'aides/aides-directives/recapitulatif/caracteristiques-sociales/recapitulatif-caracteristiques-sociales.html',
  controller: recapitulatifCaracteristiquesController,
});
recapitulatifCaracteristiquesController.$inject = [];

function recapitulatifCaracteristiquesController() {
  var vm = this;

  vm.$onInit = function() {
    vm.titleCaracteristiques = _.get(
      this.teleservice,
      'workflow.simple.pageInformationsGenerales.caracteristiquesSociales.title'
    );
  };
}
