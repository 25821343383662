angular.module('form.services').factory('mdmService', ['configuration', '$q', '$http',
  function(configuration, $q, $http) {
    'use strict';

    var _tenantId;

    // Map to choose the mdm to call for a specific source name
    // If a source is not defined here it will be fetched from configuration.mdm.tenant
    function makeSources(config) {
      return {
        inseenaf2008: config.mdm.mgdis,
        mdmpays: config.mdm.mgdis
      };
    }
    var _sourceMdm = makeSources(configuration);

    // aliases for convenience.
    var aliases = {
      civilites: 'titrescivilites'
    };

    // basic memory cache, for MDM data it should be ok to use one
    var cache = {};

    // the promises builder functions that can be called to fill the MDM cache
    var promiseBuilders = {};

    /**
     * An utility function to quickly build promises on MDM sources
     *
     * @param {string} sourceName - a source name
     * @param {Object} [query] - optional query
     * @param {Function} [transform] - optional transform function that will be applied to each item of the list
     * @param {string} [mdmUrl] - optional data server management url
     * @param {boolean} [refresh] - option to avoid using cache
     * @return {Object} promise
     */
    var buildOdataPromise = function(sourceName, query, transform, mdmUrl, refresh) {
      var deferred = $q.defer();
      if (cache[sourceName] && !refresh) {
        deferred.resolve(cache[sourceName]);
      } else {
        // fetch a hundred documents max by default
        query = query || {};
        query.$top = query.$top || 500;

        // Do not return the non-active data
        // some collections don't have this property !
        if(['mdmpays', 'inseenaf2008', 'guichetsBancaires', 'etablissementsBancaires'].indexOf(sourceName) === -1) {
          query.$filter = query.$filter || '';
          if (query.$filter.length > 0) {
            query.$filter += ' and ';
          }
          query.$filter += 'actif ne false';
        }

        var url = (mdmUrl || _sourceMdm[sourceName] || configuration.mdm.tenant);
        if (_tenantId) {
          url = url.replace('{{tenantId}}', _tenantId);
        }

        // On n'accole pas la source name si ce dernier name est une url
        if (sourceName !== mdmUrl) {
          url += sourceName;
        }

        $http.get(url, {
          params: query
        }).then(
          // success callback
          function(data) {
            var documents = data.data.d.results;

            // Prepare 2 views of the source. One is an array
            //the second is an object with the 'reference' column as a key.

            var array = _.map(documents, function(doc) {

              var uri = doc.__metadata.uri;
              var href = uri.substr(0, uri.lastIndexOf('/') + 1) + encodeURIComponent(uri.substr(uri.lastIndexOf('/') + 1));

              var link = {
                href: href,
                expand: _.cloneDeep(doc)
              };

              link.expand.libelle = {
                value: link.expand.libelle
              };

              delete link.expand.__metadata;

              if (transform) {
                link = transform(link);
              }

              link.title = link.expand.libelle.value;

              return link;
            });

            var object = _.keyBy(array, 'expand.reference');

            cache[sourceName] = {
              array: array,
              object: object
            };
            deferred.resolve(cache[sourceName]);
          },
          // error callback
          function(error) {
            deferred.reject(error);
          });
      }
      return deferred.promise;
    };

    /**
     * Use to extract the type of promise we need to build from
     * a simple kay or a dataUrl
     */
    var _getPromiseByKey = function(key) {
      // Key exists (for compatibility)
      if(promiseBuilders[key]) {
        return promiseBuilders[key];
      }
      // If the dataUrl aim to a naf2008 source
      else if(key.indexOf('naf2008') > -1) {
        return promiseBuilders.naf;
      }
      // If the dataUrl aim to a mdmpays source
      else if(key.indexOf('mdmpays') > -1) {
        return promiseBuilders.pays;
      }
    };

    /**
     * Return a promise for a mdm object that will contain the requested lists
     *
     * @param {string[]|string} keys - keys of sources names
     * @param {string} [mdmUrl] - optional data server management url
     * @param {string} [query] - optional query
     * @param {boolean} [refresh] - option to avoid using cache
     * @return {Object} promise
     */
    var fill = function(keys, mdmUrl, query, refresh) {
      var promises = {};
      _.each(keys, function(key) {
        if (_getPromiseByKey(key)) {
          // either there is a dedicated builder
          promises[key] = _getPromiseByKey(key)(refresh);
        } else {
          // or we just assume the requested key is a source in the dataserver
          promises[key] = buildOdataPromise(aliases[key] || key, query || {
            $orderby: 'libelle asc'
          }, null, mdmUrl, refresh);
        }
      });
      // build a global promise based on all requested sources
      return $q.all(promises);
    };

    promiseBuilders.pays = function(refresh) {
      return buildOdataPromise('mdmpays',
        // the query to fetch countries
        {
          $filter: 'langue eq \'fr\'',
          $orderby: 'libpayscourtmaj asc',
          $top: 400
        },
        // the function to transform countries returned by the MDM into simpler objects
        function(pays) {
          pays.expand.reference = pays.expand.codpays2car;
          pays.expand.libelle = {
            value: pays.expand.libpayscourtmaj
          };
          return pays;
        }, null, refresh);
    };

    promiseBuilders.naf = function(refresh) {
      return buildOdataPromise('inseenaf2008', {
        $orderby: 'sousclasse asc',
        $top: 1000,
        $select: 'sousclasse, codesousclasse'
      },
      // the function to transform naf items returned by the MDM into simpler objects
      function(nafItem) {
        nafItem.expand.reference = nafItem.expand.codesousclasse;
        nafItem.expand.libelle = {
          value: nafItem.expand.codesousclasse + ' - ' + nafItem.expand.sousclasse
        };
        return nafItem;
      }, null, refresh);
    };

    promiseBuilders.localisantscog = function(refresh) {
      return buildOdataPromise('localisantscog', {
        $orderby: 'localisantmaj asc',
        $top: 100
      },
      // the function to transform insee items returned by the MDM into simpler objects
      function(inseeItem) {
        return inseeItem;
      }, null, refresh);
    };

    var fillFromFields = function(fields) {
      return fill(_.filter(_.map(fields, 'dataURL')));
    };

    // only return the fill function ? Or the cache too ?
    return {
      fill: fill,
      fillFromFields: fillFromFields,
      tenantId: function(tenantId) {
        _tenantId = tenantId;
      },
      configuration: function(config) {
        _sourceMdm = makeSources(config);
      }
    };
  }
]);
