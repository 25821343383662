/**
 * @ngdoc service
 * @area api
 * @module form.services
 * @name mdmUtils
 * @description
 *
 *   Help dealing with mdm entries in entities and persistence objects
 */

angular.module('form.services').factory('mdmUtils', [

  function() {
    'use strict';

    /**
		 * Recursively clean mdm entries from an entity. They will be replaced by their references.
		 * If separataData is true the mdm entry will be kept but renamed with a _data suffix
		 *
		 * Beware that the object is modified and not cloned. If you wish to preserve the original object you should copy it before.
		 *
		 * @param  {Object} entity - The entity to clean
		 * @param  {Boolean} [separateData] - Set to true in order to keep the mdm entries withg _data suffixes
		 * @param {Object} [parent] - Used for recursivity, not needed by external users of the method
		 * @param {String} [key] - Used for recursivity, not needed by external users of the method
		 * @return {Object} the entity (not cloned)
		 */
    var cleanEntity = function(entity, separateData, parent, key) {
      for (var i in entity) {
        if (entity[i] !== null && typeof(entity[i]) === 'object') {
          if (entity[i]._mdmEntry) {
            // The current object is an mdm entry, let's process it
            if (separateData) {
              if (_.isArray(entity) && parent && key) {
                if (key.indexOf('_data') === -1) {
                  // If current iterated object is an array store its elements in a sibling array suffixed by _data
                  parent[key + '_data'] = parent[key + '_data'] || [];
                  parent[key + '_data'].push(angular.copy(entity[i]));
                }

              } else {
                if (i.indexOf('_data') === -1) {
                  // If current iterated object is not an array store its mdm entries with names suffixed by _data
                  entity[i + '_data'] = angular.copy(entity[i]);
                }
              }
            }
            entity[i] = entity[i].reference;
          } else {
            // recursivity
            cleanEntity(entity[i], separateData, entity, i);
          }
        }
      }
      return entity;
    };

    /**
		 * Return a mdm entry from either an object already matching an mdm entry or from a reference.
		 * Useful when initializing a model without being sure that the given data is raw or already populated with mdm entries.
		 *
		 * @param  {Object} source - A source object as filled by the mdm service
		 * @param  {String|Object} refOrEntry - Either the reference of a entry in the source or the complete entry
		 * @return {Object}
		 */
    var refOrEntry = function(source, refOrEntry) {
      if (refOrEntry) {
        if(typeof refOrEntry === 'object') {
          var reference; 
          var object; 
          if(_.get(refOrEntry,'expand.reference') !== undefined) { 
            reference = _.get(refOrEntry,'expand.reference'); 
            object = _.get(source, 'object.' + reference, refOrEntry); 
            return object; 
          } else { 
            reference = _.get(refOrEntry,'href'); 
            object = _.find(source.array, function(item) { 
              return _.get(item, 'href') === reference; 
            }); 
            return object; 
          } 
        }
        else if(typeof refOrEntry === 'string') {
          var array = _.get(source, 'array', []);
          var found = _.find(array, function(mdmObj) {
            return _.get(mdmObj, 'expand.reference') === refOrEntry;
          });
          return found || refOrEntry || null;
        }
      } else {
        return (source && source.object && source.object[refOrEntry]) || refOrEntry || null;
      }
    };

    return {
      cleanEntity: cleanEntity,
      refOrEntry: refOrEntry
    };
  }
]);