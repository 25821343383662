angular
  .module('portailDepotDemandeAide.dashboard')
  .controller('aideRecapitulatifController', function(
    $scope,
    $rootScope,
    $modal,
    $state,
    $log,
    $q,
    $location,
    $anchorScroll,
    $timeout,
    $stateParams,
    $translate,
    aide,
    teleservice,
    mdm,
    avisPrealablesService,
    alertsService,
    tiers,
    contributionsService,
    contributionsFactory,
    aidesService,
    contributionsConstants,
    demandesPaiement,
    demandesPaiementService,
    teleservicePaiement,
    decisions,
    suiviFinancementService,
    demandesAidesService,
    contribution,
    documentsPubliesService,
    StoreService
  ) {
    var statutsContribution = contributionsConstants.status;
    const { tenantId } = $stateParams;
    $scope.aide = aide;

    const lignePF = suiviFinancementService.getFinancialLine(aide);
    $scope.financementStatut = _.get(lignePF, 'financement.statut');

    if (!_.has(lignePF, 'financement.dispositif.href')) {
      $scope.autorisationDemandesPaiementDemandeur =
        _.get(lignePF, 'financement.teleservicePaiement') &&
        _.get(lignePF, 'financement.autorisationDemandesPaiementDemandeur', false);
    } else {
      demandesPaiementService
        .canCreatePaiement(_.get(lignePF, 'financement.dispositif.href'))
        .then(({ ouvertureDemandePaiementSurDecisionDemandeur, teleservicePaiement }) => {
          $scope.autorisationDemandesPaiementDemandeur =
            _.get(lignePF, 'financement.teleservicePaiement') && ouvertureDemandePaiementSurDecisionDemandeur;
          return demandesPaiementService.canCreateDemandePaiement({
            action: 'POST',
            resource: `/referentiel-financement/api/tenants/${tenantId}/demandes-paiement`,
            environment: {
              body: {
                teleservicePaiement: teleservicePaiement,
                demandeFinancement: {
                  href: $scope.demande.id,
                },
              },
            },
          });
        })
        .then(function(response) {
          const currentTiersReference = StoreService.currentTiersRef.get();
          const tiersDemandeurReference = _.get(aide, 'demandeur.expand.reference');
          $scope.canCreateDemandePaiement = response;
          $scope.displayPaiementBtn =
            $scope.financementStatut === 'VOTE' &&
            $scope.autorisationDemandesPaiementDemandeur &&
            !_.get($scope, 'aide.cloture.value') &&
            $scope.configurationId &&
            $scope.canCreateDemandePaiement &&
            currentTiersReference === tiersDemandeurReference;
        });
    }

    var ligneFinancement = demandesAidesService.findLigneFinancementDispositifEligible(aide);
    $scope.demande = $scope.aide;
    $scope.demande.teleservicePaiement = _.get(ligneFinancement, 'financement.teleservicePaiement.href');
    if ($scope.aide.teleservicePaiement) {
      demandesAidesService.fillTeleservicePaiementInDemande($scope.aide).then(function(result) {
        $scope.demande = result;
      });
    } else {
      $scope.demande = aide;
    }

    // Recovering of the number of documents for demande
    $scope.demandeDocuments = { count: 0, heading: $translate.instant('teleservice.reacapitulatifTabs.documents') };
    documentsPubliesService
      .getDocumentsByDemandeHref(_.get($scope, 'demande.id'))
      .then((data) => {
        $scope.demandeDocuments.count = data.total;
        if (data.total > 0) {
          $scope.demandeDocuments.heading += ' (' + data.total + ')';
        }
      })
      .catch(() => {
        $scope.alertePublication = alertsService.getAlertError('connected.dashboard.documentsReçus.erreur');
      });

    /**
     * Keep the contributions that aren't closed and not answered
     * @param  {Array} contributions
     * @return {Array}
     */
    const filterOpenedContribution = (contributions = []) =>
      contributions.filter(({ href }) => href === contribution.id);

    const initAideData = () => {
      // The contribution retrieved here is not closed
      $scope.contributionsRedirectionSize = 0;
      $scope.contributionsPourModificationSize = 0;

      if (contribution && contribution.statut !== statutsContribution.ANSWERED) {
        if (contribution.typeContribution === 'REDIRECTION') {
          $scope.contributionsRedirectionSize = filterOpenedContribution($scope.aide.contributionsRedirection).length;
        } else {
          $scope.contributionsPourModificationSize = filterOpenedContribution(
            $scope.aide.contributionsPourModification
          ).length;
        }
      }

      $scope.demande.demandeurId = _.get(aide, 'demandeur.expand.id');
      $scope.demande.hasComplements = false;
      $scope.demandeListDropdown = true;
      $scope.demande.nbComplements = '';
    };

    $scope.tiers = tiers;
    $scope.teleservice = teleservice;
    $scope.mdm = mdm;
    $scope.namespace = 'connected.dashboard.recapitulatif';
    $scope.aideConfiguration = _.get($scope.teleservice, 'aide.informations-generales');
    initAideData();
    $scope.fromAttestations = $stateParams.fromAttestations;
    $scope.fromContribution = $stateParams.fromContribution;
    $scope.fromAides = !$state.params.typeAvis && !$scope.fromContribution && !$scope.fromAttestations;
    $scope.statutContribution = $stateParams.statutContribution;
    $scope.demandesPaiement = demandesPaiement || [];
    $scope.demandesLiquide = $scope.demandesPaiement.filter(function(e) {
      return ['MANDATE', 'PAYE', 'LIQUIDE'].includes(e.statut);
    });

    // Do not display EN_COURS demandes from other demande or beneficiaire
    $scope.demandesPaiement = _.reduce(
      $scope.demandesPaiement,
      (acc, demande) => {
        if (_.get($rootScope, 'currentUser.self') !== _.get(demande, '_user.href') && demande.statut === 'EN_COURS') {
          return acc;
        }
        demande.tiersAuthorized = _.get($rootScope, 'currentUser.self') === _.get(demande, '_user.href');
        return acc.concat(demande);
      },
      []
    );

    // check if can create paiement request
    demandesPaiementService
      .canCreateDemandePaiement({
        action: 'POST',
        resource: '/referentiel-financement/api/tenants/' + $stateParams.tenantId + '/demandes-paiement',
        environment: {
          body: {
            teleservicePaiement: {
              href: $scope.demande.teleservicePaiement,
            },
            demandeFinancement: {
              href: $scope.demande.id,
            },
          },
        },
      })
      .then(function(response) {
        $scope.canCreateDemandePaiement = response;
      });

    $scope.displayBtnDemandesPaiements = function() {
      return (
        $scope.financementStatut === 'VOTE' &&
        $scope.autorisationDemandesPaiementDemandeur &&
        !_.get($scope, 'aide.cloture.value') &&
        $scope.configurationId &&
        $scope.canCreateDemandePaiement
      );
    };

    /**
     * Return the remaining to be asked
     * @returns {number}
     */
    $scope.getMontantRestantADemander = function() {
      return demandesPaiementService.getMontantRestantADemander($scope.demandesPaiement, $scope.decisions);
    };

    /**
     * Return the remaining to be paid on the voted amount
     * @returns {number}
     */
    $scope.getMontantRestantAPayer = function() {
      return demandesPaiementService.getMontantRestantAPayer($scope.demandesLiquide, $scope.decisions);
    };

    /**
     * Get autorisation payment demand from plan financement
     */
    $scope.autorisationDemandePaiement = function(demande) {
      const lignePF = suiviFinancementService.getFinancialLine(demande);
      return _.get(lignePF, 'financement.autorisationDemandesPaiement', false);
    };

    // Pagination
    $scope.pagerConfig = {
      totalItems: $scope.demandesPaiement.length,
      itemsPerPage: 10,
      maxSize: 5, //Number of pager buttons to show
      currentPage: 1,
    };

    $scope.decisions = decisions;

    /**
     * calculate montant for display
     */
    $scope.montantRestantADemander = $scope.getMontantRestantADemander();
    $scope.montantRestantAPayer = $scope.getMontantRestantAPayer();

    /**
     * Checks if there is still an amount to request on the voted amount
     */
    $scope.hasARemainingAmountToAsk = function() {
      return $scope.montantRestantADemander > 0;
    };

    if ($scope.aide.multiFinanceur) {
      $scope.financeur = _.find($scope.aide.multiFinancement.financeurs, ['href', $stateParams.financeur]);
    }
    $scope.configurationId = teleservicePaiement ? teleservicePaiement.reference : null;
    $scope.teleservicePaiement = teleservicePaiement;
    if (_.get(aide, 'demandesComplementPieces.length') > 0) {
      $scope.demande.hasComplements = true;
      $scope.demande.nbComplements = _.get(aide, 'demandesComplementPieces.length');
    }
    if ($stateParams.fromEchange) {
      $timeout(function() {
        $rootScope.$broadcast('showAsideEchange');
      }, 2000);
    }

    // Attestation over contribution MODIFICATION and REDIRECTION
    if ($scope.fromAttestations) {
      $scope.attestationContribution = {
        active: !!($scope.contributionsPourModificationSize || $scope.contributionsRedirectionSize),
      };

      if ($scope.attestationContribution.active) {
        if (contribution && ['MODIFICATION', 'REDIRECTION'].includes(contribution.typeContribution)) {
          $scope.attestationContribution.contribution = contribution;
        }
      }
    }

    /********************************************************************************************************
     * ENUMS *                                                                       *                      *
     * We write them into the code because they doesn't exists (only in the contract)                        *
     * http://gitlab.mgdis.fr/contract/referentiel-aide/blob/master/aide-noAdditionalProperties.json    *
     ********************************************************************************************************/
    var avisEnum = {
      FAVORABLE: 'FAVORABLE',
      DEFAVORABLE: 'DEFAVORABLE',
    };

    //Avis - Cast to select-field format
    //We code the values, cause we don't have it in a referentiel.
    $scope.listeTypeAvis = [
      {
        expand: {
          reference: avisEnum.FAVORABLE,
          libelle: {
            value: 'Favorable',
          },
        },
      },
      {
        expand: {
          reference: avisEnum.DEFAVORABLE,
          libelle: {
            value: 'Défavorable',
          },
        },
      },
    ];

    // Vérification que notre tiers demandeur est lié à un avis sur l'aide en cours.
    // (et donc que notre tiers est désigné comme "Contributeur pour avis préalable sur la demande")
    // Cette vérification nous permettra d'afficher ou non le bouton
    // indiquant que l'on souhaite déposer un avis
    // on test aussi le fait qu'un avis préalable n'a pas encore été donné
    $scope.displayBtnGiveOpinion =
      !_.isEmpty(avisPrealablesService.getAvisPrealableTiers(aide.avisPrealables, tiers)) &&
      _.isEmpty(_.get(aide, 'avisPrealables[0].avis'));

    // Pop-up 'Donner un avis'
    $scope.giveOpinion = function() {
      var scopeModal = $scope.$new();
      scopeModal.item = {};
      scopeModal.validOpinion = $scope.validOpinion;
      scopeModal.viewConfiguration = {
        ns: 'connected.dashboard.contributionsAvisPrealable.avis.recapitulatif.modal',
        fields: {
          typeAvis: {
            required: true,
            labelWidth: 3,
          },
          commentaire: {
            required: true,
            labelWidth: 3,
          },
        },
      };

      $modal({
        scope: scopeModal,
        template: 'dashboard/recapitulatif/modal/modal-give-opinion.html',
      });
    };

    $scope.validOpinion = function(modal, item) {
      $scope.avis = item.avis.expand.reference;

      $scope.avisPrealables = avisPrealablesService.getAvisPrealableTiers(aide.avisPrealables, tiers);
      const index = avisPrealablesService.getIndexAvisPrealableTiers(aide.avisPrealables, tiers);

      $scope.avisPrealables.avis = $scope.avis;
      $scope.avisPrealables.date = new Date().toISOString();
      $scope.avisPrealables.commentaire = modal.commentaire;
      $scope.avisPrealables.user = {
        href: $rootScope.currentUser.self,
        title: $rootScope.currentUser.displayName,
      };

      const demandeur = _.get(aide, 'demandeur.expand');
      const teleservice = $scope.teleservice;

      return (
        aidesService
          // if needed, we make the prise-en-charge-auto
          .priseEnChargeAuto(demandeur, teleservice, tenantId)
          .then(() => avisPrealablesService.patchAvisPrealable($scope.aide, $scope.avisPrealables, index))
          .then(() => {
            // Enregistrement OK
            modal.$hide();
            $scope.displayBtnGiveOpinion = _.isEmpty(_.get(aide, 'avisPrealables[0].avis'));
            $scope.displayOpinion = !_.isEmpty(_.get(aide, 'avisPrealables[0].avis'));
          })
          .catch((error) => {
            modal.$hide();
            if (error && error.status === 401) {
              // Redirect to to login screen
              $state.go('app.home', {
                loggedOut: true,
              });
            } else {
              if (error.data.message) {
                $log.error(error.data.message);
                $scope.$broadcast('alerts', alertsService.getAlertError('Une erreur est survenue. ' + error.data));
              }
            }
          })
      );
    };

    $scope.displayOpinion = !_.isEmpty(_.get(aide, 'avisPrealables[0].avis'));

    // Contribution
    $scope.replyToContribution = function() {
      if ($scope.fromContribution) {
        contributionsService.get($scope.fromContribution).then(function(contribution) {
          var teleserviceHref = _.last(_.get(contribution, 'teleservice.href', '').split('/'));

          if (teleserviceHref) {
            $state.go('app.connected.config.depot.contributions', {
              configurationId: teleserviceHref, // téléservice de contribution
              contributionRef: $scope.fromContribution,
            });
          }
        });
      }
    };

    /**
     * Check display condition of paiement list
     */

    $scope.displayPaiementList = function() {
      // When there are an initial request + an endorsment voted and canceled, the financementStatus is 'fininstruction" but
      // the initial request amount still need to be display
      // If there is an endorsment, the total amount to pay is displayed
      const avenants = !_.isEmpty(
        _.find($scope.decisions, function(decision) {
          return _.get(decision, 'avenant.id', 0) > 0;
        })
      );
      return (
        $scope.aide.status == 'ACCEPTABLE' &&
        ($scope.financementStatut == 'SOLDE' || $scope.financementStatut == 'VOTE' || avenants) &&
        // Hide if multi-dossiers
        !(($scope.aide.dossiersFinancement || []).length > 1)
      );
    };

    //Méthode de modification de la demande : elle lancera un PATCH sur la demande de financement
    // pour modifier le user en mettant à la place l'utilisateur courant et repasser le statut à
    // 'REQUESTED' ensuite il se rendra sur le preambule de la demande de financement
    $scope.modifyAide = function() {
      var patches = [
        {
          op: 'replace',
          path: '/status',
          value: 'REQUESTED',
        },
        {
          op: 'replace',
          path: '/history/begin/metadata/step',
          value: 'preambule',
        },
        {
          op: 'replace',
          path: '/user',
          value: {
            href: $rootScope.currentUser.self,
            title: $rootScope.currentUser.displayName,
            rel: 'user',
            organization: $stateParams.tenantId + '-demandeurs',
            organizationLabel: $stateParams.tenantId + '-demandeurs',
          },
        },
      ];
      if ($scope.aide.status === statutsContribution.WAITING_FOR_CERTIFICATE) {
        // Dans le cas des demandes au statut WAITING_FOR_CERTIFICATE on modifie le créateur de la demande
        var newCreator = {
          href: $rootScope.currentUser.self,
          method: 'GET',
          title: $rootScope.currentUser.displayName,
          rel: 'mgs:creator',
        };
        patches.push({
          op: 'add',
          path: '/history/begin/user',
          value: newCreator,
        });
      }
      aidesService.patchAide($scope.aide, patches).then(function() {
        $state.go('app.connected.config.depot.simple', {
          p: $scope.aide.reference,
          configurationId: $scope.aide.teleservice.href.split('/').pop(),
        });
      });
    };

    // Modification de la contribution pour modification
    $scope.modifyContribution = function() {
      var pagesARevoir = contributionsFactory.pagesARevoirToHistoryStep(
        _.get($scope.attestationContribution, 'contribution.pagesARevoir', [])
      );
      // Patch contribution
      var patchesContributions = [
        {
          op: 'replace',
          path: '/statut',
          value: 'INPROGRESS',
        },
        {
          op: 'replace',
          path: '/contact',
          value: {
            href: $rootScope.currentUser.self,
            title: $rootScope.currentUser.displayName,
          },
        },
        {
          op: 'replace',
          path: '/history/begin/metadata',
          value: {
            step: pagesARevoir[0] || 'preambule',
            stepsStack: [],
            stepsVisited: [],
          },
        },
      ];

      const runningRequest = [
        contributionsService.patch(
          _.get($scope.attestationContribution, 'contribution.reference'),
          patchesContributions
        ),
        // Patch aide (status goes back to supported)
        aidesService.patchAide($scope.aide, [
          {
            op: 'replace',
            path: '/status',
            value: 'SUPPORTED',
          },
        ]),
      ];

      $q.all(runningRequest).then(() => {
        // Go to depot contribution page
        const options = {
          reload: true,
        };

        if (contribution.typeContribution === 'MODIFICATION') {
          this.$state.go(
            'app.connected.config.depot.contributionModification',
            {
              configurationId: $scope.aide.teleservice.href.split('/').pop(),
              p: $scope.aide.reference,
              c: $scope.attestationContribution.contribution.reference,
            },
            options
          );
        } else if (contribution.typeContribution === 'REDIRECTION') {
          this.$state.go(
            'app.connected.config.depot.contributionRedirection',
            {
              configurationId: contribution.teleservice.href.split('/').pop(),
              p: $scope.aide.reference,
              c: $scope.attestationContribution.contribution.reference,
            },
            options
          );
        }
      });
    };

    if (_.get($scope, 'aide.demandesReport.length')) {
      $scope.demandesReportIframeSrc = aidesService.generateDemandeReportTableIframeSrc($scope.aide.reference);

      $timeout(() => {
        iFrameResize(
          {
            heightCalculationMethod: 'taggedElement',
            checkOrigin: false,
            inPageLinks: true,
          },
          '#demandesReportIframe'
        );
      }, 0);
    }

    $scope.displayBtnCreateContributionAvisFinancement = function() {
      return $scope.fromContribution && $scope.statutContribution !== 'ANSWERED';
    };

    /**
     * Affichage d'une fenêtre modale demandant la confirmation de la suppression d'une demande
     */
    $scope.deleteDemandePaiement = function(demandePaiement) {
      var scopeModal = $scope.$new();
      scopeModal.demandePaiement = demandePaiement;

      $modal({
        scope: scopeModal,
        template: 'dashboard/aides/demandes-paiement/modal/modal-delete-demande.html',
      });
    };

    /**
     * Suppression d'une demande
     * @param  {object} modal       Modal to hide after the persistence has been deleted
     * @param  {object} demandePaiement Demande paiement
     */
    $scope.confirmDeleteDemande = function(modal, demandePaiement) {
      modal.$hide();

      demandesPaiementService
        .removeDemandePaiement(demandePaiement)
        .then(function() {
          // Supprimer l'élément de la liste sans recharger pour autant.
          $scope.demandesPaiement = _.filter($scope.demandesPaiement, function(p) {
            return demandePaiement.reference !== p.reference;
          });

          $scope.alerts = alertsService.getAlertSuccess(
            $translate.instant('connected.dashboard.aides.demandesAides.deleted')
          );
        })
        .catch(function(error) {
          $scope.alerts = alertsService.getAlertError(`${$translate.instant('common.error.happened')} ${error.data}`);
        });
    };

    $scope.goToListAides = function() {
      const suiviDemandesState = 'app.connected.dashboard.aides.demandesAides';

      // Redirect depending on the origin
      switch ($stateParams.from) {
        case 'mesPublications':
          $state.go('app.connected.dashboard.documentsReçus');
          break;
        case 'suiviJustifications':
          $state.go(suiviDemandesState, { justificationOnly: true, page: $stateParams.page });
          break;
        default:
          $state.go(suiviDemandesState, { page: $stateParams.page });
          break;
      }
    };

    $scope.goToMyDocuments = function() {
      $state.go('app.connected.dashboard.documentsReçus');
    };

    $scope.goToAvis = function() {
      $scope.activeTabIndex = 1;
      $timeout(function() {
        $location.hash('tcRecapAvisPrealable');
        $anchorScroll();
      }, 0);
    };

    /**
     * Check if actions requises should be displayed
     * @return {boolean}
     */
    $scope.displayActionsRequises = () => {
      return (
        $scope.demande.hasComplements || $scope.contributionsPourModificationSize || $scope.contributionsRedirectionSize
      );
    };
  });
