/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name format-field
 * @scope
 * @restrict A
 * @description
 *
 *    Format a field in realtime while it is typed.
 *
 * @param {string} format - The key of the format to apply. TODO detailed list of available formts.
 *
 * @example
 *
 *   `<input type="text" format-field format="'phone'">`
 *
 */

angular.module('form.directives').directive('formatField', ['$filter', 'formFormat',

  function($filter, formFormat) {
    'use strict';
    return {
      require: 'ngModel',
      scope: {
        format: '='
      },
      link: function(scope, element, attrs, model) {

        var formatField = function() {
          // Type du format
          // Passage en minuscule pour gérer le format associé directement
          // au niveau du champ où les valeurs du format sont en majuscule
          var format = _.get(scope, 'format', '').toLowerCase();
          switch (format) {
            case 'lowercase':
            case 'uppercase':
              model.$setViewValue($filter(format)(element.val()));
              break;
            case 'capitalize':
            case 'capitalizefirst':
              model.$setViewValue(formFormat.capitalizeFirst(element.val()));
              break;
            case 'phone':
              model.$setViewValue(formFormat.formatPhone(element.val()));
              break;
          }
        };

        scope.blur = function() {
          formatField();
          model.$render();
        };

        scope.keydownPress = function(event) {
          if (event.which === 13) {
            formatField();
            model.$render();
          }
        };

        // Format the field when we leave this field
        // When we call "model.$setViewValue" we update the model and when we call "model.$render" we update the input field
        element.bind('blur', scope.blur);

        // Format the field when we type on the enter touch
        element.bind('keydown keypress', scope.keydownPress);

        scope.$on('$destroy', function() {
          element.unbind('blur', scope.blur);
          // Format the field when we type on the enter touch
          element.unbind('keydown keypress', scope.keydownPress);
        });
      }

    };
  }
]);
