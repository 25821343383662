/**
 * Factory de gestions des contributions
 */
angular.module('contributions.services').factory('contributionsFactory', contributionsFactory);

contributionsFactory.$inject['tiersService'];

function contributionsFactory(tiersService) {
  /**
   * Convert a list of pages to step format
   * @param  {Array<string>} pagesARevoir
   * @return {Array<string>}
   */
  function pagesARevoirToHistoryStep(pagesARevoir) {
    const historySteps = _.flatMap(pagesARevoir, (pageARevoir) => {
      switch (pageARevoir) {
        case 'pageFinanceur':
          return 'financeur';
        case 'pagePreambule':
          return 'preambule';
        case 'pageCritereEligibilite':
          return 'criteres';
        case 'pageInformationsDemandeur':
          // demandeur is already defined in the case of a contribution
          return 'demandeur-recapitulatif';
        case 'pageInformationsBeneficiaire':
          return ['demandeur-beneficiaire', ...tiersService.getBeneficiaireCreationSteps()];
        case 'pageInformationsGenerales':
          return 'informations-generales';
        case 'pageInformationsGenerales2':
          return 'pageInformationsGenerales2';
        case 'pageInformationsGenerales3':
          return 'pageInformationsGenerales3';
        case 'pageDocumentComptable':
          return 'document-comptable';
        case 'pageDomiciliationBancaire':
          return 'domiciliation-bancaire';
        case 'pageFinancementAnterieur':
          return 'minimis';
        case 'pageIndicateurs':
          return 'indicateurs';
        case 'pagePieces':
          return 'pieces';
        default:
          return null;
      }
    });
    return historySteps;
  }

  /**
   * Get next step of the contribution
   * @param  {Object} contribution
   * @param  {string} step
   * @return {string}
   */
  function getNextStep(contribution, step) {
    //? Special case 'informations-generales' (when the demandeur is the beneficiaire we wanna skip steps) :
    // The beneficiaire page contains multiple steps, we can't do step +1 to skip it, otherwise we will be on another beneficiaire step
    if (step === 'informations-generales') return step;

    // get all contributions steps
    const steps = contribution.pagesARevoir ? pagesARevoirToHistoryStep(contribution.pagesARevoir) : [];
    // find the next step
    const nextStepIndex = steps.indexOf(contribution.history.begin.metadata.step) + 1;
    return _.get(steps, nextStepIndex, 'recapitulatif');
  }

  return {
    getContribution: function() {
      return this.contribution;
    },
    setContribution: function(pContribution) {
      this.contribution = pContribution;
    },
    pagesARevoirToHistoryStep: pagesARevoirToHistoryStep,
    getNextStep,
  };
}
