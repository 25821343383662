/**
 * Service de gestions des contributions
 */
angular.module('components.echanges').factory('echangesService', echangesService);

function echangesService($http, $q, $log, configuration, $rootScope, $timeout, jwtSessionService) {
  'use strict';

  var _url = _.get(configuration, 'echanges.url');
  var _proxyServerUrl = _.get(configuration, 'echanges.tenant');
  var _ressourcePath = _.get(configuration, 'echanges.ressourcePath') || 'echanges';
  var _currentUserHref = $rootScope.currentUser.self;

  return {
    /**
     * Service de recherche des contributions via elastic search
     */
    countUnreadEchangesByDemande: function(tenantId, demandeFinancementRef) {
      // EU backend used as middleware to Request
      return $http.get('/aides/api/tenants/' + tenantId + '/echanges/' + demandeFinancementRef).then(function(results) {
        return _.get(results, 'data.count');
      });
    },
    socketUnreadEchanges: function(tenantId, user, demandeFinancementRef, callbackSetNb) {
      if (_.get(jwtSessionService.user(), 'echangesActif')) {
        var that = this;
        var jwtToken = jwtSessionService.jwt();

        if (_.isEmpty(jwtToken) || _.isEmpty(tenantId)) {
          return;
        }

        var query = {
          token: jwtToken,
          tenantId: tenantId,
        };
        var socket = io(window.location.origin + '/echangesNonLusDemande', {
          path: '/referentiel-echanges/api/socket.io/',
          transports: ['websocket', 'polling'],
          query: query,
        });
        socket.on('connect', function() {
          // Join room to get unread echange by demande
          socket.emit('connect-demandeFinancement', { demandeFinancement: demandeFinancementRef });
          socket.on('refreshUnreadEchanges', function() {
            $timeout(function() {
              that.countUnreadEchangesByDemande(tenantId, demandeFinancementRef).then(function(nb) {
                callbackSetNb(nb);
              });
            }, 1000);
          });
        });
      }
    },
  };
}
