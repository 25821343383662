angular
  .module('aides.services')
  .factory('demandesPaiementService', function(
    $http,
    configuration,
    $log,
    $q,
    $httpParamSerializer,
    jwtSessionService,
    $sce
  ) {
    'use strict';

    // '/api/tenants/{{tenant.id}}'
    var tenant = configuration['demande-paiement'].tenant;
    var tenantAdmin = configuration['demande-paiement'].tenantAdmin;
    var service = configuration['demande-paiement'].service;

    return {
      /**
       * Get the paiement demandes associated with the aide parameter
       * and dossierFinancement parameter
       * @param  {Object} aide
       * @param  {Object} dossierFinancementHref
       * @returns  {Promise<Array<Object>>} demandePaiements
       */
      getDemandesPaiementFromDossier: ({ reference }, dossierFinancementHref) => {
        let filter = `substringof(demandeFinancement/href,'${reference}')`;
        if (dossierFinancementHref) {
          filter += ` and substringof(dossierFinancement/href,'${dossierFinancementHref}')`;
        }
        const config = {
          params: {
            $filter: filter,
            $expand: '_embedded',
            $top: 100,
          },
        };
        return $http
          .get(`/referentiel-financement${tenant}/demandes-paiement`, config)
          .then((response) => response.data._embedded.items)
          .then((demandesPaiements) => {
            const formattedDemandesPaiements = demandesPaiements.map((demandePaiement) => {
              const formattedDemandePaiement = _.get(demandePaiement, '_links.self.expand') || {};
              formattedDemandePaiement.id = demandePaiement.id;
              formattedDemandePaiement.title = demandePaiement.title;
              return formattedDemandePaiement;
            });

            const sortyByDate = (a, b) =>
              new Date(_.get(b, 'dateReception', 'history.begin.date', '')) -
              new Date(_.get(a, 'dateReception', 'history.begin.date', ''));

            // Sort by dateReception
            return (formattedDemandesPaiements || []).sort(sortyByDate);
          });
      },

      /**
       * Get the paiement demandes associated with the aide parameter
       * and financeur parameter
       * @param  {Object} aide
       * @param  {string} financeurHref
       * @returns  {Promise<Array<Object>>} demandePaiements
       */
      getDemandesPaiementLignePlanFinancement: function(aide, financeurHref) {
        // DemandesPaiements filter by dossierFinancement's financeur (if financeur exists)
        const financeurs = _.get(aide, 'multiFinancement.financeurs', []);
        const financeur = financeurs.find(({ href }) => href === financeurHref);

        // We get dossier source through ligne fin, because multiFinancement.financeurs only has source for v8 dossier
        const ligneFinancement = this.findLigneFinancementByFinanceur(aide, financeurHref);

        // We get dossier though financeur source or ligne fin
        const dossierFinancementHref =
          _.get(financeur, 'source.href') || _.get(ligneFinancement, 'financement.source.href');
        return this.getDemandesPaiementFromDossier(aide, dossierFinancementHref);
      },

      /**
       * Get a demande paiement
       * Utilisation d'une route d'orchestration afin de ne pas être limité aux accès
       *  via le prp du referentiel-financement (JIRA PLAID-6467)
       * @param {string} reference Demande paiement reference
       * @param {Object} config Config
       */
      getDemandePaiement: function(reference, config) {
        return $http.get('/aides' + tenant + '/demandes-paiement/' + reference, config || {}).then(function(response) {
          return response.data;
        });
      },

      /**
       * Add a demande paiement
       * @param {object} demandePaiement Demande paiement
       */
      createDemandePaiement: function(demandePaiement) {
        return $http
          .post('/referentiel-financement' + tenant + '/demandes-paiement', demandePaiement)
          .then(function(response) {
            return response.data;
          });
      },

      /**
       * Update a demande paiement
       * @param {object} demandePaiement Demande paiement
       * @return {object} Demande paiement
       */
      updateDemandePaiement: function(demandePaiement) {
        return $http
          .put('/referentiel-financement' + tenant + '/demandes-paiement/' + demandePaiement.reference, demandePaiement)
          .then(function(response) {
            return response.data;
          });
      },

      patchDemandePaiement: function(reference, patches) {
        return $http
          .patch('/referentiel-financement' + tenant + '/demandes-paiement/' + reference, patches)
          .then(function(response) {
            return response.data;
          });
      },
      /**
       * Remove a demande paiement
       * @param {object} demandePaiement Demande paiement
       * @return {object} Demande paiement
       */
      removeDemandePaiement: function(demandePaiement) {
        return $http
          .delete('/referentiel-financement' + tenant + '/demandes-paiement/' + demandePaiement.reference)
          .then(function(response) {
            return response.data;
          });
      },

      /**
       * Clean µEntity demandePaiement
       * @param {object} demandePaiement Demande paiement
       * @return {object} Demande paiement
       */
      cleanEntity: function(demandePaiement) {
        var demandePaiementCleaned = angular.copy(demandePaiement);

        if (demandePaiementCleaned._links) {
          delete demandePaiementCleaned._links;
        }
        if (demandePaiementCleaned.teleservicePaiement && demandePaiementCleaned.teleservicePaiement.expand) {
          delete demandePaiementCleaned.teleservicePaiement.expand;
        }
        if (demandePaiementCleaned.user && demandePaiementCleaned.user.expand) {
          delete demandePaiementCleaned.user.expand;
        }

        if (demandePaiementCleaned.domiciliationBancaire) {
          _.each(demandePaiementCleaned.domiciliationBancaire.pieces, function(piece) {
            _.each(piece.documents, function(documentPiece) {
              if (documentPiece.expand) {
                delete documentPiece.expand;
              }
            });
          });
        }

        _.each(demandePaiementCleaned.pieces, function(piece) {
          _.each(piece.documents, function(documentPiece) {
            if (documentPiece.expand) {
              delete documentPiece.expand;
            }
          });
        });

        return demandePaiementCleaned;
      },

      /**
       * Add or update a demande paiement
       * @param {object} demandePaiement Demande paiement
       * @return {object} Demande paiement
       */
      saveDemandePaiement: function(demandePaiement) {
        var demandePaiementCleaned = this.cleanEntity(demandePaiement);
        if (demandePaiementCleaned.reference) {
          return this.updateDemandePaiement(demandePaiementCleaned);
        } else {
          return this.createDemandePaiement(demandePaiementCleaned).then(function(newDemandePaiement) {
            // Update the local demandePaiement in scope with the new one
            _.merge(demandePaiement, newDemandePaiement);
            return newDemandePaiement;
          });
        }
      },

      /**
       * Get line financement if exist
       * Search on postes and sousPoste lines
       * @param {object} aide aide
       * @param financeurHref href financeur
       * @return {object} line financement
       */
      findLigneFinancementByFinanceur: function(aide, financeurHref) {
        // On recupere la ligne dans le plan de financement
        var listeLignesPlanFinancementFinanceur = JSONPath(
          '$.planFinancement.0.recette..lignes[?(@.financement)]',
          aide
        );

        var ligneFinancement;
        // Si l'on a un href de financeur
        if (financeurHref) {
          // on recupère la ligne en fonction du href du financeur
          ligneFinancement = _.find(
            listeLignesPlanFinancementFinanceur,
            (ligne) => _.get(ligne, 'financement.financeur.href') === financeurHref
          );
        } else {
          // sinon on recupere la ligne de financement de la ligne fictive
          ligneFinancement = _.find(listeLignesPlanFinancementFinanceur, { reference: 'MGS_LIGNE_FICTIVE' });
        }

        return ligneFinancement;
      },

      /**
       * Méthode permettant la récupération du dispositif eligible sur la ligne du plan de fiancement
       * Si l'on est sur le même tenant que le dispositif alors on effectue un get sur ce dernier
       * Sinon on construit le dispositif en fonction du plan de financement selon le JIRA PLAID-13888
       * @param {*} aide demande de financement
       * @param {*} hrefFinanceur href du financeur
       */
      getDispositifEligible: function(aide, hrefFinanceur) {
        var dispositifEligible = {};
        // Récupération de la ligne de plan de financement
        var ligneFinancement = this.findLigneFinancementByFinanceur(aide, hrefFinanceur);
        dispositifEligible.id =
          _.get(ligneFinancement, 'financement.dispositif.href') || _.get(ligneFinancement, 'dispositifEligible.href');
        dispositifEligible.title =
          _.get(ligneFinancement, 'financement.dispositif.title') ||
          _.get(ligneFinancement, 'dispositifEligible.title');
        // Récupération du paramétrage de la ligne de plan de financement
        dispositifEligible.ouvertureDemandePaiementSurDecisionDemandeur = _.get(
          ligneFinancement,
          'financement.autorisationDemandesPaiementDemandeur',
          false
        );

        // Si l'on à un href et que l'on est sur le même tenant
        // Alors on récupère le dispositif eligible
        if (dispositifEligible.id && _.includes(dispositifEligible.id, '/' + tenant.split('/').pop() + '/')) {
          return $http
            .get(dispositifEligible.id)
            .then(function(response) {
              return response.data || {};
            })
            .catch(function(error) {
              // On retourne l'erreur dans la console
              $log.error(error);
              // Sinon on retourne le dispositif du plan de financement pour ne pas bloquer l'usager
              return dispositifEligible;
            });
        } else {
          return dispositifEligible;
        }
      },

      canCreateDemandePaiement: function(data) {
        return $http.post(service + tenantAdmin + '/authorization/pdp', data).then(function(response) {
          return _.get(response, 'data.decision') === 'permit';
        });
      },

      getDispositif: function(href) {
        return $http.get(href).then(function(response) {
          return response.data;
        });
      },

      /**
       * Get ligne financement dispositif elligible
       * @param {object} aide aide
       * @return {object} ligne financement dispositif elligible
       */
      findLigneFinancementDispositifEligible: function(aide) {
        const [ligneFinancementDispositifEligible] = JSONPath(
          '$.planFinancement..recette..lignes..[?(@.dispositifEligible &&' +
            '@.dispositifEligible.href && ' +
            '@.financement && ' +
            '@.financement.autorisationDemandesPaiement)]',
          aide
        );
        return ligneFinancementDispositifEligible;
      },

      /**
       * Check if all informations complementaires groups/fields are flagged as agentOnly
       * @param {array} informationsComplementaires
       */
      mustDisplayInformationsComplementaires: function(informationsComplementaires) {
        // Get informations complementaires form type without agent only property true
        const groupsNotAgentOnly = JSONPath(
          `$.[?(@.status == 'VALIDATED')].groups[?(@.agentOnly != true)].fields[?(@.agentOnly != true)]`,
          informationsComplementaires
        );
        // Get informations complementaires liste/fiche type without agent only property true
        const fieldsNotAgentOnly = JSONPath(
          `$.[?(@.status == 'VALIDATED')].fields[?(@.agentOnly != true)]`,
          informationsComplementaires
        );
        return fieldsNotAgentOnly.length || groupsNotAgentOnly.length;
      },

      /**
       * Gets the paid of amount from "demande paiement"
       * @param {object} demande The "demande paiement" object
       * @returns {number} the paid amount
       */
      getMontantPaye: function(demande) {
        return _.get(demande, 'paiement.liquidations.0.montantPaye.ttc', _.get(demande, 'paiement.montantPaye.ttc'));
      },

      /**
       * Gets the amount of "mandat" from "demande paiement"
       * @param {object} demande The "demande paiement" object
       * @returns {number} the mandat amount
       */
      getMontantMandate: function(demande) {
        return _.get(
          demande,
          'paiement.liquidations.0.montantMandate.ttc',
          _.get(demande, 'paiement.montantMandate.ttc')
        );
      },

      /**
       * Gets the amount of a liquidation from "demande paiement"
       * @param {object} demande The "demande paiement" object
       * @returns {number} the liquidation amount
       */
      getMontantLiquide: function(demande) {
        return _.get(
          demande,
          'paiement.liquidations.0.montantLiquide.ttc',
          _.get(demande, 'paiement.montantLiquide.ttc')
        );
      },

      /**
       * Gets the proposed amount from "demande paiement"
       * @param {object} demande The "demande paiement" object
       * @returns {number} the proposed amount
       */
      getMontantPropose: function(demande) {
        return _.get(demande, 'paiement.montantPropose.ttc', _.get(demande, 'montantPropose.ttc'));
      },

      /**
       * Gets the asked of amount from "demande paiement"
       * @param {object} demande The "demande paiement" object
       * @returns {number} the demande amount
       */
      getMontantDemande: function(demande) {
        return _.get(demande, 'montant.ttc');
      },

      /**
       * Gets the previsional of amount from "demande paiement"
       * @param {object} demande The "demande paiement" object
       * @returns {number} the previsionel amount
       */
      getMontantPrevisionnel: function(demande) {
        return _.get(demande, 'montantPrevisionnel.ttc');
      },

      /**
       * Return amount with type name and value for "LePlusAvanceDemandePaiement"
       *
       * @description The most advanced amount on the demande paiement.
       * In the priority order we got : payed amount, mandated amount, liquidated amount,
       * proposed amount, asked amount and the previsional amount
       * @param {object} demande demande de paiement
       * @param {string[]} typesMontantAExclure Array of amount type to ignore when getting amount "le plus avancé"
       * @returns {object} The amount type and value
       */
      getTypeMontantLePlusAvanceDemandePaiement: function(demande, typesMontantAExclure) {
        if (!demande) return;

        if (!typesMontantAExclure) typesMontantAExclure = [];

        // amount's type should be in the following order (PDA9-304)
        // 1. montantPaye -> 2. montantMandate -> 3. montantLiquide
        // 4. montantPropose -> 5. montantDemande -> 6. montantPrevisionnel
        const typesMontant = [
          'MontantPaye',
          'MontantMandate',
          'MontantLiquide',
          'MontantPropose',
          'MontantDemande',
          'MontantPrevisionnel',
        ];

        let typeFirstMontant;

        typesMontant
          .filter((typeMontant) => {
            return !_.includes(typesMontantAExclure, typeMontant.type);
          })
          .every((typeMontant) => {
            const value = this.getMontantPaiementByTypeMontant(demande, typeMontant);
            if (!_.isNil(value)) {
              typeFirstMontant = {
                type: typeMontant,
                value: value,
              };
            }
            // break if first find
            return !typeFirstMontant;
          });

        return typeFirstMontant;
      },

      /**
       * Return true if there is at least one indicator and they are not all irrelevant
       * @param {array} indicateursPrevisionnel
       * @returns {Boolean}
       */
      mustDisplayIndicateursRealisation: function(indicateursPrevisionnel) {
        // Check if there is at least one indicator
        const thereIsAtLeastOneIndicator = indicateursPrevisionnel.length >= 1;
        // Check if all indicators are pertinent : false
        const allIndicatorsAreIrrelevant =
          indicateursPrevisionnel.filter((indicateur) => _.get(indicateur, 'pertinent') === false).length ===
          indicateursPrevisionnel.length;

        return thereIsAtLeastOneIndicator && !allIndicatorsAreIrrelevant;
      },

      /**
       * Return amount "LePlusAvanceDemandePaiement"
       *
       * @description "Le montant le plus avancé valorisé sur la demande de paiement. Dans l’ordre de priorité nous avons : le montant payé, le montant mandaté, le montant liquidé, le montant proposé, le montant prévisionnel puis le montant demandé."
       * @param {object} demande demande de paiement
       * @returns {number}
       */
      getMontantLePlusAvanceDemandePaiement: function(demande) {
        if (!demande) return 0;

        const montantLePlusAvance = this.getTypeMontantLePlusAvanceDemandePaiement(demande);
        return (montantLePlusAvance && montantLePlusAvance.value) || 0;
      },

      /**
       * Return the remaining to be paid on the voted amount
       * @param {array} demandesPaiement
       * @param {array} decisions
       * @returns {number} remaining to be paid
       */
      getMontantRestantAPayer: function(demandesPaiement, decisions) {
        return _.reduce(
          demandesPaiement,
          (summ, demande) => {
            return (
              summ - (this.getFirstMontantPaiementFromTypesMontant(demande, ['MontantPaye', 'MontantMandate']) || 0)
            );
          },
          _.sumBy(decisions, (decision) => {
            if (!decision.cancelation) {
              return _.get(decision, 'montant.ttc', _.get(decision, 'montant.ht'));
            }
          })
        );
      },

      /**
       * Returns the planFinancement with DEPOSE type
       * @param {object} demandePaiement
       * @returns {object}
       */
      getPlanFinancementDepose(demandePaiement) {
        return _.find(demandePaiement.planFinancement || [], (pf) => pf.type === 'DEPOSE');
      },

      /**
       * Build the url for the iframe for the demandePaiement's planFinancement
       * @param {object} demandePaiement
       * @param {boolean} [readOnly=false]
       * @returns {string|null}
       */
      getPlanFinancementIframeUrl(demandePaiement, readOnly) {
        const planFinancementDepose = this.getPlanFinancementDepose(demandePaiement);
        if (!planFinancementDepose) return null;

        const queryParams = $httpParamSerializer({
          entityUrl: _.get(planFinancementDepose, 'href'),
          teleserviceUrl: _.get(demandePaiement, 'teleservicePaiement.href'),
          jwtKey: jwtSessionService.getJwtKey(),
          readOnly: readOnly || false,
        });
        const url = `/referentiel-plan-financement/public/#/${configuration.tenant.id}/demandes-paiement/plan-financement?${queryParams}`;
        return $sce.trustAsResourceUrl(url).toString();
      },

      canCreatePaiement: function(dispositifHref) {
        if (_.isEmpty(dispositifHref)) {
          return $q.reject('Missing dispositif href');
        }

        const tenant = _.get(configuration, 'tenant.id');
        return $http
          .post(`/aides/api/tenants/${tenant}/demandes-paiement/can-create`, {
            href: dispositifHref,
          })
          .then((response) => response.data);
      },

      /**
       * Return the amount by typeMontant on paiement
       * @param {object} demande
       * @param {string} typeMontant
       * @returns {number|undefined}
       */
      getMontantPaiementByTypeMontant: function(demande, typeMontant) {
        const method = `get${typeMontant}`;
        if (demande && typeof this[method] === 'function') {
          return this[method](demande);
        }
      },

      /**
       * Return the first amount from list of typesMontant on paiement
       *
       * @param {object} demandePaiement
       * @param {Array} typeMontant
       * @returns {number}
       */
      getFirstMontantPaiementFromTypesMontant(demandePaiement, typesMontant) {
        if (!typesMontant || !Array.isArray(typesMontant)) return;
        for (const type of typesMontant) {
          const firstMontantFromType = this.getMontantPaiementByTypeMontant(demandePaiement, type);
          if (firstMontantFromType) {
            return firstMontantFromType;
          }
        }
      },

      /**
       * Return the remaining to be asked on all demandes
       *
       * @param {array} demandesPaiement
       * @param {array} decisions
       * @returns {number}
       */
      getMontantRestantADemander: function(demandesPaiement, decisions) {
        return _.reduce(
          (demandesPaiement || []).filter(
            (demandePaiement) => !['REJETE', 'REJETFINANCIER', 'REFUSE'].includes(demandePaiement.statut)
          ),
          (summ, element) => {
            return summ - this.getMontantLePlusAvanceDemandePaiement(element);
          },
          _.sumBy(decisions, (decision) => {
            if (!decision.cancelation) {
              return _.get(decision, 'montant.ttc', _.get(decision, 'montant.ht'));
            }
          })
        );
      },
    };
  });
