/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name siret-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A SIRET field for dynamic forms.
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 *
 * @example
 *
 *   `<siret-field name="siret" view-configuration="identificationConfiguration" data="association"></textare-field>`
 *
 */

angular.module('form.directives').directive('siretField', [
  '$timeout',
  'validationService',
  'formPreferences',
  '$translate',
  function ($timeout, validationService, formPreferences, $translate) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/siret-field/siret-field.html',
      require: '^form',
      scope: {
        name: '@',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=',
        bare: '=',
        labelClass: '=?',
        asString: '=',
        _helpTooltip: '=helpTooltip',
        _labelWidth: '=labelWidth',
        _fieldWidth: '=fieldWidth',
        _helpWidth: '=helpWidth',
        _colType: '=colType', // Can choose between different col style (col-md or col-sm for example)
        valid: '=?',
        readOnly: '=',
        edit: '=',
        onChange: '&?',
        remoteValidation: '=?',
      },
      link: function (scope, element, attrs, form) {
        scope.configuration =
          scope.config ||
          _.get(scope, 'viewConfiguration.fields.' + scope.name) ||
          {};
        scope.namespace =
          scope.ns ||
          scope.configuration.ns ||
          _.get(scope, 'viewConfiguration.ns');
        scope.namespace = scope.namespace + '.' + scope.name;

        // Read remote validation from directive, configuration or viewConfiguration
        scope.remoteValidation =
          scope.remoteValidation !== undefined
            ? scope.remoteValidation
            : scope.configuration.remoteValidation !== undefined
              ? scope.configuration.remoteValidation
              : _.get(scope, 'viewConfiguration.remoteValidation') !== undefined
                ? _.get(scope, 'viewConfiguration.remoteValidation')
                : false;

        scope.helpTooltip =
          scope._helpTooltip === undefined
            ? formPreferences.config().helpTooltip
            : scope._helpTooltip;
        scope.labelWidth =
          scope._labelWidth || formPreferences.config().labelWidth || 4;
        scope.fieldWidth =
          scope._fieldWidth || formPreferences.config().fieldWidth || 4;
        scope.helpWidth =
          scope._helpWidth || formPreferences.config().helpWidth || 4;
        scope.colType =
          scope._colType || formPreferences.config().colType || 'md';
        var forbiddenSIRET =
          scope._forbiddenSIRET || scope.configuration.forbiddenSIRET;
        if (forbiddenSIRET) {
          scope.forbiddenSIRET = {
            SIREN: forbiddenSIRET.substring(0, 9),
            NIC: forbiddenSIRET.substring(9),
          };
        }
        scope.forbiddenSIRETLabel =
          scope._forbiddenSIRETLabel || scope.configuration.forbiddenSIRETLabel;

        scope.labels = {};
        _.each(['help', 'details'], function (index) {
          scope.labels[index] = $translate.instant(
            scope.namespace + '.' + index
          );
          scope.labels[index] =
            scope.labels[index] === scope.namespace + '.' + index
              ? ''
              : scope.labels[index];
        });

        var viewConfigurationLabel = scope.configuration.label;
        var tranlatedLabel = $translate.instant(scope.namespace + '.label');
        if (!_.isEmpty(viewConfigurationLabel)) {
          scope.label = viewConfigurationLabel;
        } else if (tranlatedLabel !== scope.namespace + '.label') {
          scope.label = tranlatedLabel;
        } else {
          scope.label = 'SIRET';
        }

        scope.form = form;
        scope.help = scope.configuration.help;

        scope.checkSiretValidity = function (siret, nic) {
          // SIRET is not invalid if empty.
          // It should block only if required is true
          if (!siret && !nic) {
            scope.valid = true;
          } else {
            scope.valid = validationService.verifySiret(siret, nic);
          }
          return scope.valid;
        };

        scope.isSIRETForbidden = function (siren, nic) {
          return (
            scope.forbiddenSIRET &&
            scope.forbiddenSIRET.SIREN === siren &&
            scope.forbiddenSIRET.NIC === nic
          );
        };

        // Detect invalid field
        scope.isFieldInvalid = function (siretField) {
          // Also check manually required
          return (
            scope.form[scope.name + '-' + siretField].$invalid ||
            (!scope.form[scope.name + '-' + siretField].$viewValue &&
              scope.configuration.required)
          );
        };
        // Detect required error
        scope.hasErrorRequired = function (siretField) {
          return (
            scope.form[scope.name + '-' + siretField].$error.required ||
            (!scope.form[scope.name + '-' + siretField].$viewValue &&
              scope.configuration.required)
          );
        };

        scope.SIRET = {};
        if (angular.isString(scope.model)) {
          var siret = scope.model.split(' ');
          scope.SIRET.SIREN = siret[0];
          scope.SIRET.NIC = siret[1];
        } else if (angular.isObject(scope.model)) {
          scope.SIRET.SIREN = scope.model.SIREN;
          scope.SIRET.NIC = scope.model.NIC;
        } else {
          scope.SIRET.SIREN = '';
          scope.SIRET.NIC = '';
        }

        // Management of the SIREN and NIC fields
        scope.status = {
          SIREN: null,
          NIC: null,
        };

        scope.pasteSIREN = function ($event) {
          if (scope.readOnly) {
            return;
          }
          scope.dirty = true;
          scope.valid = false;
          var pasted =
            $event.originalEvent.clipboardData.getData('text/plain') || '';
          var siret = pasted.replace(/(\W|_)+/g, '');
          scope.SIRET.SIREN = siret.substring(0, 9);
          if (siret.length > 9) {
            scope.SIRET.NIC = siret.substring(9);
          }
          form[scope.name + '-SIREN'].$setDirty(true);
          form[scope.name + '-NIC'].$setDirty(true);
        };

        scope.$watch(
          'SIRET',
          function () {
            if (scope.asString) {
              // We don't set the model if both parts are empty, else we have a whitespace or even "undefined " as value
              if (
                !_.isEmpty(scope.SIRET.SIREN) &&
                !_.isEmpty(scope.SIRET.NIC)
              ) {
                scope.model = _.join([scope.SIRET.SIREN, scope.SIRET.NIC], ' ');
              } else {
                scope.model = undefined;
              }
            } else {
              scope.model = scope.SIRET;
            }
            if (scope.onChange && scope.dirty) {
              $timeout(scope.onChange, 0);
              scope.dirty = false;
            }
          },
          true
        );
      },
    };
  },
]);
