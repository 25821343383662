/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name exerice-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A basic exercice field for dynamic forms.
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 *   Uses kendo-ui datePicker directive
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 *
 * @example
 *
 *   `<exerice-field name="dateNaissance" view-configuration="identificationDemandeurConfiguration" data="demandeur"></exerice-field>`
 *
 */

angular.module('form.directives').directive('exerciceField', ['dateUtils', '$timeout', '$translate',

  function(dateUtils, $timeout, $translate) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/exercice-field/exercice-field.html',
      scope: {
        name: '@',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=',
        bare: '=',
        readOnly: '=',
        remoteValidation: '=?',
        labelClass: '=?'
      },
      link: function(scope) {

        var watches = [];
        var timer;
        scope.forceRefresh = true;

        // https://jira.mgdis.fr/browse/PLAID-10886: on enlève la valeur par défaut initialisé à l'année courante...
        // // Set default date to today if no model defined to avoid "Invalid Date" value
        // // https://jira.mgdis.fr/browse/PLAID-3593
        // if (scope.model === null || scope.model === undefined) {
        // 	scope.model = new Date().getFullYear();
        // }
        scope.exerciceField = {
          ngModel: scope.model,
          datePickerConfig: {
            value: new Date(scope.model, 0, 1),
            format: 'yyyy',
            culture: 'fr-FR',
            start: 'decade',
            depth: 'decade'
          }
        };

        // https://jira.mgdis.fr/browse/PLAID-10886: ... et on supprime la valeur du datePickerConfig inexistante
        if (_.isEmpty(scope.model)) {
          delete scope.exerciceField.datePickerConfig.value;
        }

        // Set configuration
        watches.push(scope.$watch('[config, viewConfiguration]', function() {
					
          //met un min et un max en place
          //on veut une date au format yyyy
          scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
					
          var findMin = _.find(_.get(scope, 'configuration.restrictions'), function(contrainte) {
            if (_.get(contrainte, 'minInclusive')) {
              return contrainte;
            }
          });
          var findMax = _.find(_.get(scope, 'configuration.restrictions'), function(contrainte) {
            if (_.get(contrainte, 'maxInclusive')) {
              return contrainte;
            }
          });
          var sizeRestrictions = 0;
          if (_.get(scope, 'configuration.restrictions') && _.get(scope, 'configuration.restrictions').length>=1) {
            sizeRestrictions = scope.configuration.restrictions.length-1;
          }
          _.set(scope, 'configuration.restrictions.'+ sizeRestrictions +'.minInclusive', _.get(findMin, 'minInclusive', 1000));
          _.set(scope, 'configuration.restrictions.'+ sizeRestrictions +'.maxInclusive', _.get(findMax, 'maxInclusive', 9999));

          scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');

          // Initialize (or reinitialize min and max)
          delete scope.exerciceField.datePickerConfig.min;
          delete scope.exerciceField.datePickerConfig.max;
          dateUtils.setMinRestriction(scope.configuration.restrictions, scope.exerciceField, 'years');
          dateUtils.setMaxRestriction(scope.configuration.restrictions, scope.exerciceField, 'years');

          // dirty dirty ! force refreshing of the kendo-ui directive so that configuration modifications are applied.
          scope.forceRefresh = false;
          timer = $timeout(function() {
            scope.forceRefresh = true;
            scope.$digest();
          }, 1);

          // Read remote validation from directive, configuration or viewConfiguration
          scope.remoteValidation = scope.remoteValidation !== undefined ? scope.remoteValidation :
            scope.configuration.remoteValidation !== undefined ? scope.configuration.remoteValidation :
              _.get(scope, 'viewConfiguration.remoteValidation') !== undefined ? _.get(scope, 'viewConfiguration.remoteValidation') : false;
        }, true));

        // Mise à jour de la valeur de l'exercice si la modification du model
        // se fait depuis l'extérieur de la directive
        watches.push(scope.$watch('model', function(newValue, oldValue) {
          if(newValue !== oldValue){
            scope.exerciceField.ngModel = scope.model;
          }
        }));

        // Binding from input to model
        watches.push(scope.$watch('exerciceField.ngModel', function() {
          scope.model = scope.exerciceField.ngModel ? _.parseInt(scope.exerciceField.ngModel) : undefined;
        }));

        scope.getPlaceholder = function() {
          var placeholderValue = _.get(scope, 'configuration.placeholder');
          if (!placeholderValue) {
            var placeholderKey = scope.namespace + '.' + scope.name + '.placeholder';
	          placeholderValue = $translate.instant(placeholderKey) !== placeholderKey ? $translate.instant(placeholderKey) : '';
	        }
          if (placeholderValue && placeholderValue === 'currentYear') {
            var currentYear = new Date();
            return currentYear.getFullYear();
          } else {
            return placeholderValue;
          }
        };

        scope.validateExerciceField = function() {
          // Case where the field was modified but not its model, it means that kendo rejected its format
          // if (scope.exerciceField.ngModel && !scope.exerciceField.kModel) {
          // 	return false;
          // }
          // Case where the value doesn't respect min and max constraints. Possible if the user doesn't use the datePicker.
          if (scope.exerciceField.datePickerConfig.max && scope.model > scope.exerciceField.datePickerConfig.max.getFullYear()) {
            return false;
          }
          if (scope.exerciceField.datePickerConfig.min && scope.model < scope.exerciceField.datePickerConfig.min.getFullYear()) {
            return false;
          }
          if (scope.exerciceField.ngModel && !new RegExp(/^[0-9]*$/).test(scope.exerciceField.ngModel)) {
            return false;
          }
          return true;
        };

        scope.$on('$destroy', function() {
          $timeout.cancel(timer);
          _.each(watches, function (watch) {
            watch();
          });

        });

      }
    };
  }
]);
