'use strict';
// see http://blog.auth0.com/2014/01/07/angularjs-authentication-with-cookies-vs-token/
// Will be called on all $HTTP requests, deal with 401 returns and set the Authorization header
// Must be configured in the $http interceptors by the application
angular
  .module('user.services.authentication-interceptor-service', ['user.services.user-session-service', 'configuration'])
  .factory('authInterceptor', [
    '$q',
    '$location',
    '$rootScope',
    'userSessionService',
    'configuration',
    function ($q, $location, $rootScope, userSessionService, configuration) {
      var includeCredentialsPrefixes =
        configuration.user.includeCredentialsPrefixes && configuration.user.includeCredentialsPrefixes.length > 0
          ? configuration.user.includeCredentialsPrefixes
          : ['/'];
      var excludeCredentialsPrefixes = configuration.user.excludeCredentialsPrefixes || [];

      var urlMatchPrefixes = function (url) {
        if (!url) {
          return false;
        }
        var urlMatchPrefix = function (prefix) {
          return url.indexOf(prefix) === 0;
        };
        if (!_.find(includeCredentialsPrefixes, urlMatchPrefix)) {
          return false;
        }
        if (_.find(excludeCredentialsPrefixes, urlMatchPrefix)) {
          return false;
        }
        return true;
      };

      return {
        request: function (config) {
          config.headers = config.headers || {};
          if (urlMatchPrefixes(config.url) && userSessionService.getSessionId()) {
            config.headers.Authorization = 'Bearer ' + userSessionService.getSessionId();
          }
          return config;
        },
      };
    },
  ]);
