angular.module('portailDepotDemandeAide').controller('homeController', [
  '$scope',
  '$location',
  '$timeout',
  '$state',
  '$stateParams',
  'configuration',
  'accountManagementService',
  'alertsService',
  function($scope, $location, $timeout, $state, $stateParams, configuration, accountManagementService, alertsService) {
    'use strict';

    var that = this;

    that.configuration = configuration;

    that.accountCreationHref = $state.href('app.user.account-creation', {
      redirectTo: $stateParams.redirectTo,
      redirectParams: $stateParams.redirectParams,
    });

    $scope.accountRecoverHref = $state.href('app.user.account-recover-informations');

    /**
     * Try to access to a page who you must be authenticated
     */
    if ($stateParams.redirectTo && _.includes($stateParams.redirectTo, 'connected')) {
      that.alerts = alertsService.getAlertWarning('home.userMustBeAuthenticated');
    }

    /**
     * Logged out
     */
    if ($stateParams.loggedOut) {
      that.alerts = alertsService.getAlertWarning('home.loggedOut');
    }

    /**
     * Validate account
     */
    if ($stateParams.username && $stateParams.token) {
      that.alerts = alertsService.getAlertWarning('En attente de validation');

      accountManagementService.validateAccount($stateParams.username, $stateParams.token).then(
        function() {
          $timeout(function() {
            that.alerts = alertsService.getAlertSuccess('user.account-creation-confirmation.validation-success');
          }, 200);
        },
        function(response) {
          if (response.status === 404) {
            that.alerts = alertsService.getAlertError(
              'user.account-creation-confirmation.errors.validation-failure-404'
            );
          } else {
            that.alerts = alertsService.getAlertError(
              'user.account-creation-confirmation.errors.validation-failure-other'
            );
          }
        }
      );
    }
  },
]);
