/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name dynamic-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A form field dynamically created from declarative options.
 *
 *   Uses specialized directives (text-field, number-field, etc.) according to the field dataType, data subtype or presentation.
 *
 * @param {string} name - the name of the field, also used as the key to set in the 'data' parameter.
 * @param {String} model - The model to bind to
 * @param {object} presentation - The presentation used to render this field. TODO detailed description of format when matured.
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {object} mdm - A mdm object as filled by the mdm service
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 *
 */
angular.module('form.directives').directive('dynamicField', ['formUtils', '$http', '$translate', '$log', '$filter',
  function(formUtils, $http, $translate, $log, $filter) {
    'use strict';

    return {
      restrict: 'EA',
      templateUrl: 'form/form-directives/dynamic-field/dynamic-field.html',
      require: ['^form', '?^dynamicFormGroups'],
      scope: {
        name: '@',
        model: '=',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        presentation: '=',
        mdm: '=',
        bare: '=',
        labelClass: '=?',
        readOnly: '=',
        authUser: '=',
        authPass: '='
      },
      link: function(scope, element, attrs, controllers) {

        var watches = [];

        // Configuration
        scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};

        // Merge presentation with configuration who can override some configuration's properties
        if (!_.isEmpty(scope.presentation)) {
          scope.configuration = _.merge(scope.configuration, scope.presentation);
        }

        var ordreOptions = _.get(scope.configuration, 'select.ordreOptions') || _.get(scope.viewConfiguration, 'select.ordreOptions');

        // Namespace
        scope.namespace = scope.ns || scope.configuration.ns;

        // Wrap primitive
        formUtils.wrapPrimitiveInObject(scope, 'model', 'data');

        // Access "scopeFields" element from directive "dynamic-form-group"
        var scopeFields;
        if (controllers && controllers[1] && controllers[1].scopeFields) {
          scopeFields = controllers[1].scopeFields;
        }

        // Read remote validation from configuration or viewConfiguration
        scope.remoteValidation = scope.configuration.remoteValidation !== undefined ? scope.configuration.remoteValidation :
          _.get(scope, 'viewConfiguration.remoteValidation') !== undefined ? _.get(scope, 'viewConfiguration.remoteValidation') : false;

        //  List of properties to analyse when we use angular expression on configuration's values properties.
        var properties = ['required', 'hidden', 'min', 'max', 'minlength', 'maxlength', 'pattern', 'minDuration', 'maxDuration'];

        // Clone the configuration for not override angular expression contains on some configuration's properties
        scope.dynamicFieldConfiguration = angular.copy(scope.configuration);
        scope.restrictionDataUrl = _.get(scope.dynamicFieldConfiguration, 'restrictions[0].dataURL');

        scope.selectField =
          (scope.dynamicFieldConfiguration.dataType === 'xs:string' || scope.dynamicFieldConfiguration.dataType === 'string') && !scope.dynamicFieldConfiguration.subType &&
          (!scope.dynamicFieldConfiguration.searchKey && !scope.dynamicFieldConfiguration.displayKey && (scope.restrictionDataUrl || scope.dynamicFieldConfiguration.dataURL));

        if (scope.selectField) {
          scope.source = scope.dynamicFieldConfiguration.dataURL && scope.mdm[scope.dynamicFieldConfiguration.dataURL] ||
            scope.dynamicFieldConfiguration.restrictions[0].dataURL;
        }

        //If there is not source, we go to search the source to dataSchemas
        if (!scope.source && (scope.dynamicFieldConfiguration.dataType === 'xs:string' ||
            scope.dynamicFieldConfiguration.dataType === 'string')) {

          if (_.get(scope, 'dynamicFieldConfiguration.schema.href')) {
            $http.get(scope.dynamicFieldConfiguration.schema.href)
              .then(function(response) {
                var datSchConf = _.get(response.data, 'results[0]');
                scope.dataSchemaConfiguration = datSchConf;

                // Propagation de la propriété dynamique 'required'
                // D'autres propriétés peuvent potentiellement être concernées
                _.set(scope, 'dataSchemaConfiguration.required', _.get(scope, 'dynamicFieldConfiguration.required'));

                if (_.get(datSchConf, 'restrictions[0].dataURL')) {
                  scope.selectField = false;

                  var firstDisplayKey = scope.getFirstDisplayKey(datSchConf.displayKey);
                  scope.ngOptions = 'item.' + (datSchConf.persistedKey || firstDisplayKey) + ' as item.' + firstDisplayKey + ' for item in dynamicSearch($viewValue)';
                  scope.source = scope.dataSchemaConfiguration.restrictions[0].dataURL;

                  // Bakcup help field, and replace its value to avoid ugly display
                  if (datSchConf.displayKey && datSchConf.displayKey.indexOf(',') !== -1 &&
                    scope.dynamicFieldConfiguration.helpTooltip) {
                    scope.helpViewConfigurationBackup = angular.copy(scope.dynamicFieldConfiguration.help);
                    scope.dynamicFieldConfiguration.help = ' ';
                  }

                  // If the model contains data at loading and several values are in displayKey, we need to get some other data from MDM
                  if (!_.isEmpty(scope.model) && datSchConf.displayKey && datSchConf.displayKey.indexOf(',') !== -1) {
                    scope.dynamicSearch(scope.model).then(function(results) {
                      if (results && results[0]) {
                        scope.typeaheadOnSelect(results[0]);
                      }
                    }, function(error) {
                      $log.error('Error occured while requesting data: ', error);
                    });
                  }
                }
              });
          }
        }

        function localeSensitiveComparator(v1, v2) {
          // If we don't get strings, just compare by index
          if (v1.type !== 'string' || v2.type !== 'string') {
            return (v1.index < v2.index) ? -1 : 1;
          }

          // Compare strings alphabetically, taking locale into account
          return v1.value.localeCompare(v2.value);
        }

        //If is a enumeration, we're gonna search the items into the schema
        if (scope.dynamicFieldConfiguration.dataType === 'enumeration' || scope.dynamicFieldConfiguration.dataType === 'xs:enumeration') {
          $http.get(scope.dynamicFieldConfiguration.schema.href)
            .then(function(response) {
              var items = _.get(response.data, 'results[0].restrictions[0].enumeration.item', []).filter(function(item) {
                return item.actif === true;
              });
              if(ordreOptions) {
                items = $filter('orderBy')(items, ordreOptions, false, localeSensitiveComparator);
              }
              _.set(scope, 'dynamicFieldConfiguration.restrictions[0].enumeration.item', items);
            });
        }

        // Check if we have access to property "scopeFields" of scope of the directive "dynamic-form-groups"
        if (scopeFields) {
          _.each(properties, function(property) {
            // Evaluate property
            if (formUtils.checkIsAngularExpression(scope.configuration[property])) {
              watches.push(scopeFields.$watch(scope.configuration[property], function(newValue, oldValue) {
                if (newValue !== undefined) {
                  scope.dynamicFieldConfiguration[property] = newValue;
                }
                // Force clear field model on change on hidden property
                if (property === 'hidden' && newValue !== oldValue) {
                  scope.model = undefined;
                }
              }));
            }
          });
        }

        /**
         * When displayKey contains several keys separated by a comma, returns the first key
         * @param  {string} displayKey param from configuration
         * @return {string}            First displayKey param if several
         */
        scope.getFirstDisplayKey = function(displayKey) {
          // Updates displayKey scope's variable used in other functions
          scope.displayKey = displayKey;

          return displayKey && displayKey.indexOf(',') !== -1 ?
            displayKey.substring(0, displayKey.indexOf(',')) :
            displayKey;
        };

        /**
         * Function executed while the typeahead value is selected. This was added in order to update the tooltip
         * located near the typeahead when a new value is selected.
         *
         * @param  {[type]} $item  [description]
         * @param  {[type]} $model [description]
         * @param  {[type]} $label [description]
         * @return {[type]}        [description]
         */
        scope.typeaheadOnSelect = function($item) {
          if ($item &&
            _.get(scope, 'displayKey') &&
            scope.displayKey.indexOf(',') !== -1 &&
            scope.dynamicFieldConfiguration.helpTooltip) {

            // Backup help text when memo mode is activated on typeahead
            if (_.isEmpty(scope.helpViewConfigurationBackup)) {
              scope.helpViewConfigurationBackup = angular.copy(scope.dynamicFieldConfiguration.help);
            }
            scope.dynamicFieldConfiguration.help = $translate.instant(scope.helpViewConfigurationBackup, {
              data: $item
            });
          }
        };

        /**
         * Function executed when the user types in the typeahead
         * @return {[type]} [description]
         */
        scope.onChange = function() {
          // Backup help text when memo mode is activated on typeahead
          if (_.isEmpty(scope.helpViewConfigurationBackup)) {
            scope.helpViewConfigurationBackup = angular.copy(scope.dynamicFieldConfiguration.help);
          }
          scope.dynamicFieldConfiguration.help = ' ';
        };

        // Access to regex comparison from template
        scope.regTest = function(value, regexp) {
          return new RegExp(regexp).test(value);
        };

        scope.dynamicSearch = function dynamicSearch(searchKey) {
          if (searchKey !== undefined && searchKey !== '') {
            // Double the special characters
            searchKey = searchKey.replace(/([.*+?^=!:${}()|\'\"\[\]\/\\])/g, '$1$1');
            var filter = 'substringof(\'' + searchKey.toLowerCase() + '\', tolower(' + scope.dataSchemaConfiguration.searchKey + '))';

            var options = {
              url: scope.source,
              params: {
                $filter: filter,
                $inlinecount: 'allpages',
                $select: scope.dataSchemaConfiguration.displayKey,
                $orderby: scope.dataSchemaConfiguration.displayKey + ' asc',
                $top: 200
              }
            };

            if (scope.dataSchemaConfiguration.authSource === true && scope.authUser && scope.authPass) {
              var authorization = 'Basic ' + btoa(scope.authUser + ':' + scope.authPass);
              _.set(options, 'headers.Authorization', authorization);
            }

            var firstDisplayKey = scope.getFirstDisplayKey(scope.dataSchemaConfiguration.displayKey);
            return $http(options)
              .then(function(response) {

                scope.dataFetchedFromServer = true;
                var items = _.get(response.data, 'd.results', []);
                return _.uniq(items, firstDisplayKey);
              }, function(error) {
                scope.dataFetchedFromServer = true;
                $log.error('An error occured while requesting data from dynamic-field: ', error);
              });
          }
        };

        scope.isRequired = function() {
          return (
            // ancienne condition (compatibilité ascendante)
            (!scope.remoteValidation &&
              !scope.configuration.hidden &&
              scope.configuration.required) ||
            // nouvelle condition (bug fix)
            (!scope.remoteValidation &&
              !scope.dynamicFieldConfiguration.hidden &&
              scope.dynamicFieldConfiguration.required)
          );
        };

        scope.$on('$destroy', function() {
          _.each(watches, function(watch) {
            watch();
          });
        });
      }
    };
  }
]);
