'use strict';
angular.module('user.directives').directive('accountPasswordRecover', [
  '$location',
  'configuration',
  'accountManagementService',
  'alertsService',

  function ($location, configuration, accountManagementService, alertsService) {
    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'user/user-directives/account-password-recover/account-password-recover.html',
      scope: {
        viewConfiguration: '=',
      },
      link: function (scope) {
        scope.recover = {
          userId: null,
        };

        /**
         * Reset the password of a user
         * @param userId The id of the user which password has to be reset
         */
        scope.resetPassword = function (userId) {
          var linkMail = $location
            .absUrl()
            .replace($location.path(), configuration.user.accountResetPasswordConfirmationPath);
          accountManagementService.resetPassword(userId, linkMail).then(
            function success() {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertSuccess('user.account-recover-informations.password-forgotten-confirmation'),
                'notification-reset-password'
              );
              scope.accountPasswordRecoverForm.$setPristine();
              scope.recover.userId = null;
            },
            function error() {
              scope.$broadcast(
                'alerts',
                alertsService.getAlertError('user.account-recover-informations.password-forgotten-unknown'),
                'notification-reset-password'
              );
            }
          );
        };
      },
    };
  },
]);
