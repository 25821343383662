angular.module('portailDepotDemandeAide.depot').component('timelineComponent', {
  templateUrl: 'dashboard/aides/aide/timeline/timeline.component.html',
  controller: TimelineComponent,
  bindings: {
    originDemandeReference: '<',
    demande: '<',
  },
});

/* @ngInject */
function TimelineComponent(aidesService, AidesConstant, $translate, $filter) {
  this.$onInit = () => {
    this.displayTimeline = false;

    this.timeline = [
      // Créée le :
      {
        check: () => true,
        stepLabel: () =>
          $translate.instant(
            aidesService.isRenewed(this.demande.aide)
              ? 'connected.dashboard.aides.list.timeline.creationEtDepotDate'
              : 'connected.dashboard.aides.list.timeline.creationDate',
            { date: this.demande.createdOn }
          ),
      },
      // Déposée le : avec ou sans avis préalable
      {
        check: () => this.demande.aEteTransmise && !aidesService.isRenewed(this.demande.aide),
        stepLabel: () =>
          $translate.instant('connected.dashboard.aides.list.timeline.depotDate', {
            date: this.demande.avisPrealableDonne ? this.demande.dateDepotAvisPrealable : this.demande.transmittedOn,
          }),
      },
      // Avis préalable rendu le : avec avis préalable (affiché juste au statut)
      {
        check: () =>
          this.demande.aEteTransmise &&
          this.demande.avisPrealableDonne &&
          this.demande.state === AidesConstant.demandeStatuts.TRANSMITTED,
        stepLabel: () =>
          $translate.instant('connected.dashboard.aides.list.timeline.avisPrealable', {
            date: this.demande.transmittedOn,
          }),
      },
      // Déposée le : date de transmission en attente d avis préalable
      {
        check: () => this.demande.state === AidesConstant.demandeStatuts.REGISTERED,
        stepLabel: () =>
          $translate.instant('connected.dashboard.aides.list.timeline.depotDate', {
            date: this.demande.dateDepotAvisPrealable,
          }),
      },
      // Prise en charge le : date de prise en charge
      {
        check: () => this.demande.state === AidesConstant.demandeStatuts.SUPPORTED,
        stepLabel: () =>
          $translate.instant('connected.dashboard.aides.list.timeline.supportedDate', {
            date: this.demande.supportedOn,
          }),
      },
      // Demande avec autre statut
      {
        check: () =>
          !this.demande.aide.multiFinanceur &&
          !this.demande.lastDecision &&
          ![
            AidesConstant.dossierStatuts.VOTE,
            AidesConstant.demandeStatuts.REGISTERED,
            AidesConstant.demandeStatuts.TRANSMITTED,
            AidesConstant.demandeStatuts.REQUESTED,
            AidesConstant.dossierStatuts.SOLDE,
          ].includes(this.demande.state) &&
          this.demande.justification === undefined,
        stepLabel: () =>
          `${$translate.instant(`connected.dashboard.aides.list.state-${this.demande.state}`)}${$translate.instant(
            'connected.dashboard.aides.list.le'
          )}${$filter('date')(this.demande.lastUpdate, 'longDate')}${$translate.instant(
            'connected.dashboard.aides.list.a'
          )}${$filter('date')(this.demande.lastUpdate, 'mediumTime')}`,
      },
      // Votée le :
      {
        check: () =>
          this.demande.aide.multiFinanceur !== true &&
          this.demande.state === AidesConstant.dossierStatuts.VOTE &&
          this.demande.lastDecision,
        stepLabel: () =>
          $translate.instant('connected.dashboard.aides.list.timeline.voteDate', {
            date: this.demande.lastDecision.dateMetier || this.demande.lastDecision.date,
          }),
      },
      // Soldée le :
      {
        check: () =>
          this.demande.aide.multiFinanceur !== true &&
          this.demande.state === AidesConstant.dossierStatuts.SOLDE &&
          !this.demande.lastDecision,
        stepLabel: () =>
          $translate.instant('connected.dashboard.aides.list.timeline.voteDate', { date: this.demande.lastUpdate }),
      },
    ]
      .filter(({ check }) => check())
      .map(({ stepLabel }) => stepLabel());
  };
}
