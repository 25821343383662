angular.module('portailDepotDemandeAide.depot').controller('depotSimpleBeneficiaireIdentificationController', [
  '$scope',
  '$rootScope',
  'StoreService',
  'depotSimpleService',
  'alertsService',
  function($scope, $rootScope, StoreService, depotSimpleService, alertsService) {
    'use strict';

    $scope.demandeur = StoreService.demandeur.get();
    $scope.beneficiaire = StoreService.beneficiaire.get();

    $scope.fullName = depotSimpleService.getFullNameValidity($scope.currentUser && $scope.currentUser.name);

    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsBeneficiaire || {};

    // disable type (famille) and adresseEtrangere fields + SIRET if adresseEtrange is false
    // because those fields are already filled in the demandeur-famille step
    const adressePrincipale = _.find($scope.beneficiaire.situations, {
      principale: true,
    });
    const adresseEtrangere = adressePrincipale && adressePrincipale.etrangere;

    const stepMetadata = _.get($scope, 'aide.history.begin.metadata.stepMetadata') || {};

    $scope.restrictionFamillesTiers = _.map($scope.pageOptions.familles, 'famille.href');

    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.identificationBeneficiaireConfiguration.ns;
    $scope.identificationBeneficiaireConfiguration.showErrors = $scope.showErrorsOnNavigate();

    // Don't display errors on family change
    $scope.$watch('beneficiaire.famille', function(newFamille, oldFamille) {
      if (newFamille) {
        if (newFamille !== oldFamille) {
          $scope.identificationBeneficiaireConfiguration.showErrors = false;
        }
      }
    });

    $scope.viewConfiguration = _.merge({}, $scope.identificationBeneficiaireConfiguration, {
      fields: {
        type: {
          readOnly: true,
        },
        adresseEtrangere: {
          readOnly: true,
        },
        SIREN: {
          readOnly: stepMetadata.sirenReadOnly === undefined ? !adresseEtrangere : stepMetadata.sirenReadOnly,
        },
        NIC: {
          readOnly: stepMetadata.nicEditable === undefined ? !adresseEtrangere : !stepMetadata.nicEditable,
        },
        codeRNA: {
          readOnly: stepMetadata.codeRnaReadOnly === undefined ? false : stepMetadata.codeRnaReadOnly,
        },
      },
    });

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'beneficiaire';

    $scope.navigate.next = function() {
      // Check that beneficiaire SIRET is different than demandeur SIRET
      const demandeurSIRET = _.get($scope, 'aide.demandeur.expand.SIRET');
      const beneficiaire = _.get($scope, 'aide.beneficiaires[0].expand');
      if (
        demandeurSIRET &&
        demandeurSIRET.SIREN &&
        demandeurSIRET.NIC &&
        _.get(beneficiaire, 'famille.expand.personnaliteJuridique') === 'MORALE'
      ) {
        const beneficiaireSIRET = beneficiaire.SIRET;
        if (beneficiaireSIRET.SIREN === demandeurSIRET.SIREN && beneficiaireSIRET.NIC === demandeurSIRET.NIC) return;
      }
      // Request fonctions representants
      $scope.setFonctionsRepresentants(_.get($scope.aide.beneficiaires[0], 'expand.famille.expand'), 'beneficiaire');
      if ($scope.fullName.valid) {
        const nextPageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsThematiquesBeneficiaire || {};
        // List of fields and sections set in teleservice
        const teleserviceTypeTiersChamps = _.get(
          nextPageOptions.champsParTypeTiers,
          $scope.beneficiaire.famille.expand.typeFamille
        );
        // Page active if one configuration has been found for current tiers' type
        const beneficiaireThematiqueActive = nextPageOptions.actif && teleserviceTypeTiersChamps;

        StoreService.beneficiaire.set($scope.beneficiaire);

        $scope.saveBeneficiaire().then(() =>
          // if beneficiaire-thematique is disabled we should skip it
          $scope.goToStep(beneficiaireThematiqueActive ? 'beneficiaire-thematiques' : 'beneficiaire-adresse')
        );
      } else {
        $rootScope.$broadcast(
          'alerts',
          alertsService.getAlertError('teleservice.beneficiaire-identification.full-name.error.pattern')
        );
      }
    };
  },
]);
