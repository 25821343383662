angular.module('portailDepotDemandeAide.dashboard').directive('recapitulatifAvisInformationsGenerales', [
  '$translate',
  'teleservicesService',
  function($translate, teleservicesService) {
    'use strict';
    return {
      restrict: 'EA',
      replace: true,
      templateUrl:
        'dashboard/contributions/contributions-recapitulatif/informations-generales/recapitulatif-informations-generales.html',
      scope: {
        contribution: '=',
        configuration: '=',
        namespace: '<',
        teleservice: '<',
      },
      link: function(scope) {
        // scope.contribution.teleservice : href du teleservice de la contribution en cours de visualisation
        var teleserviceContributionHref = _.get(scope, 'contribution.teleservice.href', '');
        var reference = teleserviceContributionHref.split('/').pop();

        function generateFields(parametrageProperties) {
          if (_.get(scope, 'contribution.typeContribution') === 'AVIS_FINANCEMENT') {
            scope.fields = _.get(
              scope,
              'teleservice.workflow.contributionAvisFinancement.pageContributionAvisFinancement.fields'
            );

            var dateField = _.find(scope.fields, ['reference', 'date']);
            var avisField = _.find(scope.fields, ['reference', 'avis']);
            var montantDemandeField = _.find(scope.fields, ['reference', 'montantDemande']);
            var montantProposeField = _.find(scope.fields, ['reference', 'montant']);
            var commentaireField = _.find(scope.fields, ['reference', 'commentaire']);
            var accordField = _.find(scope.fields, ['reference', 'accordPluriannuel']);
            var millesimeField = _.find(scope.fields, ['reference', 'exercice']);

            dateField.value = _.get(scope, 'contribution.avis.date');
            avisField.value = _.get(scope, 'contribution.avis.valeur') === 'FAVORABLE' ? 'RECEVABLE' : 'IRRECEVABLE';
            montantDemandeField.value = _.get(
              scope,
              'contribution.montantDemande.ttc',
              _.get(scope, 'contribution.montantDemande.ht')
            );
            montantProposeField.value = _.get(
              scope,
              'contribution.avis.montant.ttc',
              _.get(scope, 'contribution.avis.montant.ht')
            );
            commentaireField.value = _.get(scope, 'contribution.avis.commentaire');
            if (accordField) {
              var accordValue = _.get(scope, 'contribution.avis.accordPluriannuel', false) ? 'oui' : 'non';
              accordField.value = accordValue;
            }
            if (millesimeField) {
              //? Display the exercice of the first year until we can display the current year + n when in contribution mode
              var millesimeValue = _.get(scope, 'contribution.demande.planFinancement.0.periode.exercice');
              millesimeField.value = millesimeValue;
            }
          } else {
            _.merge(scope.configuration.fields, parametrageProperties);
            scope.fields = _.transform(
              scope.configuration.fields,
              function(fieldsList, field, reference) {
                if (reference === 'exercice' || reference === 'accordPluriannuel') return;
                if (reference !== 'actif') {
                  field.reference = reference;

                  if (reference === 'avis') {
                    field.value = $translate.instant(
                      'connected.dashboard.contributions.list.' + _.get(scope, 'contribution.avis.valeur')
                    );
                  } else {
                    field.value = _.get(scope, 'contribution.avis.' + reference);
                  }

                  if (_.isObject(field.value) && _.get(field.value, 'value') !== undefined) {
                    field.value = field.value.value;
                  }

                  if (_.isObject(field.value) && _.isEmpty(field.value)) {
                    field.value = '';
                  }

                  fieldsList.push(field);
                }
              },
              []
            );
            // dataType isn't specified in the configuration, so we do it here for now
            var montant = _.find(scope.fields, {
              reference: 'montant',
            });
            _.set(montant, 'subType', 'currency');
            _.set(montant, 'value', _.get(montant, 'value.ttc', _.get(montant, 'value.ht')));
          }

          // Format date for contribution
          var date = _.find(scope.fields, {
            reference: 'date',
          });
          if (_.get(date, 'value')) {
            _.set(date, 'value', moment(date.value).format('DD/MM/YYYY'));
          }
        }

        teleservicesService.getTeleService(reference).then(function(result) {
          generateFields(_.get(result, 'workflow.contributionAvis.pageContributionAvis'));
        });
      },
    };
  },
]);
