angular.module('portailDepotDemandeAide.depot').component('demandesLiees', {
  templateUrl: 'dashboard/aides/demandes-liees/demandes-liees.component.html',
  bindings: {
    tiers: '<',
    demande: '<',
    justificationOnly: '<',
    currentTeleservice: '<',
  },
  controller: /* @ngInject */ function(renewedDemandesFinancementService) {
    this.$onInit = () => {
      this.loading = false;
      this.opened = false;
    };

    /**
     * Toggle the displaying of the linked demande
     * @returns {Promise<undefined>}
     */
    this.toggle = () => {
      this.opened = !this.opened;
      if (!this.demandesLiees && !this.loading) {
        this.loading = true;
        return renewedDemandesFinancementService
          .getDemandesLiees(this.demande)
          .then((result) => {
            this.demandesLiees = result;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    };
  },
});
