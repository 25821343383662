angular.module('portailDepotDemandeAide').controller('cguController', [
  '$scope',
  '$rootScope',
  'cguService',
  function($scope, $rootScope, cguService) {
    'use strict';

    _.set($scope, 'viewConfiguration.ns', 'cgu');

    $scope.title = _.get($scope, 'data.publicSettings.cgu.title');
    $scope.texte = _.get($scope, 'data.publicSettings.cgu.texte');
    $scope.acceptation = _.get($scope, 'data.publicSettings.cgu.acceptation');

    /** La fermeture des CGU provoque la validation automatique */
    $scope.Fermer = function(modal) {
      cguService.updateDateAcceptationCGU(_.get($rootScope, 'currentUser.self'), new Date().toISOString());

      // Quelques soit le retour du patch, on ferme la modal...
      modal.$hide();
      // ...et on ouvre la modal suivante
      $scope.next();
    };
  },
]);
