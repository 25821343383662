angular.module('common.directives').directive('downloadDocument', [
  '$http',
  'fileService',
  function($http, fileService) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'common/common-directives/download-document/download-document.html',
      scope: {
        document: '=',
        icon: '=',
        label: '@',
        documentInfoVisible: '=?',
      },
      link: function($scope) {
        $scope.compatibilityError = (bowser.ios || bowser.safari) && (bowser.mobile || bowser.tablet);
        $scope.$watch('document.expand.properties', function(newValue) {
          if (newValue && $scope.documentInfoVisible) {
            $scope.documentLength =
              $scope.document.contentStreamLength ||
              $scope.document.expand.properties.contentStreamLength ||
              ($scope.document.expand.properties &&
                $scope.document.expand.properties['cmis:contentStreamLength'] &&
                $scope.document.expand.properties['cmis:contentStreamLength'].value) ||
              ($scope.document.expand.succinctProperties &&
                $scope.document.expand.succinctProperties['cmis:contentStreamLength']);
            $scope.documentDate =
              $scope.document.creationDate ||
              $scope.document.expand.properties.creationDate ||
              ($scope.document.expand.properties &&
                $scope.document.expand.properties['cmis:creationDate'] &&
                $scope.document.expand.properties['cmis:creationDate'].value) ||
              ($scope.document.expand.succinctProperties &&
                $scope.document.expand.succinctProperties['cmis:creationDate']);
          }
        });

        /**
         * Function who format bytes
         * @param  {Number} bytes Number of bytes
         * @return {String}       Bytes formated
         */
        $scope.formatSizeFile = fileService.formatSizeFile;

        /**
         * Download a document
         * @param  {Url} url           Url to access the document
         * @param  {Document} document Document
         */
        $scope.downloadDocument = function() {
          var url = $scope.document.href.replace('&cmisselector=object', '').replace('?cmisselector=object', '');
          $http
            .get(url, {
              responseType: 'arraybuffer',
            })
            .then(function(reponse) {
              var documentExpand = $scope.document.expand || {};

              // Mime type
              documentExpand.properties['cmis:contentStreamMimeType'] =
                documentExpand.properties['cmis:contentStreamMimeType'] || {};
              var mimeType = documentExpand.properties['cmis:contentStreamMimeType'].value;

              // Blob
              var blob = new Blob([reponse.data], {
                type: mimeType,
              });

              documentExpand.properties['cmis:name'] = documentExpand.properties['cmis:name'] || {};
              // always take the name of the file on properties.entity:document:originalfilename.value
              //   adding REGEX to secure the Filename if 'properties.entity:document:originalfilename.value' === null
              var documentName =
                _.get(documentExpand, 'properties.entity:document:originalfilename.value') ||
                _.get(documentExpand, 'properties.cmis:name.value').replace(/[0-9A-Fa-f]{13}-(.*)/, '$1');
              saveAs(blob, documentName);
            });
        };
      },
    };
  },
]);
