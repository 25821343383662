angular.module('tiers').component('inscriptionRegistre', {
  templateUrl:
    'tiers/tiers-directives/identification-form/includes/inscription-registre/inscription-registre.component.html',
  controller: /* @ngInject */ inscriptionRegistre,
  require: {
    model: 'ngModel',
    form: '?^^form',
  },
  bindings: {
    viewConfiguration: '<',
  },
});

inscriptionRegistre.$inject = ['tiersService'];

function inscriptionRegistre(tiersService) {
  'use strict';

  var ctrl = this;
  ctrl.tribunauxInstances = [];

  ctrl.dateInscriptionRegistreConfig = {
    min: '01/01/1900',
    culture: 'fr-FR',
  };

  ctrl.validDate = function(displayDate, date) {
    return !(!_.isEmpty(displayDate) && !date);
  };

  ctrl.checkNull = function() {
    if (_.isEmpty(this.inscriptionRegistre.volume)) {
      this.inscriptionRegistre.volume = undefined;
    }
    if (_.isEmpty(this.inscriptionRegistre.folio)) {
      this.inscriptionRegistre.folio = undefined;
    }
  };

  /**
   * Allow to control tribunal value and delete if it's not valued
   */
  ctrl.onTribunalChange = function() {
    if (_.isEmpty(this.inscriptionRegistre.tribunal)) {
      delete this.inscriptionRegistre.tribunal;
    }
  };

  ctrl.$onInit = function() {
    ctrl.model.$render = function() {
      ctrl.inscriptionRegistre = ctrl.model.$viewValue;
      if (ctrl.inscriptionRegistre.date) {
        ctrl.inscriptionRegistreDateDisplay = moment(ctrl.inscriptionRegistre.date).format('DD/MM/YYYY');
      }
    };

    tiersService.getTribunauxInstances().then(function(tribunauxInstance) {
      ctrl.tribunauxInstances = _.filter(tribunauxInstance.items, function(tribunalInstance) {
        return tribunalInstance.expand.actif;
      });
    });
  };
}
