/**
 * @ngdoc service
 * @name tiers.services.tiersValidationService
 * @rdescription Validate specific types of data
 **/
angular.module('tiers.services').factory('tiersValidationService', [
  function() {
    'use strict';

    return {
      /**
       * Méthode qui vérifie la validité du siret
       * @param  {String} siren Siren
       * @param  {String} nic   Nic
       * @return {Boolean} Indique si le siret est valide
       */
      checkSiretValidity: function(siren, nic) {
        /*jshint maxcomplexity:false */
        var validity = false;

        if (!(siren || nic)) {
          validity = true;
        } else if (siren && nic && siren.length === 9 && nic.length === 5) {
          var siret = siren + nic;
          var somme = 0;
          var tmp;

          if (
            parseInt(siren) !== 356000000 ||
            (parseInt(siren) === 356000000 && parseInt(nic, 10) === parseInt('00048', 10))
          ) {
            for (var cpt = 0; cpt < siret.length; cpt++) {
              if (cpt % 2 === 0) {
                tmp = parseInt(siret.charAt(cpt)) * 2;
                if (tmp > 9) {
                  tmp -= 9;
                }
              } else {
                tmp = parseInt(siret.charAt(cpt));
              }
              somme += parseInt(tmp);
            }
          } else {
            for (var i = 0; i < siret.length; i++) {
              somme += parseInt(siret.charAt(i));
            }
          }

          if (somme % 10 === 0 || (parseInt(siren) === 356000000 && somme % 5 === 0)) {
            validity = true;
          }
        }

        return validity;
      },
    };
  },
]);
