function changeTiersButtonController($scope, $sce, $translate, $log, $state, $timeout, portalPersistenceService) {
  this.$onInit = () => {
    this.showAlertPopover = false;
    this.showTiersFullPopover = false;
    this.getHelpText = $sce.trustAsHtml($translate.instant('connected.tiers-button.help'));
    this.isPhysique =
      _.get(this.currentTiers, 'individu.Noms.NomUsage') && _.get(this.currentTiers, 'individu.Prenoms.PrenomUsuel');

    portalPersistenceService
      .getPersistence()
      .then((res) => {
        this.currentPersistence = res.data;
        this.showAlertPopover = !_.get(this.currentPersistence, 'notificationMultitiersMenu.viewed');
      })
      .catch((err) => {
        $log(err);
      });

    // register custom close popover event
    const that = this;
    $('body').on('click.out-off-tiers-change', function() {
      $timeout(() => {
        that.showAlertPopover = false;
        that.showTiersFullPopover = false;
        $scope.$apply();
      });
    });
  };

  // Close notification for good
  this.closeNotificationPersistence = () => {
    if (this.currentPersistence) {
      portalPersistenceService
        .setPersistence(_.merge(this.currentPersistence, { notificationMultitiersMenu: { viewed: true } }))
        .catch((err) => {
          $log(err);
        });
    }
  };
  // Open full tiers popover
  this.toggleFullTiersPopover = ($event) => {
    $event.stopPropagation();
    this.showAlertPopover = false;
    this.showTiersFullPopover = !this.showTiersFullPopover;
  };

  // Open change tiers state
  this.goTochangeTiers = ($event) => {
    $event.stopPropagation();
    this.showTiersFullPopover = false;
    $state.go('app.connected.tiers-selection', {
      redirectTo: $state.current.name,
      redirectParams: JSON.stringify($state.params),
      redirectFrom: 'portal',
    });
  };

  this.close = () => {
    this.showAlertPopover = false;
  };

  this.$onDestroy = () => {
    $('body').off('click.out-off-tiers-change');
  };
}

changeTiersButtonController.$inject = [
  '$scope',
  '$sce',
  '$translate',
  '$log',
  '$state',
  '$timeout',
  'portalPersistenceService',
];

angular.module('common.components').component('changeTiersButton', {
  templateUrl: 'common/common-components/change-tiers/change-tiers.html',
  controller: changeTiersButtonController,
  transclude: true,
  bindings: {
    allTiers: '<',
    currentTiers: '<',
  },
});
