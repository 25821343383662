class ContributionsListeController {
  constructor($scope, $modal, $state, $http, contributionsService, alertsService, $log, $translate, urlService) {
    this.$scope = $scope;
    this.$modal = $modal;
    this.$state = $state;
    this.$http = $http;
    this.contributionsService = contributionsService;
    this.alertsService = alertsService;
    this.$log = $log;
    this.$translate = $translate;
    this.urlService = urlService;
  }

  /**
   * Check if contribution can be answered
   * @param {object} contribution
   * @return {boolean}
   */
  canAnswer(contribution) {
    if (this.isTypeAvis(contribution)) {
      this.tooltip = 'connected.dashboard.contributions.list.avis-edit';
      return !this.isClosed(contribution) && contribution.statut === 'INPROGRESS';
    } else {
      this.tooltip = 'connected.dashboard.contributions.list.edit';
      return !this.isClosed(contribution) && ['ASKED', 'INPROGRESS'].includes(contribution.statut);
    }
  }

  /**
   * Check if contribution is closed
   * @param {object} contribution
   * @return {boolean}
   */
  isClosed(contribution) {
    return contribution && _.get(contribution, 'cloture.value');
  }

  /**
   * Check if contribution type is MODIFICATION or REDIRECTION
   * @param {object} contribution
   * @return {boolean}
   */
  isTypeModificationOrRedirection(contribution) {
    return ['MODIFICATION', 'REDIRECTION'].includes(contribution.typeContribution);
  }

  /**
   * Check if contribution type is AVIS_FINANCEMENT or AVIS
   * @param {object} contribution
   * @return {boolean}
   */
  isTypeAvis(contribution) {
    return ['AVIS_FINANCEMENT', 'AVIS'].includes(contribution.typeContribution);
  }

  /**
   * Get date prefix
   * @param {object} contribution
   * @return {string}
   */
  datePrefix(contribution) {
    const path = 'connected.dashboard.contributions.list';
    const key = contribution.typeContribution === 'REDIRECTION' ? 'redirected' : 'dateEnvoi';
    return this.$translate.instant(`${path}.${key}`);
  }

  /**
   * Show Trash button to delete "Contribution pour avis" if not closed or in progress
   * @param {object} contribution
   * @return {boolean}
   */
  showBtnDeleteContributionAvis(contribution) {
    const contributionNotClose = !this.isClosed(contribution) && contribution.statut === 'INPROGRESS';
    return this.isTypeAvis(contribution) && contributionNotClose;
  }

  /**
   * Show Answer button for "Contribution pour avis"
   * @param {object} contribution
   * @return {boolean}
   */
  showBtnAnswerContributionAvis(contribution) {
    const contributionNotClose = !this.isClosed(contribution) && contribution.statut === 'ASKED';
    return this.isTypeAvis(contribution) && contributionNotClose;
  }

  /**
   * Check if there is a comiteRecevabilite with VALIDATED status for this contribution
   * @param {object} contribution
   * @return {boolean}
   */
  contributionHasDemandeWithComiteStatusValide(contribution) {
    const comitesRecevabilite = _.get(contribution, 'demande.comiteRecevabilite');
    return comitesRecevabilite && comitesRecevabilite.statut === 'VALIDATED';
  }

  /**
   * Show create contribution button if contribution type is AVIS_FINANCEMENT
   * @param {object} contribution
   * @return {boolean}
   */
  showBtnCreateContributionAvisFinancement(contribution) {
    const contributionClosed = contribution.statut === 'ANSWERED';
    return contribution.typeContribution === 'AVIS_FINANCEMENT' && contributionClosed;
  }

  /**
   * Show Recap button to navigate to "Demande de financement" if "Contribution pour avis" if not closed or in progress
   * @param {object} contribution
   * @return {boolean}
   */
  showBtnRecapitulatifDemandeForContributionAvis(contribution) {
    const statuses = ['ASKED', 'INPROGRESS'];
    const contributionNotClose = !this.isClosed(contribution) && statuses.includes(contribution.statut);
    return (
      (contribution.typeContribution === 'AVIS' && contributionNotClose) ||
      contribution.typeContribution === 'AVIS_FINANCEMENT'
    );
  }

  /**
   * Check if it's possible to do action on the contribution
   * @param {object} contribution
   * @return {boolean}
   */
  showControls(contribution) {
    let visible = false;

    const contributionNotClosed =
      !this.isClosed(contribution) && ['ANSWERED', 'SUPPORTED'].includes(contribution.statut);

    const isTreated = this.isClosed(contribution) || contributionNotClosed;
    if (isTreated) {
      visible = !(this.isClosed(contribution) && ['ASKED', 'INPROGRESS'].includes(contribution.statut));
    }

    return visible;
  }

  /**
   * Show contribution recap button
   * @param {object} contribution
   * @return {boolean}
   */
  showBtnRecapitulatifContribution(contribution) {
    if (['MODIFICATION', 'REDIRECTION'].includes(contribution.typeContribution)) {
      return ['ANSWERED', 'SUPPORTED', 'WAITING_FOR_CERTIFICATE'].includes(contribution.statut);
    } else {
      return this.showControls(contribution);
    }
  }

  /**
   * Show contribution avis label
   * @param {object} contribution
   * @return {boolean}
   */
  showLabelAvisContribution(contribution) {
    return this.isTypeAvis(contribution) ? this.showControls(contribution) : false;
  }

  /**
   * Get date comite of the contribution
   * @param {object} contribution
   * @return {string}
   */
  getDateComite(contribution) {
    return _.get(contribution, 'demande.comiteRecevabilite.dateDeComite');
  }

  /**
   * Check if answer label should be displayed
   * @param {object} contribution
   * @return {boolean}
   */
  showLabelAnswer(contribution) {
    return ['ANSWERED', 'SUPPORTED'].includes(contribution.statut);
  }

  /**
   * Show delete contribution modal
   * @param {object} contribution
   * @return {boolean}
   */
  deleteContribution(contribution) {
    const scopeModal = this.$scope.$new();
    scopeModal.contribution = contribution;

    this.$modal({
      scope: scopeModal,
      template: 'contributions/contributions-components/contributions-liste/modal/contributions-modal-delete.html',
    });
  }

  /**
   * Delete contribution
   * @param {object} modal
   * @param {object} contribution
   * @return {void}
   */
  confirmDeleteContribution(modal, contribution) {
    modal.$hide();

    this.contributionsService
      .removeAnswer(contribution)
      .then(() => {
        // Refresh the status of the modification contribution in order to display proper button
        this.contributionsService.get(contribution.reference).then((response) => {
          const contrib = _.find(this.contributions, {
            reference: contribution.reference,
          });
          contrib.statut = _.get(response, 'statut');
          contrib.avis = _.get(response, 'avis');
          contrib.history = _.get(response, 'history');

          const successMessage = this.$translate.instant('connected.dashboard.contributions.list.delete.success');
          this.$scope.$broadcast('alerts', this.alertsService.getAlertSuccess(successMessage));
        });
      })
      .catch((error) => {
        this.$scope.$broadcast('alerts', this.alertsService.getAlertError(`Une erreur est survenue. ${error.data}`));
      });
  }

  /**
   * Download recapitulatif
   * @param {object} contribution
   * @return {void}
   */
  downloadRecapitulatif(contribution) {
    const recapitulatif = _.findLast(contribution.pieces, (piece) => {
      return contribution.statut === 'WAITING_FOR_CERTIFICATE'
        ? piece.reference === 'recapitulatif_initiale'
        : piece.reference === 'recapitulatif_modifie';
    });

    if (recapitulatif) {
      const documentName = `${recapitulatif.libelle.value}.pdf`;
      this.downloadDocument(recapitulatif.documents[0], documentName);
    }
  }

  /**
   * Download document
   * @param {object} document
   * @param {string} documentName
   * @return {void}
   */
  downloadDocument(document, documentName) {
    const urlMetadata = document.href;
    const url = document.href.replace('&cmisselector=object', '').replace('?cmisselector=object', '');

    this.$http
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then((reponse) => {
        const blob = new Blob([reponse.data], {
          type: 'application/pdf',
        });
        this.$http
          .get(urlMetadata)
          .then((reponse) => {
            const originalfilename = reponse.data.properties['entity:document:originalfilename'].value;
            saveAs(blob, originalfilename || documentName);
          })
          .catch((err) => {
            this.$log.error(err);
            saveAs(blob, documentName);
          });
      });
  }

  /**
   * Answer to the contribution
   * @param {object} contribution
   * @return {void}
   */
  answerContribution(contribution) {
    const teleserviceReference = this.getTeleserviceReferenceFromContribution(contribution);
    if (teleserviceReference) {
      this.$state.go('app.connected.config.depot.contributions', {
        configurationId: teleserviceReference, //teleservice
        contributionRef: contribution.reference,
      });
    }
  }

  /**
   * Go to contribution screen
   * @param {object} contribution
   * @return {void}
   */
  goToContribution(contribution) {
    if (this.isTypeAvis(contribution)) {
      const teleserviceReference = this.getTeleserviceReferenceFromContribution(contribution);
      this.$state.go('app.connected.config.depot.contributions', {
        configurationId: teleserviceReference, //teleservice
        contributionRef: contribution.reference,
      });
    } else if (contribution.typeContribution === 'MODIFICATION') {
      const teleserviceReference = _.get(contribution, 'demande.teleservice.reference');
      this.$state.go('app.connected.config.depot.contributionModification', {
        configurationId: teleserviceReference, //teleservice
        p: contribution.demande.reference, //demande
        c: contribution.reference, //contribution
      });
    } else if (contribution.typeContribution === 'REDIRECTION') {
      const teleserviceReference = contribution.teleservice.reference;
      this.$state.go('app.connected.config.depot.contributionRedirection', {
        configurationId: teleserviceReference, //teleservice
        p: contribution.demande.reference, //demande
        c: contribution.reference, //contribution
      });
    }
  }

  /**
   * Create "contribution pour avis financement"
   * @param {object} contribution
   * @return {void}
   */
  createContributionAvisFinancement(contribution) {
    this.contributionsService.createContributionAvisFinancement(contribution).then((newContribution) => {
      this.answerContribution(newContribution);
    });
  }

  /**
   * Get the reference of the contribution either from the reference property or extracted from the href
   * @param {object} contribution
   * @returns {string} the reference of the teleservice
   */
  getTeleserviceReferenceFromContribution(contribution) {
    return contribution.teleservice.reference || this.urlService.extractReferenceFromId(contribution.teleservice.href);
  }
}

ContributionsListeController.$inject = [
  '$scope',
  '$modal',
  '$state',
  '$http',
  'contributionsService',
  'alertsService',
  '$log',
  '$translate',
  'urlService',
];

angular.module('contributions.components').component('contributionsListe', {
  templateUrl: 'contributions/contributions-components/contributions-liste/contributions-liste.html',
  controller: ContributionsListeController,
  bindings: {
    contributions: '<',
  },
});
