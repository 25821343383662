angular.module('tiers').controller('rattachementUserTiersController', [
  '$scope',
  '$q',
  'Tiers',
  'teleservicesService',
  'tiersService',
  'tiersRattachementService',
  'piecesService',
  'alertsService',
  'adresseService',
  'accountManagementService',
  function(
    $scope,
    $q,
    Tiers,
    teleservicesService,
    tiersService,
    tiersRattachementService,
    piecesService,
    alertsService,
    adresseService,
    accountManagementService
  ) {
    'use strict';

    $scope.dateNaissancePickerConfig = {
      min: '01/01/1900',
      max: new Date(),
      format: 'd',
      start: 'month',
      depth: 'month',
    };

    // Tiers
    $scope.tiersRattachement = new Tiers();

    // Indicates if the tiers to which the attachment is sent is managed by the region or by an external administrator
    let regionTiers = false;

    // Id of the configuration
    $scope.configurationId = 'rattachement';

    // Status for known if we have send the attach's ask
    $scope.attempted = false;

    // Get all tiers linked to current user and keep tiers physiques
    tiersService.getAllCurrentUserTiers(true).then((allTiers) => {
      $scope.tiersMoraux = allTiers.filter((tiers) => tiers.famille.expand.personnaliteJuridique === 'MORALE');
      $scope.tiersPhysiques = allTiers.filter((tiers) => tiers.famille.expand.personnaliteJuridique === 'PHYSIQUE');
    });

    // Initialize the view
    $scope.persistence = {};
    $q.all([
      tiersRattachementService.getRattachementsConfig(),
      teleservicesService.getTeleService($scope.configurationId),
    ]).then(([config, teleservice]) => {
      // Configuration
      $scope.configuration = config;
      const pieces = _.get(teleservice, 'workflow.rattachement.PageRattachement.modelesPieces');

      // Persistence
      $scope.persistence.content = $scope.persistence.content || {};
      $scope.persistence.content.pieces = piecesService.initializePiecesPersistence(
        pieces,
        $scope.persistence.content.pieces
      );
      $scope.piece = $scope.persistence.content.pieces[0];

      // Url for upload documents
      $scope.urlDocuments = tiersRattachementService.getDocumentUploadUrl();

      // Url file icons
      $scope.urlFileIcons = './resources/images/file-icons/';
    });

    /**
     * Change the tiers family and define if the tiers is MORALE or PHYSIQUE
     */
    $scope.$watch(
      'tiersRattachement.famille.expand.personnaliteJuridique',
      (personnaliteJuridique, oldPersonnaliteJuridique) => {
        $scope.$broadcast('alerts', [], 'forbidden-tiers-physique');
        $scope.rattachementTiersPhysiqueAllowed = true;
        if (personnaliteJuridique && personnaliteJuridique !== oldPersonnaliteJuridique) {
          tiersService.changeFamilleTiers($scope.tiersRattachement, $scope.user, $scope.mdm, $scope.masterdata);
          accountManagementService.getPublicSettings().then(function(publicSettings) {
            // check if provider user exist in list oidcProviders
            // if exist set readOnly value in the scope for birthdate and birthplace
            const userProviderId = _.get($scope, 'user.providerId');
            const oidcProviders = _.get(publicSettings, 'data.oidcProviders', []);
            const provider = oidcProviders.filter((oidcProvider) => oidcProvider.providerId === userProviderId);
            if (provider && provider.length > 0 && provider[0].isTrusted) {
              $scope.isBirthplaceReadOnly = !_.isEmpty($scope.user.birthplaceName);
              $scope.isBirthdateReadOnly = !_.isEmpty($scope.user.birthdate);
            }
          });

          // Init birthdate and birthplace if 'PHYSIQUE'
          if (personnaliteJuridique === 'PHYSIQUE') {
            if ($scope.tiersPhysiques.length > 0) {
              $scope.$broadcast(
                'alerts',
                alertsService.getAlertWarning($scope.viewConfiguration.ns + '.error.forbiddenTiersPhysique'),
                'forbidden-tiers-physique'
              );
              $scope.rattachementTiersPhysiqueAllowed = false;
            } else {
              if ($scope.user.birthdate) {
                const dn = moment($scope.user.birthdate);
                $scope.tiersRattachement.individu.Naissance.DateNaissance = new Date($scope.user.birthdate);
                $scope.tiersRattachement.individu.Naissance.DateNaissanceDisplay = dn.format('DD/MM/YYYY');
              }
              if ($scope.user.birthplaceName) {
                $scope.tiersRattachement.individu.Naissance.LieuNaissance.Localite.value = $scope.user.birthplaceName;
              } else if ($scope.user.birthplace) {
                adresseService.searchCodeInseeVille($scope.user.birthplace).then((ville) => {
                  $scope.tiersRattachement.individu.Naissance.LieuNaissance.Localite.value = ville;
                });
              }
            }
          }
        }
      }
    );

    /**
     * Création d'une demande de rattachement
     * @param  {[type]} user        Utilisateur connecté
     * @param  {[type]} individu    Individu
     * @param  {[type]} coordonnees Coordonnées
     * @param  {[type]} siret       Siret
     * @param  {[type]} pieces      Pièces
     * @param  {[type]} familleTiers    Famille de tiers
     */
    function demandeRattachement(user, individu, coordonnees, siret, pieces, familleTiers) {
      // Url for accept or reject the rattachement
      const actionUrl = tiersRattachementService.getUrlConfirmationRattachement();

      $scope.attempted = false;
      $scope.alerts = [];

      // Creation of rattachement
      tiersRattachementService
        .addDemandeRattachement(user, individu, coordonnees, siret, pieces, actionUrl, familleTiers)
        .then(() => {
          const destination = regionTiers ? 'region' : 'admin';
          $scope.alerts = alertsService.getAlertSuccess(
            `${$scope.viewConfiguration.ns}.success.rattachement-${destination}`
          );
          $scope.tiersRattachement = new Tiers();
          $scope.formAttach.$setPristine();
          $scope.attempted = true;
        })
        .catch((err) => {
          if (err.status === 403) {
            $scope.attempted = true;
            $scope.alerts = alertsService.getAlertError($scope.viewConfiguration.ns + '.error.forbidden');
          }

          if (err.status === 406) {
            $scope.attempted = true;
            $scope.alerts = alertsService.getAlertError($scope.viewConfiguration.ns + '.error.alreadySend');
          }

          if ($scope.attempted === false) {
            $scope.alerts = alertsService.getAlertError($scope.viewConfiguration.ns + '.error.generic');
          }
        });
    }

    /**
     * Creation of rattachement for a tiers morale
     * @param  {User} 	user  User
     * @param  {Siret} 	siret Siret
     */
    function demandeRattachementTiersMorale(user, siret, pieces, familleTiers) {
      tiersService.controlerUniciteSIRET(siret.SIREN, siret.NIC).then((uniquenessSiret) => {
        if (uniquenessSiret.length > 0) {
          demandeRattachement(user, null, null, siret, pieces, familleTiers);
        } else {
          $scope.alerts = alertsService.getAlertError($scope.viewConfiguration.ns + '.error.tiersNotFound');
        }
      });
    }

    /**
     * Création d'une demande de rattachement pour un tiers physique
     * @param  {[type]} user        Utilisateur connecté
     * @param  {[type]} individu    Individu
     * @param  {[type]} coordonnees Coordonnées
     * @param  {[type]} pieces      Pièces
     * @param  {[type]} familleTiers    Famille de tiers
     */
    function demandeRattachementTiersPhysique(user, individu, coordonnees, pieces, familleTiers) {
      regionTiers = true;
      demandeRattachement(user, individu, coordonnees, null, pieces, familleTiers);
    }

    /**
     * Creation of rattachement.
     */
    $scope.demandeRattachement = function() {
      const user = $scope.user.getCleanEntity();
      const tiers = $scope.tiersRattachement.getCleanEntity();
      const pieces = [$scope.piece];
      const familleTiers = $scope.tiersRattachement.famille.expand.reference;

      if (tiers && tiers.SIRET) {
        demandeRattachementTiersMorale(user, tiers.SIRET, pieces, familleTiers);
      } else {
        demandeRattachementTiersPhysique(user, tiers.individu, tiers.situations[0], pieces, familleTiers);
      }
    };

    $scope.checkSiretUnicity = function() {
      // Check the SIRET validity only if not a physic person
      if (_.get($scope, 'tiersRattachement.famille.expand.personnaliteJuridique') === 'PHYSIQUE') {
        return;
      }

      // Reinitializing errors
      $scope.errorTiersAlreadyLinked = false;
      if ($scope.formAttach['SIRET-NIC']) {
        $scope.formAttach['SIRET-NIC'].$setValidity('unicite', true);
      }

      // Making the test only when the SIRET is complete
      const famille = _.get($scope, 'tiersRattachement.famille.href');
      const siren = _.get($scope, 'tiersRattachement.SIRET.SIREN');
      const nic = _.get($scope, 'tiersRattachement.SIRET.NIC');

      if (famille && siren && nic) {
        tiersService.controlerUniciteSIRET(siren, nic, famille).then((existingTiers) => {
          if (existingTiers.length > 0) {
            $scope.formAttach['SIRET-NIC'].$setValidity('unicite', true);

            // If the structure has an administrator account, pieces are optional
            regionTiers = !_.find(existingTiers, 'accountAdministrator');
            $scope.piece.obligatoire = regionTiers;

            // Search tiers in user's tiers to find if already attached
            const tiersAlreadyLinked = $scope.tiersMoraux.filter((tiers) => {
              return tiers.SIRET.SIREN === siren && tiers.SIRET.NIC === nic;
            });
            if (tiersAlreadyLinked.length > 0) {
              // If it is, display an error and block validation
              $scope.errorTiersAlreadyLinked = true;
            }
          } else if ($scope.formAttach['SIRET-NIC']) {
            $scope.formAttach['SIRET-NIC'].$setValidity('unicite', false);
          }
        });
      }
    };
  },
]);
