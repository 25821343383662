'use strict';
angular.module('user.model', ['form.services']).factory('User', [
  function () {
    /**
     * A SCIM like user as returned by the account-management service and expected by entities such as tiers
     * @param {Object} data [description]
     * @class User
     * @classdesc SCIM like user
     */
    function User(data) {
      data = data || {};

      // we pick only data that we want, omitting extra informations added by account-management
      this.id = data.id;
      this.userName = data.userName;
      this.displayName =
        data.displayName || _.trim(_.get(data, 'name.givenName', '') + ' ' + _.get(data, 'name.familyName', ''));
      this.emails = data.emails || [];
      this.phoneNumbers = data.phoneNumbers || [];
      this.groups = data.groups;
      this.name = {};

      if (_.get(data, 'name.honorificPrefix')) {
        this.name.honorificPrefix = data.name.honorificPrefix;
      }

      this.name.givenName = _.get(data, 'name.givenName');
      this.name.familyName = _.get(data, 'name.familyName');

      // With france connect, it's possible to get birthdate and birthplace (with code INSEE)
      this.birthplace = data.birthplace;
      this.birthdate = data.birthdate;

      // For OIdC users
      this.birthplaceName = data.birthplaceName;
      this.birthcountryName = data.birthcountryName;

      this.costCenter = data.costCenter; // direction
      this.division = data.division; // service
      this.divisionLabel = data.divisionLabel;
      this.department = data.department; // pôle
      this.departmentLabel = data.departmentLabel;
      this.organizationUnit = data.organizationUnit; // organization
      this.organizationUnitLabel = data.organizationUnitLabel;
      this.businessUnit = data.businessUnit; // direction
      this.businessUnitLabel = data.businessUnitLabel;
      this.title = data.title; // fonction
      this.franceConnect = data.franceConnect;
      this.self = data.self;
      this.dateAcceptationCGU = data.dateAcceptationCGU;
      this.agreements = data.agreements;
      this.groupsGestion = data.groupsGestion;
      this.providerId = data.providerId || _.get(data, 'meta.providerId'); // Depending on where the data comes from
      this.sub = data.sub;

      // account security data
      this.locked = data.locked; // is the account locked
      this.failTime = data.failTime; // last failled authentication attempts
      this.attempts = data.attempts; // number of successive failled attempts
    }

    /**
     * This function returns the user in a form compatible with Tiers services and others
     * If serialization problems are fixed in these services the need for this function should disappear.
     */
    User.prototype.getCleanEntity = function () {
      var user = _.cloneDeep(this);

      // Emails
      if (user.emails && user.emails.length > 0) {
        user.emails = _.map(user.emails, function (email, i) {
          if (typeof email === 'string') {
            return {
              value: email,
              primary: i === 0,
              type: 'work',
            };
          } else if (typeof email === 'object') {
            return email;
          } else {
            return '';
          }
        });
      } else {
        delete user.emails;
      }

      // Phone Numbers
      if (user.phoneNumbers && user.phoneNumbers.length > 0) {
        user.phoneNumbers = _.map(user.phoneNumbers, function (phoneNumber, i) {
          if (typeof phoneNumber === 'string') {
            return {
              value: phoneNumber,
              primary: i === 0,
              type: 'work',
              display: phoneNumber,
            };
          } else if (typeof phoneNumber === 'object') {
            return phoneNumber;
          } else {
            return '';
          }
        });
      } else {
        delete user.phoneNumbers;
      }

      // Groups
      if (user.groups && user.groups.length > 0) {
        user.groups = _.map(user.groups, function (group) {
          if (typeof group === 'string') {
            return {
              display: group,
              value: group,
            };
          } else if (typeof group === 'object') {
            return group;
          } else {
            return '';
          }
        });
      } else {
        delete user.groups;
      }

      // Registering only the honorific prefix label
      user.name = user.name || {};
      user.name.honorificPrefix = _.get(user, 'name.honorificPrefix.libelle') || _.get(user, 'name.honorificPrefix');

      return user;
    };

    return User;
  },
]);
