class ContributionsUnreadController {
  constructor($state) {
    this.$state = $state;
  }

  /**
   * Go to the screen associated to the contribution
   * @param {object} contribution
   * @return {void}
   */
  goToContribution(contribution) {
    this.closePopover();

    if (contribution.typeContribution === 'MODIFICATION') {
      const teleservice = _.get(contribution, 'demande.teleservice.reference');
      this.$state.go(
        'app.connected.config.depot.contributionModification',
        {
          configurationId: teleservice, //teleservice
          p: contribution.demande.reference, //demande
          c: contribution.reference, //contribution
        },
        {
          reload: true,
        }
      );
    } else if (contribution.typeContribution === 'AVIS' || contribution.typeContribution === 'AVIS_FINANCEMENT') {
      this.$state.go('app.connected.dashboard.recapitulatif', {
        aide: contribution.demande.reference,
        fromContribution: contribution.reference, //contribution
      });
    } else if (contribution.typeContribution === 'REDIRECTION') {
      const teleserviceReference = contribution.teleservice.reference;
      this.$state.go(
        'app.connected.config.depot.contributionRedirection',
        {
          configurationId: teleserviceReference, //teleservice
          p: contribution.demande.reference, //demande
          c: contribution.reference, //contribution
        },
        {
          reload: true,
        }
      );
    }
  }
}

ContributionsUnreadController.$inject = ['$state'];

angular.module('contributions.components').component('contributionsUnread', {
  templateUrl: 'contributions/contributions-components/contributions-unread/contributions-unread.html',
  controller: ContributionsUnreadController,
  bindings: {
    contributions: '<',
    closePopover: '&',
  },
});
