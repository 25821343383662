angular.module('common.helpers').factory('IFrameCommunicationManager', [
  '$rootScope',
  '$q',
  function($rootScope, $q) {
    'use strict';

    function IFrameCommunicationManager(iframeName) {
      this.listeners = [];
      this.iframeName = iframeName;
    }

    IFrameCommunicationManager.prototype.sendEvent = function(data) {
      const iFrame = angular.element(this.iframeName);
      if (iFrame && iFrame.length > 0) {
        iFrame[0].contentWindow.postMessage(data, '*');
        return this;
      }
      throw new Error(`${this.iframeName} does iFrame not exist.`);
    };

    IFrameCommunicationManager.prototype.manageEvent = function(callBack) {
      const listener = (msg) => {
        $rootScope.$apply(callBack(msg));
      };
      window.addEventListener('message', listener);
      this.listeners.push(listener);
    };

    IFrameCommunicationManager.prototype.manageEventWithPromise = function(callBack) {
      return $q((resolve, reject) => {
        const listener = (msg) => {
          $rootScope.$apply(callBack(msg, resolve, reject));
        };
        this.listeners.push(listener);
        window.addEventListener('message', listener);
        this.listeners.push(listener);
      });
    };

    IFrameCommunicationManager.prototype.close = function() {
      this.listeners.forEach((listener) => {
        window.removeEventListener('message', listener);
      });
    };

    return IFrameCommunicationManager;
  },
]);
