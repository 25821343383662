'use strict';
/**
 * @ngdoc directive
 * @area api
 * @module user.directives
 * @name user-management
 * @restrict E
 * @description
 *
 * this directive is used to integrate a small user management widget, usually in the header of a portal
 *
 * @param {string} account-href - Link to ther user's account management page
 * @param {boolean} jwtSessionOnly - Use JWT session service
 * @param {string} tooltip - display tooltips for name and email at the given position (left, right, top, or bottom)
 *
 */
angular.module('user.directives').directive('userManagement', [
  '$rootScope',
  '$popover',
  '$modal',
  '$q',
  '$log',
  '$translate',
  'USER_EVENTS',
  'AUTHENTICATION_EVENTS',
  'authenticationService',
  'userSessionService',
  'jwtSessionService',
  'accountManagementService',
  function (
    $rootScope,
    $popover,
    $modal,
    $q,
    $log,
    $translate,
    USER_EVENTS,
    AUTHENTICATION_EVENTS,
    authenticationService,
    userSessionService,
    jwtSessionService,
    accountManagementService
  ) {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      templateUrl: 'user/user-directives/user-management/user-management.html',
      scope: {
        portalId: '@',
        accountHref: '@',
        user: '=?',
        jwtSessionOnly: '=?',
        tooltip: '@',
        placement: '@',
      },
      link: function (scope, element) {
        // Tooltip
        scope.tooltipEnabled = !!scope.tooltip;

        if (scope.tooltipEnabled) {
          scope.tooltipPosition = _.includes(['top', 'right', 'bottom', 'left'], scope.tooltip)
            ? scope.tooltip
            : 'left';
        }

        // Compatibility management. userSessionService is deprecated.
        if (scope.jwtSessionOnly) {
          authenticationService = jwtSessionService;
          scope.user = scope.user || jwtSessionService.user();
        } else {
          scope.user = userSessionService.getUser();

          scope.$on(AUTHENTICATION_EVENTS.loginSuccess, function () {
            scope.user = userSessionService.getUser();
          });

          scope.$on(AUTHENTICATION_EVENTS.logoutSuccess, function () {
            scope.user = null;
          });

          scope.$on(AUTHENTICATION_EVENTS.notAuthorized, function () {
            scope.user = null;
          });

          scope.$on(USER_EVENTS.userUpdated, function () {
            scope.user = userSessionService.getUser();
          });
        }

        // Use popover by default, modal if on mobile
        var userManagementModal;
        if (!bowser.mobile) {
          var popoverButton = angular.element(element.find('#userManagementBtnPopover'));
          userManagementModal = $popover(popoverButton, {
            placement: scope.placement || 'bottom',
            template: 'user/user-directives/user-management/user-management-popover.html',
            autoClose: true,
            scope: scope,
            trigger: 'manual',
          });
        } else {
          userManagementModal = $modal({
            scope: scope,
            template: 'user/user-directives/user-management/user-management-modal.html',
            show: false,
          });
        }

        scope.showUserManagement = function () {
          // Popover and modal share the same function's name for showing
          userManagementModal.$scope.$show();
        };

        // Do not break compatibility (earlier versions used givenName + familyName)
        // But allow to display only displayName if it is the only available information
        // this is the case when user is fetched from JWT only
        scope.$watch(
          'user',
          function () {
            if (scope.user && scope.user.name && scope.user.name.givenName && scope.user.name.familyName) {
              scope.displayUser = scope.user.name.givenName + ' ' + scope.user.name.familyName;
            } else if (scope.user && scope.user.displayName) {
              scope.displayUser = scope.user.displayName;
            } else {
              scope.displayUser = '';
            }

            if (scope.user) {
              if (scope.user.franceConnect) {
                scope.providerInfos = {
                  title: 'France Connect',
                  logo: 'https://app.franceconnect.gouv.fr/images/fc-avatar.png',
                  providerSourceLabel: $translate.instant('user.user-management.providerSourceLabel', {
                    providerTitle: 'France Connect',
                  }),
                };
              } else if (jwtSessionService && _.get(jwtSessionService.user(), 'providerId', false)) {
                // We make sure the current account is NOT a paired one.
                // When these accounts are logged in from internal authentication, there is no providerId in the jwt
                scope
                  .fetchProviderInfos()
                  .then(function (oidcProviderInfos) {
                    var linkTitle;
                    if (oidcProviderInfos.homePage) {
                      linkTitle =
                        '<a href="' +
                        oidcProviderInfos.homePage +
                        '" target="_blank">' +
                        oidcProviderInfos.title +
                        '</a>';
                    } else {
                      linkTitle = oidcProviderInfos.title;
                    }
                    scope.providerInfos = {
                      title: oidcProviderInfos.title,
                      logo: oidcProviderInfos.logoUser,
                      providerSourceLabel: $translate.instant('user.user-management.providerSourceLabel', {
                        providerTitle: linkTitle,
                      }),
                    };
                  })
                  .catch(function (error) {
                    $log.error(error);
                  });
              }
            }
          },
          true
        );

        /**
         * Disconnect of the application
         */
        scope.disconnect = function () {
          authenticationService.logout();
          $rootScope.$broadcast(AUTHENTICATION_EVENTS.logoutSuccess);
        };

        /**
         * load to account information for mobile device
         */
        scope.account = function () {
          if (bowser.mobile) {
            window.location = scope.accountHref;
            window.location.reload();
          }
          userManagementModal.$scope.$hide();
        };

        /**
         * Hide the popover
         */
        scope.hidePopover = function () {
          scope.$apply(function () {
            var popover = element.find('.popover');
            if (popover && popover.data() && popover.data().$scope) {
              popover.data().$scope.$hide();
            }
          });
        };

        scope.fetchProviderInfos = function () {
          return accountManagementService
            .getPublicSettings()
            .then(function (response) {
              return response.data;
            })
            .then(function (publicSettings) {
              var userProviderId = scope.user.providerId;
              var oidcProvider = _.find(publicSettings.oidcProviders, function (oidcProvider) {
                return oidcProvider.providerId === userProviderId;
              });
              if (!oidcProvider)
                return $q.reject('oidc provider with id :' + userProviderId + ' not found in the public settings');
              return oidcProvider;
            });
        };

        // Hide popover when a user click outside of it.
        angular.element('body').on('click', function (e) {
          var userPopover = element.find('.user-popover');
          if (
            !userPopover.is(e.target) &&
            userPopover.has(e.target).length === 0 &&
            $('.popover').has(e.target).length === 0
          ) {
            scope.hidePopover();
          }
        });
      },
    };
  },
]);
