angular.module('aides').component('realisationEvaluationAide', {
  templateUrl: 'aides/aides-components/realisation-evaluation-aide/realisation-evaluation-aide.component.html',
  bindings: {
    teleserviceOptions: '<',
    aide: '<',
    name: '<',
    configuration: '<',
  },
  require: {
    form: '^^form',
  },
  controller: /* @ngInject */ realisationEvaluationAide,
});

function realisationEvaluationAide($translate) {
  var ctrl = this;

  ctrl.$onInit = function() {
    ctrl.dateRealisationField = _.get(ctrl, 'configuration.fields.dateRealisation');
    ctrl.evaluationField = _.get(ctrl, 'configuration.fields.evaluation', {});
    ctrl.dateFinSuperieurDateDebut = true;
    ctrl.noDateDebut = false;

    if (_.get(ctrl, 'aide.periodeRealisation')) {
      ctrl.dateRealisationFin = moment(_.get(ctrl, 'aide.periodeRealisation.dateFin')).format('DD/MM/YYYY');
      ctrl.dateRealisationDebut = moment(_.get(ctrl, 'aide.periodeRealisation.dateDebut')).format('DD/MM/YYYY');
      ctrl.isDateFinSuperieurDateDebut();
    } else if (_.get(ctrl, 'aide.dateRealisation')) {
      ctrl.dateRealisationDebut = moment(_.get(ctrl, 'aide.dateRealisation')).format('DD/MM/YYYY');
    }

    ctrl.configuration.fields.evaluations = {
      label: ctrl.evaluationField.label,
      required: ctrl.evaluationField.required,
      hidden: ctrl.evaluationField.hidden,
      maxlength: 32500,
      displayCharactersLeft: true,
      help: $translate.instant('realisationEtEvaluation.rgpd'),
      helpTooltip: true,
      error: {
        required: '',
      },
    };

    if (_.get(ctrl.evaluationField, 'error.required')) {
      ctrl.configuration.fields.evaluations.error.required = ctrl.evaluationField.error.required;
    }
    if (_.get(ctrl.evaluationField, 'help')) {
      ctrl.configuration.fields.evaluations.details = ctrl.evaluationField.help;
    }
  };

  ctrl.setDates = function() {
    ctrl.dateFinSuperieurDateDebut = true;
    ctrl.noDateDebut = false;

    if (ctrl.dateRealisationDebut && !ctrl.dateRealisationFin) {
      _.set(ctrl, 'aide.dateRealisation', moment(ctrl.dateRealisationDebut, 'DD/MM/YYYY').toISOString());
      _.unset(ctrl, 'aide.periodeRealisation');
    } else if (ctrl.dateRealisationFin) {
      ctrl.isDateFinSuperieurDateDebut();
      if (!ctrl.noDateDebut && ctrl.dateFinSuperieurDateDebut) {
        _.set(ctrl, 'aide.periodeRealisation.dateDebut', moment(ctrl.dateRealisationDebut, 'DD/MM/YYYY').toISOString());
        _.set(ctrl, 'aide.periodeRealisation.dateFin', moment(ctrl.dateRealisationFin, 'DD/MM/YYYY').toISOString());
        _.unset(ctrl, 'aide.dateRealisation');
      }
    }
  };

  ctrl.isDateFinSuperieurDateDebut = function() {
    if (!ctrl.dateRealisationDebut) {
      ctrl.noDateDebut = true;
      return;
    }
    var dateDebut = moment(ctrl.dateRealisationDebut, 'DD/MM/YYYY');
    var dateFin = moment(ctrl.dateRealisationFin, 'DD/MM/YYYY');
    ctrl.dateFinSuperieurDateDebut = dateDebut.isBefore(dateFin, 'day');
    if (ctrl.form.dateDemarrage) {
      ctrl.form.dateDemarrage.$setValidity('dateFinSuperieurDateDebut', ctrl.dateFinSuperieurDateDebut);
    }
  };

  ctrl.dateRealisationHasError = function() {
    return (
      (ctrl.form.dateDemarrage.$invalid && (ctrl.form.dateDemarrage.$touched || ctrl.form.$submitted)) ||
      (ctrl.form.dateFin.$invalid && (ctrl.form.dateFin.$touched || ctrl.form.$submitted)) ||
      !ctrl.dateFinSuperieurDateDebut ||
      ctrl.noDateDebut
    );
  };
}
