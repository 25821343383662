/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name text-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A basic text field for dynamic forms.
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 *
 * @example
 *
 *   `<text-field name="dateNaissance" view-configuration="identificationDemandeurConfiguration" data="demandeur"></text-field>`
 *
 */
angular.module('form.directives').directive('textField', ['$timeout', 'formUtils',
  function ($timeout, formUtils) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/text-field/text-field.html',
      scope: {
        name: '@',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=',
        format: '@',
        bare: '=',
        displayCharactersLeft: '=?',
        remoteValidation: '=?',
        readOnly: '=',
        onChange: '&?',
        labelClass: '=?'
      },
      link: function (scope, element) {
        scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
        scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');
        scope.format = scope.format || scope.configuration.format;

        scope.configuration.minlength = _.get(scope.configuration, 'restrictions[0].minLength', scope.configuration.minlength || 0);
        scope.configuration.maxlength = _.get(scope.configuration, 'restrictions[0].maxLength', scope.configuration.maxlength || 100);

        scope.charLeft = {
          display: false
        };

        var inputElement = null;

        function updateCharactersLeft() {
          scope.$apply(function () {
            scope.charLeft.value = scope.configuration.maxlength - _.get(inputElement,'0.value.length', 0);
          });
        }
        
        // Read option from directive, configuration or viewConfiguration
        function readGlobalOption(optionName) {
          scope[optionName] = scope[optionName] !== undefined ? scope[optionName] :
            scope.configuration[optionName] !== undefined ? scope.configuration[optionName] :
              _.get(scope, 'viewConfiguration.' + optionName) !== undefined ? _.get(scope, 'viewConfiguration.' + optionName) : false;
        }
        readGlobalOption('displayCharactersLeft');
        readGlobalOption('remoteValidation');

        // Characters left update
        if (scope.displayCharactersLeft) {
          // Wait for validation-field to render full HTML
          $timeout(function() {
            inputElement = element.find('input');
            // Bind to HTML events
            inputElement.on('input', updateCharactersLeft);
            scope.$on('destroy', function(){
              inputElement.off('input', updateCharactersLeft);
            });
          }, 0);
        }

        // Put model into a 'data' object
        formUtils.wrapPrimitiveInObject(scope, 'model', 'data');

        //Remove know characters \u2018\u2019 by '
        //Remove special characters (only accepts ISO 8859-15 characters)
        scope.$watch('model', function (newValue) {
          if (newValue) {
            var valueISO885915 = newValue.replace(/[\u2018\u2019] /g, '\'');
            valueISO885915 = valueISO885915.replace(/[^\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178] /g, ' ');
            scope.model = valueISO885915;
          }
          if (scope.displayCharactersLeft) {
            $timeout(function () {
              updateCharactersLeft();
            });
          }
        });

        // Callback on changes
        scope.change = function () {
          if (scope.onChange) {
            // Must wait scope to update
            $timeout(scope.onChange, 0);
          }
        };

        // Added a timeout to undisplay character left message.
        // This timeout must give to the user time to click on a form button at the bottom of the page.
        // Without this timeout, when the message disappears, the button pull up in the form and the click on the button is not always effective.
        scope.waitUndisplayCharacterLeft = function(){
          $timeout(function () {
            scope.charLeft.display = false;
          }, 100);
        };
      }
    };
  }
]);
