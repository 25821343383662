(function () {
  'use strict';
  // interact with the account-management service developped at mgdis
  // see http://gitserv/gitweb/?p=mgdis/backend/account-management.git;a=summary

  angular
    .module('user.services.account-management-service', ['user.model', 'configuration'])
    .factory('accountManagementService', accountManagementService);

  accountManagementService.$inject = ['$http', '$q', 'configuration', 'User'];

  function accountManagementService($http, $q, configuration, User) {
    // See http://stackoverflow.com/questions/20305615/configure-angularjs-module-to-send-patch-request
    // to add HTTP PATCH method
    $http.defaults.headers.patch = {
      'Content-Type': 'application/json;charset=utf-8',
    };

    var _tenantId;

    function _getUrl() {
      return _tenantId
        ? configuration.user.accountManagement + '/' + _tenantId + '/users/'
        : configuration.user.accountManagement + '/users/';
    }

    function _getUrlAdmin() {
      return configuration.user.accountManagementAdmin;
    }

    return {
      tenantId: function (tenantId) {
        _tenantId = tenantId;
      },

      /**
       * Get the user's account
       * @param userId The user id from which we want to get the account
       */
      getAccount: function (userId) {
        return $http.get(_getUrl() + userId).then(function (response) {
          return new User(response.data);
        });
      },

      /**
       * Modify the user's account
       * @param user The user which has to be modified
       */
      modifyAccount: function (user) {
        // If we send all the user object in this request, we get an HTTP code 406 with the following message :
        // Use special validation routes to modify active, emails and password attributes
        // So, pass only the needed values
        user = user.getCleanEntity();
        var newUser = {};
        newUser.name = {};
        newUser.name.honorificPrefix = user.name.honorificPrefix;
        newUser.name.givenName = user.name.givenName;
        newUser.name.familyName = user.name.familyName;
        newUser.displayName = user.name.givenName + ' ' + user.name.familyName;

        return $http({
          url: _getUrl() + user.userName,
          data: newUser,
          method: 'PATCH',
        });
      },

      /**
       * @param {*} userName id de l'utilisateur à modifier
       * @param {*} patches tableau de patch à effectuer
       */
      patchAccount: function (userName, patches) {
        return $http({
          method: 'PATCH',
          url: _getUrl() + userName,
          headers: {
            'Content-Type': 'application/json-patch+json',
          },
          data: patches,
        });
      },

      /**
       * Account creation is done in 2 shots, first request the creation with a scim-like user object
       * that contains email addresses. An email will be sent to validate the creation of the user
       * The actionURL parameter is used to tell the service where the validation link should point to
       *
       * @param user The user which has to be created
       * @param url The url which will be in the confirmation email
       */
      createAccount: function (user, url) {
        user = user.getCleanEntity();
        return $http.put(_getUrl() + user.userName, user, {
          params: {
            actionURL: url,
          },
        });
      },

      /**
       * The application should triger this function when the account creation
       * confirmation link is clicked
       * @param userId The user id which has to validate its account
       * @param token The token is generated server side and is used to identify the account creation
       */
      validateAccount: function (userId, token) {
        return $http.put(_getUrl() + userId + '/active', null, {
          params: {
            token: token,
          },
        });
      },

      /**
       * Forgotten password method used to change a password when not connected
       * in two shots, same as user creation
       * @param userId The user id which wants to reset its account
       * @param url The url which will be in the confirmation email
       */
      resetPassword: function (userId, url) {
        return $http.put(_getUrl() + userId + '/password', null, {
          params: {
            actionURL: url,
          },
        });
      },

      /**
       * Second part of the forgotten password method
       * @param userId The user id which wants to validate its password
       * @param token The token is generated server side and is used to identify the forgotten password request
       * @param password The new password
       */
      validatePassword: function (userId, token, password) {
        return $http.put(_getUrl() + userId + '/password', password, {
          params: {
            token: token,
          },
          headers: {
            'Content-type': 'text/plain',
          },
        });
      },

      /**
       * Modify password while connected
       * @param userId The user id which wants to modify its password
       * @param oldPassword The previously defined password
       * @param newPassword The new password to be associated to the user
       */
      modifyPassword: function (userId, oldPassword, newPassword) {
        return $http({
          url: _getUrl() + userId + '/password',
          data: {
            newPassword: newPassword,
            oldPassword: oldPassword,
          },
          method: 'PATCH',
        });
      },

      /**
       * Recover the user's email
       * @param email The email whith which the user created its account
       */
      recoverEmail: function (email) {
        return $http.get(_getUrl() + 'email?email=' + email);
      },

      /**
       * Modify the email of the user
       * @param userId The user id which wants to modify its email
       * @param mail The new email
       * @param newPassword The user's password
       * @param actionURL : optionnal param to specify the page URL for email modification confirmation
       */
      modifyEmail: function (userId, mail, password, actionURL) {
        var emails = [mail];
        return $http.put(
          _getUrl() + userId + '/emails/',
          {
            email: emails,
            password: password,
          },
          {
            params: {
              actionURL: actionURL,
            },
          }
        );
      },

      /**
       * Modify the email of the user
       * @param userId The user id which wants to validate its email
       * @param email The user new email to be validated
       * @param token The token is generated server side and is used to identify the modify email request
       */
      validateEmail: function (userId, email, token) {
        return $http.post(_getUrl() + userId + '/emails/' + email, null, {
          params: {
            token: token,
          },
        });
      },
      /**
       * Tells whether there is an email modification pending for this user or not
       * @param userId The user id which email state is being checked
       */
      emailModificationPending: function (userId) {
        return $http.get(_getUrl() + userId + '/emails/state');
      },

      /**
       * Check if a password will be valid before allowing the user to submit
       */
      checkPassword: function (password) {
        return $http
          .post(configuration.user.accountManagement + '/_check_password', '"' + password + '"')
          .then(function (response) {
            return response.data;
          });
      },

      getPublicSettings: function () {
        return $http.get(_getUrlAdmin() + '/publicSettings');
      },

      /**
       * Return the Unpairing URL with a redirectTo parameter
       * @param {string} redirectTo
       * @param {string} jwtKey
       */
      getUnpairingUrl: function (redirectTo, jwtKey) {
        return (
          configuration.user.accountManagement +
          '/ux/#/account-unpairing' +
          '?redirectTo=' +
          redirectTo +
          '&jwtKey=' +
          jwtKey
        );
      },
    };
  }
})();
