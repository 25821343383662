angular.module('common.services').factory('tenantsService', tenantsService);

/* @ngInject */
function tenantsService(configuration, $http) {
  return {
    getMonoTenantId: () => {
      return $http.get(configuration.tenantId.url).then((result) => {
        return _.get(result, 'data.tenantId');
      });
    },
  };
}
