/**
 * @ngdoc directive
 * @name tiers.directive:identificationRecapitulatif
 * @restrict EAC
 * @description Directive for display tiers informations on recapitulatif
 * @element
 * @example
 * <div identification-recapitulatif tiers="tiers" edit-href="'#/user/tiers-edit'"></div>
 **/
angular.module('tiers').directive('identificationRecapitulatif', [
  'mdmService',
  function(mdmService) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'tiers/tiers-directives/identification-recapitulatif/identification-recapitulatif.html',
      scope: {
        tiers: '=',
        editHref: '=',
        readOnly: '=',
        title: '=identificationRecapitulatifTitle',
      },
      link: function(scope) {
        scope.nomNaissanceHidden = !_.get(
          scope,
          'tiers.famille.expand.infosStandardsComplementaires.NomFamille.visible'
        );
      },
    };
  },
]);
