angular.module('offresStage.model').factory('OffreStage', function(Link, User) {
  'use strict';

  /**
   * Classe OffreStage. Objet OffreStage
   * @param {Object} data Données d'une offre de stage en persistance utilisée pour initialiser l'objet
   * @class OffreStage
   * @classdesc OffreStage canonique
   */
  function OffreStage(data) {
    /*eslint complexity:0 */
    /*eslint max-statements:0 */

    data = data || {};

    this.id = data.id;
    this.kind = data.kind;
    this.tenant = data.tenant;
    this.origin = data.origin;

    this.title = data.title;
    this.active = data.active;
    this.reference = data.reference;
    this.referenceAdministrative = data.referenceAdministrative;
    this.date = data.date;
    this.version = data.version;

    this.user = new Link({
      title: _.get(data, 'user.displayName'),
      rel: 'user',
      href: _.get(data, 'user.href'),
      expand: new User(_.get(data, 'user.expand')),
    });

    this.statut = data.statut;

    this.history = data.history || {
      begin: {},
      events: [],
      end: {},
    };

    this.classifications = data.classifications;
    this.correlations = data.correlations;
    this.annotations = data.annotations;
    this.teleservice = data.teleservice;
    this.cadreDepot = data.cadreDepot;
    this.dossierRattachement = data.dossierRattachement;
    this.dateReceptionModification = data.dateReceptionModification;
    this.raisonSociale = data.raisonSociale;
    this.description = data.description;
    this.secteurActivite = data.secteurActivite;
    this.effectif = data.effectif;
    this.siteWeb = data.siteWeb;
    this.courriel = data.courriel;
    this.typeStage = data.typeStage;
    this.mission = data.mission;
    this.profil = data.profil;
    this.dureeStage = data.dureeStage;
    this.niveauQualification = data.niveauQualification;
    this.remunere = data.remunere;
    this.datePrevisionnelleDebut = data.datePrevisionnelleDebut;
    this.niveauQualificationCandidatRetenu = data.niveauQualificationCandidatRetenu;
    this.numeroDossierSubvention = data.numeroDossierSubvention;
    this.numeroCourrierNotification = data.numeroCourrierNotification;
    this.dateReceptionNotification = data.dateReceptionNotification;
    this.adresse = data.adresse;
    this.dateDemarrage = data.dateDemarrage;
    this.genre = data.genre;
    this.age = data.age;
    this.handicap = data.handicap;
    this.residence = data.residence;
    this.pieces = data.pieces;
    this.accesTransport = data.accesTransport;
    this.referenceAdministrative = data.referenceAdministrative;
    this.SIRET = data.SIRET;
  }
  return OffreStage;
});
