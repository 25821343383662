angular.module('portailDepotDemandeAide').controller('connectedController', [
  '$scope',
  '$state',
  '$rootScope',
  '$stateParams',
  '$modal',
  '$transitions',
  'accountPublicSettings',
  'agreementService',
  'StoreService',
  'tiersService',
  'routeUtilsService',
  'IFrameCommunicationManager',
  'selectableTiers',
  'selectedTiers',
  function(
    $scope,
    $state,
    $rootScope,
    $stateParams,
    $modal,
    $transitions,
    accountPublicSettings,
    agreementService,
    StoreService,
    tiersService,
    routeUtilsService,
    IFrameCommunicationManager,
    selectableTiers,
    selectedTiers
  ) {
    $scope.tenantId = $stateParams.tenantId;
    $scope.accountHref = $state.href('app.connected.user.account-user');
    $scope.print = $stateParams.print === 'true' || null;
    $scope.selectableTiers = selectableTiers;
    $scope.selectedTiers = selectedTiers;
    $scope.currentTiersRef = _.get(selectedTiers, 'reference');

    // hide some ui elements on special connected screens
    $scope.$watch('$state.current.name', function() {
      $scope.hideLayout = $state.current.name === 'app.connected.tiers-selection';
    });

    // We use a transition hook to make our dashboard disappear on accueil reload
    // for example, on changing tiers
    // The only drawback is that we'll eventually have to unregister it on destroy.
    // As we already have some events to 'clean' this is acceptable.
    const transCriteria = {
      to: 'app.connected.dashboard.accueil',
      from: (state) => state.name !== 'app.connected.tiers-selection',
    };
    const unregisterLoadingTransition = $transitions.onStart(transCriteria, function(transition) {
      $scope.stateLoading = true;
      transition.promise.finally(() => {
        $scope.stateLoading = false;
      });
    });
    // Tiers change request event sent by iframes
    // We need it at this top level state, as we could listen
    // for this event from different places on the portal.
    const listenChangeTiersRequest = new IFrameCommunicationManager();

    /** change tiers request listener
     * @param {Object} msg iframe message
     * @return {void}
     */
    function changeTiersRequestCallback(msg) {
      const action = _.get(msg, 'data.action');
      const source = _.get(msg, 'data.source');

      // Change current Tiers restricted to tiers-selection screen for now
      if (
        source === 'referentiel-tiers' &&
        action === 'changeTiers' &&
        _.get($state, 'current.name') === 'app.connected.tiers-selection'
      ) {
        const tiersRef = _.get(msg, 'data.reference');
        // On iframe event, we set our new reference in localstorage
        // This will trigger the storage event which will reload other opened window or tab.
        if (!$scope.currentTiersRef || tiersRef !== $scope.currentTiersRef) {
          if (_.get($state, 'params.redirectFrom') === 'portal') {
            // those params are dynamic. We must ensure that those are reinitialized.
            // to avoid non wanted redirection when manually changing tiers.
            $state.params.redirectTo = 'app.connected.dashboard.accueil';
            $state.params.redirectParams = JSON.stringify({});
          }
          StoreService.currentTiersRef.set(tiersRef, true);
          routeUtilsService.handleRedirectionOrGoTo('app.connected.dashboard.accueil', { reload: true });
        } else if ($scope.currentTiersRef === tiersRef) {
          // tiers hasn't changed.so we want to reset the state to where we were.
          // without reloading the state.
          // We will directly use redirectTo and redirectFrom params and
          // need to handle badly formatted parameters.
          let previousLocation = _.get($state, 'params.redirectTo') || 'app.connected.dashboard.accueil';
          let previousParams = {};
          const rawpreviousParams = _.get($state, 'params.redirectParams');
          if (rawpreviousParams) {
            try {
              previousParams = JSON.parse(rawpreviousParams);
            } catch (err) {
              previousLocation = 'app.connected.dashboard.accueil';
            }
          }
          $state.go(previousLocation, previousParams);
        }
      }
    }
    // register the listener.
    listenChangeTiersRequest.manageEvent(changeTiersRequestCallback);

    /** Local storage listener
     * used to reload on current tiers changes from other windows/tabs. (ls.current-tiers-ref)
     * @param {Object} storageEvent event
     * @return {void}
     */
    function tiersChangedInStorageCallback(storageEvent) {
      if (storageEvent.key === 'ls.current-tiers-ref') {
        // first ensure that our cached current tiers is unset
        // Obviously, we don't want to unset it in localstorage.
        StoreService.currentTiersRef.unset(false);
        // Reloading our state with the newly instorage set tiers
        $state.go('app.connected.dashboard.accueil', {}, { reload: true });
      }
    }
    // register the listener.
    window.addEventListener('storage', tiersChangedInStorageCallback);

    /** Fonction d'ouverture des modals paramétrées */
    const openModal = function(modals) {
      if (modals.length > 0) {
        // Blocage de la navigation pour ne pas outrepasser les modals obligatoires
        $rootScope.blockedNavigation = true;

        const scopeModal = $scope.$new();
        scopeModal.data = _.get(_.first(modals), 'data');

        // Cette fonction devra être appelé à la fermeture de la modal "courante", et...
        scopeModal.next = function() {
          // ...à sa fermeture, on rappelle la fonction en retirant la modal "courante" du tableau pour ouvrir la suivante
          openModal(_.slice(modals, 1));
        };

        $modal({
          template: _.get(_.first(modals), 'template'),
          backdrop: 'static',
          keyboard: false,
          scope: scopeModal,
        });
      } else {
        // une fois que toutes les modals "obligatoires" sont fermées, on débloque la navigation
        $rootScope.blockedNavigation = false;
      }
    };

    // Lastly we listen for current tiers change to update mainbar tiers button
    $scope.$on('tiers.current.updated', () => {
      tiersService.getAllCurrentUserTiers(true).then((allTiers) => {
        $scope.selectableTiers = allTiers;
      });
      tiersService.getCurrentTiers().then((currentTiers) => {
        $scope.selectedTiers = currentTiers;
      });
    });

    /** le tableau de modal permet d'enchaîner des modals :
     *  - "scope" permettant de transmettre des infos dans le scope de la modal
     *  - "next" ouvre la modal 'enfant' et est appelée à la fermeture de la modal 'parente'
     */
    var modalTab = [];

    // 1 - Affichage modal CGU
    var cguModal = {
      template: 'modal/cgu.html',
      controller: 'cguController',
      data: {
        publicSettings: accountPublicSettings,
      },
    };
    var datePublication = _.get(accountPublicSettings, 'cgu.datePublication');
    var dateAcceptationCGU = _.get($rootScope, 'currentUser.dateAcceptationCGU');
    if (
      datePublication &&
      (_.isNil(dateAcceptationCGU) || (!_.isNil(dateAcceptationCGU) && dateAcceptationCGU < datePublication))
    ) {
      modalTab.push(cguModal);
    }

    // 2 - Affichage modal "Abonnements et autorisations"
    var agreementModal = {
      template: 'modal/agreement.html',
      controller: 'agreementController',
    };
    // Condition d'affichage des 'agreements'
    // il faut au moins un 'agreement' actif non encore validé par le user
    agreementService.getList($scope.tenantId).then(function(list) {
      // Récupération des agreements 'actif' ET date d'activation antérieure ou égale à la date du jour
      var listeAgreement = _.filter(list, function(agreement) {
        return agreement.actif && moment(agreement.dateActivation).isSameOrBefore(new Date(), 'day');
      });

      // Récupération des agreements du user ayant une date de validation
      var agreementsUser = _.get($rootScope, 'currentUser.agreements');
      var validateAgreementsUser = _.filter(agreementsUser, function(agreement) {
        return agreement.dateValidation;
      });

      // Exclusion des agreements déjà validés par le user
      if (!_.isNil(validateAgreementsUser)) {
        listeAgreement = _.filter(listeAgreement, function(agreement) {
          return !_.find(validateAgreementsUser, function(userAgreement) {
            return userAgreement.id === agreement.id && userAgreement.dateValidation;
          });
        });
      }

      if (listeAgreement && listeAgreement.length > 0) {
        modalTab.push(agreementModal);
      }

      // Ouvre la première modal du tableau
      openModal(modalTab);
    });

    // on destroy, we always ensure that listeners
    // are removed.
    $scope.$on('$destroy', function() {
      unregisterLoadingTransition();
      listenChangeTiersRequest.close();
      window.removeEventListener('storage', tiersChangedInStorageCallback);
    });
  },
]);
