angular.module('common.services').factory('indicateursService', indicateursService);

/* @ngInject */
function indicateursService(configuration, $httpParamSerializer, jwtSessionService, $http, $log) {
  /**
   * Extract criteres analytiques href collection from demande
   * @param {Object} demande
   * @return {Array} criteresAnalytiques href collection
   */
  const getCriteresAnalytiquesHrefFromDemande = ({ hierarchies = [] }) =>
    _.flatten(hierarchies.map(({ criteresAnalytiques = [] }) => criteresAnalytiques)).map(({ href }) => href);

  return {
    /**
     * Method that builds url to load the indicateurValues form
     * @param {Object} entity
     * @param {String} state should be one of those 'previewed' or 'realized'
     * @param {Boolean} readOnly
     * @param {Boolean} fetchIndicateurs
     * @param {Object} contribution
     * @param {String} origin
     * @returns {String} iframe url
     */
    getIndicateursIframeUrl: (
      entity,
      readOnly = false,
      state = 'previewed',
      fetchIndicateurs = false,
      contribution = null,
      origin = null
    ) => {
      const queryParams = {
        jwtKey: jwtSessionService.getJwtKey(),
        entityId: entity.id,
        state,
        fetchIndicateurs,
        readOnly,
      };

      if (origin) queryParams.origin = origin;
      if (contribution) queryParams.contributionId = contribution.id;

      const serializedQueryParams = $httpParamSerializer(queryParams);
      return `${configuration.referentielHierarchies.frontUrl}${configuration.tenant.id}/indicateurs-saisis/userIndicateursSaisis?${serializedQueryParams}`;
    },

    /**
     * Update data with message sent from indicateurs iframe
     * @param {Object} msg
     * @param {Function} accept
     * @param {Function} reject
     * @param {Object} aide
     * @returns {Promise<number>} Number of indicateurs
     */
    updateIndicateursSaisisOnAide: (msg, accept, reject, aide) => {
      const action = _.get(msg, 'data.action');
      const indicateursSaisis = _.get(msg, 'data.indicateursSaisis');
      // Update aide
      if (action === 'updateIndicateursSaisis' && indicateursSaisis) {
        _.set(aide, 'indicateursSaisis', indicateursSaisis);
        accept();
      } else if (action === 'invalidIndicateursSaisis') {
        reject(new Error('Invalid indicateur form'));
      } else {
        reject(new Error('Unknown error'));
      }
    },

    /**
     * Get the number of indicateurs for this demande-aide using criteres analytiques
     * @param {Object} aide
     * @returns {Promise<number>} number of indicateurs
     */
    getIndicateursCount: (aide) => {
      const queryParams = {
        'criteresAnalytiques[]': getCriteresAnalytiquesHrefFromDemande(aide),
      };
      const serializedQueryParams = $httpParamSerializer(queryParams);
      // Send request
      return $http
        .get(
          `/referentiel-hierarchies/api/tenants/${configuration.tenant.id}/indicateurs/from-criteres-analytiques?${serializedQueryParams}`
        )
        .then(function(response) {
          return _.get(response, 'data.data', []).length;
        })
        .catch(function(err) {
          $log.error(err);
          throw new Error(err);
        });
    },

    getCriteresAnalytiquesHrefFromDemande,

    /**
     * The indicateurs list should NOT be fetched when (by priority in descending order):
     * - user has already visited "indicateurs saisis" page (aide or contribution)
     * - this is NOT a contribution and indicateursSaisis is NOT empty
     * In any other scenario, indicateurs list should be fetched.
     * @param {Object} history history object from aide or contribution
     * @param {Boolean} isContribution `true` if user is editing a contribution, else `false`
     * @param {Array} indicateursSaisis list of indicateurs saisis
     * @return {Boolean} `true` if indicateurs should be fetched, else `false`
     */
    shouldFetchIndicateurs(history, isContribution, indicateursSaisis = []) {
      const stepsVisited = _.get(history, 'begin.metadata.stepsVisited', []);
      // If steps already visited, do NOT fetch
      if (_.includes(stepsVisited, 'indicateurs')) {
        return false;
      }

      // If a contribution, or if not a contribution and indicateursSaisis is empty, fetch!
      return !(!isContribution && indicateursSaisis.length);
    },
  };
}
