angular.module('common.directives').directive('ibanField', [
  '$q',
  '$window',
  'domiciliationService',
  function($q, $window, domiciliationService) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'common/common-directives/iban-field/iban-field.html',
      require: '^form',
      scope: {
        viewConfiguration: '=',
        configuration: '=?',
        namespace: '=?',
        name: '=',
        model: '=',
        validationFr: '=',
        remoteValidation: '=?',
        mdm: '<',
      },
      link: function(scope, element, attributes, formCtrl) {
        scope.namespace = scope.namespace || scope.viewConfiguration.ns;
        scope.configuration = scope.configuration || _.get(scope, 'viewConfiguration.fields.' + scope.name);
        scope.errors = {};
        scope.bicDisabled = false;

        // Read remote validation from directive, configuration or viewConfiguration
        if (!scope.remoteValidation) {
          scope.remoteValidation = _.get(
            scope,
            'configuration.remoteValidation',
            _.get(scope, 'viewConfiguration.remoteValidation', false)
          );
        }

        /**
         * Example:
         * We need to transform 'FR7610107001011234567890129' into ['FR76', '1010', '7001', …]
         * numero = 'FR7610107001011234567890129'
         *  _.times(8, () => '') = ['', '', '', '', '', '', '', '']
         * _.chunk(numero, 4) = [["F", "R", "7", "6"], ["1", "0", "1", "0"], ["7", "0", "0", "1"], ["0", "1", "1", "2"], …]
         * scope.ibanArray = ['FR76', '1010', '7001', …]
         */

        // Init an array of 8 empty strings to split iban into
        scope.ibanArray = _.times(8, () => '');
        const numero = _.get(scope, 'model.numero');
        if (numero && numero !== 'incorrect') {
          scope.bicDisabled = true;
          // Split iban in array of 4 elements to add it in ibanArray
          _.chunk(numero, 4).forEach((chunk, index) => {
            // Should join chunk into a string
            scope.ibanArray[index] = chunk.join('');
          });
          ibanChanged(true);
        }

        function setValidity(fieldName, valid, error) {
          scope.errors[error] = !valid;
          if (formCtrl[fieldName]) {
            formCtrl[fieldName].$setValidity(error, valid);
          }
        }
        /**
         * Search a valid IBAN from banks listed (limited to Banque de France)
         * @return promise bank counter data
         */
        function findFrCounter(init) {
          const idEtabl = scope.model.numero.substr(4, 5);
          const idGuichet = scope.model.numero.substr(9, 5);
          // don't display spinner on init
          scope.waitForFRValidation = !init;
          return domiciliationService
            .verifyIban(idGuichet, idEtabl)
            .then(function(response) {
              scope.waitForFRValidation = false;
              let corresponding = {};
              if (
                idGuichet === _.get(response, 'data.idguichet') &&
                idEtabl === _.get(response, 'data.idetabl') &&
                _.has(response, 'data.bic')
              ) {
                corresponding = {
                  bic: response.data.bic,
                  idguichet: response.data.idguichet,
                  idetabl: response.data.idetabl,
                  lbdomicil: response.data.lbdomicil,
                };
              }
              scope.bicDisabled = !_.isEmpty(corresponding);
              return corresponding;
            })
            .catch(function(err) {
              // Erreur d'appel du referentiel bancaire
              scope.waitForFRValidation = false;
            });
        }

        function ibanChanged(init = false) {
          _.set(scope, 'model.numero', '');

          const iban = scope.ibanArray.join('').replace(/(\W|_)+/g, '');

          // IBAN is checked when it has at least 2 digits to check if the country is in the SEPA zone
          if (iban.length >= 2 && !IBAN.countries[iban.slice(0, 2)]) {
            return setValidity(scope.name, false, 'invalidIbanField');
          }
          // IBAN is registered only when it has a correct format
          // recovery of the expected number of digits from the country code
          if (iban.length >= 2 && iban.length === IBAN.countries[iban.slice(0, 2)].length) {
            const validIban = IBAN.isValid(iban);
            setValidity(scope.name, validIban, 'invalidIbanField');
            // iban is registered only when correct
            scope.model.numero = validIban ? iban : 'incorrect';
            if (scope.model.numero.slice(0, 2) === 'FR') {
              findFrCounter(init).then(function(corresponding) {
                // Acquire corresponding bic
                scope.model.BIC = _.isEmpty(corresponding) ? scope.model.BIC : corresponding.bic;
                scope.model.domiciliation = _.get(corresponding, 'lbdomicil', '');
                // The value is invalidated if no counter has been found and the validation parameter is set to true
                setValidity(scope.name, !scope.validationFr || !!corresponding, 'unknownIbanField');
                scope.bicDisabled = !_.isEmpty(corresponding);
              });
            } else {
              // On passe par une promesse pour pallier à un problème de sous-scope généré par form
              // Sans cela le scope n'est pas mis à jour directement
              $q(function(resolve) {
                resolve();
              }).then(function() {
                setValidity(scope.name, true, 'unknownIbanField');
                // On initialise le libellé de la domiciliation par le libellé du pays récupéré dans le mdm
                // (nécessaire pour la génération des fichiers PAYMEN)
                const codePays = scope.model.numero.slice(0, 2);
                const listePays = _.get(scope.mdm, 'pays.object');
                if (codePays) {
                  const pays = _.find(listePays, ['expand.reference', codePays]);
                  scope.model.domiciliation = _.get(pays, 'title', '');
                }
                scope.bicDisabled = validIban && iban.slice(0, 2) === 'FR';
                if (!init && (!validIban || iban.slice(0, 2) !== 'FR')) {
                  _.unset(scope, 'model.BIC');
                }
              });
            }
          }
          // Empty iban should not be invalid
          else if (iban.length === 0) {
            setValidity(scope.name, true, 'invalidIbanField');
          }
        }

        /**
         * Dynamically change focus when the user is writing the iban
         */
        scope.moveIbanFocus = function moveIbanFocus(index, key, direction) {
          // For accessibility needs, we mustn't move focus for some keys (tab, shift, arrows…)
          const movingKeys = _.range(9, 46)
            .concat(_.range(112, 146))
            .concat([91, 92, 225]);
          const deleteKeys = [8, 46];
          if (!_.includes(movingKeys, key)) {
            let target = -1;
            // If the input has 4 characters, we move to the next one
            if (scope.ibanArray[index].length === 4 && direction && !_.includes(deleteKeys, key)) {
              target = index + 1;
            }
            // If the input has been erased, we go back to the previous one
            else if (scope.ibanArray[index].length === 0 && !direction && _.includes(deleteKeys, key)) {
              target = index - 1;
            }

            if (target > -1 && target < scope.ibanArray.length) {
              // Asynchronous DOM manipulation to automatically go to the next field
              element[0].querySelector('.iban-part-' + target).focus();
            }
          }
        };

        scope.pasteIBAN = function pasteIBAN($event) {
          let pasted = '';
          if (_.get($event, 'originalEvent.clipboardData.getData')) {
            pasted = $event.originalEvent.clipboardData.getData('text/plain') || '';
          }
          // IE compatibility
          else if ($window.clipboardData) {
            pasted = $window.clipboardData.getData('Text') || '';
          }

          pasted = pasted.replace(/(\W|_)+/g, '');

          // Transform pasted value into an array of 4 chars strings
          // 'FR7610107001011234567890129' into ['FR76', '1010', '7001', …]
          const slices = _.chunk(pasted, 4).map((chunk) => chunk.join(''));
          //We make the forEach inside a tempArray, to avoid overload the digest cycle in ibanArray (IE Case)
          const tempArray = [];
          // Copy each slice of pasted iban into the right ibanArray index
          _.each(scope.ibanArray, (part, index) => {
            tempArray[index] = slices[index] || '';
          });

          // When the array is ready, we replace it
          scope.ibanArray = tempArray;
          scope.ibanChanged();
        };

        // debounced functions versions (perf gain, IE case)
        scope.ibanChanged = _.debounce(ibanChanged, 250, { maxWait: 1000 });
      },
    };
  },
]);
