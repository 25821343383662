angular.module('portailDepotDemandeAide.user').controller('accountUserController', [
  '$rootScope',
  '$scope',
  'mdm',
  'userAccountUserConfiguration',
  'rattachementUserConfiguration',
  'alertsService',
  'accountManagementService',
  '$stateParams',
  'tiers',
  'masterdata',
  'jwtSessionService',
  'agreementService',
  function(
    $rootScope,
    $scope,
    mdm,
    userAccountUserConfiguration,
    rattachementUserConfiguration,
    alertsService,
    accountManagementService,
    $stateParams,
    tiers,
    masterdata,
    jwtSessionService,
    agreementService
  ) {
    'use strict';

    $scope.userAccountUserConfiguration = userAccountUserConfiguration;
    $scope.rattachementUserConfiguration = rattachementUserConfiguration;
    $scope.tiers = tiers;
    $scope.mdm = mdm;
    $scope.masterdata = masterdata;
    $scope.userSession = $rootScope.currentUser;
    $scope.isOidcUser = $scope.userSession && $scope.userSession.providerId && !$scope.userSession.franceConnect;

    // Directory of file's icons
    $scope.urlFileIcons = './resources/images/file-icons/';

    /**
     * Change the user's password
     * @param  {string} newPassword The new password for this user
     * @param  {string} confirmPassword Confirmation of the password
     */
    $scope.validatePassword = function(newPassword, confirmPassword) {
      if (
        newPassword &&
        newPassword.length > 0 &&
        confirmPassword &&
        confirmPassword.length > 0 &&
        newPassword === confirmPassword
      ) {
        accountManagementService.validatePassword($stateParams.username, $stateParams.token, newPassword).then(
          function() {
            $scope.$broadcast('alerts', alertsService.getAlertSuccess('user.account-reset-password.confirmation'));
          },
          function() {
            $scope.$broadcast('alerts', alertsService.getAlertError('user.account-reset-password.error'));
          }
        );
      } else {
        // Handle case when the user clicks on the 'Modify' button, when the 2 passwords are not identical,
        // and possibly other cases not seen yet
        $scope.$broadcast('alerts', alertsService.getAlertError('connected.user.account-reset-password.error'));
      }
    };

    /**
    /*  Ajout des abonnements
    * @param list : liste mise à jour avec les abonnements
    */
    var ajoutAbonnements = function(list) {
      _.forEach($scope.listeAbonnement, function(abonnement) {
        var userAbonnement = {
          type: _.get(abonnement, 'type'),
          id: _.get(abonnement, 'id'),
          checked: _.get(abonnement, 'checked', false),
          dateValidation: new Date().toISOString(),
        };
        list.push(userAbonnement);
      });
      // Pour modifier seulement les abonnements on utilise listeAutorisationStatic
      _.forEach($scope.listeAutorisationStatic, function(autorisation) {
        var userAutorisation = {
          type: _.get(autorisation, 'type'),
          id: _.get(autorisation, 'id'),
          checked: _.get(autorisation, 'checked', false),
          dateValidation: new Date().toISOString(),
        };
        list.push(userAutorisation);
      });
      $scope.listeAbonnementStatic = angular.copy($scope.listeAbonnement);
    };

    /**
    /*  Ajout des autorisations
    * @param list : liste mise à jour avec nouvelles autorisations
    */
    var ajoutAutorisations = function(list) {
      _.forEach($scope.listeAutorisation, function(autorisation) {
        var userAutorisation = {
          type: _.get(autorisation, 'type'),
          id: _.get(autorisation, 'id'),
          checked: _.get(autorisation, 'checked', false),
          dateValidation: new Date().toISOString(),
        };
        list.push(userAutorisation);
      });
      // Pour modifier seulement les autorisations on utilise listeAbonnementStatic
      _.forEach($scope.listeAbonnementStatic, function(abonnement) {
        var userAbonnement = {
          type: _.get(abonnement, 'type'),
          id: _.get(abonnement, 'id'),
          checked: _.get(abonnement, 'checked', false),
          dateValidation: new Date().toISOString(),
        };
        list.push(userAbonnement);
      });
      $scope.listeAutorisationStatic = angular.copy($scope.listeAutorisation);
    };

    // Validation des autorisations
    $scope.saveAutorisations = function() {
      var userAgreements = [];
      ajoutAutorisations(userAgreements);

      agreementService.updateList(_.get($rootScope, 'currentUser.self'), userAgreements);
    };

    // Validation des abonnements
    $scope.saveAbonnements = function() {
      var userAgreements = [];
      ajoutAbonnements(userAgreements);

      agreementService.updateList(_.get($rootScope, 'currentUser.self'), userAgreements);
    };
  },
]);
