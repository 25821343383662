/**
 * @ngdoc directive
 * @module aides
 * @name informationsGeneralesAideForm
 * @restrict EA
 * @description
 *
 * Edit the 'information générales' part of an 'aide' object
 *
 * @param {object} aide - Aide to edit
 * @param {object} viewConfiguraton - view configuration
 *
 * @example
 *
 *  `<div informations-generales-aide-form
 *     view-configuration="informationsGeneralesConfiguration"
 *     aide="demande" class="col-md-12">
 *   </div>`
 */

angular.module('aides').directive('informationsGeneralesAideForm', [
  'aidesService',
  '$log',
  '$translate',
  function(aidesService, $log, $translate) {
    'use strict';

    return {
      restrict: 'EA',
      replace: 'true',
      transclude: false,
      templateUrl: 'aides/aides-directives/informations-generales-aide-form/informations-generales-aide-form.html',
      require: '^form',
      scope: {
        aide: '<',
        viewConfiguration: '<',
        teleserviceConfiguration: '<',
      },
      link: function(scope, element, attrs, formController) {
        if (_.has(scope, 'aide.contrat') && _.isEmpty(scope.aide.contrat)) {
          delete scope.aide.contrat;
        }

        scope.form = formController;
        // Regex for years between 1900-2099
        scope.onlyYears = /^(19|20)\d{2}$/;

        // Exercices
        scope.exerciceOptions = {
          start: 'decade',
          depth: 'decade',
          value: '' + scope.aide.exerciceBudgetaire,
        };

        // parseInt only when is a string (in the contract aide.exerciceBudgetaire is an integer)
        scope.$watch('exerciceOptions.value', function(value) {
          if (_.isString(value) && !_.isEmpty(value)) {
            scope.aide.exerciceBudgetaire = _.parseInt(value);
          } else if (!_.isNaN(value) && !_.isNumber(value) && !_.isEmpty(value)) {
            // Initialize at current year
            scope.aide.exerciceBudgetaire = new Date().getFullYear();
          } else if (!value) {
            // Delete if empty
            delete scope.aide.exerciceBudgetaire;
          }
        });

        // Montant
        scope.montant = scope.aide.montant;

        // Active thématiques
        scope.thematiques = _.get(scope, 'aide.teleservice.expand.thematiques', []).filter(
          ({ actif }) => actif === true
        );

        // Sous-thématiques
        scope.sousThematiques = [];

        scope.isVisibleExerciceBudgetaire = function(viewConfiguration) {
          return !aidesService.isCPO(scope.aide) && !viewConfiguration.fields.exerciceBudgetaire.hidden;
        };

        // Fréquences
        scope.frequences = [
          {
            id: 'PREMIERE',
            label: $translate.instant('aides.informations-generales.frequence.premiere'),
          },
          {
            id: 'RENOUVELLEMENT',
            label: $translate.instant('aides.informations-generales.frequence.renouvellement'),
          },
        ];
        scope.isRequiredFrequence = function(viewConfiguration) {
          return (
            !viewConfiguration.fields.frequence.hidden &&
            !viewConfiguration.remoteValidation &&
            viewConfiguration.fields.frequence.required
          );
        };

        // Natures
        scope.natures = [
          {
            id: 'FONCTIONNEMENT_GLOBAL',
            label: $translate.instant('aides.informations-generales.nature.fonctionnement-global'),
          },
          {
            id: 'PROJET_ACTION',
            label: $translate.instant('aides.informations-generales.nature.projet-action'),
          },
        ];
        scope.isRequiredNature = function(viewConfiguration) {
          return (
            !viewConfiguration.fields.nature.hidden &&
            !viewConfiguration.remoteValidation &&
            viewConfiguration.fields.nature.required
          );
        };
        scope.displayNature = function(viewConfiguration) {
          return !_.get(viewConfiguration, 'fields.nature.hidden', true);
        };

        // Sous-thématiques
        scope.chargementSousThematiques = function(firstTime) {
          scope.sousThematiques = [];

          if (!_.isEmpty(scope.aide.thematique)) {
            if (!firstTime) {
              scope.aide.sousThematique = undefined;
            }
            aidesService
              .getSousThemathiques(scope.aide.thematique)
              .then(function(sousThematiques) {
                scope.sousThematiques = _.map(_.orderBy(sousThematiques, 'title'), function(sousThematique) {
                  return {
                    href: sousThematique.id,
                    title: sousThematique.title,
                  };
                });
              })
              .catch(function(error) {
                // Erreur d'appel du referentiel financement
                $log.error(error);
                scope.sousThematiques = [];
              });
          }
        };

        scope.chargementSousThematiques(true);
      },
    };
  },
]);
