angular.module('tiers').component('agrementsDemandeurs', {
  bindings: {
    name: '@',
    configuration: '<',
    readOnly: '<',
    tiers: '<',
  },
  templateUrl: 'tiers/tiers-directives/agrements-demandeurs/agrements-demandeurs.html',
  controller: agrementsDemandeursController,
});
agrementsDemandeursController.$inject = ['$scope', '$modal'];

function agrementsDemandeursController($scope, $modal) {
  var modalEditTemplate = 'tiers/tiers-directives/agrements-demandeurs/modal/modal-edit-agrement.html';
  var modalDeleteTemplate = 'tiers/tiers-directives/agrements-demandeurs/modal/modal-delete-agrement.html';
  var modalAddTemplate = 'tiers/tiers-directives/agrements-demandeurs/modal/modal-add-agrement.html';
  var vm = this;
  var thematiqueVide = {};

  vm.$onInit = function() {
    thematiqueVide = {
      active: true,
      reference: vm.tiers.reference,
      tiers: {
        href: vm.tiers.id,
        title: vm.tiers.title,
      },
    };
    $scope.viewConfiguration = _.merge(
      {
        ns: 'teleservice.demandeur-thematiques.TIERS_ASSOCIATION.agrements-demandeurs',
      },
      vm.configuration
    );

    vm.thematiqueAssociation = _.get(vm.tiers, 'thematiquesLiees.association', thematiqueVide);
  };

  /**
   *  Affichage d'une fenêtre modale pour la creation d'un agrément
   * @param  {index} index de l'agrément que l'on souhaite modifier
   */
  vm.addAgrement = function() {
    var scopeModal = $scope.$new();
    scopeModal.agrement = {};
    scopeModal.niveauxAgrement = _.get(vm.tiers, 'famille.expand.niveauxAgrement');
    scopeModal.viewConfiguration = $scope.viewConfiguration;
    scopeModal.confirmAddAgrement = $scope.confirmAddAgrement;

    $modal({
      scope: scopeModal,
      template: modalAddTemplate,
      backdrop: 'static',
    });
  };
  /**
   *  Affichage d'une fenêtre modale pour la modification d'un agrément
   * @param  {index} index de l'agrément que l'on souhaite modifier
   */
  vm.editAgrement = function(index) {
    var scopeModal = $scope.$new();
    scopeModal.confirmEditAgrement = $scope.confirmEditAgrement;
    scopeModal.agrement = angular.copy(vm.thematiqueAssociation.agrements[index]);
    scopeModal.niveauxAgrement = _.get(vm.tiers, 'famille.expand.niveauxAgrement');
    scopeModal.index = index;
    scopeModal.viewConfiguration = $scope.viewConfiguration;

    $modal({
      scope: scopeModal,
      template: modalEditTemplate,
      backdrop: 'static',
    });
  };

  /**
   *  Affichage d'une fenêtre modale demandant la confirmation de la suppression d'un agrément
   * @param  {index} index de l'agrément que l'on souhaite supprimer
   */
  vm.deleteAgrement = function(index) {
    var scopeModal = $scope.$new();
    scopeModal.confirmDeleteAgrement = $scope.confirmDeleteAgrement;
    scopeModal.agrement = vm.thematiqueAssociation.agrements[index];
    scopeModal.index = index;
    $modal({
      scope: scopeModal,
      template: modalDeleteTemplate,
      backdrop: 'static',
    });
  };

  $scope.confirmAddAgrement = function(agrement) {
    vm.thematiqueAssociation.agrements = vm.thematiqueAssociation.agrements || [];
    vm.thematiqueAssociation.agrements.push(agrement);
  };

  $scope.confirmEditAgrement = function(agrement, index) {
    vm.thematiqueAssociation.agrements[index] = agrement;
  };

  $scope.confirmDeleteAgrement = function(index) {
    vm.thematiqueAssociation.agrements.splice(index, 1);
  };
}
