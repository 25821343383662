/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name confirmation-field
 * @restrict A
 * @description
 *
 *   When 2 fields must have the same value (generally password or emails), check if the 2 values given are identical.
 *
 *
 *   Inspiration from : http://stackoverflow.com/questions/14012239/password-check-directive-in-angularjs#nxEqual
 *
 *   Note : quite redundant with validation-field directive
 *
 * @param {string} validation-field - The expression that should be equal to the value of the current model
 *
 * @example
 *
 *   `<input type="text" ng-model="password1" confirmation-field="password2">`
 *
 */

angular.module('form.directives').directive('confirmationField', [
  function() {
    'use strict';
    return {
      require: ['ngModel'],
      link: function(scope, elem, attrs, ctrls) {

        var modelCtrl = ctrls[0];
        // if ngModel is not defined, we do nothing
        if (!modelCtrl) {
          return;
        }
        if (!attrs.confirmationField) {
          return;
        }

        scope.$watch(attrs.confirmationField, function(value) {
          // the second value is not set yet, we do nothing
          if (modelCtrl.$viewValue === undefined || modelCtrl.$viewValue === '') {
            return;
          }
          modelCtrl.$setValidity('confirmationField', value === modelCtrl.$viewValue);
        });
        modelCtrl.$parsers.push(function(value) {
          var isValid = value === scope.$eval(attrs.confirmationField);
          modelCtrl.$setValidity('confirmationField', isValid);
          // Affectation de la valeur, même en cas d'erreur (contraire au principe Angular)
          // Cela permet de résoudre un problème lors de la validation de 2 champs email
          // cf http://jira.mgdis.fr/browse/CRPLPDA-530
          return value;
        });
      }
    };
  }
]);