angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleDemandeurFamilleController', depotSimpleDemandeurFamilleController);

depotSimpleDemandeurFamilleController.$inject = [
  '$scope',
  '$q',
  '$httpParamSerializer',
  'Tiers',
  'tiersService',
  'configuration',
  'jwtSessionService',
  '$sce',
  'StoreService',
  '$log',
  'alertsService',
];

function depotSimpleDemandeurFamilleController(
  $scope,
  $q,
  $httpParamSerializer,
  Tiers,
  tiersService,
  configuration,
  jwtSessionService,
  $sce,
  StoreService,
  $log,
  alertsService
) {
  'use strict';

  $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsDemandeur || {};
  $scope.cleanNavigate();
  // Manually set $error to form to disable save button at init
  // If we already have a valid tiers we should receive a message from iframe to clear $error
  $scope.depotForm.$error = { invalidTiers: {} };

  // Get the list of familles references in a string
  const restrictionFamillesTiers = _.map($scope.pageOptions.familles, (famille) =>
    famille.famille.href.split('/').pop()
  ).join(',');
  const gestionRattachementCompte =
    _.get(configuration, 'tiers.rattachement.demandeRattachementCompteTiers') &&
    $scope.teleserviceConfiguration.workflow.pageInformationsDemandeur.gestionRattachementCompte;

  let publicSettingsTiers;

  tiersService.getPublicSettingsTiers().then((publicSettings) => {
    publicSettingsTiers = publicSettings;
  });

  let globalMetadata = _.get($scope, 'aide.history.begin.metadata');

  const promises = [];
  let demandeur = StoreService.demandeur.get();

  const isDemandeurStatusMissing = demandeur && !demandeur.status;

  // create the demandeur if it does not already exist
  if (!demandeur || isDemandeurStatusMissing) {
    const newDemandeur = new Tiers({
      status: 'TEMPORARY',
      linkedUsers: $scope.aide.linkedUsers,
      user: $scope.aide.user,
    }).getCleanEntity();

    // ! we wait for pending promises in depot workflow before update aide. this is important to avoid 2 creations.
    const promise = StoreService.depot.pendingPromises
      .promise()
      .then(() => {
        return tiersService.saveTiers(newDemandeur, $scope.mdm).then((tiers) => {
          // at this moment we don't have a "famille", so... we don't need to add it to entity
          demandeur = StoreService.demandeur.set(tiers);

          $scope.aide.demandeur = {
            href: demandeur.id,
            title: demandeur.title,
            expand: demandeur,
          };

          return $scope.saveAide();
        });
      })
      .catch((err) => {
        $scope.alerts = alertsService.getAlertError('connected.config.depot.errors.save');
        $log.error(`[depotSimpleDemandeurFamilleController] StoreService pendingPromises: `, err);
      });
    promises.push(promise);
  }

  // ! we don't need to wait for a created tiers to load the iframe. since tiers iframe just is used to verify siret and send data to parent (portail depot)
  // ! ref-tiers doesn't save data, only "portail depot" does
  $scope.referentielTiersIframeUrl = getVerificationSiretIframeUrl(restrictionFamillesTiers, gestionRattachementCompte);
  // Resize iframe
  iFrameResize(
    {
      heightCalculationMethod: 'lowestElement',
      checkOrigin: false,
      inPageLinks: true,
    },
    '#verification-siret-iframe'
  );

  $scope.iframeElement = _.get(angular.element('#verification-siret-iframe')[0], 'contentWindow');

  $scope.navigate.ns = $scope.familleDemandeurConfiguration.ns;
  $scope.viewConfiguration = $scope.familleDemandeurConfiguration;
  $scope.stepsWizard.steps = $scope.getSimpleSteps();
  $scope.stepsWizard.active = 'tiers';
  window.addEventListener('message', receiveMessageEvent);

  $scope.$on('$destroy', () => {
    window.removeEventListener('message', receiveMessageEvent);
  });

  $scope.navigate.next = () => {
    // Next step
    const isValid = _.get($scope, 'tiersValidation.valid');
    if (isValid) {
      const isKnownTiers = _.get($scope, 'tiersValidation.origin') === 'referentiel';
      const nextStep = isKnownTiers ? 'demandeur-rattachement' : 'demandeur-identification';
      const adresseEtrangere = _.get(demandeur, 'situations[0].etrangere');

      const rnaFilled = globalMetadata.searchTypeDemandeur === 'RNA';

      const metadatas = {
        sirenReadOnly: !adresseEtrangere && !!_.get($scope, 'tiersFound.SIRET.SIREN'),
        nicEditable: adresseEtrangere || !_.get($scope, 'tiersFound.SIRET.SIREN') || rnaFilled,
        codeRnaReadOnly: rnaFilled,
      };

      $scope.depotForm.$error = {};

      $q.all(promises)
        .then(() => $scope.saveDemandeur())
        .then(() => $scope.goToStep(nextStep, undefined, undefined, undefined, metadatas));
    } else {
      $scope.iframeElement && $scope.iframeElement.postMessage({ action: 'click-next' }, '*');
    }
  };

  /**
   * Returns the referentiel-tiers iframe url for siret verification
   */
  function getVerificationSiretIframeUrl(familles, gestionRattachementCompte) {
    const teleservice = _.get($scope, 'aide.teleservice.expand.reference');
    const jwtKey = jwtSessionService.getJwtKey();
    const queryParams = $httpParamSerializer({
      theme: 'demandeur',
      usagers: true,
      ref: demandeur && demandeur.reference,
      boutonSuivant: false,
      jwtKey,
      familles,
      gestionRattachementCompte,
      teleservice,
      adresseEtrangere: true,
      nsResources: 'demandeur-famille',
      searchType: globalMetadata.searchTypeDemandeur,
    });
    const url = `${configuration.tiers.ux}#/verificationsiret?${queryParams}`;
    return $sce.trustAsResourceUrl(url);
  }

  /**
   * Manage the messages we receive from child frames
   * @param msgEvent
   */
  function receiveMessageEvent(msgEvent) {
    const data = msgEvent.data;
    if (data.action !== 'tiersValidation') {
      return;
    }

    $scope.navigate.unlockNavigation();
    $scope.tiersValidation = data;

    // add/reset error in the form to disable/enable the save button
    $scope.depotForm.$error = data.valid ? {} : { invalidTiers: {} };

    // Add search type to global meta.
    // We will need to remove those at validation time.
    globalMetadata.searchTypeDemandeur = data.searchType;

    // override demandeur data if the iframe returns a valid tiers
    // (may return valid message without a tiers to avoid overriding demandeur on load)
    if (data.valid && data.tiers) {
      $scope.tiersFound = data.tiers;

      const apiEntrepriseActive = _.get(publicSettingsTiers, 'apiEntreprise.active');

      const updatedDemandeurFields = { SIRET: data.tiers.SIRET };

      // If tiers's famille is physique and other tiers physiques have the same user,
      // navigation should be stopped because a user can only be linked to one tiers physique
      if (data.tiers.famille.expand.personnaliteJuridique === 'PHYSIQUE' && $scope.tiersPhysiqueAlreadyLinked) {
        $scope.navigate.lockNavigation();
        $scope.depotForm.$error = { invalidTiers: {} };
        // Alert is managed through verification-siret iframe
        $scope.iframeElement && $scope.iframeElement.postMessage({ action: 'invalid-tiers-physique' }, '*');
      }

      if (apiEntrepriseActive) {
        const thematiquesLiees = _.get(demandeur, 'thematiquesLiees', {});
        const newThematiqueAsso = _.get(data, 'tiers.thematiquesLiees.association', {});

        if (!thematiquesLiees.association || !thematiquesLiees.association.expand) {
          _.set(thematiquesLiees, 'association.expand', {});
        }

        const newAssoInfos = newThematiqueAsso.expand || {};
        const oldAssoInfos = thematiquesLiees.association.expand;

        oldAssoInfos.codeRNA = newAssoInfos.codeRNA;
        oldAssoInfos.dateModificationRNA = newAssoInfos.dateModificationRNA;
        oldAssoInfos.datePublicationJO = newAssoInfos.datePublicationJO;
        oldAssoInfos.descriptionActivites = newAssoInfos.descriptionActivites;

        _.assign(updatedDemandeurFields, {
          raisonSociale: data.tiers.raisonSociale,
          referenceAdministrative: data.tiers.referenceAdministrative,
          situations: data.tiers.situations,
          NAF: data.tiers.NAF,
          NAFNiv732: data.tiers.NAFNiv732,
          externalData: data.tiers.externalData,
          enseigne: data.tiers.enseigne,
          thematiquesLiees,
        });
      } else {
        const situations = _.get(demandeur, 'situations');
        const situationPrincipale = _.find(situations, { principale: true });
        const currentSituationPaysCode = _.get(situationPrincipale, 'geographique.Pays.code');
        const updatedSituationPaysCode = _.get(data.tiers, 'situations[0].geographique.Pays.code');
        const doublonFound = data.tiers.id;

        // reset situations when domiciliation changes if api entreprise is not active
        if (currentSituationPaysCode !== updatedSituationPaysCode || !doublonFound) {
          updatedDemandeurFields.situations = data.tiers.situations;
        }
      }
      updatedDemandeurFields.sansSIRET = _.get(data.tiers, 'sansSIRET');

      // get the expanded famille from mdm
      // (the expanded version from iframe is missing some informations needed for the depot)
      updatedDemandeurFields.famille = _.find(
        $scope.mdm.famillestiers.array,
        (famille) => famille.href === _.get(data.tiers, 'famille.href')
      );

      // add famille pieces to the tiers
      // (not done by the referentiel-tiers middleware because the tiers is created without any famille)
      tiersService.addFamillePiecesToTiers(updatedDemandeurFields);

      const newDemandeur = new Tiers(_.assign({}, demandeur, updatedDemandeurFields));

      // call the function of famille change to update the tiers
      tiersService.changeFamilleTiers(newDemandeur, $scope.currentUser, $scope.mdm, $scope.masterdata);
      demandeur = StoreService.demandeur.set(newDemandeur);
      $scope.aide.demandeur.expand = demandeur;
    }

    // Manually set $error to form to disable/enable save button and then use $scope.$apply()
    // to force angular to trigger watcher because this function isn't called from angularjs
    $scope.$apply();
  }
}
