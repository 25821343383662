/**
  * @ngdoc module
  * @module i18n
  * @area lib
  * @name translate
  * @description
  *
  *   This controller is the i18n module
  *
 */
angular.module('i18n', [
	'ngSanitize',
	'mgcrea.ngStrap.select',
	'pascalprecht.translate',
	'configuration',
	'i18n.localization',
	'i18n.translate',
	'i18n.directives'
	])
	.config(['$translateProvider', 'translateLoaderModuleProvider', 'localizationServiceProvider', 'configuration',
		function($translateProvider, translateLoaderModuleProvider, localizationServiceProvider, configuration) {
			'use strict';

			// Get the locale
			var locale = localizationServiceProvider.getActiveLocale();

			// Configuration of angular-translate for the entire application.
			if (configuration.i18n.pattern) {
				$translateProvider.useLoader('translateLoaderModule', {
					templatesUrl: configuration.i18n.pattern,
					teleservice: configuration.i18n.teleservice
				});
			}

			// Configuration of the culture of kendo if the var exist
			if (window.hasOwnProperty('kendo')) {
				kendo.culture(locale);
			}

			$translateProvider.use(locale);
		}
	]);

angular.module('i18n.directives', ['i18n.directives.language-selection']);

/**
  * @ngdoc module
  * @module i18n
  * @area lib
  * @name i18n.languageSelection
  * @description
  *
  *   This controller is the i18n languageSelection directive
  *
 */
angular.module('i18n.directives.language-selection', ['i18n.localization.service']).directive('languageSelection', [

  function() {
    'use strict';

    return {
      restrict: 'A',
      replace: true,
      transclude: false,
      templateUrl: 'language-selection.tpl.html',
      scope: {
        languages: '=',
        placeholder: '@'
      },
      controller: ['$scope', '$window', 'localizationService',
        function($scope, $window, localizationService) {

          // Initialize language
          $scope.selectedLanguage = localizationService.getActiveLocale();
          localizationService.initializeLanguage($scope.selectedLanguage);

          // Change the language of the application
          $scope.changeLanguage = function(selectedLanguage) {
            localizationService.changeLanguage(selectedLanguage);
          };
        }
      ]
    };
  }
]);

/**
  * @ngdoc module
  * @module i18n
  * @area lib
  * @name i18n.templateCache
  * @description
  *
  *   This controller is the i18n templateCache
  *
 */
angular.module('i18n.directives.language-selection').run(['$templateCache',
	function($templateCache) {
		'use strict';

		$templateCache.put('language-selection.tpl.html',
			'<button type="button" class="btn btn-sm btn-primary" ng-change="changeLanguage(selectedLanguage)" placeholder="{{placeholder}}"' +
						  'ng-model="selectedLanguage" data-html="1"'+
						  'ng-options="language.value as language.label for language in languages" bs-select>'+
			    '<span class="caret"></span>'+
			'</button>');
	}
]);

/**
  * @ngdoc module
  * @module i18n
  * @area lib
  * @name localization.tmhDynamicLocaleProvider
  * @description
  *
  *   This controller is the i18n tmhDynamicLocaleProvider
  *
 */
angular.module('i18n.localization', ['tmh.dynamicLocale', 'configuration', 'i18n.localization.service'])
	.config(['tmhDynamicLocaleProvider', 'configuration',
		function(tmhDynamicLocaleProvider, configuration) {
			'use strict';
			tmhDynamicLocaleProvider.localeLocationPattern(configuration.i18n['angular-i18n'].path + '/angular-locale_{{locale}}.js');
		}
	]);

/**
  * @ngdoc module
  * @module i18n
  * @area lib
  * @name localization.localizationService
  * @description
  *
  *   This controller is the i18n localizationService service
  *
 */
angular.module('i18n.localization.service', ['configuration', 'tmh.dynamicLocale']).provider('localizationService', ['configuration',

	function(configuration) {

		'use strict';

		var getLocaleBrowser = function() {
			var nav = window.navigator;
			var locale = ((
				nav.language ||
				nav.browserLanguage ||
				nav.systemLanguage ||
				nav.userLanguage
			) || '').split('-').join('_');

			if (locale.length > 2) {
				locale = locale.substring(0, 2);
			}

			return angular.lowercase(locale);
		};

		var getLocaleInCache = function() {
			return window.localStorage.getItem('i18n.locale');
		};

		this.getActiveLocale = function() {
			return getLocaleInCache() || configuration.i18n.defaultLocale || getLocaleBrowser();
		};

		this.$get = ['$q', 'tmhDynamicLocale',
			function($q, tmhDynamicLocale) {
				return {
					getActiveLocale: function() {
						return getLocaleInCache() || getLocaleBrowser();
					},
					initializeLanguage: function(language) {
						var deferred = $q.defer();

						tmhDynamicLocale.set(angular.lowercase(language)).then(function(results) {
							window.localStorage.setItem('i18n.locale', language);
							deferred.resolve(results);
						});

						return deferred.promise;
					},
					changeLanguage: function(selectedLanguage) {
						window.localStorage.setItem('i18n.locale', selectedLanguage);
						window.location.reload();
					}
				};
			}
		];

	}
]);

/**
  * @ngdoc module
  * @module i18n
  * @area lib
  * @name translate
  * @description
  *
  *   This controller is the i18n translate module
  *
 */
angular.module('i18n.translate', ['i18n.translate.translateLoaderModule', 'i18n.translate.translateLoaderDynamic']);

/**
 * @ngdoc module
 * @module i18n
 * @area lib
 * @name translate.translateLoaderModule
 * @description
 *
 *   This controller is the i18n translateLoaderModule
 *
 */
angular.module('i18n.translate.translateLoaderModule', []).provider('translateLoaderModule', [

  function () {
    'use strict';

    function isStringValid(str) {
      return angular.isString(str) && str !== '';
    }

    /**
     * Function who replace key in url template with values from context
     * @param templateUrl Url template
     * @param context Context
     * @returns {*}
     */
    function parseUrlWithContext(templateUrl, context) {

      for (var property in context) {
        if (context.hasOwnProperty(property)) {
          var regExp = new RegExp('{' + property + '}', 'g');
          templateUrl = templateUrl.replace(regExp, context[property]);
        }
      }

      return templateUrl;
    }

    /**
     * Definition of a module
     * @param {Module} name Name of the module. Constructor
     */
    function Module(name) {
      this.name = name;
      this.translations = {};
    }

    Module.prototype.parseUrl = function (templateUrl, lang, context) {
      templateUrl = templateUrl.replace(/\{module\}/g, this.name).replace(/\{lang\}/g, lang);
      templateUrl = parseUrlWithContext(templateUrl, context);
      return templateUrl;
    };

    Module.prototype.getTranslations = function (lang, $q, $http, templatesUrl, context) {

      var deferred = $q.defer();

      if (angular.isArray(templatesUrl)) {

        if (!this.translations[lang]) {

          var that = this;

          var promises = [];
          templatesUrl.forEach(function (templateUrl) {
            templateUrl = templateUrl = that.parseUrl(templateUrl, lang, context);
            if (templateUrl.indexOf('{') === -1) {
              promises.push($http({
                method: 'GET',
                url: templateUrl
              }).catch(function () {
                return {};
              }));
            }
          });

          $q.all(promises).then(function (results) {

            var translations = {};
            results.forEach(function (result) {
              $.extend(true, translations, result.data);
            });

            that.translations[lang] = translations;

            deferred.resolve(translations);

          }, function () {
            deferred.reject(that.name);
          });

        } else {
          deferred.resolve(this.translations[lang]);
        }
      }

      return deferred.promise;
    };

    // List of modules loaded in the application
    var modules = {};

    /**
     * Verify is the module has been loaded at the application level
     * @param {String} name Name of the module
     * @return {Boolean} Indicates whether the module has been loaded
     */
    function hasModule(name) {
      return modules.hasOwnProperty(name);
    }

    /**
     * Add the module to the list
     * @param {String} name Name of the module
     */
    this.addModule = function (name) {
      if (!isStringValid(name)) {
        throw new TypeError('Couldn\'t add module, module name has to be a string!');
      }
      if (!hasModule(name)) {
        modules[name] = new Module(name);
      }

      modules[name].isActive = true;
    };

    /**
     * Delete the module of the list
     * @param  {String} name Name of the module
     */
    this.deleteModule = function (name) {
      if (!isStringValid(name)) {
        throw new TypeError('Couldn\'t delete module, first arg has to be string.');
      }
      if (hasModule(name)) {
        modules[name].isActive = false;
      }
    };

    var teleservices = {};

    function getTeleserviceTranslations(lang, $q, $http, teleservice, workflow, templatesUrl, context) {
      var deferred = $q.defer();

      if (teleservices[teleservice] && teleservices[teleservice][lang]) {
        deferred.resolve(teleservices[teleservice][lang]);
      } else {
        var promises = [];

        templatesUrl.forEach(function (templateUrl) {

          templateUrl = parseUrlWithContext(templateUrl, context);
          templateUrl = templateUrl.replace(/\{workflow\}/g, workflow).replace(/\{teleservice\}/g, teleservice).replace(/\{lang\}/g, lang);

          if (templateUrl.indexOf('{') === -1) {
            promises.push($http({
              method: 'GET',
              url: templateUrl
            }).catch(function () {
              return {};
            }));
          }
        });

        $q.all(promises).then(function (results) {

          var translations = {};
          results.forEach(function (result) {
            $.extend(true, translations, result.data);
          });

          teleservices[teleservice] = teleservices[teleservice] || {};
          teleservices[teleservice][lang] = translations;

          deferred.resolve(translations);

        }, function () {
          deferred.reject(teleservice);
        });
      }

      return deferred.promise;
    }

    this.$get = ['$q', '$http',

      function ($q, $http) {

        var _context = {};

        var service = function (options) {

          var deferred = $q.defer();

          var loaders = [];
          for (var module in modules) {
            if (hasModule(module) && modules[module].isActive) {
              loaders.push(modules[module].getTranslations(options.key, $q, $http, options.templatesUrl, _context));
            }
          }

          // Also fetch translations for the current teleservice if defined
          if (options.teleservice && options.teleservice.id && options.teleservice.workflow && options.teleservice.pattern) {
            loaders.push(getTeleserviceTranslations(options.key, $q, $http, options.teleservice.id, options.teleservice.workflow, options.teleservice.pattern, _context));
          }

          if (loaders.length) {
            $q.all(loaders).then(function (translations) {
              var translationMap = {};
              for (var i = 0; i < translations.length; i++) {
                $.extend(true, translationMap, translations[i]);
              }
              deferred.resolve(translationMap);
            }, function () {
              deferred.reject(options.key);
            });
          } else {
            deferred.resolve({});
          }
          return deferred.promise;
        };

        service.updateContext = function (context) {
          _context = context;
        };

        return service;
      }
    ];
  }
]);
/**
 * @ngdoc module
 * @module i18n
 * @area lib
 * @name translate.translateLoaderDynamic
 * @description
 *
 *   This controller is the i18n translateLoaderDynamic
 *
 *   It should replace the translateLoaderModule which was charge with some logic required when merging resources
 *   from client side component.
 *   This one fetches already merged resources from a service, but it has the capacity to load translations at runtime.
 *   This is useful in multi-tenant applications
 *
 */
angular.module('i18n.translate.translateLoaderDynamic', []).provider('translateLoaderDynamic', [

  function() {
    'use strict';

    // angular-translate will use this function to fetch translations when refreshed
    this.$get = ['$q', '$http', '$log', function($q, $http, $log) {

      var _context = {};

      // simili mustache
      function _applyContextToPattern(pattern) {
        Object.keys(_context).forEach(function(contextKey) {
          pattern = pattern.replace(new RegExp('{{' + contextKey + '}}', 'g'), _context[contextKey]);
        });

        if (pattern.indexOf('{{') !== -1) {
          $log.debug('i18n.translate.translateLoaderService pattern not fully resolved (missing var)', pattern);
          return null;
        }

        return pattern;
      }

      // loader is a function as expected by angular translate
      var loader = function(options) {
        if (!options.patterns) {
          $log.error('i18n.translate.translateLoaderService options require a key "patterns"');
          return;
        }

        _context.locale = options.key;

        var urls = options.patterns.map(_applyContextToPattern);
        urls = urls.filter(function(url) {
          return !!url;
        });

        var promises = [];
        urls.map(function(url) {
          promises.push($http.get(url).then(function(response) {
            if (response.status !== 200) {
              throw 'i18n.translate.translateLoaderService Failed to fetch ' + url + ' with error ' + response.statusCode + ' - ' + response.data;
            }

            return response.data;
          }));
        });

        var translations = {};
        return $q.all(promises).then(function(responses) {
          responses.forEach(function(response) {
            $.extend(true, translations, response);
          });

          return translations;
        });

      };

      // but loader is also an object with another function as attribute
      loader.updateContext = function(context) {
        $log.debug('i18n.translate.translateLoaderService update context', context);
        _context = context;
      };

      return loader;

    }];
  }

]);
