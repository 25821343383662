angular.module('portailDepotDemandeAide.depot').controller('domiciliationBancaireConfiguration', [
  '$scope',
  '$modal',
  'aidesService',
  'contributionsService',
  'depotSimpleService',
  'configuration',
  function($scope, $modal, aidesService, contributionsService, depotSimpleService, configuration) {
    'use strict';

    // Persistence - Save the persistence when we add a new document on domiciliation piece or update/delete a document
    $scope.savePersistence = function() {
      if ($scope.contribution) {
        contributionsService.saveContribution($scope.aide, $scope.contribution);
      } else {
        aidesService.update($scope.aide);
      }
    };

    // Recherche du beneficiaire
    // Pour l'instant on rammène un objet vide car on créé le bénéficiaire à chaque fois.
    $scope.beneficiaire = $scope.aide.demandeurEtBeneficiaire === false ? {} : $scope.tiers;

    // Steps Wizard
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'dossier';

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.noform = false;
    $scope.navigate.ns = $scope.domiciliationBancaireConfiguration.ns;
    $scope.domiciliationBancaireConfiguration.showErrors = $scope.showErrorsOnNavigate();
    $scope.domiciliationBancaireConfiguration.lockNavigation = () => {
      $scope.navigate.lock++;
    };

    $scope.domiciliationBancaireConfiguration.unlockNavigation = () => {
      $scope.navigate.lock = Math.max(0, $scope.navigate.lock - 1);
    };

    // Tri des domiciliations : en premier la principale et ensuite on tri par titulaire
    _.get($scope, 'beneficiaire.domiciliationsBancaires', []).sort((a, b) => {
      if (a.principale && !b.principale) {
        return -1;
      }
      if (b.principale && !a.principale) {
        return 1;
      }
      return a.titulaire.localeCompare(b.titulaire);
    });

    $scope.navigate.next = (forget) => {
      // Copy domiciliation bancaire document
      if (!$scope.createDom && $scope.selectedDom >= 0) {
        _.each($scope.aide.domiciliationBancaire.pieces, (piece) => {
          _.each(piece.documents, (documentPiece) => {
            aidesService.copyDocumentDomiciliationBancaireTiers(
              $scope.urlDocuments,
              documentPiece,
              'aide',
              $scope.aide
            );
          });
        });
      }
      // The user created a new domiciliation but had selection displayed
      else if ($scope.showDomiciliations) {
        // Search for a correlation in tier's domiciliations
        const compareTo = $scope.aide.domiciliationBancaire.horsSEPA ? 'horsSEPA[0].valeur' : 'IBAN.numero';
        const correlationFound = _.findIndex(
          _.filter($scope.beneficiaire.domiciliationsBancaires, { actif: true }),
          (domLink) => _.get(domLink, compareTo) === _.get($scope.aide.domiciliationBancaire, compareTo)
        );

        // If a correlation is found, alert user that tiers' already has this domiciliation
        if (correlationFound > -1) {
          // displays confirmation Modal
          const scopeModal = $scope.$new();

          scopeModal.namespace = $scope.navigate.ns;
          // On validation, select the tiers' domiciliation
          scopeModal.validate = (modal) => modal.$hide();

          $modal({
            scope: scopeModal,
            template: 'depot/simple/domiciliation-bancaire/modal/correlation-confirm.html',
          });
          // Display the modal without going to next page
          return;
        }
      }
      // Go to next page
      $scope.goToStep('pieces', forget);
    };

    // Page Options
    $scope.pageOptions = _.get($scope.teleserviceConfiguration, 'workflow.pageDomiciliationBancaire', {});
    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    }

    // Url documents
    $scope.urlDocuments = $scope.aide._links['mgs:documents'].href;

    // Validation Banque de France
    $scope.bdfFile = _.get(configuration, 'domiciliationBancaire.banqueDeFrance');
    $scope.validationIbanFr = $scope.bdfFile && _.get(configuration, 'demande-paiement.validationIbanFr');

    // Domiciliation defined by user
    let newDomiciliation;

    // Add persistenceConfiguration to allowedExtensions
    $scope.persistenceConfiguration = _.get($scope.teleserviceConfiguration, 'persistenceConfiguration.expand', {});

    /**
     * Select domiciliation from existing one
     * @param  {number} index Index of domiciliations array
     */
    $scope.selectDomiciliation = (index) => {
      // If we leave the creation form, we keep the data somewhere
      if ($scope.selectedDom === -1 && $scope.aide.domiciliationBancaire && !$scope.aide.domiciliationBancaire.id) {
        newDomiciliation = $scope.aide.domiciliationBancaire;
      }
      $scope.aide.domiciliationBancaire = $scope.beneficiaire.domiciliationsBancaires[index];
      $scope.selectedDom = index;
      $scope.createDom = false;
    };

    $scope.createDomiciliation = () => {
      $scope.aide.domiciliationBancaire = newDomiciliation;
      $scope.selectedDom = -1;
      $scope.createDom = true;
    };

    // Display active domiciliations from tiers
    if (_.get($scope.beneficiaire, 'domiciliationsBancaires')) {
      const domiciliationBancairesActives = _.filter($scope.beneficiaire.domiciliationsBancaires, (dom) => {
        return dom.actif;
      });
      // If user's tiers has domiciliations, the user can pick one of them
      $scope.showDomiciliations = domiciliationBancairesActives.length > 0;
    }

    $scope.kind = $scope.contribution ? 'contribution' : 'aide';
    $scope.createDom = true;
    $scope.selectedDom = -1;

    // If there are domiciliations, we search the selected one
    if ($scope.showDomiciliations) {
      let indexExistingDb, indexSelectedDb;

      if ($scope.aide.domiciliationBancaire) {
        indexExistingDb = _.findIndex(
          $scope.beneficiaire.domiciliationsBancaires,
          (domLink) => domLink.id === _.get($scope, 'aide.domiciliationBancaire.id', '')
        );
      } else {
        // Par défaut, on sélectionne la domiciliation principale si elle active
        indexSelectedDb = _.findIndex(
          $scope.beneficiaire.domiciliationsBancaires,
          (domLink) => domLink.principale && domLink.actif
        );
        // Sinon on sélectionne la première
        if (indexSelectedDb < 0) {
          indexSelectedDb = _.findIndex($scope.beneficiaire.domiciliationsBancaires, (domLink) => domLink.actif);
        }
      }

      const selectedIndex = $scope.aide.domiciliationBancaire ? indexExistingDb : indexSelectedDb;

      // The first time the page is loaded, the first domiciliation (0) is selected
      if (selectedIndex > -1) {
        $scope.selectDomiciliation(selectedIndex);
      }
    }

    // Principale domiciliation has a status that show a label
    $scope.principaleStatus = { label: $scope.navigate.ns + '.list.item.principal', class: 'success' };
  },
]);
