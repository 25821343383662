angular.module('common.directives').directive('pageTitle', [
  function() {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'common/common-directives/page-title/page-title.html',
      scope: {
        pageConfigurationKey: '=',
        state: '=',
      },
      link: function($scope) {
        $scope.$on('printJustification', function(e, workflowHeader) {
          $scope.print = {
            urlLogo: workflowHeader.urlLogo || false,
          };
        });
      },
    };
  },
]);
