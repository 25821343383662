/**
 * @ngdoc service
 * @name common.services.teleservicesService
 * @requires $http, $q, dataDocService, Piece
 * @description Interface for a Tiers' pieces
 **/
angular.module('common.services').factory('teleservicesService', [
  '$state',
  '$http',
  '$q',
  '$log',
  'configuration',
  function($state, $http, $q, $log, configuration) {
    'use strict';

    let _tenantId;
    let _url = _.get(configuration, 'teleservices.url') || '/referentiel-financement';

    // Configure either at provider level or directly service
    this.tenantId = function(tenantId) {
      _tenantId = tenantId;
    };
    this.url = function(url) {
      _url = url;
    };

    function _getUrl() {
      return _tenantId ? _url + '/' + _tenantId : _url;
    }

    /**
     * In a teleservice, replace a list of fields by a hierarchy of fields, sorted by their references
     * @param {object} teleservice Teleservice containing the list of fields
     * @param {string} category Path where we can find the list of fields
     */
    const deepSetFields = function(teleservice, category) {
      const fieldsList = _.get(teleservice, category + '.fields');

      if (fieldsList) {
        // Replacing the list with an empty dictionary
        _.set(teleservice, category, {});
        const fieldsConf = _.get(teleservice, category);
        // For each field, we place it under the name of the page it's in, and 'fields'
        _.each(fieldsList, function(field) {
          _.set(fieldsConf, field.reference.replace(/\.([^.]+)$/, '.fields.$1'), field);
        });
      }
    };

    /**
     * Apply a transformation to a teleservice definition that will make it much easier to use in the portals
     */
    const transform = function(teleservice) {
      // Create an arborescent structure to describe all fields
      _.each(['demandeur', 'beneficiaire', 'aide'], _.partial(deepSetFields, teleservice));

      return teleservice;
    };

    return {
      /**
       * Check if the given tiers have access to the given teleservice
       * @param  {string} tiers                     JSON string representation of the tiers
       * @param  {string} teleserviceConfiguration  JSON string representation of the teleservice configuration
       * @return {boolean}                          Indicated if the tiers can access to the teleservice
       */
      checkFamilleTeleservice: function(tiers, teleserviceConfiguration) {
        let access = false;

        const tiersFamille = tiers.famille.href;
        if (tiersFamille) {
          const teleserviceFamilles = _.get(teleserviceConfiguration, 'workflow.pageInformationsDemandeur.familles');
          if (!_.isEmpty(teleserviceFamilles)) {
            access = _.find(teleserviceFamilles, function(teleserviceFamille) {
              return tiersFamille === teleserviceFamille.famille.href;
            });
          }
        }

        return access;
      },

      /**
       * Get the teleservices list. If a tiers is already created, the returned list will be filtered with only
       * authorized teleservices for this tiers. If a workflow is given in parameter, the teleservices list will
       * also be filtered for the given workflow
       * @param {object} tiers     JSON string representation of the tiers associated with the connected user
       * @param {string} workflowType  JSON string representation of the workflow
       * @param {array} sources list of fields to pick
       * @param {object} filters additionnal filters
       * @param {boolean} filters.noInvitation hide the teleservices with "surInvitation" option if true
       * @return {Promise<array>}  The teleservices list, filtered by tiers and workflow if requested
       */
      getListTeleServices: (tiers, workflowType = 'simple', sources = [], filters = {}) => {
        const body = {
          from: 0,
          size: 1000,
          sources: ['reference', ...sources],
          workflow: {
            type: workflowType,
          },
        };

        const famille = _.get(tiers, 'famille.href');
        if (famille) {
          body.famille = famille;
        }

        if (filters.noInvitation) {
          body.noInvitation = filters.noInvitation;
        }

        const url = `${_getUrl()}/teleservices/search`;
        return $http.post(url, body).then(({ data }) => {
          const teleservices = _.get(data, 'hits.hits', []).map(({ _source }) => _source);
          return teleservices;
        });
      },

      /**
       * Returns the teleservice whose id is given in parameter
       * @param  {string} teleserviceId The id of the teleservice to fetch
       * @param  {boolean} inactive      Indicates if we want to fetch inactive teleservice
       * @return {string}               JSON string representation of the teleservice
       */
      getTeleService: function(teleserviceId, inactive, queryParams) {
        if (!teleserviceId) return;

        const params = _.assign(
          {
            merged: true,
          },
          queryParams
        );

        if (!inactive) {
          params.active = true;
        }

        const url = `${_getUrl()}/teleservices/${teleserviceId}`;

        return $http
          .get(url, {
            params: params,
          })
          .then(({ data }) => transform(data))
          .catch((error) => {
            $log.error(error);
            $state.go('app.connected.dashboard.accueil', {
              teleServiceNotFound: true,
            });
          });
      },
      /**
       * Return a teleservice extension by using filter
       * @param {string} filter : hrefTeleservice and hrefGroupeGestion (can be extended)
       */
      getTeleserviceExtension: function(filter) {
        const url = `${_getUrl()}/teleservices-extension`;

        const queryParams = {
          $filter: `teleservice/href eq '${filter.hrefTeleservice}' and groupeGestion/href eq '${filter.hrefGroupeGestion}'`,
        };

        return $http
          .get(url, { params: queryParams })
          .then((response) => _.get(response, 'data._embedded.items[0]'))
          .catch((error) => {
            $log.error(error);
            $state.go('app.connected.dashboard.accueil', {
              teleServiceNotFound: true,
            });
          });
      },
      getDispositif: function(url) {
        if (url) {
          return $http
            .get(url)
            .then(function(response) {
              return response.data;
            })
            .catch(function(error) {
              $log.error(error);
              $state.go('app.connected.dashboard.accueil', {
                teleServiceNotFound: true,
              });
            });
        } else {
          return;
        }
      },
      /**
       * Get the teleservice from the given aide parameter
       * @param  {string} object JSON string representation of an aide
       * @param  {boolean} getLastVersion true if you want the last teleservice version
       * @return {string} JSON string representation of the teleservice
       */
      getTeleServiceFromObject: function(object, getLastVersion = false) {
        var urlTeleservice = object.teleservice.href;
        if (urlTeleservice) {
          // Retrieve the latest version of the teleservice instead of the revision if the variable 'getLastVersion' is true
          let teleserviceId = urlTeleservice.split('/').pop();
          teleserviceId = getLastVersion ? teleserviceId.split('?date=')[0] : teleserviceId;

          return this.getTeleService(teleserviceId, true).then((teleservice) => {
            // Here the value of the element "echangesActif" is modified to ensure compatibility of behavior between versions.
            // If the 'echangesActif' is undefind all exchange related features should be accessible on the teleservice,
            // so the 'undefined' is changed to true
            if (_.isUndefined(teleservice.echangesActif)) teleservice.echangesActif = true;

            return teleservice;
          });
        } else {
          return $q.when(null);
        }
      },

      /**
       * Returns the specifiques infos from given teleservices, indexed by reference
       * @param  {string} teleservice JSON string representation of the teleservice
       * @return {string}             JSON string representation of the specifiques infos
       */
      getTeleservicesSpecifiques: function(teleservice) {
        return _.keyBy(teleservice.specifiques, 'reference');
      },

      /**
       * Check if teleservice is open or closed
       * notOpenYet is false if today is same as dateDebut or after
       * alreadyClosed is false if today is same as dateFin or before
       * @param {object} ts teleservice
       * @param {object} aide aide
       * @returns {{notOpenYet: boolean, alreadyClosed: boolean}} both false if tiers can submit his demande
       */
      controlDatesTeleservice: function(ts, aide) {
        // Eliminer l'éventuelle révision
        const refTeleservice = ts.reference.split('?')[0];
        const idTeleservice = ts.id;
        const financeur = _.get(aide, 'financeurPrincipal');
        const service = this;
        let promise;
        let dates = {
          notOpenYet: true,
          alreadyClosed: true,
        };
        if (financeur) {
          promise = service
            .getTeleserviceExtension({
              hrefTeleservice: idTeleservice,
              hrefGroupeGestion: _.get(financeur, 'groupesGestion.0.href'),
            })
            .then(function(extension) {
              if (extension) {
                return service.getTeleService(refTeleservice, null, {
                  teleserviceExtension: extension.id,
                });
              }
              return service.getTeleService(refTeleservice);
            });
        } else {
          promise = service.getTeleService(refTeleservice);
        }

        return promise
          .then(function(teleservice) {
            if (new Date() >= new Date(teleservice.dateDebut)) {
              dates.notOpenYet = false;
            }
            if (new Date() <= new Date(teleservice.dateFin)) {
              dates.alreadyClosed = false;
            }
            return dates;
          })
          .catch(function(err) {
            $log.error('Une erreur est survenue lors du chargement du téléservice courant', err);
          });
      },
    };
  },
]);
