angular.module('common.services').factory('agreementService', [
  '$http',
  'configuration',

  function($http, configuration) {
    'use strict';

    var _urlAgreementList = urljoin(configuration.user.accountManagement, 'agreements');

    return {
      /** Récupération de la liste des agreements */
      getList: function(tenantId) {
        return $http
          .get(_urlAgreementList.replace('{{tenantId}}', tenantId))
          .then(function(response) {
            return response.data.results;
          })
          .catch(function(err) {
            throw new Error('Fail list agreement ', err);
          });
      },
      /** Màj de la liste des agreements du user */
      updateList: function(userHref, agreements) {
        var bodyPartialUpdate = {
          agreements: agreements,
        };

        // Patch d'un user dans account-management, il s'agit d'un "partial update"
        return $http
          .patch(userHref, bodyPartialUpdate)
          .then(function(response) {
            return _.get(response, 'data');
          })
          .catch(function(err) {
            throw err;
          });
      },
    };
  },
]);
