angular.module('aides.services').factory('demandesAidesService', [
  '$q',
  '$http',
  '$translate',
  'teleservicesService',
  'avisPrealablesService',
  'suiviFinancementService',
  'dateFilter',
  'Aide',
  'aidesService',
  'dossiersService',
  'AidesConstant',
  function(
    $q,
    $http,
    $translate,
    teleservicesService,
    avisPrealablesService,
    suiviFinancementService,
    dateFilter,
    Aide,
    aidesService,
    dossiersService,
    AidesConstant
  ) {
    'use strict';

    /**
     * Return last decision event from ligne financement
     * @param {*} ligneFinancement
     */
    function getDecisionEvent(ligneFinancement) {
      return _.find(_.get(ligneFinancement, 'financement.history.events', []), {
        type: 'DECISION',
      });
    }

    const ORDERED_STATUSES = AidesConstant.dossierStatuts.ORDERED_STATUSES;

    return {
      fillTeleservicePaiementInDemande: function(demande) {
        if (demande.teleservicePaiement) {
          return $http.get(demande.teleservicePaiement).then(function(response) {
            var teleservicePaiement = response.data;
            if (teleservicePaiement.statut === 'PUBLIE') {
              demande.teleservicePaiement = teleservicePaiement;

              // The demande.hasDemandesPaiement flag controls the display of the Paiements button for usagers
              // In the case of a demande that has paiements but gets an avenant and then reaches status FININSTRUCTION
              // we still need to show the Paiements button, so this flag needs to be 'true'
              demande.hasDemandesPaiement = _.includes(['VOTE', 'SOLDE', 'CLOTURE', 'FININSTRUCTION'], demande.state);
            }
            return demande;
          });
        } else {
          return demande;
        }
      },

      /**
       * Function used to identify if the "aide" is with multi financeurs without financeur principal.
       * @param  {object} aide Aide
       * @return {boolean} True is if the "aide" is with multi financeurs without financeur principal.
       */
      isMultiFinanceursWithoutFinanceurPrincipal: function(aide) {
        return _.get(aide, 'multiFinanceur') && _.isEmpty(aide.financeurPrincipal);
      },

      /**
       * Méthode qui permet de récupérer la ligne de financement éligible
       * à un dispositif de la région.
       * @param  {object} aide Aide
       * @return {object}      Ligne financement eligible
       */
      findLigneFinancementDispositifEligible: function(aide) {
        const [ligneFinancementDispositifEligible] = JSONPath(
          '$.planFinancement..recette..lignes..[?(@.dispositifEligible && @.dispositifEligible.href && @.financement && @.financement.autorisationDemandesPaiement)]',
          aide
        );
        return ligneFinancementDispositifEligible;
      },

      /**
       * Get all the lignes financement in the demande
       * @param  {object} aide Aide
       * @return {array}       Lignes financement
       */
      findLignesFinancement: function(aide) {
        const lignesFinancement = JSONPath(
          '$.planFinancement..recette..lignes..[?(@.financement && @.financement.source && @.financement.source.href)]',
          aide
        );
        return lignesFinancement;
      },

      /**
       * Get the most advanced ligne financement in the demande depending on their displayed status
       * @param  {object} aide Aide
       * @return {object}      Ligne financement
       */
      findMostAdvancedLigneFinancement: function(aide) {
        const lignesFinancement = this.findLignesFinancement(aide);
        lignesFinancement.sort((ligne1, ligne2) => {
          const status1 = dossiersService.getStatusToDisplay(ligne1);
          const status2 = dossiersService.getStatusToDisplay(ligne2);
          return ORDERED_STATUSES.indexOf(status2) - ORDERED_STATUSES.indexOf(status1);
        });
        return lignesFinancement[0];
      },

      /**
       * Get the first ligne financement in the demande
       * @param  {object} aide Aide
       * @return {object}      Ligne financement
       */
      findLigneFinancement: function(aide) {
        return this.findLignesFinancement(aide)[0];
      },

      /**
       * Duplicate a demand and return the new demand
       */
      duplicateDemand: function(aide) {
        var url =
          '/aides/api/tenants/' + _.get(aide, 'tenant') + '/demandes-financement/' + aide.reference + '/duplicate';
        return $http.post(url).then(function(response) {
          return new Aide(response.data);
        });
      },

      /**
       * Méthode qui permet de récupérer la ligne de financement du financeur
       * à un dispositif de la région.
       * @param  {object} aide Aide
       * @param  {object} financeurHref financeurHref
       * @return {object}      Ligne de financement
       */
      findLigneFinancementFinanceur: function(aide, financeurHref) {
        var lignePF;
        _.each(aide.planFinancement, function(planFinancement) {
          var postes = _.get(planFinancement, 'recette.postes');
          _.each(postes, function(poste) {
            _.each(_.get(poste, 'lignes'), function(ligne) {
              if (_.get(ligne, 'financement.financeur.href') === financeurHref) {
                lignePF = ligne;
              }
            });
          });
        });

        return lignePF;
      },

      /**
       * Get a list of demandes-financement
       * ! FIXME simplify this function, 200 lines that's too much
       * @param {Array} aides Aides
       * @param {Object} tiers Tiers
       * @return {Array} demandes
       */
      getListeDemandes: function(aides, tiers) {
        // Creating demande objects to display
        const displayedDemandes = [];

        // We browse transmitted aides
        (aides || []).forEach((aide) => {
          const demande = {
            _id: aide.id,
            object: _.get(aide, 'libelle.value') || _.get(aide, 'objet.value'),
            state: aide.status,
            statut: aide.statut,
            createdOn: _.get(aide, 'history.begin.date'),
            lastUpdate:
              aide.history && aide.history.events && _.get(aide.history.events[aide.history.events.length - 1], 'date'),
            transmitted: true,
            reference: aide.reference,
            referenceAdministrative: aide.referenceAdministrative,
            aide: aide,
            demandeurId: _.get(aide, 'demandeur.expand.id'),
            contributionsPourModification: aide.contributionsPourModification,
            contributionsRedirection: aide.contributionsRedirection,
            //Check if the aide was created in espace depot
            createdFromEspaceDepot: _.some(aide.correlations, function(correlation) {
              return correlation.type === 'gestion-depot-demandes:demande-aide';
            }),
            avisPrealableTiers: avisPrealablesService.getAvisPrealableTiers(aide.avisPrealables, tiers),
            aEteTransmise: this.getEtatTransmis(aide),
            avisPrealableDonne: this.getAvisPrealable(aide),
            dateDepotAvisPrealable: this.getDateDepotAvisPrealable(aide),
          };

          // Transmission date
          if (aide.status !== 'REGISTERED') {
            const transmittedEvent = _.find(_.get(aide, 'history.events'), {
              reference: 'TRANSMITTED',
            });
            if (transmittedEvent) {
              demande.transmittedOn = transmittedEvent.date;
            }
          }

          // Supported date
          if (aide.status === AidesConstant.demandeStatuts.SUPPORTED) {
            const supportedEvent = _.find(_.get(aide, 'history.events', []), {
              reference: AidesConstant.demandeStatuts.SUPPORTED,
            });
            if (supportedEvent) {
              demande.supportedOn = supportedEvent.date;
            }
          }

          // Motif refus
          if (aide.status === 'RETURNED') {
            const motifRefus = _.find(aide.conformite.motifs, {
              regle: 'RETURNED',
            });
            if (motifRefus) {
              demande.motif = motifRefus.description.value;
            }
          }

          // Dispositif if the aide has no object
          if (!demande.object) {
            teleservicesService.getTeleServiceFromObject(aide).then(function(teleservice) {
              if (teleservice) {
                demande.object = teleservice.reference;
              }
            });
          }

          // Number of request for complementary pieces
          if (_.get(aide, 'demandesComplementPieces.length') > 0) {
            demande.hasComplements = true;
            demande.nbComplements = _.reduce(
              aide.demandesComplementPieces,
              function(sum, complement) {
                const incr = _.get(complement, 'expand.avancement') === 'TRANSMIS' ? 1 : 0;
                return sum + incr;
              },
              0
            );
          }

          // Instruction
          if (aide.status === 'ACCEPTABLE' && aide.planFinancement) {
            let ligneRegion;
            // Case : multi-dossiers
            if ((aide.dossiersFinancement || []).length > 1) {
              ligneRegion = this.findMostAdvancedLigneFinancement(aide);
            } else {
              // Demande Paiement
              ligneRegion = this.findLigneFinancementDispositifEligible(aide);

              // Ligne financement si aucune ligne de dispositif éligible
              if (!ligneRegion) {
                ligneRegion = this.findLigneFinancement(aide);
              }
            }
            const tsPaiement = _.get(ligneRegion, 'financement.teleservicePaiement.href');
            if (tsPaiement) {
              demande.teleservicePaiement = tsPaiement;
            }
            // Statut du financement
            if (_.get(ligneRegion, 'financement.statut') && !_.get(demande, 'aide.multiFinanceur')) {
              demande.state = dossiersService.getStatusToDisplay(ligneRegion);
              demande.hasCorrelationsV8 = _.find(_.get(demande, 'aide.correlations', []), function(correlation) {
                return _.startsWith(correlation.value, '/connecteur-aides-v9v8');
              });

              // if the "dossier v8" exists, we get the status on this "dossier v8"
              if (demande.hasCorrelationsV8) {
                demande.state = _.get(demande, 'aide.dossiersFinancement[0].expand.statut', demande.state);
              }
            }
          }

          // Justification
          const justificationStatus = _.get(aide, 'justification.statut');
          if (justificationStatus) {
            demande.state = `JUSTIFICATION_${justificationStatus}`;
          }

          // Cloture
          if (_.get(aide, 'cloture.value')) {
            demande.state = AidesConstant.demandeStatuts.CLOTURE;
          }

          // Multi-financeur
          if (aide.multiFinanceur) {
            _.get(demande, 'aide.multiFinancement.financeurs', []).forEach((financeur) => {
              // Formatage du titre du financeur
              if (financeur.title) {
                const tab = financeur.title.split(' ');
                tab.splice(tab.length - 3, 3);
                financeur.libelle = tab.join(' ');
              } else {
                financeur.libelle = '';
              }
              // Historique du financement
              const history = suiviFinancementService.getHistorySuiviFinancementFinanceur(aide, financeur) || {};
              const indexEvent = _.size(history.events) - 1;
              const eventHistory = _.get(history, 'events.' + indexEvent, history.begin);

              // Formatage de la date de l'événement
              if (eventHistory) {
                const dateLabel = dateFilter(eventHistory.date, 'longDate');

                const ns = 'connected.dashboard.aides.list.multiFinanceur';
                if (eventHistory.type === 'DECISION') {
                  eventHistory.summary = $translate.instant(`${ns}.DECISION`, {
                    dateLabel: dateLabel,
                  });
                } else {
                  eventHistory.summary = $translate.instant(`${ns}.EN_COURS_INSTRUCTION`);
                }
              }
              financeur.lastEventHistory = eventHistory;

              // Recuperation de ligne de Financement du financeur
              const ligneFinanceur = this.findLigneFinancementFinanceur(aide, financeur.href);
              financeur.teleservicePaiement = _.get(ligneFinanceur, 'financement.teleservicePaiement.href');

              financeur.canCreateDemandesPaiement =
                !_.isNil(financeur.teleservicePaiement) &&
                _.get(ligneFinanceur, 'financement.autorisationDemandesPaiementDemandeur', false);
            });

            // Also calculate aide.statut for mode "financeur principal"
            if (demande.aide.financeurPrincipal) {
              demande.state = aidesService.statutForFinanceurPrincipal(demande.aide);
            } // update multi-dossiers state
            else if (
              demande.state === AidesConstant.demandeStatuts.ACCEPTABLE &&
              Array.isArray(aide.dossiersFinancement) &&
              aide.dossiersFinancement.length > 1
            ) {
              const mostAdvancedLigne = this.findMostAdvancedLigneFinancement(aide);
              const statut = _.get(mostAdvancedLigne, 'financement.statut');

              // Update the state with the lastest ligne status
              if (statut) demande.state = statut;
            }

            displayedDemandes.push(demande);
          } else {
            // Add the teleserviceDemandePaiement attribute to the demande
            displayedDemandes.push(this.fillTeleservicePaiementInDemande(demande));
          }
        });

        return $q.all(displayedDemandes);
      },

      /**
       * Get transmitted event
       * @param {Object} [aide={}]
       * @returns {Object|null}
       */
      getEtatTransmis: (aide = {}) => {
        const events = aide.history && aide.history.events;
        if (!Array.isArray(events)) return null;

        const filteredEvents = events.filter((event) => {
          return event.type === 'STATUS' && event.reference === 'TRANSMITTED';
        });
        return _.maxBy(filteredEvents, ({ date }) => date);
      },

      /**
       * Get latest avis prealable
       * @param {Object} [aide={}]
       * @returns {Object|null}
       */
      getAvisPrealable: (aide = {}) => {
        if (!Array.isArray(aide.avisPrealables)) return null;

        return _.maxBy(aide.avisPrealables, ({ date }) => date);
      },

      /**
       * Get latest depot avisPrealale event
       * @param {Object} [aide={}]
       * @returns {Object|null}
       */
      getDateDepotAvisPrealable: (aide) => {
        const events = aide.history && aide.history.events;
        if (!Array.isArray(events)) return null;

        const filteredEvents = events.filter((event) => {
          return event.type === 'STATUS' && event.reference === 'REGISTERED';
        });
        const avisPrealableEvent = _.maxBy(filteredEvents, ({ date }) => date);
        return avisPrealableEvent && avisPrealableEvent.date;
      },

      /**
       * Get the last decision event (vote)
       * @param {Object} [aide={}]
       * @returns {Object|null}
       */
      getlastDecision(aide) {
        const events = this.getHistorySuiviFinancementMonoFinanceur(aide);
        if (!Array.isArray(events)) return null;

        const filteredEvents = events.filter((event) => {
          return event.type === 'DECISION';
        });
        return _.maxBy(filteredEvents, ({ date }) => date);
      },

      /**
       * Get financement events fron the current plan financement
       * @param {Object} aide
       * @returns {Array<Object>}
       */
      getHistorySuiviFinancementMonoFinanceur: (aide) => {
        // postes of the current plan financement
        const planFinancement = aidesService.getCurrentPlanFinancement(aide);
        const postesPlanFinancement = _.get(planFinancement, 'recette.postes', []);
        let events = [];

        // get planFinancement financement events
        postesPlanFinancement.forEach((poste) => {
          (poste.lignes || []).forEach((lignePlanFinancement) => {
            if (_.get(lignePlanFinancement, 'financement.history.events')) {
              events.push(...lignePlanFinancement.financement.history.events);
            }
          });
        });

        return events;
      },

      /**
       * GET the voted from the ligne financement
       * @param {object} aide
       * @param {string} financeurHref
       * @returns {number}
       */
      getVotedAmount: function(aide, financeurHref) {
        let ligneFinancement;
        if (financeurHref) {
          ligneFinancement = this.findLigneFinancementFinanceur(aide, financeurHref);
        } else {
          ligneFinancement = this.findLigneFinancement(aide);
        }

        if (!_.has(ligneFinancement, 'financement.montantVote')) {
          const decision = getDecisionEvent(ligneFinancement);
          return _.get(decision.montant, 'ttc', _.get(decision.montant, 'ht'));
        }
        return _.get(
          ligneFinancement,
          'financement.montantVote.ttc',
          _.get(ligneFinancement, 'financement.montantVote.ht')
        );
      },
    };
  },
]);
