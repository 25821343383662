/**
 * @ngdoc controller
 * @module portailDepotDemandeAide
 * @area lib
 * @name depotSimpleController
 * @description
 *
 *   This controller manages a series of page for 'simple' model of procedure
 *
 */

/*eslint max-params:0 */
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleController', [
  '$scope',
  '$rootScope',
  '$window',
  '$state',
  '$log',
  '$q',
  'teleserviceConfiguration',
  'simpleConfiguration',
  'aidesService',
  'teleservicesService',
  'alertsService',
  'userSessionService',
  'tiersService',
  'tiersThematiquesService',
  'aide',
  'demandeur',
  'configuration',
  'mdm',
  'publicSettingsFinancement',
  'contributionsFactory',
  'contributionsService',
  '$stateParams',
  '$timeout',
  'declarationCompteSignataire',
  'attestationDeclarationHonneur',
  '$translate',
  '$modal',
  'StoreService',
  'depotSimpleService',
  'IFrameCommunicationManager',
  'bourseService',
  'tiersPhysiqueAlreadyLinked',
  'indicateursService',
  'resolveService',
  // eslint-disable-next-line max-statements
  function(
    $scope,
    $rootScope,
    $window,
    $state,
    $log,
    $q,
    teleserviceConfiguration,
    simpleConfiguration,
    aidesService,
    teleservicesService,
    alertsService,
    userSessionService,
    tiersService,
    tiersThematiquesService,
    aide,
    demandeur,
    configuration,
    mdm,
    publicSettingsFinancement,
    contributionsFactory,
    contributionsService,
    $stateParams,
    $timeout,
    declarationCompteSignataire,
    attestationDeclarationHonneur,
    $translate,
    $modal,
    StoreService,
    depotSimpleService,
    IFrameCommunicationManager,
    bourseService,
    tiersPhysiqueAlreadyLinked,
    indicateursService,
    resolveService
  ) {
    $scope.errorsExceptRequired = true;
    const listeners = [];
    const stateViewsArray = [];

    const DEMANDEUR_CREATION_STEPS = tiersService.getDemandeurCreationSteps();
    const BENEFICIAIRE_CREATION_STEPS = tiersService.getBeneficiaireCreationSteps();
    let skipUpdate = false;
    // Keep track of scopes created in the controller...
    const scopes = [];
    // ... and delete them when the scope is destroyed
    $scope.$on('$destroy', function() {
      _.forEach(scopes, function(sc) {
        sc.$destroy();
      });
    });

    /**
     * Go to new router state, and resolve all promises before transition starts
     * @param {*} stateName
     * @param {*} params
     */
    const goToState = (stateName, params) => {
      $state.go(stateName, params);
    };

    /**
     * Hide and alert after 5sec
     * @param {*} alerts The created alert to hide in 5sec
     */
    const hideAlertAfterTimeout = (alerts) => {
      // alerts is an array but always has only one item
      $timeout(function() {
        alerts.pop(); // Remove the created alert from the array
      }, 5000);
    };

    $scope.aide = aide;
    $scope.oldAide = _.cloneDeep(aide);
    $scope.contribution = contributionsFactory.getContribution();
    $scope.tiersPhysiqueAlreadyLinked = tiersPhysiqueAlreadyLinked;
    const iframeCommunicationManagers = [];

    // Redirect to the dashboard with an error if the user goes back to a
    // demande and has selected a tiers different than the demandeur
    if (!aidesService.canAccessDepotForDemande($scope.aide, $scope.tiers)) {
      StoreService.dashboardAlert.set(
        alertsService.getAlertWarning($translate.instant('connected.depot.errors.demandeOfAnotherTiers'))
      );
      goToState('app.connected.dashboard.accueil');
      return; // do not load the component
    }

    const beneficiaires = _.get($scope, 'aide.beneficiaires');
    if (!_.isEmpty(beneficiaires) && beneficiaires[0].expand) {
      const beneficiaire = beneficiaires[0].expand;
      StoreService.beneficiaire.set(beneficiaire);
    }

    // If the demandeur's personnalité juridique is PHYSIQUE and a tiers physique is already linked to our user,
    // the tiers already linked will be used as demandeur
    if (demandeur) {
      if (
        demandeur.famille &&
        demandeur.famille.expand &&
        demandeur.famille.expand.personnaliteJuridique === 'PHYSIQUE' &&
        $scope.tiersPhysiqueAlreadyLinked
      ) {
        // Current demandeur must be changed...
        demandeur = $scope.tiersPhysiqueAlreadyLinked;
        // ...before we can launch our replacment process
        replaceDemandeurIfTiersPhysique();
      }
      StoreService.demandeur.set(demandeur);
      $scope.aide.demandeur = {
        href: demandeur.id,
        title: demandeur.title,
        expand: demandeur,
      };
    }
    restartDepotProcessIfUnlinkedFromTiers();
    /**
     * Unset the demandeur from the demande and go back to preambule if the user
     * is not linked to the tiers anymore
     *
     * By doing this the user has to create a new tiers on the demandeur famille
     * step
     *
     * @return {void}
     */
    function restartDepotProcessIfUnlinkedFromTiers() {
      const hasDemandeurSet = _.get($scope.aide, 'demandeur.href') !== undefined;
      const islinkedToKnownTiers = $scope.tiers !== undefined;
      const isTemporary = demandeur && demandeur.status === 'TEMPORARY';
      const isInLinkedUsers = _.some(demandeur && demandeur.linkedUsers, (linkedUser) => {
        const isCurrentUser = linkedUser.href === $scope.currentUser.self;
        const isLinked = linkedUser.form === 'CONTRIBUTOR' || linkedUser.form === 'ADMINISTRATOR';
        return isCurrentUser && isLinked;
      });
      const hasBeenUnlinkedFromTiers = hasDemandeurSet && !islinkedToKnownTiers && (!isTemporary || !isInLinkedUsers);

      if (hasBeenUnlinkedFromTiers) {
        StoreService.demandeur.set(undefined);
        $scope.aide.demandeur = {};

        _.set($scope.aide, 'history.begin.metadata.step', 'preambule');
        _.set($scope.aide, 'history.begin.metadata.stepsStack', []);
        _.set($scope.aide, 'history.begin.metadata.stepsMetaStack', []);
      }
    }

    if ($scope.contribution) {
      if ($scope.contribution.statut === 'ANSWERED') {
        contributionsService.notifications.alreadyDone();
        return goToState('app.connected.dashboard.accueil');
      }
    }

    function initialize() {
      if ($stateParams.fromEchange) {
        $timeout(function() {
          $rootScope.$broadcast('showAsideEchange');
        }, 2000);
      }
      $scope.declarationCompteSignataire = declarationCompteSignataire;
      $scope.attestationDeclarationHonneur = attestationDeclarationHonneur;
      if ($scope.declarationCompteSignataire) {
        $scope.htmlHautTransmissionAttestation = _.get(
          $scope,
          'teleserviceConfiguration.workflow.pageConfirmation.htmlHautTransmissionAttestation'
        );
        $scope.htmlBasTransmissionAttestation = _.get(
          $scope,
          'teleserviceConfiguration.workflow.pageConfirmation.htmlBasTransmissionAttestation'
        );
      }
      $scope.confirmationAttestation =
        aide.status === 'WAITING_FOR_CERTIFICATE' && aide.history.begin.metadata.step === 'confirmation';
      $scope.displayInformationHeader = $translate.instant(
        ($scope.navigate.ns || 'teleservice.' + _.get(aide, 'history.begin.metadata.step')) + '.information-header'
      );
      $scope.displayInformationFooter = $translate.instant(
        ($scope.navigate.ns || 'teleservice.' + _.get(aide, 'history.begin.metadata.step')) + '.information-footer'
      );

      // Listener pour la mise à jour du bouton Enregistrer
      window.addEventListener('message', displayFormsState, false);
      listeners.push(displayFormsState);

      // Initialize plan financement at the beginning
      depotSimpleService.initPlanFinancementFields($scope.aide, $scope.teleserviceConfiguration);
    }

    initialize();

    /**
     * Lorsque le portail reçoit un message de type 'displayFormsState',
     * On stocke la réponse dans un tableau
     * Dès que le tableau ne possède plus de state: 'error', le bouton Enregistrer est accessible
     * Sinon, le bouton est grisé
     * @param msg Message reçu par le portail
     */
    function displayFormsState(msg) {
      if (_.get(msg, 'data.action') === 'displayFormsState') {
        var state = _.get(msg, 'data.state');
        var viewReference = _.get(msg, 'data.reference');

        var viewFoundInStateArray = _.find(stateViewsArray, { reference: viewReference });
        if (!viewFoundInStateArray) {
          // Nouvelle référence absente du tableau
          stateViewsArray.push({ reference: viewReference, state: state });
        } else {
          // référence déjà présente, on met juste à jour le state
          viewFoundInStateArray.state = state;
        }

        $scope.$apply(function() {
          $scope.errorsExceptRequired = _.find(stateViewsArray, { state: 'error' }) ? true : false;
        });
      }
    }

    $scope.isCreation = function() {
      return _.get($scope, 'stepsWizard.steps.length') > 0 && !$scope.persistenceError && !$scope.contribution;
    };
    $scope.isContribution = function() {
      return _.get($scope, 'stepsWizard.steps.length') > 0 && !$scope.persistenceError && $scope.contribution;
    };

    $scope.hideBtnValidateOrFinish = function() {
      return (
        _.get($scope, 'navigate.ns') === 'teleservice.recapitulatif' ||
        _.get($scope, 'navigate.ns') === 'teleservice.confirmation'
      );
    };

    $scope.preventValidationOnEnter = function(event) {
      if (
        event.keyCode === 13 &&
        event.target.tagName !== 'TEXTAREA' &&
        event.target.id !== 'depotNavigateFormBtnPrevious' &&
        event.target.id !== 'depotNavigateFormBtnSave' &&
        event.target.type !== 'submit'
      ) {
        event.preventDefault();
      }
    };

    // set setting parameter for 1 document by piece
    $scope.unDocumentParPiece = _.get(publicSettingsFinancement, 'unDocumentParPiece', false);

    // initialize planFinancementPieces error
    $scope.errorPiecesLignes = {};

    var mToday = moment();
    var dateDebut = _.get($scope.aide, 'teleservice.expand.dateDebut');

    var user = userSessionService.getUser();
    var invitedUser = $stateParams.user;
    if (invitedUser && invitedUser !== user.id) {
      goToState('app.connected.dashboard.accueil', {
        teleserviceNotInvited: $scope.aide.teleservice.title,
      });
    }

    if (_.get($scope.aide, 'teleservice.expand.statut') !== 'PUBLIE' || !dateDebut || !mToday.isAfter(dateDebut)) {
      $scope.alerts = alertsService.getAlertError(
        $translate.instant('teleservice.errors.notOpen', { libelleTeleservice: $scope.aide.teleservice.title })
      );
      $timeout(function() {
        $scope.navigate.block = true;
      });
    }

    if (!$scope.contribution) {
      teleservicesService.controlDatesTeleservice(teleserviceConfiguration, $scope.aide).then((dates) => {
        if (dates.notOpenYet) {
          $scope.alerts = alertsService.getAlertError('teleservice.errors.dateDebutError');
        } else if (dates.alreadyClosed) {
          $scope.alerts = alertsService.getAlertError('teleservice.errors.dateFinError');
        }
        return checkDatesTeleservice($scope.aide);
      });
    }

    // Verify access for this tiers to this teleservice
    if (
      $scope.tiers &&
      _.get(teleserviceConfiguration, 'workflow.type') === 'simple' &&
      !teleservicesService.checkFamilleTeleservice($scope.tiers, teleserviceConfiguration)
    ) {
      $scope.alerts = alertsService.getAlertError(
        $translate.instant('teleservice.errors.badTiers', {
          libelleFamilleTiers: $scope.tiers.famille.title,
          libelleTeleservice: $scope.aide.teleservice.title,
        })
      );
      $timeout(function() {
        $scope.navigate.block = true;
      });
    }

    // Prevent user to edit an already send demande
    if ($scope.aide.status !== 'REQUESTED' && !$scope.contribution) {
      goToState('app.connected.dashboard.accueil');
    }

    // Prepare steps that match the requirement for almost all cases
    // For more specific behavior, every controller should do its work
    $scope.getSimpleSteps = function() {
      if (!$scope.contribution) {
        return depotSimpleService.getSimpleSteps($scope.teleserviceConfiguration);
      }

      return depotSimpleService.getSimpleStepsForContribution(
        $scope.teleserviceConfiguration,
        $scope.contribution.pagesARevoir,
        $scope.contribution.typeContribution
      );
    };

    // Add begin event to entity if not present
    if (_.isEmpty(_.get($scope.aide, 'history.begin'))) {
      var steps = depotSimpleService.getSimpleSteps($scope.teleserviceConfiguration);
      depotSimpleService.initializeAideHistory(aide, steps[0]);
    }

    // On "contribution" we override the "aide" state by the "contribution" one
    if ($scope.contribution) {
      $scope.aide.history.begin.metadata = $scope.contribution.history.begin.metadata;
    }

    /**
     * Delete precision and detail if amount is 0 on each lines of the poste
     * @param {object} poste
     * @returns {void}
     */
    function deletePrecisionAndDetails(poste) {
      _.forEach(poste.lignes, function(ligne) {
        if (!_.get(ligne, 'montant.ttc') && !_.get(ligne, 'montant.ht')) {
          ligne.commentaire = '';
          delete ligne.details;
        }
      });
    }

    /**
     * Delete all precision and detail on the plan financement when amount is 0
     * @param {object} planFinancement
     * @returns {void}
     */
    function deletePrecisionAndDetailsPlanFin(planFinancement) {
      _.forEach(planFinancement, function(pf) {
        _.forEach(_.get(pf, 'depense.postes', []), function(depensePoste) {
          deletePrecisionAndDetails(depensePoste);
        });
        _.forEach(_.get(pf, 'recette.postes', []), function(recettePoste) {
          deletePrecisionAndDetails(recettePoste);
        });
      });
    }

    function editedRequestAlreadyTransmittedModal() {
      var scopeModal = $scope.$new();
      scopes.push(scopeModal);

      scopeModal.close = (modal) => {
        modal.$hide();
        modal.$on('modal.hide', () => {
          goToState('app.connected.dashboard.aides.demandesAides');
        });
      };

      $modal({
        scope: scopeModal,
        template: 'depot/simple/modal/modal-edited-request-already-transmitted.html',
      });
    }

    $scope.saveAide = () => {
      /* skipUpdate is set during goToStep method.
        if page is not displayed to the user we don't want to perform an update
        */
      if (skipUpdate) {
        skipUpdate = false;
        return;
      }
      const promise = aidesService
        .saveAide($scope.aide)
        .then((newAide) => {
          // computed datas, we have to update the aide
          if (!_.isNil(_.get(newAide, 'multiFinanceur'))) {
            $scope.aide.multiFinanceur = newAide.multiFinanceur;
          }
          if (!_.isNil(_.get(newAide, 'optionsMultiFinanceur'))) {
            $scope.aide.optionsMultiFinanceur = newAide.optionsMultiFinanceur;
          }

          // Updates 'specifiques' data on local aide to maintain compatibility with legacy expressions
          // 'specifiques' properties are calculated on the back-end so we have to update them client side.
          // exemple: find(aide.specifiques,'reference','TR_ACHAT_SECOND_VEHICULE').value===true
          if (!_.isNil(_.get(newAide, 'specifiques'))) {
            _.set($scope, 'aide.specifiques', _.get(newAide, 'specifiques'));
          }

          $rootScope.isPFLoading = false;
          return newAide;
        })
        .catch((err) => {
          const data = _.get(err, 'data', {});
          if (err.status === 403 && data.code === 403.1 && data.maxPersistenceNumber) {
            goToState('app.connected.dashboard.accueil', {
              maximumPersistenceReach: true,
            });
          } else if (err.status === 409) {
            editedRequestAlreadyTransmittedModal();
          } else {
            if (data.message) {
              $log.error(data.message);
            }
          }

          throw new Error(data.message || 'Error during demande saving');
        })
        .finally(() => {
          $rootScope.isPFLoading = false;
        });

      StoreService.depot.pendingPromises.push(promise);
      return promise;
    };

    /**
     * Replace current demandeur physique with a tiers physique already linked to user (comptes multi-tiers)
     * @returns {void}
     */
    function replaceDemandeurIfTiersPhysique() {
      // Ensure that the demandeur will not see demandeur creation steps if editing an existing demande
      const aideSteps = _.get($scope.aide, 'history.begin.metadata.stepsStack', []);
      const currentStep = _.get($scope.aide, 'history.begin.metadata.step');
      const stepsMetaStack = _.get($scope.aide, 'history.begin.metadata.stepsMetaStack', []);
      const pageInformationsDemandeur = $scope.teleserviceConfiguration.workflow.pageInformationsDemandeur;
      const { areInfosCompActive } = tiersService.areInfosCompTiersActive(
        pageInformationsDemandeur,
        $scope.teleserviceConfiguration,
        demandeur || $scope.tiers,
        $scope.tiers
      );

      let demandeurCreationStepsToRemove = DEMANDEUR_CREATION_STEPS;
      // If info complementaires are active, do not remove the page even if the tier is defined
      if (areInfosCompActive) {
        demandeurCreationStepsToRemove = demandeurCreationStepsToRemove.filter(
          (step) => step !== 'demandeur-complementaire'
        );
      }

      const isOnDemandeurCreationStep = currentStep && demandeurCreationStepsToRemove.includes(currentStep);
      const aideHasDemandeurCreationSteps = aideSteps.some((step) => demandeurCreationStepsToRemove.includes(step));

      if (isOnDemandeurCreationStep || aideHasDemandeurCreationSteps) {
        const firstDemandeurStepIndex = aideSteps.findIndex((step) => demandeurCreationStepsToRemove.includes(step));
        const lastDemandeurStepIndex = aideSteps
          .map((step) => demandeurCreationStepsToRemove.includes(step))
          .lastIndexOf(true);
        const stepsToRemove = lastDemandeurStepIndex === -1 ? 0 : lastDemandeurStepIndex - firstDemandeurStepIndex + 1;

        // We set demandeur in store, in $scope and resolve for dashboard
        StoreService.currentTiersRef.set(demandeur.reference, true, true);
        $scope.tiers = demandeur;
        resolveService.replaceResolveByToken($state, 'tiers', demandeur);

        // remove all demandeur creation steps and redirect to 'demandeur-recapitulatif'
        aideSteps.splice(firstDemandeurStepIndex, stepsToRemove);
        stepsMetaStack.splice(firstDemandeurStepIndex, stepsToRemove);
        _.set($scope.aide, 'history.begin.metadata.step', 'demandeur-recapitulatif');
      }
    }

    /// Persistence
    {
      // Manage step navigations
      $scope.$watch(
        '[aide.history.begin.metadata.step, aide.history.begin.metadata.stepMetadata]',
        ([newStep], [oldStep]) => {
          // label of the page
          const depotState = $state.$current;
          depotState.data = $state.$current.data || {};
          $rootScope.pageConfigurationKey =
            (teleserviceConfiguration.libelle && teleserviceConfiguration.libelle.value) ||
            teleserviceConfiguration.reference;
          depotState.data.title = 'teleservice.' + newStep + '.title';

          if (!_.includes(['financeur', 'preambule', 'confirmation'], newStep) && oldStep !== 'preambule') {
            // Delete precision if amount is not set
            if (aide.history.begin.metadata.step === 'minimis') {
              deletePrecisionAndDetailsPlanFin(_.get($scope, 'aide.planFinancement', []));
            }

            // Will either create of update a aide based on whether the _id key is present
            if (!$scope.contribution) {
              $scope.saveAide();
            } else {
              contributionsService.saveContribution($scope.aide, $scope.contribution);
            }
          }
          $scope.confirmationAttestation =
            aide.status === 'WAITING_FOR_CERTIFICATE' && aide.history.begin.metadata.step === 'confirmation';

          //Gestion de l'affichage des vignettes, HTML haut et bas
          $scope.displayInformationHeader = $translate.instant(
            ($scope.navigate.ns || 'teleservice.' + _.get(aide, 'history.begin.metadata.step')) + '.information-header'
          );
          $scope.displayInformationFooter = $translate.instant(
            ($scope.navigate.ns || 'teleservice.' + _.get(aide, 'history.begin.metadata.step')) + '.information-footer'
          );
          var vignette = $translate.instant(
            ($scope.navigate.ns || 'teleservice.' + aide.history.begin.metadata.step) + '.informations.content'
          );

          $scope.showHeader = !(
            _.includes($scope.displayInformationHeader, 'information-header') ||
            _.isEmpty($scope.displayInformationHeader)
          );
          $scope.showFooter = !(
            _.includes($scope.displayInformationFooter, 'information-footer') ||
            _.isEmpty($scope.displayInformationFooter)
          );
          $scope.showVignette = !(_.includes(vignette, 'informations.content') || _.isEmpty(vignette));

          // broadcast that content changed for scroll-behind-footer directive to refresh its listeners
          $scope.$broadcast('contentChanged');
        }
      );

      // Add the reference in the url when the aide is created by progressOrCreate
      // and set the teleservice id by its revision
      $scope.$watch('aide.teleservice.href', (newReference, oldReference) => {
        if (newReference !== oldReference) {
          // wait for all pending promises before continue
          $state.transitionTo(
            'app.connected.config.depot.simple',
            {
              p: $scope.aide.reference,
              configurationId: newReference
                .split('/')
                .pop()
                .split('?')
                .shift(),
            },
            {
              location: true,
              inherit: true,
              notify: false,
            }
          );
        }
      });
    }

    /// Navigation
    {
      $scope.navigate.previous = function() {
        return $q
          .resolve()
          .then(executeCustomBeforePreviousActions)
          .then(executeCommonPreviousActions);
      };

      // Small helper function for the 'next' children navigation functions
      $scope.goToStep = function(step, forget, back, force, metadata) {
        const stepChanged = $scope.aide.history.begin.metadata.step != step;
        if (forget) skipUpdate = true;
        let currentStep;
        if ($scope.contribution && !force && step !== 'confirmation' && stepChanged) {
          // if back is true, the call is coming from a completude error so we directly go the step
          // otherwise we look for the next step
          const nextStep = back ? step : contributionsFactory.getNextStep($scope.contribution, step);
          $scope.goToStep(nextStep, forget, back, true);
        } else if ($scope.checkNextStep()) {
          $scope.$emit('hideModalsAndErrors');
          // The forget parameter can be used to skip adding the step to the steps history stack
          // Usefull when a step wants to skip itself entirely
          if (!forget && !back) {
            const currentStepsStack = $scope.aide.history.begin.metadata.stepsStack;
            currentStep = $scope.aide.history.begin.metadata.step;
            if (currentStep) {
              currentStepsStack.push(currentStep);
            }
            let currentStepsMetaStack = $scope.aide.history.begin.metadata.stepsMetaStack;
            if (!currentStepsMetaStack) {
              currentStepsMetaStack = [];
            }
            const currentStepMetadata = $scope.aide.history.begin.metadata.stepMetadata;
            currentStepsMetaStack.push(currentStepMetadata);
            $scope.aide.history.begin.metadata.stepsMetaStack = currentStepsMetaStack;
          }
          // Back indicates that we're jumping to a previous step (probably from a link in recapitulatif)
          // We need to update the steps stack to keep it consistent
          if (back) {
            const stepPosition = _.indexOf($scope.aide.history.begin.metadata.stepsStack, step);
            if (stepPosition !== -1) {
              // Remove all steps after the target
              $scope.aide.history.begin.metadata.stepsStack.splice(stepPosition);
            }
          }
          // Recuperation de la step courante
          currentStep = _.get($scope.aide.history, 'begin.metadata.step');
          // Initialisation du tableau si inexistant des step déja visité si inexistant
          if (!_.get($scope.aide.history.begin, 'metadata.stepsVisited')) {
            $scope.aide.history.begin.metadata.stepsVisited = [];
          } else if (currentStep) {
            // Recuperation de la position du step dans le tableau déja visité
            const stepPositionVisited = _.indexOf($scope.aide.history.begin.metadata.stepsVisited, currentStep);
            // Si non présent
            if (stepPositionVisited === -1) {
              // On l'ajoute
              $scope.aide.history.begin.metadata.stepsVisited.push(currentStep);
            }
          }

          $scope.aide.history.begin.metadata.step = step;
          $scope.aide.history.begin.metadata.stepMetadata = metadata;
          if (stepChanged) {
            $scope.cleanNavigate();
          }
          // Prevent keeping the scroll offset accross pages
          $window.scrollTo(0, 0);
        }
      };

      // Same as gotToStep but returns a function for later execution, makes it possible to use oneliners for navigate.next definitions
      $scope.goToStepFn = function(step, metadata) {
        return function(forget, back) {
          $scope.goToStep(step, forget, back, false, metadata);
        };
      };

      $scope.showErrorsOnNavigate = () => {
        // Si l'option controleCompletudeDepot est active et que le tableau de step visited est initialisé
        if (
          _.get($scope.aide.history.begin, 'metadata.stepsVisited') &&
          _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false)
        ) {
          // Recuperation de la position du step courant dans le tableau déja visité
          const stepPositionVisited = _.indexOf(
            $scope.aide.history.begin.metadata.stepsVisited,
            _.get($scope.aide.history, 'begin.metadata.step')
          );
          // Si non présent
          if (stepPositionVisited !== -1) {
            return true;
          }
        }
        return false;
      };

      /**
       * Valide (ou pas) le passage à l'étape suivante (ou précédente)
       */
      $scope.checkNextStep = () => {
        if (_.get($scope.aide, 'teleservice.expand.dateDebut')) {
          if (moment().isBefore($scope.aide.teleservice.expand.dateDebut)) {
            $scope.alerts = alertsService.getAlertError('teleservice.errors.dateDebutError');
            return false;
          }
        } else {
          $scope.alerts = alertsService.getAlertError('teleservice.errors.dateDebutRequired');
          return false;
        }

        if (!$scope.contribution) {
          checkDatesTeleservice($scope.aide);
        }
        return true;
      };
    }

    /**
     * Execute the custom actions before previous common actions if defined
     *
     * @returns {*} the return value of the custom actions before previous (Promise or not) or undefined if not defined
     */
    function executeCustomBeforePreviousActions() {
      if ($scope.navigate.beforePrevious) {
        return $scope.navigate.beforePrevious();
      }
    }

    /**
     * Execute the common previous actions
     *
     * @returns {void}
     */
    function executeCommonPreviousActions() {
      $rootScope.isPFLoading = true;

      if ($scope.checkNextStep()) {
        return saveTiersIfNeeded().then(goToPreviousStep);
      }
    }

    /**
     * Update aide metadata and go to previous step
     *
     * @returns {void}
     */
    function goToPreviousStep() {
      $scope.$emit('hideModalsAndErrors');

      // Get to last element of the stack of steps and remove it
      const previousStep = $scope.aide.history.begin.metadata.stepsStack.pop();
      const stepChange = $scope.aide.history.begin.metadata.step != previousStep;
      $scope.aide.history.begin.metadata.step = previousStep;
      $scope.aide.history.begin.metadata.stepMetadata = $scope.aide.history.begin.metadata.stepsMetaStack.pop();

      if (stepChange) {
        $scope.cleanNavigate();
      }

      $scope.navigate.metadata = $scope.aide.history.begin.metadata.stepMetadata;

      $window.scrollTo(0, 0);
    }

    /**
     * Save the demandeur or beneficiaire if needed depending on the current step
     *
     * @returns {Promise} promise of the tiers saving or empty if no tiers needs to be saved
     */
    function saveTiersIfNeeded() {
      // we should save the demandeur or beneficiaire if hitting "previous" on a creation step
      let promise;

      const currentStep = $scope.aide.history.begin.metadata.step;
      const shouldSaveDemandeur = _.includes(DEMANDEUR_CREATION_STEPS, currentStep);
      const shouldSaveBeneficiaire = _.includes(BENEFICIAIRE_CREATION_STEPS, currentStep);

      if (shouldSaveDemandeur) {
        promise = $scope.saveDemandeur();
      } else if (shouldSaveBeneficiaire) {
        promise = $scope.saveBeneficiaire();
      } else {
        promise = $q.resolve();
      }

      return promise;
    }

    /**
     * Display message and block navigation if teleservice is not open yet or already closed
     * @param {object} aide aide
     * @return {void}
     */
    function checkDatesTeleservice(aide) {
      teleservicesService.controlDatesTeleservice(teleserviceConfiguration, aide).then((dates) => {
        if (dates.notOpenYet || dates.alreadyClosed) {
          $scope.alerts = alertsService.getAlertError(
            $translate.instant('teleservice.errors.notOpen', { libelleTeleservice: aide.teleservice.title })
          );
          $scope.navigate.block = true;
        }
      });
    }

    /// Aide
    {
      // Standard configurations
      const createConfiguration = (entity, step) => {
        const mergedConfiguration = _.merge(
          {
            ns: 'teleservice.' + step,
            displayCharactersLeft: true,
            remoteValidation: _.get(teleserviceConfiguration, 'controleCompletudeDepot', false),
          },
          simpleConfiguration[step],
          _.get(teleserviceConfiguration, entity + '.' + step)
        );
        return mergedConfiguration;
      };

      // Load in scope the view configurations for all steps
      const loadConfigurations = () => {
        $scope.demandeurBeneficiaireConfiguration = simpleConfiguration['demandeur-beneficiaire'];
        $scope.identificationDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-identification');
        $scope.thematiquesDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-thematiques');
        _.merge($scope.thematiquesDemandeurConfiguration, simpleConfiguration.thematiques);
        $scope.adresseDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-adresse');
        $scope.informationsComplementairesDemandeurConfiguration = createConfiguration(
          'demandeur',
          'demandeur-complementaire'
        );
        $scope.representantLegalDemandeurConfiguration = createConfiguration(
          'demandeur',
          'demandeur-representant-legal'
        );
        $scope.representantsDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-representants');
        $scope.familleBeneficiaireConfiguration = createConfiguration('beneficiaire', 'beneficiaire-famille');
        $scope.familleDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-famille');
        $scope.identificationBeneficiaireConfiguration = createConfiguration(
          'beneficiaire',
          'beneficiaire-identification'
        );
        $scope.thematiquesBeneficiaireConfiguration = createConfiguration('beneficiaire', 'beneficiaire-thematiques');
        _.merge($scope.thematiquesBeneficiaireConfiguration, simpleConfiguration.thematiques);
        $scope.adresseBeneficiaireConfiguration = createConfiguration('beneficiaire', 'beneficiaire-adresse');
        $scope.domiciliationBancaireConfiguration = createConfiguration('aide', 'domiciliation-bancaire');
        $scope.informationsComplementairesBeneficiaireConfiguration = createConfiguration(
          'beneficiaire',
          'beneficiaire-complementaire'
        );
        //Controls screen "Représentant légal du bénéficiaire"
        $scope.representantLegalBeneficiaireConfiguration = createConfiguration(
          'beneficiaire',
          'beneficiaire-representant-legal'
        );
        //Controls screen "Contacts du bénéficiaire"
        $scope.representantsBeneficiaireConfiguration = createConfiguration(
          'beneficiaire',
          'beneficiaire-representants'
        );
        $scope.informationsGeneralesConfiguration = createConfiguration('aide', 'informations-generales');
        _.merge(
          $scope.informationsGeneralesConfiguration.fields,
          _.keyBy(
            _.get(teleserviceConfiguration, 'workflow.pageInformationsGenerales.demandeFinancement.fields'),
            'reference'
          )
        );
        _.merge(
          $scope.informationsGeneralesConfiguration.fields,
          _.keyBy(
            _.get(teleserviceConfiguration, 'workflow.pageInformationsGenerales.realisationEtEvaluation.fields'),
            'reference'
          )
        );
        $scope.pageFinanceurConfiguration = createConfiguration('aide', 'financeur');
        $scope.recapitulatifAideConfiguration = createConfiguration('aide', 'recapitulatif');
        $scope.criteresConfiguration = createConfiguration('aide', 'criteres');
        $scope.confirmationAideConfiguration = createConfiguration('aide', 'confirmation');
        $scope.piecesConfiguration = createConfiguration('aide', 'pieces');
        $scope.documentComptableConfiguration = createConfiguration('aide', 'document-comptable');
        $scope.minimisConfiguration = createConfiguration('aide', 'minimis');
        $scope.informationsComplementaires2Configuration = createConfiguration('aide', 'pageInformationsGenerales2');
        $scope.informationsComplementaires3Configuration = createConfiguration('aide', 'pageInformationsGenerales3');
        $scope.indicateurs = createConfiguration('aide', 'indicateurs');
      };

      loadConfigurations();
    }

    /// Tiers
    function tiersConfigurations() {
      // Keep list of functions through several pages
      $scope.fonctionsRepresentants = {};
      $scope.setFonctionsRepresentants = (famille, tiersKey) => {
        // Request functions associated with tiers' famille
        $scope.fonctionsRepresentants[tiersKey] = _.filter(mdm.fonctionsrepres.array, (fonctionLink) => {
          return _.find(famille.fonctionsRepresentants, {
            href: fonctionLink.href,
          });
        });
      };
    }

    tiersConfigurations();

    /**
     * Close all iFrame communication managers
     */
    function closeIframeCommunicationManagers() {
      iframeCommunicationManagers.forEach((manager) => {
        manager.close();
      });
    }

    /**
     * Save formation suivie data or formation contribution
     */
    function saveProgressFormation() {
      if (_.get($scope.teleserviceConfiguration, 'workflow.pageInformationsGenerales.formationSuivie.actif')) {
        // Thanks to saveInProgress option, the data will be considered valid and will be saved
        closeIframeCommunicationManagers();
        const formationCommunicationManager = new IFrameCommunicationManager('#formationIframe');
        iframeCommunicationManagers.push(formationCommunicationManager);
        return formationCommunicationManager
          .sendEvent({
            action: 'validate',
            options: { saveInProgress: true },
          })
          .manageEventWithPromise((msg, resolve, reject) => {
            bourseService.updateFormationAide(msg, resolve, reject, $scope.aide);
          });
      }
      return $q.resolve(true);
    }

    /**
     * Get the iframe if the current step contains views
     * @param {string} kind
     * @return {*} the iframe
     */
    function getViewIframe(kind) {
      let viewIFrame;

      if ($scope.aide.views || kind === 'demandeur') {
        const step = _.get($scope.aide, 'history.begin.metadata.step');
        let elements;

        // Getting the iframe, depending of the step
        switch (step) {
          case 'informations-generales':
            elements = angular.element('#viewsIframe1');
            break;
          case 'pageInformationsGenerales2':
            elements = angular.element('#viewsIframe2');
            break;
          case 'pageInformationsGenerales3':
            elements = angular.element('#viewsIframe3');
            break;
          case 'demandeur-complementaire':
            elements = angular.element('#viewsInfosComps');
            break;
        }

        if (_.size(elements) > 0) {
          viewIFrame = elements[0];
        }
      }
      return viewIFrame;
    }

    /**
     * Get the iframe if the current step contains indicateurs
     * @return {Object} the iframe
     */
    function getIndicateursIframe() {
      let indicateursIframe;
      let elements;
      const step = _.get($scope.aide, 'history.begin.metadata.step');

      if (step === 'indicateurs') {
        elements = angular.element('#indicateursPrevisionnelIframe');
      }

      if (elements && _.size(elements) > 0) {
        indicateursIframe = elements[0];
      }
      return indicateursIframe;
    }

    /**
     * Validate iframes data (views, indicateurs)
     * @param {string} kind
     * @returns {Promise<void>}
     */
    function validateIframes(kind = 'aide') {
      // Assert if indicateur page is active in workflow
      const isPageIndicateursActif = _.get($scope.teleserviceConfiguration, 'workflow.pageIndicateurs.actif', false);
      if (!isPageIndicateursActif) {
        // Only views to save
        return validateViews(kind);
      }
      // If page indicateurs is active
      // save views
      const viewsPromise = validateViews(kind);
      // and save indicateurs
      const indicateursPromise = validateIndicateurs();
      // validation of all iframes
      return $q.all([viewsPromise, indicateursPromise]);
    }

    /**
     * Validate "données complémentaires" data (form or master details views).
     * @param {string} kind
     */
    function validateViews(kind = 'aide') {
      const viewIframe = getViewIframe(kind);

      if (!viewIframe) {
        return $q.resolve();
      }

      const validateViewsListeners = [];
      return $q((resolve, reject) => {
        /**
         * Listener for "viewsUpdated" event emitted by the view iFrame when a view validation is OK.
         * @param {*} msg
         */
        const onViewsUpdated = (msg) => {
          if (_.get(msg, 'data.action') === 'viewsUpdated') {
            const index = _.get(msg, 'data.index');
            const values = _.get(msg, 'data.values');
            _.set($scope, `${kind}.views[${index}].values`, values);
          }
        };
        window.addEventListener('message', onViewsUpdated, false);
        validateViewsListeners.push(onViewsUpdated);

        /**
         * Listener for "updateStateViews" event emitted by the view iFrame when every views validation is completed.
         * @param {*} msg
         */
        const onUpdateStateViews = (msg) => {
          if (_.get(msg, 'data.action') === 'updateStateViews') {
            if (_.get(msg, 'data.state') === 'error') {
              reject(msg.data);
            } else if (_.get(msg, 'data.state') === 'ok') {
              resolve();
            }
          }
        };
        window.addEventListener('message', onUpdateStateViews, false);
        validateViewsListeners.push(onUpdateStateViews);

        const messageData = {
          action: 'validViews',
          options: { skipRequiredErrors: true, showAllErrors: false },
        };

        // we send a patch when saving tiers famille infos-comp
        if (kind === 'demandeur') {
          messageData.options.patchViewsEntity = true;
        }

        // Ask views validation to view iFrame.
        viewIframe.contentWindow.postMessage(messageData, '*');
      }).finally(() => {
        _.each(validateViewsListeners, (listener) => {
          window.removeEventListener('message', listener);
        });
      });
    }

    /**
     * Validate indicateurs iframe
     * @returns {Promise<void>} Resolve or reject when validation is done
     */
    function validateIndicateurs() {
      const indicateursIframe = getIndicateursIframe();

      if (!indicateursIframe) {
        return $q.resolve();
      }

      const validateIndicateursListeners = [];
      return $q((resolve, reject) => {
        /**
         * Listener for "updateStateIndicateurs" event emitted by the indicateurs iFrame
         * when every indicateurs validation is completed.
         * @param {Object} msg
         * @returns {void}
         */
        const onUpdateIndicateurs = (msg) =>
          indicateursService.updateIndicateursSaisisOnAide(msg, resolve, reject, $scope.aide);

        window.addEventListener('message', onUpdateIndicateurs, false);
        validateIndicateursListeners.push(onUpdateIndicateurs);

        // Ask indicateurs validation to view iFrame.
        const messageData = {
          action: 'validateIndicateursSaisis',
          options: { saveInProgress: true },
        };
        indicateursIframe.contentWindow.postMessage(messageData, '*');
      }).finally(() => {
        _.each(validateIndicateursListeners, (listener) => {
          window.removeEventListener('message', listener);
        });
      });
    }

    /**
     * Show a success message when everything is correctly save
     */
    function displayAlertSuccess() {
      $scope.alerts = alertsService.getAlertSuccess('connected.config.depot.success.save');
      hideAlertAfterTimeout($scope.alerts);
    }

    /**
     * Show an error message when an error occured while trying to save the "aide"
     */
    function displayAlertError(err) {
      $log.error("Une erreur est survenue lors de l'enregistrement de la demande", err);
    }

    $scope.saveProgress = (depotForm) => {
      const step = _.get($scope.aide, 'history.begin.metadata.step');

      if (!hasErrorsExceptRequired(depotForm.$error)) {
        const currentStep = _.get($scope, 'aide.history.begin.metadata.step');
        let tiersToSave;
        let isDemandeur = false;
        let isBeneficiaire = false;
        if (DEMANDEUR_CREATION_STEPS.includes(currentStep)) {
          tiersToSave = StoreService.demandeur.get();
          isDemandeur = true;
        } else if (BENEFICIAIRE_CREATION_STEPS.includes(currentStep)) {
          tiersToSave = StoreService.beneficiaire.get();
          isBeneficiaire = true;
        }

        if ($scope.contribution) {
          saveProgressFormation()
            .then(() => validateIframes())
            .then(() => contributionsService.saveContribution($scope.aide, $scope.contribution))
            .then(displayAlertSuccess)
            .catch((err) => {
              displayAlertError(err);
            });
        } else if (tiersToSave) {
          if (step === 'demandeur-complementaire') {
            // this screen works with partial updates (JSON PATCH)
            // eventually, all screens should manage JSON PATCH
            const patches = StoreService.tiers.patches.get();
            const patchPromise = _.isEmpty(patches)
              ? $q.resolve()
              : tiersService.patchTiers(tiersToSave.reference, patches, $scope.mdm).then((patchedTiers) => {
                  // we always need the family expand in depot workflow
                  patchedTiers.famille = tiersToSave.famille;
                  StoreService.demandeur.set(patchedTiers);
                  StoreService.tiers.patches.clean();
                });

            patchPromise
              .then(() => aidesService.saveAide($scope.aide))
              .then(() => validateIframes('demandeur'))
              .then(displayAlertSuccess)
              .catch(displayAlertError);
          } else {
            saveProgressFormation()
              .then(() =>
                saveTiers(tiersToSave, !isDemandeur).then((savedTiers) => {
                  // we always need the family expand in depot workflow
                  savedTiers.famille = tiersToSave.famille;
                  if (isDemandeur) {
                    StoreService.demandeur.set(savedTiers);
                  } else if (isBeneficiaire) {
                    StoreService.beneficiaire.set(savedTiers);
                  }
                })
              )
              .then(() => aidesService.saveAide($scope.aide))
              .then(() => validateIframes())
              .then(displayAlertSuccess)
              .catch((err) => {
                displayAlertError(err);
              });
          }
        } else {
          saveProgressFormation()
            .then(() => validateIframes())
            .then(() => aidesService.saveAide($scope.aide))
            .then(displayAlertSuccess)
            .catch((err) => {
              displayAlertError(err);
            });
        }
      }
    };

    //Check if error list (without required) is empty
    function hasErrorsExceptRequired(errorList) {
      return errorList ? !_.isEmpty(_.pull(_.keys(errorList), 'required')) : false;
    }

    // Are we on a page that allows "save"
    $scope.canSaveDemande = () => {
      const noSaveBtnPages = ['preambule', 'criteresEligibilite', 'recapitulatif', 'financeur', null];

      // we want to display the save button for the 'tiers' pages, excepted the demandeur-recapitulatif one
      const step = _.get($scope, 'aide.history.begin.metadata.step');
      const isDemandeurRecapitulatif = step === 'demandeur-recapitulatif';

      return !_.includes(noSaveBtnPages, $scope.stepsWizard.active) && !isDemandeurRecapitulatif;
    };

    $scope.saveDemandeur = function saveDemandeur() {
      const storedDemandeur = StoreService.demandeur.get();
      return saveTiers(storedDemandeur);
    };

    $scope.saveBeneficiaire = function saveBeneficiaire() {
      const storedBeneficiaire = StoreService.beneficiaire.get();
      return saveTiers(storedBeneficiaire, true);
    };

    /**
     * Returns if the tiers should be saved
     * If it is found in the referentiel with a status that is not TEMPORARY, then it should
     * not be saved because it should not be updated during depot
     */
    function shouldSaveTiers(tiers) {
      if (!tiers.reference) {
        return $q.resolve(true);
      }

      return tiersService
        .findTiers({ $filter: `reference eq '${tiers.reference}'`, $select: 'status' })
        .then((savedTiers) => {
          const savedTiersStatus = Array.isArray(savedTiers) && savedTiers[0] && savedTiers[0].status;
          return !savedTiersStatus || savedTiersStatus === 'TEMPORARY';
        });
    }

    function saveTiers(tiers, isBeneficiaire = false) {
      return shouldSaveTiers(tiers).then((shouldSave) => {
        if (shouldSave) {
          const cleanedTiers = tiers.getCleanEntity();

          const savingThematiques = _.map(cleanedTiers.thematiquesLiees, (thematique, name) => {
            // The api only accepts thematiques in kebabCase (currently they are in camelCase)
            const collectionName = _.kebabCase(name);
            return tiersThematiquesService.saveThematique(collectionName, thematique, tiers);
          });

          return $q.all(savingThematiques).then(() => {
            return tiersService.saveTiers(cleanedTiers, $scope.mdm).then((savedTiers) => {
              // keep the expanded family if exists and update store value
              savedTiers.famille = tiers.famille;
              if (isBeneficiaire) {
                StoreService.beneficiaire.set(savedTiers);
                $scope.aide.beneficiaires[0].expand = savedTiers;
              } else {
                StoreService.demandeur.set(savedTiers);
                $scope.aide.demandeur.expand = savedTiers;
              }
              return savedTiers;
            });
          });
        } else {
          return $q.resolve();
        }
      });
    }

    /**
     * Check if previous button should be displayed
     * @returns {boolean}
     */
    $scope.displayPreviousButton = () => {
      const stepsStack = _.get(aide, 'history.begin.metadata.stepsStack', []);
      const step = _.get(aide, 'history.begin.metadata.step');
      return stepsStack.length > 0 && !['confirmation', 'preambule'].includes(step);
    };

    // Check if depotForm errors change
    $scope.$watch(
      'depotForm.$error',
      (errors) => {
        $scope.errorsExceptRequired = hasErrorsExceptRequired(errors);
      },
      true
    );

    // Update aide referenceAdministrative after aide had been transmitted
    $rootScope.$on('aide-transmitted', ($event, args) => {
      _.set($scope, 'aide.referenceAdministrative', args.referenceAdministrative);
    });

    // Remove listeners
    $scope.$on('$destroy', () => {
      // unset the demandeur in the store when leaving depot screen
      StoreService.demandeur.set(null);
      StoreService.beneficiaire.set(null);

      _.each(listeners, (listener) => {
        window.removeEventListener('message', listener);
      });

      closeIframeCommunicationManagers();
    });
  },
]);
