/**
 * @ngdoc module
 * @module form
 * @name form.directives
 * @description
 *
 *   Form directives. Fields by types, validation mechanisms, dynamic forms, etc.
 *
 *   This module is a part of {@link ../form/index.html form} and uses {@link ../form.services/index.html form.services}.
 *
 */

angular.module('form.directives', [
  'form.templates',
  'form.filters',
  'form.services',
  'kendo.directives',
  'ui.utils',
  'pascalprecht.translate',
  'ui.bootstrap.typeahead',
  'ui.bootstrap.tpls',
  'mgcrea.ngStrap.tooltip',
  'ngSanitize',
  'ngTagsInput'
]).config(['tagsInputConfigProvider', function(tagsInputConfigProvider) {
  'use strict';
  // Watch tags-input's placeholder
  tagsInputConfigProvider.setActiveInterpolation('tagsInput', { placeholder: true });
}]);
