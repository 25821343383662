angular.module('common.services').factory('cguService', [
  'configuration',
  '$http',

  function(configuration, $http) {
    'use strict';

    return {
      /** Màj de la date d'acceptation du CGU d'un user dans account-management
       */
      updateDateAcceptationCGU: function(userHref, dateAcceptationCGU) {
        var bodyPartialUpdate = {
          dateAcceptationCGU: dateAcceptationCGU,
        };

        // Patch d'un user dans account-management, il s'agit d'un "partial update"
        return $http
          .patch(userHref, bodyPartialUpdate)
          .then(function(response) {
            return _.get(response, 'data');
          })
          .catch(function(err) {
            throw err;
          });
      },
    };
  },
]);
