angular.module('aides.services').factory('documentComptableService', function() {
  /**
   * Get montant for a ligne
   * @param {object} ligne
   */
  const montantLigne = (ligne) => {
    return _.get(ligne, `montant.ttc`, 0) + _.get(ligne, `montant.ht`, 0);
  };

  return {
    /**
     * Calculate autofinancement value
     * @param {object} planFinancement
     * @param {string} typeMontant
     */
    calculateAutofinancement: function(planFinancement, typeMontant) {
      // Retrieve recette financement lines and autoFinancement line
      const recettePostes = _.get(planFinancement, 'recette.postes', []);
      const lignes = JSONPath('$..lignes[?(@.reference)]', recettePostes);
      const ligneAutofinancement = _.find(lignes, (ligne) => ligne.reference === 'S_AUTOFINANCEMENT' && ligne.visible);

      // Skip this part if the plan financement doesn't have autofinancement line
      if (!ligneAutofinancement) {
        return planFinancement;
      }

      const filtredRecetteLignes = _.filter(
        lignes,
        (ligne) => ligne.reference !== 'S_AUTOFINANCEMENT' && ligne.cumulable
      );

      const depense = _.get(planFinancement, `depense.montant.total`, 0);

      const typeMontantRecette = typeMontant === 'ht' ? 'ht' : 'ttc';

      // Calculate total recette without autoFinancement
      const recetteMt = _.reduce(
        filtredRecetteLignes,
        (acc, curr) => (acc += _.get(curr, `montant.${typeMontantRecette}`, 0)),
        0
      );

      // Calculate autoFinancement value
      const autoFinancement = depense - recetteMt;
      _.set(
        ligneAutofinancement,
        `montant.${typeMontantRecette}`,
        autoFinancement > 0 ? _.round(autoFinancement, 2) : 0
      );

      // After that, we update autoFinancement related poste and total recette amounts
      const poste = _.find(recettePostes, (poste) =>
        _.find(poste.lignes, (ligne) => ligne.reference === 'S_AUTOFINANCEMENT')
      );

      const lignesPosteAutofin = JSONPath('$..lignes[?(@.reference && @.cumulable)]', poste);
      const mtPosteAutofinancement = _.reduce(
        lignesPosteAutofin,
        (acc, curr) => (acc += _.get(curr, `montant.${typeMontantRecette}`, 0)),
        0
      );

      _.set(poste, `montant.${typeMontantRecette}`, _.round(mtPosteAutofinancement, 2));
      _.set(
        planFinancement,
        `recette.montant.${typeMontantRecette}`,
        _.round(recetteMt + _.get(ligneAutofinancement, `montant.${typeMontantRecette}`), 2)
      );
      _.set(planFinancement, 'recette.montant.total', _.get(planFinancement, `recette.montant.${typeMontantRecette}`));
    },

    updateSousTotal: function(planFinancement) {
      // Find all lines from planFinancement recette
      // cumulables and with the details enabled
      const lignes = JSONPath('$.recette..lignes[?(@.cumulable===true && @.details)]', planFinancement);

      // This function filters lines according to financementDeDroit value
      // It adds all amounts (HT + TTC) of all these lines (for backward compatibility)
      const sousTotalForFinancementDroit = (value) =>
        lignes
          .filter((l) => l.details.financementDeDroit === value)
          .reduce((prev, current) => prev + montantLigne(current), 0);

      // Update the planFinancement sousTotal
      _.set(planFinancement, 'recette.sousTotal', {
        public: sousTotalForFinancementDroit('PUBLIC'),
        prive: sousTotalForFinancementDroit('PRIVE'),
      });
    },
  };
});
