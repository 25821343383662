angular.module('portailDepotDemandeAide.user').controller('accountCreationConfirmationController', [
  '$scope',
  '$window',
  '$location',
  'alertsService',
  'accountManagementService',
  '$stateParams',
  function($scope, $window, $location, alertsService, accountManagementService, $stateParams) {
    'use strict';

    // The user clicked in the link which was in the account creation confirmation email
    if ($stateParams.token && $stateParams.username) {
      accountManagementService.validateAccount($stateParams.username, $stateParams.token).then(
        function() {
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertSuccess('user.account-creation-confirmation.validation-success')
          );
        },
        function(response) {
          if (response.status === 404) {
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('user.account-creation-confirmation.errors.validation-failure-404')
            );
          } else {
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('user.account-creation-confirmation.errors.validation-failure-other')
            );
          }
        }
      );
    }
  },
]);
