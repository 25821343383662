angular.module('portailDepotDemandeAide.depot').controller('demandePaiementDocumentComptableController', [
  'demandesPaiementService',
  '$scope',
  '$timeout',
  'IFrameCommunicationManager',
  function demandePaiementDocumentComptableController(
    demandesPaiementService,
    $scope,
    $timeout,
    IFrameCommunicationManager
  ) {
    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageDocumentComptable', {
      actif: false,
    });
    $scope.cleanNavigate();

    $scope.navigate.ns = $scope.documentComptableConfiguration.ns;
    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'informationsGenerales';
    $scope.isWaitingForSaveResponse = false;

    const iFrameCommunicationManager = new IFrameCommunicationManager('#iframeDocumentComptablePaiement');
    // If page is not active
    // or if demande paiement doesn't have planFinancement depose
    // we just go next
    if ($scope.pageOptions.actif && demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement)) {
      $scope.activePage();
    } else {
      $scope.navigate.next(true);
    }

    this.$onInit = function() {
      // Get iframe's URL
      $scope.iframeDocumentComptablePaiement = demandesPaiementService.getPlanFinancementIframeUrl(
        $scope.demandePaiement
      );
    };

    $scope.$on('$destroy', function() {
      iFrameCommunicationManager.close();
    });

    $scope.navigate.next = function(forget) {
      // Fast skip if we are already waiting
      if ($scope.isWaitingForSaveResponse) {
        return;
      }

      // Action we are asking
      // the response has to have the same route and action
      const event = {
        route: 'demandePaiement',
        action: 'savePlanFinancement',
      };

      // Init the listener for the iframe
      // Listen for a successul response and go to next step
      iFrameCommunicationManager.manageEvent(({ data }) => {
        if ($scope.isWaitingForSaveResponse && data.route === event.route && data.action === event.action) {
          $scope.isWaitingForSaveResponse = false;
          $scope.navigate.lock--;
          if (data.success) {
            $scope.goToStep('domiciliation-bancaire', forget);
          }
        }
      });

      // Disable the navigation
      $scope.navigate.lock++;
      $scope.isWaitingForSaveResponse = true;

      // Post the message to the iframe to do the save
      iFrameCommunicationManager.sendEvent(event);
    };

    $timeout(function() {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },
        '#iframeDocumentComptablePaiement'
      );
    }, 0);
  },
]);
