/**
 * @ngdoc service
 * @name common.services.urlService
 * @requires $http, $q, $location, dataDocService, Piece
 * @description Interface for a Tiers' pieces
 **/
angular.module('common.services').factory('urlService', [
  '$http',
  '$q',
  '$location',

  function($http, $q, $location) {
    'use strict';

    return {
      /**
       * Remove the baseUrl string from the urls in the entity
       * @param {string} baseUrl the base to remove
       * @param {object} entity
       * @returns {object} the entity with the processed urls
       */
      deleteBaseUrlInEntity: function(baseUrl, entity) {
        var entityString = JSON.stringify(entity);
        var regExp = new RegExp(baseUrl, 'g');
        entityString = entityString.replace(regExp, '');
        return JSON.parse(entityString);
      },
      /**
       * Returns the protocol, the address and the port of the current url
       * @returns {string} the root of the url (protocol, address and port if necessary)
       */
      getRootUrl: function() {
        var url;
        if (
          ($location.protocol().toLowerCase() === 'http' && $location.port() === 80) ||
          ($location.protocol().toLowerCase() === 'https' && $location.port() === 443)
        ) {
          url = $location.protocol() + '://' + $location.host();
        } else {
          url = $location.protocol() + '://' + $location.host() + ':' + $location.port();
        }
        return url;
      },
      /**
       * Extract the reference from the id
       * @param {string} id id or href of an entity
       * @returns {string} the reference in the id
       */
      extractReferenceFromId: function(id) {
        return id
          ? id
              .split('?')[0]
              .split('/')
              .pop()
          : id;
      },
    };
  },
]);
