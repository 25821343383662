angular.module('portailDepotDemandeAide.depot').controller('demandePaiementInformationsGeneralesController', [
  '$scope',
  '$http',
  '$stateParams',
  'paiementService',
  'demandesPaiementService',
  'Tiers',
  'configuration',
  function demandePaiementInformationsGeneralesController(
    $scope,
    $http,
    $stateParams,
    paiementService,
    demandesPaiementService,
    Tiers,
    configuration
  ) {
    'use strict';

    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsGenerales;

    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.informationsGeneralesConfiguration.ns;
    $scope.viewConfiguration = $scope.informationsGeneralesConfiguration;

    // Update viewConfiguration from teleservice definition
    _.each($scope.teleserviceConfiguration.demandePaiement.fields, function(field) {
      var reference = _.replace(field.reference, 'informations-generales.', '');
      _.set($scope, 'viewConfiguration.fields.' + reference + '.required', field.required);
      _.set($scope, 'viewConfiguration.fields.' + reference + '.hidden', field.hidden);
    });

    // Show condition on montantDemande in order to prevent the field validation to be triggered on submit
    $scope.showMontantDemande = !_.get($scope, 'viewConfiguration.fields.montantDemande.hidden', false);

    // Get typesPaiementAvecDepensesJustifiees setting
    // If dispositif is in typesPaiementAvecDepensesJustifiees array, set showMontantDepensesJustifiees to false,
    // until the type de paiement field is filled
    $scope.showMontantDepensesJustifiees = !_.get(
      $scope,
      'viewConfiguration.fields.montantDepensesJustifiees.hidden',
      false
    );
    if (_.get($scope.pageOptions, 'typesPaiementAvecDepensesJustifiees')) {
      $scope.$watch('demandePaiement.typePaiement.title', function(newValue, oldValue) {
        if ($scope.demandePaiement.title && !_.startsWith($scope.demandePaiement.title, newValue)) {
          var dateReception = moment($scope.demandePaiement.dateReception).format('DD/MM/YYYY');
          $scope.demandePaiement.title = newValue + ' du ' + dateReception;
        }
        $scope.showMontantDepensesJustifiees = _.includes(
          $scope.pageOptions.typesPaiementAvecDepensesJustifiees,
          newValue
        );

        // Delete value, so that if the field is hidden and shown again, the value won't stay the same
        if (!$scope.showMontantDepensesJustifiees) {
          delete $scope.demandePaiement.montantDepensesJustifiees;
        }
      });
    }

    $scope.informationsGeneralesConfiguration = $scope.viewConfiguration;

    /**
     * Function triggered when we naviguate to the next Step progress
     * @param {object} forget
     * @returns {void}
     */
    $scope.navigate.next = function(forget) {
      if (
        _.get($scope, 'teleserviceConfiguration.workflow.pageInformationsComplementaires.actif') &&
        $scope.displayInfosComplementaires
      ) {
        $scope.goToStep('informations-complementaires', forget);
      } else if ($scope.displayIndicateursRealisation) {
        $scope.goToStep('indicateurs', forget);
      } else if (
        _.get($scope, 'teleserviceConfiguration.workflow.pageDocumentComptable.actif') &&
        demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement)
      ) {
        $scope.goToStep('document-comptable', forget);
      } else {
        $scope.goToStep('domiciliation-bancaire', forget);
      }
    };

    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'informationsGenerales';

    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    } else {
      $scope.activePage();
    }

    const getPaiementsPromise = $stateParams.dossier
      ? demandesPaiementService.getDemandesPaiementFromDossier($scope.aide, $stateParams.dossier)
      : demandesPaiementService.getDemandesPaiementLignePlanFinancement($scope.aide, $stateParams.financeur);
    getPaiementsPromise.then((demandesPaiements) => {
      $scope.demandesPaiements = demandesPaiements || [];

      $scope.paiementsTable = paiementService.buildPaiementsTable(demandesPaiements);
    });

    // Types de paiement
    $scope.typesPaiementFiltered = _.orderBy($scope.typesPaiement, 'title');

    // Bind beneficiaire
    $scope.demandePaiement.attributaire = {
      href: _.get($scope.demandePaiement, 'demandeFinancement.expand.beneficiaires[0].href'),
      title: _.get($scope.demandePaiement, 'demandeFinancement.expand.beneficiaires[0].title'),
    };

    //Bind beneficiaire
    $http
      .get($scope.demandePaiement.demandeFinancement.expand.beneficiaires[0].href, {
        params: {
          expand: 'famille',
        },
      })
      .then(function(res) {
        $scope.demandePaiement.demandeFinancement.expand.beneficiaires[0].expand = new Tiers(res.data);
      });

    // Initialize 'montant'
    $scope.demandePaiement.montant = $scope.demandePaiement.montant || {};

    //Bind demandeur
    $http
      .get($scope.demandePaiement.demandeFinancement.expand.demandeur.href, {
        params: {
          expand: 'famille',
        },
      })
      .then(function(res) {
        $scope.demandePaiement.demandeFinancement.expand.demandeur.expand = new Tiers(res.data);
      });

    // Bind teleservice
    $http
      .get($scope.demandePaiement.demandeFinancement.expand.teleservice.href, {
        params: {
          expand: 'dispositifs',
        },
      })
      .then(function(res) {
        $scope.demandePaiement.demandeFinancement.expand.teleservice.expand = res.data;
      });

    $scope.demandePaiement.montantDepensesJustifiees = $scope.demandePaiement.montantDepensesJustifiees || {};

    // Add user infos
    $scope.demandePaiement.user = {
      href: configuration.user.accountManagement + '/users/' + $scope.currentUser.userName,
      title: $scope.currentUser.displayName,
      expand: $scope.currentUser,
    };

    $scope.isRequestedAmountSmallerThanVoted = function() {
      const montantRestantADemander = demandesPaiementService.getMontantRestantADemander(
        ($scope.demandesPaiements || []).filter((demandePaiement) => demandePaiement.statut !== 'EN_COURS'),
        $scope.decisions
      );
      var checkValidity = montantRestantADemander >= _.get($scope, 'demandePaiement.montant.ttc', 0);

      if ($scope.depotForm) {
        // check-validation-field directive has some limitations used with number-field component
        // this line assures that the validation is initialized
        $scope.depotForm.montantDemande.$setValidity('checkValidity', checkValidity);
      }

      return checkValidity;
    };

    /**
     * Allow to control typePaiement value and delete if it's not valued
     */
    $scope.onTypePaiementChange = function() {
      if (_.isEmpty($scope.demandePaiement.typePaiement)) {
        delete $scope.demandePaiement.typePaiement;
      }
    };
  },
]);
