angular.module('form.directives').directive('datetimeField', ['dateUtils', '$timeout',

  function(dateUtils, $timeout) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/datetime-field/datetime-field.html',
      require: '^form',
      scope: {
        name: '@',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=', 
        bare: '=',
        datePickerConfig: '=',
        readOnly: '=',
        remoteValidation: '=?',
        labelClass: '=?'
      },
      link: function(scope) {

        scope.forceRefresh = true;
        var watches = [];
        var timer;

        watches.push(scope.$watch('[config, viewConfiguration]', function() {
          scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
          scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');

          // Read remote validation from directive, configuration or viewConfiguration
          scope.remoteValidation = scope.remoteValidation !== undefined ? scope.remoteValidation :
            scope.configuration.remoteValidation !== undefined ? scope.configuration.remoteValidation :
              _.get(scope, 'viewConfiguration.remoteValidation') !== undefined ? _.get(scope, 'viewConfiguration.remoteValidation') : false;
        }, true));

        watches.push(scope.$watch('model', function() {
          var ngModel = null;
          if (scope.model) {
            ngModel = moment.isDate(scope.model) ? scope.model : new Date(scope.model);
          }
          scope.dateField = {
            ngModel: kendo.toString(ngModel, 'dd/MM/yyyy HH:mm'),
            // "datePickerConfig" correspond à l'attribut "k-options" du composant "kendo-date-time-picker" :
            datePickerConfig: scope.datePickerConfig ?
              scope.datePickerConfig: {
                culture: 'fr-FR',
                format: 'dd/MM/yyyy HH:mm', // Format d'affichage de la date dans le champ de saisie.
                timeFormat: 'HH:mm' // Format d'affichage de l'heure dans la liste déroulante.
              }
          };

          // Min & max based on configuration.restrictions
          dateUtils.setMinRestriction(scope.configuration.restrictions, scope.dateField);
          dateUtils.setMaxRestriction(scope.configuration.restrictions, scope.dateField);

          // Min & max based on configuration.restrictions.minDuration && configuration.restrictions.maxDuration
          if(scope.configuration && scope.configuration.restrictions) {
            scope.configuration.restrictions.filter(function(restriction){
              return (restriction.hasOwnProperty('maxDurationInclusive') || 
                restriction.hasOwnProperty('minDurationInclusive') ||
                restriction.hasOwnProperty('maxDurationExclusive') ||
                restriction.hasOwnProperty('minDurationExclusive'));
            }).forEach(function(restriction){
              dateUtils.setDurationRestriction(restriction, scope.dateField); 
            });
          }

          // dirty dirty ! force refreshing of the kendo-ui directive so that configuration modifications are applied.
          scope.forceRefresh = false;
          timer = $timeout(function() {
            scope.forceRefresh = true;
            scope.$digest();
          }, 1);

        }, true));

        scope.validateDateField = function() {
          // Case where the value doesn't respect min and max constraints. Possible if the user doesn't use the datePicker.
          if (scope.dateField.datePickerConfig.max && (scope.model > scope.dateField.datePickerConfig.max)) {
            return false;
          }
          if (scope.dateField.datePickerConfig.min && (scope.model < scope.dateField.datePickerConfig.min)) {
            return false;
          }
          return true;
        };

        // Read user input
        watches.push(scope.$watch('dateField.ngModel', function() {
          var regexIsDateTime = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;
          if (regexIsDateTime.test(scope.dateField.ngModel)) {
            scope.model = moment(scope.dateField.ngModel, 'DD/MM/YYYY HH:mm').toDate();
          }
        }));

        scope.$on('$destroy', function() {

          $timeout.cancel(timer);
          _.each(watches, function(watch) {
            watch();
          });
        });
      }
    };
  }
]);
