angular.module('portailDepotDemandeAide.depot').controller('depotSimpleDemandeurRattachementController', [
  '$scope',
  'alertsService',
  'tiersRattachementService',
  'StoreService',
  function($scope, alertsService, tiersRattachementService, StoreService) {
    'use strict';

    $scope.cleanNavigate();
    $scope.navigate.ns = 'teleservice.demandeur-rattachement';
    $scope.stepsWizard.steps = ['preambule', 'rattachement'];
    $scope.stepsWizard.active = 'rattachement';

    if ($scope.tiers) {
      $scope.goToStep('demandeur-recapitulatif', true);
    }

    $scope.demandeur = StoreService.demandeur.get();

    $scope.demandeRattachement = function() {
      var user = $scope.currentUser.getCleanEntity();
      var siret = $scope.demandeur.SIRET;

      // Url for accept or reject the rattachement
      var actionUrl = tiersRattachementService.getUrlConfirmationRattachement();
      tiersRattachementService.addDemandeRattachement(user, null, null, siret, null, actionUrl).then(
        function() {
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertSuccess('teleservice.demandeur-rattachement.success'),
            'rattachement-alerts'
          );
        },
        function(response) {
          var attempted = false;
          if (response.status === 403) {
            attempted = true;
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('teleservice.demandeur-rattachement.errors.forbidden'),
              'rattachement-alerts'
            );
          }

          if (response.status === 406) {
            attempted = true;
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('teleservice.demandeur-rattachement.errors.alreadySend'),
              'rattachement-alerts'
            );
          }

          if (attempted === false) {
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('teleservice.demandeur-rattachement.errors.generic'),
              'rattachement-alerts'
            );
          }
        }
      );
    };
  },
]);
