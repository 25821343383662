angular.module('common.services').factory('planFinancementService', [planFinancementService]);

function planFinancementService() {
  return {
    // find montant type already registered (ht or ttc) on plan de financement
    getTypeMontant: (documentComptable) => {
      if (_.get(documentComptable, 'recette.montant.ttc') || _.get(documentComptable, 'depense.montant.ttc'))
        return 'ttc';
      if (_.get(documentComptable, 'recette.montant.ht') || _.get(documentComptable, 'depense.montant.ht')) return 'ht';
      return null;
    },
  };
}
