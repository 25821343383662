'use strict';
angular
  .module('user.constants', [])
  .constant('USER_ROLES', {
    all: '*',
    admin: 'admin',
    user: 'user',
    guest: 'guest',
  })
  .constant('AUTHENTICATION_EVENTS', {
    loginSuccess: 'authentication-login-success',
    loginFailed: 'authentication-login-failed',
    logoutSuccess: 'authentication-logout-success',
    sessionTimeout: 'authentication-session-timeout',
    notAuthenticated: 'authentication-not-authenticated',
    notAuthorized: 'authentication-not-authorized',
  })
  .constant('USER_EVENTS', {
    userUpdated: 'user-updated',
  });
