class SaisieDemandeReportComponent {
  constructor(aidesService, $timeout, $state, IFrameCommunicationManager) {
    this.aidesService = aidesService;
    this._$timeout = $timeout;
    this._$state = $state;
    this.iFrameCommunicationManager = new IFrameCommunicationManager('#saisieDemandeReportIframe');
  }

  $onInit() {
    // init iFrame src
    this.saisieDemandeReportIframeSrc = this.aidesService.generateDemandeReportSaisieIframeSrc(this.demandeReference);

    this._$timeout(() => {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },
        '#saisieDemandeReportIframe'
      );
    }, 0);
  }

  /**
   * Go back to justifications component
   */
  goBack() {
    this._$state.go('app.connected.dashboard.aides.demandesAides', {
      justificationOnly: true,
    });
  }

  /**
   * Create demande report
   */
  create() {
    return this.iFrameCommunicationManager
      .sendEvent({
        action: 'create',
      })
      .manageEventWithPromise((msg, resolve, reject) => {
        const action = _.get(msg, 'data.action');
        const state = _.get(msg, 'data.state');
        if (action === 'reportCreation') {
          if (state === 'ok') {
            resolve();
          } else {
            reject();
          }
        }
      })
      .then(() => {
        this._$state.go('app.home');
      })
      .catch(() => {
        // do nothing, error message is displayed in the iFrame
      });
  }

  $onDestroy() {
    this.iFrameCommunicationManager.close();
  }
}

SaisieDemandeReportComponent.$inject = ['aidesService', '$timeout', '$state', 'IFrameCommunicationManager'];

angular.module('reports.components').component('saisieDemandeReportComponent', {
  templateUrl: 'dashboard/demandes-report/components/saisie-demande-report.html',
  controller: SaisieDemandeReportComponent,
  bindings: {
    demandeReference: '<',
  },
});
