angular.module('portailDepotDemandeAide.dashboard').component('contributionsModifications', {
  templateUrl: 'dashboard/contributions/contributions-modifications/contributions-modifications.html',
  controller: contributionsModificationsController,
  bindings: {
    contributionsDepart: '<',
  },
});

contributionsModificationsController.$inject = ['$state', '$stateParams', 'contributionsService'];

function contributionsModificationsController($state, $stateParams, contributionsService) {
  const ctrl = this;

  ctrl.$onInit = function() {
    ctrl.contributions = ctrl.contributionsDepart.contributions;
    ctrl.totalContributions = ctrl.contributionsDepart.totalContributions;
    ctrl.page = 1;
    ctrl.itemsPerPage = 20;
  };

  /**
   * Recherche les contributions
   * @param {*} resetPage - si vrai remet le numéro de page à 1
   */
  ctrl.searchContributions = function(resetPage) {
    if (resetPage) {
      ctrl.page = 1;
    }
    contributionsService
      .searchModificationsForDemande($stateParams.demande, ctrl.page - 1)
      .then(({ contributions, total }) => {
        const contribT = [];
        const contribNT = [];

        contributions.forEach((contribution) => {
          if (contribution) {
            if (
              (contribution.cloture && contribution.cloture.value) ||
              !contribution.statut ||
              !['asked', 'inprogress'].includes(contribution.statut.toLowerCase())
            ) {
              contribT.push(contribution);
            } else {
              contribNT.push(contribution);
            }
          }
        });

        ctrl.contributions = contribNT
          .sort(contributionsService.sortByDateFunction)
          .concat(contribT.sort(contributionsService.sortByDateFunction));
        ctrl.totalContributions = total;
      });
  };

  ctrl.pageDebut = function(numPage) {
    return (numPage - 1) * ctrl.itemsPerPage + 1;
  };

  ctrl.pageFin = function(numPage, contributions) {
    return (numPage - 1) * ctrl.itemsPerPage + contributions.length;
  };

  ctrl.goToListAides = function() {
    $state.go('app.connected.dashboard.aides.demandesAides', { page: $stateParams.page });
  };
}
