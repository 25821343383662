/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name select-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A field dedicated to selected a value from a list
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {Object} source - An object as returned by the mdm service for the a source
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 * @param {boolean} limit - Specify a limit to the number of elements to display (only with typeahead type)
 *
 * @example
 *
 *   `<select-field name="naf" view-configuration="identificationDemandeurConfiguration" data="demandeur" source="mdm.naf"></select-field>`
 *
 */

angular.module('form.directives').directive('selectField', ['$timeout', 'mdmService', 'mdmUtils', 'formUtils', '$filter',

  function($timeout, mdmService, mdmUtils, formUtils, $filter) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/select-field/select-field.html',
      scope: {
        name: '@',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=',
        source: '=',
        type: '@',
        bare: '=',
        limit: '=?',
        tooltips: '=?',
        readOnly: '=',
        labelWidth: '=',
        fieldWidth: '=',
        helpWidth: '=',
        onChange: '&?',
        remoteValidation: '=?',
        labelClass: '=?',
        defaultValueUndefined: '<'
      },
      link: function(scope) {
        scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
        scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');
        scope.limit = scope.limit || 20;

        // ng-options order
        var ordreOptions = _.get(scope.configuration, 'select.ordreOptions') || _.get(scope.viewConfiguration, 'select.ordreOptions');
        if(ordreOptions && _.get(scope, 'source.array')) {
          if(!_.startsWith(ordreOptions, 'expand')) {
            ordreOptions = 'expand.' + ordreOptions;
          }
          scope.source.array = $filter('orderBy')(scope.source.array, ordreOptions);
        }

        // Read remote validation from directive, configuration or viewConfiguration
        scope.remoteValidation = scope.remoteValidation !== undefined ? scope.remoteValidation :
          scope.configuration.remoteValidation !== undefined ? scope.configuration.remoteValidation :
            _.get(scope, 'viewConfiguration.remoteValidation') !== undefined ? _.get(scope, 'viewConfiguration.remoteValidation') : false;

        scope.configuration.loading = true;

        // Displays or not tooltips
        scope.tooltips = !!(scope.tooltips || scope.configuration.tooltips);

        // La source de données est une url
        if (_.get(scope.configuration, 'restrictions[0].dataURL')) {
          var keys = [scope.configuration.restrictions[0].dataURL];
          var urlSource = scope.configuration.restrictions[0].dataURL;
          mdmService.fill(keys, urlSource).then(function(result) {
            scope.source = result[scope.configuration.restrictions[0].dataURL];
            if(ordreOptions && scope.source.array) {
              scope.source.array = $filter('orderBy')(scope.source.array, ordreOptions);
            }
          });
        }

        // manage expand of specifics values
        if (scope.model && scope.model.expand && scope.model.expand.d) {
          scope.model.expand = scope.model.expand.d;
        }

        scope.$watch('source', function(newVal) {
          if (newVal) {
            var newModelValue = mdmUtils.refOrEntry(scope.source, scope.model);

            // if the binding "default-value-undefined" is set use undefined instead of null
            // (binding set to true to avoid contract error on save)
            if (newModelValue !== null || !scope.defaultValueUndefined) {
              scope.model = newModelValue;
            } else {
              scope.model = undefined;
            }

            scope.configuration.loading = false;
          }
        });

        formUtils.wrapPrimitiveInObject(scope, 'model', 'data');

        // Callback on changes
        scope.change = function() {
          if (scope.onChange) {
            // Must wait scope to update
            $timeout(scope.onChange, 0);
          }
        };
      }
    };
  }

]);
