angular.module('portailDepotDemandeAide.dashboard').controller('contributionsAvisPrealableController', [
  '$scope',
  '$state',
  'aides',
  function($scope, $state, aides) {
    $scope.typeAvis = $state.params.typeAvis;
    $scope.ns = 'connected.dashboard.contributionsAvisPrealable.' + $scope.typeAvis;

    // Liste des demandes de financement comportant un avis
    $scope.aidesWithAvis = aides;
  },
]);
