angular.module('aides').constant('JustificationsConstant', {
  justificationStatuts: {
    JUSTIFICATION_REQUESTED: 'JUSTIFICATION_REQUESTED',
    JUSTIFICATION_JUSTIFIED: 'JUSTIFICATION_JUSTIFIED',
    JUSTIFICATION_NEED_UPDATE: 'JUSTIFICATION_NEED_UPDATE',
    JUSTIFICATION_READY_TO_JUSTIFY: 'JUSTIFICATION_READY_TO_JUSTIFY',
    JUSTIFICATION_VALIDATED: 'JUSTIFICATION_VALIDATED',
  },
  justificationSubStatuts: {
    REQUESTED: 'REQUESTED',
    JUSTIFIED: 'JUSTIFIED',
    NEED_UPDATE: 'NEED_UPDATE',
    READY_TO_JUSTIFY: 'READY_TO_JUSTIFY',
    VALIDATED: 'VALIDATED',
  },
  onGoingJustificationStatuts: [
    'JUSTIFICATION_REQUESTED',
    'JUSTIFICATION_NEED_UPDATE',
    'JUSTIFICATION_READY_TO_JUSTIFY',
  ],
});
