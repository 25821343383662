angular.module('form.services').factory('noCacheInterceptor', [

  function() {

    'use strict';

    return {
      /**
			 * Add a parameter named 'timestamp' to each GET request for disable cache on ajax request on IE.
			 * We hade also specific headers for disable cache on IE too.
			 * http://stackoverflow.com/questions/16098430/angular-ie-caching-issue-for-http
			 * @param  {[type]} config Configuration of ajax request
			 */
      'request': function(config) {

        // Disable cache only for get requests
        if (config.method === 'GET' && config.url && config.url.indexOf('.html') === -1 && config.url.indexOf('.htm') === -1) {
          var separator = config.url.indexOf('?') === -1 ? '?' : '&';
          config.url = config.url + separator + 'timestamp=' + new Date().getTime();

          // Initialize if not here
          if (!config.headers) {
            config.headers = {};
          }

          // Disable IE ajax request caching
          config.headers['If-Modified-Since'] = '0';
          config.headers['Cache-Control'] = 'no-cache';
          config.headers.Pragma = 'no-cache';
        }

        return config;
      }
    };
  }
]);