/** eslint angular / document - service: 0 * /
 //jscs:disable requireCamelCaseOrUpperCaseIdentifiers
 /*jshint camelcase:false */
angular
  .module('offresStage.services')
  .factory('offresStageService', function($http, $q, $log, OffreStage, configuration, $state) {
    'use strict';
    const _url = _.get(configuration, 'teleservices.url');
    const _ressourcePath = _.get(configuration, 'offresStage.ressourcePath') || 'offres-stage';

    function extractLinkType(res) {
      const items = _.get(res, 'data._embedded.items', []);
      return items.map(function(item) {
        return {
          title: item.title,
          href: item.id || item._links.self.href,
        };
      });
    }
    const referentiels = {
      cadresDepot: null,
      secteursActiviteStructure: null,
      effectifsStructure: null,
      typesStage: null,
      typesContrat: null,
      dureesStageMarche: null,
      dureesStageSubvention: null,
      niveauxQualificationRequis: null,
    };
    return {
      referentiels: referentiels,
      /**
       * Valid an offre-stage
       * @param newOffreStage
       * @returns {HttpPromise}
       */
      validOffreStage: function(newOffreStage) {
        newOffreStage.statut = 'TRANSMITTED';
        const patches = [
          {
            op: 'replace',
            path: '/statut',
            value: 'TRANSMITTED',
          },
        ];
        return $http.patch(`${_url}/${_ressourcePath}/${newOffreStage.reference}`, patches).then(({ data }) => data);
      },
      /**
       * Get an offre-stage
       * @param {string} reference
       * @param {string} expand
       * @param {string} after
       * @returns {HttpPromise}
       */
      get: function(reference, expand, after) {
        const config = {
          params: {},
        };

        if (expand) {
          config.params.expand = expand;
        }
        if (after) {
          config.params.after = after;
        }

        return $http.get(`${_url}/${_ressourcePath}/${reference}`, config).then(({ data }) => {
          return new OffreStage(data);
        });
      },
      /**
       * Get an offre-stage by its reference
       * @param {string} reference
       */
      getOffreStage: function(reference) {
        return $http.get(`${_url}/${_ressourcePath}/${reference}`).then(({ data }) => data);
      },
      /**
       * Get the offres-stage associated with the current user
       * @param {number} skip
       * @param {number} top
       * @returns {HttpPromise}
       */
      getOffresStage: function(skip, top) {
        // use backendForFrontEnd proxy endpoint

        const options = {
          params: {
            $top: top,
            $skip: skip,
          },
        };
        return $http
          .get(`/aides/api/tenants/${_.get(configuration, 'tenant.id')}/${_ressourcePath}`, options)
          .then(({ data }) => {
            return {
              total: data.hits.total.value,
              data: data.hits.hits.map((hit) => hit._source),
            };
          });
      },
      /** Patch an offre-stage by its reference
       * @param {string} reference
       * @param {Array<object>} data
       * @returns {HttpPromise}
       */
      patch: function(reference, data) {
        return $http.patch(`${_url}/${_ressourcePath}/${reference}`, data).then(({ data }) => data);
      },
      /**
       * Remove an offre-stage
       * @param {object} offre
       * @returns {HttpPromise}
       */
      remove: function(offre) {
        return $http.delete(`${_url}/${_ressourcePath}/${offre.reference}`);
      },
      /**
       * Create an offre-stage
       * @param {object} offreStage
       * @returns {HttpPromise}
       */
      create: function(offreStage) {
        return $http
          .post(`${_url}/${_ressourcePath}`, offreStage)
          .then(({ data }) => {
            return new OffreStage(data);
          })
          .catch((err) => {
            if (err.status === 403 && _.get(err, 'data.code') === 403.1) {
              $state.go('app.connected.dashboard.accueil', {
                maximumOffresStageReached: true,
              });
            } else {
              if (err.data.message) {
                $log.error(err.data.message);
              }
            }
          });
      },
      /**
       * Update an offre-stage
       * @param {object} offreStage
       * @param {object} mdm
       * @returns {HttpPromise}
       */
      update: function(offreStage) {
        const offreStageJson = angular.toJson(offreStage);
        return $http
          .put(`${_url}/${_ressourcePath}/${offreStage.reference}`, offreStageJson)
          .then(({ data }) => {
            return new OffreStage(data);
          })
          .catch((err) => {
            if (err.status === 403 && _.get(err, 'data.code') === 403.1) {
              $state.go('app.connected.dashboard.accueil', {
                maximumOffresStageReached: true,
              });
            } else {
              if (err.data.message) {
                $log.error(err.data.message);
              }
            }
          });
      },
      /**
       * Initialize cadres-depot
       * @returns {HttpPromise}
       */
      initCadresDepot: function() {
        return $http.get(`${_url}/cadres-depot`).then(({ data }) => {
          referentiels.cadresDepot = _.get(data, '_embedded.items', []);
        });
      },
      /**
       * Initialize secteurs-activite-structure
       * @returns {HttpPromise}
       */
      initSecteursActiviteStructure: function() {
        return $http
          .get(`${_url}/secteurs-activite-structure`)
          .then(extractLinkType)
          .then((secteursActiviteStructure) => {
            referentiels.secteursActiviteStructure = secteursActiviteStructure;
          });
      },
      /**
       * Initialize effectifs-structure
       * @returns {HttpPromise}
       */
      initEffectifStructure: function() {
        return $http
          .get(`${_url}/effectifs-structure`)
          .then(extractLinkType)
          .then((effectifsStructure) => {
            referentiels.effectifsStructure = effectifsStructure;
          });
      },
      /**
       * Initialize types-stage
       * @returns {HttpPromise}
       */
      initTypesStage: function() {
        return $http
          .get(`${_url}/types-stage`)
          .then(extractLinkType)
          .then((typesStage) => {
            referentiels.typesStage = typesStage;
          });
      },
      /**
       * Initialize types-contrat
       * @returns {HttpPromise}
       */
      initTypesContrat: function() {
        return $http
          .get(`${_url}/types-contrat`)
          .then(extractLinkType)
          .then((typesContrat) => {
            referentiels.typesContrat = typesContrat;
          });
      },
      /**
       * Initialize durees-stage
       * @returns {HttpPromise}
       */
      initDureesStage: function() {
        return $q.all([
          $http
            .get(`${_url}/durees-stage-subvention`)
            .then(extractLinkType)
            .then((dureesStageSubvention) => {
              referentiels.dureesStageSubvention = dureesStageSubvention;
            }),
          $http
            .get(`${_url}/durees-stage-marche`)
            .then(extractLinkType)
            .then((dureesStageMarche) => {
              referentiels.dureesStageMarche = dureesStageMarche;
            }),
        ]);
      },
      /**
       * Get durees-stage by cadre-depot
       * @param {string} referenceCadreDepot
       */
      getDureesStage: function(referenceCadreDepot) {
        if (referenceCadreDepot === 'SUBVENTION') {
          return referentiels.dureesStageSubvention;
        } else {
          return referentiels.dureesStageMarche;
        }
      },
      /**
       * Initialize niveaux-qualification-requis
       * @returns {HttpPromise}
       */
      initNiveauxQualificationRequis: function() {
        return $http
          .get(`${_url}/niveaux-qualification-requis`)
          .then(extractLinkType)
          .then((niveauxQualificationRequis) => {
            referentiels.niveauxQualificationRequis = niveauxQualificationRequis;
          });
      },
    };
  });
