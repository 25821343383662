/**
 * @ngdoc service
 * @name tiers.services.tiersRattachementsService
 * @requires configuration, $http, $location
 * @description Attachment of a user to a tiers
 **/
angular.module('tiers.services').factory('tiersRattachementService', [
  'configuration',
  '$http',
  '$location',

  function(configuration, $http, $location) {
    'use strict';

    let rattachementsConfig;

    function _getUrl() {
      return configuration.tiers.rattachement.url;
    }

    return {
      getUrlConfirmationRattachement: function() {
        // Url for accept or reject the rattachement
        var actionUrl = null;
        if (_.get(configuration, 'tiers.rattachement.rattachementConfirmationPath')) {
          actionUrl = $location
            .absUrl()
            .replace($location.url(), configuration.tiers.rattachement.rattachementConfirmationPath);
        }

        return actionUrl;
      },

      getDemandeRattachement: function(demandeRattachementId) {
        return $http.get(_getUrl() + '/tiers/rattachements/' + demandeRattachementId);
      },

      /**
       * Creation of a rattachement request
       */
      addDemandeRattachement: function(user, individu, coordonnees, siret, pieces, actionUrl, familleTiers) {
        var demandeRattachement = {
          user: user,
          individu: individu,
          coordonnees: coordonnees,
          siret: siret,
          pieces: pieces,
          famille: familleTiers,
        };

        return $http.post(_getUrl() + '/tiers/rattachements', demandeRattachement, {
          params: {
            actionUrl: actionUrl,
          },
        });
      },

      /**
       * Accept a rattachement
       * @param  {String} demandeRattachement Rattachement object
       */
      validDemandeRattachement: function(demandeRattachement) {
        var demandeRattachementId = demandeRattachement.id;
        return $http.put(_getUrl() + '/tiers/rattachements/' + demandeRattachementId, demandeRattachement);
      },

      /**
       * List of rattachements
       * @type {[type]}
       */
      listDemandesRattachement: function() {
        return $http.get(_getUrl() + '/tiers/rattachements/');
      },

      /**
       * Get rattachements config from gestion-depot-demandes public settings
       * (documents allowed extensions, max size, etc)
       */
      getRattachementsConfig() {
        if (!rattachementsConfig) {
          rattachementsConfig = $http
            .get(configuration.publicSettingsGestionDepotDemandes.service)
            .then(function(response) {
              return _.get(response, 'data.rattachements');
            });
        }

        return rattachementsConfig;
      },

      /**
       * Get URL to upload rattachement document
       */
      getDocumentUploadUrl() {
        return `${_getUrl()}/tiers/rattachements/documents`;
      },
    };
  },
]);
