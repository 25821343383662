angular.module('aides').component('comiteRecevabilite', {
  templateUrl: 'aides/aides-components/recapitulatif/comite-recevabilite/comite-recevabilite.component.html',
  bindings: {
    aide: '<',
  },
  controller: comiteRecevabilite,
});

function comiteRecevabilite(comitesRecevabiliteService, COMITE_STATUS, tiersService) {
  var vm = this;

  this.$onInit = function() {
    return tiersService.getCurrentTiers().then(function(tiers) {
      var financeur = _.find(_.get(vm, 'aide.multiFinancement.financeurs'), function(financeur) {
        return financeur.href === tiers.id;
      });
      if (financeur) {
        vm.COMITE_STATUS = COMITE_STATUS;
        var comiteHref = _.get(vm, 'aide.comiteRecevabilite.href');
        if (comiteHref) {
          comitesRecevabiliteService.getByHref(comiteHref).then(function(res) {
            vm.comiteRecevabilite = res.data;
          });
        }
      }
    });
  };
}
