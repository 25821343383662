/**
 * @ngdoc module
 * @module contributions
 * @name contributions
 * @description
 *
 *   The main module for all contributions related services and directives for our portals
 *
 */
angular.module('contributions', [
  'common',
  'contributions.services',
  'contributions.components',
  'contributions.constants',
]);

angular.module('contributions.services', []);

angular.module('contributions.components', ['contributions.services']);
