angular.module('form.services').factory('validationService', [

  function() {

    'use strict';

    return {
      /**
       * Vérifie la bonne écriture d'une adresse mail (RFC #822 for autorized characters, RFC#3696 who correct RFC #2821
       * for the maximal autorize length)
       * Also check http://jira.mgdis.fr/browse/PADA-12
       *
       * @param  {string} email Email à valider
       * @return {boolean}      True si l'email est valide, false sinon
       */
      verifyEmail: function(email) {

        if (!email || email.length === 0) {
          return true;
        } else {
          return validator.isEmail(email);
        }
      },
      /**
       * Méthode qui vérifie la validité du siret
       * @param  {String} siren Siren
       * @param  {String} nic   Nic
       * @return {Boolean} Indique si le siret est valide
       */
      verifySiret: function(siren, nic) {

        var validity = false;

        if (siren && nic && siren.length === 9 && nic.length === 5) {
          var siret = siren + nic;
          var somme = 0;
          var tmp;

          if (parseInt(siren) !== 356000000 || (parseInt(siren) === 356000000 && parseInt(nic, 10) === parseInt('00048', 10))) {
            for (var cpt = 0; cpt < siret.length; cpt++) {
              if ((cpt % 2) === 0) {
                tmp = parseInt(siret.charAt(cpt)) * 2;
                if (tmp > 9) {
                  tmp -= 9;
                }
              } else {
                tmp = parseInt(siret.charAt(cpt));
              }
              somme += parseInt(tmp);
            }
          } else {
            for (var i = 0; i < siret.length; i++) {
              somme += parseInt(siret.charAt(i));
            }
          }

          if ((somme % 10) === 0 || (parseInt(siren) === 356000000 && (somme % 5) === 0)) {
            validity = true;
          }
        }

        return validity;
      },
      verifyUrl: function(str) {

        if (str === undefined || str === null || str === '') {
          return true;
        } else {
          /* eslint camelcase: 0 */
          return validator.isURL(str, {require_protocol: true});
        }
      }
    };
  }
]);