angular.module('portailDepotDemandeAide.contact', ['form']).controller('contactController', [
  '$scope',
  '$state',
  '$http',
  '$location',
  '$anchorScroll',
  'configuration',
  'validationService',
  'alertsService',
  function($scope, $state, $http, $location, $anchorScroll, configuration, validationService, alertsService) {
    'use strict';

    $scope.mdm = $scope.mdm || {};
    $scope.viewConfiguration = $scope.viewConfiguration || {};
    $scope.captcha = {};
    $scope.configuration = configuration;
    $scope.validationService = validationService;
    $scope.alertShow = false;

    $scope.contactSuccess = false;

    $scope.message = {};
    $scope.documents = [];

    $scope.viewConfiguration.displayCharactersLeft = true;
    $scope.extensionsAllowed =
      $scope.rattachementsDocumentConfiguration && $scope.rattachementsDocumentConfiguration.allowedDocumentExtension;

    // If a user is connected, the identification part of the form is initialized with their contact information
    var initIdentity = function() {
      if ($scope.currentUser) {
        $scope.message.civilite = _.get($scope.currentUser, 'name.honorificPrefix');
        $scope.message.nom = _.get($scope.currentUser, 'name.familyName') || '';
        $scope.message.nom = $scope.message.nom.toUpperCase();
        $scope.message.prenom = _.get($scope.currentUser, 'name.givenName');
        $scope.message.email = _.get($scope.currentUser, 'emails.0');
      } else {
        $state.go('app.contact-page');
      }
    };
    initIdentity();

    /**
     * Builds the FormData and sends it to the contact service by $http
     * Once the mail is sent, displays a success message
     */
    $scope.sendMail = function() {
      // Registering the user's page in the message
      $scope.message.contexte = $location.absUrl();
      $scope.message.date = new Date().toLocaleString();

      // Initializing the formData
      var contactData = new FormData();
      for (var key in $scope.message) {
        if ($scope.message.hasOwnProperty(key)) {
          contactData.append(key, $scope.message[key]);
        }
      }
      // The files have to be added one by one
      for (var i = 0; i < $scope.documents.length; i++) {
        contactData.append('documents', $scope.documents[i]);
      }

      var params = {
        transformRequest: angular.identity,
        headers: {
          'Content-Type': undefined,
        },
      };
      var responseCaptcha = $scope.captcha.response;
      if (responseCaptcha) {
        params.params = {
          recaptchaResponse: responseCaptcha,
        };
      }

      // Sending the formData
      $http.post(configuration.contact.service, contactData, params).then(
        function() {
          // Display a success message and hide the eventual alert
          $scope.alerts = alertsService.getAlertSuccess('contact.sent');

          if (responseCaptcha) {
            $scope.captcha = {};
            $scope.$broadcast('reload-captcha');
          }
          // Reinitialize the form
          $scope.contactForm.$setPristine();
          $scope.documents.splice(0);
          $scope.message = {};
          initIdentity();
          $scope.alertShow = true;
          $scope.contactSuccess = true;
        },
        function() {
          // Show an error alert
          if (responseCaptcha) {
            $scope.captcha = {};
            $scope.$broadcast('reload-captcha');
          }

          $scope.alerts = alertsService.getAlertError('contact.error');
        }
      );
    };

    // Allow to control message civilite value and delete if it's not valued
    $scope.onCiviliteChange = function() {
      if (_.isEmpty($scope.message.civilite)) {
        delete $scope.message.civilite;
      }
    };

    // Allow to control message motif value and delete if it's not valued
    $scope.onMotifChange = function() {
      if (_.isEmpty($scope.message.motif)) {
        delete $scope.message.motif;
      }
    };
  },
]);
