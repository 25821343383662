angular.module('form.services').factory('gridUtils', ['$compile', '$templateCache', '$log',

  function($compile, $templateCache, $log) {
    'use strict';

    function fieldEditor(options) {
      var tplSrc = options.template || $templateCache.get(options.templateUrl);

      if(!tplSrc) {
        $log.error('No template defined for editable grid field editor. Options: ', options);
        return;
      }

      var tpl = $compile(tplSrc, options.transclude);

      return function(container, kendoOptions) {
        var editorScope = options.scope.$new();
        editorScope.kendoOptions = kendoOptions;
        tpl(editorScope, null, null, options.transclude).appendTo(container);
        editorScope.$digest();
      };
    }

    return {
      fieldEditor: fieldEditor
    };
  }
]);
