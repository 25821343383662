angular.module('portailDepotDemandeAide.depot').controller('demandePaiementInformationsComplementairesController', [
  'viewsService',
  '$scope',
  '$timeout',
  'demandesPaiementService',
  'IFrameCommunicationManager',
  function demandePaiementInformationsComplementairesController(
    viewsService,
    $scope,
    $timeout,
    demandesPaiementService,
    IFrameCommunicationManager
  ) {
    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageInformationsComplementaires', {
      actif: false,
    });
    $scope.cleanNavigate();

    $scope.navigate.ns = $scope.informationsComplementairesConfiguration.ns;
    $scope.viewConfiguration = $scope.viewConfiguration;

    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'informationsGenerales';

    const iFrameCommunicationManagers = [];
    const iFrameCommunicationManager = new IFrameCommunicationManager();
    iFrameCommunicationManagers.push(iFrameCommunicationManager);
    // If page is not active we just go next
    if ($scope.pageOptions.actif) {
      $scope.activePage();
    } else {
      $scope.navigate.next(true);
    }
    this.$onInit = function() {
      // Get iframe's URL
      $scope.iframeInformationsComplementairesPaiement = viewsService.getViewsIframeUrl(
        $scope.demandePaiement,
        'pageInformationsComplementaires',
        'demandeur',
        false,
        5,
        null,
        {
          'referentiel-tiers': [
            'Accept:application/vnd.mgdis.tiers-3.19.0+json',
            'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
          ],
        }
      );
      // Add iframe's listener to update demande
      iFrameCommunicationManager.manageEvent(iframeEventListener);
    };

    $scope.$on('$destroy', function() {
      _.each(iFrameCommunicationManagers, (manager) => {
        manager.close();
      });
    });

    function iframeEventListener(msg) {
      const action = _.get(msg, 'data.action');
      const source = _.get(msg, 'data.source');

      // Update view's datas on current demande de paiement
      if (action === 'viewsUpdated') {
        const index = _.get(msg, 'data.index');
        const values = _.get(msg, 'data.values');
        _.set($scope, 'demandePaiement.views.' + index + '.values', values);
      }

      // Send context to evaluate display's condition
      if (source === 'data-schemas.views' && action === 'ready') {
        const viewsIframeInit = _.get(angular.element('#iframeInformationsComplementairesPaiement'), '0', null);
        if (viewsIframeInit) {
          viewsIframeInit.contentWindow.postMessage(
            {
              action: 'updateContextExpressions',
              contextExpressions: JSON.parse(JSON.stringify($scope.demandePaiement)),
            },
            '*'
          );
        }
      }
    }

    /**
     * Function triggered when we naviguate to the next Step progress
     * @param {object} forget
     * @returns {void}
     */
    $scope.navigate.next = function(forget) {
      const goToNextStep = () => {
        if ($scope.displayIndicateursRealisation) {
          $scope.goToStep('indicateurs', forget);
        } else if (
          _.get($scope, 'teleserviceConfiguration.workflow.pageDocumentComptable.actif') &&
          demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement)
        ) {
          $scope.goToStep('document-comptable', forget);
        } else {
          $scope.goToStep('domiciliation-bancaire', forget);
        }
      };

      if (_.isEmpty(_.get($scope, 'demandePaiement.views', []))) {
        goToNextStep();
      } else {
        const viewsCommunicationManager = new IFrameCommunicationManager('#iframeInformationsComplementairesPaiement');
        const viewsValidationPromise = viewsCommunicationManager
          .sendEvent({
            action: 'validViews',
          })
          .manageEventWithPromise((msg, resolve, reject) => {
            viewsService.updateStateViewsPaiement($scope, msg, resolve, reject);
          });
        iFrameCommunicationManagers.push(viewsCommunicationManager);

        viewsValidationPromise
          .then(() => {
            goToNextStep();
          })
          .catch(() => {
            // iframe validation / user rejected
          });
      }
    };

    $timeout(function() {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },
        '#iframeInformationsComplementairesPaiement'
      );
    }, 0);
  },
]);
