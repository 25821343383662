angular.module('common.directives').directive('themeManagement', themeManagement);

/* @ngInject */
function themeManagement($transitions, themeManagementService) {
  return {
    restrict: 'A',
    link: () => {
      // If accessing app.connected.config.depot.simple from an outside source, check for theme in params
      $transitions.onBefore(
        {
          from: '',
          to: 'app.connected.config.depot.simple',
        },
        themeManagementService.registerTheme
      );
      // Apply stored theme
      $transitions.onStart({}, themeManagementService.applyTheme);
      // If accessing app.connected.config.depot.simple from anywhere but outside or itself, put the saved theme in the query param
      $transitions.onSuccess(
        {
          from: (state) => state.name && state.name !== 'app.connected.config.depot.simple',
          to: 'app.connected.config.depot.simple',
        },
        themeManagementService.themeInParams
      );
    },
  };
}
