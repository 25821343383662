angular.module('portailDepotDemandeAide.user').controller('accountResetPasswordController', [
  '$scope',
  'userAccountResetPasswordConfiguration',
  'username',
  'token',
  function($scope, userAccountResetPasswordConfiguration, username, token) {
    'use strict';

    // Give access the configuration of the view
    $scope.userAccountResetPasswordConfiguration = userAccountResetPasswordConfiguration;

    $scope.username = username;
    $scope.token = token;
  },
]);
