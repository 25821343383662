angular.module('alerts', ['pascalprecht.translate', 'alerts.templates']);
(function () {
  'use strict';

  angular
    .module('alerts')
    .factory('alertsService', alertsService);

  alertsService.$inject = ['$translate'];

  function alertsService($translate) {

    /**
     * Create an alert
     * @param  Type of alert. Values possible : error, fielderror, success, warning, info
     * @param  Message of the alert
     * @param  {String} title      (optional) Title of the alert
     * @return Alerts
     */

    function getAlert(type, message, parameters, title) {
      var alerts = [];
      var alert = {};

      $translate(message, parameters).then(function (messageResolved) {
        alert.messages = [messageResolved];
      }, function () {
        // Original message. Message not outsourced in a file
        alert.messages = [message];
      });
      alert.type = type;
      if (title) {
        alert.title = title;
      }
      alerts.push(alert);
      return alerts;
    }

    return {
      /**
       * Function who create an alert of type error
       * @param  {String} message    Message of the alert
       * @param  {Array} parameters  Parameters of the message
       * @param  {String} title      (optional) Title of the alert
       * @return {Array}             Alerts
       */
      getAlertError: function (message, parameters, title) {
        return getAlert('error', message, parameters, title);
      },
      /**
       * [getAlertWarning description]
       * @param  {String} message    Message of the alert
       * @param  {Array} parameters  Parameters of the message
       * @param  {String} title      (optional) Title of the alert
       * @return {Array}             Alerts
       */
      getAlertWarning: function (message, parameters, title) {
        return getAlert('warning', message, parameters, title);
      },
      /**
       * [getAlertInfo description]
       * @param  {String} message    Message of the alert
       * @param  {Array} parameters  Parameters of the message
       * @param  {String} title      (optional) Title of the alert
       * @return {Array}             Alerts
       */
      getAlertInfo: function (message, parameters, title) {
        return getAlert('info', message, parameters, title);
      },
      /**
       * [getAlertSuccess description]
       * @param  {String} message    Message of the alert
       * @param  {Array} parameters  Parameters of the message
       * @param  {String} title      (optional) Title of the alert
       * @return {Array}             Alerts
       */
      getAlertSuccess: function (message, parameters, title) {
        return getAlert('success', message, parameters, title);
      }
    };
  }
})();

(function () {

  'use strict';

  angular
    .module('alerts')
    .directive('alertsCenter', alertsCenter);

  alertsCenter.$inject = ['$window', '$log'];

  function alertsCenter($window, $log) {
    return {
      restrict: 'A',
      replace: true,
      transclude: false,
      templateUrl: 'alerts/alerts-directives/alerts-center/alerts-center.html',
      scope: {
        id: '=',
        alerts: '=?'
      },
      link: function (scope, element) {

        /**
         * Function who listen an evenement of type 'alerts'
         * @param  {[type]} event  Evenement
         * @param  {[type]} alerts Alerts to display
         * @param  {[type]} id     Od of an alerts directive
         */
        scope.$on('alerts', function (event, alerts, id) {
          if (id === null || id === scope.id) {
            scope.alerts = alerts;

            // Scroll to view alerts, including if we are integrated in an iframe
            if (alerts && alerts.length) {
              var parent;
              try {
                parent = $window.parent;
              } catch (e) {
                // Happens in case of cross domain iframe integration
              }

              if (!parent || parent === $window.self) {
                return $window.scrollTo(0, element.offset().top);
              }

              if (!$window.frameElement) {
                return $log.warning('alertsService - Iframe element is not named, its offset can not be used to manage scroll');
              }

              var globalOffset = $($window.frameElement).offset().top + element.offset().top;

              // 160 est arbitraire et correspond surtout à l'intégration dans portail-acteurs-eco
              // TODO: meilleure manière de faire ?
              parent.scrollTo(0, globalOffset - 160 > 0 ? globalOffset - 160 : 0);
            }
          }
        });

        scope.$on('hideAlerts', function () {
          scope.hide();
        });

        /**
         * Function who hide alerts
         */
        scope.hide = function () {
          scope.alerts = [];
        };
      }
    };
  }
})();

angular.module("alerts.templates", []).run(["$templateCache", function($templateCache) {$templateCache.put("alerts/alerts-directives/alerts-center/alerts-center.html","<div class=\"bgcolor\">\n    <div ng-repeat=\"alert in alerts\">\n        <div ng-switch on=\"alert.type\">\n            <div ng-switch-when=\"error\" class=\"alert alert-danger\">\n                <a class=\"close\" ng-click=\"hide()\">×</a>\n                <i class=\"fa fa-exclamation-triangle\"></i>&nbsp;&nbsp;\n                <strong>{{alert.title || \'alert.error\' | translate}}</strong>\n                <br />\n                <span ng-repeat=\"message in alert.messages\" ng-bind-html=\"message + \'<br/>\'\"></span>\n            </div>\n            <div ng-switch-when=\"success\" class=\"alert alert-success\">\n                <a class=\"close\" ng-click=\"hide()\">×</a>\n                <i class=\"fa fa-check-circle\"></i>&nbsp;&nbsp;\n                <strong>{{alert.title || \'alert.validation\' | translate}}</strong>\n                <br />\n                <span ng-repeat=\"message in alert.messages\" ng-bind-html=\"message + \'<br/>\'\"></span>\n            </div>\n            <div ng-switch-when=\"warning\" class=\"alert alert-warning\">\n                <a class=\"close\" ng-click=\"hide()\">×</a>\n                <i class=\"fa fa-exclamation-triangle\"></i>&nbsp;&nbsp;\n                <strong>{{alert.title || \'alert.warning\' | translate}}</strong>\n                <br />\n                <span ng-repeat=\"message in alert.messages\" ng-bind-html=\"message + \'<br/>\'\"></span>\n            </div>\n            <div ng-switch-default class=\"alert alert-info\">\n                <a class=\"close\" ng-click=\"hide()\">×</a>\n                <span ng-repeat=\"message in alert.messages\" ng-bind-html=\"message + \'<br/>\'\"></span>\n            </div>\n        </div>\n    </div>\n</div>");}]);