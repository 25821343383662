angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotOffresStageConfirmationController', ['$scope', '$state', '$sce', portailDepotDemandeAide]);

function portailDepotDemandeAide($scope, $state, $sce) {
  var ctrl = this;
  ctrl.ns = 'offres-stage';

  ctrl.getFooter = function() {
    return $sce.trustAsHtml(_.get($scope, 'workflow.pageConfirmation.footer'));
  };

  ctrl.getHeader = function() {
    return $sce.trustAsHtml(_.get($scope, 'workflow.pageConfirmation.header'));
  };

  function init() {
    $scope.cleanNavigate();
    $scope.navigate.ns = 'teleservice.confirmation';

    $scope.stepsWizard.steps = [];
    $scope.stepsWizard.active = null;

    $scope.navigate.finish = function() {
      $state.go('app.connected.dashboard.accueil');
    };
  }
  init();
}
