angular.module('tiers.model').factory('Tiers', [
  'TiersModel',
  function(TiersModel) {
    'use strict';

    /**
     * Classe Tiers. Objet canonique Tiers
     * @classdesc Tiers canonique
     */

    function Tiers(data, mdm) {
      var tiersModel = new TiersModel(data, mdm);

      return tiersModel.tiers;
    }

    return Tiers;
  },
]);

angular.module('tiers.model').factory('Siret', function() {
  'use strict';

  /**
   * Classe Siret. Représente le SIRET d'une société
   * @param {Object} data Données du SIRET en persistence utilisé pour initialiser l'objet
   */

  function Siret(data) {
    data = data || {};
    this.SIREN = data.SIREN;
    this.NIC = data.NIC;
  }

  return Siret;
});

angular.module('tiers.model').factory('Nir', function() {
  'use strict';

  /**
   * Classe Nir. Représente le numéro d'identification des personnnes physiques.
   * @param {Object} data Données en persistance
   * @class
   * @classdesc Numéro d'identification des personnes physiques.
   * Le NIR est un numéro à treize caractères dont la composition est précisée dans l'article 4 du décret n° 82-103 du 22 janvier 1982 :
   * Le numéro attribué à chaque personne inscrite au répertoire comporte 13 chiffres.
   * Ce numéro indique successivement et exclusivement le sexe (1 chiffre), l'année de naissance (2 chiffres), le mois de naissance (2 chiffres),
   * et le lieu de naissance (5 chiffres ou caractères) de la personne concernée.
   * Les trois chiffres suivants permettent de distinguer les personnes nées au même lieu, à la même période.
   */

  function Nir(data) {
    data = data || {};
    this.NIR = data.NIR;
    this.CleNIR = data.CleNIR;
  }

  return Nir;
});

angular.module('tiers.model').factory('Pcs', function() {
  'use strict';

  /**
   * Classe Pcs. Représente le code de la nomenclature des Professions et Catégories Socioprofessionnelles 2003
   * @param {Object} data Données en persistance
   * @class
   * @classdesc
   */

  function Pcs(data) {
    data = data || {};
    this.Niv1 = data.Niv1;
    this.Niv2 = data.Niv2;
    this.Niv3 = data.Niv3;
    this.Niv4 = data.Niv4;
  }

  return Pcs;
});
