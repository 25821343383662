/**
 * @ngdoc compotenant
 * @module aides
 * @name documentsComptablesAideForm
 * @restrict 'EA'
 * @description
 *
 *   Plan de financement éditable d'une aide
 *
 * @param {object} documentComptable - document's model
 * @param {string} typeMontant - type to display after the financial values (ex: 'ttc')
 * @param {object} viewConfiguraton - view configuration
 * @param {boolean} equilibre - initial value of the balance
 * @example
 *
 *  `<documents-comptables-aide-form
 *    document-comptable="demande.plan.fonctionnement"
 *    view-configuration="documentComptableConfiguration"
 *    type-montant="documentComptableConfiguration.typeMontant[0]"
 *    equilibre="equilibre">
 *  </documents-comptables-aide-form>`
 *
 */
angular.module('aides').component('documentsComptablesAideForm', {
  templateUrl: 'aides/aides-directives/documents-comptables-aide-form/documents-comptables-aide-form.html',
  controller: DocumentsComptablesAideFormController,
  bindings: {
    aide: '<',
    typeMontant: '<',
    viewConfiguration: '<',
    equilibre: '=',
    pageOptions: '<',
    readOnly: '<?',
    teleserviceConfiguration: '<',
    isContribution: '<?',
    postesPlanFinancement: '<',
  },
  require: {
    form: '^^form',
  },
});

angular.module('aides').directive('stringToNumber', function() {
  return {
    require: ['ngModel', '^^documentsComptablesAideForm'],
    restrict: 'A',
    link: function(scope, element, attrs, controllers) {
      const ngModel = controllers[0];
      const $ctrl = controllers[1];

      ngModel.$parsers.push(function(value) {
        //? how it is represented in memory
        if (!value) return null;
        return parseInt(value);
      });
      ngModel.$formatters.push(function(value) {
        //? how it is represented in the view
        if (!value) return;
        return parseInt(value);
      });
      ngModel.$validators.minmax = function(modelValue) {
        if (!modelValue) return true;
        if (!$ctrl.millesimeAnneeSeule) return true;

        return !(moment(modelValue).isAfter($ctrl.nextYear) || moment(modelValue).isBefore($ctrl.currentYear));
      };
    },
  };
});

angular.module('aides').directive('dateFieldHandler', [
  function() {
    return {
      require: ['ngModel', '^^documentsComptablesAideForm'],
      restrict: 'A',
      priority: 0,
      link: function(scope, element, attrs, controllers) {
        const ngModel = controllers[0];
        const validDateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
        /**
         * This parser and formatter are used to display the date as DD/MM/YYYY on the view but save it as an iso date in the memory
         */
        ngModel.$parsers.push(function(value) {
          //? how it is represented in memory

          if (!value) return null; //? must return null because undefined cannot be parsed
          return moment(value, 'DD/MM/YYYY').toISOString();
        });
        ngModel.$formatters.push(function(value) {
          //? how it is represented in the view
          if (!value) return;
          return moment(value).format('DD/MM/YYYY');
        });
        ngModel.$validators.pattern = (modelValue, viewValue) => {
          //? override default pattern validator to use the viewValue instead of the modelValue
          if (!viewValue) return true;
          return validDateRegex.test(viewValue);
        };
      },
    };
  },
]);

DocumentsComptablesAideFormController.$inject = ['$scope', 'aidesService', '$modal', '$translate'];

function DocumentsComptablesAideFormController($scope, aidesService, $modal, $translate) {
  'use strict';

  let isMillesimeFieldVisible = false;
  let isExerciceFieldHidden = false;

  this.$onInit = () => {
    const retrieveExerciceFieldVisibility = (teleserviceConfiguration) => {
      const informationsGeneralesFields = _.get(
        teleserviceConfiguration,
        'workflow.pageInformationsGenerales.demandeFinancement.fields',
        []
      );
      const exerciceBudgetaireField = _.find(informationsGeneralesFields, ['reference', 'exerciceBudgetaire']);
      return !!exerciceBudgetaireField && exerciceBudgetaireField.hidden;
    };
    this.anneesComptables = [];
    this.hidePrecisions = this.pageOptions.masquerPrecision;
    this.precisionObligatoire = this.pageOptions.precisionObligatoire;
    this.millesimeAffiche = this.pageOptions.afficherMillesime;
    this.paramPeriode = this.pageOptions.periode || { hidden: true };
    this.paramDuree = this.pageOptions.duree || {};
    this.dureeMin = _.get(this, 'paramDuree.min', 2);
    this.dureeMax = _.get(this, 'paramDuree.max', 5);
    this.dateCreation = _.get(this.aide, 'history.begin.date', '');

    this.currentPlanFinancement = aidesService.getCurrentPlanFinancement(this.aide);

    const multiFinanceur = _.get(this.teleserviceConfiguration, 'multiFinanceur.active');
    const modePreInstruction = _.get(this.teleserviceConfiguration, 'multiFinanceur.options.modePreInstruction');
    const affichageMillesime = this.pageOptions.affichageMillesime;

    this.isMultiFinanceursPartage = multiFinanceur && modePreInstruction === 'PARTAGE';
    this.millesimeAnneeSeule = this.millesimeAffiche && affichageMillesime === 'ANNEE_SEULE';

    this.typeAffichage = this.pageOptions.affichageMillesime;
    this.nextYear = moment()
      .add(1, 'years')
      .year();

    this.anneeField = {
      min: '0999-12-31T23:00:00.000Z',
      max: '9998-12-31T23:00:00.000Z',
      datePickerConfig: {
        format: 'yyyy',
        culture: 'fr-FR',
        start: 'decade',
        depth: 'decade',
      },
    };

    this.currentYear = moment().year();
    this.isEmpty = _.isEmpty;
    this.errorMillesimeMinMax = $translate.instant('aides.document-comptable-aide-form.millesime.error.exerciceDepart');
    this.errorMillesimeRequired = $translate.instant('aides.document-comptable-aide-form.millesime.error.required');
    this.errorMillesimeInvalid = $translate.instant('aides.document-comptable-aide-form.millesime.error.invalid');
    this.dateDebutSup = $translate.instant('aides.document-comptable-aide-form.millesime.error.dateFinSup');

    this.dureeTmp = this.aide.duree;

    // we don't want to mutate paramPeriode (don't use assign or merge)
    this.paramPeriode.reference = _.get(this, 'paramPeriode.required') && !this.readOnly;
    this.paramDuree.required = !this.readOnly;

    this.viewConfiguration.fields.millesime = {
      reference: 'millesime',
      affichageMillesime: this.pageOptions.affichageMillesime,
      afficherMillesime: this.pageOptions.afficherMillesime,
    };

    isMillesimeFieldVisible = _.get(
      this.teleserviceConfiguration,
      'workflow.pageDocumentComptable.afficherMillesime',
      false
    );
    isExerciceFieldHidden = retrieveExerciceFieldVisibility(this.teleserviceConfiguration);
  };

  this.isCpo = () => {
    return this.isMultiFinanceursPartage && this.millesimeAnneeSeule && this.aide.periode === 'PLURIANNUELLE';
  };

  /**
   * Permet de réagir à la modification du champs durée
   */
  const watchModificationDuree = (() => {
    const showPopin = (newValue, oldValue) => {
      const hasValueChanged =
        !_.isUndefined(newValue) && // the field is not empty
        newValue !== this.dureeTmp && // the value differed from the previous one
        newValue !== oldValue && // special case onInit, watcher is triggered with equals new & old value
        newValue >= this.dureeMin && // the value < 2 are not considered
        newValue <= this.dureeMax && // the value > 5 are not considered
        newValue < this.dureeTmp; // the popin should appear only if we reduce the number of plans

      // recovering all montants of the N+X plans not equal to 0, if it's not empty we should show the popin
      const montants = JSONPath(
        '$.planFinancement[1:]..postes[?(@.montant && @.montant.ttc != 0 && @.montant.ht != 0)]',
        this.aide
      );

      return hasValueChanged && !_.isEmpty(montants);
    };

    return $scope.$watch('$ctrl.aide.duree', (newVal, oldVal) => {
      if (!newVal || newVal === oldVal) return;
      if (this.aide.planFinancement.length > 1 && showPopin(newVal, oldVal)) {
        const scopeModal = $scope.$new();
        scopeModal.title = this.viewConfiguration.ns + '.duree.confirmation.title';
        scopeModal.message = this.viewConfiguration.ns + '.duree.confirmation.message';
        scopeModal.onConfirm = () => {
          this.dureeTmp = newVal;
        };
        scopeModal.onDecline = () => {
          this.aide.duree = this.dureeTmp;
        };
        $modal({
          scope: scopeModal,
          template: 'common/common-modal/confirmation-modal.html',
          backdrop: 'static',
        });
      } else {
        this.dureeTmp = newVal;
      }
    });
  })();

  /**
   * This watcher is needed because aidesService#saveAide replaces the reference of aide.planFinancement
   * so we need a way to update this.currentPlanFinancement with the new reference
   */
  const watchPlanFinancement = (() => {
    return $scope.$watch('$ctrl.aide.planFinancement', (newVal) => {
      if (newVal) {
        this.currentPlanFinancement = aidesService.getCurrentPlanFinancement(this.aide);
        const firstYear = aidesService.getFirstYearOfPf(this.aide.planFinancement);
        this.isFirstPlanFinancement = _.get(this.currentPlanFinancement, 'periode.exercice', 0) === firstYear;
      }
    });
  })(); //! direct function call to initialize the watcher and keep a reference to the returned function to destroy it later

  /**
   *  watch pour determiner le caractere obligatoire du millesime
   */
  const watchCurrentPf = (() => {
    return $scope.$watch(
      '$ctrl.currentPlanFinancement',
      () => {
        this.ligneRenseignee = checkLignes(this.currentPlanFinancement);
      },
      true
    );
  })();

  this.$onDestroy = () => {
    watchModificationDuree();
    watchCurrentPf();
    watchPlanFinancement();
  };

  this.isPremierExercice = () => {
    return !_.has(this, 'aide.history.begin.metadata.stepMetadata.exercice');
  };

  /**
   * Le champ durée doit être est affiché ?
   * il faut etre en multifinanceur partagé avec gestion du millesime en annee seul et gestion de la periode en pluriannuelle
   */
  this.isDureeAffichee = () => {
    return this.isCpo();
  };

  const unsetDuree = () => {
    if (this.isContribution) {
      // The contribution process explicitly needs a null value to remove it
      // when merging with demande-financement
      this.aide.duree = null;
    } else {
      _.unset(this.aide, 'duree');
    }
  };

  /**
   * Affichage d'une fenêtre de validation
   * SI le porteur de projet passe la demande de pluriannuelle à annuelle
   * ET qu'il y a des données saisies dans les budgets N+X
   *
   * @param newValue Nouvelle selection de la période
   * @param oldValue Ancienne selection de la période
   */
  this.onPeriodeChange = (newValue, oldValue) => {
    const pluriannuelleToAnnuelle = newValue === 'PONCTUELLE' && oldValue === 'PLURIANNUELLE';
    if (pluriannuelleToAnnuelle) {
      const plansFinancementsRemplis = _.filter(this.aide.planFinancement, function(pf) {
        return checkLignes(pf);
      });
      const hasManyBudget = plansFinancementsRemplis.length > 1;
      if (hasManyBudget) {
        // Si on change la période de pluriannuelle en annuelle, on affiche un message d'avertissement
        // de suppression des plans de financement saisis seulement dans le cas où il y a plus d'un plan de financement déjà saisi.
        const scopeModal = $scope.$new();
        scopeModal.title = this.viewConfiguration.ns + '.periode.change.confirmation.title';
        scopeModal.message = this.viewConfiguration.ns + '.periode.change.confirmation.message';
        scopeModal.onConfirm = () => {
          _.set(this.aide, '_metadata.isCpo', false);
          unsetDuree();

          this.aide.planFinancement = [this.currentPlanFinancement];
        };
        scopeModal.onDecline = () => {
          this.aide.periode = oldValue;
        };
        $modal({
          scope: scopeModal,
          template: 'common/common-modal/confirmation-modal.html',
          backdrop: 'static',
        });
      } else {
        _.set(this.aide, '_metadata.isCpo', false);
        unsetDuree();

        this.aide.planFinancement = [this.currentPlanFinancement];
      }
    } else if (newValue === 'PONCTUELLE') {
      unsetDuree();
    } else {
      const isCpo = this.isCpo();
      _.set(this.aide, '_metadata.isCpo', isCpo);
    }
  };

  this.onMillesimeChange = () => {
    if (!_.get(this.currentPlanFinancement, 'periode.exercice')) return;
    if (isExerciceFieldHidden && isMillesimeFieldVisible) {
      this.aide.exerciceBudgetaire = aidesService.getFirstYearOfPf(this.aide.planFinancement);
    }
  };

  this.isMillesimeRequired = () => {
    /**
     * 1st case : We are in ANNEE_SEULE and a ligne of the PF has been filled : millesime is required
     * 2nd case : We are in ANNEE_OU_INTERVALLE_TEMPOREL and a ligne of the PF has been filled and both date fields have not been filled : millesime is required
     * 3th case : We are in ANNEE_OU_INTERVALLE_TEMPOREL and one of the date field has been filled : Millesime is not required
     */
    return (
      (this.typeAffichage === 'ANNEE_SEULE' && this.ligneRenseignee) ||
      (this.typeAffichage === 'ANNEE_OU_INTERVALLE_TEMPOREL' &&
        this.ligneRenseignee &&
        !this.currentPlanFinancement.periode.debut &&
        !this.currentPlanFinancement.periode.fin)
    );
  };

  this.onDateChange = () => {
    const dateDebut = _.get(this.currentPlanFinancement, 'periode.debut');
    const dateFin = _.get(this.currentPlanFinancement, 'periode.fin');

    if (!dateDebut || !dateFin) return;

    if (moment(dateDebut).isAfter(dateFin)) {
      this.form.exerciceDateDebut.$setValidity('dateMismatch', false);
    } else {
      this.form.exerciceDateDebut.$setValidity('dateMismatch', true);
    } //! false means the form gets INVALIDED
  };

  this.areDatesRequired = () => {
    return (
      this.typeAffichage === 'ANNEE_OU_INTERVALLE_TEMPOREL' &&
      this.ligneRenseignee &&
      !this.currentPlanFinancement.periode.exercice
    );
  };

  /**
   * This function validates if event contains only numbers
   * https://www.w3schools.com/charsets/ref_html_ascii.asp
   * Why this? to prevent "e" in <input type="number"/>
   */
  this.isNumberKey = (event) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (48 <= charCode && charCode <= 57) {
      return true;
    }
    return event.preventDefault();
  };

  /**
   * This method goes through all the lignes of the plan de financement to check if a ligne
   * has a montant > 0, it's used to trigger the required attribute on the millesime field
   * @param {object} planFinancement the plan de financement of the current exercice

   */
  function checkLignes(planFinancement) {
    if (!planFinancement) return false;
    function checkLignesInPostes(postes) {
      var hasLigneWithMontant = false;

      _.each(postes, function(currentPoste) {
        _.each(currentPoste.lignes, function(ligne) {
          if (_.get(ligne, `montant.ht`, 0) !== 0 || _.get(ligne, `montant.ttc`, 0) !== 0) {
            hasLigneWithMontant = true;
          }
        });

        _.each(currentPoste.sousPostes, function(sousPoste) {
          _.each(sousPoste.lignes, function(currentLigne) {
            if (_.get(currentLigne, `montant.ht`, 0) !== 0 || _.get(currentLigne, `montant.ttc`, 0) !== 0) {
              hasLigneWithMontant = true;
            }
          });
        });
      });

      return hasLigneWithMontant;
    }

    const depensePostes = _.get(planFinancement, 'depense.postes', []);
    const recettePostes = _.get(planFinancement, 'recette.postes', []);

    const checkDepense = checkLignesInPostes(depensePostes); //true if either sousPoste or poste has a ligne with a montant > 0
    const checkRecette = checkLignesInPostes(recettePostes);

    return checkDepense || checkRecette;
  }

  this.isMillesimeDisabled = () =>
    this.typeAffichage === 'ANNEE_OU_INTERVALLE_TEMPOREL' &&
    (this.currentPlanFinancement.periode.debut || this.currentPlanFinancement.periode.fin);

  this.isPeriodeDisabled = () =>
    this.typeAffichage === 'ANNEE_OU_INTERVALLE_TEMPOREL' && this.currentPlanFinancement.periode.exercice;
}
