/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name dynamic-name
 * @scope
 * @restrict EA
 * @description
 *
 *   Workaround to allow dynamic name on form elements.
 *
 *   See http://stackoverflow.com/questions/14378401/dynamic-validation-and-name-in-a-form-with-angularjs
 *
 * @param {string} dynamicName - An expression to evaluate and assign as the input's name
 *
 * @example
 *
 *   `<input type="text" dynamic-name="myFieldDefinition.name">`
 *
 */ 

angular.module('form.directives').directive('dynamicName', ['$compile', '$parse', 
  function($compile, $parse) {
    'use strict';
    return {
      restrict: 'A',
      terminal: true,
      priority: 100000,
      link: function(scope, elem) {
        var name = $parse(elem.attr('dynamic-name'))(scope);
        elem.removeAttr('dynamic-name');
        elem.attr('name', name);
        $compile(elem)(scope);
      }
    };
  }]);