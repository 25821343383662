angular.module('portailDepotDemandeAide').component('printAide', {
  templateUrl: 'print-aide/print-aide.component.html',
  bindings: {
    mdm: '<',
    settings: '<',
    contribution: '<',
    aide: '<',
    tiers: '<',
    teleservice: '<',
    publicSettingsFinancement: '<',
  },
  controller: printAide,
});

/* @ngInject */
function printAide($rootScope, $window, $filter) {
  this.$onInit = function() {
    $rootScope.hideFooter = true;
    this.demandeur = _.get(this.aide.history.begin, 'user.title');
    // Logo and labels from referentiel-financement
    this.configStandard = _.get(this.publicSettingsFinancement, 'recapitulatifStandard', {});

    // If transmitted, display transmission date
    if (this.aide.status !== 'REQUESTED') {
      var dateTransmissionEvent =
        _.find(_.get(this.aide, 'history.events'), function(event) {
          return event.type === 'STATUS' && event.reference === 'TRANSMITTED';
        }) || {};

      this.dateTransmission = $filter('date')(dateTransmissionEvent.date, 'short');
      if (!this.dateTransmission.includes(this.settings.timeZone)) {
        this.dateTransmission += ` (${this.settings.timeZone})`;
      }
    }
  };

  this.$onDestroy = function() {
    $rootScope.hideFooter = false;
  };

  this.print = function() {
    $window.print();
  };
}
