/**
  * @ngdoc directive
  * @name tiers.directive:accountsTiers
  * @restrict EA
  * @description Directive who display tiers's account
  * @element
  * @example
  * <accounts-tiers tiers="tiers"
       accounts="linkedUsers"
       administrator="true"
       remove-account="true"
       attach="false"
       user="currentUser"
       remove-administrator="false"
       define-administrator="false"
       view-configuration="userTiersEditConfiguration"
       elements-per-line="'3'"
       validate="saveTiers()">
    </accounts-tiers>
**/
angular.module('tiers').directive('accountsTiers', [
  '$log',
  '$translate',
  '$modal',
  'Link',
  'LinkedUser',
  'tiersAccountsService',
  'alertsService',
  'validationService',
  'aidesService',
  function(
    $log,
    $translate,
    $modal,
    Link,
    LinkedUser,
    tiersAccountsService,
    alertsService,
    validationService,
    aidesService
  ) {
    'use strict';

    /**
     * Function that allows to define the sorting of the accounts.
     * This function is sent as a parameter for ordered accounts
     * @param  {User} account Account
     * @return {String}    String that allows to sort accounts
     */
    var sortByAccounts = function(account) {
      return account.form + account.expand.name.familyName + account.expand.name.givenName;
    };

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'tiers/tiers-directives/accounts-tiers/accounts-tiers.html',
      scope: {
        user: '=',
        tiers: '=',
        accounts: '=',
        administrator: '=',
        removeAccount: '=',
        removeAdministrator: '=',
        defineAdministrator: '=',
        attach: '=',
        viewConfiguration: '=',
        elementsPerLine: '=',
        mdm: '=',
        readOnly: '=',
      },
      link: function(scope) {
        scope.tiers = scope.tiers || {};

        // Math
        scope.Math = window.Math;

        // Validation de l'email
        scope.validationService = validationService;

        /**
         * Watcher on "accounts" collection.
         * Call only when we want add or delete an element of the collection and not when we update an element of the collection.
         */
        scope.$watchCollection('accounts', function() {
          if (scope.accounts) {
            // Do not display owner user, and ignore signataires
            var filteredAccounts = _.filter(scope.accounts, function(account) {
              return account.form !== 'OWNER' && account.form !== 'SIGNATAIRE';
            });
            scope.sortedAccounts = _.sortBy(filteredAccounts, sortByAccounts);
          }
        });

        // Help tooltips
        scope.tooltips = {
          admin: {
            title: 'tiers.accounts-tiers.actions.admin',
          },
          removeAdmin: {
            title: 'tiers.accounts-tiers.actions.removeAdmin',
          },
          remove: {
            title: 'tiers.accounts-tiers.actions.remove',
          },
        };

        scope.isSignataire = function(account) {
          return _.find(scope.accounts, function(acc) {
            return acc.href === account.href && acc.form === 'SIGNATAIRE';
          });
        };

        /**
         * Attach account on tiers
         */
        scope.attachAccountOnTiers = function() {
          scope.$broadcast('alerts', [], 'inviteSuccess');
          var scopeModal = scope.$new();
          scopeModal.account = scope.user;
          scopeModal.tiers = scope.tiers;

          // SIREN  NIC
          scopeModal.SIRET = {
            SIREN: undefined,
            NIC: undefined,
          };

          scopeModal.fields = {
            SIREN: {
              status: undefined,
            },
            NIC: {
              status: undefined,
            },
          };

          scopeModal.blurFieldsSiret = function(form, fieldName) {
            if (form[fieldName].$invalid) {
              scopeModal.fields[fieldName].status = 'error';
            } else {
              scopeModal.fields[fieldName].status = 'success';
            }
          };
          $modal({
            scope: scopeModal,
            template: 'tiers/tiers-directives/accounts-tiers/modal/attach.html',
            backdrop: 'static',
          });
        };

        scope.inviteUser = function() {
          scope.$broadcast('alerts', [], 'inviteSuccess');
          var modalScope = scope.$new();
          modalScope.viewConfiguration = scope.viewConfiguration;
          modalScope.data = {
            email: '',
          };
          $modal({
            scope: modalScope,
            template: 'tiers/tiers-directives/accounts-tiers/modal/invite.html',
            backdrop: 'static',
          });
        };

        /**
         * @param {Tiers} tiers - Tiers
         * @param {string} email - Email of the user to attach on tiers
         * @param {boolean} auto - Autorattachement
         * @returns {Promise}
         */
        const doRattachement = function(tiers, email, auto) {
          if ((tiers.sansSIRET && tiers.sansSIRET.valeur) || auto) {
            return tiersAccountsService.getUserByEmail(email).then(function(res) {
              const knownAccount = _.get(res, 'data.length', 0) >= 1;
              if (!knownAccount) {
                return tiersAccountsService.inviteUserToAutoAttachOnTiers(tiers, email);
              } else {
                return tiersAccountsService.inviteUserToAttachOnTiers(tiers, email);
              }
            });
          } else {
            return tiersAccountsService.inviteUserToAttachOnTiers(tiers, email);
          }
        };

        /**
         * Invite user to attach on tiers
         * @param {Modal} modal Modal
         * @param {Tiers} tiers Tiers
         * @param {String} email Email of the user to attach on tiers
         */
        scope.confirmInviteUser = function(modal, tiers, email) {
          // Rattachement auto ?
          aidesService.getPublicSettingsGestionDepotDemandes().then(function(publicSettings) {
            scope.processingInvitation = true;
            var autoRattachementActif = publicSettings.autoRattachementByAdministrator.value;
            email = email.toLowerCase();

            doRattachement(tiers, email, autoRattachementActif)
              .then(function() {
                // refresh for Linked users
                tiersAccountsService.getLinkedUsers(tiers).then(function(response) {
                  var nbUsers = scope.accounts.length;

                  scope.accounts = _.map(response.data, function(linkedUser) {
                    return new LinkedUser(linkedUser);
                  });

                  // Comparing the new linked users number with the previous one to determine if a new user has been added or not
                  var successMessage =
                    nbUsers === scope.accounts.length
                      ? 'tiers.accounts-tiers.invite.success'
                      : 'tiers.accounts-tiers.invite.added';
                  scope.$broadcast('alerts', alertsService.getAlertSuccess(successMessage), 'inviteSuccess');
                });
                scope.processingInvitation = false;
                modal.$hide();
              })
              .catch(function(response) {
                // user account is already attached
                if (response.status === 406 && _.get(response, 'data.code') === '406.12') {
                  modal.$broadcast(
                    'alerts',
                    alertsService.getAlertError(
                      'tiers.accounts-tiers.invite.error.alreadyAttached',
                      {},
                      $translate.instant('tiers.accounts-tiers.invite.error.title')
                    ),
                    'invite'
                  );
                } else {
                  $log.error('Invite user failed', response);
                  modal.$broadcast(
                    'alerts',
                    alertsService.getAlertError('tiers.accounts-tiers.invite.error.failed'),
                    'invite'
                  );
                }
                scope.processingInvitation = false;
              });
          });
        };

        /**
         * Define an account of type administrator on tiers
         * @param {Modal} modal Modal
         * @param {Tiers} tiers Tiers
         * @param {User} account Account
         */
        scope.defineAccountAdministrator = function(modal, tiers, account) {
          tiersAccountsService.defineAdministratorOnTiers(tiers, account.userName).then(
            function() {
              scope.accounts[_.indexOf(scope.accounts, account)].expand.form = 'ADMINISTRATOR';
              modal.$hide();
            },
            function() {
              modal.$broadcast(
                'alerts',
                alertsService.getAlertError('tiers.accounts-tiers.defineAccountAdministrator.error'),
                'define-account-administrator'
              );
            }
          );
        };

        /**
         * Display a modal for confirm that account will become administrator on tiers
         * @param  {User} account Account
         */
        scope.confirmAccountAdministrator = function(account) {
          scope.$broadcast('alerts', [], 'inviteSuccess');
          var scopeModal = scope.$new();
          scopeModal.account = account;
          scopeModal.tiers = scope.tiers;
          scopeModal.defineAccountAdministrator = scope.defineAccountAdministrator;

          $modal({
            scope: scopeModal,
            template: 'tiers/tiers-directives/accounts-tiers/modal/define-account-administrator.html',
            backdrop: 'static',
          });
        };

        /**
         * Remove administrator on tiers
         * @param {Modal} modal Modal
         * @param {Tiers} tiers Tiers
         * @param {User} administrator Administrator to remove
         */
        scope.removeAdministratorOnTiers = function(modal, tiers, administrator) {
          tiersAccountsService.removeAdministratorOnTiers(tiers).then(
            function() {
              scope.accounts[_.indexOf(scope.accounts, administrator)].expand.form = 'CONTRIBUTOR';
              modal.$hide();
            },
            function() {
              modal.$broadcast(
                'alerts',
                alertsService.getAlertError('tiers.accounts-tiers.removeAdministrator.error'),
                'remove-administrator-confirm'
              );
            }
          );
        };

        /**
         * Display a modal for remove administrator on tiers
         * @param  {User} administrator Administrator to remove
         */
        scope.confirmRemoveAdministratorOnTiers = function(administrator) {
          scope.$broadcast('alerts', [], 'inviteSuccess');
          var scopeModal = scope.$new();
          scopeModal.administrator = administrator;
          scopeModal.tiers = scope.tiers;
          scopeModal.removeAdministratorOnTiers = scope.removeAdministratorOnTiers;
          $modal({
            scope: scopeModal,
            template: 'tiers/tiers-directives/accounts-tiers/modal/remove-administrator-confirm.html',
            backdrop: 'static',
          });
        };

        /**
         * Remove account on tiers
         * @param {Modal} modal Modal
         * @param {Tiers} tiers Tiers
         * @param {User} account Account to remove
         */
        scope.removeAccountOnTiers = function(modal, tiers, account) {
          tiersAccountsService.removeUserOnTiers(tiers, account.href).then(
            function() {
              tiersAccountsService.mailDeleteUserTiers(tiers, account).then(function() {
                scope.accounts.splice(_.indexOf(scope.accounts, account), 1);
                modal.$hide();
              });
            },
            function() {
              modal.$broadcast(
                'alerts',
                alertsService.getAlertError('tiers.accounts-tiers.removeAccount.error'),
                'remove-account-confirm'
              );
            }
          );
        };

        /**
         * Remove an account from tiers
         * @param  {User} account Account
         */
        scope.confirmRemoveAccountOnTiers = function(account) {
          scope.$broadcast('alerts', [], 'inviteSuccess');
          var scopeModal = scope.$new();
          scopeModal.account = account;
          scopeModal.tiers = scope.tiers;
          scopeModal.removeAccountOnTiers = scope.removeAccountOnTiers;
          $modal({
            scope: scopeModal,
            template: 'tiers/tiers-directives/accounts-tiers/modal/remove-account-confirm.html',
            backdrop: 'static',
          });
        };
      },
    };
  },
]);
