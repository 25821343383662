angular.module('portailDepotDemandeAide.depot').controller('depotSimplePreambuleController', [
  '$scope',
  '$translate',
  function($scope, $translate) {
    'use strict';
    var isMultiFinPartage =
      _.get($scope, 'teleserviceConfiguration.multiFinanceur.active') &&
      _.get($scope, 'teleserviceConfiguration.multiFinanceur.options.modePreInstruction') === 'PARTAGE';
    $scope.displayPreambuleSpecifique =
      isMultiFinPartage && _.has($scope, 'teleserviceConfiguration.workflow.pagePreambule.preambule.content');
    $scope.content = $translate.instant('teleservice.preambule.content');
    $scope.hasValidDefaultPreambule =
      $scope.content !== 'teleservice.preambule.content' && $scope.content.trim() !== '';
    $scope.cleanNavigate();
    $scope.navigate.next = $scope.goToStepFn('criteres');
    $scope.navigate.ns = 'teleservice.preambule';
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'preambule';
  },
]);
