/* global kendo */

/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name number-field
 * @scope
 * @restrict EA
 * @description
 *
 *   A basic number field for dynamic forms.
 *
 *   Uses validation-field directive for automatic layout, configuration and labels management.
 *
 *   Uses kendo-ui numeric textbox directive
 *
 * @param {string} name - The name of the field in the current form and in the model
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 * @param {object} configuration - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {string} namespace - The configuration of the field extracted from a viewConfiguration object (in replacement to viewConfiguration)
 * @param {String} model - The model to bind to
 * @param {number} [subdigits] - The number of subdigits supported (0 for an integer, usually 2 for a float). Default to 2.
 * @param {string} [type] - The type of number, 'n' for number, 'c' for currency, 'p' for percentage. Default to 'n'.
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages
 *
 * @example
 *
 *   `<number-field name="montant" view-configuration="infosGeneralesConfiguration" data="demande" subdigits="2" type="c"></number-field>`
 *
 */
angular.module('form.directives').directive('numberField', ['$translate',

  function($translate) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/number-field/number-field.html',
      scope: {
        name: '@',
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=',
        subdigits: '@',
        type: '@', // n for number, c for currency, p for percentage
        bare: '=',
        readOnly: '=',
        labelWidth: '=',
        fieldWidth: '=',
        helpWidth: '=',
        remoteValidation: '=?',
        labelClass: '=?',
        defaultToZero: '=?'
      },
      link: function(scope) {
        scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
        scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');

        // Read remote validation from directive, configuration or viewConfiguration
        scope.remoteValidation = scope.remoteValidation !== undefined ? scope.remoteValidation :
          scope.configuration.remoteValidation !== undefined ? scope.configuration.remoteValidation :
            _.get(scope, 'viewConfiguration.remoteValidation') !== undefined ? _.get(scope, 'viewConfiguration.remoteValidation') : false;

        var watches = [];
        scope.subdigits = scope.subdigits || 2;
        if (scope.defaultToZero === undefined) {
          scope.defaultToZero = true;
        }
        scope.type = scope.type || 'n';
        scope.format = scope.type + scope.subdigits;

        // Initialize display value when empty to avoid hyphen double inputs for negative numbers
        scope.numberField = {
          displayValue: scope.model || 0,
          modelValue: scope.model,
          numericTextBoxConfig: {
            format: scope.format,
            spinners: scope.configuration.spinners || false
          },
          decimals: parseInt(scope.subdigits)
        };

        // Little manipulation so that we can add placeholder
        scope.buildKOptions = function() {
          var placeholderValue = _.get(scope, 'configuration.placeholder');
          if (!placeholderValue) {
            var placeholderKey = scope.namespace + '.' + scope.name + '.placeholder';
            placeholderValue = $translate.instant(placeholderKey) !== placeholderKey ? $translate.instant(placeholderKey) : '';
          }
          scope.numberField.numericTextBoxConfig.placeholder = placeholderValue;
          return scope.numberField.numericTextBoxConfig;
        };

        scope.getMinlength = function(fieldMinlength) {
          if (fieldMinlength !== undefined) {
            // replace string with int
            return typeof fieldMinlength === 'string' ? parseInt(fieldMinlength) : fieldMinlength;
          } else {
            return 0;
          }
        };

        scope.getMaxlength = function(fieldMaxlength) {
          if (fieldMaxlength !== undefined) {
            return typeof fieldMaxlength === 'string' ? parseInt(fieldMaxlength) : fieldMaxlength;
          } else {
            if (scope.subdigits !== undefined && scope.subdigits !== '0') {
              var res = 14 + Number(scope.subdigits);
              return res;
            } else {
              return 13;
            }
          }
        };

        /*
         * This trigger is important
         *
         * It will catch all the change of the displayed value (the value that is changed while typing)
         * And it will forbid to type more than the allowed number of decimals and set the value to the modelValue
         */
        watches.push(scope.$watch('numberField.displayValue', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            if (scope.defaultToZero && (newValue === '')) {
              //If is empty, asign now...
              scope.numberField.modelValue = 0;
            } else {
              // Check the decimals rounding to the max
              if (scope.subdigits > 0 && kendo.parseFloat(scope.numberField.displayValue) !== null) {
                var normalizedStringValue = kendo.parseFloat(scope.numberField.displayValue).toString();
                var indexOfPoint = normalizedStringValue.indexOf('.');
                if (indexOfPoint > 0) {
                  scope.numberField.displayValue = normalizedStringValue.substring(0, indexOfPoint + 1 + parseInt(scope.subdigits));
                }
                scope.numberField.modelValue = kendo.parseFloat(scope.numberField.displayValue);
              }
              // Else, simply parse to an integer to suppress the possible unexpected '0'
              else {
                scope.numberField.modelValue = parseInt(scope.numberField.displayValue);
              }
            }
          }

        }));

        // We have to explicitly two way binding the value with parent model
        watches.push(scope.$watch('numberField.modelValue', function(newValue) {
          scope.model = !(_.isNull(newValue) || _.isNaN(newValue)) ? newValue : undefined;
        }));

        watches.push(scope.$watch('model', function(newValue) {
          scope.numberField.modelValue = newValue !== undefined ? newValue : scope.numberField.modelValue;
          scope.numberField.displayValue = newValue;
        }));

        // Process restrictions
        if(scope.configuration && scope.configuration.restrictions && Array.isArray(scope.configuration.restrictions) && scope.configuration.restrictions.length > 0) {
         
          scope.configuration.restrictions.forEach(function(element){
            // Set min and max
            if(element.hasOwnProperty('minInclusive')) {
              scope.numberField.numericTextBoxConfig.min = element.minInclusive;
            }
            if(element.hasOwnProperty('maxInclusive')) {
              scope.numberField.numericTextBoxConfig.max = element.maxInclusive;
            }
            if(element.hasOwnProperty('minExclusive')) {
              scope.numberField.numericTextBoxConfig.min = (element.minExclusive - Math.pow(10, scope.subdigits * -1));
            }
            if(element.hasOwnProperty('maxExclusive')) {
              scope.numberField.numericTextBoxConfig.max = (element.maxExclusive - Math.pow(10, scope.subdigits * -1));
            }
          });
        }

        scope.$on('$destroy', function() {

          _.each(watches, function(watch) {
            watch();
          });
        });
      }
    };
  }
]);
