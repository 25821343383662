// Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationConfirmationComponent', {
  templateUrl: 'depot/justification/confirmation/justification-confirmation.component.html',
  controller: /* @ngInject */ JustificationConfirmationController,
  bindings: {
    justification: '=',
    workflow: '<',
  },
});

function JustificationConfirmationController(
  $state,
  $http,
  $log,
  $rootScope,
  $interval,
  justificationService,
  alertsService
) {
  var ctrl = this;
  var retrySetRecap;

  ctrl.$onInit = function() {
    // we set recap document with retry
    retrySetRecap = $interval(ctrl.setRecap, 3000);
  };

  ctrl.$onDestroy = function() {
    $interval.cancel(retrySetRecap);
  };

  ctrl.finishStep = function() {
    $state.go('app.connected.dashboard.accueil');
  };

  /**
   * Method to set the justification recapitulatif pdf
   */
  ctrl.setRecap = function() {
    // we get justification recapitulatif url
    return justificationService.getJustification(ctrl.justification.reference, ['pieces']).then(function(result) {
      var recap = _.get(_.find(result.pieces, { reference: 'recapitulatif' }), 'documents[0]', false);
      if (!recap || !recap.href) return;

      return ctrl.getRecapCmis(recap).then(function(properties) {
        if (!properties) return;
        // wee init the recap object and add set only needed keys
        ctrl.recap = {
          url: recap.id,
        };

        ctrl.recap.fileName = _.get(
          properties,
          'cmis:description.value',
          _.get(properties, 'entity:document:originalfilename.value')
        );

        ctrl.recap.mimeType = _.get(
          properties,
          'cmis:contentStreamMimeType.value',
          _.get(properties, 'cmis:contentStreamMimeType.value')
        );

        // we kill the interval on setRecap()
        $interval.cancel(retrySetRecap);
      });
    });
  };

  ctrl.getRecapCmis = function(recap) {
    // we get justification documents cmis
    return $http
      .get(recap.href)
      .then(function(res) {
        // we focus on documents cmis
        var properties = _.get(res, 'data.properties', false);
        return properties;
      })
      .catch(function(err) {
        $rootScope.$broadcast(
          'alerts',
          alertsService.getAlertError('justification.common.alerts.error.documentMetadata')
        );
        $log.error('Cannot get document metadatas. Error detail: ' + err);
      });
  };

  /**
   * Method to download the PDF recapitulatif document
   */
  ctrl.downloadRecap = function() {
    // we set a shortcut to access to the recap url
    var recapUrl = _.get(ctrl, 'recap.url', false);
    if (!recapUrl) return;
    return $http
      .get(recapUrl, {
        responseType: 'arraybuffer',
      })
      .then(
        function(response) {
          var mimeType = ctrl.recap.mimeType || 'application/pdf';
          var blob = new Blob([response.data], {
            type: mimeType,
          });
          return saveAs(blob, ctrl.recap.fileName || 'recapitulatif.pdf');
        },
        function(error) {
          $log.error('Unable to download recapitulatif: ', error);
        }
      )
      .catch(function(err) {
        $log('An error append when trying to download. More informations: ' + err);
      });
  };
}
