angular
  .module('common.services')
  .factory('viewsService', [
    'configuration',
    '$timeout',
    '$modal',
    '$stateParams',
    '$httpParamSerializer',
    '$rootScope',
    viewsService,
  ]);

function viewsService(configuration, $timeout, $modal, $stateParams, $httpParamSerializer, $rootScope) {
  'use strict';

  /**
   * Return true if the view.schema.href match the href
   * these href contains revision number, we have to substring them to compare
   * @param {*} view view to match href
   * @param {*} href href to compare
   */
  function isViewSchemaMatchHref(view, href) {
    const hrefView = _.get(view, 'schema.href');
    if (!href || !hrefView) return false;
    const hrefViewSplitted = hrefView.split('?').shift();
    const hrefSplitted = href.split('?').shift();
    return hrefSplitted === hrefViewSplitted;
  }

  return {
    /**
     * Method that builds url to load
     * views for a specific "demande d'aide"
     * @param {Object} aide
     * @param {string} page
     * @param {string} theme Graphic theme to apply to the iframe
     * @param {boolean} readOnly Display views in readonly
     * @param {number} topTitleLevel Initialize the title level value to the highest in the iFrame.
     * @param {string} fieldsSelectionParameterName Custom select fields name
     * @param {Object} contextHeaders Specific headers for context
     * @return {string}
     */
    getViewsIframeUrl: function(
      aide,
      page,
      theme,
      readOnly,
      topTitleLevel,
      fieldsSelectionParameterName,
      contextHeaders
    ) {
      // Get display entity url
      let urlEntity = aide.id;

      const queryParams = {
        urlEntity,
        indexes: [],
        urls: [],
        jwtKey: `jwt-${configuration.tenant.id}-portail-depot-demande-aides`,
        theme: !_.isNil($rootScope.specificTheme) ? 'specific' : theme,
        readOnly: readOnly || false,
        displaySaveButton: false,
        topTitleLevel: topTitleLevel || 4,
        fieldsSelectionParameterName,
        patchViewsEntity: false,
        contextHeaders,
      };

      if (!_.isEmpty($stateParams.c)) {
        // If we have a contribution, we display the "aide" merged to the contribution
        queryParams.urlEntity = `${queryParams.urlEntity}?contribution=${$stateParams.c}`;
      }

      const originalViews = _.get(aide, 'views', []).filter((view) => view.actif);
      _.each(_.sortBy(originalViews, 'section'), function(view) {
        const index = originalViews.indexOf(view);
        const url = _.get(view, 'schema.href');
        if (view.page === page && view.actif) {
          queryParams.indexes.push(index);
          queryParams.urls.push(url);
        }
      });

      const serializedQueryParams = $httpParamSerializer(queryParams);

      return `/data-schemas/#/${configuration.tenant.id}/views?${serializedQueryParams}`;
    },

    /**
     * Building an iFrame url with the all the views
     * @param {Object} entityToPatch The entity from which the url is built
     * @param {string} theme
     * @param {boolean} readOnly
     * @param {boolean} remoteValidation
     * @param {number} topTitleLevel
     * @param {boolean} displaySaveButton
     * @param {Object[]} views
     * @param {Object} headersEntity Specific headers for entity
     * @param {string} urlTemplateEntity
     * @param {Object} contextHeaders Specific headers for context
     * @return {string}
     */
    getAllViewsIframeUrl: (
      entityToPatch,
      theme,
      readOnly,
      remoteValidation,
      topTitleLevel,
      displaySaveButton = false,
      views,
      headersEntity,
      urlTemplateEntity,
      contextHeaders,
      patchViewsEntity = false
    ) => {
      // Check if entityToPatch.id is not empty
      if (!entityToPatch || _.isEmpty(entityToPatch.id)) {
        throw new Error('allViewsIframeUrl - "entityToPatch" parameter must have an "id" property');
      }

      const queryParams = {
        indexes: [],
        urls: [],
        jwtKey: `jwt-${configuration.tenant.id}-portail-depot-demande-aides`,
        theme: !_.isNil($rootScope.specificTheme) ? 'specific' : theme,
        readOnly: readOnly || false,
        remoteValidation: remoteValidation || false,
        topTitleLevel: topTitleLevel || 4,
        displaySaveButton: displaySaveButton,
        headersEntity,
        urlTemplateEntity,
        contextHeaders,
        patchViewsEntity,
      };

      const originalViews = (views || _.get(entityToPatch, 'views') || []).filter((view) => view.actif);

      _.each(_.sortBy(originalViews, ['page', 'section']), (view) => {
        let index = -1;
        if (entityToPatch && Array.isArray(entityToPatch.views)) {
          // If we want to add data-schemas values on a particular entity we should match indexes
          index = _.findIndex(entityToPatch.views, (viewEntityToPatch) => {
            return isViewSchemaMatchHref(viewEntityToPatch, _.get(view, 'schema.href'));
          });
        }
        if (index === -1) {
          index = _.findIndex(views, (viewEntity) => {
            return isViewSchemaMatchHref(viewEntity, _.get(view, 'schema.href'));
          });
        }

        const url = _.get(view, 'schema.href');
        queryParams.indexes.push(index);
        queryParams.urls.push(url);
      });

      let urlEntity = entityToPatch.id;

      const referenceContribution = $stateParams ? $stateParams.c || $stateParams.contributionRef : undefined;
      // Do not take contribution into account for tiers since the contribution cannot affect it
      if (!_.isEmpty(referenceContribution) && entityToPatch.kind !== 'TIERS') {
        // if we have a reference contribution parameter, we get a merged demande with contribution
        urlEntity = `${entityToPatch.id}?contribution=${referenceContribution}`;
      }

      queryParams.urlEntity = urlEntity || entityToPatch.id;

      const serializedQueryParams = $httpParamSerializer(queryParams);
      const urlIframe = `/data-schemas/#/${configuration.tenant.id}/views?${serializedQueryParams}`;

      return urlIframe;
    },

    /**
     * Loading the views of the iframe
     * @param {Object} scope
     * @param {Object} msg
     * @param {Object} entity
     * @param {String} iframeName format '#nameOfTheIframe'
     * @param {Object} options options to be passed to data-schema
     * @param {boolean} options.showErrors errors if present during completness check
     * @param {boolean} options.saveInProgress bypass required fields for form validation
     */
    updateViewsEntity: (scope, msg, entity, iframeName, options) => {
      if (_.get(msg, 'data.action') === 'viewsUpdated') {
        const index = _.get(msg, 'data.index');
        const values = _.get(msg, 'data.values');
        _.set(entity, `views.${index}.values`, values);
      } else if (_.get(msg, 'data.source') === 'data-schemas.views' && _.get(msg, 'data.action') === 'ready') {
        const viewsIframe = angular.element(iframeName);

        // Display the error if the uses commes back on the page
        if (options.showAllErrors) {
          // Send a message which enable the validation of the Liste/Fiche/Formulaires
          $timeout(() => {
            viewsIframe[0].contentWindow.postMessage(
              {
                action: 'validViews',
                options,
              },
              '*'
            );
          }, 0);
        }
      } else if (_.startsWith(_.get(msg, 'data.action', ''), 'modal')) {
        scope.$apply(() => {
          scope.$emit(_.get(msg, 'data.action'));
        });
      }
    },

    /**
     * Checking that the iframe form is valid
     * @param {Object} msg
     * @param {Function} accept
     * @param {Function} reject
     * @param {Object} teleserviceConfiguration
     */
    updateStateViews: function(scope, msg, accept, reject, teleserviceConfiguration) {
      if (_.get(msg, 'data.action') !== 'updateStateViews') return; // not involved with the event
      if (_.get(msg, 'data.state') === 'ok') {
        accept();
      } else if (
        _.get(teleserviceConfiguration, 'controleCompletudeDepot', false) ||
        _.get(msg, 'data.state') === 'error'
      ) {
        // Affichage des erreurs dans une modale uniquement en cas d'alertes transmises
        // ce qui n'arrive qu'avec les liste-fiche
        if (_.isEmpty(_.get(msg, 'data.alerts'))) {
          reject(new Error('updateStateViews - iFrame validation rejected'));
          return;
        }
        var scopeModal = scope.$new();
        scopeModal.confirmNext = function() {
          accept();
        };
        scopeModal.views = _.get(msg, 'data.views', []);
        $modal({
          scope: scopeModal,
          template: 'depot/simple/informations-generales/modal/alerts-views.html',
        }).catch(() => {
          reject(new Error('updateStateViews - iFrame validation rejected'));
        });
      } else {
        reject(new Error('updateStateViews - iFrame validation rejected'));
      }
    },

    /**
     * Checking that the iframe form is valid then go to the next step
     * @param {*} scope
     * @param {*} msg
     * @param {Function} accept
     * @param {Function} reject
     */
    updateStateViewsTiers: (scope, msg, accept, reject) => {
      const action = _.get(msg, 'data.action');

      if (action === 'updateStateViews') {
        const state = _.get(msg, 'data.state');
        if (state === 'ok') {
          accept();
        } else {
          if (!_.isEmpty(_.get(msg, 'data.alerts'))) {
            const scopeModal = scope.$new();
            scopeModal.views = _.get(msg, 'data.views', []);
            $modal({
              scope: scopeModal,
              template: 'depot/simple/informations-generales/modal/alerts-views.html',
            });
          }
          reject();
        }
      }
    },

    /**
     * Checking that the iframe form is valid
     * @param {Object} msg
     * @param {Function} accept
     * @param {Function} reject
     * @param {Object} teleserviceConfiguration
     */
    updateStateViewsPaiement: function(scope, msg, accept, reject) {
      if (_.get(msg, 'data.action') === 'updateStateViews') {
        // If the iframe send a state ok then we go to next step
        if (_.get(msg, 'data.state') === 'ok') {
          accept();
        } else if (_.get(msg, 'data.state') === 'error') {
          // Display errors in a modal only if there's some transmitted alerts
          if (!_.isEmpty(_.get(msg, 'data.alerts'))) {
            var scopeModal = scope.$new();
            scopeModal.confirmNext = function() {
              accept();
            };
            scopeModal.views = _.get(msg, 'data.views', []);
            $modal({
              scope: scopeModal,
              template: '/depot/demande-paiement/informations-complementaires/modal/alerts-views.html',
            }).catch(() => {
              reject(new Error('updateStateViews - iFrame validation rejected'));
            });
          } else {
            reject(new Error('updateStateViews - iFrame validation rejected'));
          }
        } else {
          reject(new Error('updateStateViews - iFrame validation rejected'));
        }
      }
    },

    /**
     * update state views from justification
     * @param {object} msg
     * @param {function} goNextStep
     */
    updateStateViewsJustification: function(scope, msg, goNextStep) {
      if (_.get(msg, 'data.action') !== 'updateStateViews') {
        return;
      }
      scope.$apply(function() {
        // If the iframe send a state ok then we go to next step
        if (_.get(msg, 'data.state') === 'ok') {
          goNextStep();
        } else if (_.get(msg, 'data.state') === 'error') {
          // Display errors in a modal only if there's some transmitted alerts
          if (_.isEmpty(_.get(msg, 'data.alerts'))) {
            return;
          }
          var scopeModal = scope.$new();
          scopeModal.confirmNext = function() {
            goNextStep();
          };
          scopeModal.views = _.get(msg, 'data.views', []);
          $modal({
            scope: scopeModal,
            template: '/depot/justification/informations-complementaires/modal/alert-views.html',
          });
        }
      });
    },
  };
}
