// Module declaration
angular.module('common.components').component('stepWizardNavigation', {
  templateUrl: 'common/common-components/steps-wizard/navigation-bar.component.html',
  controller: /* @ngInject */ StepsWizardNavigation,
  bindings: {
    navigationDelegate: '<',
    type: '<',
    disableNavigation: '<',
  },
});

// Controller
function StepsWizardNavigation($scope, $compile) {
  var ctrl = this;

  ctrl.$onInit = function() {};
}
