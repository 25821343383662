angular.module('common.services').factory('alerteurService', [
  '$q',
  'configuration',
  '$http',
  'jwtSessionService',
  '$timeout',
  function($q, configuration, $http, jwtSessionService, $timeout) {
    'use strict';

    return {
      countUnreadEchanges: function(tenantId) {
        // EU backend used as middleware to Request
        return $http.get(`/aides/api/tenants/${tenantId}/echanges`).then((results) => {
          return _.get(results, 'data.count');
        });
      },
      // Called only once when loading alerteur componant
      socketUnreadEchanges: function(tenantId, user, callbackSetNb) {
        if (_.get(jwtSessionService.user(), 'echangesActif')) {
          const jwtToken = jwtSessionService.jwt();

          if (_.isEmpty(jwtToken) || _.isEmpty(tenantId)) {
            return;
          }

          const query = {
            token: jwtToken,
            tenantId: tenantId,
            userHref: user.self,
            userGroups: [user.groups],
          };

          const socketEchanges = io(`${window.location.origin}/echangesNonLus`, {
            path: '/referentiel-echanges/api/socket.io/',
            transports: ['websocket', 'polling'],
            query: query,
          });

          socketEchanges.on('connect', () => {
            socketEchanges.on('message', () => {
              // avoid request lag
              $timeout(() => {
                return this.countUnreadEchanges(tenantId).then((nb) => {
                  callbackSetNb(nb);
                });
              }, 1500);
            });
          });
        }
      },
    };
  },
]);
