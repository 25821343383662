angular.module('portailDepotDemandeAide.dashboard').controller('dashboardMesPublicationsController', [
  'configuration',
  'jwtSessionService',
  '$scope',
  '$state',
  '$httpParamSerializer',
  'StoreService',
  function(configuration, jwtSessionService, $scope, $state, $httpParamSerializer, StoreService) {
    const publicationIframeQueryParams = $httpParamSerializer({
      jwtKey: jwtSessionService.getJwtKey(),
      currentTiers: StoreService.currentTiersRef.get(),
    });
    const templatePublicationIframeSrc =
      configuration.referentielPublication.ux + '<%= tenantId %>/documents-publies/my?<%= queryParams %>';
    const compiledPublicationIframeSrc = _.template(templatePublicationIframeSrc);
    const publicationIframeSrc = compiledPublicationIframeSrc({
      tenantId: configuration.tenant.id,
      queryParams: publicationIframeQueryParams,
    });
    this.publicationIframeSrc = publicationIframeSrc;

    function resizeIframe() {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },
        '#publicationIframe'
      );
    }

    window.addEventListener('message', function(e) {
      const aide = _.get(e, 'data.aide');
      const route = _.get(e, 'data.route');
      const action = _.get(e, 'data.action');

      if (route === 'documents-publies.my.ux') {
        resizeIframe();
      } else if (action === 'go-to-aide') {
        $state.go('app.connected.dashboard.recapitulatif', { aide, from: 'mesPublications' });
      }
    });
    $scope.$on('$destroy', function() {
      window.removeEventListener('message', resizeIframe);
    });
  },
]);
