angular.module('aides').component('recapitulatifAide', {
  templateUrl: 'aides/aides-components/recapitulatif-aide/recapitulatif-aide.component.html',
  bindings: {
    aide: '<',
    contribution: '<',
    userTiers: '<',
    teleservice: '<',
    mdm: '<',
    namespace: '<',
    user: '<?',
  },
  controller: recapitulatifAide,
});

function recapitulatifAide(
  $scope,
  $timeout,
  viewsService,
  tiersService,
  jwtSessionService,
  indicateursService,
  bourseService,
  configuration
) {
  this.$onInit = function $onInit() {
    // To access this in anonymous function
    const that = this;

    this.demandeur = this.tiers || _.get(this.aide, 'demandeur.expand', {});

    this.pageInformationsGeneralesOptions = _.get(this.teleservice, 'workflow.simple.pageInformationsGenerales', {});

    // If we don't have access to this tiers, at least we have the title in the aide
    if (!this.demandeur.title) {
      this.demandeur.title = this.aide.demandeur.title;
    }

    this.beneficiaires = [];
    const beneficiaire = _.get(this.aide.beneficiaires, '0.expand', {});
    const depotDelegueActif = _.get(this.teleservice, 'workflow.simple.depotDelegue.actif', false);

    if (
      (beneficiaire.id && beneficiaire.id !== this.demandeur.id) ||
      (depotDelegueActif === true && !_.isEmpty(beneficiaire))
    ) {
      // We always get the first beneficiaire, so we won't ever loop more than once (ng-repeat + line 64)
      this.beneficiaires[0] = beneficiaire;
    }

    this.aideConfiguration = _.get(this.teleservice, 'aide.informations-generales');
    this.tiersConfiguration = _.get(this.teleservice, 'demandeur.demandeur-complementaire');

    // Which tiers should have an influence on the summary's content?
    this.tiersReference = this.demandeur;
    if (!_.isEmpty(this.beneficiaires)) {
      this.tiersReference = this.beneficiaires[0].expand;
    }

    this.tiersFamille = this.demandeur;
    if (_.get(this.demandeur, 'famille.expand.d')) {
      this.tiersFamille.famille.expand = this.demandeur.famille.expand.d;
    }

    this.pageCritereEligibiliteActif = _.get(this.teleservice, 'workflow.simple.pageCritereEligibilite.actif');

    const pageOptions = _.get(this.teleservice, 'workflow.simple.pageInformationsDemandeur');

    const { isEntrepriseOrAssociationAndActive, areInfosCompActive } = tiersService.areInfosCompTiersActive(
      pageOptions,
      this.teleservice,
      this.demandeur,
      null
    );

    this.isDemandeurEntrepriseOrAssociation = isEntrepriseOrAssociationAndActive;
    this.demandeurComplementaire = areInfosCompActive;

    // Beneficiaires
    this.beneficiaireComplementaire = {};
    this.isBeneficiaireEntrepriseOrAssociation = {};
    _.each(this.beneficiaires, (beneficiaire) => {
      const pageOptions = _.get(this.teleservice, 'workflow.simple.pageInformationsBeneficiaire');
      const { isEntrepriseOrAssociationAndActive, areInfosCompActive } = tiersService.areInfosCompTiersActive(
        pageOptions,
        this.teleservice,
        beneficiaire,
        null,
        'beneficiaire'
      );

      this.isBeneficiaireEntrepriseOrAssociation[beneficiaire.reference] = isEntrepriseOrAssociationAndActive;
      this.beneficiaireComplementaire[beneficiaire.reference] = areInfosCompActive;
    });

    // Domiciliation bancaire
    this.displayDomiciliation = _.get(this.teleservice, 'workflow.simple.pageDomiciliationBancaire.actif') || false;

    // Views - Champs spécifiques
    if (!_.isEmpty(_.get(this.aide, 'views'))) {
      /**
       * Handle interactions between views and portal
       * @param {Object} msg Message from "data-schemas" service
       * @return {void}
       */
      this.updateViewsAide = function updateViewsAide(msg) {
        viewsService.updateViewsEntity($scope, msg, that.aide, '#viewsIframe', {
          showAllErrors: true,
        });
      };

      // iframe url for 'champs spécifiques - Views'
      this.viewsIframeSrc = viewsService.getAllViewsIframeUrl(
        this.aide,
        'demandeur',
        true,
        false,
        5,
        null,
        null,
        null,
        null,
        {
          'referentiel-tiers': [
            'Accept:application/vnd.mgdis.tiers-3.19.0+json',
            'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
          ],
        }
      );

      // Listen to messages from 'data-schemas' iframe
      window.addEventListener('message', this.updateViewsAide);
    }

    // ==== Formation ====
    const formationConfiguration = _.get(this, 'pageInformationsGeneralesOptions.formationSuivie', {});

    // Check formation data
    this.formationActif = formationConfiguration.actif;
    if (this.formationActif) {
      this.formationTitle = formationConfiguration.title;
      this.formationIframeSrc = bourseService.generateFormationIframeSrc(
        _.get(this, 'aide.reference'),
        this.contribution,
        true
      );
    }

    // ==== Localisation ====
    // Fetch the 'localisation' of 'demande-financement' if it exists
    const localisationsSurDemande = _.get(this.aide, 'localisations');

    // Fetch active 'hiérarchies de localisations' on the teleservice
    const hierarchies = _.get(
      this.teleservice,
      'workflow.simple.pageInformationsGenerales.informationsLocalisation.hierarchies',
      []
    );
    const hierarchiesActives = _.filter(hierarchies, 'actif');

    // Keep visible 'hiérarchies'
    const hierarchiesVisible = _.reject(hierarchiesActives, 'hideOnEspaceUsagers');
    // Keep 'localisations' from visible 'hiérarchies'
    const localisationsSurDemandeVisibles = _.filter(localisationsSurDemande, function(localisation) {
      return _.some(hierarchiesVisible, { href: localisation.hierarchie.href });
    });

    if (_.isEmpty(localisationsSurDemandeVisibles)) {
      this.localisationPresente = false;
    } else {
      this.localisationPresente = true;
      // Build 'hiérarchies' iframe request
      const hierarchiesVisibleReference = _.map(hierarchiesVisible, function(hierarchie) {
        return hierarchie.href.split('/').pop();
      });
      const queryStringHierarchies = _.join(hierarchiesVisibleReference, ',');

      // 'localisations' references that are possibly on the 'demande-financement'.
      const localisationsSurDemandeReference = _.map(localisationsSurDemandeVisibles, function(localisation) {
        const referenceLocalisation = localisation.href.split('/').pop();
        const referenceHierarchie = localisation.hierarchie.href.split('/').pop();
        return referenceLocalisation + ':' + referenceHierarchie;
      });
      const queryStringLocalisations = _.join(localisationsSurDemandeReference, ',');

      // Generate 'localisation' iframe URl
      const templateLocalisationIframeSrc =
        configuration.referentielLocalisation.ux +
        '#/<%= tenantId %>/selectionLocalisation?jwtKey=<%= jwtKey %>&theme=demandeur' +
        '&hierarchies=<%= qsHierarchies %>&selected=<%= qsLocalisation %>&readOnly=<%= qsReadOnly %>';

      const compiledLocalisationIframeSrc = _.template(templateLocalisationIframeSrc);

      this.localisationIframeSrc = compiledLocalisationIframeSrc({
        tenantId: configuration.tenant.id,
        jwtKey: jwtSessionService.getJwtKey(),
        qsHierarchies: queryStringHierarchies,
        qsLocalisation: queryStringLocalisations,
        qsReadOnly: true,
      });
    }

    this.listeLocalisations = _.map(this.aide.localisations, 'title').join(', ');

    // 'Moyens Humains'
    this.moyensHumainsPresent = _.get(
      this.teleservice,
      'workflow.simple.pageInformationsGenerales.moyensHumains.actif'
    );

    // Tiers with type 'Financeur Partenaire'
    this.tiersFinanceurPartenaire =
      _.get(this.userTiers, 'thematiquesLiees.financeur.typeFinanceur') === 'PARTENAIREFINANCIER';

    // Get publicSettings
    // Default false because we have to wait for the end of the request
    this.sectionAdresseContacts = { active: false };
    tiersService.getPublicSettingsTiers().then((response) => {
      this.sectionAdresseContacts = response.sectionAdresseContacts;
    });

    // Get indicateurs' iframe url (readonly)
    // add contribution (this.contribution is undefined if no contribution found)
    this.iframeIndicateursPrevisionnelFinancement = indicateursService.getIndicateursIframeUrl(
      this.aide,
      true,
      'previewed',
      false,
      this.contribution,
      null
    );

    $timeout(function() {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
        },
        '#localisationIframe, #viewsIframe, #formationIframe'
      );
    }, 0);
    $timeout(function() {
      iFrameResize(
        {
          heightCalculationMethod: 'lowestElement',
          checkOrigin: false,
          inPageLinks: true,
        },
        '#indicateursPrevisionnelIframe'
      );
    }, 0);

    // Listen to messages coming from child frames
    window.addEventListener('message', receiveMessageEvent.bind(this));
    // We remove the event listener on scope destruction
    $scope.$on('$destroy', function() {
      window.removeEventListener('message', receiveMessageEvent);
    });
  };

  /**
   * Manage the messages we receive from child frames
   * @param {Object} msgEvent
   * @return {void}
   */
  function receiveMessageEvent(msgEvent) {
    if (msgEvent.data.action === 'fetchCustomHierarchies') {
      const customHierarchies = _.get(
        this.teleservice,
        'workflow.simple.pageInformationsGenerales.informationsLocalisation.hierarchies',
        []
      );
      if (customHierarchies && !_.isEmpty(customHierarchies)) {
        msgEvent.source.postMessage(
          {
            action: 'sendCustomHierarchies',
            payload: customHierarchies,
          },
          event.origin
        );
      }
    }
  }
}
