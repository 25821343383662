angular.module('form.directives').directive('editableGrid', ['gridUtils',

  function(gridUtils) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'form/form-directives/editable-grid/editable-grid.html',
      scope: {
        viewConfiguration: '=',
        config: '=configuration',
        ns: '=namespace',
        model: '=',
        gridOptions: '='
      },
      link: function(scope, element, attrs, ctr, transclude) {
        scope.configuration = scope.config || _.get(scope, 'viewConfiguration.fields.' + scope.name) || {};
        scope.namespace = scope.ns || scope.configuration.ns || _.get(scope, 'viewConfiguration.ns');

        _.each(scope.gridOptions.columns, function(column) {
          if (column.editorTemplate) {
            column.editor = gridUtils.fieldEditor({
              scope: scope,
              template: column.editorTemplate,
              transclude: transclude
            });
          }
          if (column.editorTemplateUrl) {
            column.editor = gridUtils.fieldEditor({
              scope: scope,
              templateUrl: column.editorTemplateUrl,
              transclude: transclude
            });
          }
        });
      }
    };
  }
]);
