angular.module('portailDepotDemandeAide.depot').controller('depotSimpleDemandeurBeneficiaireController', [
  '$scope',
  function($scope) {
    'use strict';

    $scope.cleanNavigate();
    $scope.navigate.ns = 'teleservice.demandeur-beneficiaire';
    $scope.navigate.next = function(forget) {
      if ($scope.aide.demandeurEtBeneficiaire) {
        $scope.aide.beneficiaires.splice(0, 1);
        $scope.goToStep('informations-generales', forget);
      } else {
        $scope.goToStep('beneficiaire-famille', forget);
      }
    };

    // Skip ?
    if (
      $scope.teleserviceConfiguration.workflow.depotDelegue.actif &&
      ((!_.isUndefined($scope.teleserviceConfiguration.workflow.depotDelegue.delegationObligatoire) &&
        $scope.teleserviceConfiguration.workflow.depotDelegue.delegationObligatoire) ||
        (_.isUndefined($scope.teleserviceConfiguration.workflow.depotDelegue.delegationObligatoire) &&
          !_.isUndefined($scope.teleserviceConfiguration.workflow.depotDelegue.demandeurPeutEtreBeneficiaire) &&
          $scope.teleserviceConfiguration.workflow.depotDelegue.demandeurPeutEtreBeneficiaire))
    ) {
      $scope.goToStep('beneficiaire-famille', true);
    }

    if (!$scope.teleserviceConfiguration.workflow.depotDelegue.actif) {
      $scope.goToStep('informations-generales', true);
    }

    $scope.viewConfiguration = $scope.demandeurBeneficiaireConfiguration;
    $scope.viewConfiguration.ns = 'teleservice.demandeur-beneficiaire';

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'beneficiaire';
  },
]);
