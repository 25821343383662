angular
  .module('common.services')
  .factory('bourseService', ['$http', 'configuration', 'jwtSessionService', '$sce', bourseService]);

function bourseService($http, configuration, jwtSessionService, $sce) {
  'use strict';
  return {
    /**
     * Build formation iframe url
     * @param {string} referenceDemande
     * @param {Object} contribution
     * @param {boolean} readOnly
     * @param {boolean} visited
     * @param {boolean} displayHeaderFooter
     * @return {string}
     */
    generateFormationIframeSrc: function(referenceDemande, contribution, readOnly, visited, displayHeaderFooter) {
      let templateFormationIframeSrc =
        configuration.referentielFinancementBourse.ux +
        '<%= tenantId %>/demandes-financement/<%= demandeId %>/formation?jwtKey=<%= jwtKey %>&referenceContribution=<%= qsContribution %>&readOnly=<%= qsReadOnly %>&visited=<%= qsVisited %>&displayHeaderFooter=<%= qsDisplayHeaderFooter %>';

      const key = jwtSessionService.getJwtKey();
      const templateData = {
        tenantId: configuration.tenant.id,
        demandeId: referenceDemande,
        jwtKey: key,
        qsContribution: contribution && contribution.reference,
        qsReadOnly: readOnly,
        qsVisited: visited,
        qsDisplayHeaderFooter: displayHeaderFooter,
      };
      if (contribution && contribution.typeContribution === 'REDIRECTION') {
        templateFormationIframeSrc += '&teleserviceRedirectionId=<%= qsTeleserviceRedirectionId %>';
        templateData.qsTeleserviceRedirectionId = _.get(contribution, 'teleservice.href');
      }

      const compiledFormationIframeSrc = _.template(templateFormationIframeSrc);

      const formationIframeSrc = compiledFormationIframeSrc(templateData);

      return $sce.trustAsResourceUrl(formationIframeSrc);
    },
    /**
     * Update data with message sent from formation iframe
     * @param {Object} msg
     * @param {Function} accept
     * @param {Function} reject
     * @param {Object} aide
     */
    updateFormationAide: function(msg, accept, reject, aide) {
      const action = _.get(msg, 'data.action');
      const bourse = _.get(msg, 'data.bourse');
      const state = _.get(msg, 'data.state');

      // Update aide
      if (action === 'updateBourse' && bourse) {
        _.set(aide, 'bourse', bourse);

        if (state === 'ok') {
          accept();
        } else {
          reject();
        }
      }
    },

    /**
     * Fetch the bourse with the given id
     */
    getBourseById: (id) => {
      return $http.get(id).then(({ data }) => data);
    },
  };
}
