// Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationRecapitulatifComponent', {
  templateUrl: 'depot/justification/recapitulatif/justification-recapitulatif.component.html',
  controller: /* @ngInject */ JustificationRecapitulatifController,
  bindings: {
    workflow: '<',
    justification: '=',
    persistenceConfiguration: '<',
    validStep: '<',
    disableNavigation: '<',
    saveJustification: '<',
    piecesExpanded: '=',
  },
});

function JustificationRecapitulatifController($scope, $log, piecesService) {
  var ctrl = this;

  ctrl.$onInit = function() {
    ctrl.uploadInProgress = false;
    ctrl.setPieceFomConfiguration();
  };

  ctrl.validatePieceAttestation = function() {
    var pieceAttestation = _.find(ctrl.justification.pieces, {
      reference: _.get(ctrl, 'workflow.attestation.piece.reference', 'pieceAttestation'),
    });
    pieceAttestation = _.get(pieceAttestation, 'documents', false);
    if (!pieceAttestation) return false;
    return pieceAttestation.length > 0;
  };

  ctrl.setFormValid = function() {
    $scope.justificationRecapitulatifForm.$valid = ctrl.validatePieceAttestation();
  };

  ctrl.isFormValid = function() {
    ctrl.setFormValid();
    return $scope.justificationRecapitulatifForm && $scope.justificationRecapitulatifForm.$valid;
  };

  /**
   * Method to set piece configuration
   */
  ctrl.setPieceFomConfiguration = function() {
    // Pieces's configuration
    ctrl.piecesConfiguration = {
      ns: 'teleservice.pieces',
      displayCharactersLeft: true,
      remoteValidation: false,
      showErrors: false,
    };

    // Shorcut for access to pieces
    ctrl.pieces = ctrl.setPieces();

    // Set pieces persistance
    ctrl.piecesPersistance = piecesService.setPiecesPersistance(ctrl.pieces, _.get(ctrl, 'piecesExpanded', []));

    // Directory of file's icons
    ctrl.urlFileIcons = './resources/images/file-icons/';

    // Url where to upload files
    ctrl.urlDocuments = _.get(ctrl.justification, "_links['mgs:documents'].href");
  };

  /**
   * Method to set pieces
   * @returns {array}
   **/
  ctrl.setPieces = function() {
    // first we set a default attestion piece
    var stepPieces = [
      {
        libelle: { value: _.get(ctrl, 'workflow.attestation.piece.title', 'Attestation') },
        reference: _.get(ctrl, 'workflow.attestation.piece.reference', 'pieceAttestation'),
        obligatoire: _.get(ctrl, 'workflow.attestation.piece.obligatoire', true),
        description: { value: _.get(ctrl, 'workflow.attestation.piece.description', '') },
        modeTransmission: 'DEPOSEE',
        documents: [],
      },
    ];
    // if resquested pieces are set on teleservice we use them
    if (_.get(ctrl, 'workflow.pieces', false)) {
      stepPieces = ctrl.workflow.pieces.map(function(piece) {
        return {
          libelle: { value: piece.title },
          reference: piece.reference,
          obligatoire: piece.obligatoire || false,
          modeTransmission: piece.envoiPostal ? 'ENVOIEPOSTAL' : 'DEPOSEE',
          documents: [],
        };
      });
    }

    // we use justification datas if exists
    if (!_.get(ctrl, 'piecesExpanded', false)) {
      return stepPieces;
    }

    var mergeStepPieceAndJustifPiece = _.map(stepPieces, function(piece) {
      return (
        _.find(ctrl.piecesExpanded, function(justifPiece) {
          return piece.reference === justifPiece.reference;
        }) || piece
      );
    });

    // we return a cloneDeep to prevent justification piece mapping sideEffect when PATCH is sent
    return _.cloneDeep(mergeStepPieceAndJustifPiece);
  };

  /**
   * Prevent form validation when keyboard "enter" is press
   */
  ctrl.preventValidationOnEnter = function(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  /**
   * Method for the justification-step-wizard-navigation to go to the previous step
   */
  ctrl.previousStep = function() {
    ctrl.validStep(-1);
  };

  /**
   * Method to apply on finish button
   * @param {function} action
   */
  ctrl.finishStep = function() {
    // we check if form is valid
    ctrl.setFormValid();
    if (!ctrl.isFormValid()) {
      $log.error('Required pieces missing to go to the next step');
      return;
    }
    ctrl.justification.statut = 'JUSTIFIED';
    return ctrl.validStep();
  };

  /**
   * Method to save justification
   */
  ctrl.save = function() {
    // we fusion current screen pieces with persistance pièces before send to the service
    ctrl.justification.pieces = _.union(ctrl.pieces, ctrl.piecesPersistance);
    // we use a correct piece mapping pefore patch
    ctrl.justification.pieces = ctrl.piecesMapping();
  };

  /**
   * Method to set all justification pieces needed fields before save
   * @returns {array}
   */
  ctrl.piecesMapping = function() {
    const documentsMapping = (documents, mapping) => _.map(documents, (document) => _.pick(document, mapping));

    const piecesExpanded = _.map(ctrl.justification.pieces, function(piece) {
      return {
        description: piece.description,
        documents: documentsMapping(piece.documents, ['href', 'id', 'rel', 'title', 'expand', 'error']),
        envoiPostal: piece.envoiPostal,
        libelle: piece.libelle,
        obligatoire: piece.obligatoire,
        reference: piece.reference,
        modeTransmission: _.get(piece, 'modeTransmission', 'DEPOSEE'),
      };
    });

    const justificationPieces = _.map(ctrl.justification.pieces, function(piece) {
      return {
        description: piece.description,
        documents: documentsMapping(piece.documents, ['href', 'id', 'rel', 'title']),
        envoiPostal: piece.envoiPostal,
        libelle: piece.libelle,
        obligatoire: piece.obligatoire,
        reference: piece.reference,
        modeTransmission: _.get(piece, 'modeTransmission', 'DEPOSEE'),
      };
    });

    ctrl.piecesExpanded = piecesExpanded.filter(
      (piece) => piece.modeTransmission === 'ENVOIEPOSTAL' || !_.isEmpty(piece.documents)
    );

    return justificationPieces.filter(
      (piece) => piece.modeTransmission === 'ENVOIEPOSTAL' || !_.isEmpty(piece.documents)
    );
  };

  /**
   * Method to say if piece is visible
   * @returns {boolean}
   */
  ctrl.isPieceVisible = function(piece) {
    if (!piece) return false;
    return piecesService.evalConditionPiece($scope, piece, 'conditionAffichage');
  };

  /**
   * Method to lock navigation
   */
  ctrl.lockNavigation = function() {
    ctrl.uploadInProgress = true;
  };

  /**
   * Method to unlock navigation
   */
  ctrl.unlockNavigation = function() {
    ctrl.uploadInProgress = false;
  };

  /**
   * Method to say if piece is required
   * @returns {boolean}
   */
  $scope.isPieceRequired = function(piece) {
    if (!piece) return false;
    return piece.obligatoire === true;
  };
}
