'use strict';
angular.module('user.directives', [
  'mgcrea.ngStrap.popover',
  'focus-if',
  'configuration',
  'alerts',
  'form',
  'pascalprecht.translate',
  'user.services',
  'user.templates',
]);
