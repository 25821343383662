angular
  .module('portailDepotDemandeAide.depot')
  .controller('depotSimpleDemandeurComplementaireController', depotSimpleDemandeurComplementaireController);

depotSimpleDemandeurComplementaireController.$inject = [
  '$scope',
  '$timeout',
  'tiersService',
  'viewsService',
  'StoreService',
  'IFrameCommunicationManager',
  '$q',
  'jsonpatch',
];

function depotSimpleDemandeurComplementaireController(
  $scope,
  $timeout,
  tiersService,
  viewsService,
  StoreService,
  IFrameCommunicationManager,
  $q,
  jsonpatch
) {
  $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pageInformationsDemandeur;
  $scope.cleanNavigate();
  $scope.navigate.ns = $scope.informationsComplementairesDemandeurConfiguration.ns;

  $scope.demandeur = StoreService.demandeur.get();
  $scope.initialDemandeurViews = _.cloneDeep($scope.demandeur.views);

  $scope.informationsComplementairesDemandeurConfiguration.showErrors = $scope.showErrorsOnNavigate();

  const iFrameCommunicationManagers = [];
  const viewsCommunicationManager = new IFrameCommunicationManager('#viewsInfosComps');
  iFrameCommunicationManagers.push(viewsCommunicationManager);

  function updateTiers(tiers) {
    if ($scope.tiers) {
      $scope.tiers = tiers;
    }

    StoreService.demandeur.set(tiers);
    _.set($scope, 'aide.demandeur.expand', tiers);
  }

  /**
   * Get the changes on the views properties since the screen was loaded as an
   * array of jsonpatch changes
   *
   * @returns {object[]} jsonpatch changes made on the views
   */
  function getViewsPatches() {
    return jsonpatch.compare({ views: $scope.initialDemandeurViews }, { views: $scope.demandeur.views });
  }

  /**
   * Check if the data set on the tiers views are valid and wait for them to be updated on the tiers in memory
   *
   * Done only if views are active and on first depot. If not on first depot, the tiers "views" infos comp are displayed on another screen
   *
   * @param {boolean} ignoreAllFields option to ignore errors on fields
   * @returns {Promise} promise that resolves when the views are updated or immediatly if they don't need to be updated
   */
  function validateViewsAndWaitForUpdate(ignoreAllFields) {
    if ($scope.tiers || !$scope.areViewsActive) {
      return $q.resolve();
    }

    viewsCommunicationManager.sendEvent({
      action: 'validViews',
      options: { skipRequiredErrors: ignoreAllFields, showAllErrors: !ignoreAllFields },
    });

    return viewsCommunicationManager.manageEventWithPromise((msg, resolve, reject) => {
      viewsService.updateStateViewsTiers($scope, msg, resolve, reject);
    });
  }

  $scope.navigate.beforePrevious = () => {
    return validateViewsAndWaitForUpdate(true);
  };

  $scope.navigate.next = () => {
    // this screen works with partial updates (JSON PATCH)
    const controleCompletude = _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false);
    return validateViewsAndWaitForUpdate(controleCompletude)
      .then(() => {
        const patches = StoreService.tiers.patches
          .get()
          .filter((patch) => !patch.path.startsWith('/views'))
          .concat(getViewsPatches());

        if (!_.isEmpty(patches)) {
          // When tiers infos-comp are active, referentiel-tiers will add the views property
          // according to the famille
          return tiersService.patchTiers($scope.demandeur.reference, patches, $scope.mdm).then((tiers) => {
            const familleExpand = _.get($scope.demandeur, 'famille.expand');

            tiers.famille.expand = familleExpand;

            StoreService.tiers.patches.clean();
            updateTiers(tiers);
          });
        }
      })
      .then(() => $scope.goToStep('demandeur-beneficiaire'))
      .catch(() => {
        // iframe validation / user rejected step change
      });
  };

  const { isEntrepriseOrAssociationAndActive, areInfosCompActive } = tiersService.areInfosCompTiersActive(
    $scope.pageOptions,
    $scope.teleserviceConfiguration,
    $scope.demandeur,
    $scope.tiers
  );

  $scope.isEntrepriseOrAssociationAndActive = isEntrepriseOrAssociationAndActive;

  // if tiers is defined, views were displayed previously so we can skip the page if there is nothing else
  if (!areInfosCompActive) {
    // navigate but don't remember the page we are leaving
    $scope.goToStep('demandeur-beneficiaire', true);
  }

  $scope.stepsWizard.steps = $scope.getSimpleSteps();
  $scope.stepsWizard.active = 'tiers';

  // If the tiers is defined, this means the infos-comp of the tiers were displayed in the recapitulatif
  // Thus we don't need to display it in this case
  if (!$scope.tiers) {
    tiersService.getViewsSrcFromFamille($scope.demandeur, $scope.demandeur.famille.href).then((data) => {
      $scope.areViewsActive = data.areViewsActive;
      $scope.viewsIframeSrc = data.viewsIframeSrc;

      // iFrame needs to be resized
      $timeout(() => {
        iFrameResize(
          {
            heightCalculationMethod: 'taggedElement',
            checkOrigin: false,
            inPageLinks: true,
          },
          '#viewsInfosComps'
        );
      }, 0);

      $scope.$watch(
        'demandeur',
        (newValue) => {
          if (newValue) {
            const viewsIframe = angular.element('#viewsInfosComps');
            if (viewsIframe && viewsIframe[0]) {
              viewsIframe[0].contentWindow.postMessage(
                {
                  action: 'updateContextExpressions',
                  contextExpressions: JSON.parse(JSON.stringify($scope.demandeur)),
                },
                '*'
              );
            }
          }
        },
        true
      );
    });

    const updateViewsTiers = (msg) => {
      viewsService.updateViewsEntity($scope, msg, $scope.demandeur, '#viewsInfosComps', {
        showAllErrors: $scope.informationsComplementairesDemandeurConfiguration.showErrors,
      });
    };

    viewsCommunicationManager.manageEvent(updateViewsTiers);

    $scope.$on('$destroy', () => {
      _.each(iFrameCommunicationManagers, (manager) => {
        manager.close();
      });
    });
  }
}
