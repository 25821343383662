angular.module('portailDepotDemandeAide.dashboard').controller('dashboardDemandesPaiementController', [
  '$scope',
  '$state',
  '$modal',
  '$stateParams',
  'demandesPaiementService',
  'suiviFinancementService',
  'alertsService',
  'aide',
  'dispositifEligible',
  'teleservicePaiement',
  'demandesPaiement',
  'decisions',
  '$timeout',
  'demandesAidesService',
  '$rootScope',
  '$log',
  'aidesService',
  '$translate',
  function(
    $scope,
    $state,
    $modal,
    $stateParams,
    demandesPaiementService,
    suiviFinancementService,
    alertsService,
    aide,
    dispositifEligible,
    teleservicePaiement,
    demandesPaiement,
    decisions,
    $timeout,
    demandesAidesService,
    $rootScope,
    $log,
    aidesService,
    $translate
  ) {
    $scope.aide = aide;
    $scope.referenceDossier = $stateParams.dossier;
    const ligneDossier = $scope.referenceDossier
      ? suiviFinancementService.getLigneWithDossier(aide, $scope.referenceDossier)
      : demandesAidesService.findLigneFinancement(aide);
    $scope.dossier = _.get(ligneDossier, 'financement.source');
    $scope.dossierTitle = $scope.dossier.title || _.get(ligneDossier, 'financement.financeur.title');

    const financeurHref = _.get($stateParams, 'financeur') || _.get(ligneDossier, 'financement.financeur.href');

    // Do not display demande paiement en cours if the owner is not the current user
    $scope.demandesPaiement = _.reduce(
      demandesPaiement,
      (acc, demande) => {
        if (
          _.get($rootScope, 'currentUser.self') !== _.get(demande, 'history.begin.user.href') &&
          demande.statut === 'EN_COURS'
        ) {
          return acc;
        }
        demande.tiersAuthorized = _.get($rootScope, 'currentUser.self') === _.get(demande, 'history.begin.user.href');
        return acc.concat(demande);
      },
      []
    );

    // teleservicePaiement is wrapped in a lodash get as it can be undefined
    $scope.configurationId = _.get(teleservicePaiement, 'reference');
    $scope.teleservicePaiement = teleservicePaiement;
    $scope.dispositifEligible = dispositifEligible;
    if ($scope.aide.multiFinanceur) {
      $scope.financeur = $scope.aide.multiFinancement.financeurs.find(({ href }) => href === financeurHref);
    }

    //see if there is new complements pieces
    _.each(demandesPaiement, function(demPai) {
      demPai.hasComplements = false;

      // filter complements associated to the connected user
      const filteredComplements = _.filter(_.get(demPai, 'demandesComplementPieces', []), (complement) => {
        return _.find(
          _.get($rootScope, 'currentUser.emails', []),
          (email) => email === _.get(complement, 'notification.destinataire.0')
        );
      });

      if (filteredComplements.length) {
        demPai.hasComplements = true;
        demPai.nbComplements = _.reduce(
          demPai.demandesComplementPieces,
          function(sum, complement) {
            var incr = _.get(complement, 'avancement') === 'TRANSMIS' ? 1 : 0;
            return sum + incr;
          },
          0
        );
      }
    });

    // Found the statut of financement
    if (aide.status === 'ACCEPTABLE' && aide.planFinancement) {
      $scope.ligneRegion = demandesPaiementService.findLigneFinancementDispositifEligible(aide);

      if (_.get($scope.ligneRegion, 'financement.statut')) {
        $scope.financementStatut = _.get($scope.ligneRegion, 'financement.statut');
      }
    }

    /**
     * Method which allows to display paiement button
     * @return {boolean}
     */
    function canDisplayPaiementBtn() {
      return (
        // we check if aide have at least one event with type DECISION and avis FAVORABLE like it said in PDA9-275 RG-01
        // we check if DECISION are actives (not CLOTURE)
        aidesService.isAideHaveAtLeastOneActiveDecisionFavorable($scope.ligneRegion) &&
        // next we do somme authorization validations
        $scope.autorisationDemandePaiement &&
        !_.get($scope, 'aide.cloture.value') &&
        $scope.configurationId &&
        $scope.canCreateDemandePaiement
      );
    }

    function createDemandePaiement() {
      demandesPaiementService
        .canCreateDemandePaiement({
          action: 'POST',
          resource: '/referentiel-financement/api/tenants/' + $stateParams.tenantId + '/demandes-paiement',
          environment: {
            body: {
              teleservicePaiement: $scope.teleservicePaiement,
              demandeFinancement: {
                href: $scope.aide.id,
              },
              dossierFinancement: {
                href: _.get($scope.dossier, 'href'),
              },
            },
          },
        })
        .then((response) => {
          $scope.canCreateDemandePaiement = response;
          $scope.displayPaiementBtn = canDisplayPaiementBtn();
        })
        .finally(() => {
          if ($scope.displayPaiementBtn && _.isEmpty(demandesPaiement) && $scope.hasARemainingAmountToAsk()) {
            // Added $timeout function so that redirection works
            // Found details here: http://stackoverflow.com/questions/28208893/ui-router-state-go-is-not-redirecting
            $timeout(function() {
              $state.go('app.connected.config.depot.demandePaiement', {
                configurationId: teleservicePaiement.reference,
                aide: aide,
                referenceAide: aide.reference,
                financeurHref: financeurHref,
                dossier: $stateParams.dossier,
              });
            });
          }
        });
    }

    let lignePF;
    if (_.get(aide, 'multiFinanceur') === true && _.isEmpty(aide.financeurPrincipal)) {
      lignePF = demandesAidesService.findLigneFinancementFinanceur(aide, financeurHref);
      $scope.autorisationDemandePaiement =
        $scope.teleservicePaiement && _.get(lignePF, 'financement.autorisationDemandesPaiementDemandeur', false);

      createDemandePaiement();
    } else {
      lignePF = suiviFinancementService.getFinancialLine(aide);

      demandesPaiementService
        .canCreatePaiement(_.get(lignePF, 'financement.dispositif.href'))
        .then(({ ouvertureDemandePaiementSurDecisionDemandeur, teleservicePaiement }) => {
          $scope.autorisationDemandePaiement = ouvertureDemandePaiementSurDecisionDemandeur;
          $scope.teleservicePaiement = teleservicePaiement;
        })
        .catch((error) => {
          $log.warn('Dispositif unreachable', error);
          $scope.autorisationDemandePaiement = _.get(
            lignePF,
            'financement.autorisationDemandesPaiementDemandeur',
            false
          );
        })
        .then(() => {
          createDemandePaiement();
        });
    }

    /**
     * Affichage d'une fenêtre modale demandant la confirmation de la suppression d'une demande
     */
    $scope.deleteDemandePaiement = function(demandePaiement) {
      var scopeModal = $scope.$new();
      scopeModal.demandePaiement = demandePaiement;

      $modal({
        scope: scopeModal,
        template: 'dashboard/aides/demandes-paiement/modal/modal-delete-demande.html',
      });
    };

    // We need a comparator to order demand list with Date & hour (without a comparator, it only order on date)
    $scope.dateTimeComparator = function(date1, date2) {
      return moment(_.get(date1, 'value.begin.date')).isAfter(moment(_.get(date2, 'value.begin.date'))) ? 1 : -1;
    };

    /**
     * Suppression d'une demande
     * @param  {object} modal       Modal to hide after the persistence has been deleted
     * @param  {object} demandePaiement Demande paiement
     */
    $scope.confirmDeleteDemande = function(modal, demandePaiement) {
      modal.$hide();

      demandesPaiementService
        .removeDemandePaiement(demandePaiement)
        .then(function() {
          // Supprimer l'élément de la liste sans recharger pour autant.
          $scope.demandesPaiement = _.filter($scope.demandesPaiement, function(p) {
            return demandePaiement.reference !== p.reference;
          });

          $scope.alerts = alertsService.getAlertSuccess(
            $translate.instant('connected.dashboard.aides.demandesAides.deleted')
          );
        })
        .catch(function(error) {
          $scope.alerts = alertsService.getAlertError(`${$translate.instant('common.error.happened')} ${error.data}`);
        });
    };

    $scope.goToListAides = function() {
      $state.go('app.connected.dashboard.aides.demandesAides');
    };

    /**
     * Checks if there is still an amount to request on the voted amount
     */
    $scope.hasARemainingAmountToAsk = function() {
      return demandesPaiementService.getMontantRestantADemander($scope.demandesPaiement, decisions) > 0;
    };
  },
]);
