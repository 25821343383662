/**
 * @ngdoc component
 * @module components.autorisations
 * @name autorisationsComponent
 * @description
 *
 *   Allow to select a "financeur" before starting the "depot"
 *
 * @param {object} tenantId - tenantId
 *
 * @example
 *
 * <choix-financeur teleservice-configuration="$scope.teleserviceConfiguration" financeur-selected="$scope.financeurSelected(financeur)"></choix-financeur>
 *
 */
angular.module('portailDepotDemandeAide.depot').component('choixFinanceur', {
  templateUrl: 'depot/simple/financeur/component/choix-financeur.component.html',
  controller: choixFinanceurController,
  require: {
    form: '?^^form',
  },
  bindings: {
    teleserviceConfiguration: '<',
    ns: '<',
    onFinanceurSelected: '&',
    financeurPrincipal: '<',
  },
});

choixFinanceurController.$inject = ['tiersService'];

function choixFinanceurController(tiersService) {
  var ctrl = this;

  // list of "financeurs" to display on the list box
  var financeursPossibles = [];

  ctrl.$onInit = function() {
    financeursPossibles = _.get(ctrl.teleserviceConfiguration, 'workflow.pageFinanceur.financeurs', []);
    financeursPossibles = _.forEach(financeursPossibles, (financeur) => (financeur.id = financeur.href));

    if (ctrl.financeurPrincipal && !ctrl.selectedFinanceur) {
      tiersService
        .getTiersFinanceurPrivilegieByGroupeGestionHref(_.get(ctrl, 'financeurPrincipal.groupesGestion[0].href'))
        .then(function(financeurs) {
          if (financeurs) {
            ctrl.selectedFinanceur = _.get(financeurs, 'thematiquesLiees.financeur[0]');
            ctrl.selectFinanceur(ctrl.selectedFinanceur);
          }
        });
    }
  };

  /**
   * Allow to search financeur
   */
  ctrl.searchFinanceurs = function(searchText) {
    return tiersService.findFinanceursPrivilegie(searchText).then(function(financeurs) {
      var filteredFinanceurs = _.intersectionBy(financeurs, financeursPossibles, 'id');
      filteredFinanceurs = filteredFinanceurs.splice(0, 20);
      filteredFinanceurs = _.map(filteredFinanceurs, 'thematiquesLiees.financeur.0');
      filteredFinanceurs = _.orderBy(filteredFinanceurs, 'financeurPrivilegie.libelleCourt', 'asc');
      return filteredFinanceurs;
    });
  };

  /**
   * When a new financeur is selected
   */
  ctrl.selectFinanceur = function(financeur) {
    ctrl.onFinanceurSelected && ctrl.onFinanceurSelected({ financeur });
  };
}
