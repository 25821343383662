angular.module('portailDepotDemandeAide.dashboard').controller('dashboardCentreEchangesController', [
  '$scope',
  '$sce',
  '$stateParams',
  '$state',
  '$httpParamSerializer',
  'alertsService',
  'jwtSessionService',
  '$rootScope',
  'StoreService',
  function(
    $scope,
    $sce,
    $stateParams,
    $state,
    $httpParamSerializer,
    alertsService,
    jwtSessionService,
    $rootScope,
    StoreService
  ) {
    const iframeCentreEchangesParams = $httpParamSerializer({
      jwtKey: jwtSessionService.getJwtKey(),
      theme: 'demandeur',
      currentTiers: StoreService.currentTiersRef.get(),
    });
    $scope.iframeCentreEchangesUrl = $sce.trustAsResourceUrl(
      '/referentiel-echanges/#/' + $stateParams.tenantId + '/centreEchangesUsager?' + iframeCentreEchangesParams
    );

    $scope.resizeIframe = function() {
      iFrameResize(
        {
          heightCalculationMethod: 'taggedElement',
          checkOrigin: false,
          inPageLinks: true,
          scrolling: true,
        },
        '#centreEchanges'
      );
    };

    function listenIframeMessages(msg) {
      if (msg.data && msg.data.action === 'updateIframeCentreEchanges') {
        $scope.resizeIframe();
      } else if (msg.data && msg.data.action === 'goToWorkflowDemandeFinancement') {
        $state.go('app.connected.config.depot.simple', {
          configurationId: msg.data.teleserviceRef,
          p: msg.data.demandeRef,
          fromEchange: msg.data.echangeRef,
        });
      } else if (msg.data && msg.data.action === 'goToRecapDemandeFinancement') {
        $state.go('app.connected.dashboard.recapitulatif', {
          aide: msg.data.demandeRef,
          fromEchange: msg.data.echangeRef,
        });
      } else if (msg.data && msg.data.action === 'unAuthorizedUserOnAide') {
        $rootScope.$broadcast(
          'alerts',
          alertsService.getAlertError('connected.dashboard.alerteur.echanges.error'),
          'general-error'
        );
      }
    }

    window.addEventListener('message', listenIframeMessages);
  },
]);
