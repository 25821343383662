angular.module('portailDepotDemandeAide.dashboard').controller('dashboardDeposerDemandeAideController', [
  '$scope',
  'teleservices',
  'familles',
  'tiers',
  function($scope, teleservices, familles, tiers) {
    // Filter teleservice by date
    $scope.teleservices = (teleservices || []).filter((teleservice) => {
      // Do not display teleservice with no famille
      return _.get(teleservice, 'workflow.familles.length', 0) > 0;
    });

    // If there is a tiers for the current user, we pick the familles matching with his famille
    const tiersFamille = _.get(tiers, 'famille.href');
    if (tiersFamille) {
      $scope.familles = (familles.raw || []).filter((famille) => tiersFamille === famille.id);
    }
    // otherwise, we pick the familles that exist in at least on teleservice
    else {
      $scope.familles = (familles.raw || []).filter((famille) => {
        return $scope.teleservices.some((teleservice) => {
          return _.get(teleservice, 'workflow.familles', []).some(({ href }) => href === famille.id);
        });
      });
    }

    $scope.namespace = 'connected.dashboard.deposerDemandeAide';

    // Pagination
    $scope.pagerConfig = {
      totalItems: $scope.teleservices.length,
      itemsPerPage: 20,
      maxSize: 5, //Number of pager buttons to show
      currentPage: 1,
    };

    $scope.refreshPagination = () => {
      // When pagination changes, we just bring the user back to page 1 to keep things simple
      $scope.pagerConfig.currentPage = 1;
      // update totalItems by applying filters
      $scope.pagerConfig.totalItems = $scope.teleservices.filter($scope.searchFilter).length;
    };

    // search options
    $scope.search = {
      text: '',
      famille: '',
    };

    // do not enable famille filter if the user is already attached to a tiers
    if (tiers) {
      $scope.disableFamillesFilter = true;
      $scope.search.familleHref = tiersFamille;
    }

    // Filter teleservice with text and familles options
    $scope.searchFilter = (teleservice) => {
      const libelleFilter = S((teleservice.title || '').toLowerCase())
        .latinise()
        .include(S($scope.search.text.toLowerCase()).latinise());

      const familles = _.get(teleservice, 'workflow.familles', []);
      const familleHasTeleservice =
        !$scope.search.familleHref || familles.some((famille) => famille.href === $scope.search.familleHref);

      return libelleFilter && familleHasTeleservice;
    };
  },
]);
