angular.module('portailDepotDemandeAide.depot').controller('depotSimpleInformationsGeneralesController', [
  '$scope',
  '$timeout',
  '$location',
  '$anchorScroll',
  'configuration',
  'viewsService',
  'localisationsService',
  'bourseService',
  'IFrameCommunicationManager',
  '$q',
  'StoreService',
  function(
    $scope,
    $timeout,
    $location,
    $anchorScroll,
    configuration,
    viewsService,
    localisationsService,
    bourseService,
    IFrameCommunicationManager,
    $q,
    StoreService
  ) {
    'use strict';

    const controleCompletude = _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false);

    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageInformationsGenerales', {});
    $scope.config = configuration;
    $scope.realisationEvaluation = 'realisationEvaluation';

    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.informationsGeneralesConfiguration.ns;
    $scope.informationsGeneralesConfiguration.showErrors = $scope.showErrorsOnNavigate();
    _.set($scope.informationsGeneralesConfiguration, 'select.ordreOptions', 'value');

    // La localisation des demandes de financement est elle active sur le téléservice ?
    // N.B. : sur l'espace usager on désactive la localisation si aucune hiérarchie n'est visible.
    $scope.localisationActif = localisationsService.isLocationActiveOnClaimantPortal($scope.teleserviceConfiguration);

    const realisationEtEvaluation = $scope.pageOptions.realisationEtEvaluation || {};
    $scope.realisationEtEvaluationActif =
      realisationEtEvaluation.actif &&
      // at least one field should be visible
      (realisationEtEvaluation.fields || []).some(({ hidden }) => !hidden);

    // Les moyens humains des demandes de financement est elle active sur le téléservice ?
    $scope.moyensHumainsActif = _.get($scope, 'pageOptions.moyensHumains.actif', false);

    // Les caracteristiques sociales des demandes de financement sont elles active sur le téléservice ?
    $scope.caracteristiquesSocialesActif = _.get($scope, 'pageOptions.caracteristiquesSociales.actif', false);

    // Generation of the url to load the views.
    // Allows to know if we have to display the iframe or not
    let viewsPage = [];

    StoreService.depot.pendingPromises.promise().then(() => {
      if (_.some(_.get($scope, 'aide.views'))) {
        viewsPage = _.filter(_.get($scope, 'aide.views'), {
          page: 'pageInformationsGenerales',
        });
      }

      if (_.some(viewsPage)) {
        $scope.viewsIframeSrc = viewsService.getViewsIframeUrl(
          $scope.aide,
          'pageInformationsGenerales',
          'demandeur',
          false,
          5,
          null,
          {
            'referentiel-tiers': [
              'Accept:application/vnd.mgdis.tiers-3.19.0+json',
              'Content-Type:application/vnd.mgdis.tiers-3.19.0+json',
            ],
          }
        );

        viewsCommunicationManager.manageEvent(updateViewsAide);
        viewsCommunicationManager.manageEvent(updateContextExpressions);
      }

      // Wait for iframes to be loaded
      $timeout(function() {
        iFrameResize(
          {
            heightCalculationMethod: 'taggedElement',
            checkOrigin: false,
            inPageLinks: true,
          },
          '#localisationIframe, #viewsIframe1, #formationIframe'
        );
      }, 0);
    });

    const iFrameCommunicationManagers = [];
    const viewsCommunicationManager = new IFrameCommunicationManager('#viewsIframe1');
    iFrameCommunicationManagers.push(viewsCommunicationManager);
    const formationCommunicationManager = new IFrameCommunicationManager('#formationIframe');
    iFrameCommunicationManagers.push(formationCommunicationManager);

    let forgetNextStep = false;

    function updateViewsAide(msg) {
      viewsService.updateViewsEntity($scope, msg, $scope.aide, '#viewsIframe1', {
        showAllErrors: $scope.informationsGeneralesConfiguration.showErrors,
        skipRequiredErrors: controleCompletude,
      });
    }

    function updateContextExpressions(msg) {
      if (_.get(msg, 'data.action', '') === 'ready') {
        // Sends the iframe an up to date context when the 'Aide' is modified.
        // This allows expressions to be evaluated against 'Aide' data which is modified on the same screen
        $scope.$watch(
          'aide',
          function(newValue, oldValue) {
            if (newValue !== oldValue) {
              const viewsIframe = angular.element('#viewsIframe1');
              if (viewsIframe && viewsIframe[0]) {
                viewsIframe[0].contentWindow.postMessage(
                  {
                    action: 'updateContextExpressions',
                    contextExpressions: JSON.parse(JSON.stringify($scope.aide)),
                  },
                  '*'
                );
              }
            }
          },
          true
        );
      }
    }

    /**
     * Add listener on page creation
     */
    this.$onInit = function() {
      const localisationManager = new IFrameCommunicationManager();
      localisationManager.manageEvent(receiveMessageEvent);
      iFrameCommunicationManagers.push(localisationManager);
    };

    /**
     * Méthode qui écoute les messages provenant des différentes Iframes
     * @param msg Message de l'iframe
     */
    function receiveMessageEvent(msg) {
      const action = msg.data.action;

      if (action) {
        $scope.navigate.block = false;
      }
      if (action === 'fetchCustomHierarchies') {
        const customHierarchies = _.get($scope, 'pageOptions.informationsLocalisation.hierarchies', null);
        if (customHierarchies && !_.isEmpty(customHierarchies)) {
          msg.source.postMessage(
            {
              action: 'sendCustomHierarchies',
              payload: customHierarchies,
              showErrorsOnPage: $scope.informationsGeneralesConfiguration.showErrors,
              hasLocalisations: _.some($scope.aide.localisations),
            },
            event.origin
          );
        }
      }

      // Adding a localisation
      if (action === 'localisationAdded') {
        const localisationToAdd = _.get(msg, 'data.localisation');
        localisationToAdd.href = localisationToAdd.id;
        delete localisationToAdd.id;

        if ($scope.aide && Array.isArray($scope.aide.localisations)) {
          const localisationsHref = $scope.aide.localisations.map(({ href }) => href);

          const isLocalisationPresente = _.includes(localisationsHref, localisationToAdd.href);
          if (!isLocalisationPresente) {
            $scope.aide.localisations.push(localisationToAdd);
          }
        } else {
          _.set($scope, 'aide.localisations', [localisationToAdd]);
        }
      }

      // Suppression d'une localisation
      if (action === 'localisationDeleted') {
        const localisationToRemove = _.get(msg, 'data.localisation');
        if (_.get($scope, 'aide.localisations')) {
          _.remove($scope.aide.localisations, function(localisation) {
            return localisation.title === localisationToRemove.title;
          });
        }
      }
    }

    $scope.navigate.beforePrevious = () => {
      if (!_.isEmpty(viewsPage)) {
        return validateViewsAndWaitForUpdate({
          skipRequiredErrors: true,
          showAllErrors: false,
        });
      }
    };

    /**
     *
     */
    $scope.navigate.next = function(forget) {
      forgetNextStep = forget;
      if (
        _.isEmpty(viewsPage) &&
        !$scope.localisationActif &&
        !$scope.moyensHumainsActif &&
        !$scope.formationActif &&
        !$scope.realisationEtEvaluationActif
      ) {
        $scope.goToStep('pageInformationsGenerales2', forget);
      } else {
        if ($scope.localisationActif) {
          //Detection des erreurs éventuelles sur le champ localisations
          const localisationIframe = angular.element('#localisationIframe');
          localisationIframe[0].contentWindow.postMessage(
            {
              action: 'validLocalisation',
              payload: _.get($scope, 'aide.localisations'),
            },
            '*'
          );

          if (_.isEmpty($scope.aide.localisations) && !controleCompletude) {
            return;
          }
        }

        if ($scope.realisationEtEvaluationActif) {
          //controle de la selection d'évaluation
          const evaluationFields = _.get($scope, 'pageOptions.realisationEtEvaluation.fields[1]', []);
          const evaluationSelectionnee = _.isEmpty(_.get($scope, 'aide.evaluation'));
          if (_.get(evaluationFields, 'required' && evaluationSelectionnee && !controleCompletude)) {
            return;
          }
        }

        // Contrôle de sélection de la moyens humains
        const fields = _.get($scope, 'pageOptions.moyensHumains.fields', []);
        $scope.moyensHumainsElementRequired = _.find(fields, function(field) {
          if (field.reference === 'description') {
            return field.required;
          }
        });
        $scope.moyensHumainsSelectionnee = !_.isEmpty(_.get($scope, 'aide.moyensHumains.description'));

        if (
          $scope.moyensHumainsActif &&
          !$scope.moyensHumainsSelectionnee &&
          $scope.moyensHumainsElementRequired &&
          !controleCompletude
        ) {
          return;
        }

        // Le nombre d'ETPT doit être inférieur ou égal au nombre de personnes.
        if ($scope.moyensHumainsActif && _.get($scope, 'aide.moyensHumains')) {
          const foundError = _.find(_.get($scope, 'aide.moyensHumains.content'), function(moyen) {
            return !_.isNaN(moyen.nbPersonnes) && moyen.nbPersonnes < moyen.nbETPT;
          });
          if (foundError) {
            $location.hash('moyensHumainsTableau');
            $anchorScroll();
            return;
          } else {
            _.each($scope.aide.moyensHumains.content, function(moyenHumain) {
              delete moyenHumain.title;
              delete moyenHumain.nbEtptTotal;
              delete moyenHumain.nbPersonnesTotal;
            });
          }
        }

        const skipPage = _.isEmpty(viewsPage) && !$scope.formationActif;
        if (skipPage) {
          $scope.goToStep('pageInformationsGenerales2', forget);
        }

        const iFramePromises = [];

        if (!_.isEmpty(viewsPage)) {
          const viewsValidationPromise = validateViewsAndWaitForUpdate({
            skipRequiredErrors: controleCompletude,
            showAllErrors: !controleCompletude,
          });
          iFramePromises.push(viewsValidationPromise);
        }

        if ($scope.formationActif) {
          const formationValidationPromise = formationCommunicationManager
            .sendEvent({
              action: 'validate',
              options: { saveInProgress: controleCompletude },
            })
            .manageEventWithPromise((msg, resolve, reject) => {
              bourseService.updateFormationAide(msg, resolve, reject, $scope.aide);
            })
            .catch(() => {
              $location.hash('formationIframe');
              $anchorScroll();
              throw new Error('A field from formation suivie is in error.');
            });

          iFramePromises.push(formationValidationPromise);
        }

        $q.all(iFramePromises)
          .then(() => {
            // goToStep has already been called if skipPage
            if (!skipPage) $scope.goToStep('pageInformationsGenerales2', forgetNextStep);
          })
          .catch(() => {
            // iframe validation / user rejected step change
          });
      }
    };

    /**
     * Check if the data set on the demande views are valid and wait for them to be updated on the demande in memory
     *
     * @param {boolean} showAllErrors show all errors at the validation
     * @param {boolean} skipRequiredErrors ignore required fields for the page validation
     * @param {boolean} skipRequiredErrors ignore all fields for the validation
     * @returns {Promise} promise that resolves when the views are updated
     */
    function validateViewsAndWaitForUpdate({ showAllErrors = true, skipRequiredErrors = false }) {
      return viewsCommunicationManager
        .sendEvent({
          action: 'validViews',
          options: {
            showAllErrors: showAllErrors,
            skipRequiredErrors: skipRequiredErrors,
          },
        })
        .manageEventWithPromise((msg, resolve, reject) => {
          viewsService.updateStateViews($scope, msg, resolve, reject, $scope.teleserviceConfiguration);
        });
    }

    $scope.formationConfiguration = _.get($scope, 'pageOptions.formationSuivie', {});

    // Check formation data
    $scope.formationActif = _.get($scope, 'formationConfiguration.actif', false);
    if ($scope.formationActif) {
      // We block navigation to avoid skipping the page before the form validation
      $scope.navigate.block = true;

      $scope.formationTitle = $scope.formationConfiguration.title;
      $scope.formationIframeSrc = bourseService.generateFormationIframeSrc(
        $scope.aide.reference,
        $scope.contribution,
        false,
        $scope.showErrorsOnNavigate(),
        true
      );
    }

    if (_.get($scope.pageOptions, 'actif') === false) {
      $scope.navigate.next(true);
    }

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'dossier';

    // Localisation du dossier
    if ($scope.localisationActif) {
      // We block navigation to avoid skipping the page before the form validation
      $scope.navigate.block = true;
      // Récupération et affectation de l'URL de l'iframe de sélection de la localisation
      $scope.localisationIframeSrc = localisationsService.getLocalisationsIframeUrl(
        $scope.teleserviceConfiguration,
        $scope.aide.localisations
      );
    }

    // Remove listeners
    $scope.$on('$destroy', function() {
      _.each(iFrameCommunicationManagers, (manager) => {
        manager.close();
      });
    });
  },
]);
