/**
 * @ngdoc controller
 * @module portailDepotDemandeAide
 * @area lib
 * @name depotSimpleController
 * @description
 *
 *   This controller manages a series of page for 'simple' model of procedure
 *
 */

/*eslint max-params:0 */
angular.module('portailDepotDemandeAide.depot').controller('depotOffresStageController', [
  '$scope',
  '$rootScope',
  '$window',
  '$state',
  '$location',
  'teleserviceConfiguration',
  'userSessionService',
  'offreStage',
  'configuration',
  'siret',
  function(
    $scope,
    $rootScope,
    $window,
    $state,
    $location,
    teleserviceConfiguration,
    userSessionService,
    offreStage,
    configuration,
    siret
  ) {
    'use strict';

    $scope.offreStage = offreStage;
    $scope.siret = siret;
    $scope.workflow = offreStage.teleservice.expand.workflow;

    // Add begin event to entity if not present
    if (_.isEmpty(_.get($scope.offreStage, 'history.begin'))) {
      $scope.offreStage.history.begin = {
        summary: 'Creation of the entity - Status ' + $scope.offreStage.status,
        user: {
          href: configuration.user.accountManagement + /users/ + _.get(userSessionService.getUser(), 'userName'),
          method: 'GET',
        },
        date: new Date(),
        metadata: {
          step: 'preambule',
          stepsStack: [],
        },
      };
    }

    // Persistence
    // Manage step navigations
    $scope.$watch('offreStage.history.begin.metadata.step', function(newStep, oldStep) {
      // label of the page
      var depotState = $state.$current;
      depotState.data = $state.$current.data || {};
      $rootScope.pageConfigurationKey =
        (teleserviceConfiguration.libelle && teleserviceConfiguration.libelle.value) ||
        teleserviceConfiguration.reference;
      depotState.data.title = 'teleservice.' + newStep + '.title';
      // if (newStep && newStep !== 'preambule' && oldStep !== 'preambule' && newStep !== 'confirmation') {
      // }
    });

    // Add the reference in the url when the offreStage is created by progressOrCreate
    $scope.$watch('offreStage.reference', function(newReference, oldReference) {
      if (newReference && !oldReference) {
        $location.search({
          ref: newReference,
        });
      }
    });

    /// Navigation
    $scope.navigate.previous = function() {
      if ($scope.checkNextStep()) {
        // Get to last element of the stack of steps and remove it
        $scope.offreStage.history.begin.metadata.step = $scope.offreStage.history.begin.metadata.stepsStack.pop();
        $scope.cleanNavigate();
        $window.scrollTo(0, 0);
      }
    };

    // Small helper function for the 'next' children navigation functions
    $scope.goToStep = function(step, forget) {
      if ($scope.checkNextStep()) {
        if (!forget) {
          $scope.offreStage.history.begin.metadata.stepsStack.push($scope.offreStage.history.begin.metadata.step);
        }
        $scope.offreStage.history.begin.metadata.step = step;
        $scope.cleanNavigate();
        // Prevent keeping the scroll offset accross pages
        $window.scrollTo(0, 0);
      }
    };

    // Same as gotToStep but returns a function for later execution, makes it possible to use oneliners for navigate.next definitions
    $scope.goToStepFn = function(step) {
      return function(forget) {
        $scope.goToStep(step, forget);
      };
    };

    /**
     * Valide (ou pas) le passage à l'étape suivante (ou précédente)
     */
    $scope.checkNextStep = function() {
      return true;
    };

    // Prepare steps that match the requirement for almost all cases
    // For more specific behavior, every controller should do its work
    $scope.getSimpleSteps = function() {
      var steps = [];
      steps.push('preambule');
      steps.push('informations');
      steps.push('recapitulatif');
      return steps;
    };
  },
]);
