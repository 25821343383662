/**
 * @ngdoc module
 * @module document-fusion
 * @name document-fusion
 * @description
 *
 *   The main module for all interactions with the document-fusion
 *
 */

angular.module('document-fusion', ['document-fusion.templates']);

/**
 * @ngdoc directive
 * @module document-fusion
 * @name fusionDownloaderForm
 * @description
 *
 *   Create a link to download a document merged by the document-fusion service.
 *   This link will actually the submit button of a hidden form.
 *
 */

angular.module('document-fusion').directive('fusionDownloaderForm', ['$sce', '$http',

function($sce, $http) {
  'use strict';

  return {
    restrict : 'EA',
    replace : 'true',
    transclude : false,
    templateUrl : 'document-fusion/document-fusion-directives/fusion-downloader-form/fusion-downloader-form.html',
    scope : {
      label : '=',
      serviceBase : '=?',
      templateUri : '=?',
      content : '=',
      contentType : '=?',
      accept : '=?',
      noLinkClass : '=?'
    },
    link : function(scope) {
      scope.serviceBase = scope.serviceBase || '/document-fusion';
      scope.contentType = scope.contentType || 'application/json';
      scope.accept = scope.accept || 'application/pdf';

      scope.$watch('content', function() {
        scope.contentStr = typeof scope.content === 'string' ? scope.content : JSON.stringify(scope.content);
      });

      /* Setting the action dynamically seems to prevent the form from being sent*/
      scope.$watch('[serviceBase,templateUri]', function() {
        var url = scope.serviceBase + '/document';
        if (scope.templateUri) {
          url += '?templateURI=' + scope.templateUri;
        }
        scope.url = $sce.trustAsResourceUrl(url);
      }, true);

      // Javascript FORM post and download for document-fusion
      scope.downloadDocument = function() {
        $http({
          method: 'POST',
          url: scope.url,
          responseType: 'arraybuffer',
          headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
          },
          transformRequest : function(obj) {
            var str = [];
            for (var p in obj)
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            return str.join('&');
          },
          data : {
            '_method' : 'PUT',
            '_content' : scope.contentStr,
            '_content_type' : scope.contentType,
            '_accept' : scope.accept
          }
        }).then(function(response) {
          // Blob
          var fileName = (response.headers('content-disposition') && response.headers('content-disposition').split('=').length > 0) ? response.headers('content-disposition').split('=')[1] : 'download_file';
          var blob = new Blob([response.data], {
            type : scope.accept
          });
          saveAs(blob, fileName);
        });
      };

    }
  };
}]);