angular.module('common.services').factory('routeUtilsService', [
  '$log',
  '$state',
  function($log, $state) {
    'use strict';

    /**
     * ensure valid params on redirect
     * @param {string} toLocation state to name
     * @param {string} toParams state to params in json string form
     * @returns {object} parsed params
     */
    function ensureValidParams(toLocation, toParams) {
      let to = toLocation;
      let params = {};
      try {
        params = JSON.parse(toParams);
      } catch (err) {
        $log.error(err);
        to = 'app.connected.dashboard.accueil';
      }
      return { toLocation: to, toParams: params };
    }

    return {
      /**
       * Redirects to the "redirectTo" param if set or go to given destination
       * otherwise
       *
       * @param {string} destination destination if there is no redirection set
       * @param {object} options state go options
       * @returns {void}
       */
      handleRedirectionOrGoTo(destination, options) {
        if ($state.params.redirectTo) {
          this.manageRedirection(options);
        } else {
          $state.go(destination, {}, options);
        }
      },

      /**
       * @param {object} options state go options
       * @returns {void}
       */
      manageRedirection: function(options) {
        const { toLocation, toParams } = ensureValidParams($state.params.redirectTo, $state.params.redirectParams);
        // Redirection after home page authentification
        $state.go(toLocation, toParams, options);
      },

      /**
       * @param {string} name state name
       * @param {object} toParams state to parameters
       * @returns {void}
       */
      redirectToHome: function(name, toParams) {
        const askLocation = name;
        const askParams = JSON.stringify(toParams);
        $state.go('app.home', {
          tenantId: toParams.tenantId,
          redirectTo: askLocation,
          redirectParams: askParams,
        });
      },
    };
  },
]);
