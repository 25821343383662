angular.module('aides.services').factory('renewedDemandesFinancementService', RenewedDemandesFinancementService);

/* @ngInject */
function RenewedDemandesFinancementService(aidesService, AideSearch, demandesAidesService) {
  return {
    /**
     * Get demandes linked to the current one using the idCpo
     * @param {Object} demande
     * @returns {Promise<Array<Object>>}
     */
    getDemandesLiees: (demande) =>
      aidesService
        .searchAides(
          new AideSearch({
            idCpo: demande.aide.idCpo,
          }),
          null,
          null,
          ['demandesComplementPieces', 'demandeur', 'dispositif', 'teleservice', 'justification', 'demandesReport']
        )
        .then((results) => demandesAidesService.getListeDemandes(results.filter(({ id }) => id !== demande.aide.id))),
  };
}
