// Module declaration
angular.module('portailDepotDemandeAide.depot').component('justificationStepWizardContent', {
  templateUrl: 'depot/justification/step-wizard-content/justification-step-wizard-content.component.html',
  controller: /* @ngInject */ JustificationStepWizardContent,
  transclude: true,
  bindings: {
    workflow: '<',
    print: '<',
  },
});

// Controller
function JustificationStepWizardContent() {
  var ctrl = this;

  ctrl.$onInit = function() {};
}
