/**
 * @ngdoc service
 * @name tiers.services.tiersAccountsService
 * @requires $http, $q, $log, configuration
 * @description Access to a tiers' linked users
 **/
angular.module('tiers.services').factory('tiersAccountsService', [
  '$http',
  '$q',
  '$log',
  'configuration',

  function($http, $q, $log, configuration) {
    'use strict';

    var _tenantId;
    var _url = configuration.tiers.url || '/referentiel-tiers';

    // Configure either at provider level or directly service
    this.tenantId = function(tenantId) {
      _tenantId = tenantId;
    };
    this.url = function(url) {
      _url = url;
    };

    function _getUrl() {
      return _tenantId ? _url + '/' + _tenantId : _url;
    }

    return {
      /**
       * Get linked users of tiers
       * @param  {Tiers} tiers Tiers
       * @return {Array}       Linked users
       */
      getLinkedUsers: function(tiers) {
        return $http.get(configuration.tiers.rattachement.url + '/tiers/' + tiers.reference + '/linkedUsers');
      },

      /**
       * Define an administrator on a tiers
       * @param  {Tiers} tiers Tiers
       * @param  {String} userName  User who will become administrator
       */
      defineAdministratorOnTiers: function(tiers, userName) {
        return $http.post(_getUrl() + '/tiers/' + tiers.reference + '/administrator/' + userName);
      },

      /**
       * Invite user to attach on a tiers
       * @param  {Tiers} tiers Tiers
       * @param  {String} email Email
       */
      inviteUserToAttachOnTiers: function(tiers, email) {
        return $http.post(configuration.tiers.rattachement.url + '/tiers/' + tiers.reference + '/rattachements', {
          email: email,
        });
      },

      /**
       * AutoRattachement for user on tiers
       * @param  {Tiers} tiers Tiers
       * @param  {String} email Email
       */
      inviteUserToAutoAttachOnTiers: function(tiers, email) {
        return $http.post(configuration.tiers.rattachement.url + '/tiers/' + tiers.reference + '/autorattachement', {
          email: email,
        });
      },

      /**
       * Remove the administrator specify on tiers
       * @param  {Tiers} tiers    Tiers
       */
      removeAdministratorOnTiers: function(tiers) {
        var userIndex = _.findIndex(tiers.linkedUsers, function(linkedUser) {
          return linkedUser.form === 'ADMINISTRATOR';
        });

        var patch = [
          {
            op: 'remove',
            path: '/linkedUsers/' + userIndex,
          },
        ];

        return $http({
          method: 'PATCH',
          url: _getUrl() + '/tiers/' + tiers.reference,
          data: patch,
        });
      },

      /**
       * Send mail when delete user on tiers
       * @param {tiers} the reference of the tiers
       * @param {account} the reference of a user
       */
      mailDeleteUserTiers: function(tiers, account) {
        var userId = account.href.split('/').pop();
        return $http.put(
          configuration.tiers.rattachement.url + '/tiers/' + tiers.reference + '/deleteUser/' + userId,
          tiers
        );
      },

      /**
       * Detach an user from tiers
       * @param  {Tiers} tiers Tiers
       * @param  {string} accountId id of the account to remove
       */
      removeUserOnTiers: function(tiers, accountId) {
        const patches = [];

        _.forEachRight(tiers.linkedUsers, (linkedUser, index) => {
          if (linkedUser.href === accountId && linkedUser.form !== 'OWNER') {
            patches.push({
              op: 'remove',
              path: `/linkedUsers/${index}`,
            });
          }
        });

        return $http({
          method: 'PATCH',
          url: _getUrl() + '/tiers/' + tiers.reference,
          data: patches,
        });
      },

      /**
       * Fetch a user by its email from account-management using a service account.
       * @param {string} email Email of the user
       */
      getUserByEmail: function(email) {
        return $http({
          url: configuration.tiers.rattachement.url + '/users',
          params: {
            $filter: "emails eq '" + email + "'",
          },
        });
      },
    };
  },
]);
