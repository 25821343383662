class ContributionsFilterController {
  constructor($translate) {
    this.$translate = $translate;
  }

  $onInit() {
    this.ns = 'connected.dashboard.contributionsSearch.filters';

    this.types = [
      { title: this.$translate.instant(`${this.ns}.none`), value: null },
      { title: this.$translate.instant(`${this.ns}.AVIS`), value: 'AVIS' },
      { title: this.$translate.instant(`${this.ns}.AVIS_FINANCEMENT`), value: 'AVIS_FINANCEMENT' },
      { title: this.$translate.instant(`${this.ns}.MODIFICATION`), value: 'MODIFICATION' },
      { title: this.$translate.instant(`${this.ns}.REDIRECTION`), value: 'REDIRECTION' },
    ];

    this.searchText = '';
    this.typeFilter = this.types[0].value;
    this.typeFilterSelected = this.types[0].title;
  }

  /**
   * Update the contribution type filter
   * @param {object} type
   * @return {void}
   */
  changeTypeFilter(type) {
    this.typeFilter = type.value;
    this.typeFilterSelected = type.title;
    this.changeType(type.value);
    this.search(true);
  }

  /**
   * Trigger search on enter
   * @param {object} event
   * @return {void}
   */
  reload(event) {
    if (event && event.key === 'Enter') {
      this.search(true);
    }
  }
}

ContributionsFilterController.$inject = ['$translate'];

angular.module('contributions.components').component('contributionsFilter', {
  templateUrl: 'contributions/contributions-components/contributions-filter/contributions-filter.html',
  controller: ContributionsFilterController,
  bindings: {
    changeType: '=',
    searchText: '=',
    search: '=',
  },
});
