angular.module('tiers.model').factory('Representant', [
  'LocalizedText',
  'Link',
  'MasterDataRecord',
  'Individu',
  'Coordonnees',
  'Telephone',
  'configuration',
  'Geographique',
  'Courriel',
  function(
    LocalizedText,
    Link,
    MasterDataRecord,
    Individu,
    Coordonnees,
    Telephone,
    configuration,
    Geographique,
    Courriel
  ) {
    'use strict';

    /**
     * Classe Representant
     * @class Representant
     */
    function Representant(data, mdm, keepAdress) {
      data = data || {};
      mdm = mdm || {};

      this.id = data.id;
      this.libelleComplementaire = new LocalizedText(data.libelleComplementaire);
      this.sigle = data.sigle;
      this.civilite = new Link({
        title: data.civilite && data.civilite.title,
        href: data.civilite && data.civilite.href,
        expand: (data.civilite && data.civilite.expand) || new MasterDataRecord(),
      });
      this.individu = new Individu(data.individu);
      this.logo = data.logo;

      // Adresses du tiers
      this.situations = _.map(data.situations || [], function(coordonnees) {
        coordonnees = _.merge(coordonnees, coordonnees.expand);
        _.unset(coordonnees, 'expand');
        return new Coordonnees(coordonnees, mdm);
      });
      if (this.situations[0]) {
        this.courriels = data.courriels || this.situations[0].courriels || [];
      } else {
        this.courriels = data.courriels || [];
      }
      this.sitesWeb = data.sitesWeb || [];
      this.profession = data.profession;

      // Téléphones
      this.telephones = data.telephones || _.get(this.situations, '[0].telephones') || [];

      this.principal = data.principal || false;
      this.actif = _.get(data, 'actif', true);
      this.ordre = data.ordre;
      this.note = data.note;

      // Fonction
      this.fonction = data.fonction;
      this.entreeFonction = data.entreeFonction;
      this.sortieFonction = data.sortieFonction;

      // Champs complémentaires UX
      this.userIsRepresentant = data.userIsRepresentant || false;
      if (_.has(data, 'adresseCommuneTiers')) {
        this.adresseCommuneTiers = data.adresseCommuneTiers;
      } else if (!keepAdress) {
        this.adresseCommuneTiers = _.has(data, 'situations.0') ? false : true;
      }

      // Default representant.type for tiers entity 3.0
      this.types = _.get(data, 'types', [{ href: urljoin(configuration.tiers.url, 'typesRepresentants/REPRES') }]);
    }

    Representant.prototype = Object.create(Link.prototype);

    var cleanTelephones = function(telephones) {
      if (_.isArray(telephones)) {
        _.remove(telephones, function(tel) {
          return !tel.value;
        });
        return telephones;
      }
    };

    /**
     * Clean a representant
     * @return {Representant} représentant
     */
    Representant.prototype.getCleanEntity = function(keepUxControls) {
      var representant = angular.copy(this);

      if (representant.fonction) {
        delete representant.fonction.expand;
      }

      if (representant.civilite) {
        delete representant.civilite.expand;
      }

      if (representant.individu) {
        representant.individu = representant.individu.getCleanEntity();
      }

      // Situations
      representant.situations = _.map(representant.situations, function(situation) {
        return situation.getCleanEntity();
      });

      // Pays
      if (
        representant.coordonnees &&
        representant.coordonnees.geographique &&
        representant.coordonnees.geographique.Pays
      ) {
        representant.coordonnees.geographique.Pays = {
          code: representant.coordonnees.geographique.Pays.reference,
          value: representant.coordonnees.geographique.Pays.libelle,
        };
      }

      if (representant.telephones && representant.telephones.length > 0) {
        representant.telephones = cleanTelephones(representant.telephones);
      }

      if (!keepUxControls) {
        delete representant.userIsRepresentant;
        delete representant.adresseCommuneTiers;
      }

      if (Array.isArray(representant.courriels)) {
        representant.courriels = representant.courriels.filter((courriel) => courriel && courriel.value);
      }

      return representant;
    };

    /**
     * Initialise les valeurs du représentant en fonction du paramètre userIsRepresentant
     * @param  {object} user       Utilisateur connecté
     * @param  {object} telephones Téléphones
     * @param  {mdm} mdm Mdm
     */
    Representant.prototype.fillRepresentant = function(user, telephones, mdm, sectionAdresseActive) {
      if (this.userIsRepresentant && this.userIsRepresentant === true) {
        this.fillRepresentantWithUserInformations(user, angular.copy(telephones), mdm, sectionAdresseActive);
      } else {
        this.cleanRepresentant();
      }
    };

    /**
     * Initialisation de l'adresse du représentant en fonction de la sélection ou non d'une adresse différente du tiers
     * @param  {Geographique} coordonneesGeographiqueTiers Adresse géographique du tiers
     */
    Representant.prototype.checkAdresseCommuneTiers = function(situationTiers) {
      if (this.adresseCommuneTiers === undefined || !this.adresseCommuneTiers) {
        this.situations[0].geographique = new Geographique();
        this.situations[0].codePostalVille = '';
      } else {
        this.situations[0].geographique = new Geographique(angular.copy(situationTiers.geographique));
        this.situations[0].codePostalVille = this.situations[0].getCodePostalVille();
      }
    };

    /**
     * Method that fills the 'representant' information with the user's data
     * @param {Object} user user
     * @param {string[]} telephones tiers phones
     * @param {Object} mdm MDM
     * @param {boolean} sectionAdresseActive if property "sectionAdresseContacts" is active in public settings
     */
    Representant.prototype.fillRepresentantWithUserInformations = function(
      user,
      telephones,
      mdm,
      sectionAdresseActive
    ) {
      this.civilite = _.find(mdm.civilites.array, ['expand.libelle.value', user.name.honorificPrefix]);
      this.individu.Noms.NomUsage = user.name.familyName;
      this.individu.Prenoms.PrenomUsuel = user.name.givenName;

      if (user.emails && user.emails[0]) {
        // Création automatique d'une coordonnées si aucune n'existe au niveau du tiers et que l'option "sectionAdresseContacts" est vraie
        if (sectionAdresseActive && (!this.situations || this.situations.length === 0)) {
          this.situations = [new Coordonnees()];
        }

        var courriel = new Courriel();
        courriel.value = user.emails[0];
        courriel.TYPE = 'internet';
        this.courriels[0] = courriel;
        this.telephones = telephones;
      }
    };

    /**
     * Réinitialisation du représentant
     */
    Representant.prototype.cleanRepresentant = function() {
      var cleanData = {};

      // Keeping the tier's adress if it shall remain the same
      if (this.adresseCommuneTiers && this.situations && this.situations.length > 0) {
        cleanData.situations = [];
        cleanData.situations.push(new Coordonnees());

        cleanData.situations[0].geographique = this.situations[0].geographique;
        cleanData.situations[0].etrangere = this.situations[0].etrangere;
      }

      cleanData.principal = this.principal;

      _.merge(this, new Representant(cleanData, undefined, true));

      this.courriels = [];
      this.telephones = [];

      // Fonction
      this.fonction = undefined;
      this.entreeFonction = undefined;
      this.sortieFonction = undefined;
    };

    /**
     * Méthode qui indique si l'adresse est étrangère
     * @return {Boolean} Adresse étrangère
     */
    Representant.prototype.isForeignAddress = function() {
      return this.situations[0].geographique.Pays.code !== 'FR';
    };

    return Representant;
  },
]);
