angular.module('aides.recapitulatif').directive('recapitulatifPieces', [
  'piecesService',
  function(piecesService) {
    'use strict';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'aides/aides-directives/recapitulatif/pieces/recapitulatif-pieces.html',
      scope: {
        aide: '=',
      },
      link: function(scope, element, attributes, controllers) {
        scope.viewConfiguration = {
          ns: 'teleservice.pieces',
        };

        scope.urlFileIcons = './resources/images/file-icons/';

        /**
         * Evaluate conditionAffichage (visible/hidden) setting on a piece
         * @method isPieceVisible
         * @param {Object} piece the piece
         * @returns {Boolean} the evaluate condition
         */
        scope.isPieceVisible = function(piece) {
          return piecesService.evalConditionPiece(scope, piece, 'conditionAffichage');
        };
      },
    };
  },
]);
