/**
 * @ngdoc service
 * @name common.services.numberService
 * @requires $window
 * @description Manages numbers
 **/
angular.module('common.services').factory('numberService', function() {
  'use strict';
  return {
    /**
     * Rounds the given number with the given decimal digits
     */
    round: (number, digits) => {
      return parseFloat(Number(number).toFixed(digits));
    },
  };
});
