/**
 * @desc Select field with full text search on contrat
 * name: The fieldname as String
 * model: Ng-model
 * viewConfiguration: viewConfiguration
 */
angular.module('aides').component('contratField', {
  bindings: {
    name: '@',
    model: '=',
    viewConfiguration: '<',
    readOnly: '<',
    typeContrats: '<',
    required: '=',
  },
  templateUrl: 'aides/aides-directives/contrat-field/contrat-field.html',
  controller: contratFieldController,
});
contratFieldController.$inject = ['aidesService', '$scope'];

function contratFieldController(aidesService, $scope) {
  this.$onInit = () => {
    $scope.viewConfiguration = this.viewConfiguration;
    $scope.model = this.model;
  };

  /**
   * Called every time the user choose a contrat
   * @param {Object} item    the item selected
   * @return {void}
   */
  this.onChangeContrat = (item) => {
    this.localModel = item;
    this.model = {
      href: item.id,
      title: item.title,
    };
    $scope.model = this.model;
  };

  /**
   * Get a list of contrat based on search keywords
   * @param {string} searchKey The keywords space separed
   * @return {Promise<Array>}
   */
  this.getContrats = (searchKey) => {
    const escapedSearchKey = searchKey.replace(/'/g, "''");

    return aidesService.getContracts(this.typeContrats, escapedSearchKey).then((contratsTeleservice) => {
      return _.orderBy(contratsTeleservice, 'title').slice(0, 20);
    });
  };
}
