/**
 * @ngdoc service
 * @name common.services.piecesService
 * @requires $http, $q, $location, dataDocService, Piece
 * @description Interface for a Tiers' pieces
 **/
angular
  .module('common.services')
  .factory('piecesService', function(
    $http,
    $log,
    $q,
    cmisService,
    dataDocService,
    Piece,
    aidesService,
    contributionsService
  ) {
    'use strict';
    /**
     * Utils function dedicated to integration which permit to find a property in a collection by his reference
     * Use case : find(aide.specifiques, 'reference', 'RefDeMonChamp').value
     * (NB : .value  if it's an object to get the value)
     * @param collection
     * @param property
     * @param value
     */
    var find = function(collection, property, value) {
      return _.find(collection, function(item) {
        return _.get(item, property) === value;
      });
    };

    /**
     * Utils function dedicated to integration which permit to get a specific field or not in an array
     * Use case : getSpecificValue(aide.views, 'REFDEMONFORMULAIRE', 'RefDeMonChamp').value
     * (NB : if it's an object: ".value" to get the value, ".title" to get the title, etc..)
     * @param collection
     * @param viewReference
     * @param fieldReference
     */
    var getSpecificValue = function(collection, viewReference, fieldReference) {
      if (!collection || !viewReference || !fieldReference) return undefined;
      return _.get(
        _.find(collection, function(item) {
          return (
            _.get(item, 'expand.reference ') === viewReference ||
            _.get(item, 'schema.href', '?')
              .split('?')
              .shift()
              .split('/')
              .pop() === viewReference
          );
        }),
        'values[0].' + fieldReference
      );
    };
    return {
      /**
       * Return the url of service documents persistence
       * @param persistenceId
       * @param configurationId
       * @returns {*}
       */
      getUrlUploadDocumentsPersistence: function(persistenceId, configurationId) {
        return dataDocService.getUrlServiceDocumentsPersistence(persistenceId, configurationId);
      },

      /**
       * Initialize pieces persistence.
       * @param  {Array} piecesConfiguration Pieces's configuration
       * @param  {Array} piecesPersistence   Pieces in persistence
       * @return {Array}                     Pieces
       */
      initializePiecesPersistence: function(piecesConfiguration, piecesPersistence) {
        var pieces = _.map(piecesConfiguration, function(pieceConfiguration) {
          // Find the piece in persistence corresponding to this piece's configuration
          var piecePersistence = _.find(piecesPersistence, function(piece) {
            return piece.reference === pieceConfiguration.reference;
          });

          if (piecePersistence && pieceConfiguration.typesPaiement) {
            piecePersistence.typesPaiement = angular.copy(pieceConfiguration.typesPaiement);
          }

          var pieceParametree = new Piece(_.merge(piecePersistence, pieceConfiguration));
          // Récupération du paramétrage de l'envoi postal depuis le paramétrage du téléservice
          pieceParametree.envoiPostal = pieceConfiguration.envoiPostal || false;

          return pieceParametree;
        });

        // The alphabetic sort is no longer applied here
        return pieces;
      },

      /**
       * Function who return the list documents, document who are not attach to a piece.
       * @param  {Array} pieces           Pieces
       * @param  {String} urlDocuments    Url for get the list of documents and use for delete an orphan document
       * @return {Promise}                Promise for recover a list of id of document to delete
       */
      getDocumentsPersistenceToDelete: function(pieces, urlDocuments) {
        var documentsIdPieces = [];
        _.each(pieces, function(piece) {
          _.each(piece.documents, function(documentPiece) {
            documentsIdPieces.push(_.get(documentPiece, "expand.properties['cmis:objectId'].value"));
          });
        });

        var documentsId = [];
        return $http.get(urlDocuments).then(function(response) {
          // Datacollect-persistence
          _.each(response.data.results, function(documentPersistence) {
            documentsId.push(documentPersistence._id);
          });

          return _.difference(documentsId, documentsIdPieces);
        });
      },

      /**
       * Function who return the list documents which are not attached to a piece.
       * @param  {Array} pieces           Pieces
       * @param  {String} urlDocuments    Url to get the list of documents and use it to delete an orphan document
       * @return {Promise}                Promise to recover a list of documents' id to delete
       */
      getDocumentsUrlPersistenceToDelete: function(pieces, urlDocuments) {
        return $http.get(urlDocuments).then(function(response) {
          // Document-collect
          var documentsId = [];
          _.each(response.data.objects, function(documentPersistence) {
            var documentName = decodeURIComponent(_.get(documentPersistence, 'object.properties.cmis:name.value'));
            var objectId = _.get(documentPersistence, 'object.properties.cmis:objectId.value');
            documentsId.push(urlDocuments + '/' + documentName);
            documentsId.push(cmisService.getUrlDocuments(urlDocuments, objectId));
          });

          var documentsIdPieces = [];
          _.each(pieces, function(piece) {
            _.each(piece.documents, function(documentPiece) {
              documentsIdPieces.push(decodeURIComponent(documentPiece.id));
            });
          });

          return _.difference(documentsId, documentsIdPieces);
        });
      },

      /**
       * Evaluate display or required condition for a piece
       * @param {object} scope scope for evaluation condition
       * @param {object} piece Piece to evaluate
       * @param {string} typeCondition property to check : "conditionObligatoire" or "conditionAffichage"
       * @param {boolean} isDemandePaiement is piece for a "demande de paiement"
       * @returns {boolean} is piece match condition
       */
      evalConditionPiece: function(scope, piece, typeCondition, isDemandePaiement) {
        var condition;
        // If is "demande de paiement", check if its dossier is linked to a v8
        var hasCorrelationsV8 = false;
        if (isDemandePaiement) {
          hasCorrelationsV8 = _.find(
            _.get(scope, 'demandePaiement.demandeFinancement.expand.correlations', []),
            function(correlation) {
              return correlation.value.startsWith('/connecteur-aides-v9v8');
            }
          );
        }
        condition = _.get(piece, typeCondition);
        // If dossier is linked to a v8
        if (condition && hasCorrelationsV8) {
          // if one of the conditions contains a link with "dossierFinancement" then no condition is interpreted
          if (condition.includes('dossierFinancement')) condition = null;
        }

        if (!condition) {
          switch (typeCondition) {
            // Pour la rétro compatibilité et la factoristion on renvoi le caractère obligatoire de la pièce
            // dans le cas ou l'on est sur les conditions d'obligations d'une pièce
            case 'conditionObligatoire':
              return piece.obligatoire || false;
            default:
              return true;
          }
        }
        // Récupération du scope courant
        var currentScope = scope;
        // Ajout de librairies utilitaires
        currentScope.moment = moment;
        currentScope._ = _;
        // Exemple d'utilisation : JSONPath('$.planFinancement.0.recette..ligne[?(@.financement)]', aide)
        currentScope.JSONPath = JSONPath;

        // Ajout des fonctions utilitaires permettant la simplification de calcul des conditions sur une pièce
        currentScope.find = find;
        currentScope.getSpecificValue = getSpecificValue;

        var isOK = false;
        try {
          isOK = currentScope.$eval(condition);
        } catch (e) {
          $log.error(
            'Error on evaluate ' + typeCondition + ' : ' + condition + ' on piece ' + piece.reference + ', ' + e
          );
        }
        return isOK;
      },
      /**
       * Method to set pieces persistance for not used pieces in this screen
       * @param {array} displayedPieces original pieces
       * @param {array} piecesToFilter pieces to filter
       * @returns {array}
       */
      setPiecesPersistance: function(displayedPieces, piecesToFilter) {
        var screenPieces = displayedPieces || [];
        return _.filter(piecesToFilter, function(piece) {
          var isInScreen = _.find(screenPieces, { reference: piece.reference });
          if (!isInScreen) return piece;
        });
      },

      /**
       * If the piece should not be visible BUT documents are saved, we have to delete those documents.
       * Bug exemple: 1. piece has been uploaded
       *              2. then the user changes a field that impacts the condition
       *              3. the piece is not supposed to be visible anymore but the uploded document stays
       *
       * @param {object} aide
       * @param {object} contribution
       * @param {object} piece Piece to evaluate
       * @returns {Boolean} true if the clean was necessary
       */
      cleanNotVisiblePieces: function(aide, contribution, piece) {
        // documents in this piece
        if (!_.isEmpty(_.get(piece, 'documents', [])) && _.isNil(piece.handled)) {
          // we do this operation only once per piece
          piece.handled = true;
          // Documents found for this piece that is not visible
          return this.emptyPieceFromEntity(aide, contribution, piece);
        }

        // No documents to delete
        return $q.resolve(false);
      },

      /**
       * Delete all documents of a piece in database AND GED
       * @param {object} aide The demande-financement
       * @param {object} contribution The contribution
       * @param {object} piece The document to remove
       * @return {Promise}
       */
      emptyPieceFromEntity: function(aide, contribution, piece) {
        const defaultUrlDocuments = _.get(aide, '_links[`mgs:documents`].href');
        // Remove from GED
        const promises = _.get(piece, 'documents', []).map((documentPiece) => {
          // Remove ALL documents of this piece
          return this.deleteDocumentOnGED(documentPiece, defaultUrlDocuments);
        });

        // Remove all documents from GED
        return $q
          .all(promises)
          .then(() => {
            // Remove all documents from aide for this piece
            const newAide = _.cloneDeep(aide);
            const pieces = aide.pieces;
            const indexPiece = _.findIndex(pieces, (p) => p.reference === piece.reference);
            if (indexPiece > -1) {
              _.set(newAide, `pieces[${indexPiece}].documents`, []);

              const updateEntity = contribution
                ? contributionsService.saveContribution(newAide, contribution)
                : aidesService.update(newAide);

              // Save aide or contribution
              return updateEntity
                .then(() => true)
                .catch((err) => {
                  $log.error('[emptyPieceFromEntity] - error updating aide', err);
                  return false;
                });
            } else {
              $log.error('[emptyPieceFromEntity] - error indexPiece = -1');
              return false;
            }
          })
          .catch((err) => {
            $log.error('[emptyPieceFromEntity] - error deleting docs from GED', err);
            return false;
          });
      },

      /**
       * Delete piece from GED
       * @param {*} documentPiece The document to remove
       * @param {*} defaultUrlDocuments
       */
      deleteDocumentOnGED: function(documentPiece, defaultUrlDocuments) {
        var query = {};
        switch (documentPiece.rel) {
          case 'cmis':
            query = {
              method: 'POST',
              url: documentPiece.id,
              params: {
                cmisaction: 'delete',
              },
            };
            break;
          default:
            query = {
              method: 'POST',
              url: defaultUrlDocuments,
              params: {
                cmisaction: 'delete',
                objectId: _.get(documentPiece, "expand.properties['cmis:objectId'].value", ''),
              },
            };
            break;
        }
        return $http(query);
      },
    };
  });
