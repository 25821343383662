/**
 * Directive that triggers the bootstrap typeahead on arrow down pressed
 * It sets a ngmodel values to trigger the typeahead
 */
angular.module('common.directives').directive('typeaheadTriggerOnKeyUp', function() {
  return {
    require: ['ngModel'],
    link: function(scope, element, attr, ctrls) {
      element[0].onkeyup = function($event) {
        if ($event.keyCode === 40 && (!element[0].value || element[0].value === ' ')) {
          ctrls[0].$setViewValue(' ');
        }
      };
    },
  };
});
