angular.module('aides.services').factory('recapitulatifService', function($q, $http, aidesService) {
  'use strict';

  return {
    /**
     * Méthode de récupération de l'aide
     * @param aide
     * @param mdm
     * @param expands
     * @returns {*}
     */
    getAide: function(aide, mdm, expands) {
      expands =
        expands ||
        [
          'demandeur.famille',
          'demandeur.formeJuridique',
          'beneficiaires.famille',
          'pieces.documents',
          'domiciliationBancaire.pieces.documents',
          'teleservice',
          'specifiques.value',
          'planFinancement.depense.postes.lignes.pieces.documents',
        ].join(',');

      return aidesService.get(aide, mdm, expands);
    },

    /**
     * Get aide for recapitlatif merged with contribution
     * @param aide
     * @param mdm
     * @param expands
     * @returns {*}
     */
    getAideWithContribution: function(aide, referenceContribution, mdm, expands) {
      expands =
        expands ||
        [
          'demandeur.famille',
          'demandeur.formeJuridique',
          'beneficiaires.famille',
          'pieces.documents',
          'domiciliationBancaire.pieces.documents',
          'teleservice',
          'specifiques.value',
        ].join(',');

      return aidesService.getAideWithContribution(aide, referenceContribution, mdm, expands);
    },

    /**
     * Méthode de récupération du récapitulatif de l'aide
     * @param aide
     * @param mdm
     * @param expands
     * @returns {*}
     */
    getRecap: function(aide, mdm, params) {
      params = params || {};
      params.expand =
        params.expand ||
        [
          'demandeur.famille',
          'demandeur.formeJuridique',
          'beneficiaires.famille',
          'pieces.documents',
          'domiciliationBancaire.pieces.documents',
          'specifiques.value',
          'planFinancement.depense.postes.lignes.pieces.documents',
        ].join(',');

      return aidesService.getRecap(aide, mdm, params);
    },

    /**
     * Renvoie un document de reference 'recapitulatif' en le cherchant
     *  - soit dans les pièces de la demande
     *  - soit dans les links
     * @returns Promise
     */
    getRecapitulatifDemandePaiement: function(demande, pieces) {
      //Recherche d'une pièce sur la demande
      var pieceRecapitulatif = _.find(pieces, { reference: 'recapitulatif' });
      var documentRecapitulatif = _.get(pieceRecapitulatif, 'documents.0');

      if (documentRecapitulatif) {
        return $q.when(documentRecapitulatif);
      }
      //retrocompatibilité
      //Recherche d'un document dans document-collect, url dans les _links de la demande
      var urlDocuments = _.get(demande, '_links.mgs:documents.href');
      return $http
        .get(urlDocuments)
        .then(function(response) {
          var data = _.find(response.data.objects, ['object.properties.entity:category.value', 'recapitulatif']);
          if (data) {
            data.config = response.config;
            return data;
          }
        })
        .catch(function(error) {
          throw new Error('Unable to get CMIS Documents Informations : ', error);
        });
    },

    /**
     * indique si le recapitulatif PDF est prêt (a été généré par ref-financement)
     * @param {string} idAide id de l'aide
     * @param {string} hrefContribution href de la contribution
     */
    isReady: function(idAide, hrefContribution) {
      var url = idAide + '/recapitulatif/is-ready';
      if (hrefContribution) {
        url += '?idContribution=' + hrefContribution.split('/').pop();
      }

      return $http.post(url).then(function(response) {
        return response.data;
      });
    },
  };
});
