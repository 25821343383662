angular.module('tiers').directive('fileReader', ['$q', '$log', ReadFileDirective]);

function ReadFileDirective($q, $log) {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: (scope, element, attributes, ngModel) => {
      if (!ngModel) {
        return;
      }

      element.on('change', () => {
        const input = element && element[0];

        // do nothing if the input value is null
        // (IE11 triggers this event again when we set input.value to null below)
        if (!input || !input.value) {
          return;
        }

        const file = _.get(input, 'files[0]');
        if (file) {
          readFileContent(file)
            .then((content) => {
              // set ngModel value
              ngModel.$setViewValue({ file, content });
              // remove the file from the input (so we can upload the same image again if we delete it)
              input.value = null;
            })
            .catch(() => {
              $log.error(`Error while reading file ${file.name}`);
            });
        } else {
          ngModel.$setViewValue(null);
        }
      });

      /**
       * Read the content of a file (as data url / base64)
       * @param {*} file
       */
      const readFileContent = (file) => {
        const reader = new FileReader();
        const deferred = $q.defer();

        reader.onload = (e) => deferred.resolve(e.target.result);
        reader.onerror = () => deferred.reject();
        reader.readAsDataURL(file);

        return deferred.promise;
      };
    },
  };
}
