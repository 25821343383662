angular.module('jwt.session', ['jwt.session.service', 'jwt.session.interceptor']);

// Storage is separated from the service. Because it has to be called from an interceptor which can not inject a module that depends on $http
// Long story short: separate concerns to solve injector mess.

angular.module('jwt.session.storage', ['LocalStorageModule'])
  .provider('jwtSessionStorage', [function () {
    'use strict';

    // The service supports storing sessions for multiple tenant.
    // But there is only one active tenant at any point in time
    var _tenantId;

    // Name of the key used for stored the jwt in localStorage
    var _jwtKey;
    this.jwtKey = function(jwtKey) {
      _jwtKey = jwtKey;
    };

    this.$get = ['localStorageService', '$window', function (localStorageService, $window) {

      // Decode base64 from JWT with occasional encoded UTF-8 characters
      // cf https://github.com/auth0/angular-jwt/blob/master/src/angularJwt/services/jwt.js
      function urlBase64Decode(str) {
        var output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
          case 0: { break; }
          case 2: { output += '=='; break; }
          case 3: { output += '='; break; }
          default: {
            throw 'Illegal base64url string!';
          }
        }
        return $window.decodeURIComponent(escape($window.atob(output)));
      }

      return {

        tenantId: function (tenantId) {
          _tenantId = tenantId;
        },

        /**
         * Function for define the name of the jwt key
         * @param {string} jwtKey Jwt's key
         */
        setJwtKey: function (jwtKey) {
          _jwtKey = jwtKey;
        },

        getJwtKey: function (noTenant) {
          if (noTenant) {
            return _jwtKey || 'jwt-session';
          } else {
            return _jwtKey || (_tenantId ? (_tenantId + '.jwt-session') : 'jwt-session');
          }
        },

        storeJWTInfo: function (jwt) {
          var payload = JSON.parse(urlBase64Decode(jwt.split('.')[1]));

          // Store the time of creation of this JWT.
          // Do not trust the 'iat' and 'exp' field inside it, as the clocks are not necessarily synchronized.
          var localIAT = new Date().getTime() / 1000;
          var localEXP = payload.exp + (localIAT - payload.iat);

          localStorageService.set(this.getJwtKey(), {
            jwt: jwt,
            localIAT: localIAT,
            localEXP: localEXP,
            payload: payload
          });
        },

        removeJWTInfo: function () {
          localStorageService.remove(this.getJwtKey());
        },

        getJWTInfo: function () {
          return localStorageService.get(this.getJwtKey()) || localStorageService.get(this.getJwtKey(true));
        }
      };
    }];

  }]);

// This service interacts with a session-management service configured to
// serve JWT authentication

angular.module('jwt.session.service', ['jwt.session.storage'])
  .provider('jwtSessionService', ['$httpProvider', function ($httpProvider) {
    'use strict';
    var _this = this;

    // Configuration of the service
    var _authenticationServiceUrl = '/session-management';
    this.authenticationServiceUrl = function (authenticationServiceUrl) {
      _authenticationServiceUrl = authenticationServiceUrl;
    };

    // For compatibility
    this.sessionManagementUrl = function (sessionManagementUrl) {
      _authenticationServiceUrl = sessionManagementUrl;
    };

    // interval default is 30 minutes
    var _interval = 1800;
    this.interval = function (interval) {
      _interval = interval;
    };

    // Any user action will potentially prolongate his session
    var active = true;

    $(document).keypress(function () {
      active = true;
    });

    $(document).mousemove(function () {
      active = true;
    });

    // Also long running tasks that output HTTP requests should prolongate the session
    $httpProvider.interceptors.push(function () {
      return {
        request: function (config) {
          active = true;
          return config;
        }
      };
    });

    this.$get = ['$http', '$timeout', '$log', '$rootScope', 'jwtSessionStorage',
      function ($http, $timeout, $log, $rootScope, jwtSessionStorage) {

        // The service supports storing sessions for multiple tenant.
        // But there is only one active tenant at any point in time
        var _tenantId;

        function _getUrl() {
          return _tenantId ? _authenticationServiceUrl + '/' + _tenantId + '/tokens' : _authenticationServiceUrl + '/tokens';
        }

        // This function is called to maintain a session if the user made a recent request.
        // If the session is active it will call itself recursively to keep checking.
        function _refreshFunction() {
          var jwtInfo = jwtSessionStorage.getJWTInfo();

          if (!jwtInfo) {
            $log.debug('jwt-session - No session to maintain');
            return;
          }

          // If the token is expired, remove all persistence
          if (jwtInfo.localEXP - new Date().getTime() / 1000 <= 0) {
            $rootScope.$broadcast('jwt-session-expires');
            $log.debug('jwt-session - Session is expired, remove all stored information and do not keep iterating');
            return jwtSessionStorage.removeJWTInfo();
          }

          if (active) {
            active = false;
            $log.debug('Activity detected from user since last token update, ask for another token');

            $http({
              method: 'POST',
              url: _getUrl(),
              headers: {
                'Content-Type': 'application/jwt'
              },
              data: jwtInfo.jwt
            }).then(function (response) {
              $log.debug('jwt-session - A new replacement token was received');
              jwtSessionStorage.storeJWTInfo(response.data);
            });
          }

          // check again in the requested interval
          $timeout(_refreshFunction, _interval * 1000);
        }

        return {

          /**
           * Property who define the url of authentication service.
           * Deprecated because the name of property refers to a service's name specific and not maintained.
           * Use instead the generic property 'authenticationServiceUrl'.
           * @param sessionManagementUrl
           * @deprecated since version 0.5
           */
          sessionManagementUrl: function (sessionManagementUrl) {
            _this.authenticationServiceUrl(sessionManagementUrl);
            _refreshFunction();
          },

          /**
           * Generic property for define the url of authentication service
           * @param authenticationServiceUrl
           */
          authenticationServiceUrl: function (authenticationServiceUrl) {
            _this.authenticationServiceUrl(authenticationServiceUrl);
            _refreshFunction();
          },

          interval: _this.interval,

          getJwtKey: function () {
            return jwtSessionStorage.getJwtKey();
          },

          tenantId: function (tenantId) {
            if (_tenantId !== tenantId) {
              _tenantId = tenantId;
              jwtSessionStorage.tenantId(tenantId);
              _refreshFunction();
            }
          },

          login: function (username, password) {
            // request a JWT to session management by sending username and password
            return $http({
              method: 'POST',
              url: _getUrl(),
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              transformRequest: function (obj) {
                // small trick to send data using the x-www-form-urlencoded mime-type that is not
                // very well supported by angular.$http
                var str = [];
                for (var p in obj) {
                  str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
                }

                return str.join('&');
              },

              data: {
                username: username,
                password: password
              }
            }).then(function (response) {
              $log.debug('jwt-session - Login successful, store information in local storage');
              jwtSessionStorage.storeJWTInfo(response.data);
              $timeout(_refreshFunction, _interval * 1000);
              return response;
            });
          },

          logout: function () {
            jwtSessionStorage.removeJWTInfo();
          },

          user: function () {
            return (jwtSessionStorage.getJWTInfo() || {}).payload;
          },

          jwt: function () {
            return (jwtSessionStorage.getJWTInfo() || {}).jwt;
          },

          info: function () {
            return jwtSessionStorage.getJWTInfo();
          }
        };
      }

    ];
  }]);

angular.module('jwt.session.interceptor', ['jwt.session.storage']).factory('jwtAuthInterceptor', ['$log', '$location', 'jwtSessionStorage', 'configuration',
function ($log, $location, jwtSessionStorage, configuration) {
    'use strict';
    return {
      request: function (config) {

        // If the ux's url contains a parameter named jwtKey, we assign this property in jwtSessionStorage.
        // Used when we want use a jwt with specific key
        var parameters = $location.search();
        if (parameters.jwtKey) {
          jwtSessionStorage.setJwtKey(parameters.jwtKey);
        }

        config.headers = config.headers || {};

        var jwt = (jwtSessionStorage.getJWTInfo() || {}).jwt;

        // Filter some outside services
        var excluded = _.find(_.get(configuration, 'user.JWTHeaderExclude', []), function(excludedUrl) {
          return new RegExp('^(https?://)?' + excludedUrl).test(config.url);
        })

        // If a JWT is stored locally, send it in all outgoing requests
        if (jwt && !config.headers.Authorization && !excluded) {
          config.headers.Authorization = 'Bearer ' + jwt;
        }

        function uuidv4() {
          /**! http://stackoverflow.com/a/2117523/377392 */
          var fmt = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
          return fmt.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
          });
        };

        // If incoming headers does not have X-Request-ID
        // then add it
        if (!config.headers['X-Request-ID'] && !excluded) {
          config.headers['X-Request-ID'] = uuidv4();
        }

        return config;
      }
    };

  }]);
