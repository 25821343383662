angular.module('common.components').component('stepWizardDepot', {
  templateUrl: 'common/common-components/steps-wizard-depot/steps-wizard-depot.html',
  controller: stepWizardDepotController,
  bindings: {
    steps: '=',
    labelsPrefix: '=',
    active: '=',
    stepLabel: '@',
    activeStepLabel: '@',
  },
});

/* @ngInject */
function stepWizardDepotController($scope, $translate) {
  let ctrl = this;

  const watch = $scope.$watch(
    'steps',
    function() {
      if ($scope.steps) {
        $scope.width = 100 / $scope.steps.name.length + '%';
      }
    },
    true
  );

  $scope.$on('$destroy', function() {
    watch();
  });

  /**
   * Get the translation for an active step
   * @return string
   */
  ctrl.stepInProgressLabel = () => {
    return $translate.instant('teleservice.steps.stepInProgress');
  };
}
