/* global _ */

/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name validation-field
 * @scope
 * @transclude
 * @restrict EA
 * @description
 *
 *   Add standard patterns on a form field.
 *
 *   Standard layout of label, help message, etc. Automatic labels management, automatic common field configuration management.
 *
 *   This directive will expect to find in its children elements a form field (input, textarea, select) with a name and a ngModel.
 *   This name will be used to fetch the labels using angular.translate with the given namespace.
 *   It will also fetch the field configuration in the viewConfiguration.fields object (required, pattern, maxlength, etc.).
 *
 *
 *
 * @param {object} viewConfiguration - The configuration of the current page or section of page. As returned by the resource-manager service. If not given it will be fetched from the parent scope.
 * @param {object} configuration - The configuration of the field. If not given, validartion-field will look into viewConfiguration.fields[name]
 * @param {string} namespace - Prefix use to load labels with $translate
 *
 * All parameters below can also be written in the field's configuration
 *
 * @param {boolean} displayIcons - Set to true to display font-awesome icons along to the messages surrounding the field (validity, required, etc).
 * @param {boolean} displayWarning - Set to true to display a warning when the field is invalid even if the form was not attempted.
 * @param {string} labelClass - Add a css class to the label message.
 * @param {string} fieldClass - Add a css class to the field message.
 * @param {number} labelWidth - Set a class col-md-'labelWidth' to the label message. Defaults to 4.
 * @param {number} fieldWidth - Set a class col-md-'fieldWidth' to the field. Defaults to 4.
 * @param {number} helpWidth - Set a class col-md-'helpWidth' to the help message. Defaults to 4.
 * @param {string} colType - Indicates if previous variables are to be set to a class col-md- or col-sm.
 * @param {boolean} noExtendField - Specify that the field should take the room of the help message even if it is empty. By default it will.
 * @param {boolean} bare - Specify that the field should be left alone without label and help messages.
 * @param {boolean} errorsInline - Specify that errors should be displayed next to the field, instead of below.
 * @param {boolean} helpTooltip - Specify that the help message should be displayed using a helpTooltip
 *
 * @example
 *
 *   ```
 *   <validation-field configuration="configuration" namespace="viewConfiguration.ns">
 *     <input name="name" ng-model="user.name" placeholder="{{viewConfiguration.ns + '.' + name + '.placeholder' | translate}}" ng-required="configuration.required">
 *   </validation-field>
 *   ```
 *
 */

angular.module('form.directives').directive('validationField', ['$log', '$translate', '$timeout', '$rootScope', 'formPreferences', '$sce',
  function($log, $translate, $timeout, $rootScope, formPreferences, $sce) {
    'use strict';

    /**
     * Find the form element of transclude content
     * @param  {HtmlElement} element [description]
     * @return {HtmlElement}         Reference of the form element of transclude content
     */
    var getFormElement = function(element) {
      var formElement = element.find('input');
      formElement = formElement.length === 0 ? element.find('select') : formElement;
      formElement = formElement.length === 0 ? element.find('textarea') : formElement;
      // Some kendo directives have more than one element
      if (formElement.length > 1) {
        formElement = element.find('input[name]');
      }
      return formElement;
    };

    return {
      restrict: 'EA',
      replace: false,
      transclude: true,
      templateUrl: 'form/form-directives/validation-field/validation-field.html',
      require: ['^form'],
      scope: {
        viewConfiguration: '=',
        configuration: '=',
        namespace: '=',
        displayIcons: '=',
        displayWarning: '=',
        labelClass: '=?',
        fieldClass: '=',
        labelWidth: '=', // will be added to a col-md- class
        fieldWidth: '=',
        helpWidth: '=',
        colType: '=', // Can choose between different col style (col-md or col-sm for example)
        noExtendField: '=',
        bare: '=',
        errorsInline: '=',
        helpTooltip: '='
      },
      link: function(scope, element, attrs, ctrls) {

        var formController = ctrls[0];
        var watches = [];
        // Form instance
        scope.form = formController;

        // Field
        var formElement = getFormElement(element);
        var fieldName = formElement.attr('name') || scope.configuration.reference || scope.$parent.name;

        // Retrieve field status in form data
        scope.field = scope.form[fieldName];
        if (!scope.field) {
          $log.error('validationField : A field with name and ng-model attributes must be defined.');
        }

        /**
        * Process field's configuration
        * Read field properties (required, hidden) and initialize labels
        */
        var processConfiguration = function() {
          var fieldConfiguration = {};
          // Search viewConfiguration in parent if not given
          var viewConfiguration = scope.viewConfiguration || scope.$parent.viewConfiguration || {};

          // Copy scope configuration
          if (scope.configuration) {
            fieldConfiguration = angular.copy(scope.configuration);
          // Or find it in viewConfiguration
          } else if (_.get(viewConfiguration, 'fields.' + fieldName)) {
            fieldConfiguration = angular.copy(viewConfiguration.fields[fieldName]);
          } else {
            $log.warn('No configuration found for field ' + fieldName + '. Did you forget to pass a configuration or viewConfiguration to validation-field?');
          }
          // the namespace is used to find translated labels for this field.
          // it can be configured, or written in viewConfiguration
          fieldConfiguration.ns = scope.namespace || fieldConfiguration.ns || viewConfiguration.ns;

          // duplicate what check-validation-field does (set validity on specific rules), but descending from a configuration object.
          if (fieldConfiguration.validation) {
            watches.push(scope.$parent.$watch(fieldConfiguration.validation, function(isValid) {
              scope.field.$setValidity('checkValidity', isValid);
            }));
          }

          // Rendering parameters can be given as scope paremeters, view configuration, or simply as keys to the configuration
          var formPreferencesConfig = formPreferences.config();
          _.each(['displayIcons',
            'displayWarning',
            'labelClass',
            'fieldClass',
            'labelWidth',
            'fieldWidth',
            'helpWidth',
            'colType',
            'noExtendField',
            'bare',
            'errorsInline',
            'helpTooltip',
            'loading',
            'tooltipPlacement'
          ], function(param) {
            fieldConfiguration[param] = fieldConfiguration[param] === undefined ? scope[param] : fieldConfiguration[param];
            fieldConfiguration[param] = fieldConfiguration[param] === undefined ? viewConfiguration[param] : fieldConfiguration[param];
            fieldConfiguration[param] = fieldConfiguration[param] === undefined ? formPreferencesConfig[param] : fieldConfiguration[param];
          });

          // Evaluation of required attribute (in configuration or on the element)
          scope.required = fieldConfiguration.required || formElement.attr('required');
          // Dynamically watch the changes on eventual ng-required
          if (formElement.attr('ng-required') && formElement.attr('ng-required').length > 0) {
            watches.push(scope.$parent.$watch(formElement.attr('ng-required'), function(required) {
              scope.required = fieldConfiguration.required || required;
            }));
          }

          // Status management
          scope.status = null;
          // Detect a required error without using DOM
          scope.hasRequiredError = function() {
            var viewValue = _.get(scope, 'field.$viewValue');
            return fieldConfiguration.required && ([undefined, null, ''].indexOf(viewValue) > -1);
          };
          // Indicates if errors can be shown
          scope.displayErrors = function() {
            return scope.status==='error' && scope.field.$dirty || scope.form.$submitted;
          };
          // Changes status on blur
          scope.blur = function() {
            if (scope.field.$invalid || scope.hasRequiredError()) {
              scope.status = 'error';
            } else {
              scope.status = 'success';
            }
            scope.$digest();
          };
          formElement.bind('blur', scope.blur);

          // Show errors on any circumstance if global option is set
          scope.showErrors = viewConfiguration.showErrors;

          /**
           * Initializes labels and the field's display
           */
          var writeField = function() {
            // Complete field configuration with standard labels
            _.each(['label',
              'unit',
              'help',
              'placeholder',
              'details',
              'error.required',
              'error.pattern',
              'error.min',
              'error.max',
              'error.minlength',
              'error.maxlength',
              'error.confirmationField',
              'error.checkValidity'
            ], function(key) {
              var lbKey = fieldConfiguration.ns + '.' + fieldName + '.' + key;

              // Initialize the value with the configuraiton field;
              var lb = _.get(fieldConfiguration, key, null);
              if ($translate.instant(lbKey) !== lbKey) {
                lb = $translate.instant(lbKey);
              }

              if (lb === lbKey || lb === null || lb === undefined) {
                var keyDefault = 'form.field.' + key;
                var lbDefault = $translate.instant(keyDefault);
                if (lbDefault !== keyDefault) {
                  lb = lbDefault;
                }
              }

              _.set(fieldConfiguration, key, lb);
            });

            // Set details as trusted Html
            fieldConfiguration.details = $sce.trustAsHtml(fieldConfiguration.details);

            // Set width attributes
            scope.widths = {
              label: fieldConfiguration.labelWidth || 4,
              field: fieldConfiguration.fieldWidth || 4,
              help: fieldConfiguration.helpWidth || 4,
              colType: fieldConfiguration.colType || 'md'
            };

            if (!(fieldConfiguration.help && !fieldConfiguration.helpTooltip) &&
              !fieldConfiguration.noExtendField && !fieldConfiguration.errorsInline) {
              scope.widths.field += scope.widths.help;
            }

            if (fieldConfiguration.bare) {
              scope.widths.field = fieldConfiguration.fieldWidth || 12;
            }
          };

          // Has to be called whenever translations are loaded
          watches.push($rootScope.$on('$translateChangeSuccess', writeField));
          writeField();

          // Finally write configuration into scope
          scope.fieldConfiguration = fieldConfiguration;
        };

        // Process configuration on every changes
        watches.push(scope.$watch('[namespace, configuration, viewConfiguration]', function() {
          processConfiguration();
        }, true));

        // Remove watches and bound functions
        scope.$on('$destroy', function() {
          formElement.unbind('blur', scope.blur);
          _.each(watches, function (watch) {
            watch();
          });
        });
      }
    };
  }

]);
