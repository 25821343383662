angular.module('comitesRecevabilite.services').factory('comitesRecevabiliteService', function($http) {
  'use strict';

  return {
    /**
     * Get a comiteRecevabilite
     * @param href
     * @returns {*}
     */
    getByHref: function(href) {
      return $http.get(href);
    },
  };
});
