class RecapitulatifDecisionsCardController {}

RecapitulatifDecisionsCardController.$inject = [];

angular.module('aides.recapitulatif').component('recapitulatifDecisionsCard', {
  templateUrl: 'aides/aides-components/recapitulatif/decisions/recapitulatif-decisions-card.html',
  controller: RecapitulatifDecisionsCardController,
  bindings: {
    namespace: '<',
    decisions: '<',
  },
});
