/**
 * @ngdoc directive
 * @area api
 * @module form.directives
 * @name dynamic-form-groups
 * @scope
 * @restrict EA
 * @description
 *
 *   A dynamic form whose presentation will describe a simple list of groups of fields.
 *
 * @param {object} data - The object that will be completed by this dynamic form.
 * @param {object} presentation - The presentation used to render the form. TODO detailed description of format when matured.
 * @param {object} viewConfiguration - A view configuration as returned by the resource-manager service for example
 *
 * @example
 *
 *   `<dynamic-form-groups data="demandeur.specificData" viewConfiguration="teleserviceConfiguration" presentation="formGroupPresentation"></dynamic-form-groups>`
 *
 */

angular.module('form.directives').directive('dynamicFormGroups', ['$translate', 'formUtils',
  function($translate, formUtils) {
    'use strict';

    /**
     * Define a new scope for fields for analyse angular expression use by some properties
     * @param  {object} scope  Scope of the directive
     * @param  {object} fields List of fields use by this dynamic form
     * @return {object}        Specific scope for analyse expression
     */
    var getScopeFields = function(scope) {
      var scopeFields = scope.$new();
      _.each(scope.fields, function(field, key) {
        scopeFields[key] = field;
      });

      return scopeFields;
    };

    /**
     * Define translations for some properties
     * @param  {array} properties Properties
     * @param  {string} namespace  Namespace
     * @param  {object} group      Group's presentation
     */
    var defineTranslations = function(properties, namespace, group) {
      _.each(properties, function(element) {

        // Key for the translation
        var key = namespace + '.group.' + group.id + '.' + element;
        group[element] = $translate.instant(key);

        if (group[element] === key) {
          group[element] = null;
        }
      });
    };

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'form/form-directives/dynamic-form-groups/dynamic-form-groups.html',
      require: '^form',
      scope: {
        data: '=',
        presentation: '=',
        namespace: '=',
        fields: '=',
        mdm: '=',
        readOnly: '=',
        authUser: '=',
        authPass: '=',
        viewConfiguration: '='
      },
      controller: ['$scope', function($scope) {

        var that = this;

        // Creation of a new scope for analyse angular expressions for properties
        // Given access to this property of scope to another directives, like 'dynamic-field'
        that.scopeFields = getScopeFields($scope);

        // Watch entire data object who contains value of each field.
        // We update the property 'scopeFields' use by differents watcher
        $scope.$watch('data', function(newValue) {
          if (newValue) {
            _.each($scope.data, function(element, key) {
              _.set(that, 'scopeFields.' + key + '.value', $scope.data[key]);
            });
          }
        }, true);

        // Presentation
        if ($scope.presentation && $scope.presentation.groups) {

          // Iteration on each group of the presentation
          _.each($scope.presentation.groups, function(group) {

            // Manage translations for this properties
            var properties = ['legend', 'header', 'footer'];
            defineTranslations(properties, $scope.namespace, group);

            // Evaluation of angular expression on group's value property 'visible'
            // We must clone the object for don't loose angular expression define on the property.
            var groupConfiguration = angular.copy(group);
            group.configuration = groupConfiguration;

            if (formUtils.checkIsAngularExpression(group.hidden)) {
              that.scopeFields.$watch(groupConfiguration.hidden, function(newValue) {
                if (newValue !== undefined) {
                  groupConfiguration.hidden = newValue;
                  if (groupConfiguration.hidden) {
                    _.each(group.fields, function(field) {
                      if ($scope.data[field]) {
                        $scope.data[field] = undefined;
                      }
                    });
                  }
                }
              });
            } else {
              groupConfiguration.hidden = false;
            }
          });
        }
      }]
    };
  }
]);
