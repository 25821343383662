'use strict';
angular.module('user.directives').directive('accountUpdateEmail', [
  '$rootScope',
  'configuration',
  '$location',
  'accountManagementService',
  'alertsService',
  'validationService',
  'userSessionService',
  'USER_EVENTS',

  function (
    $rootScope,
    configuration,
    $location,
    accountManagementService,
    alertsService,
    validationService,
    userSessionService,
    USER_EVENTS
  ) {
    return {
      restrict: 'EA',
      replace: true,
      transclude: true,
      templateUrl: 'user/user-directives/account-update-email/account-update-email.html',
      scope: {
        viewConfiguration: '=',
        mdm: '=',
        user: '=?',
      },
      link: function (scope) {
        scope.validationService = validationService;

        // Initialize the updateEmail data structure
        scope.updateEmail = {
          email: null,
          confirmEmail: null,
          oldEmail: angular.copy(scope.user.emails[0] || userSessionService.getUser().emails[0]),
          password: null,
        };

        if (userSessionService.getUser()) {
          scope.userId = userSessionService.getUser().id;
        }

        /**
         * Modify email of a user
         * @param  {String} userId The user id
         * @param  {String} email New email address
         * @param  {String} password User's password
         * @return {Promise} publicSettings
         */
        scope.modifyEmail = function (userId, email, password) {
          //Get the public settings to know the emailUpdate value and actionTokenTTL delay to send validation email
          return accountManagementService
            .getPublicSettings()
            .then(function (response) {
              var publicSettings = response.data;
              if (publicSettings.emailUpdate === 'emailConfirm') {
                var linkMail = $location
                  .absUrl()
                  .replace($location.path(), configuration.user.accountEmailModificationConfirmationPath);
                accountManagementService
                  .modifyEmail(userId, email, password, linkMail)
                  .then(function success() {
                    var delay = {};
                    var defaultTokenTime = 43200; //12 hours (in seconds)

                    //We parse the value into a moment() object, to easy convert
                    var intervalObject =
                      publicSettings && publicSettings.actionTokenTTL
                        ? moment.duration(publicSettings.actionTokenTTL, 'seconds')
                        : moment.duration(defaultTokenTime, 'seconds');

                    //Create the display object
                    delay.seconds = intervalObject.asSeconds();
                    delay.hours = intervalObject.asHours();
                    delay.days = intervalObject.asDays();
                    delay.human = intervalObject.humanize();

                    scope.$broadcast(
                      'alerts',
                      alertsService.getAlertSuccess('user.account-update-email.success-email-confirm', {
                        email: email,
                        delay: delay,
                      }),
                      'notification-update-email'
                    );
                  })
                  .catch(function (error) {
                    displayError(error);
                  });
              } else {
                // We assume configuration.user.emailUpdate is set to 'instant' because it's not 'emailConfirm'
                accountManagementService
                  .modifyEmail(userId, email, password)
                  .then(function success() {
                    scope.$broadcast(
                      'alerts',
                      alertsService.getAlertSuccess('user.account-update-email.success'),
                      'notification-update-email'
                    );
                    scope.updateUser(userId);
                    scope.updateEmail = {
                      oldEmail: angular.copy(email),
                    };
                    scope.updateEmailForm.$setPristine();
                  })
                  .catch(function (error) {
                    displayError(error);
                  });
              }
            })
            .catch(function (error) {
              displayError(error);
            });
        };

        /**
         * Function notificating errors
         * @param {Object} error
         */
        function displayError(error) {
          var messageKey = 'user.account-update-email.failure';
          if (error.status === 401) {
            messageKey = 'user.account-update-email.failure-password';
          }
          if (error.status === 409 && error.data && error.data.code === 3) {
            messageKey = 'user.account-update-email.failure-franceconnect';
          }
          scope.$broadcast('alerts', alertsService.getAlertError(messageKey), 'notification-update-email');
        }

        /**
         * Update a user, with the values which are in the current session
         * @param  userId   The user id which has to be updated
         */
        scope.updateUser = function (userId) {
          accountManagementService
            .getAccount(userId, scope.mdm)
            .then(function (user) {
              scope.user = user;
              $rootScope.$broadcast(USER_EVENTS.userUpdated);
            })
            .catch(function (error) {
              displayError(error);
            });
        };

        scope.isFranceConnect = function () {
          return scope.user.franceConnect;
        };
      },
    };
  },
]);
