// Called on every HTTP requests to display loading icon and react on errors
angular.module('portailDepotDemandeAide').factory('portalInterceptor', [
  '$q',
  '$rootScope',
  'AUTHENTICATION_EVENTS',
  'configuration',
  'StoreService',
  function($q, $rootScope, AUTHENTICATION_EVENTS, configuration, StoreService) {
    'use strict';
    var count = 0;

    return {
      request: function(config) {
        count++;
        $rootScope.loading = count > 0;

        // Filter some outside services
        var excluded = _.find(_.get(configuration, 'user.JWTHeaderExclude', []), function(excludedUrl) {
          return new RegExp('^(https?://)?' + excludedUrl).test(config.url);
        });

        if (!excluded) {
          _.set(config, 'headers.mg-authentication', true);
          // get our eventual current selected tiers reference
          const currentTiersRef = StoreService.currentTiersRef.get();
          if (currentTiersRef) {
            _.set(config, 'headers.X-Current-Tiers', currentTiersRef);
          }
        }

        // Send and get tiers 3.0 (on '/referentiel-tiers/')
        if (new RegExp('^' + configuration.tiers.url).test(config.url)) {
          _.set(config, 'headers.Content-Type', 'application/vnd.mgdis.tiers-3.19.0+json');
          _.set(config, 'headers.Accept', 'application/vnd.mgdis.tiers-3.19.0+json');
        }

        // Fix for IE, get request for demandes-financement where cached
        var forceNoCache = new RegExp('/demandes-financement').test(config.url);

        if (forceNoCache) {
          _.set(config, 'headers.Cache-Control', 'no-cache');
          _.set(config, 'headers.Pragma', 'no-cache');
        }

        return config || $q.when(config);
      },
      requestError: function(rejection) {
        count--;
        $rootScope.loading = count > 0;
        $rootScope.$broadcast('$httpError', rejection);
        return $q.reject(rejection);
      },
      response: function(response) {
        count--;
        $rootScope.loading = count > 0;
        return response || $q.when(response);
      },
      responseError: function(rejection) {
        count--;
        $rootScope.loading = count > 0;

        // No error message
        if (_.get(rejection, 'config.headers.X-No-Interceptor')) {
          return $q.reject(rejection);
        }

        // Deconnection when the user session expires
        if (rejection.status === 401 && !_.startsWith(rejection.config.url, configuration.user.accountManagement)) {
          $rootScope.$broadcast(AUTHENTICATION_EVENTS.notAuthorized);
        }

        if (
          rejection.status === 403 &&
          _.get(rejection, 'data.error.code') === 403.1 &&
          _.get(rejection, 'data.error.maxPersistenceNumber') &&
          _.endsWith(rejection.config.url, '/duplicate')
        ) {
          return $q.reject(rejection);
        }

        if (rejection.config.noLogOn404 && rejection.status === 404) {
          return $q.reject(rejection);
        }

        $rootScope.$broadcast('$httpError', rejection);

        return $q.reject(rejection);
      },
    };
  },
]);
