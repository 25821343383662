angular.module('portailDepotDemandeAide.dashboard').controller('dashboardMesOffresController', [
  '$scope',
  'offres',
  '$sce',
  '$translate',
  '$modal',
  'offresStageService',
  'jsonpatch',
  'OffreStage',
  'mdm',
  function($scope, offres, $sce, $translate, $modal, offresStageService, jsonpatch, OffreStage, mdm) {
    const ctrl = this;

    const itemsPerPageConstant = 20;

    ctrl.$onInit = $onInit;
    $scope.checkIfBeingModified = checkIfBeingModified;
    $scope.manageTooltip = manageTooltip;
    $scope.deleteOffre = deleteOffre;
    $scope.confirmDeleteOffre = confirmDeleteOffre;
    $scope.changePagination = changePagination;
    $scope.changePage = changePage;
    $scope.isImported = isImported;

    function $onInit() {
      $scope.helpHtmlContent = $sce.trustAsHtml($translate.instant('connected.dashboard.offresStage.mesOffres.help'));
      $scope.mesOffres = offres.data;
      $scope.pagination = {
        visible: true,
        totalCount: _.get(offres, 'total'),
        itemsPerPage: itemsPerPageConstant,
        skip: 0,
        top: itemsPerPageConstant,
        currentPage: 1,
      };
    }

    /**
     * Check if the given offre-stage is being modified
     * If it is in "REQUESTED" status and once was in "TRANSMITTED" status then it is being modified
     * If it is in "REQUESTED" status but never was in "TRANSMITTED" then it is being created.
     * Otherwise, keep its status.
     * @param {Object} offre The offre to get the transmission date from
     * @returns {boolean}
     */
    function checkIfBeingModified(offre) {
      const statut = _.get(offre, 'statut', '');
      if (statut !== 'REQUESTED') {
        return false;
      }

      const events = _.sortBy(_.get(offre, 'history.events', []), 'date').reverse();
      const eventStatut = _.find(events, (event) => {
        return (
          event.type === 'STATUS' &&
          _.get(event, 'metadata.from', '') === 'REQUESTED' &&
          _.get(event, 'metadata.to', '') === 'TRANSMITTED'
        );
      });
      return eventStatut;
    }
    /**
     * Manage offre-stage tooltip
     * @param {Object} offre
     * @param {string} action
     * @returns {string}
     */
    function manageTooltip(offre, action) {
      let tooltipMessageKey = '';
      if (action === 'access') {
        if ($scope.checkIfBeingModified(offre)) {
          tooltipMessageKey = 'connected.dashboard.offresStage.mesOffres.list.modify';
        } else {
          tooltipMessageKey = 'connected.dashboard.offresStage.mesOffres.list.saisie';
        }
      }
      if (action === 'delete') {
        if ($scope.checkIfBeingModified(offre)) {
          tooltipMessageKey = 'connected.dashboard.offresStage.mesOffres.list.deleteRevision';
        } else {
          tooltipMessageKey = 'connected.dashboard.offresStage.mesOffres.list.delete';
        }
      }
      if (action === 'pieces') {
        tooltipMessageKey = 'connected.dashboard.offresStage.mesOffres.list.pieces';
      }
      if (action === 'notify') {
        tooltipMessageKey = 'connected.dashboard.offresStage.mesOffres.list.notify';
      }
      if (action === 'edit') {
        tooltipMessageKey = 'connected.dashboard.offresStage.mesOffres.list.edit';
      }
      if (action === 'recap') {
        tooltipMessageKey = 'connected.dashboard.offresStage.mesOffres.list.recap';
      }
      return $translate.instant(tooltipMessageKey);
    }

    /**
     * Display delete modal
     * @param {Object} offre
     * @returns {Void}
     */
    function deleteOffre(offre) {
      const scopeModal = $scope.$new();
      scopeModal.offre = offre;

      $modal({
        scope: scopeModal,
        template: 'dashboard/offres-stage/modal/modal-delete-offre.html',
      });
    }

    /**
     * Delete offre-stage or rollback to its last "TRANSMITTED" version
     * @param {Object} modal Modal to hide after the offre has been deleted
     * @param {Object} offre Offre
     * @returns {Void}
     */
    function confirmDeleteOffre(modal, offre) {
      modal.$hide();

      const eventChangementStatut = $scope.checkIfBeingModified(offre);

      // If the offre-stage was being created, we delete it
      if (!eventChangementStatut) {
        offresStageService.remove(offre).then(() => {
          // Remove the offre-stage from the list
          $scope.mesOffres = _.filter($scope.mesOffres, function(mesOffres) {
            return offre.reference !== mesOffres.reference;
          });
        });
      }
      // When the offre-stage is being modified
      //  the deletion corresponds to the offre-stage at its last TRANSMITTED state
      else {
        offresStageService.get(offre.reference, null, eventChangementStatut.date).then(function(revision) {
          const offreInitiale = angular.copy(offre);
          const patches = jsonpatch.compare(new OffreStage(offreInitiale, mdm), revision);
          offresStageService.patch(offre.reference, patches).then(function(result) {
            const indexOffreModifiee = _.findIndex($scope.mesOffres, function(item) {
              return item.reference === offre.reference;
            });
            $scope.mesOffres[indexOffreModifiee] = result;
          });
        });
      }
    }

    /**
     * Update paginiation parameters
     * @param {number} skip
     * @param {number} top
     * @param {number} currentPage
     * @returns {Void}
     */
    function changePaginationComponentCfg(skip, top, currentPage) {
      $scope.pagination.top = top;
      $scope.pagination.skip = skip;
      $scope.pagination.currentPage = currentPage;
    }

    /**
     * Change pagination
     * @param {number} skip
     * @param {number} top
     * @param {number} currentPage
     * @returns {Void}
     */
    function changePagination(skip, top, currentPage) {
      changePaginationComponentCfg(skip, top, currentPage);
      changePage(skip, top);
    }
    /***
     * Change the current page and retrieve the related offre-stage
     * @param {number} skip
     * @param {number} top
     * @returns {Void}
     */
    function changePage(skip, top) {
      offresStageService.getOffresStage(skip, top).then(({ data }) => {
        $scope.mesOffres = data;
      });
    }
    /**
     * Check if the offre has been imported
     * Imported offres are initialized with TRANSMITTED status and doesn't have event about changing status to TRANSMITTED
     * @param {*} offre The offre to check
     * @returns {boolean}
     */
    function isImported(offre) {
      return (
        !offre.history ||
        !_.some(offre.history.events, (event) => {
          return event.type === 'STATUS' && _.get(event, 'metadata.to') === 'TRANSMITTED';
        })
      );
    }
  },
]);
