class DashboardController {
  constructor(
    $scope,
    $rootScope,
    $modal,
    $stateParams,
    $translate,
    userSessionService,
    alertsService,
    aidesService,
    avisPrealablesService,
    demandesAidesService,
    accountManagementService,
    configuration,
    USER_EVENTS,
    licenseService,
    justificationsService,
    documentsPubliesService,
    StoreService
  ) {
    this._scope = $scope;
    this._modal = $modal;
    this._licenseService = licenseService;
    this._avisPrealablesService = avisPrealablesService;
    this._alertsService = alertsService;
    this._translate = $translate;
    this._justificationsService = justificationsService;
    this._documentsPubliesService = documentsPubliesService;
    this.configuration = configuration;
    this.isUserSignataire = false;
    this.currentUser = userSessionService.getUser();

    if ($stateParams.unauthorized) {
      this.alerts = alertsService.getAlertError('connected.dashboard.aides.errors.incompatible-tiers');
    } else if ($stateParams.teleServiceNotFound) {
      this.alerts = alertsService.getAlertWarning('teleservice.errors.notFound');
    } else if ($stateParams.maximumPersistenceReach) {
      this.modalMaxNumberPersistenceReach();
    } else if ($stateParams.maximumOffresStageReached) {
      this.modalMaxNumberOffresPersistenceReached();
    } else if ($stateParams.teleserviceNotInvited) {
      this.alerts = alertsService.getAlertWarning(
        this._translate.instant('teleservice.errors.notInvited', {
          libelleTeleservice: $stateParams.teleserviceNotInvited,
        })
      );
    } else if (StoreService.dashboardAlert.get()) {
      this.alerts = StoreService.dashboardAlert.get();
      StoreService.dashboardAlert.clear();
    }

    // we get the redirectParams parameter and we parse it
    const redirectParams = this.getParam('redirectParams') || '{}';
    let parsed;
    try {
      parsed = JSON.parse(redirectParams);
    } catch (err) {
      parsed = {};
    }
    const token = $stateParams.token || parsed.token;
    const email = $stateParams.email || parsed.email;

    // Skip email modification confirmation process when login oidc provider
    // franceConnect Users have a providerId as well so we need to specifically allow them
    if (_.get(this, 'currentUser.franceConnect', false) || !_.get(this, 'currentUser.providerId', false)) {
      // The user clicked in the link which was in the email modification confirmation email
      if (token && email && _.get(this, 'currentUser.id')) {
        accountManagementService
          .validateEmail(userSessionService.getUser().id, email, token)
          .then(() => {
            this.currentUser.emails = [email];
            this.alerts = alertsService.getAlertSuccess(
              'user.account-email-modification-confirmation.validation-success'
            );
            $rootScope.$broadcast(USER_EVENTS.userUpdated);
          })
          .catch((error) => {
            if (error.status === 404) {
              this.alerts = alertsService.getAlertError(
                'user.account-email-modification-confirmation.errors.validation-failure-404'
              );
            } else {
              this.alerts = alertsService.getAlertError(
                'user.account-email-modification-confirmation.errors.validation-failure-other'
              );
            }
          });
      } else {
        accountManagementService.emailModificationPending(userSessionService.getUser().id).then((response) => {
          if (response.data.status === 'VALIDATION_IN_PROGRESS') {
            this.alerts = alertsService.getAlertWarning('user.account-update-email.email-validation-pending', {
              email: response.data.email,
            });
          }
        });
      }
    }
  }

  $onInit() {
    this.usersSignataires = _.each(_.get(this, 'tiers.linkedUsers'), (link) => {
      if (link.form === 'SIGNATAIRE' && link.href === _.get(this, 'currentUser.self')) {
        this.isUserSignataire = true;
      }
    });

    // Recovering of the unread documents
    this.unreadDocuments = 0;
    if (this.tiers) {
      this._documentsPubliesService
        .getStats()
        .then((data) => {
          this.unreadDocuments = data.unread;
          this.printingDocument = this._translate.instant('connected.dashboard.documentsReçus.nonLu');
          if (this.unreadDocuments > 1) {
            this.printingDocument = this._translate.instant('connected.dashboard.documentsReçus.nonLus');
          }
        })
        .catch(() => {
          this.alertePublication = this._alertsService.getAlertError('connected.dashboard.documentsReçus.erreur');
          return 0;
        });
    }

    if (this.offresStageActif && !this._licenseService.getPresenceLicence('depot-offres-stage')) {
      this.alerteLicenceOffreStage = this._alertsService.getAlertWarning('connected.dashboard.offresStage.licence');
    }

    this._justificationsService.hasTeleservices().then((hasTeleservices) => {
      if (this.tiers) {
        this.showJustifications = hasTeleservices;
      }

      if (hasTeleservices && !this._licenseService.getPresenceLicence('bilan-justification')) {
        this.alerteLicenceJustification = this._alertsService.getAlertWarning(
          'connected.dashboard.justifications.licence'
        );
      }
    });

    // Vérification si le tiers associé à l'utilisateur à déposer ou doit déposer des avis
    // sur des demandes de financement
    if (this.tiers) {
      this._avisPrealablesService.getDemandesFinancementWithJustAvisPrealablesForCount().then((aidesWithAvis) => {
        const aidesWithAvisADonner = _.filter(aidesWithAvis, (aide) => {
          const avisTiers = this._avisPrealablesService.getAvisPrealableTiers(aide.avisPrealables, this.tiers);
          return avisTiers && _.isEmpty(avisTiers.avis);
        });

        this.aidesWithAvisADonner = aidesWithAvisADonner.length;

        const aidesWithAvisRendu = _.filter(aidesWithAvis, (aide) => {
          const avisTiers = this._avisPrealablesService.getAvisPrealableTiers(aide.avisPrealables, this.tiers);
          return avisTiers && !_.isEmpty(avisTiers.avis);
        });

        this.aidesWithAvisRendu = aidesWithAvisRendu.length;
      });

      this._justificationsService.countDemandeJustifications(this.tiers).then((nbJustifications) => {
        this.nbJustifications = nbJustifications;
      });

      if (this.tiers.sansSIRET && this.tiers.sansSIRET.valeur) {
        this.noSiretAlert = this._alertsService.getAlertWarning(
          _.get(this.referentielTiersI18n, 'tiers.sansSiret.alert.usager')
        );
      }
    }
  }

  modalMaxNumberPersistenceReach() {
    const scopeModal = this._scope.$new();
    this._modal({
      scope: scopeModal,
      template: 'dashboard/aides/modal/modal-maxnumber-demandes.html',
    });
  }

  modalMaxNumberOffresPersistenceReached() {
    const scopeModal = this._scope.$new();
    this._modal({
      scope: scopeModal,
      template: 'dashboard/aides/modal/modal-maxnumber-offresstage.html',
    });
  }

  // Return a parameter value from the current URL
  // we use this method because we can't access to url parameters via $location or $stateParams
  getParam(sname) {
    let params = location.href.substr(location.href.indexOf('?') + 1);
    let sval = '';
    params = params.split('&');
    // split param and value into individual pieces
    for (let i = 0; i < params.length; i++) {
      const temp = params[i].split('=');
      if (temp[0] === sname) {
        sval = temp[1];
      }
    }
    return decodeURIComponent(sval);
  }
}

angular.module('portailDepotDemandeAide.dashboard').component('dashboard', {
  controller: DashboardController,
  templateUrl: 'dashboard/dashboard.component.html',
  bindings: {
    offresStageActif: '<',
    tiers: '<',
    referentielTiersI18n: '<',
    referent: '<',
  },
});

DashboardController.$inject = [
  '$scope',
  '$rootScope',
  '$modal',
  '$stateParams',
  '$translate',
  'userSessionService',
  'alertsService',
  'aidesService',
  'avisPrealablesService',
  'demandesAidesService',
  'accountManagementService',
  'configuration',
  'USER_EVENTS',
  'licenseService',
  'justificationsService',
  'documentsPubliesService',
  'StoreService',
];
