class DossierCardController {}

DossierCardController.$inject = [];

angular.module('dossiers').component('dossierCard', {
  templateUrl: 'dossiers/dossiers-components/dossier-card/dossier-card.html',
  bindings: {
    namespace: '<',
    dossierFinancement: '<',
  },
  controller: DossierCardController,
});
