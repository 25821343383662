/**
 * @ngdoc module
 * @module form
 * @name form.filters
 * @description
 *
 *   Filters useful in forms.
 *
 *   This module is a part of {@link ../form/index.html form}.
 *
 */

angular.module('form.filters', []);