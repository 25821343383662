/**
 * @ngdoc module
 * @name tiers
 * @description
 *
 *   The main module for all tiers related services and directives for our portals
 */

angular.module('tiers', [
  'common',
  'tiers.model',
  'tiers.services',
  'ngMap',
  'mgcrea.ngStrap.modal',
  'kendo.directives',
  'ui.utils',
  'ui.bootstrap.typeahead',
  'ui.bootstrap.tpls',
  'ui.bootstrap.tooltip',
  'form',
  'alerts',
  'mgcrea.ngStrap.tooltip',
  'pascalprecht.translate',
  'configuration',
  'persistence',
  'angularFileUpload',
  'ngSanitize',
  'ngPatternRestrict',
]);
