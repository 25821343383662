class JustificationsServices {
  constructor($http, $log, configuration) {
    this.demandesFinancementUrl = _.get(configuration, 'aides.url') + '/' + _.get(configuration, 'aides.ressourcePath');
    this.justificationsUrl = _.get(configuration, 'referentielJustification.service');
    this.demandeJustificationUrl =
      _.get(configuration, 'aides.justification.service') + '/demandes-financement-justification';
    this._http = $http;
    this.logger = $log;
  }

  /**
   * check in the list of available teleservices justification if there's one occurence
   * @returns {boolean} has at least one teleservice justification
   */
  hasTeleservices() {
    const params = {
      params: {
        $filter: 'active eq true',
      },
    };
    return this._http
      .get(`${this.justificationsUrl}/teleservices`, params)
      .then(({ data }) => _.get(data, 'meta.total', 0) > 0)
      .catch((rejection) => {
        this.logger.error(
          `An error occured trying to recover the number of active teleservices from the referentiel-justification with message: ${JSON.stringify(
            rejection.data
          )}`
        );
        return false;
      });
  }

  /**
   * Check the number of justification available for the given tiers
   * @param {Object} tiers
   * @returns {Promise} the response
   */
  countDemandeJustifications() {
    return this._http
      .post(this.demandeJustificationUrl, {})
      .then(({ data }) => data.total)
      .catch((rejection) => {
        this.logger.error(
          `An error occured trying to recover the demandes to justify or with an ongoing justification from request with message : ${rejection.data}`
        );
        return 0;
      });
  }

  /**
   * Create a justification
   * @param {object} justification
   */
  createJustification(justification) {
    return this._http
      .post(this.justificationsUrl + '/justifications', justification)
      .then(({ data }) => data)
      .catch(({ data }) => {
        this.logger.error(data);
        throw new Error(data);
      });
  }

  /**
   * Used to delete a justification
   * @param {Object} _justification
   */
  deleteJustification(justification) {
    return this._http
      .delete(justification.id)
      .then(({ data }) => (!_.isNil(data) ? justification.id : false))
      .catch((err) => {
        this.logger.error(`An error occured trying to delete the justification of the demande : ${err}`);
        return false;
      });
  }
}

JustificationsServices.$inject = ['$http', '$log', 'configuration'];

angular.module('justifications.services').service('justificationsService', JustificationsServices);
