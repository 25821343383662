angular.module('portailDepotDemandeAide.depot').controller('depotSimpleRecapitulatifController', [
  '$scope',
  '$log',
  '$q',
  '$translate',
  'controleCompletudeService',
  'aidesService',
  'contributionsService',
  '$state',
  'alertsService',
  '$rootScope',
  'Piece',
  'Link',
  'Aide',
  'contributionsConstants',
  'teleservicesService',
  'StoreService',
  'controleEligibiliteService',
  'tiersService',
  function(
    $scope,
    $log,
    $q,
    $translate,
    controleCompletudeService,
    aidesService,
    contributionsService,
    $state,
    alertsService,
    $rootScope,
    Piece,
    Link,
    Aide,
    contributionsConstants,
    teleservicesService,
    StoreService,
    controleEligibiliteService,
    tiersService
  ) {
    'use strict';
    //Statuts de contribution
    var statusContributions = contributionsConstants.status;

    // Demandeur steps
    let steps = [];

    $scope.blockingErrors = {
      controleEligibilite: false,
      completude: false,
      datesTeleservice: false,
      global: false,
    };

    function checkBlockingErrors() {
      $scope.navigate.block = false;
      for (const errorKey in $scope.blockingErrors) {
        if ($scope.blockingErrors[errorKey]) $scope.navigate.block = true;
      }
    }

    $scope.$watch('blockingErrors', () => checkBlockingErrors(), true);

    function checkCompletude() {
      if (!$scope.contribution) {
        steps = steps.concat([
          'demandeur-identification',
          'demandeur-adresse',
          'demandeur-representant-legal',
          'demandeur-representants',
          'demandeur-complementaire',
          'demandeur-thematiques',
        ]);

        const depotDelegue =
          _.get($scope.teleserviceConfiguration, 'workflow.depotDelegue.actif') &&
          _.get($scope.aide, 'beneficiaires[0].expand');

        if (depotDelegue) {
          steps = steps.concat([
            'beneficiaire-identification',
            'beneficiaire-adresse',
            'beneficiaire-representant-legal',
            'beneficiaire-representants',
            'beneficiaire-complementaire',
            'beneficiaire-thematiques',
          ]);
        }
      }

      // Aide steps
      steps = steps.concat([
        'informations-generales',
        'pageInformationsGenerales2',
        'pageInformationsGenerales3',
        'document-comptable',
        'domiciliation-bancaire',
      ]);

      // add "indicateurs" if necessary
      if (
        _.get($scope, 'teleserviceConfiguration.workflow.pageIndicateurs.actif', false) &&
        $scope.aide.hierarchies &&
        $scope.aide.hierarchies.length
      ) {
        steps.push('indicateurs');
      }

      // "Pieces" is not present in a contribution (except for redirection contribution)
      if (!$scope.contribution || $scope.contribution.typeContribution === 'REDIRECTION') steps.push('pieces');

      // Controle completude
      $scope.navigate.lockNavigation();
      $scope.controleCompletudeInProgress = true;

      // Call referentiel-financement and concatenate errors
      var completudeRequests = [];
      $scope.completude = {};
      $scope.controlAlerts = [];
      _.each(steps, function(step) {
        completudeRequests.push(
          controleCompletudeService.controlStep($scope.aide, step, $scope.contribution).then(function(result) {
            if (!result.valid) {
              // Sort errors by step
              $scope.completude[step] = result.errors;
              $scope.controlAlerts = $scope.controlAlerts.concat(result.errors);
            }
          })
        );
      });

      return $q.all(completudeRequests).finally(() => {
        $scope.navigate.unlockNavigation();
        $scope.controleCompletudeInProgress = false;

        // If there are errors, we can not transmit 'demande'
        $scope.blockingErrors.completude = $scope.controlAlerts.length > 0;
      });
    }

    function controlDatesTeleservice() {
      $scope.navigate.lockNavigation();
      return teleservicesService
        .controlDatesTeleservice($scope.teleserviceConfiguration, $scope.aide)
        .then(function(dates) {
          // If 'teleservice' is closed,  we can not transmit 'demande'
          const hasError = dates.notOpenYet || dates.alreadyClosed;
          $scope.blockingErrors.datesTeleservice = hasError;
        })
        .finally(() => {
          $scope.navigate.unlockNavigation();
        });
    }

    function initialize() {
      // Data for directive download-recapitulatif-aide
      $scope.demandeur = StoreService.demandeur.get();
      $scope.dataRecapitulatifAide = {};
      $scope.dataRecapitulatifAide = angular.copy($scope.aide);

      if ($scope.contribution) {
        $scope.dataRecapitulatifAide.contribution = $scope.contribution.reference;
      }
      $scope.noAttestationProvided = false;

      if ($scope.declarationCompteSignataire) {
        prepareSignataires();
      }

      $scope.urlDocuments = $scope.aide._links['mgs:documents'].href;
      $scope.persistenceConfiguration = _.get($scope, 'teleserviceConfiguration.persistenceConfiguration.expand', {});
      // Directory of file's icons
      $scope.urlFileIcons = './resources/images/file-icons/';

      // Récupération des différents messages html personnalisés à afficher
      $scope.messageAttestationHonneur = _.get(
        $scope,
        'teleserviceConfiguration.workflow.pageRecapitulatif.messageAttestationHonneur'
      );
      $scope.messageSelectionCompteSignataire = _.get(
        $scope,
        'teleserviceConfiguration.workflow.pageRecapitulatif.messageSelectionCompteSignataire'
      );
      $scope.messageAide = _.get(
        $scope,
        'teleserviceConfiguration.workflow.pageRecapitulatif.selectionCompteSignataire.help'
      );
      $scope.testrecaplibelle = false;
      $scope.contenuHtml = $translate.instant($scope.recapitulatifAideConfiguration.ns + '.contenuHtml');
      if (
        $scope.contenuHtml &&
        $scope.contenuHtml.length > 0 &&
        $scope.contenuHtml !== $scope.recapitulatifAideConfiguration.ns + '.contenuHtml'
      ) {
        $scope.testrecaplibelle = true;
      }
      $scope.cleanNavigate();
      $scope.navigate.noform = true;
      $scope.navigate.ns = $scope.recapitulatifAideConfiguration.ns;

      // check controlesEligibilite if there is an url to call on the teleservice
      if ($scope.teleserviceConfiguration.urlApiExterneControleEligibilite) {
        controleEligibiliteService
          .check($scope.aide)
          .then((controlesEligibilite) => {
            $scope.aide.controlesEligibilite = controlesEligibilite;
            const hasError =
              controlesEligibilite &&
              controlesEligibilite.length > 0 &&
              controlesEligibilite.some((controle) => controle.blocking);

            $scope.blockingErrors.controleEligibilite = hasError;
          })
          .catch(function() {
            $scope.blockingErrors.global = true;
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('teleservice.recapitulatif.controlesEligibilite.error'),
              'recapitulatif-alerts'
            );
          });
      }

      $scope.navigate.validate = validateDepot;

      // Wizard
      $scope.stepsWizard.steps = $scope.getSimpleSteps();
      $scope.stepsWizard.active = 'recapitulatif';

      $scope.viewConfiguration = $scope.recapitulatifAideConfiguration;
      $scope.labelSelectionCompteSignataire = _.get(
        $scope,
        'teleserviceConfiguration.workflow.pageRecapitulatif.selectionCompteSignataire.label'
      );
      $scope.messageErreurSelectionSignataire = _.get(
        $scope,
        'teleserviceConfiguration.workflow.' + 'pageRecapitulatif.selectionCompteSignataire.error.required'
      );
      // Controle completude : this way we really wait for pending promises to complete before checking completude
      StoreService.depot.pendingPromises.promise().then(() => {
        checkCompletude()
          .then(function() {
            if (!$scope.contribution && !$scope.blockingErrors.completude) {
              return controlDatesTeleservice();
            }
          })
          .catch(function() {
            $scope.blockingErrors.global = true;
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('teleservice.recapitulatif.completude.error'),
              'recapitulatif-alerts'
            );
          });
      });
    }
    initialize();

    /**
     * Fetch signataires and prepare variables using them
     * @returns {Promise}
     */
    function prepareSignataires() {
      return tiersService
        .getSignataires($scope.demandeur.id)
        .then((signataires) => {
          $scope.usersSignataires = signataires.map((signataire) => new Link(signataire));
          // We check if the current user is also a signataire
          $scope.isCurrentUserSignataire = $scope.usersSignataires.some(
            (user) => user.href === _.get($scope, 'currentUser.self')
          );
          $scope.attestationDeclarationHonneur =
            getAttestationDeclarationHonneur() && !$scope.contribution
              ? getAttestationDeclarationHonneur()
              : $scope.attestationDeclarationHonneur;
        })
        .catch(() => {
          $scope.blockingErrors.global = true;
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertError('teleservice.recapitulatif.fetchSignataires.error'),
            'recapitulatif-alerts'
          );
        });
    }

    // Url where to upload files
    function getAttestationDeclarationHonneur() {
      $scope.noAttestationProvided =
        $scope.declarationCompteSignataire &&
        $scope.usersSignataires.length === 0 &&
        (!$scope.attestationDeclarationHonneur.documents ||
          $scope.attestationDeclarationHonneur.documents.length === 0) &&
        $scope.attempted;
      var array = _.filter($scope.aide.pieces, function(piece) {
        return piece.reference === 'attestationDeclarationHonneur';
      });
      if (!_.isEmpty(array)) {
        return array[0];
      }
      return null;
    }

    function isFormValid() {
      // Il y a 4 cas de validation différents
      // 1) cas normal : pas de déclaration de compte signataire, juste l'acceptation cgu
      var case1 = !$scope.declarationCompteSignataire && $scope.cguAcceptation;
      //Ensuite nous avons tous les cas avec déclaration de compte signataire :
      // 2) Déclaration de compte signataire et aucun compte signataire ==> vérification de la présence du document de l'attestation
      var case2 =
        $scope.declarationCompteSignataire &&
        $scope.usersSignataires.length === 0 &&
        !_.isEmpty(_.get($scope, 'attestationDeclarationHonneur.documents'));
      // 3) Déclaration de compte signataire et le user courant est signataire ==> vérification de l'acceptation cgu
      var case3 = $scope.declarationCompteSignataire && $scope.isCurrentUserSignataire && $scope.cguAcceptation;
      // 4) Déclaration de compte signataire et présence de user signataires ==> vérification qu'un user signataire a bien été selectionné
      var case4 =
        $scope.declarationCompteSignataire &&
        $scope.usersSignataires.length > 0 &&
        !$scope.isCurrentUserSignataire &&
        $scope.aide.signataire;
      return case1 || case2 || case3 || case4;
    }

    function cleanSpecificValues(aideToUpdate) {
      var specifiques = _.get(aideToUpdate, 'specifiques');
      if (specifiques) {
        _.each(specifiques, function(valSpec) {
          if (_.isNull(_.get(valSpec, 'schemaList'))) {
            _.unset(valSpec, 'schemaList');
          }
          if (_.isNull(_.get(valSpec, 'defaultValue'))) {
            _.unset(valSpec, 'defaultValue');
          }
          if (_.isNull(_.get(valSpec, 'subType'))) {
            _.unset(valSpec, 'subType');
          }
          if (_.isNull(_.get(valSpec, 'uniqueKey'))) {
            _.unset(valSpec, 'uniqueKey');
          }
          if (_.isNull(_.get(valSpec, 'schemaList'))) {
            _.unset(valSpec, 'schemaList');
          }
          if (_.isNull(_.get(valSpec, 'restrictions'))) {
            _.unset(valSpec, 'restrictions');
          }
        });
      }
    }

    /**
     * Update demande and contribution
     * @param {Object} aideToUpdate
     * @param {string} statutContribution
     * @return {Promise<void>}
     */
    function updateDemandeAndContribution(aideToUpdate, statutContribution) {
      cleanSpecificValues(aideToUpdate);
      aideToUpdate.demandeur = _.pick(aideToUpdate.demandeur, ['href', 'title', 'rel']);

      return (
        aidesService
          // first check if an avisPrealable is needed (REDIRECTION)
          .determinerAvisPrealable(aideToUpdate, $scope.contribution)
          // update the aide before the contribution
          .then(() => aidesService.update(aideToUpdate, $scope.mdm))
          .then(() => {
            const patches = [
              {
                op: 'add',
                path: '/statut',
                value: statutContribution,
              },
            ];

            if (statutContribution === statusContributions.ANSWERED) {
              patches.push({
                op: 'add',
                path: '/dateReponse',
                value: new Date(),
              });
            }

            return contributionsService.patch($scope.contribution.reference, patches);
          })
          .then((res) => {
            const contribution = res.data;
            $rootScope.$broadcast('refresh-alerteur-contributions', contribution);
            $scope.goToStep('confirmation');
          })
      );
    }

    function validateDepot() {
      // Controle completude
      return checkCompletude()
        .then(function() {
          if (!$scope.blockingErrors.completude) {
            $scope.attempted = true;
            $scope.noAttestationProvided =
              $scope.declarationCompteSignataire &&
              $scope.usersSignataires.length === 0 &&
              (!$scope.attestationDeclarationHonneur.documents ||
                $scope.attestationDeclarationHonneur.documents.length === 0) &&
              $scope.attempted;

            if (isFormValid()) {
              // Dans le cas ou l'on a du selectionner un signataire
              if ($scope.declarationCompteSignataire && $scope.aide.signataire && !$scope.isCurrentUserSignataire) {
                $scope.aide.status = statusContributions.WAITING_FOR_CERTIFICATE;
              }
              // Dans le cas ou l'utilisateur courant est signataire, on l'enregistre automatiquement comme signataire de la demande
              if ($scope.declarationCompteSignataire && $scope.isCurrentUserSignataire) {
                var linkSignataire = _.filter($scope.usersSignataires, function(user) {
                  return user.href === _.get($scope, 'currentUser.self');
                })[0];
                $scope.aide.signataire = new Link(linkSignataire);
              }

              // in the case of a contribution we first update the aide, then the contribution
              if ($scope.contribution) {
                if ($scope.declarationCompteSignataire && $scope.aide.signataire && !$scope.isCurrentUserSignataire) {
                  aidesService.getAide($scope.aide.reference).then(function(res) {
                    var oldAide = res.data;
                    oldAide.status = statusContributions.WAITING_FOR_CERTIFICATE;
                    // remove the dateDemande so it is automatically recalculated in the back
                    delete oldAide.dateDemande;
                    oldAide.signataire = new Link($scope.aide.signataire);
                    oldAide = new Aide(oldAide);
                    updateDemandeAndContribution(oldAide, statusContributions.WAITING_FOR_CERTIFICATE);
                  });
                } else {
                  // Do nothing by default
                  let saveContributionPromise = $q.resolve();
                  if (
                    $scope.declarationCompteSignataire &&
                    !_.isEmpty($scope.attestationDeclarationHonneur.documents)
                  ) {
                    setAttestation();
                    // Add the new attestation in the contribution
                    saveContributionPromise = contributionsService.saveContribution($scope.aide, $scope.contribution);
                  }
                  saveContributionPromise.then(() => {
                    aidesService
                      .getAideWithContribution($scope.aide.reference, $scope.contribution.reference)
                      .then((aide) => updateDemandeAndContribution(aide, statusContributions.ANSWERED))
                      .then(() => aidesService.updateDemandeurGroupesGestion($scope.aide));
                  });
                }
              } else {
                return aidesService
                  .validAide(
                    $scope.demandeur,
                    $scope.aide,
                    isModificationAttestation(),
                    $scope.teleserviceConfiguration,
                    $state.params.tenantId
                  )
                  .then(function(aide) {
                    $log.debug('aide - after creation :' + JSON.stringify(aide));
                    $rootScope.$broadcast('refresh-alerteur-contributions');
                    $rootScope.$broadcast('aide-transmitted', {
                      referenceAdministrative: aide.referenceAdministrative,
                    });
                    StoreService.currentTiersRef.set($scope.demandeur.reference, true, true);
                    $scope.goToStep('confirmation');
                  })
                  .catch(function(error) {
                    if (error && error.status === 401) {
                      $state.go('home', {
                        loggedOut: true,
                      });
                    }
                  });
              }
            }
          }
        })
        .catch(function() {
          $scope.blockingErrors.global = true;
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertError('teleservice.recapitulatif.completude.error'),
            'recapitulatif-alerts'
          );
        });
    }

    /** Return step position in workflow */
    $scope.getStepOrder = function(alert) {
      return _.indexOf(steps, alert.step);
    };

    /**
     * Return the translation of a field name or its error
     * @param {string} field: field or property name
     * @param {string} label: label wanted (ex 'label' or 'error.required')
     * @param {string} step
     * @param {object} labelValue (optional): labels given directly by the completude
     */
    $scope.translateField = function(field, label, step, labelValue, stepMetadata) {
      // Default key for fields
      var labelKey = 'teleservice.' + step + '.' + field + '.' + label;

      if (labelValue) {
        // If there is a label given by completude, immediately use it
        if (labelValue.value && label === 'label') {
          return labelValue.value;
        } else if (labelValue.error && label === 'error.invalid') {
          return labelValue.error;
        }
        // The completude might indicates a key to use with $translate
        if (labelValue.key) {
          // If information-generales, it's a specific field
          if (step === 'informations-generales') {
            labelKey = 'presentations.' + labelValue.key + '.' + label;
          }
          // Else, use default key
          else {
            labelKey = 'teleservice.' + step + '.' + labelValue.key + '.' + label;
          }
        }
      }

      // Special cases, because each page has its exceptions

      // For thematiques and complementaire screens, path of fields is given by completude
      if (/(demandeur|beneficiaire)-(thematiques|complementaire)/.test(step)) {
        // However, we have to replace the sections' names: they are slightly different in the portal
        var replacements = [
          { from: 'sectionIdentite', to: 'identite-tiers' },
          { from: 'sectionMoyensHumains', to: 'moyens-humains' },
          { from: 'sectionRelations', to: 'relations-autres-associations' },
          { from: 'effectifsGroupe', to: 'effectifs-groupe' },
          { from: 'donneesFinancieresGroupe', to: 'donneesFinancieres-groupe' },
        ];
        _.each(replacements, function(replacement) {
          labelKey = labelKey.replace(replacement.from, replacement.to);
        });
      }
      // Pieces must use a different message for their required error
      else if ((step === 'pieces' || field === 'rib') && label === 'error.required') {
        if (field === 'piecesObligatoires') {
          labelKey = 'teleservice.pieces.error.missingAssociations';
        } else {
          labelKey = 'teleservice.pieces.error.missing';
        }
      }
      // Domiciliation-bancaire has it's fields under the key "create"
      else if (step === 'domiciliation-bancaire') {
        labelKey =
          'teleservice.domiciliation-bancaire.create.' + (field !== 'rib' ? 'fields.' : '') + field + '.' + label;
      }
      // Document-comptable uses a special label for missing precision on lines
      else if (step === 'document-comptable' && label === 'error.commentaire') {
        labelKey = 'teleservice.document-comptable.precisionObligatoire';
      }

      var translation = $translate.instant(labelKey, stepMetadata);

      // If no translation was found, use default form translation
      if (labelKey === translation) {
        if (label !== 'label') {
          labelKey = 'form.field.' + label;
        }
        translation = $translate.instant(labelKey);

        // If there is still no translation, we display the key that was intented
        if (labelKey === translation) {
          translation = field + '.' + label;
        }
      }

      return translation;
    };
    $scope.headerCompletude = function(alert) {
      return _.indexOf($scope.completude[alert.step], alert) === 0;
    };

    /**
     * Allow to control signataire value and delete if it's not valued
     */
    $scope.onSignataireChange = function() {
      if (_.isEmpty($scope.aide.signataire)) {
        delete $scope.aide.signataire;
      }
    };

    $scope.getStepTitle = function(step) {
      var complement = '';
      if (/-thematiques$/.test(step)) {
        var tiers = /demandeur-thematiques$/.test(step)
          ? $scope.demandeur || {}
          : _.get($scope.aide, 'beneficiaires[0].expand', {});
        complement = '.' + _.get(tiers, 'famille.expand.typeFamille');
      }
      return 'teleservice.' + step + complement + '.title';
    };

    function removeAttestation() {
      _.remove($scope.aide.pieces, function(piece) {
        return piece.reference === 'attestationDeclarationHonneur';
      });
    }

    function setAttestation() {
      if (getAttestationDeclarationHonneur()) {
        removeAttestation($scope.attestationDeclarationHonneur);
      }
      $scope.aide.pieces.push(new Piece($scope.attestationDeclarationHonneur));
    }

    $scope.savePersistence = function() {
      if (_.isEmpty($scope.contribution)) {
        setAttestation();
        aidesService.update($scope.aide);
      }
    };

    $scope.changeAcceptation = function(value) {
      $scope.cguAcceptation = value;
    };

    function isModificationAttestation() {
      // On modifie une demande en attestation si son status actuel est REQUESTED et le precedent WAITING_FOR_CERTIFICATE
      var status = _.get($scope, 'aide.history.events');
      return (
        status.length > 1 &&
        status[status.length - 1].reference === statusContributions.REQUESTED &&
        status[status.length - 2].reference === statusContributions.WAITING_FOR_CERTIFICATE
      );
    }

    $scope.redirectionPageControleCompletude = function(step) {
      // Si le tiers existe on redirige vers la fiche récapitulative du Tiers
      if ($scope.tiers && _.startsWith(step, 'demandeur-')) {
        $scope.goToStep('demandeur-recapitulatif', true, true);
      } else {
        $scope.goToStep(step, true, true);
      }
    };
  },
]);
